import { BreadcrumbGroupProps, CollectionPreferencesProps, TableProps } from '@amzn/awsui-components-react';
import { DEFAULT_LARGE_PAGE_SIZE } from 'src/components/generic-components/TableCommons';
import { getReadableDateFromDateTime } from 'src/utilities/DateTimeUtilities';
import { AdminBaseBreadcrumbs } from '../AdminHomePage';
import { FeedbackFlatTable } from './FeedbacksModel';

export const getBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'Feedback',
      href: '/admin/feedbacks'
    }
  ];
};

export const DEFAULT_VISIBLE_CONTENT = ['createdTime', 'applicationName', 'feedbackType', 'rating', 'feedback', 'createdBy'];

export const DEFAULT_FEEDBACK_VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Properties',
    options: [
      { id: 'createdTime', label: 'Date', editable: false },
      { id: 'feedbackType', label: 'Feedback Type', editable: false },
      { id: 'applicationName', label: 'Application', editable: false },
      { id: 'rating', label: 'Rating', editable: false },
      { id: 'feedback', label: 'Feedback', editable: false },
      { id: 'createdBy', label: 'Author', editable: false }
    ]
  }
];

export const FEEDBACK_FILTERING_PROPERTIES = [
  {
    key: 'createdTime',
    propertyLabel: 'Date',
    groupValuesLabel: 'Date values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'feedbackType',
    propertyLabel: 'Feedback Type',
    groupValuesLabel: 'Feedback Type values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'applicationName',
    propertyLabel: 'Application Name',
    groupValuesLabel: 'Application Name values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'rating',
    propertyLabel: 'Rating',
    groupValuesLabel: 'Rating values',
    operators: ['=', '!=', '<', '<=', '>', '>=']
  },
  {
    key: 'feedback',
    propertyLabel: 'Feedback',
    groupValuesLabel: 'Feedback values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'createdBy',
    propertyLabel: 'Author',
    groupValuesLabel: 'Author values',
    operators: [':', '!:', '=', '!=']
  }
];

export const FEEDBACK_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: DEFAULT_LARGE_PAGE_SIZE,
  visibleContent: DEFAULT_VISIBLE_CONTENT,
  wrapLines: true,
  stripedRows: false
};

export const FEEDBACK_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<FeedbackFlatTable>[] = [
  {
    id: 'createdTime',
    header: 'Date',
    width: 150,
    cell: (item) => item.createdTime,
    sortingField: 'createdTime'
  },
  {
    id: 'feedbackType',
    header: 'Feedback Type',
    cell: (item) => item.feedbackType,
    sortingField: 'feedbackType'
  },
  {
    id: 'applicationName',
    header: 'Application',
    width: 200,
    cell: (item) => item.applicationName,
    sortingField: 'applicationName'
  },
  {
    id: 'rating',
    header: 'Rating',
    width: 100,
    cell: (item) => item.rating,
    sortingField: 'rating'
  },
  {
    id: 'feedback',
    header: 'Feedback',
    cell: (item) => item.feedback,
    sortingField: 'feedback'
  },
  {
    id: 'createdBy',
    header: 'Author',
    width: 180,
    cell: (item) => item.createdBy,
    sortingField: 'createdBy'
  }
];
