import { Button, FormField, Input, Select, SelectProps, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useEffect, useState, forwardRef, ReactNode } from 'react';
import { useAppContext } from 'src/components/context/AppContextProvider';
import {
  labelInputToLabelDropdownOptions,
  labelInputToSelectDropdown,
  selectDropdownToLabel
} from 'src/components/generic-components/LabelDropdownUtility';
import { v4 as uuidv4 } from 'uuid';
import { NonProductionEnvironmentsEntity } from '../../AdminModels';
import { getAccessLevelIcon } from './StepsUtilities';
import { nonProductionEnvironmentSchema } from './StepsValidations';
import FormFieldPopover from 'src/components/generic-components/FormFieldPopover';

interface NonProductionEnvironmentFormProps {
  hasNonProductionEnvironments: boolean;
  addNewNonProductionEnvironment: (nonProductionEnvironmentsEntity: NonProductionEnvironmentsEntity) => void;
  updateNonProductionEnvironment: (nonProductionEnvironmentsEntity: NonProductionEnvironmentsEntity) => void;
}

export interface NonProductionEnvironmentFormMethods {
  editSelectedNonProd: (editNonProdEnvironment: NonProductionEnvironmentsEntity) => void;
}

const NonProductionEnvironmentForm = forwardRef<NonProductionEnvironmentFormMethods, NonProductionEnvironmentFormProps>(
  (
    { hasNonProductionEnvironments, addNewNonProductionEnvironment, updateNonProductionEnvironment }: NonProductionEnvironmentFormProps,
    ref: React.Ref<any>
  ) => {
    const appContext = useAppContext();
    const [newNonProdEnvironmentIsValid, setNewNonProdEnvironmentIsValid] = useState(false);
    const [newEnvironment, setNewEnvironment] = useState<NonProductionEnvironmentsEntity>({
      id: uuidv4(),
      accessLevel: { id: '', label: '', labelType: 'AccessLevel' },
      displayText: '',
      link: ''
    });
    const [newEnvironmentErrors, setNewEnvironmentErrors] = useState<NonProductionEnvironmentsEntity>({
      id: null,
      accessLevel: { id: '', label: '', labelType: 'AccessLevel' },
      displayText: '',
      link: ''
    });
    const [actionType, setActionType] = useState<'add' | 'edit'>('add');

    const editSelectedNonProd = async (editNonProdEnvironment: NonProductionEnvironmentsEntity) => {
      if (editNonProdEnvironment) {
        setActionType('edit');
        const temp: NonProductionEnvironmentsEntity = {
          id: editNonProdEnvironment.id,
          accessLevel: {
            id: editNonProdEnvironment.accessLevel.id,
            label: editNonProdEnvironment.accessLevel.label,
            labelType: 'AccessLevel'
          },
          displayText: editNonProdEnvironment.displayText,
          link: editNonProdEnvironment.link
        };
        setNewEnvironment(temp);
        try {
          await nonProductionEnvironmentSchema.validate(temp, { abortEarly: false });
          setNewNonProdEnvironmentIsValid(true);
          setNewEnvironmentErrors({
            ...newEnvironmentErrors,
            accessLevel: { id: '', label: '', labelType: 'AccessLevel' },
            displayText: '',
            link: ''
          });
        } catch (error: any) {
          setNewNonProdEnvironmentIsValid(false);
        }
      } else {
        setActionType('add');
        setNewEnvironment({
          id: uuidv4(),
          accessLevel: { id: '', label: '', labelType: 'AccessLevel' },
          displayText: '',
          link: ''
        });
      }
    };

    React.useImperativeHandle(ref, () => ({
      editSelectedNonProd
    }));

    const addNonProductionEnvironment = () => {
      const newRow: NonProductionEnvironmentsEntity = {
        id: uuidv4(),
        accessLevel: newEnvironment.accessLevel,
        displayText: newEnvironment.displayText,
        link: newEnvironment.link
      };
      clearForm();
      addNewNonProductionEnvironment(newRow);
    };

    const clearForm = () => {
      setNewEnvironment({ id: null, accessLevel: { id: '', label: '', labelType: 'AccessLevel' }, displayText: '', link: '' });
      setNewEnvironmentErrors({
        id: '',
        accessLevel: { id: '', label: '', labelType: 'AccessLevel' },
        displayText: '',
        link: ''
      });
      setNewNonProdEnvironmentIsValid(false);
    };

    const isValidNewNonProdData = async () => {
      try {
        await nonProductionEnvironmentSchema.validate(newEnvironment, { abortEarly: false });
        setNewNonProdEnvironmentIsValid(true);
      } catch (error: any) {
        setNewNonProdEnvironmentIsValid(false);
      }
    };

    const cancelEditNonProdEnvironment = () => {
      clearForm();
      setActionType('add');
    };

    const updatedChanges = () => {
      updateNonProductionEnvironment(newEnvironment);
      clearForm();
      setActionType('add');
    };

    return (
      <div ref={ref} className="inline-table-horizontal-form">
        <FormField
          label="Access level"
          errorText={newEnvironmentErrors.accessLevel?.label}
          stretch={true}
          info={
            <FormFieldPopover
              header={'Access level'}
              messages={['Select Public to allow access to all users.', 'Select Admin to restrict access to admins only.']}
            />
          }
        >
          <Select
            expandToViewport={true}
            filteringType="auto"
            placeholder="Choose access level"
            disabled={!hasNonProductionEnvironments}
            options={
              labelInputToLabelDropdownOptions(appContext.listApplicationDropdowns?.listAccessLevels)?.map((accessLevels) => {
                return {
                  ...accessLevels,
                  iconName: getAccessLevelIcon(accessLevels.label)
                };
              }) as SelectProps.Options
            }
            selectedOption={labelInputToSelectDropdown(
              appContext.listApplicationDropdowns?.listAccessLevels?.find((accessLevel) => accessLevel.id === newEnvironment.accessLevel.id)
            )}
            onChange={({ detail }) =>
              setNewEnvironment({ ...newEnvironment, accessLevel: selectDropdownToLabel(detail.selectedOption, 'AccessLevel') })
            }
            onBlur={async () => {
              nonProductionEnvironmentSchema
                .validateAt('accessLevel', {
                  accessLevel: appContext.listApplicationDropdowns?.listAccessLevels?.find(
                    (accessLevel) => accessLevel.id === newEnvironment.accessLevel.id
                  )
                })
                .then(() => {
                  setNewEnvironmentErrors({
                    ...newEnvironmentErrors,
                    accessLevel: { id: '', label: '', labelType: 'AccessLevel' }
                  });
                })
                .catch((error) => {
                  setNewEnvironmentErrors({
                    ...newEnvironmentErrors,
                    accessLevel: { id: '', label: error.message, labelType: 'AccessLevel' }
                  });
                })
                .finally(() => {
                  isValidNewNonProdData();
                });
            }}
          />
        </FormField>

        <FormField
          label="Display name"
          errorText={newEnvironmentErrors?.displayText}
          stretch={true}
          info={<FormFieldPopover header={'Display name'} messages={['Name the link you want displayed.']} />}
        >
          <Input
            onChange={({ detail }) => setNewEnvironment({ ...newEnvironment, displayText: detail.value })}
            disabled={!hasNonProductionEnvironments}
            value={newEnvironment.displayText || ''}
            onBlur={async () => {
              nonProductionEnvironmentSchema
                .validateAt('displayText', { displayText: newEnvironment.displayText })
                .then(() => {
                  setNewEnvironmentErrors({
                    ...newEnvironmentErrors,
                    displayText: ''
                  });
                })
                .catch((error) => {
                  setNewEnvironmentErrors({
                    ...newEnvironmentErrors,
                    displayText: error.message
                  });
                })
                .finally(() => {
                  isValidNewNonProdData();
                });
            }}
          />
        </FormField>

        <FormField
          label="Link"
          className="flex-grow-field"
          constraintText={`Include http:// or https:// in your link`}
          errorText={newEnvironmentErrors?.link}
          stretch={true}
          info={<FormFieldPopover header={'Link'} messages={['Enter the non-production environment URL.']} />}
        >
          <Input
            onChange={({ detail }) => setNewEnvironment({ ...newEnvironment, link: detail.value })}
            disabled={!hasNonProductionEnvironments}
            value={newEnvironment.link || ''}
            onBlur={async () => {
              nonProductionEnvironmentSchema
                .validateAt('link', { link: newEnvironment.link })
                .then(() => {
                  setNewEnvironmentErrors({
                    ...newEnvironmentErrors,
                    link: ''
                  });
                })
                .catch((error) => {
                  setNewEnvironmentErrors({
                    ...newEnvironmentErrors,
                    link: error.message
                  });
                })
                .finally(() => {
                  isValidNewNonProdData();
                });
            }}
          />
        </FormField>

        {actionType === 'add' && (
          <Button className="add-new-button" formAction="none" disabled={!newNonProdEnvironmentIsValid} onClick={addNonProductionEnvironment}>
            {`Add`}
          </Button>
        )}
        {actionType === 'edit' && (
          <>
            <Button className="add-new-button" formAction="none" onClick={cancelEditNonProdEnvironment}>
              {`Cancel`}
            </Button>
            <Button className="add-new-button" formAction="none" variant="primary" disabled={!newNonProdEnvironmentIsValid} onClick={updatedChanges}>
              {`Save`}
            </Button>
          </>
        )}
      </div>
    );
  }
);

export default NonProductionEnvironmentForm;
