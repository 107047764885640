import React, { useState } from 'react';
import { ApplicationWizard, ApplicationWizardTools } from '../../AdminModels';
import { HelpPanel } from '@amzn/awsui-components-react';
import { AdminNewAppWizardSteps } from './AdminNewApp';
import { FormikProps } from 'formik';
import { InfoPanelDetailsHelpPanel } from './InfoPanelDetails';

const ExampleInfoContent = () => {
  return (
    <>
      <span>Example info</span>
    </>
  );
};

export const TOOLS_CONTENT: ApplicationWizardTools = {
  applicationDetails: {
    default: {
      title: 'Application Details',
      content: <ExampleInfoContent />
    },
    applicationOverview: {
      title: 'Application overview',
      content: <ExampleInfoContent />
    },
    applicationName: {
      title: 'Application Name',
      content: <ExampleInfoContent />
    },
    applicationLink: {
      title: 'Application link',
      content: <ExampleInfoContent />
    },
    applicationOwner: {
      title: 'Application Owner',
      content: <ExampleInfoContent />
    },
    shortApplicationDescription: {
      title: 'Short application description',
      content: <ExampleInfoContent />,
      links: []
    },
    fullApplicationDescription: {
      title: 'Full application description',
      content: <ExampleInfoContent />,
      links: []
    },
    applicationCategories: {
      title: 'application categories',
      content: <ExampleInfoContent />
    },
    glAccounts: {
      title: 'glAccounts',
      content: <ExampleInfoContent />,
      links: []
    },
    toolUI: {
      title: 'toolUI',
      content: <ExampleInfoContent />,
      links: []
    }
  },
  linksAndTags: {
    default: {
      title: 'Default',
      content: <ExampleInfoContent />,
      links: []
    },
    nonProductionEnvironments: {
      title: 'Non production environments',
      content: <ExampleInfoContent />,
      links: []
    },
    applicationTags: {
      title: 'Application tags',
      content: <ExampleInfoContent />,
      links: []
    }
  },
  homePageConfigurations: {
    default: {
      title: 'Select homePageConfigurations',
      content: <ExampleInfoContent />
    },
    welcomeTitle: {
      title: 'Select welcome',
      content: <ExampleInfoContent />
    },
    announcements: {
      title: 'Select announcements',
      content: <ExampleInfoContent />
    },
    tutorials: {
      title: 'Select tutorials',
      content: <ExampleInfoContent />
    },
    feedback: {
      title: 'Select feedback',
      content: <ExampleInfoContent />
    }
  },
  infoPanelPageConfiguration: {
    default: {
      title: 'Preview',
      content: <ExampleInfoContent />
    }
  }
};

const getDefaultToolsContent = (activeIndex: number) => TOOLS_CONTENT[AdminNewAppWizardSteps[activeIndex].stateKey].default;
const getFormattedToolsContent = (tools: any) => <HelpPanel header={<h2>{tools.title}</h2>}>{tools.content}</HelpPanel>;

export const useTools = () => {
  const [headerName, setHeaderName] = useState<string>('Help / Support');
  const [toolsContent, setToolsContent] = React.useState<any>();
  const [isToolsOpen, setIsToolsOpen] = React.useState(false);

  const setFormattedToolsContent = (tools: any, formik: FormikProps<ApplicationWizard>) => {
    if (formik)
      setToolsContent(<InfoPanelDetailsHelpPanel headerName={headerName} infoPanelPageConfiguration={formik.values.infoPanelPageConfiguration} />);
    else setToolsContent(getFormattedToolsContent(tools));
  };

  const setHelpPanelContent = (tools: any, formik: FormikProps<ApplicationWizard>) => {
    if (tools) {
      setFormattedToolsContent(tools, formik);
      setIsToolsOpen(true);
    }
  };
  const closeTools = () => setIsToolsOpen(false);

  const onToolsChange = (evt: any) => setIsToolsOpen(evt.detail.open);

  return {
    toolsContent,
    isToolsOpen,
    setHelpPanelContent,
    closeTools,
    setFormattedToolsContent,
    onToolsChange,
    headerName,
    setHeaderName
  };
};
