/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement($input: AnnouncementInput) {
    createAnnouncement(input: $input) {
      announcement
      announcementCategory {
        id
        label
        labelType
      }
      announcementDate
      announcementLink
      applicationName {
        id
        label
        labelType
      }
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      title
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication($input: ApplicationInput) {
    createApplication(input: $input) {
      additionalResources {
        displayName
        id
        link
        position
      }
      applicationLink
      applicationName
      applicationOwner {
        id
        label
        labelType
      }
      finTechContacts {
        alias
        contactDescription
        id
        position
      }
      financeContacts {
        alias
        contactDescription
        id
        position
      }
      fullApplicationDescription
      glAccounts {
        id
        label
        labelType
      }
      hasAdditionalResources
      hasAnnouncements
      hasFeedback
      hasFinTechContacts
      hasFinanceContacts
      hasNonProductionEnvironments
      hasOfficeHours
      hasReportIssues
      hasTutorials
      hasWelcomeContent
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      keyDimension {
        id
        label
        labelType
      }
      lastEnhanced {
        id
        label
        labelType
      }
      nonProductionEnvironments {
        accessLevel {
          id
          label
          labelType
        }
        displayText
        id
        link
      }
      officeHoursV2 {
        dateTimeRecurrence
        id
        locationOrLinkName
        meetingLink
      }
      process {
        id
        label
        labelType
      }
      reportIssues {
        displayName
        id
        link
        position
      }
      scenario {
        id
        label
        labelType
      }
      shortApplicationDescription
      timeGranularity {
        id
        label
        labelType
      }
      toolUI {
        id
        label
        labelType
      }
      tutorialContentDetails {
        category
        displayTitle
        id
        link
        position
      }
      welcomeContentDetails {
        description
        displayText
        id
        link
      }
      welcomeTitle
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback($input: FeedbackInput) {
    createFeedback(input: $input) {
      applicationName {
        id
        label
        labelType
      }
      feedback
      feedbackType
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      rating
    }
  }
`;
export const createGeneralContent = /* GraphQL */ `
  mutation CreateGeneralContent($input: GeneralContentInput) {
    createGeneralContent(input: $input) {
      content
      id
      name
    }
  }
`;
export const createLabel = /* GraphQL */ `
  mutation CreateLabel($input: LabelInput) {
    createLabel(input: $input) {
      id
      label
      labelType
    }
  }
`;
export const createOrUpdateApplicationFavorites = /* GraphQL */ `
  mutation CreateOrUpdateApplicationFavorites($input: FavoriteInput) {
    createOrUpdateApplicationFavorites(input: $input) {
      applicationId
      id
      userAlias
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication($input: [ApplicationInput]) {
    deleteApplication(input: $input)
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement($input: AnnouncementInput) {
    updateAnnouncement(input: $input) {
      announcement
      announcementCategory {
        id
        label
        labelType
      }
      announcementDate
      announcementLink
      applicationName {
        id
        label
        labelType
      }
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      title
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication($input: ApplicationInput) {
    updateApplication(input: $input) {
      additionalResources {
        displayName
        id
        link
        position
      }
      applicationLink
      applicationName
      applicationOwner {
        id
        label
        labelType
      }
      finTechContacts {
        alias
        contactDescription
        id
        position
      }
      financeContacts {
        alias
        contactDescription
        id
        position
      }
      fullApplicationDescription
      glAccounts {
        id
        label
        labelType
      }
      hasAdditionalResources
      hasAnnouncements
      hasFeedback
      hasFinTechContacts
      hasFinanceContacts
      hasNonProductionEnvironments
      hasOfficeHours
      hasReportIssues
      hasTutorials
      hasWelcomeContent
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      keyDimension {
        id
        label
        labelType
      }
      lastEnhanced {
        id
        label
        labelType
      }
      nonProductionEnvironments {
        accessLevel {
          id
          label
          labelType
        }
        displayText
        id
        link
      }
      officeHoursV2 {
        dateTimeRecurrence
        id
        locationOrLinkName
        meetingLink
      }
      process {
        id
        label
        labelType
      }
      reportIssues {
        displayName
        id
        link
        position
      }
      scenario {
        id
        label
        labelType
      }
      shortApplicationDescription
      timeGranularity {
        id
        label
        labelType
      }
      toolUI {
        id
        label
        labelType
      }
      tutorialContentDetails {
        category
        displayTitle
        id
        link
        position
      }
      welcomeContentDetails {
        description
        displayText
        id
        link
      }
      welcomeTitle
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback($input: FeedbackInput) {
    updateFeedback(input: $input) {
      applicationName {
        id
        label
        labelType
      }
      feedback
      feedbackType
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      rating
    }
  }
`;
export const updateGeneralContent = /* GraphQL */ `
  mutation UpdateGeneralContent($input: GeneralContentInput) {
    updateGeneralContent(input: $input) {
      content
      id
      name
    }
  }
`;
export const updateLabel = /* GraphQL */ `
  mutation UpdateLabel($input: LabelInput) {
    updateLabel(input: $input) {
      id
      label
      labelType
    }
  }
`;
export const createOrUpdateMapping = /* GraphQL */ `
  mutation CreateOrUpdateMapping($input: [MappingInput], $table_name: String) {
    createOrUpdateMapping(input: $input, table_name: $table_name) {
      numberOfRecordsUpdated
    }
  }
`;
