import { LineChartProps, MixedLineBarChartProps } from '@amzn/awsui-components-react';

export const tableMockData = [
  {
    account: 'AD3',
    profile: 'appstore-refunds',
    lastUpdatedBy: 'Kelvin Xie',
    cost: '729.57'
  },
  {
    account: 'AD3',
    profile: 'D_DISTRIBUTOR_ORDER_ITEMS ANDES Load',
    lastUpdatedBy: 'Ayush Aman',
    cost: '508.93'
  },
  {
    account: 'AD3',
    profile: 'Daily Load Profile for AD3-D_UNIFIED_CUSTOMER_ORDER_ITEMS',
    lastUpdatedBy: 'Kelvin Xie',
    cost: '385.64'
  },
  {
    account: 'AD3',
    profile: 'D_DAILY_ASIN_GV_METRICS',
    lastUpdatedBy: 'Sam Geller',
    cost: '151.42'
  },
  {
    account: 'AD3-DasFinance',
    profile: 'ad3-das_fin-rsdb-KD_CUSTOMER_RETURN_ITEMS-Extract',
    lastUpdatedBy: 'Aashutosh Tiwari',
    cost: '127.23'
  }
];

export const lineChartData: MixedLineBarChartProps.LineDataSeries<Date>[] = [
  {
    title: 'AD3',
    type: 'line',
    data: [
      { x: new Date(1601017200000), y: 58020 },
      { x: new Date(1601018100000), y: 102402 },
      { x: new Date(1601019000000), y: 104920 },
      { x: new Date(1601019900000), y: 94031 },
      { x: new Date(1601020800000), y: 125021 },
      { x: new Date(1601021700000), y: 159219 },
      { x: new Date(1601022600000), y: 193082 },
      { x: new Date(1601023500000), y: 162592 },
      { x: new Date(1601024400000), y: 274021 },
      { x: new Date(1601025300000), y: 264286 },
      { x: new Date(1601026200000), y: 289210 },
      { x: new Date(1601027100000), y: 256362 },
      { x: new Date(1601028000000), y: 257306 },
      { x: new Date(1601028900000), y: 186776 },
      { x: new Date(1601029800000), y: 294020 },
      { x: new Date(1601030700000), y: 385975 },
      { x: new Date(1601031600000), y: 486039 },
      { x: new Date(1601032500000), y: 490447 },
      { x: new Date(1601033400000), y: 361845 },
      { x: new Date(1601034300000), y: 339058 },
      { x: new Date(1601035200000), y: 298028 },
      { x: new Date(1601036100000), y: 231902 },
      { x: new Date(1601037000000), y: 224558 },
      { x: new Date(1601037900000), y: 253901 },
      { x: new Date(1601038800000), y: 102839 },
      { x: new Date(1601039700000), y: 234943 },
      { x: new Date(1601040600000), y: 204405 },
      { x: new Date(1601041500000), y: 190391 },
      { x: new Date(1601042400000), y: 183570 },
      { x: new Date(1601043300000), y: 162592 },
      { x: new Date(1601044200000), y: 148910 },
      { x: new Date(1601045100000), y: 229492 },
      { x: new Date(1601046000000), y: 293910 }
    ]
  },
  {
    title: 'FDA',
    type: 'line',
    data: [
      { x: new Date(1601017200000), y: 28020 },
      { x: new Date(1601018100000), y: 132402 },
      { x: new Date(1601019000000), y: 103420 },
      { x: new Date(1601019900000), y: 934031 },
      { x: new Date(1601020800000), y: 135021 },
      { x: new Date(1601021700000), y: 139219 },
      { x: new Date(1601022600000), y: 193082 },
      { x: new Date(1601023500000), y: 132592 },
      { x: new Date(1601024400000), y: 234021 },
      { x: new Date(1601025300000), y: 263486 },
      { x: new Date(1601026200000), y: 234210 },
      { x: new Date(1601027100000), y: 253362 },
      { x: new Date(1601028000000), y: 253206 },
      { x: new Date(1601028900000), y: 183776 },
      { x: new Date(1601029800000), y: 294320 },
      { x: new Date(1601030700000), y: 384375 },
      { x: new Date(1601031600000), y: 483439 },
      { x: new Date(1601032500000), y: 493247 },
      { x: new Date(1601033400000), y: 361445 },
      { x: new Date(1601034300000), y: 339038 },
      { x: new Date(1601035200000), y: 298038 },
      { x: new Date(1601036100000), y: 231302 },
      { x: new Date(1601037000000), y: 214558 },
      { x: new Date(1601037900000), y: 213901 },
      { x: new Date(1601038800000), y: 112839 },
      { x: new Date(1601039700000), y: 214943 },
      { x: new Date(1601040600000), y: 214405 },
      { x: new Date(1601041500000), y: 100391 },
      { x: new Date(1601042400000), y: 103570 },
      { x: new Date(1601043300000), y: 102592 },
      { x: new Date(1601044200000), y: 108910 },
      { x: new Date(1601045100000), y: 209492 },
      { x: new Date(1601046000000), y: 203910 }
    ]
  },
  {
    title: 'AD3-DasFinance',
    type: 'line',
    data: [
      { x: new Date(1601017200000), y: 328020 },
      { x: new Date(1601018100000), y: 135402 },
      { x: new Date(1601019000000), y: 123420 },
      { x: new Date(1601019900000), y: 924031 },
      { x: new Date(1601020800000), y: 125021 },
      { x: new Date(1601021700000), y: 129219 },
      { x: new Date(1601022600000), y: 123082 },
      { x: new Date(1601023500000), y: 122592 },
      { x: new Date(1601024400000), y: 224021 },
      { x: new Date(1601025300000), y: 223486 },
      { x: new Date(1601026200000), y: 224210 },
      { x: new Date(1601027100000), y: 223362 },
      { x: new Date(1601028000000), y: 223206 },
      { x: new Date(1601028900000), y: 283776 },
      { x: new Date(1601029800000), y: 224320 },
      { x: new Date(1601030700000), y: 324375 },
      { x: new Date(1601031600000), y: 423439 },
      { x: new Date(1601032500000), y: 423247 },
      { x: new Date(1601033400000), y: 321445 },
      { x: new Date(1601034300000), y: 319038 },
      { x: new Date(1601035200000), y: 228038 },
      { x: new Date(1601036100000), y: 221302 },
      { x: new Date(1601037000000), y: 224558 },
      { x: new Date(1601037900000), y: 223901 },
      { x: new Date(1601038800000), y: 122839 },
      { x: new Date(1601039700000), y: 224943 },
      { x: new Date(1601040600000), y: 224405 },
      { x: new Date(1601041500000), y: 120391 },
      { x: new Date(1601042400000), y: 123570 },
      { x: new Date(1601043300000), y: 202592 },
      { x: new Date(1601044200000), y: 208910 },
      { x: new Date(1601045100000), y: 229492 }
    ]
  }
];
export const costByAccountData = [
  {
    title: 'AD3',
    value: 60
    //color:"orange"
  },
  {
    title: 'FDA',
    value: 25
    //color:"green"
  },
  {
    title: 'AD3-DasFinance',
    value: 15
    //color:"red"
  }
];
