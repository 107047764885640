import React, { ReactNode, useEffect, useState } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import { Box, Header, AreaChart, AreaChartProps } from '@amzn/awsui-components-react';
import { ScaleType } from '../FinTechOpsCommonElements';
import { EmptyState } from '../../generic-components/empty-state';

export const AreaChartWithProps = (props: {
  hideFilter?: boolean | undefined;
  i18nStrings?: {};
  xDomain?: readonly string[] | readonly number[] | readonly Date[] | undefined;
  stackedBars?: boolean | undefined;
  yTitle: string | undefined;
  xScaleType: ScaleType | undefined;
  domainType: 'num' | 'string';
  yDomain?: readonly number[] | undefined;
  arialLabel: string | undefined;
  errorText: string | undefined;
  xTitle: string | undefined;
  emptyMessage: ReactNode;
  noMatchMessage: ReactNode;
  series: readonly AreaChartProps.AreaSeries<string | number | Date>[];
  headerName: String | undefined;
  hideLegend?: boolean | undefined;
}) => {
  const [xDomain, setXDomain]: any = useState([]);
  const [yDomain, setYDomain]: any = useState([]);

  //Act as an attempt to create an xDomain
  async function returnDomainX() {
    const domainX: (string | number | Date)[] = [];
    props.series.forEach((element) => {
      element.data.forEach((dataPoint) => {
        domainX.push(dataPoint.x);
      });
      setXDomain(domainX);
    });
  }

  //act as an attempt to create yDomain
  //override using props.xDomain
  async function returnDomainY() {
    const domainY: number[] = [0];
    const rollingTotalsOfData: number[] = [];
    let maxValOfData = 0;
    let firstIteration = true;

    props.series.forEach((element) => {
      let pointCounter = 0;
      element.data.forEach((dataPoint) => {
        if (firstIteration) {
          rollingTotalsOfData.push(dataPoint.y);
        } else if (rollingTotalsOfData[pointCounter] != null) {
          rollingTotalsOfData[pointCounter] += dataPoint.y;
        } else {
          rollingTotalsOfData.push(dataPoint.y);
        }
        pointCounter++;
      });
      firstIteration = false;
    });
    maxValOfData = rollingTotalsOfData.reduce((a, b) => Math.max(a, b), -Infinity);
    domainY.push(maxValOfData * 1.1);
    setYDomain(domainY);
    return;
  }

  useEffect(() => {
    returnDomainX();
    returnDomainY();
  }, [props.series]);

  const NoMatchMessage = (
    <Box textAlign="center" color="inherit">
      <b>No matching data</b>
      <Box variant="p" color="inherit">
        {props.noMatchMessage}
      </Box>
    </Box>
  );

  return (
    <Container fitHeight header={<Header variant="h3">{props.headerName}</Header>}>
      <AreaChart
        series={props.series}
        xDomain={props.xDomain || xDomain}
        yDomain={props.yDomain || yDomain}
        ariaLabel={props.arialLabel}
        errorText={props.errorText}
        loadingText="Loading chart"
        recoveryText="Retry"
        xScaleType={props.xScaleType}
        xTitle={props.xTitle}
        yTitle={props.yTitle}
        hideFilter={props.hideFilter || false}
        height={300}
        hideLegend={props.hideLegend || false}
        i18nStrings={
          props.i18nStrings || {
            filterLabel: 'Filter displayed data',
            filterPlaceholder: 'Filter data'
          }
        }
        empty={props.emptyMessage || <EmptyState title={'Error Loading Data'} description="No data to could be loaded" />}
        noMatch={NoMatchMessage}
      />
    </Container>
  );
};
