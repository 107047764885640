import React from 'react';
import { Box, Checkbox, SelectProps } from '@amzn/awsui-components-react';

interface CategoryCheckboxProps {
  category: SelectProps.Option;
  onChange: (value: string, checked: boolean) => void;
  checked: boolean;
}

export const CategoryCheckbox: React.FC<CategoryCheckboxProps> = ({ category, onChange, checked }) => {
  const handleCheckboxChange = (checked: any) => {
    onChange(category.label || '', checked);
  };

  return (
    <Box padding="xxs">
      <Checkbox onChange={({ detail }) => handleCheckboxChange(detail.checked)} checked={checked}>
        {category.label}
      </Checkbox>
    </Box>
  );
};
