import { useMemo, useState } from 'react';
import { eUserPreferenceKeys } from 'src/constants/AppConstants';
import { load, save } from './LocalStorage';

export function useColumnWidths(storageKey: eUserPreferenceKeys, columnDefinitions: any[]) {
  const [widths, saveWidths] = useLocalStorage(storageKey);

  function handleWidthChange(event: any) {
    saveWidths(mapWithColumnDefinitionIds(columnDefinitions, 'width', event.detail.widths));
  }
  const memoDefinitions = useMemo(() => {
    return addToColumnDefinitions(columnDefinitions, 'width', widths);
  }, [widths, columnDefinitions]);

  return [memoDefinitions, handleWidthChange];
}

export function useLocalStorage<T>(key: string, defaultValue?: T) {
  const [value, setValue] = useState<T>(() => load(key) ?? defaultValue);

  function handleValueChange(newValue: T) {
    setValue(newValue);
    save(key, newValue);
  }

  return [value, handleValueChange] as const;
}

export const addToColumnDefinitions = (columnDefinitions: any[], propertyName: string, columns: any) =>
  columnDefinitions.map((colDef) => {
    const column = (columns || []).find((col: any) => col.id === colDef.id);
    return {
      ...colDef,
      [propertyName]: (column && column[propertyName]) || colDef[propertyName]
    };
  });

export const mapWithColumnDefinitionIds = (columnDefinitions: any[], propertyName: string, items: any[]) =>
  columnDefinitions.map(({ id }, index) => ({
    id,
    [propertyName]: items[index]
  }));
