import { FlashbarProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import { StatusType } from '../sas/SasInterfaces';
import { PolicyEngineDetailsTabInfo, PolicyEngineGraphData } from './PolicyEngineInterface';

export type PolicyEngineContextType = {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => void;

  policyEngineGraphStatus: StatusType;
  setPolicyEngineGraphStatus: (status: StatusType) => void;

  policyEngineGraphData: PolicyEngineGraphData;
  setPolicyEngineGraphData: (graphData: PolicyEngineGraphData) => void;

  policyEngineDetailsTabStatus: StatusType;
  setPolicyEngineDetailsTabStatus: (status: StatusType) => void;

  policyEngineDetailsTabData: PolicyEngineDetailsTabInfo;
  setPolicyEngineDetailsTabData: (policyEngineDetailsData: PolicyEngineDetailsTabInfo) => void;
};

export const PolicyEngineContext = createContext<PolicyEngineContextType>({
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => {},

  policyEngineGraphStatus: 'pending',
  setPolicyEngineGraphStatus: (status: StatusType) => {},

  policyEngineGraphData: {} as PolicyEngineGraphData,
  setPolicyEngineGraphData: (graphData: PolicyEngineGraphData) => {},

  policyEngineDetailsTabStatus: 'pending',
  setPolicyEngineDetailsTabStatus: (status: StatusType) => {},

  policyEngineDetailsTabData: {} as PolicyEngineDetailsTabInfo,
  setPolicyEngineDetailsTabData: (policyEngineDetailsData: PolicyEngineDetailsTabInfo) => {}
});

export const usePolicyEngineContext = () => useContext(PolicyEngineContext);
