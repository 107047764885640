import { WidgetConfig } from '../widgets/interface';

/**
 * List of application IDs that require custom widget dimensions
 * These applications need different layout settings than the default
 */
const EXCEPTIONAL_APPS = [
  '94ffdf7b-0aa9-4a12-b93b-f72f006a902f', // DaS FinSuite in Alpha
  '4ed7e962-2001-46cf-b97c-df5fe17ed1bb', // BFCM Sales in Alpha
  'b6702aa5-a957-4c41-947f-1487efa57fef', // BFCM Sales in Beta
  '3e4c278c-a3dd-43f2-a947-1bef687ef1d9' // BFCM Sales in prod
];

/**
 * Custom widget dimensions for exceptional applications
 */
const CUSTOM_WIDGET_DEFINITION = {
  defaultRowSpan: 4,
  defaultColumnSpan: 2
};

/**
 * Customizes the welcome widget dimensions for specific applications
 *
 * @param welcomeWidget - The original welcome widget configuration
 * @param id - The application ID to check against exceptional cases
 * @returns The widget config, either customized or original based on the app ID
 */
export const getCustomizedWelcomeWidget = (welcomeWidget: WidgetConfig, id: string): WidgetConfig => {
  if (!EXCEPTIONAL_APPS.includes(id)) {
    return welcomeWidget;
  }

  return {
    ...welcomeWidget,
    definition: CUSTOM_WIDGET_DEFINITION
  };
};
