import { FlashbarProps, MixedLineBarChartProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import { StatusType } from './CostMetricsInterface';
import { CostMetricsDetailsTabData, CostMetricsSummaryContainer, CostMetricsTableData } from './CostMetricsInterface';

export type CostMetricsContextType = {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => void;

  costMetricsGraphStatus: StatusType;
  setCostMetricsGraphStatus: (status: StatusType) => void;

  costMetricsDetailsTabStatus: StatusType;
  setCostMetricsDetailsTabStatus: (status: StatusType) => void;

  costMetricsDetailsTabData: CostMetricsDetailsTabData;
  setCostMetricsDetailsTabData: (costMetricsDetailsData: CostMetricsDetailsTabData) => void;

  costMetricsTrendsByMonthGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]; //Risk by Resource Type
  setCostMetricsTrendsByMonthGraphData: (costMetricsTrendsByMonthGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void; //Based on sevTicketsByDateData
  costMetricsTrendsByMonthGraphDataXDomain: string[];
  setCostMetricsTrendsByMonthGraphDataXDomain: (costMetricsTrendsByMonthGraphDataXDomain: string[]) => void;
  costMetricsTrendsByMonthGraphDataYDomain: number[];
  setCostMetricsTrendsByMonthGraphDataYDomain: (costMetricsTrendsByMonthGraphDataYDomain: number[]) => void;

  costByProductGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[];
  setCostByProductGraphData: (costByProductGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => void;
  costByProductGraphDataXDomain: string[];
  setCostByProductGraphDataXDomain: (costByProductGraphDataXDomain: string[]) => void;
  costByProductGraphDataYDomain: number[];
  setCostByProductGraphDataYDomain: (costByProductGraphDataYDomain: number[]) => void;

  costByResourceTypeGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[];
  setcostByResourceTypeGraphData: (costByResourceTypeGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => void;
  costByResourceTypeGraphDataXDomain: string[];
  setcostByResourceTypeGraphDataXDomain: (costByResourceTypeGraphDataXDomain: string[]) => void;
  costByResourceTypeGraphDataYDomain: number[];
  setcostByResourceTypeGraphDataYDomain: (costByResourceTypeGraphDataYDomain: number[]) => void;

  costByApplicationGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[];
  setCostByApplicationGraphData: (costByApplicationGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => void;
  costByApplicationGraphDataXDomain: string[];
  setCostByApplicationGraphDataXDomain: (costByApplicationGraphDataXDomain: string[]) => void;
  costByApplicationGraphDataYDomain: number[];
  setCostByApplicationGraphDataYDomain: (costByApplicationGraphDataYDomain: number[]) => void;

  costMetricsDetailsTable: CostMetricsTableData;
  setCostMetricsDetailsTable: (costMetricsDetailsTable: CostMetricsTableData) => void;

  aggregatedCostByProductDetailsTable: CostMetricsTableData;
  setAggregatedCostByProductDetailsTable: (aggregatedCostByProductDetailsTable: CostMetricsTableData) => void;

  aggregatedCostByAccountDetailsTable: CostMetricsTableData;
  setAggregatedCostByAccountDetailsTable: (aggregatedCostByAccountDetailsTable: CostMetricsTableData) => void;

  aggregatedCostByApplicationsDetailsTable: CostMetricsTableData;
  setAggregatedCostByApplicationsDetailsTable: (aggregatedCostByApplicationsDetailsTable: CostMetricsTableData) => void;

  costMetricsSummaryContainer: CostMetricsSummaryContainer;
  setCostMetricsSummaryContainer: (costMetricsSummaryContainer: CostMetricsSummaryContainer) => void;
};

export const CostMetricsContext = createContext<CostMetricsContextType>({
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => {},

  costMetricsGraphStatus: 'pending',
  setCostMetricsGraphStatus: (status: StatusType) => {},

  costMetricsDetailsTabStatus: 'pending',
  setCostMetricsDetailsTabStatus: (status: StatusType) => {},

  costMetricsDetailsTabData: {} as CostMetricsDetailsTabData,
  setCostMetricsDetailsTabData: (costMetricsDetailsData: CostMetricsDetailsTabData) => {},

  costMetricsTrendsByMonthGraphData: [],
  setCostMetricsTrendsByMonthGraphData: (costMetricsTrendsByMonthGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  costMetricsTrendsByMonthGraphDataXDomain: [],
  setCostMetricsTrendsByMonthGraphDataXDomain: (costMetricsTrendsByMonthGraphDataXDomain: string[]) => {},
  costMetricsTrendsByMonthGraphDataYDomain: [],
  setCostMetricsTrendsByMonthGraphDataYDomain: (costMetricsTrendsByMonthGraphDataYDomain: number[]) => {},

  costByProductGraphData: [],
  setCostByProductGraphData: (costByProductGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => {},
  costByProductGraphDataXDomain: [],
  setCostByProductGraphDataXDomain: (costByProductGraphDataXDomain: string[]) => {},
  costByProductGraphDataYDomain: [],
  setCostByProductGraphDataYDomain: (costByProductGraphDataYDomain: number[]) => {},

  costByResourceTypeGraphData: [],
  setcostByResourceTypeGraphData: (costByResourceTypeGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => {},
  costByResourceTypeGraphDataXDomain: [],
  setcostByResourceTypeGraphDataXDomain: (costByResourceTypeGraphDataXDomain: string[]) => {},
  costByResourceTypeGraphDataYDomain: [],
  setcostByResourceTypeGraphDataYDomain: (costByResourceTypeGraphDataYDomain: number[]) => {},

  costByApplicationGraphData: [],
  setCostByApplicationGraphData: (costByApplicationGraphData: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => {},
  costByApplicationGraphDataXDomain: [],
  setCostByApplicationGraphDataXDomain: (costByApplicationGraphDataXDomain: string[]) => {},
  costByApplicationGraphDataYDomain: [],
  setCostByApplicationGraphDataYDomain: (costByApplicationGraphDataYDomain: number[]) => {},

  costMetricsDetailsTable: {} as CostMetricsTableData,
  setCostMetricsDetailsTable: (costMetricsDetailsTable: CostMetricsTableData) => {},

  aggregatedCostByProductDetailsTable: {} as CostMetricsTableData,
  setAggregatedCostByProductDetailsTable: (aggregatedCostByProductDetailsTable: CostMetricsTableData) => {},

  aggregatedCostByAccountDetailsTable: {} as CostMetricsTableData,
  setAggregatedCostByAccountDetailsTable: (aggregatedCostByAccountDetailsTable: CostMetricsTableData) => {},

  aggregatedCostByApplicationsDetailsTable: {} as CostMetricsTableData,
  setAggregatedCostByApplicationsDetailsTable: (aggregatedCostByApplicationsDetailsTable: CostMetricsTableData) => {},

  costMetricsSummaryContainer: {} as CostMetricsSummaryContainer,
  setCostMetricsSummaryContainer: (costMetricsSummaryContainer: CostMetricsSummaryContainer) => {}
});

export const useCostMetricsContext = () => useContext(CostMetricsContext);
