import { Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { eTicketLinks } from 'src/constants/AppConstants';
import { FeedbackModal } from './DFPFeedbackModal';

export const DFPFooter: React.FC = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const onSupportClick = () => {
    window.open(eTicketLinks.DAS_FINSUITE_GENERAL_ISSUE, '_blank');
  };

  const onConfirm = () => {
    setShowFeedbackModal(false);
  };

  return (
    <>
      <FeedbackModal showFeedbackModal={showFeedbackModal} onCancel={() => setShowFeedbackModal(false)} onConfirm={() => onConfirm()} />
      <div className="footer">
        <div className="footer-content">
          <Link color="inverted" variant="secondary" fontSize="body-s">
            <span onClick={() => setShowFeedbackModal(true)}>{'Feedback'}</span>
          </Link>
          <Link color="inverted" variant="secondary" fontSize="body-s" onFollow={() => onSupportClick()}>
            <SpaceBetween direction="horizontal" size="xs">
              <span>{'Support'}</span>
              <Icon name="external" size="small" />
            </SpaceBetween>
          </Link>
        </div>
      </div>
    </>
  );
};
