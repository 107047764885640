import { Box, Button, Container, Header, Multiselect, MultiselectProps, SpaceBetween } from '@amzn/awsui-components-react';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import React, { useEffect, useState } from 'react';
import { logger } from 'src/logger';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';
import { getCurrentTime, getTimeDifference } from 'src/utilities/DateTimeUtilities';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { filtersLabel } from '../FinTechOpsCommonElements';
import { getDashboardFilters, objectToOptions, optionsToObject, parseObjectToMultiSelectOptions_Sorted } from '../FinTechOpsDataProcessingFunctions';
import {
  applyDashboardFiltersPolicyEngineMetrics,
  createOpsPolicyEngineDashboardData,
  getDashboardFiltersFromPolicyEngine
} from '../policy-engine/PolicyEngineDataProcessing';
import { PolicyEngineParsedDataItem } from '../policy-engine/PolicyEngineInterface';
import { applyDashboardFiltersSAS, createOpsSasData } from '../sas/SasDataProcessing';
import { SasParsedData } from '../sas/SasInterfaces';
import { applyDashboardFiltersTickets, getDashboardFiltersFromTickets, getTicketsDashboardData } from '../tickets/TicketsDataProcessing';
import { TicketsDashboard, TicketsParsedDataItem } from '../tickets/TicketsInterfaces';
import {
  applyDashboardFiltersUserMetrics,
  createOpsUserMetricsData,
  getDashboardFiltersFromUserMetrics
} from '../user-metrics/UserMetricsDataProcessing';
import { UserMetricsParsedData } from '../user-metrics/UserMetricsInterfaces';
import { DashboardMasterFilters, PolicyEngineDashboardItems, SaSDashboardItems, UserMetricsDashboardItems } from './FinTechOpsDataInterfaces';
import styles from './styles.module.scss';
import { applyDogmaDashboardFiltering, createOpsDogmaDashboardData, getDashboardFiltersFromDogma } from '../dogma/DogmaDataProcessing';
import { DogmaDashboardItems, DogmaParsedData } from '../dogma/DogmaInterface';
import { applyCostMetricsDashboardFiltering, createOpsCostMetricsDashboardData } from '../cost-metrics/CostMetricsDataProcessing';
import { CostMetricsDashboardItems, CostMetricsParsedData } from '../cost-metrics/CostMetricsInterface';

export const FinTechOpsSelectionFilters: React.FC = () => {
  const finOpsContext = useFinTechContext();

  const [managers, setManagers] = useState<MultiselectProps.Options>([]);
  const [assignees, setAssignees] = useState<MultiselectProps.Options>([]);
  const [team, setTeam] = useState<MultiselectProps.Options>([]);

  const [masterFilters, setMasterFilters] = useState<DashboardMasterFilters>({} as DashboardMasterFilters);

  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    //Triggers call upon MultiSelectStatus data finish
    if (finOpsContext.dashboardFilterStatus == 'finished') {
      selectDashboardFiltersWithAllOptions();
      setIsResetFilter(false);
    }
  }, [finOpsContext.dashboardFilterStatus, isResetFilter]);

  //Sets all filters to select all upon initial load
  const selectDashboardFiltersWithAllOptions = () => {
    try {
      if (finOpsContext.dashboardFilterData.managers.length > 0) {
        const _team = objectToOptions(finOpsContext.dashboardFilterData.team_name);
        const _managers = objectToOptions(finOpsContext.dashboardFilterData.managers);
        const _assignees = objectToOptions(finOpsContext.dashboardFilterData.assignees);

        setAssignees(_assignees);
        setManagers(_managers);
        setTeam(_team);

        updateData(_team, _managers, _assignees);
      }
    } catch (error: any) {
      logger.error('Unable to update selected filters', error);
    }
  };

  const updateFilters = (
    ticketsData: TicketsParsedDataItem[],
    userMetricsData: UserMetricsParsedData[],
    sasData: SasParsedData[],
    policyEngineData: PolicyEngineParsedDataItem[],
    dogmaData: DogmaParsedData[],
    costMetricsData: CostMetricsParsedData[]
  ) => {
    const filterValues = getDashboardFilters(ticketsData, userMetricsData, sasData, policyEngineData, dogmaData, costMetricsData);
    setMasterFilters(filterValues);
    setAssignees(objectToOptions(filterValues.uniqueAssignees));
    setManagers(objectToOptions(filterValues.uniqueManagers));
    setTeam(objectToOptions(filterValues.uniqueTeams));
  };

  const updateData = (_team: MultiselectProps.Options, _managers: MultiselectProps.Options, _assignees: MultiselectProps.Options) => {
    try {
      const selectedTeamList: string[] = optionsToObject(_team);
      const selectedManagersList: string[] = optionsToObject(_managers);
      const selectedAssigneesList: string[] = optionsToObject(_assignees);

      const start = getCurrentTime();

      const appliedTicketsFilterData: TicketsParsedDataItem[] = applyDashboardFiltersTickets(finOpsContext.finTechOpsTicketsData.data, {
        team_name: selectedTeamList,
        managers: selectedManagersList,
        assignees: selectedAssigneesList
      });
      const calculatedDataTickets: TicketsDashboard = getTicketsDashboardData(appliedTicketsFilterData);
      finOpsContext.setFinTechOpsTicketDashboard(calculatedDataTickets);

      // User Metrics
      const appliedUserMetricsFilterData: UserMetricsParsedData[] = applyDashboardFiltersUserMetrics(finOpsContext.userMetricsWithUserData.data, {
        team_name: selectedTeamList,
        managers: selectedManagersList,
        assignees: selectedAssigneesList
      });
      const calculatedDataUserMetrics: UserMetricsDashboardItems = createOpsUserMetricsData(appliedUserMetricsFilterData);
      finOpsContext.setFinTechOpsUserMetricsDashBoard(calculatedDataUserMetrics);

      // SAS
      const appliedSasFilterData: SasParsedData[] = applyDashboardFiltersSAS(finOpsContext.sasData.data, {
        team_name: selectedTeamList,
        managers: selectedManagersList,
        assignees: selectedAssigneesList
      });
      const calculatedDataSas: SaSDashboardItems = createOpsSasData(appliedSasFilterData);
      finOpsContext.setFinTechOpsSasDashBoard(calculatedDataSas);

      const appliedPolicyEngineFilterData: PolicyEngineParsedDataItem[] = applyDashboardFiltersPolicyEngineMetrics(
        finOpsContext.finTechOpsPolicyEngineParsedData.data,
        {
          team_name: selectedTeamList,
          managers: selectedManagersList,
          assignees: selectedAssigneesList
        }
      );
      const calculatedDataPolicyEngine: PolicyEngineDashboardItems = createOpsPolicyEngineDashboardData(appliedPolicyEngineFilterData);
      finOpsContext.setFinTechOpsPolicyEngineDashboardData(calculatedDataPolicyEngine);

      const appliedDogmaFilterData: DogmaParsedData[] = applyDogmaDashboardFiltering(finOpsContext.finTechOpsDogmaParsedData.data, {
        team_name: selectedTeamList,
        managers: selectedManagersList,
        assignees: selectedAssigneesList
      });
      const calculatedDogmaData: DogmaDashboardItems = createOpsDogmaDashboardData(appliedDogmaFilterData);
      finOpsContext.setFinTechOpsDogmaDashboardData(calculatedDogmaData);

      // Cost Metrics
      const appliedCostMetricsFilterData: CostMetricsParsedData[] = applyCostMetricsDashboardFiltering(
        finOpsContext.finTechOpsCostMetricsParsedData.data,
        {
          team_name: selectedTeamList,
          managers: selectedManagersList,
          assignees: selectedAssigneesList
        }
      );
      const costMetricsMonthList = finOpsContext.finTechOpsCostMetricsMonthList;
      const calculatedDataCostMetrics: CostMetricsDashboardItems = createOpsCostMetricsDashboardData(
        appliedCostMetricsFilterData,
        costMetricsMonthList
      );
      finOpsContext.setFinTechOpsCostMetricsDashboardData(calculatedDataCostMetrics);

      updateFilters(
        appliedTicketsFilterData,
        appliedUserMetricsFilterData,
        appliedSasFilterData,
        appliedPolicyEngineFilterData,
        appliedDogmaFilterData,
        appliedCostMetricsFilterData
      );

      const elapsed = getTimeDifference(start);
      logger.info(`Calculated dashboard data for FinOps in ${elapsed} ms`);

      finOpsContext.setFinTechOpsTicketDataStatus('finished');
      finOpsContext.setDashboardFilterStatus('finished');
      finOpsContext.setFinTechOpsUserMetricsDataStatus('finished');
      finOpsContext.setFinTechOpsSasDataStatus('finished');
      finOpsContext.setFinTechOpsDogmaDataStatus('finished');
      finOpsContext.setFinTechOpsPolicyEngineDataStatus('finished');
      finOpsContext.setFinTechOpsCostMetricsDataStatus('finished');
    } catch (error: any) {
      logger.error('Unable to update data for Ops Page', error);
      finOpsContext.setFinTechOpsTicketDataStatus('error');
      finOpsContext.setDashboardFilterStatus('error');
      finOpsContext.setFinTechOpsUserMetricsDataStatus('error');
      finOpsContext.setFinTechOpsSasDataStatus('error');
      finOpsContext.setFinTechOpsDogmaDataStatus('error');
      finOpsContext.setFinTechOpsPolicyEngineDataStatus('error');
      finOpsContext.setFinTechOpsCostMetricsDataStatus('error');
    }
  };

  //Determines if API should be called based on selection change
  const triggerCall = () => {
    finOpsContext.setDashboardFilterStatus('loading');
    updateData(team, managers, assignees);
  };

  return (
    <div>
      <SpaceBetween size="l">
        <Box padding={{ top: 'xl' }}>
          <Container
            header={
              <SpaceBetween size="m" direction="horizontal" alignItems="center">
                <Header variant="h2">FinTech Ops</Header>
                <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
              </SpaceBetween>
            }
          >
            <Box>
              <div className={styles.multiselectContainer}>
                <div className={styles.multiselect}>
                  <FormField label={filtersLabel('Teams')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(team, 'Teams', finOpsContext.dashboardFilterData.team_name)}
                      options={parseObjectToMultiSelectOptions_Sorted(masterFilters.uniqueTeams, 'All Teams')}
                      selectedOptions={team}
                      onChange={({ detail }) => setTeam(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Teams"
                      errorText="Unable to load data"
                      statusType={finOpsContext.dashboardFilterStatus}
                      disabled={finOpsContext.dashboardFilterDataCalculationStatus === 'loading' ? true : false}
                    />
                  </FormField>
                </div>
                <div className={styles.multiselect}>
                  <FormField label={filtersLabel('Managers')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(managers, 'Managers', finOpsContext.dashboardFilterData.managers)}
                      options={parseObjectToMultiSelectOptions_Sorted(masterFilters.uniqueManagers, 'All Managers')}
                      selectedOptions={managers}
                      onChange={({ detail }) => setManagers(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Managers"
                      errorText="Unable to load data"
                      statusType={finOpsContext.dashboardFilterStatus}
                      disabled={finOpsContext.dashboardFilterDataCalculationStatus === 'loading' ? true : false}
                    />
                  </FormField>
                </div>
                <div className={styles.multiselect}>
                  <FormField label={filtersLabel('Assignees')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(assignees, 'Assignees', finOpsContext.dashboardFilterData.assignees)}
                      options={parseObjectToMultiSelectOptions_Sorted(masterFilters.uniqueAssignees, 'All Assignees')}
                      selectedOptions={assignees}
                      onChange={({ detail }) => setAssignees(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Assignees"
                      errorText="Unable to load data"
                      statusType={finOpsContext.dashboardFilterStatus}
                      disabled={finOpsContext.dashboardFilterDataCalculationStatus === 'loading' ? true : false}
                    />
                  </FormField>
                </div>
              </div>
            </Box>
          </Container>
        </Box>
      </SpaceBetween>
    </div>
  );
};
