import { TicketsDashboard } from './tickets/TicketsInterfaces';

export const GRAPH_WIDTHS_BREAK_POINTS = { default: 12, xxs: 12, xs: 12, s: 6, m: 4, l: 4, xl: 4 };

//Convert link to hyperlink with text
export enum FinTechOpsMessages {
  APIError = 'Error fetching information, please refresh your page to try again',
  APIErrorDetailsTab = 'Error fetching details information, please refresh your page to try again',
  APIErrorOverviewTab = 'Error fetching overview information, please refresh your page to try again',
  dataProcessingError = 'Data Processing Error',
  dataProcessingErrorDescription = 'Please submit a ticket',
  success = '',
  errorText = 'Sorry, information unable to load',
  emptyStateMessageDescription = 'Data Not Found',
  emptyStateMessage = `We can't find a match to your selected filters`,
  noMatchMessage = `Data Does Not Exist`,

  errorContainerMain = `Connection error`,
  errorContainerDescription = `The data couldn't be fetched.`,

  nonInitializedMessageMain = `Please wait while we initialize the application`,
  nonInitializedMessageDescription = `This will only take a second`,
  S3APIError = 'Error fetching information, please cache refresh your page to try again'
}

export const POLICY_ENGINE_URL = 'https://policyengine.amazon.com/entity/';
export const FINTECH_MONTH_COUNT = 6;
export const OPS_TIME_PERIOD = `${FINTECH_MONTH_COUNT} months`;

export const FinTechOpsMetricsDescriptions = {
  totalTickets: `Total ticket count in last ${OPS_TIME_PERIOD}`,
  ticketsSolvedTickets: `Total Resolved ticket count in last ${OPS_TIME_PERIOD}`,
  ticketsUnsolvedTickets: `Total Open ticket count in last ${OPS_TIME_PERIOD}`,
  ticketsResolutionRate: `Percentage of Resolved tickets by Total tickets in last ${OPS_TIME_PERIOD}`,
  ticketsFirstResolutionTimeAvg: `Median between First resolved and ticket creation time for last ${OPS_TIME_PERIOD}`,
  ticketsFirstReplyTimeAvg: `Average time between First assigned and ticket creation time for last ${OPS_TIME_PERIOD}`,

  userMetricsTotalApplications: `Total applications (Excluding common & skynet) used in last ${OPS_TIME_PERIOD}`,
  userMetricsAverageViewership: `Avg Viewership count per application (Excluding common & skynet) in last ${OPS_TIME_PERIOD}`,
  currentMontnTopAppWithUniqueUsers: `Application (Excluding common & skynet) with most unique users in current month`,
  currentMontnTopAppWithUsers: `Most viewed Application (Excluding common & skynet) With highest view in current month`,

  sasTotalRisk: `Total SAS risks in last ${OPS_TIME_PERIOD}`,
  sasSolved: `Total resolved SAS risks in last ${OPS_TIME_PERIOD}`,
  sasUnsolved: `Total unresolved SAS risks in last ${OPS_TIME_PERIOD}`,
  sasOpenWithRecommendation: `Total Open SAS risks with recommendation in last ${OPS_TIME_PERIOD}`,
  sasOpenWithoutRecommendation: `Total Open SAS risks with no recommendation in last ${OPS_TIME_PERIOD}`,
  sasAverageRecommendationAge: `Average of recommendation age in last ${OPS_TIME_PERIOD} for all SAS risks`,
  sasResolutionRate: `Solved SAS risks percentage for ${OPS_TIME_PERIOD}`,

  policyEngineTotal: `Total PE risks in last ${OPS_TIME_PERIOD}`,
  policyEngineSolved: `Total resolved policy engine risks in last ${OPS_TIME_PERIOD}`,
  policyEngineUnsolved: `Total open policy engine risks in last ${OPS_TIME_PERIOD}`,
  totalOpenCriticalRisks: `Total open policy engine risks with critical severity in last ${OPS_TIME_PERIOD}`,
  resolutionRate: `Solved Policy engine risks percentage for ${OPS_TIME_PERIOD}`,

  dogmaTotalRisks: `Total Dogma violations in last ${OPS_TIME_PERIOD}`,
  dogmaSolved: `Total resolved Dogma risks in last ${OPS_TIME_PERIOD}`,
  dogmaUnsolved: `Total open Dogma risks in last ${OPS_TIME_PERIOD}`,
  dogmaTotalOpenHighRisks: `Total open Dogma violations risks with High severity in last ${OPS_TIME_PERIOD}`,
  dogmaResolutionRate: `Solved Dogma risks percentage in last ${OPS_TIME_PERIOD}`,

  riskRemediatorTotalRisk: 'Total risks identified by Remediator ',
  riskRemediatorOpenRisk: 'Total risks waiting for developer interruption',
  riskRemediatorTotalRemediatedRisk: 'Total risks auto resolved by Remediator',
  riskRemediatorDeveloperRemediatedRisk: 'Total risks identified by remediator and fixed by developer',
  riskRemediatorRemediationRate: 'Percentage of risks auto resolved compared to total risks identified by the Remediator',
  riskRemediatorASRMetrics: 'Percentage of certified ASR applications',

  costMetricsTotalCurrentMonthCost: 'Total cost across products in current month',
  costMetricsTotalPreviousMonthCost: 'Total cost across products in previous month',
  costMetricsTopProductContributingToCost: 'Top product contributing to cost in current month',
  costMetricsAvgMonthlyCost: `Average monthly cost in last ${OPS_TIME_PERIOD}`
};

export const ALL_APPLICATIONS = 'All Applications';
export const SKYNET_ALL = 'Skynet All';
export const SKYNET = 'Skynet';
export enum OEEmptyStringMappings {
  Generic_Mapping = 'Unassigned',
  Unassigned_Manager = 'Unassigned Manager',
  Unassigned_Manager_Login = 'Unassigned Manager Login',
  Unassigned_Login = 'Unassigned Login',
  Unassigned_Team = 'Unassigned Team',
  Unassigned_Application = 'Unassigned Application',
  Not_Entered = 'NOT ENTERED',
  Has_Recommendation = 'Has Recommendation',
  No_Recommendation = 'No Recommendation',
  Blocked = 'Blocked',
  NonBlocked = 'Non Blocked'
}

export enum FinTechOpsHref {
  finTechOps = '/fintech-ops',
  tickets = '/fintech-ops/tickets',
  sas = '/fintech-ops/sas',
  userMetrics = '/fintech-ops/user-metrics',
  policyEngine = '/fintech-ops/policyEngine',
  riskRemediator = '/fintech-ops/risk-remediator',
  dogma = '/fintech-ops/dogma',
  costMetrics = '/fintech-ops/cost-metrics'
}

export enum FinTechOpsCategories {
  tickets = 'Tickets',
  sas = 'SAS Risk Tool',
  userMetrics = 'User Metrics',
  policyEngine = 'Policy Engine',
  riskRemediator = 'Risk Remediator',
  dogma = 'Dogma',
  costMetrics = 'Cost Metrics'
}

export enum FinTechOpsCategoriesLabelColors {
  tickets = 'green',
  sas = 'blue',
  userMetrics = 'grey',
  policyEngine = 'red',
  dogma = 'green',
  costMetrics = 'grey'
}

export const InitialTicketsTabData: TicketsDashboard = {
  totalTicketsCount: 0,
  solvedTicketCount: 0,
  unSolvedTicketCount: 0,
  resolutionRate: 0,
  medianResolutionTimeInMinutes: 0,
  firstResponseTimeAvgInMinutes: 0
};

export const InitialSasTabData = {
  total_sas_risk: 0,
  solved: 0,
  unsolved: 0,
  open_with_recommendation: 0,
  open_without_recommendation: 0,
  avg_with_recommendation_age: 0,
  resolution_rate: 0
};

export const InitialUserMetricsTabData = {
  total_applications: 0,
  avg_view_per_application: '',
  app_most_unique_users: '',
  most_viewed_application: ''
};

export const GraphDateFormat_MMM_YY = 'MMM-YY';

export const FilterDateFormat_MMM_YYYY = 'MMM-YYYY';

export const FunctionDateFormat_YYYYMM = 'YYYYMM';

export const BaseDayFormat_YYYY_MM_DD = 'YYYY-MM-DD';

//TODO - change this to be based on variables from '~@amzn/awsui-design-tokens/polaris'
export const green = '#2ea597'; //green
export const blue = '#688ae8'; //blue
export const orange = '#e07941'; //orange
export const red = '#c33d69'; //red

export const USER_METRICS_S3_PREFIX = 'user_metrics';

export const TICKETS_S3_PREFIX = 'sim_tickets';

export const POLICY_ENGINE_S3_PREFIX = 'policy_engine_datalake';

export const DOGMA_S3_PREFIX = 'dogma-data';

export const SAS_S3_PREFIX = 'sasviolations';

export const COST_METRICS_S3_PREFIX = 'cost_metrics';

export const USER_METRICS_LAST_UPDATED_AT_S3_PREFIX = 'last-updated-at/user_metrics';

export const TICKETS_LAST_UPDATED_AT_S3_PREFIX = 'last-updated-at/sim_tickets';

export const POLICY_ENGINE_LAST_UPDATED_AT_S3_PREFIX = 'last-updated-at/policy_engine_datalake';

export const DOGMA_LAST_UPDATED_AT_S3_PREFIX = 'last-updated-at/dogma-data';

export const SAS_LAST_UPDATED_AT_S3_PREFIX = 'last-updated-at/sasviolations';

export const COST_METRICS_LAST_UPDATED_AT_S3_PREFIX = 'last-updated-at/cost_metrics';
