import React, { useEffect, useState } from 'react';
import {
  AppLayout,
  Box,
  ColumnLayout,
  Container,
  Flashbar,
  FlashbarProps,
  FormField,
  Link,
  Select,
  SelectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { DFPHomePageSideNavigation } from '../../dfp-home-page/DFPHomePageSideNavigation';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { FinTechOpsHref, FinTechOpsMessages } from '../FinTechOpsConstants';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { objectToOptions } from '../FinTechOpsDataProcessingFunctions';
import { filtersLabel } from '../FinTechOpsCommonElements';
import { API } from 'aws-amplify';
import { recordApiRequest } from 'src/analytics/CloudWatchRumClient';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import { logger } from 'src/logger';
import { getCurrentTime, getTimeDifference } from 'src/utilities/DateTimeUtilities';
import * as queries from 'src/graphql/queries';
import { MappingConfig } from './MappingInterface';
import { EmptyState } from 'src/components/generic-components/empty-state';
import { MappingTable } from './MappingTable';
import { EmptyStateMsg } from './MappingConstants';

export function MappingMainPage() {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [lastRefreshed, setLastRefreshed] = useState('');
  const [mappingTableSelected, setMappingTableSelected] = useState<SelectProps.Option>({ label: 'Select Mapping Tables', value: '' });

  const [mappingConfigSelectData, setMappingConfigSelectData] = useState([]);

  const [mappingConfigData, setMappingConfigData] = useState<MappingConfig[]>([]);

  const [selectedConfig, setSelectedConfig] = useState('');

  const updateLastRefreshed = (val?: string) => {
    if (val) {
      setLastRefreshed(val);
    }
  };

  //Function to update the mapping selection
  const updateMappingTableSelected = (detail: SelectProps.ChangeDetail) => {
    setMappingTableSelected(detail.selectedOption);
    setLastRefreshed('');
  };

  //Setting the selectedConfig value based on change of value of drop down
  useEffect(() => {
    const selectedValue = mappingConfigData.find((item) => item.mapping_name === mappingTableSelected.value);
    setSelectedConfig(selectedValue?.mapping_key || '');
  }, [mappingTableSelected.value]);

  //one time retrieval of mapping config data
  useEffect(() => {
    getMappingConfig();
  }, []);

  //Function to retrieve the mapping config for the drop down
  const getMappingConfig = async () => {
    try {
      const start = getCurrentTime();
      const mappingConfigResponse: any = await API.graphql({
        query: queries.listMappingConfig,
        variables: {}
      });

      const elapsed = getTimeDifference(start);
      logger.info(`Fetched Mapping Config Data in ${elapsed} ms`);
      recordApiRequest('listMappingConfig', elapsed);
      setMappingConfigSelectData(
        mappingConfigResponse.data.listMappingConfig.mapping_config.data.map((item: { mapping_name: string }) => item.mapping_name)
      );
      setMappingConfigData(mappingConfigResponse.data.listMappingConfig.mapping_config.data);
    } catch (error: any) {
      logger.error('Unable to load Mapping Config data', error);
      displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            submit a ticket
          </Link>
        );
      }
    }

    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  //Function will dismiss notification
  const dismissNotification = () => {
    setFlashbarItems([]);
  };

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={flashbarItems} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: FinTechOpsHref.finTechOps },
            {
              text: 'Ops Mapping',
              href: ''
            }
          ]}
        />
      }
      maxContentWidth={Number.MAX_VALUE}
      content={
        <>
          <Box padding={{ top: 'xl' }}>
            <Container
              fitHeight
              header={
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Box variant="h2">Ops Mapping</Box>
                  <LastRefreshedAt lastRefreshedDateTime={lastRefreshed} />
                </SpaceBetween>
              }
            >
              <SpaceBetween size="m" direction="vertical">
                <ColumnLayout columns={3}>
                  <FormField label={filtersLabel('Mapping Config')}>
                    <Select
                      placeholder={'Select Mapping'}
                      onChange={({ detail }) => updateMappingTableSelected(detail)}
                      options={objectToOptions(mappingConfigSelectData)}
                      selectedOption={mappingTableSelected}
                      expandToViewport
                      loadingText="Loading Mapping Options"
                      errorText="Unable to load Mapping Options"
                    />
                  </FormField>
                </ColumnLayout>
              </SpaceBetween>
            </Container>
          </Box>

          <Box padding={{ top: 'xl' }}>
            {selectedConfig ? (
              <MappingTable
                lastRefreshedVal={updateLastRefreshed}
                mappingConfig={selectedConfig}
                mappingName={mappingTableSelected.value}
                notifyUser={(status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string, dismiss?: boolean) => {
                  displayNotification(status, message, linkMessage);
                }}
                resetNotification={dismissNotification}
              />
            ) : (
              <EmptyState title={EmptyStateMsg.Title} description={EmptyStateMsg.Description} verticalCenter={true} />
            )}{' '}
          </Box>
        </>
      }
    />
  );
}
