import { FlashbarProps, MixedLineBarChartProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import { StatusType } from '../sas/SasInterfaces';
import { UserMetricsAggregatedViewsTable, UserMetricsApplicationFootprintContainer } from './UserMetricsInterfaces';

export type UserMetricsContextType = {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => void;
  multiselectStatus: StatusType;
  setMultiselectStatus: (status: StatusType) => void;

  overViewStatus: StatusType;
  setOverViewStatus: (status: StatusType) => void;

  detailsStatus: StatusType;
  setDetailsStatus: (status: StatusType) => void;

  //graphs
  //1
  appViewershipByTeamData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[];
  setAppViewershipByTeamData: (appViewershipByTeamData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void;
  appViewershipByTeamXDomain: string[];
  setAppViewershipByTeamXDomain: (appViewershipByTeamXDomain: string[]) => void;
  appViewershipByTeamYDomain: number[];
  setAppViewershipByTeamYDomain: (appViewershipByTeamYDomain: number[]) => void;

  //2
  top5AppsViewership: MixedLineBarChartProps.LineDataSeries<string | number | Date>[];
  setTop5AppsViewership: (top5AppsViewership: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => void;
  top5AppsViewershipXDomain: string[];
  setTop5AppsViewershipXDomain: (top5AppsViewershipXDomain: string[]) => void;
  top5AppsViewershipYDomain: number[];
  setTop5AppsViewershipYDomain: (top5AppsViewershipYDomain: number[]) => void;

  //3
  uniqueUsersByApplication: MixedLineBarChartProps.LineDataSeries<string | number | Date>[];
  setUniqueUsersByApplication: (uniqueUsersByApplication: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => void;
  uniqueUsersByApplicationXDomain: string[];
  setUniqueUsersByApplicationXDomain: (uniqueUsersByApplicationXDomain: string[]) => void;
  uniqueUsersByApplicationYDomain: number[];
  setUniqueUsersByApplicationYDomain: (uniqueUsersByApplicationYDomain: number[]) => void;

  //4
  totalApplicationViews: MixedLineBarChartProps.LineDataSeries<string | number | Date>[];
  setTotalApplicationViews: (totalApplicationViews: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => void;
  totalApplicationViewsXDomain: string[];
  setTotalApplicationViewsXDomain: (totalApplicationViewsXDomain: string[]) => void;
  totalApplicationViewsYDomain: number[];
  setTotalApplicationViewsYDomain: (totalApplicationViewsYDomain: number[]) => void;

  //Tables for Details
  aggregatedViewsTableData: UserMetricsAggregatedViewsTable;
  setAggregatedViewsTableData: (aggregatedViewsTableData: UserMetricsAggregatedViewsTable) => void;

  applicationUsersTableData: UserMetricsAggregatedViewsTable;
  setApplicationUsersTableData: (applicationViewsTableData: UserMetricsAggregatedViewsTable) => void;

  aggregatedSubApplicationViewsTableData: UserMetricsAggregatedViewsTable;
  setAggregatedSubApplicationViewsTableData: (aggregatedSubApplicationViewsTableData: UserMetricsAggregatedViewsTable) => void;

  aggregatedSubApplicationUniqueUsersTableData: UserMetricsAggregatedViewsTable;
  setAggregatedSubApplicationUniqueUsersTableData: (aggregatedSubApplicationUniqueUsersTableData: UserMetricsAggregatedViewsTable) => void;

  applicationFootprintTableData: UserMetricsAggregatedViewsTable;
  setApplicationFootprintTableData: (applicationFootprintTableData: UserMetricsAggregatedViewsTable) => void;

  subApplicationFootprintTableData: UserMetricsAggregatedViewsTable;
  setSubApplicationFootprintTableData: (applicationFootprintTableData: UserMetricsAggregatedViewsTable) => void;

  applicationFootprintContainer: UserMetricsApplicationFootprintContainer;
  setApplicationFootprintContainer: (applicationFootprintContainer: UserMetricsApplicationFootprintContainer) => void;

  applicationUserViewTableData: UserMetricsAggregatedViewsTable;
  setApplicationUserViewTableData: (applicationuserViewTableData: UserMetricsAggregatedViewsTable) => void;

  subApplicationUserViewTableData: UserMetricsAggregatedViewsTable;
  setSubApplicationUserViewTableData: (subApplicationUserViewTableData: UserMetricsAggregatedViewsTable) => void;
};

export const UserMetricsContext = createContext<UserMetricsContextType>({
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => {},
  multiselectStatus: 'pending',
  setMultiselectStatus: (status: StatusType) => {},

  overViewStatus: 'pending',
  setOverViewStatus: (status: StatusType) => {},

  detailsStatus: 'pending',
  setDetailsStatus: (status: StatusType) => {},

  appViewershipByTeamData: [],
  setAppViewershipByTeamData: (appViewershipByTeamData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  appViewershipByTeamXDomain: [],
  setAppViewershipByTeamXDomain: (appViewershipByTeamXDomain: string[]) => {},
  appViewershipByTeamYDomain: [],
  setAppViewershipByTeamYDomain: (appViewershipByTeamYDomain: number[]) => {},
  top5AppsViewership: [],
  setTop5AppsViewership: (top5AppsViewership: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => {},
  top5AppsViewershipXDomain: [],
  setTop5AppsViewershipXDomain: (top5AppsViewershipXDomain: string[]) => {},
  top5AppsViewershipYDomain: [],
  setTop5AppsViewershipYDomain: (top5AppsViewershipYDomain: number[]) => {},
  uniqueUsersByApplication: [],
  setUniqueUsersByApplication: (uniqueUsersByApplication: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => {},
  uniqueUsersByApplicationXDomain: [],
  setUniqueUsersByApplicationXDomain: (uniqueUsersByApplicationXDomain: string[]) => {},
  uniqueUsersByApplicationYDomain: [],
  setUniqueUsersByApplicationYDomain: (uniqueUsersByApplicationYDomain: number[]) => {},
  totalApplicationViews: [],
  setTotalApplicationViews: (totalApplicationViews: MixedLineBarChartProps.LineDataSeries<string | number | Date>[]) => {},
  totalApplicationViewsXDomain: [],
  setTotalApplicationViewsXDomain: (totalApplicationViewsXDomain: string[]) => {},
  totalApplicationViewsYDomain: [],
  setTotalApplicationViewsYDomain: (totalApplicationViewsYDomain: number[]) => {},
  aggregatedViewsTableData: {} as UserMetricsAggregatedViewsTable,
  setAggregatedViewsTableData: (aggregatedViewsTableData: UserMetricsAggregatedViewsTable) => {},
  applicationUsersTableData: {} as UserMetricsAggregatedViewsTable,
  setApplicationUsersTableData: (applicationViewsTableData: UserMetricsAggregatedViewsTable) => {},

  aggregatedSubApplicationViewsTableData: {} as UserMetricsAggregatedViewsTable,
  setAggregatedSubApplicationViewsTableData: (aggregatedSubApplicationViewsTableData: UserMetricsAggregatedViewsTable) => {},

  aggregatedSubApplicationUniqueUsersTableData: {} as UserMetricsAggregatedViewsTable,
  setAggregatedSubApplicationUniqueUsersTableData: (aggregatedSubApplicationUniqueUsersTableData: UserMetricsAggregatedViewsTable) => {},

  applicationFootprintTableData: {} as UserMetricsAggregatedViewsTable,
  setApplicationFootprintTableData: (applicationFootprintTableData: UserMetricsAggregatedViewsTable) => {},

  subApplicationFootprintTableData: {} as UserMetricsAggregatedViewsTable,
  setSubApplicationFootprintTableData: (subApplicationFootprintTableData: UserMetricsAggregatedViewsTable) => {},

  applicationFootprintContainer: {} as UserMetricsApplicationFootprintContainer,
  setApplicationFootprintContainer: (applicationFootprintContainer: UserMetricsApplicationFootprintContainer) => {},
  applicationUserViewTableData: {} as UserMetricsAggregatedViewsTable,
  setApplicationUserViewTableData: (applicationuserViewTableData: UserMetricsAggregatedViewsTable) => {},

  subApplicationUserViewTableData: {} as UserMetricsAggregatedViewsTable,
  setSubApplicationUserViewTableData: (applicationuserViewTableData: UserMetricsAggregatedViewsTable) => {}
});

export const useUserMetricsContext = () => useContext(UserMetricsContext);
