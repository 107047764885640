import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import WorkInProgress from 'src/components/generic-components/WorkInProgress';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import React, { useEffect, useState } from 'react';
import { Grid } from '@amzn/awsui-components-react';
import { usePolicyEngineContext } from './PolicyEngineContext';
import { BarLineChartWithProps } from '../fintech-ops-charts/BarChartWithProps';
import { CreateBarChartData, CreateLineChartData } from '../FinTechOpsDataProcessingFunctions';
import { FinTechOpsMessages, GRAPH_WIDTHS_BREAK_POINTS } from '../FinTechOpsConstants';
import { i18nStringHour } from '../i18nStrings';
import { EmptyStateMessage } from '../EmptyStateMessage';
import { GenericBar, GenericLine } from '../sas/SasInterfaces';
import { BarChartSingleDataSeries } from 'src/components/graph-components/BarChartSingleDataSeries';

const EMPTY_BAR: GenericBar = { dataSeries: [], xDomain: [], yDomain: [] };
const EMPTY_LINE: GenericLine = { dataSeries: [], xDomain: [], yDomain: [] };

export const PolicyEngineOverview: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const policyEngineContext = usePolicyEngineContext();

  const [totalPERicksByMonthGraph, setTotalPERicksByMonthGraph] = useState<GenericBar>(EMPTY_BAR);
  const [totalOpenRisksByResolutionType, setTotalOpenRisksByResolutionType] = useState<GenericBar>(EMPTY_BAR);
  const [TotalOpenRisksByTeam, setTotalOpenRisksByTeam] = useState<GenericBar>(EMPTY_BAR);
  const [TotalOpenRisksBySeverityType, setTotalOpenRisksBySeverityType] = useState<GenericBar>(EMPTY_BAR);
  const [TotalOpenRisksByPolicyClass, setTotalOpenRisksByPolicyClass] = useState<GenericBar>(EMPTY_BAR);
  const [TotalOpenRisksByAWSAccount, setTotalOpenRisksByAWSAccount] = useState<GenericLine>(EMPTY_LINE);

  useEffect(() => {
    if (finOpsContext.finTechOpsPolicyEngineDataStatus == 'finished' && policyEngineContext.policyEngineGraphStatus == 'finished') {
      setTotalPERicksByMonthGraph(CreateBarChartData(policyEngineContext?.policyEngineGraphData?.TotalPERisksByMonth || []));
      setTotalOpenRisksByResolutionType(CreateBarChartData(policyEngineContext?.policyEngineGraphData?.TotalOpenRisksByResolutionType || []));
      setTotalOpenRisksByTeam(CreateBarChartData(policyEngineContext?.policyEngineGraphData?.TotalOpenRisksByTeam || []));
      setTotalOpenRisksBySeverityType(CreateBarChartData(policyEngineContext?.policyEngineGraphData?.TotalOpenRisksBySeverityType || []));
      setTotalOpenRisksByPolicyClass(CreateBarChartData(policyEngineContext?.policyEngineGraphData?.TotalOpenRisksByPolicyClass || []));
      setTotalOpenRisksByAWSAccount(CreateLineChartData(policyEngineContext?.policyEngineGraphData?.TotalOpenRisksByAWSAccount || []));
    }
  }, [finOpsContext, policyEngineContext]);

  return (
    <div>
      {(finOpsContext.finTechOpsPolicyEngineDataStatus == 'loading' || policyEngineContext.policyEngineGraphStatus == 'loading') && (
        <LoadingSpinner />
      )}
      {(finOpsContext.finTechOpsPolicyEngineDataStatus == 'error' || policyEngineContext.policyEngineGraphStatus == 'error') && <ErrorContainer />}
      {(finOpsContext.finTechOpsPolicyEngineDataStatus == 'pending' || policyEngineContext.policyEngineGraphStatus == 'pending') && (
        <NotInitializedContainer />
      )}
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'finished' && policyEngineContext.policyEngineGraphStatus == 'finished' && (
        <Grid
          gridDefinition={[
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS }
          ]}
        >
          {/* 1  */}
          <BarLineChartWithProps
            headerName="Total Risks by Month"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={totalPERicksByMonthGraph?.dataSeries}
            xDomain={totalPERicksByMonthGraph?.xDomain}
            yDomain={totalPERicksByMonthGraph?.yDomain}
            arialLabel="Total Risks by Month"
            xTitle="Month"
            yTitle="Total risks"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Policy Engine')}
            emptyMessage={<EmptyStateMessage title={''} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
          {/* 2  */}
          <BarLineChartWithProps
            headerName="Total Open Risks by Resolution Type"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={totalOpenRisksByResolutionType?.dataSeries}
            xDomain={totalOpenRisksByResolutionType?.xDomain}
            yDomain={totalOpenRisksByResolutionType?.yDomain}
            arialLabel="Total Open Risks by Resolution Type"
            xTitle="Month"
            yTitle="Total risks"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Policy Engine')}
            emptyMessage={<EmptyStateMessage title={''} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
          {/* 3  */}
          <BarLineChartWithProps
            headerName="Total Open Risks by Team"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={TotalOpenRisksByTeam?.dataSeries}
            xDomain={TotalOpenRisksByTeam?.xDomain}
            yDomain={TotalOpenRisksByTeam?.yDomain}
            arialLabel="Total Open Risks by Team"
            xTitle="Month"
            yTitle="Total open risks"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Policy Engine')}
            emptyMessage={<EmptyStateMessage title={''} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
          {/* 4  */}
          <BarLineChartWithProps
            headerName="Total Open Risks by Severity Type"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={TotalOpenRisksBySeverityType?.dataSeries}
            xDomain={TotalOpenRisksBySeverityType?.xDomain}
            yDomain={TotalOpenRisksBySeverityType?.yDomain}
            arialLabel="Total Open Risks by Severity Type"
            xTitle="Month"
            yTitle="Total open risks"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Policy Engine')}
            emptyMessage={<EmptyStateMessage title={''} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
          {/* 5  */}
          <BarLineChartWithProps
            headerName="Total Open Risks by Policy Class"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={TotalOpenRisksByPolicyClass?.dataSeries}
            xDomain={TotalOpenRisksByPolicyClass?.xDomain}
            yDomain={TotalOpenRisksByPolicyClass?.yDomain}
            arialLabel="Total Open Risks by Policy Class"
            xTitle="Month"
            yTitle="Total open risks"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Policy Engine')}
            emptyMessage={<EmptyStateMessage title={''} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
        </Grid>
      )}
    </div>
  );
};
