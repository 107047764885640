import { Icon, Link, TableProps } from '@amzn/awsui-components-react';
import * as React from 'react';
import { getMinutesAgoDetails } from 'src/utilities/DateTimeUtilities';
import { DogmaParsedData } from './DogmaInterface';

export const DOGMA_DETAILS_ISSUES_VISIBLE_CONTENT = [
  'dogma_assignee_name',
  'team_name',
  'risk_entity_type',
  'risk_pipeline_name',
  'risk_rule_id',
  'risk_severity',
  'violation_age_in_minutes',
  'status'
];

export const DOGMA_DETAILS_TABLE: TableProps.ColumnDefinition<DogmaParsedData>[] = [
  {
    id: 'dogma_assignee_name',
    header: 'Owner',
    sortingField: 'dogma_assignee_name',
    cell: (item: DogmaParsedData) => item.dogma_assignee_name,
    isRowHeader: true
  },
  {
    id: 'team_name',
    header: 'Team',
    sortingField: 'team_name',
    cell: (item: DogmaParsedData) => item.team_name
  },
  {
    id: 'risk_entity_type',
    header: 'Entity',
    sortingField: 'risk_entity_type',
    cell: (item: DogmaParsedData) => item.risk_entity_type
  },
  {
    id: 'risk_pipeline_name',
    header: 'Pipeline',
    sortingField: 'risk_pipeline_name',
    cell: (item: DogmaParsedData) => item.risk_pipeline_name
  },
  {
    id: 'risk_rule_id',
    header: 'Rule',
    sortingField: 'risk_rule_id',
    cell: (item: DogmaParsedData) => item.risk_rule_id
  },
  {
    id: 'risk_severity',
    header: 'Severity',
    sortingField: 'risk_severity',
    cell: (item: DogmaParsedData) => item.risk_severity
  },
  {
    id: 'violation_age_in_minutes',
    header: 'Risk since',
    sortingField: 'violation_age_in_minutes',
    cell: (item: DogmaParsedData) => getMinutesAgoDetails(item.violation_age_in_minutes)
  },
  {
    id: 'status',
    header: 'Status',
    sortingField: 'status',
    cell: (item: DogmaParsedData) => item.status
  }
];
