import React, { useState } from 'react';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { RemoveDialog } from './remove-dialog';
import { boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import { WidgetDataType } from 'src/components/das-finsuite/widgets/interface';

interface BaseWidgetProps {
  config: WidgetDataType;
  onRemove: () => void;
  removeConfirmationText?: string;
  actions?: ReadonlyArray<{ text: string; onClick: () => void }>;
}

export function ConfigurableWidget({ config, onRemove, actions = [], removeConfirmationText }: BaseWidgetProps) {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  return (
    <>
      <BoardItem
        header={<div>{config.header}</div>}
        disableContentPaddings={config.disableContentPaddings}
        i18nStrings={boardItemI18nStrings}
        settings={
          <ButtonDropdown
            items={[...actions.map((action) => ({ id: action.text, text: action.text })), { id: 'remove', text: 'Remove' }]}
            ariaLabel="Widget settings"
            variant="icon"
            onItemClick={(event) => {
              if (event.detail.id === 'remove') {
                if (removeConfirmationText) {
                  setShowRemoveDialog(true);
                } else {
                  onRemove();
                }
              } else {
                const actionMatch = actions.find((action) => action.text === event.detail.id)!;
                actionMatch.onClick();
              }
            }}
          />
        }
        footer={config.footer && <div>{config.footer}</div>}
      >
        <div>{config.content}</div>
      </BoardItem>
      {showRemoveDialog && (
        <RemoveDialog
          title={removeConfirmationText!}
          onDismiss={() => setShowRemoveDialog(false)}
          onConfirm={() => {
            setShowRemoveDialog(false);
            onRemove();
          }}
        />
      )}
    </>
  );
}
