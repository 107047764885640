import React, { useState } from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

interface ResetButtonProps {
  children: React.ReactNode;
  onReset: () => void;
}

export function ResetButton({ children, onReset }: ResetButtonProps) {
  const [showDialog, setShowDialog] = useState(false);

  function onClick() {
    setShowDialog(true);
  }

  function onDismiss() {
    setShowDialog(false);
  }

  function onConfirm() {
    onReset();
    setShowDialog(false);
  }

  return (
    <>
      <Button onClick={onClick}>{children}</Button>
      {showDialog && (
        <Modal
          visible={true}
          header="Reset to default layout"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" formAction="none" onClick={onDismiss}>
                  Cancel
                </Button>
                <Button variant="primary" formAction="none" onClick={onConfirm}>
                  Reset
                </Button>
              </SpaceBetween>
            </Box>
          }
          onDismiss={onDismiss}
        >
          You will lose your customizations when you reset the layout.
        </Modal>
      )}
    </>
  );
}
