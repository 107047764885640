import { APIInfo, SecretKeyConfig } from './AppContextModels';

// Enum representing keys used in AWS Secrets Manager.
export enum SecretManagerKeys {
  API_KEY = 'das-finsuite-app-apikey-secret'
}

// Configuration for Secret Key ARNs, required only for Dev and Alpha environments.
export const SecretKeyArnConfig: SecretKeyConfig[] = [
  {
    environment: 'dev',
    secretARN: 'arn:aws:secretsmanager:us-west-2:850882930567:secret:das-finsuite-app-apikey-secret-Ib2Zj2'
  },
  {
    environment: 'alpha',
    secretARN: 'arn:aws:secretsmanager:us-west-2:850882930567:secret:das-finsuite-app-apikey-secret-Ib2Zj2'
  }
];

// Configuration for backend GraphQL API endpoints based on environment.
export const BackendGraphQLApiConfig: APIInfo[] = [
  {
    environment: 'dev',
    finsuite_api: 'https://fmaiyn7m5nhkfdeymrb5chilkm.appsync-api.us-west-2.amazonaws.com/graphql',
    risk_remediator_api: 'https://alpha.api.risk-remediator.fintech.device.finance.amazon.dev',
    papi: 'https://beta.ad3papi.device.finance.amazon.dev'
  },
  {
    environment: 'alpha',
    finsuite_api: 'https://fmaiyn7m5nhkfdeymrb5chilkm.appsync-api.us-west-2.amazonaws.com/graphql',
    risk_remediator_api: 'https://alpha.api.risk-remediator.fintech.device.finance.amazon.dev',
    papi: 'https://beta.ad3papi.device.finance.amazon.dev'
  },
  {
    environment: 'beta',
    finsuite_api: 'https://tby5i5xuvzhdrmppv3rkswz3va.appsync-api.us-west-2.amazonaws.com/graphql',
    risk_remediator_api: 'https://beta.api.risk-remediator.fintech.device.finance.amazon.dev',
    papi: 'https://beta.ad3papi.device.finance.amazon.dev'
  },
  {
    environment: 'prod',
    finsuite_api: 'https://y4dff7baobfzrotbxldlbhv3xu.appsync-api.us-west-2.amazonaws.com/graphql',
    risk_remediator_api: 'https://api.risk-remediator.fintech.device.finance.amazon.dev',
    papi: 'https://ad3papi.device.finance.amazon.dev'
  }
];
