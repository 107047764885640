import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import styles from './styles.module.scss';
import { useCostMetricsContext } from './CostMetricsContext';
import { Box, Container, Grid, Header, SpaceBetween, TableProps, Toggle } from '@amzn/awsui-components-react';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { DetailsTableWithProps, DetailsTableWithPropsNoPag } from '../fintech-ops-charts/TableWithPropsCommonComponent';
import { getColumnDefinitionCostMetricsSummaryContainer } from './CostMetricsDataProcessing';
import { TOP_3_ACCOUNTS_VISIBLE_COLUMNS, TOP_3_PRODUCTS_VISIBLE_COLUMNS } from './CostMetricsConstants';

export const CostMetricsDetailsPage: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const costMetricsContext = useCostMetricsContext();
  const [checked, setChecked] = useState(false);

  // Top 3 Products
  const [top3ProductsColumnDefinition, setTop3ProductsColumnDefinition] = useState<TableProps.ColumnDefinition<any>[]>([]);
  const [top3ProductsData, setTop3ProductsData] = useState<any[]>([]);

  // Top 3 Accounts
  const [top3AccountsColumnDefinition, setTop3AccountsColumnDefinition] = useState<TableProps.ColumnDefinition<any>[]>([]);
  const [top3AccountsData, setTop3AccountsData] = useState<any[]>([]);

  useEffect(() => {
    const currentMonth = costMetricsContext.costMetricsSummaryContainer.currentMonth;
    if (costMetricsContext.costMetricsSummaryContainer.top3Products) {
      const colDef = getColumnDefinitionCostMetricsSummaryContainer('product', currentMonth);
      setTop3ProductsColumnDefinition(colDef);
    }
    setTop3ProductsData(costMetricsContext.costMetricsSummaryContainer.top3Products || []);
  }, [costMetricsContext.costMetricsSummaryContainer.top3Products]);

  useEffect(() => {
    const currentMonth = costMetricsContext.costMetricsSummaryContainer.currentMonth;
    if (costMetricsContext.costMetricsSummaryContainer.top3Accounts) {
      const colDef = getColumnDefinitionCostMetricsSummaryContainer('account', currentMonth);
      setTop3AccountsColumnDefinition(colDef);
    }
    setTop3AccountsData(costMetricsContext.costMetricsSummaryContainer.top3Accounts || []);
  }, [costMetricsContext.costMetricsSummaryContainer.top3Accounts]);

  return (
    <div>
      {finOpsContext?.finTechOpsCostMetricsDataStatus == 'loading' && <LoadingSpinner />}
      {finOpsContext?.finTechOpsCostMetricsDataStatus == 'error' && <ErrorContainer />}
      {finOpsContext?.finTechOpsCostMetricsDataStatus == 'pending' && <NotInitializedContainer />}
      {finOpsContext?.finTechOpsCostMetricsDataStatus == 'finished' && (
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 4, l: 4, xl: 4 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 8, l: 8, xl: 8 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } }
          ]}
        >
          <Container fitHeight header={<Header variant="h2">{`Total Cost Summary`}</Header>}>
            <Box variant="h4" textAlign="left">
              Trailing 6 months Cost
            </Box>
            <Box variant="h3" textAlign="center">
              {numberFormatter(costMetricsContext.costMetricsSummaryContainer.totalCost, true)}
            </Box>
            <Box variant="h4" textAlign="left">
              Previous Month Cost
            </Box>
            <Box variant="h3" textAlign="center">
              {numberFormatter(costMetricsContext.costMetricsSummaryContainer.previousMonthCost, true)}
            </Box>
            {costMetricsContext.costMetricsSummaryContainer.top3Products.length > 0 ? (
              <div className={styles.CostMetricsDetailsFootPrintContainerTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={3}
                  emptyMessage="No data to display"
                  Data={top3ProductsData}
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={TOP_3_PRODUCTS_VISIBLE_COLUMNS}
                  COLUMN_DEFINITIONS={top3ProductsColumnDefinition}
                  headerName=""
                />
                <DetailsTableWithPropsNoPag
                  pageSize={3}
                  emptyMessage="No data to display"
                  Data={top3AccountsData}
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={TOP_3_ACCOUNTS_VISIBLE_COLUMNS}
                  COLUMN_DEFINITIONS={top3AccountsColumnDefinition}
                  headerName=""
                />
              </div>
            ) : (
              <Box textAlign="center">No Data To Display</Box>
            )}
          </Container>
          <Container
            fitHeight
            header={
              <>
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Header>Cost Metrics Aggregated Details</Header>
                  <Toggle onChange={({ detail }) => setChecked(detail.checked)} checked={checked}>
                    {checked ? 'Product' : 'Account'}
                  </Toggle>
                </SpaceBetween>
              </>
            }
          >
            {checked ? (
              <div className={styles.CostMetricsAggregatedDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={100}
                  emptyMessage="No Product to Display"
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={costMetricsContext.aggregatedCostByProductDetailsTable.visibleColumns}
                  COLUMN_DEFINITIONS={costMetricsContext.aggregatedCostByProductDetailsTable.columnData}
                  Data={costMetricsContext.aggregatedCostByProductDetailsTable.rowData}
                />
              </div>
            ) : (
              <div className={styles.CostMetricsAggregatedDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={100}
                  emptyMessage="No Account to Display"
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={costMetricsContext.aggregatedCostByAccountDetailsTable.visibleColumns}
                  COLUMN_DEFINITIONS={costMetricsContext.aggregatedCostByAccountDetailsTable.columnData}
                  Data={costMetricsContext.aggregatedCostByAccountDetailsTable.rowData}
                />
              </div>
            )}
          </Container>

          <Container
            fitHeight
            header={
              <>
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Header>Application Aggregated Details</Header>
                </SpaceBetween>
              </>
            }
          >
            <div className={styles.CostMetricsAggregatedDetailsTable}>
              <DetailsTableWithPropsNoPag
                pageSize={100}
                emptyMessage="No Application to Display"
                FILTERING_PROPERTIES={[]}
                VISIBLE_CONTENT={costMetricsContext.aggregatedCostByApplicationsDetailsTable.visibleColumns}
                COLUMN_DEFINITIONS={costMetricsContext.aggregatedCostByApplicationsDetailsTable.columnData}
                Data={costMetricsContext.aggregatedCostByApplicationsDetailsTable.rowData}
              />
            </div>
          </Container>

          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={10}
              stickyHeader={false}
              emptyMessage="No costMetrics data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={costMetricsContext?.costMetricsDetailsTable?.visibleColumns}
              COLUMN_DEFINITIONS={costMetricsContext?.costMetricsDetailsTable?.columnData}
              Data={costMetricsContext?.costMetricsDetailsTable?.rowData}
              headerName="Cost Metrics Details"
            />
          </Container>
        </Grid>
      )}
    </div>
  );
};
