import { Box, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { WidgetConfig } from '../interface';
import { useNavigate } from 'react-router-dom';
import { AllAppsTutorialContentDetailsEntity, AppRedirectInfo } from 'src/components/context/AppContextModels';
import TutorialThumbnail from './TutorialThumbnail';

export const TutorialsWidget = (
  title: string,
  description: string,
  tutorialDetails: AllAppsTutorialContentDetailsEntity[],
  appRedirectInfo?: AppRedirectInfo
): WidgetConfig => {
  return {
    definition: { minRowSpan: 3, minColumnSpan: 1, defaultRowSpan: 4, defaultColumnSpan: 4 },
    data: {
      icon: 'list',
      title: title,
      description: description,
      header: <TutorialHeader title={title} description={description} />,
      content: <TutorialWidgetContent tutorialContentDetails={tutorialDetails} />,
      footer: <TutorialsFooter appRedirectInfo={appRedirectInfo} />
    }
  };
};

interface TutorialHeaderProps {
  title: string;
  description: string;
}
const TutorialHeader: React.FC<TutorialHeaderProps> = ({ title, description }) => {
  return (
    <Header description={description}>
      <Box variant="h2">{title}</Box>
    </Header>
  );
};

interface TutorialWidgetContentProps {
  tutorialContentDetails: AllAppsTutorialContentDetailsEntity[];
}
export const TutorialWidgetContent: React.FC<TutorialWidgetContentProps> = ({ tutorialContentDetails }) => {
  const sortedData: AllAppsTutorialContentDetailsEntity[] = [...tutorialContentDetails].sort((a, b) => {
    if (a.position === null && b.position === null) {
      return 0;
    }
    if (a.position === null) {
      return 1;
    }
    if (b.position === null) {
      return -1;
    }
    return a.position - b.position;
  });

  return (
    <div>
      {sortedData.length === 0 && <Box textAlign="center">No content</Box>}
      {sortedData.length !== 0 && (
        <SpaceBetween size="m" direction="horizontal">
          {sortedData.map((item, index) => (
            <TutorialThumbnail key={index} link={item.link} displayTitle={item.displayTitle} category={item.category} />
          ))}
        </SpaceBetween>
      )}
    </div>
  );
};

interface TutorialsFooterProps {
  appRedirectInfo?: AppRedirectInfo;
}
const TutorialsFooter = ({ appRedirectInfo }: TutorialsFooterProps) => {
  const navigate = useNavigate();
  return (
    <Box textAlign="center">
      <Link
        onFollow={() => {
          navigate('/tutorials', { replace: true, state: appRedirectInfo });
        }}
      >
        View all tutorials
      </Link>
    </Box>
  );
};
