import { Button, FormField, Input, StatusIndicator } from '@amzn/awsui-components-react';
import React, { forwardRef, useState } from 'react';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { v4 as uuidv4 } from 'uuid';
import { WelcomeContentDetailsEntity } from '../../AdminModels';
import {
  WELCOME_CONTENT_LINKS_MAX_LENGTH,
  WELCOME_LINK_DESCRIPTION_MAX_LENGTH,
  WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH,
  welcomeContentDetailsSchema
} from './StepsValidations';
import FormFieldPopover from 'src/components/generic-components/FormFieldPopover';

interface WelcomeLinksContentFormProps {
  hasWelcomeContent: boolean;
  addNewWelcomeContent: (welcomeContent: WelcomeContentDetailsEntity) => void;
  updateRow: (welcomeContent: WelcomeContentDetailsEntity) => void;
  welcomeContents: WelcomeContentDetailsEntity[];
}

export interface WelcomeLinksContentFormMethods {
  editSelectedRow: (editNonProdEnvironment: WelcomeContentDetailsEntity) => void;
}

const WelcomeLinksContentForm = forwardRef<WelcomeLinksContentFormMethods, WelcomeLinksContentFormProps>(
  ({ hasWelcomeContent, addNewWelcomeContent, updateRow, welcomeContents }: WelcomeLinksContentFormProps, ref: React.Ref<any>) => {
    const [actionType, setActionType] = useState<'add' | 'edit'>('add');
    const [newWelcomeLinkContent, setNewWelcomeLinkContent] = useState<WelcomeContentDetailsEntity>({
      id: uuidv4(),
      displayText: '',
      link: '',
      description: ''
    });
    const [newWelcomeContentIsValid, setNewWelcomeLinkContentIsValid] = useState(false);
    const [newWelcomeLinkContentErrors, setNewWelcomeLinkContentErrors] = useState({
      displayText: '',
      link: '',
      description: ''
    });

    const isValidNewNonProdData = async () => {
      try {
        await welcomeContentDetailsSchema.validate(newWelcomeLinkContent, { abortEarly: false });
        setNewWelcomeLinkContentIsValid(true);
      } catch (error: any) {
        setNewWelcomeLinkContentIsValid(false);
      }
    };

    const addWelcomeContent = () => {
      const newRow: WelcomeContentDetailsEntity = {
        id: uuidv4(),
        displayText: newWelcomeLinkContent.displayText,
        link: newWelcomeLinkContent.link,
        description: newWelcomeLinkContent.description
      };
      clearForm();
      addNewWelcomeContent(newRow);
    };

    const editSelectedRow = (editRow: WelcomeContentDetailsEntity) => {
      if (editRow) {
        setNewWelcomeLinkContent({
          id: editRow.id,
          displayText: editRow.displayText,
          link: editRow.link,
          description: editRow.description
        });
        setActionType('edit');
        isValidNewNonProdData();
      } else {
        setActionType('add');
        setNewWelcomeLinkContent({ id: null, displayText: '', link: '', description: '' });
      }
    };

    React.useImperativeHandle(ref, () => ({
      editSelectedRow
    }));

    const clearForm = () => {
      setNewWelcomeLinkContent({ id: null, displayText: '', link: '', description: '' });
      setNewWelcomeLinkContentIsValid(false);
    };

    const cancelEdit = () => {
      clearForm();
      setActionType('add');
    };

    const updatedChanges = () => {
      updateRow(newWelcomeLinkContent);
      clearForm();
      setActionType('add');
    };

    return (
      <>
        <div ref={ref} className="inline-table-horizontal-form">
          <FormField
            className="welcome-links-display-name-field"
            label="Display name"
            errorText={newWelcomeLinkContentErrors.displayText}
            stretch={true}
            constraintText={characterCountConstraintMessage(WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH, newWelcomeLinkContent?.displayText?.length || 0)}
            info={<FormFieldPopover header={'Display name'} messages={['Name the link you want displayed']} />}
          >
            <Input
              placeholder="Enter display name"
              value={newWelcomeLinkContent.displayText || ''}
              disabled={!hasWelcomeContent || welcomeContents.length >= WELCOME_CONTENT_LINKS_MAX_LENGTH}
              onChange={({ detail }) => setNewWelcomeLinkContent({ ...newWelcomeLinkContent, displayText: detail.value })}
              onBlur={async () => {
                welcomeContentDetailsSchema
                  .validateAt('displayText', { displayText: newWelcomeLinkContent.displayText })
                  .then(() => {
                    setNewWelcomeLinkContentErrors({
                      ...newWelcomeLinkContentErrors,
                      displayText: ''
                    });
                  })
                  .catch((error) => {
                    setNewWelcomeLinkContentErrors({
                      ...newWelcomeLinkContentErrors,
                      displayText: error.message
                    });
                  })
                  .finally(() => {
                    isValidNewNonProdData();
                  });
              }}
            />
          </FormField>

          <FormField
            className="welcome-links-link-field"
            label="Link"
            constraintText={`Include http:// or https:// in the URL`}
            errorText={newWelcomeLinkContentErrors.link}
            stretch={true}
            info={<FormFieldPopover header={'Link'} messages={['Enter the URL of the link you want to add']} />}
          >
            <Input
              placeholder="Paste URL"
              disabled={!hasWelcomeContent || welcomeContents.length >= WELCOME_CONTENT_LINKS_MAX_LENGTH}
              onChange={({ detail }) => setNewWelcomeLinkContent({ ...newWelcomeLinkContent, link: detail.value })}
              value={newWelcomeLinkContent.link || ''}
              onBlur={async () => {
                welcomeContentDetailsSchema
                  .validateAt('link', { link: newWelcomeLinkContent.link })
                  .then(() => {
                    setNewWelcomeLinkContentErrors({
                      ...newWelcomeLinkContentErrors,
                      link: ''
                    });
                  })
                  .catch((error) => {
                    setNewWelcomeLinkContentErrors({
                      ...newWelcomeLinkContentErrors,
                      link: error.message
                    });
                  })
                  .finally(() => {
                    isValidNewNonProdData();
                  });
              }}
            />
          </FormField>

          <FormField
            className="flex-grow-field"
            label="Description"
            errorText={newWelcomeLinkContentErrors.description}
            stretch={true}
            constraintText={characterCountConstraintMessage(WELCOME_LINK_DESCRIPTION_MAX_LENGTH, newWelcomeLinkContent?.description?.length || 0)}
            info={<FormFieldPopover header={'Description'} messages={['Describe the link']} />}
          >
            <Input
              placeholder="Enter link description"
              disabled={!hasWelcomeContent || welcomeContents.length >= WELCOME_CONTENT_LINKS_MAX_LENGTH}
              onChange={({ detail }) => setNewWelcomeLinkContent({ ...newWelcomeLinkContent, description: detail.value })}
              value={newWelcomeLinkContent.description || ''}
              onBlur={async () => {
                welcomeContentDetailsSchema
                  .validateAt('description', { description: newWelcomeLinkContent.description })
                  .then(() => {
                    setNewWelcomeLinkContentErrors({
                      ...newWelcomeLinkContentErrors,
                      description: ''
                    });
                  })
                  .catch((error) => {
                    setNewWelcomeLinkContentErrors({
                      ...newWelcomeLinkContentErrors,
                      description: error.message
                    });
                  })
                  .finally(() => {
                    isValidNewNonProdData();
                  });
              }}
            />
          </FormField>

          {actionType === 'add' && (
            <Button className="add-new-button" formAction="none" disabled={!newWelcomeContentIsValid} onClick={addWelcomeContent}>
              {`Add`}
            </Button>
          )}
          {actionType === 'edit' && (
            <>
              <Button className="add-new-button" formAction="none" onClick={cancelEdit}>
                {`Cancel`}
              </Button>
              <Button variant="primary" className="add-new-button" formAction="none" disabled={!newWelcomeContentIsValid} onClick={updatedChanges}>
                {`Save`}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
);

export default WelcomeLinksContentForm;
