/* eslint-disable no-fallthrough */
import { Icon, Link, TableProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import { PHONE_TOOL_URL } from 'src/constants/AppConstants';
import { numberFormatter, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import {
  getBackAgeInPeriod,
  getFormattedMonthYearFromUTC,
  getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps,
  isCurrentYearAndMonth
} from 'src/utilities/DateTimeUtilities';
import { FilterDateFormat_MMM_YYYY, FunctionDateFormat_YYYYMM, OEEmptyStringMappings } from '../FinTechOpsConstants';
import { DashboardFilters, SaSDashboardItems } from '../fintech-ops-dashboard/FinTechOpsDataInterfaces';
import { findCommonElements3, indicatorIcon, indicatorIconVariant } from '../fintech-ops-dashboard/FinTechOpsUtils';
import {
  DataForGraphProcessing,
  Metadata,
  SASCalculatedDataForDetailsTab,
  SASCalculatedDataForGrah,
  SaSDetailsTabTableData,
  SaSSummaryContainerInfo,
  SasDetails,
  SasMasterFilters,
  SasParsedData,
  SasParsedDetails,
  SasRawData,
  SasSeriesDatum
} from './SasInterfaces';
import * as React from 'react';
import { sortDistinctMonths_MM_YYYY } from '../FinTechOpsDataProcessingFunctions';

export function filterDataByCreateDates(rawData: SasRawData[]): SasRawData[] {
  const currentDate = moment();
  const lastSixMonthsIncludingCurrentMonth = getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps();

  const included: SasRawData[] = [];
  const excluded: SasRawData[] = [];

  rawData.forEach((item) => {
    if (item.snapshot_month) {
      const createDate = moment(item.snapshot_month, 'YYYY-MM-DD');
      if (createDate.isBetween(lastSixMonthsIncludingCurrentMonth, currentDate, 'day', '[]')) {
        included.push(item);
      } else {
        excluded.push(item);
      }
    } else {
      console.debug('Item without create_date ', item);
    }
  });

  // For debugging
  // console.log('SAS Excluded Records:', JSON.stringify(excluded));
  // const uniqueMonthsSet = new Set(included.map((item) => moment(item.snapshot_month).format('YYYY-MM')));
  // const uniqueMonths = Array.from(uniqueMonthsSet).sort();
  // console.log('SAS Included uniqueMonths ', JSON.stringify(uniqueMonths));

  return included;
}

export const returnUsefulRawDataSas = (sasRawDetails: SasRawData[], lastUpdatedAt: Metadata): SasParsedDetails => {
  const sasParsed: SasParsedData[] = [];

  const filteredSaSData = filterDataByCreateDates(sasRawDetails);

  filteredSaSData.forEach((item: SasRawData) => {
    const snapshotMonth_YYYY_MM_DD = moment(item.snapshot_month, 'YYYY-MM-DD');

    sasParsed.push({
      team_name: item.team_name ? item.team_name : OEEmptyStringMappings.Unassigned_Team,

      manager_name_hierarchy_concat: item.supervisor_name_hierarchy.split(':') || [],
      manager: item.supervisor_name_hierarchy.split(':')[0] || OEEmptyStringMappings.Generic_Mapping,

      manager_login_hierarchy_concat: item.supervisor_login_name_hierarchy.split(':') || [],
      manager_login: item.supervisor_login_name_hierarchy.split(':')[0] || OEEmptyStringMappings.Generic_Mapping,

      assignee_name: item.assignee_name,
      assignee_login: item.assignee_login,

      campaign_name: item.campaign_name,
      has_recommendation: item.has_recommendation == 'N' ? OEEmptyStringMappings.No_Recommendation : OEEmptyStringMappings.Has_Recommendation,
      is_blocked_campaign: item.is_blocked_campaign === 'NON-BLOCKED' ? OEEmptyStringMappings.NonBlocked : OEEmptyStringMappings.Blocked,
      resource_type: item.resource_type,

      oldest_recommendation_age: item.oldest_recommendation_age || 0,
      oldest_risk_age: item.oldest_risk_age || 0,
      resource_name: item.resource_name ? item.resource_name : 'N/A',
      risk_status_at_eom: item.risk_status_at_eom,

      snapshot_month_YYYY_MM_DD: item.snapshot_month,
      snapshotMonth_MM_YYYY_Formatted: snapshotMonth_YYYY_MM_DD.format(FilterDateFormat_MMM_YYYY)
    } as SasParsedData);
  });

  // console.log('SAS Raw ', JSON.stringify(filteredSaSData));
  // console.log('SAS Parsed ', JSON.stringify(sasParsed));
  // const sample = sasParsed.filter(
  //   (item) => item.snapshot_month_YYYY_MM_DD === '2023-09-01' && item.team_name === 'AD3' && item.risk_status_at_eom === 'Open'
  // );
  // console.log('SAS sample ', JSON.stringify(sample));

  return {
    metadata: lastUpdatedAt,
    data: sasParsed
  } as SasParsedDetails;
};

export const applyFiltersSas = (items: SasParsedData[], filters: SasMasterFilters): SasParsedData[] => {
  return items.filter((item) => {
    return (
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.team.includes(item.team_name) &&
      filters.assignees.includes(item.assignee_name)
    );
  });
};

export const applyDashboardFiltersSAS = (items: SasParsedData[], filters: DashboardFilters): SasParsedData[] => {
  return items.filter((item) => {
    return (
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.team_name.includes(item.team_name) &&
      filters.assignees.includes(item.assignee_name)
    );
  });
};

export const getDashboardFiltersFromSAS = (items: SasParsedData[]): DashboardFilters => {
  const sasMasterFilterData: DashboardFilters = {
    team_name: [],
    managers: [],
    assignees: []
  };
  items.forEach((item) => {
    if (!sasMasterFilterData.team_name.includes(item.team_name)) {
      sasMasterFilterData.team_name.push(item.team_name);
    }

    if (!sasMasterFilterData.managers.includes(item.manager)) {
      sasMasterFilterData.managers.push(item.manager);
    }

    if (!sasMasterFilterData.assignees.includes(item.assignee_name)) {
      sasMasterFilterData.assignees.push(item.assignee_name);
    }
  });
  return sasMasterFilterData;
};

export const createMasterFilterDataSas = (items: SasParsedData[]): SasMasterFilters => {
  const sasMasterFilterData: SasMasterFilters = {
    managers: [],
    assignees: [],
    team: []
  };
  items.forEach((item) => {
    if (!sasMasterFilterData.managers.includes(item.manager)) {
      sasMasterFilterData.managers.push(item.manager);
    }
    if (!sasMasterFilterData.assignees.includes(item.assignee_name)) {
      sasMasterFilterData.assignees.push(item.assignee_name);
    }
    if (!sasMasterFilterData.team.includes(item.team_name)) {
      sasMasterFilterData.team.push(item.team_name);
    }
  });
  return sasMasterFilterData;
};

// Calculations for FinTechOps DashBoard SAS section
export const createOpsSasData = (items: SasParsedData[]): SaSDashboardItems => {
  let solved = 0;
  let unsolved = 0;
  let open_with_recommendation = 0;
  let open_without_recommendation = 0;
  let totalRecommendationAge = 0;
  let totalNumberOfRecommendationAge = 0;

  items.forEach((item: SasParsedData) => {
    if (item.risk_status_at_eom == 'Open' && isCurrentYearAndMonth(item.snapshot_month_YYYY_MM_DD)) {
      unsolved++;
      if (item.has_recommendation == OEEmptyStringMappings.Has_Recommendation) {
        open_with_recommendation++;
      } else {
        open_without_recommendation++;
      }
    } else if (item.risk_status_at_eom == 'Resolved') {
      solved++;
    }
    if (item.oldest_recommendation_age != 0 && item.has_recommendation == OEEmptyStringMappings.Has_Recommendation) {
      totalRecommendationAge = totalNumberOfRecommendationAge + (item.oldest_recommendation_age as unknown as number);
      totalNumberOfRecommendationAge++;
    }
  });

  return {
    total_sas_risk: solved + unsolved,
    solved: solved,
    unsolved: unsolved,
    open_with_recommendation: open_with_recommendation,
    open_without_recommendation: open_without_recommendation,
    avg_with_recommendation_age:
      totalNumberOfRecommendationAge > 0 ? ((totalRecommendationAge / totalNumberOfRecommendationAge).toFixed(2) as unknown as number) : 0,
    resolution_rate: unsolved + solved > 0 ? (((solved / (unsolved + solved)) * 100).toFixed(2) as unknown as number) : 0
  };
};

// SAS Graph Data calculations
export const createSasGraphDatav2 = (items: SasParsedData[]): SASCalculatedDataForGrah => {
  if (items.length == 0) {
    return {
      openRiskResourceTotalGraph: [],
      openRiskTotalMonthlyGraph: [],
      campaignsGraph: [],
      openRecommendationGraph: [],
      openRecommendationBlockedGraph: [],
      averageRiskDOWGraph: []
    };
  }
  //Graphs
  const openResourceTotalMap = new Map<string, DataForGraphProcessing>(); //count title category
  const openRiskMonthlyMap = new Map<string, DataForGraphProcessing>();
  const campaignsMap = new Map<string, DataForGraphProcessing>();
  const openRecommendationsMap = new Map<string, DataForGraphProcessing>();
  const openRecommendationsBlockedMap = new Map<string, DataForGraphProcessing>();
  const averageRiskDOWMap = new Map<string, DataForGraphProcessing>();

  const openRiskResourceTotalList: DataForGraphProcessing[] = [];
  const openRiskTotalMonthlyList: DataForGraphProcessing[] = [];
  const campaignsList: DataForGraphProcessing[] = [];
  const openRecommendationList: DataForGraphProcessing[] = [];
  const openRecommendationBlockedList: DataForGraphProcessing[] = [];
  const averageRiskDOWList: DataForGraphProcessing[] = [];
  const sasOpenTotalRiskList: SasParsedData[] = [];

  let lastDay = moment(items[0].snapshot_month_YYYY_MM_DD);
  let firstDay = moment(items[0].snapshot_month_YYYY_MM_DD);
  const lastFourMonths: string[] = [];
  const currentMonth = moment();

  for (let i = 0; i < 4; i++) {
    lastFourMonths.push(currentMonth.format(FunctionDateFormat_YYYYMM));
    currentMonth.subtract(1, 'months');
  }

  items.forEach((item) => {
    const itemResource = item.resource_type;
    const itemMonth = getFormattedMonthYearFromUTC(item.snapshot_month_YYYY_MM_DD, FunctionDateFormat_YYYYMM);
    const itemTeam = item.team_name;
    const itemCampaign = item.campaign_name;
    const itemRecommendation = item.has_recommendation;
    const itemDayOfWeek = moment(item.snapshot_month_YYYY_MM_DD).format('ddd');
    const itemBlocked = item.is_blocked_campaign;
    let isOpen = false;
    if (item.risk_status_at_eom == 'Open' && getBackAgeInPeriod(itemMonth, 'months') < 1) {
      isOpen = true;
    }

    if (item.risk_status_at_eom == 'Open') {
      openResourceTotalMap.get(itemResource + itemMonth) != undefined
        ? (openResourceTotalMap.get(itemResource + itemMonth)!.count += 1)
        : openResourceTotalMap.set(itemResource + itemMonth, {
            title: itemResource,
            category: itemMonth,
            count: 1
          });

      openRiskMonthlyMap.get(itemTeam + itemMonth) != undefined
        ? (openRiskMonthlyMap.get(itemTeam + itemMonth)!.count += 1)
        : openRiskMonthlyMap.set(itemTeam + itemMonth, {
            title: itemTeam,
            category: itemMonth,
            count: 1
          });
      campaignsMap.get(itemCampaign + itemMonth) != undefined
        ? (campaignsMap.get(itemCampaign + itemMonth)!.count += 1)
        : campaignsMap.set(itemCampaign + itemMonth, {
            title: itemCampaign,
            category: itemMonth,
            count: 1
          });
      openRecommendationsMap.get(itemRecommendation + itemMonth) != undefined
        ? (openRecommendationsMap.get(itemRecommendation + itemMonth)!.count += 1)
        : openRecommendationsMap.set(itemRecommendation + itemMonth, {
            title: itemRecommendation,
            category: itemMonth,
            count: 1
          });
      openRecommendationsBlockedMap.get(itemBlocked + itemMonth) != undefined
        ? (openRecommendationsBlockedMap.get(itemBlocked + itemMonth)!.count += 1)
        : openRecommendationsBlockedMap.set(itemBlocked + itemMonth, {
            title: `${itemBlocked}`,
            category: itemMonth,
            count: 1
          });
    }
    averageRiskDOWMap.get(itemTeam + itemDayOfWeek) != undefined
      ? (averageRiskDOWMap.get(itemTeam + itemDayOfWeek)!.count += 1)
      : averageRiskDOWMap.set(itemTeam + itemDayOfWeek, {
          title: itemTeam,
          category: itemDayOfWeek,
          count: 1
        });
    if (isOpen) {
      sasOpenTotalRiskList.push(item);
    }

    moment(item.snapshot_month_YYYY_MM_DD).isBefore(lastDay) ? (lastDay = moment(item.snapshot_month_YYYY_MM_DD)) : lastDay;
    moment(item.snapshot_month_YYYY_MM_DD).isAfter(firstDay) ? (firstDay = moment(item.snapshot_month_YYYY_MM_DD)) : firstDay;
  });

  for (const [key, value] of openResourceTotalMap) {
    openRiskResourceTotalList.push(value);
  }

  for (const [key, value] of openRiskMonthlyMap) {
    openRiskTotalMonthlyList.push(value);
  }

  for (const [key, value] of campaignsMap) {
    campaignsList.push(value);
  }

  for (const [key, value] of openRecommendationsMap) {
    openRecommendationList.push(value);
  }

  for (const [key, value] of openRecommendationsBlockedMap) {
    openRecommendationBlockedList.push(value);
  }
  for (const [key, value] of averageRiskDOWMap) {
    value.count = Math.round(value.count / firstDay.diff(lastDay, 'week'));
    averageRiskDOWList.push(value);
  }

  return {
    openRiskResourceTotalGraph: openRiskResourceTotalList,
    openRiskTotalMonthlyGraph: openRiskTotalMonthlyList,
    campaignsGraph: campaignsList,
    openRecommendationGraph: openRecommendationList,
    openRecommendationBlockedGraph: openRecommendationBlockedList,
    averageRiskDOWGraph: averageRiskDOWList
  };
};

export const setDomainOrder = (categoryOrder: string[], items: SasSeriesDatum[]): SasSeriesDatum[] => {
  const returnSortedItems: SasSeriesDatum[] = [];

  categoryOrder.forEach((category) => {
    items.forEach((item) => {
      if (item.x == category) {
        returnSortedItems.push(item);
      }
    });
  });
  return returnSortedItems;
};

// Details Tab code
export const createSasDetailsTabDataV2 = (sasParsedData: SasParsedData[]): SASCalculatedDataForDetailsTab => {
  const sasSummaryContainer = getSaSSummaryContainerInfo(sasParsedData);
  const totalRisksByResourceTypeAndTeam = getTotalRisksByResourceTypeAndTeamInfo(sasParsedData);
  const annualTotalRisksBreakoutByTeamManagers = getAnnualTotalRisksBreakoutByTeamManagers(sasParsedData);
  const aggregatedRisksByTeamManagers = getAggregatedRisksByTeamManagers(sasParsedData);
  const openTotalRiskTable = getTotalOpenRisksWithRecommendationDetails(sasParsedData);

  return {
    sasSummaryContainer: sasSummaryContainer,
    totalRisksByResourceTypeAndTeam: totalRisksByResourceTypeAndTeam,
    annualTotalRisksBreakoutByTeamManagers: annualTotalRisksBreakoutByTeamManagers,
    aggregatedRisksByTeamManagers: aggregatedRisksByTeamManagers,
    openTotalRiskTable: openTotalRiskTable
  };
};

const getSaSSummaryContainerInfo = (sasParsedData: SasParsedData[]): SaSSummaryContainerInfo => {
  const distinctTeams = getUniqueFieldValues(sasParsedData, 'team_name');

  const currentMonth = moment().startOf('month').format('YYYY-MM-DD');
  const previousMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

  const totalOpenSASRisksInCurrentMonth = sasParsedData.filter(
    (item) => item.risk_status_at_eom == 'Open' && item.snapshot_month_YYYY_MM_DD === currentMonth
  );
  const totalOpenSASRisksInPrevMnth = sasParsedData.filter(
    (item) => item.risk_status_at_eom == 'Open' && item.snapshot_month_YYYY_MM_DD === previousMonth
  );

  const aggregatedValuesByTeam: any[] = [];

  distinctTeams.forEach((team) => {
    const teamsOpenRisksInCurrentMonth = totalOpenSASRisksInCurrentMonth.filter((item) => item.team_name === team);
    const teamsOpenRisksInPreviousMonth = totalOpenSASRisksInPrevMnth.filter((item) => item.team_name === team);

    aggregatedValuesByTeam.push({
      team: team,
      totalOpenRisksInCurrentMonth: teamsOpenRisksInCurrentMonth.length,
      MoM: teamsOpenRisksInCurrentMonth?.length - teamsOpenRisksInPreviousMonth?.length
    });
  });

  const colDef: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'team',
      header: 'Team',
      sortingField: 'team',
      width: 90,
      cell: (item: any) => item.team
    },
    {
      id: 'totalOpenRisksInCurrentMonth',
      header: 'Risks',
      width: 90,
      sortingField: 'totalOpenRisksInCurrentMonth',
      cell: (item: any) => numberFormatter(item.totalOpenRisksInCurrentMonth) || 0
    },
    {
      id: 'MoM',
      header: 'MoM',
      width: 90,
      sortingField: 'MoM',
      cell: (item: any) => (
        <>
          {numberFormatter(Math.abs(item.MoM)) || '-'}
          <Icon name={indicatorIcon(item.MoM)} variant={indicatorIconVariant(item.MoM)} />
        </>
      )
    }
  ];

  const visibleColumns = colDef.map((item) => {
    return item.id;
  });

  return {
    totalOpenRisks: totalOpenSASRisksInCurrentMonth.length,
    totalRisksByTeam: {
      rowData: aggregatedValuesByTeam || [],
      columnData: colDef || [],
      visibleColumns: visibleColumns || []
    }
  };
};

// Total Open Risk by Resource Type and Team
const getTotalRisksByResourceTypeAndTeamInfo = (sasParsedData: SasParsedData[]): SaSDetailsTabTableData => {
  const totalRiskData: Record<string, any> = {};
  let distinctMonths: string[] = [];

  sasParsedData.forEach((item) => {
    const { snapshotMonth_MM_YYYY_Formatted, team_name, resource_type, risk_status_at_eom } = item;

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(snapshotMonth_MM_YYYY_Formatted)) {
      distinctMonths.push(snapshotMonth_MM_YYYY_Formatted);
    }

    if (risk_status_at_eom === 'Open') {
      const monthYear = snapshotMonth_MM_YYYY_Formatted;
      const key = `${team_name}-${resource_type}`;

      if (!totalRiskData[key]) {
        totalRiskData[key] = {
          team: team_name,
          resourceType: resource_type
        };
      }

      if (!totalRiskData[key][monthYear]) {
        totalRiskData[key][monthYear] = 1;
      } else {
        totalRiskData[key][monthYear]++;
      }
    }
  });

  const result: any[] = Object.values(totalRiskData);
  distinctMonths = sortDistinctMonths_MM_YYYY(distinctMonths);

  // Generate the column definition dynamically
  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'resourceType',
      header: 'Resource Type',
      sortingField: 'resourceType',
      width: 140,
      cell: (item: any) => <>{item?.resourceType}</>,
      isRowHeader: true
    },
    {
      id: 'team',
      header: 'Team',
      sortingField: 'team',
      width: 100,
      cell: (item: any) => <>{item?.team}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      width: 120,
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month]) : 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    visibleColumns: visbleColumns,
    columnData: columnDefinition,
    rowData: result
  } as SaSDetailsTabTableData;
};

// Total Risks Breakout By Team Managers
const getAnnualTotalRisksBreakoutByTeamManagers = (sasParsedData: SasParsedData[]): SaSDetailsTabTableData => {
  // Create an object to store the aggregated data
  const managerBreakdown: Record<
    string,
    {
      blocked_has_recommendation: number;
      blocked_has_no_recommendation: number;
      non_blocked_has_recommendation: number;
      non_blocked_has_no_recommendation: number;
    }
  > = {};

  // Iterate through the data array
  for (const item of sasParsedData) {
    const teamManager = item.manager;
    const hasRecommendation = item.has_recommendation;
    const isBlocked = item.is_blocked_campaign;

    // Initialize the manager's entry if it doesn't exist
    if (!managerBreakdown[teamManager]) {
      managerBreakdown[teamManager] = {
        blocked_has_recommendation: 0,
        blocked_has_no_recommendation: 0,
        non_blocked_has_recommendation: 0,
        non_blocked_has_no_recommendation: 0
      };
    }

    // Update counts based on the data
    if (isBlocked === OEEmptyStringMappings.Blocked) {
      if (hasRecommendation === OEEmptyStringMappings.Has_Recommendation) {
        managerBreakdown[teamManager].blocked_has_recommendation++;
      } else if (hasRecommendation === OEEmptyStringMappings.No_Recommendation) {
        managerBreakdown[teamManager].blocked_has_no_recommendation++;
      }
    } else if (isBlocked === OEEmptyStringMappings.NonBlocked) {
      if (hasRecommendation === OEEmptyStringMappings.Has_Recommendation) {
        managerBreakdown[teamManager].non_blocked_has_recommendation++;
      } else if (hasRecommendation === OEEmptyStringMappings.No_Recommendation) {
        managerBreakdown[teamManager].non_blocked_has_no_recommendation++;
      }
    }
  }

  // Convert the aggregated data into the desired array format
  const annualTotalRisksBreakout: {
    team_manager: string;
    blocked_has_recommendation: number;
    blocked_has_no_recommendation: number;
    non_blocked_has_recommendation: number;
    non_blocked_has_no_recommendation: number;
  }[] = [];

  for (const manager in managerBreakdown) {
    const breakdown = managerBreakdown[manager];
    annualTotalRisksBreakout.push({
      team_manager: manager,
      blocked_has_recommendation: breakdown.blocked_has_recommendation,
      blocked_has_no_recommendation: breakdown.blocked_has_no_recommendation,
      non_blocked_has_recommendation: breakdown.non_blocked_has_recommendation,
      non_blocked_has_no_recommendation: breakdown.non_blocked_has_no_recommendation
    });
  }

  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'team_manager',
      header: 'Team Manager',
      sortingField: 'team_manager',
      minWidth: 150,
      cell: (item: any) => <>{item?.team_manager}</>,
      isRowHeader: true
    },
    {
      id: 'blocked_has_recommendation',
      header: 'Blocked w/ Recommendations',
      sortingField: 'blocked_has_recommendation',
      minWidth: 100,
      cell: (item: any) => <>{numberFormatter(item?.blocked_has_recommendation)}</>
    },
    {
      id: 'blocked_has_no_recommendation',
      header: 'Blocked w/o Recommendations',
      sortingField: 'blocked_has_no_recommendation',
      minWidth: 100,
      cell: (item: any) => <>{numberFormatter(item?.blocked_has_no_recommendation)}</>,
      isRowHeader: true
    },
    {
      id: 'non_blocked_has_recommendation',
      header: 'Non-Blocked w/ Recommendations',
      sortingField: 'non_blocked_has_recommendation',
      minWidth: 100,
      cell: (item: any) => <>{numberFormatter(item?.non_blocked_has_recommendation)}</>
    },
    {
      id: 'non_blocked_has_no_recommendation',
      header: 'Non-Blocked w/o Recommendations',
      sortingField: 'non_blocked_has_no_recommendation',
      minWidth: 100,
      cell: (item: any) => <>{numberFormatter(item?.non_blocked_has_no_recommendation)}</>
    }
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    columnData: columnDefinition || [],
    rowData: annualTotalRisksBreakout || [],
    visibleColumns: visbleColumns || []
  } as SaSDetailsTabTableData;
};

const getAggregatedRisksByTeamManagers = (sasParsedData: SasParsedData[]): SaSDetailsTabTableData => {
  const managerMonthlyCounts: Record<string, Record<string, number>> = {};
  let distinctMonths: string[] = [];

  for (const item of sasParsedData) {
    const teamManager = item.manager;
    const date = moment(item.snapshot_month_YYYY_MM_DD);

    const monthYear = date.format('MMM-YYYY');
    const formattedDate = `${monthYear}`;

    if (!distinctMonths.includes(formattedDate)) {
      distinctMonths.push(formattedDate);
    }

    if (!managerMonthlyCounts[teamManager]) {
      managerMonthlyCounts[teamManager] = {};
    }

    if (!managerMonthlyCounts[teamManager][formattedDate]) {
      managerMonthlyCounts[teamManager][formattedDate] = 1;
    } else {
      managerMonthlyCounts[teamManager][formattedDate]++;
    }
  }

  const aggregatedData: any[] = [];

  for (const manager in managerMonthlyCounts) {
    const monthlyCounts = managerMonthlyCounts[manager];
    const entry: any = { team_manager: manager, ...monthlyCounts };
    aggregatedData.push(entry);
  }
  distinctMonths = sortDistinctMonths_MM_YYYY(distinctMonths);

  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'team_manager',
      header: 'Manager',
      sortingField: 'team_manager',
      cell: (item: any) => <>{item?.team_manager}</>,
      isRowHeader: true,
      minWidth: 150
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month]) : 0}</>,
      maxWidth: 100
    }))
  ];

  const visibleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    columnData: columnDefinition || [],
    rowData: aggregatedData || [],
    visibleColumns: visibleColumns || []
  } as SaSDetailsTabTableData;
};

const getTotalOpenRisksWithRecommendationDetails = (sasParsedData: SasParsedData[]): SaSDetailsTabTableData => {
  const currentMonth = moment().startOf('month').format('YYYY-MM-DD');

  // Filter out the data for the current month and only consider open risks
  const considerableData: SasParsedData[] = sasParsedData.filter(
    (item) =>
      item.snapshot_month_YYYY_MM_DD === currentMonth &&
      item.risk_status_at_eom === 'Open' &&
      item.has_recommendation === OEEmptyStringMappings.Has_Recommendation
  );

  const colDef = SAS_OPEN_TOTAL_RISK_TABLE_COLUMN_DEFINITIONS;

  const visibleColumns: string[] = colDef.map((item) => item.id || '');

  return {
    columnData: colDef || [],
    rowData: considerableData || [],
    visibleColumns: visibleColumns || []
  };
};

export const SAS_OPEN_TOTAL_RISK_TABLE_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<SasParsedData>[] = [
  {
    id: 'assignee_name',
    header: 'Assignee',
    sortingField: 'assignee_name',
    cell: (item: SasParsedData) => (
      <>
        {item.assignee_login && (
          <Link target="_blank" href={`${PHONE_TOOL_URL}${item.assignee_login}`}>
            {item.assignee_name}
          </Link>
        )}
        {!item.assignee_login && <>{item.assignee_name}</>}
      </>
    )
  },
  {
    id: 'campaign',
    header: 'Campaign',
    sortingField: 'campaign_name',
    cell: (item: SasParsedData) => item.campaign_name
  },
  {
    id: 'resource_type',
    header: 'Resource Type',
    sortingField: 'resource_type',
    cell: (item: SasParsedData) => item.resource_type
  },
  {
    id: 'resource_name',
    header: 'Resource Name',
    sortingField: 'resource_name',
    cell: (item: SasParsedData) => item.resource_name
  },
  {
    id: 'oldest_risk_age',
    header: 'Oldest Risk Age',
    sortingComparator: (a: SasParsedData, b: SasParsedData) => {
      return (a.oldest_risk_age || 0) - (b.oldest_risk_age || 0);
    },
    cell: (item: SasParsedData) => item.oldest_risk_age
  },
  {
    id: 'oldest_recommendation_age',
    header: 'Oldest Recommendation Age',
    sortingComparator: (a: SasParsedData, b: SasParsedData) => {
      return (a.oldest_recommendation_age || 0) - (b.oldest_recommendation_age || 0);
    },
    cell: (item: SasParsedData) => item.oldest_recommendation_age
  }
];
