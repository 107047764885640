import { Button, FormField, Input, StatusIndicator } from '@amzn/awsui-components-react';
import React, { forwardRef, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReportAndAdditionalIssuesEntity } from '../../AdminModels';
import { reportIssuesAndAdditionalDetailsSchema } from './StepsValidations';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';
import FormFieldPopover from 'src/components/generic-components/FormFieldPopover';

interface ReportsAndAdditionalResourcesProps {
  hasEntities: boolean | any;
  addNewEntity: (addNewEntity: ReportAndAdditionalIssuesEntity) => void;
  updateEntity: (addNewEntity: ReportAndAdditionalIssuesEntity) => void;
}

export interface ReportsAndAdditionalResourcesMethods {
  editSelectedRow: (addNewTutorialContent: ReportAndAdditionalIssuesEntity) => void;
}

export const ReportsAndAdditionalResourcesForm = forwardRef<ReportsAndAdditionalResourcesMethods, ReportsAndAdditionalResourcesProps>(
  ({ hasEntities, addNewEntity, updateEntity }: ReportsAndAdditionalResourcesProps, ref) => {
    const [actionType, setActionType] = useState<'add' | 'edit'>('add');
    const [isEntityValid, setIsEntityValid] = useState(false);
    const [newEntity, setNewEntity] = useState<ReportAndAdditionalIssuesEntity>({
      id: uuidv4(),
      position: null,
      displayName: '',
      link: ''
    });
    const [newEntityErrors, setNewEntityErrors] = useState({
      id: '',
      position: '',
      displayName: '',
      link: ''
    });

    const editSelectedRow = (selectedRow: ReportAndAdditionalIssuesEntity) => {
      if (selectedRow) {
        setActionType('edit');
        setNewEntity({
          id: selectedRow.id,
          position: selectedRow.position,
          displayName: selectedRow.displayName,
          link: selectedRow.link
        });
      } else {
        setActionType('add');
        setNewEntity({
          id: uuidv4(),
          position: null,
          displayName: '',
          link: ''
        });
      }
    };

    React.useImperativeHandle(ref, () => ({
      editSelectedRow
    }));

    const addNewRow = () => {
      const newRow: ReportAndAdditionalIssuesEntity = {
        id: uuidv4(),
        position: newEntity.position ? +newEntity.position : null,
        displayName: newEntity.displayName,
        link: newEntity.link
      };
      setNewEntity({ id: null, position: null, displayName: '', link: '' });
      setIsEntityValid(false);
      addNewEntity(newRow);
    };

    const isValidNewEntity = async () => {
      try {
        await reportIssuesAndAdditionalDetailsSchema().validate(newEntity, { abortEarly: false });
        setIsEntityValid(true);
      } catch (error: any) {
        setIsEntityValid(false);
      }
    };

    const clearForm = () => {
      setNewEntity({ id: uuidv4(), position: null, displayName: '', link: '' });
      setNewEntityErrors({ id: '', position: '', displayName: '', link: '' });
      setIsEntityValid(false);
    };

    const cancelEdit = () => {
      clearForm();
      setActionType('add');
    };

    const updateChanges = () => {
      clearForm();
      setActionType('add');
      updateEntity(newEntity);
    };

    return (
      <>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            window.location.reload();
          }}
        >
          <div className="inline-table-horizontal-form">
            <FormField
              className="width-130px"
              label="Position"
              errorText={newEntityErrors?.position}
              stretch={true}
              info={
                <FormFieldPopover
                  header={'Position'}
                  messages={[
                    'Enter a number to specify the list position.',
                    'Items with duplicate numbers will be organized in alphabetical order and leaving it blank will add the item to the end of the list.'
                  ]}
                />
              }
            >
              <Input
                placeholder="Enter number"
                disabled={!hasEntities}
                onChange={({ detail }) => {
                  setNewEntity({
                    ...newEntity,
                    position: isNaN(+detail.value) ? null : +detail.value
                  });
                }}
                value={newEntity.position == null ? '' : newEntity.position.toString()}
                onBlur={async () => {
                  reportIssuesAndAdditionalDetailsSchema()
                    .validateAt('position', { position: newEntity.position })
                    .then(() => {
                      setNewEntityErrors({
                        ...newEntityErrors,
                        position: ''
                      });
                    })
                    .catch((error) => {
                      setNewEntityErrors({
                        ...newEntityErrors,
                        position: error.message
                      });
                    })
                    .finally(() => {
                      isValidNewEntity();
                    });
                }}
              />
            </FormField>

            <FormField label="Display name" errorText={newEntityErrors?.displayName} stretch={true}>
              <Input
                placeholder="Enter display name"
                disabled={!hasEntities}
                onChange={({ detail }) => {
                  setNewEntity({
                    ...newEntity,
                    displayName: detail.value
                  });
                }}
                value={newEntity.displayName || ''}
                onBlur={async () => {
                  reportIssuesAndAdditionalDetailsSchema()
                    .validateAt('displayName', { displayName: newEntity.displayName })
                    .then(() => {
                      setNewEntityErrors({
                        ...newEntityErrors,
                        displayName: ''
                      });
                    })
                    .catch((error) => {
                      setNewEntityErrors({
                        ...newEntityErrors,
                        displayName: error.message
                      });
                    })
                    .finally(() => {
                      isValidNewEntity();
                    });
                }}
              />
            </FormField>

            <FormField className="flex-grow-field" label={'Link'} errorText={newEntityErrors?.link} stretch={true}>
              <Input
                placeholder="Paste URL"
                disabled={!hasEntities}
                onChange={({ detail }) => {
                  setNewEntity({ ...newEntity, link: detail.value });
                }}
                value={newEntity.link || ''}
                onBlur={async () => {
                  reportIssuesAndAdditionalDetailsSchema()
                    .validateAt('link', { link: newEntity.link })
                    .then(() => {
                      setNewEntityErrors({
                        ...newEntityErrors,
                        link: ''
                      });
                    })
                    .catch((error) => {
                      setNewEntityErrors({
                        ...newEntityErrors,
                        link: error.message
                      });
                    })
                    .finally(() => {
                      isValidNewEntity();
                    });
                }}
              />
            </FormField>

            {actionType === 'add' && (
              <Button className="add-new-button" formAction="none" disabled={!isEntityValid} onClick={addNewRow}>
                {`Add`}
              </Button>
            )}
            {actionType === 'edit' && (
              <>
                <Button className="add-new-button" formAction="none" disabled={!isEntityValid} onClick={cancelEdit}>
                  {`Cancel`}
                </Button>
                <Button variant="primary" className="add-new-button" formAction="none" disabled={!isEntityValid} onClick={updateChanges}>
                  {`Save`}
                </Button>
              </>
            )}
          </div>
        </ErrorBoundary>
      </>
    );
  }
);
