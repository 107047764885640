import { useCollection } from '@amzn/awsui-collection-hooks';
import { Button, Header, Pagination, PropertyFilter, PropertyFilterProps, SpaceBetween, Table, TableProps } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BLANK_SEARCH_AND,
  Preferences,
  TableEmptyState,
  TableNoMatchState,
  getMatchesCountText
} from 'src/components/generic-components/TableCommons';
import { paginationAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import {
  ANNOUNCEMENTS_DEFAULT_PREFERENCES,
  ANNOUNCEMENTS_FILTERING_PROPERTIES,
  ANNOUNCEMENT_COLUMN_DEFINITIONS,
  DEFAULT_ANNOUNCEMENT_VISIBLE_CONTENT_OPTIONS
} from './AdminAnnouncementsConfig';
import { useAnnouncements } from './AdminAnnouncements';
import { API } from 'aws-amplify';
import * as mutations from 'src/graphql/mutations';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { dateTimeComparatorForTable, getCurrentUTCTimeZoneInISO } from 'src/utilities/DateTimeUtilities';
import { logger } from 'src/logger';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { AnnouncementEntity, AnnouncementFlatTableEntity, Application } from 'src/components/context/AppContextModels';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { eFinSuiteAnnouncementMessages, eUserPreferenceKeys } from 'src/constants/AppConstants';
import { useColumnWidths } from 'src/utilities/useColumnWidths';
import { usePersistedState } from 'src/utilities/useLocalStorage';

export const AdminAnnouncementTable: React.FC = () => {
  const navigate = useNavigate();
  const userAuthDetails = useAuth();

  const { listOfApplications, announcements, announcementsFlatEntity } = useAppContext();
  const { announcementStatus, setAnnouncementStatus, displayFlashMessage, refreshAnnouncements } = useAnnouncements();

  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  // Table Properties
  const [announcementsTableEntity, setAnnouncementsTableEntity] = useState<AnnouncementFlatTableEntity[]>([]);
  const [selectedAnnouncements, setSelectedAnnouncements] = useState<AnnouncementFlatTableEntity[]>([]);
  const [announcementsColumnDefinitions, saveWidths] = useColumnWidths(
    eUserPreferenceKeys.ADMIN_ANNOUNCEMENTS_TABLE_WIDTHS,
    ANNOUNCEMENT_COLUMN_DEFINITIONS
  );

  const [filteringProperties, setFilteringProperties] = useState<PropertyFilterProps.FilteringProperty[]>(ANNOUNCEMENTS_FILTERING_PROPERTIES);
  const [preferences, setPreferences] = usePersistedState(
    eUserPreferenceKeys.ADMIN_ANNOUNCEMENTS_TABLE_PREFERENCES,
    ANNOUNCEMENTS_DEFAULT_PREFERENCES
  );
  const [query, setQuery] = useState(BLANK_SEARCH_AND);

  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  useEffect(() => {
    setAnnouncementsTableEntity(announcementsFlatEntity);
  }, [announcementsFlatEntity]);

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(announcementsTableEntity, {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName="Announcements" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        />
      )
    },
    pagination: {
      pageSize: preferences.pageSize
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'announcementDate',
          sortingComparator: (a: AnnouncementFlatTableEntity, b: AnnouncementFlatTableEntity) => {
            return dateTimeComparatorForTable(a.announcementDate, b.announcementDate);
          }
        },
        isDescending: true
      }
    }
  });

  const navigateToNewAnnouncement = () => {
    navigate('/admin/announcements/new');
  };

  const navigateToAnnouncement = () => {
    navigate('/admin/announcements/' + selectedAnnouncements[0].id);
  };

  const removeSelectedAnnouncements = () => {
    setAnnouncementStatus(true);

    const filteredAnnouncements = announcements.filter((announcement) =>
      selectedAnnouncements.map((selectedAnnouncement) => selectedAnnouncement.id).includes(announcement.id)
    );

    deleteAnnouncement({
      ...filteredAnnouncements[0],
      itemMetadata: {
        ...filteredAnnouncements[0].itemMetadata,
        isActive: false, // Deleting announcement means it is no longer active
        updatedBy: userAuthDetails.Alias,
        updatedTime: getCurrentUTCTimeZoneInISO()
      }
    })
      .then((response) => {
        logger.info(`Successfully deleted announcement`);
        displayFlashMessage(eFinSuiteAnnouncementMessages.ANNOUNCEMENT_SUCCESSFULLY_DELETED, 'success');
        setSelectedAnnouncements([]);
        refreshAnnouncements();
        setAnnouncementStatus(false);
        closeModal();
      })
      .catch((error: any) => {
        logger.error('Unable to delete announcement', error);
        displayFlashMessage(eFinSuiteAnnouncementMessages.ANNOUNCEMENT_DELETION_FAILED, 'error');
        setAnnouncementStatus(false);
        closeModal();
      });
  };

  const deleteAnnouncement = async (announcement: AnnouncementEntity) => {
    const updateResponse: any = await API.graphql({
      query: mutations.updateAnnouncement,
      variables: { input: announcement }
    });
    return updateResponse?.data?.updateAnnouncement;
  };

  const deleteSelectedAnnouncement = () => {
    setShowModalInfo({
      showModal: true,
      eventHeader: 'Delete announcement',
      eventContentType: 'DELETE_ANNOUNCEMENT'
    });
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  const onConfirm = async (showModalInfo: ShowModalInfo) => {
    if (showModalInfo.eventContentType === 'DELETE_ANNOUNCEMENT') {
      removeSelectedAnnouncements();
    }
  };

  const handleTableInlineEdit = async (
    currentItem: AnnouncementFlatTableEntity,
    column: TableProps.ColumnDefinition<AnnouncementFlatTableEntity>,
    value: any
  ) => {
    if (column.id === 'status') {
      const selectedAnnouncement = announcements.find((announcement) => announcement.id === currentItem.id);
      if (selectedAnnouncement) {
        deleteAnnouncement({
          ...selectedAnnouncement,
          itemMetadata: {
            ...selectedAnnouncement.itemMetadata,
            isActive: value === 'Active', // Deleting announcement means, setting it to Inactive.
            updatedBy: userAuthDetails.Alias,
            updatedTime: getCurrentUTCTimeZoneInISO()
          }
        })
          .then((response) => {
            logger.info(`Successfully updates status of an announcement`);
            displayFlashMessage(eFinSuiteAnnouncementMessages.ANNOUNCEMENT_SUCCESSFULLY_UPDATED, 'success');
            setSelectedAnnouncements([]);
            refreshAnnouncements();
            setAnnouncementStatus(false);
            closeModal();
          })
          .catch((error: any) => {
            logger.error('Unable to updates status of an announcement', error);
            displayFlashMessage(eFinSuiteAnnouncementMessages.ANNOUNCEMENT_UPDATE_FAILED, 'error');
            setAnnouncementStatus(false);
            closeModal();
          });
      }
    }
  };

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
      <Table
        variant="full-page"
        stickyHeader={true}
        loading={announcementStatus}
        loadingText={'Loading announcements...'}
        items={items}
        selectionType={'single'}
        selectedItems={selectedAnnouncements}
        onSelectionChange={({ detail }) => setSelectedAnnouncements(detail.selectedItems)}
        columnDefinitions={announcementsColumnDefinitions as TableProps.ColumnDefinition<AnnouncementFlatTableEntity>[]}
        onColumnWidthsChange={saveWidths as any}
        visibleColumns={preferences.visibleContent}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        submitEdit={handleTableInlineEdit}
        header={
          <Header
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  variant="normal"
                  disabled={selectedAnnouncements.length === 0 || selectedAnnouncements[0].status === 'Inactive'}
                  onClick={navigateToAnnouncement}
                  iconName="edit"
                >
                  {'Edit'}
                </Button>
                <Button variant="primary" onClick={navigateToNewAnnouncement} iconName="add-plus">
                  {'New Announcement'}
                </Button>
              </SpaceBetween>
            }
            description={`Post general or product specific announcements which users can see on the homepage and app-specific landing pages`}
          >
            {`Announcements`}
          </Header>
        }
        filter={
          <PropertyFilter
            i18nStrings={propertyFilterI18nStrings('Announcements')}
            countText={getMatchesCountText(filteredItemsCount!)}
            expandToViewport={true}
            {...propertyFilterProps}
            query={query}
            onChange={(event) => {
              setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH_AND : event.detail);
            }}
          />
        }
        {...collectionProps}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />}
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            visibleContentOptions={DEFAULT_ANNOUNCEMENT_VISIBLE_CONTENT_OPTIONS}
            disablePageSizeOptions={false}
            disableResizableColumnsOptions={true}
          />
        }
      />
    </>
  );
};
