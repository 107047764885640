import { Box, Button, Checkbox, Container, Header, SpaceBetween, StatusIndicator, Table } from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import EmptyData from 'src/components/generic-components/EmptyData';
import { ApplicationWizard, ContactsEntity, OfficeHoursV2, ReportAndAdditionalIssuesEntity } from '../../AdminModels';
import ContactsForm, { ContactsFormMethods } from './ContactsForm';
import OfficeHoursFormV2, { OfficeHoursFormMethod } from './OfficeHoursFormV2';
import { ReportsAndAdditionalResourcesForm, ReportsAndAdditionalResourcesMethods } from './ReportsAndAdditionalResources';
import {
  APP_FINANCE_CONTACT_DEFINITION,
  APP_OFFICE_HOURS_DEFINITION,
  APP_REPORT_ISSUES_ADDITIONAL_RESOURCES_DEFINITION,
  removeQuotesFromString
} from './StepsUtilities';

interface HelpPanelConfigurationProps {
  setHelpPanelContent: any;
  applicationFormRef: React.RefObject<FormikProps<ApplicationWizard>>;
  formik: FormikProps<ApplicationWizard>;
}

const HelpPanelConfiguration: React.FC<HelpPanelConfigurationProps> = ({
  setHelpPanelContent,
  applicationFormRef,
  formik
}: HelpPanelConfigurationProps) => {
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  // Finance Contacts
  const financeContactFormRef = useRef<ContactsFormMethods>(null);
  const [contactDetails, setContactDetails] = useState<ContactsEntity[]>(
    formik.values?.infoPanelPageConfiguration?.financeContacts ? formik.values?.infoPanelPageConfiguration?.financeContacts : []
  );
  const [selectedContactDetails, setSelectedContactDetails] = useState<any[]>([]);

  const addNewFinanceContact = (addNewFinanceContactRow: ContactsEntity) => {
    const newRow: ContactsEntity[] = [addNewFinanceContactRow];
    const finalRows = newRow.concat(contactDetails);
    setContactDetails(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.financeContacts', finalRows);
  };

  const clearNewFinanceContacts = () => {
    setSelectedContactDetails([]);
  };

  const editFinanceContactRow = () => {
    if (financeContactFormRef.current) {
      financeContactFormRef.current.editSelectedRow(selectedContactDetails[0]);
      setSelectedContactDetails([]);
    }
  };

  const removeSelectedFinanceContacts = () => {
    const finalRows = contactDetails.filter((obj) => !selectedContactDetails.includes(obj));
    setContactDetails(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.financeContacts', finalRows);
    setSelectedContactDetails([]);
  };

  const updateFinanceContact = (contactDetail: ContactsEntity) => {
    const updatedFinanceContactDetailsList = contactDetails.map((obj) => {
      if (obj.id === contactDetail.id) {
        return contactDetail;
      }
      return obj;
    });
    setContactDetails(updatedFinanceContactDetailsList);
    formik.setFieldValue('infoPanelPageConfiguration.financeContacts', updatedFinanceContactDetailsList);
  };

  // FinTech Contacts
  const finTechContactFormRef = useRef<ContactsFormMethods>(null);
  const [finTechContactDetails, setFinTechContactDetails] = useState<ContactsEntity[]>(
    formik.values?.infoPanelPageConfiguration?.finTechContacts ? formik.values?.infoPanelPageConfiguration?.finTechContacts : []
  );
  const [selectedFinTechContactDetails, setSelectedFinTechContactDetails] = useState<any[]>([]);

  const addNewFinTechContact = (addNewFinTechContactRow: ContactsEntity) => {
    const newRow: ContactsEntity[] = [addNewFinTechContactRow];
    const finalRows = newRow.concat(finTechContactDetails);
    setFinTechContactDetails(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.finTechContacts', finalRows);
  };

  const clearFinTechContactsSelection = () => {
    setSelectedFinTechContactDetails([]);
  };

  const editFinTechContactRow = () => {
    if (finTechContactFormRef.current) {
      finTechContactFormRef.current.editSelectedRow(selectedFinTechContactDetails[0]);
      setSelectedFinTechContactDetails([]);
    }
  };

  const removeFintechContacts = () => {
    const finalRows = finTechContactDetails.filter((obj) => !selectedFinTechContactDetails.includes(obj));
    setFinTechContactDetails(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.finTechContacts', finalRows);
    setSelectedFinTechContactDetails([]);
  };

  const updateFinTechContact = (finTechContact: ContactsEntity) => {
    const finalRows = finTechContactDetails.map((obj) => {
      if (obj.id === finTechContact.id) {
        return finTechContact;
      }
      return obj;
    });
    setFinTechContactDetails(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.finTechContacts', finalRows);
  };

  // Office Hours
  const officeFormRef = useRef<OfficeHoursFormMethod>(null);
  const [officeHoursV2, setOfficeHoursV2] = useState<OfficeHoursV2[]>(
    formik.values?.infoPanelPageConfiguration?.officeHoursV2 ? formik.values?.infoPanelPageConfiguration?.officeHoursV2 : []
  );
  const [selectedOfficeHours, setSelectedOfficeHours] = useState<OfficeHoursV2[]>([]);

  const addNewOfficeHours = (officeHour: OfficeHoursV2) => {
    const newRow: OfficeHoursV2[] = [officeHour];
    const finalRows = newRow.concat(officeHoursV2);
    setOfficeHoursV2(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.officeHoursV2', finalRows);
  };

  const editOfficeHoursRow = () => {
    if (officeFormRef.current) {
      officeFormRef.current.editSelectedRow(selectedOfficeHours[0]);
      setSelectedOfficeHours([]);
    }
  };

  const removeOfficeHours = () => {
    const finalRows = officeHoursV2.filter((obj) => !selectedOfficeHours.includes(obj));
    setOfficeHoursV2(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.officeHoursV2', finalRows);
    setSelectedOfficeHours([]);
  };

  const updateOfficeHours = (officeHour: OfficeHoursV2) => {
    const finalRows = officeHoursV2.map((obj) => {
      if (obj.id === officeHour.id) {
        return officeHour;
      }
      return obj;
    });
    setOfficeHoursV2(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.officeHoursV2', finalRows);
  };

  const clearOfficeHours = () => {
    setSelectedOfficeHours([]);
  };

  // report issues
  const reportFormRef = useRef<ReportsAndAdditionalResourcesMethods>(null);
  const [reportIssues, setReportIssues] = useState<ReportAndAdditionalIssuesEntity[]>(
    formik.values?.infoPanelPageConfiguration?.reportIssues ? formik.values?.infoPanelPageConfiguration?.reportIssues : []
  );
  const [selectedReportIssues, setSelectedReportIssues] = useState<ReportAndAdditionalIssuesEntity[]>([]);

  const addNewReportIssues = (addNewReportIssues: ReportAndAdditionalIssuesEntity) => {
    const newRow: ReportAndAdditionalIssuesEntity[] = [addNewReportIssues];
    const finalRows = newRow.concat(reportIssues);
    setReportIssues(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.reportIssues', finalRows);
  };

  const editReportIssue = () => {
    if (reportFormRef.current) {
      reportFormRef.current.editSelectedRow(selectedReportIssues[0]);
      setSelectedReportIssues([]);
    }
  };

  const removeSelectedReportIssues = () => {
    const finalRows = reportIssues.filter((obj) => !selectedReportIssues.includes(obj));
    setReportIssues(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.reportIssues', finalRows);
    setSelectedReportIssues([]);
  };

  const updateReportIssues = (reportIssue: ReportAndAdditionalIssuesEntity) => {
    const finalRows = reportIssues.map((obj) => {
      if (obj.id === reportIssue.id) {
        return reportIssue;
      }
      return obj;
    });
    setReportIssues(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.reportIssues', finalRows);
  };

  const clearReportIssues = () => {
    setSelectedReportIssues([]);
  };

  // additional resources
  const additionalFormRef = useRef<ReportsAndAdditionalResourcesMethods>(null);
  const [additionalResources, setAdditionalResources] = useState<ReportAndAdditionalIssuesEntity[]>(
    formik.values?.infoPanelPageConfiguration?.additionalResources ? formik.values?.infoPanelPageConfiguration?.additionalResources : []
  );
  const [selectedAdditionalResources, setSelectedAdditionalResources] = useState<ReportAndAdditionalIssuesEntity[]>([]);

  const addAdditionalResources = (addAdditionalResources: ReportAndAdditionalIssuesEntity) => {
    const newRow: ReportAndAdditionalIssuesEntity[] = [addAdditionalResources];
    const finalRows = newRow.concat(additionalResources);
    setAdditionalResources(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.additionalResources', finalRows);
  };

  const editAdditionalResource = () => {
    if (additionalFormRef.current) {
      additionalFormRef.current.editSelectedRow(selectedAdditionalResources[0]);
      setSelectedAdditionalResources([]);
    }
  };

  const removeSelectedAdditionalResources = () => {
    const finalRows = additionalResources.filter((obj) => !selectedAdditionalResources.includes(obj));
    setAdditionalResources(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.additionalResources', finalRows);
    setSelectedAdditionalResources([]);
  };

  const updateAdditionalResourcesIssues = (additionalResource: ReportAndAdditionalIssuesEntity) => {
    const finalRows = additionalResources.map((obj) => {
      if (obj.id === additionalResource.id) {
        return additionalResource;
      }
      return obj;
    });
    setAdditionalResources(finalRows);
    formik.setFieldValue('infoPanelPageConfiguration.additionalResources', finalRows);
  };

  const clearAdditionalResources = () => {
    setSelectedAdditionalResources([]);
  };

  // Confirmation modal
  const onConfirm = (showModalInfo: ShowModalInfo) => {
    switch (showModalInfo.eventContentType) {
      case 'DELETE_FINANCE_CONTACTS':
        removeSelectedFinanceContacts();
        closeModal();
        break;
      case 'DELETE_FINTECH_CONTACTS':
        removeFintechContacts();
        closeModal();
        break;
      case 'DELETE_OFFICE_HOURS':
        removeOfficeHours();
        closeModal();
        break;
      case 'DELETE_REPORT_ISSUES':
        removeSelectedReportIssues();
        closeModal();
        break;
      case 'DELETE_ADDITIONAL_RESOURCES':
        removeSelectedAdditionalResources();
        closeModal();
        break;
      default:
        break;
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />

      {/* <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}> */}
      <div>
        {/* Contacts - Finance    */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`Finance team key contacts and optional descriptions to differentiate each contact (e.g., by module supported)`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.hasFinanceContacts', detail.checked);
                        clearNewFinanceContacts();
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.hasFinanceContacts', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration?.hasFinanceContacts || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                Contacts - Finance
              </Header>
            }
          >
            {/* Contacts - Finance content  */}
            {formik.values.infoPanelPageConfiguration?.hasFinanceContacts && (
              <SpaceBetween size="m" direction="vertical">
                <ContactsForm
                  ref={financeContactFormRef}
                  hasContacts={formik.values.infoPanelPageConfiguration.hasFinanceContacts}
                  addNewContact={addNewFinanceContact}
                  updateContact={updateFinanceContact}
                ></ContactsForm>

                <Table
                  variant="embedded"
                  header={
                    <Header
                      description={
                        formik.touched.infoPanelPageConfiguration?.financeContacts &&
                        formik.errors.infoPanelPageConfiguration?.financeContacts && (
                          <StatusIndicator type="error">
                            {formik.touched.infoPanelPageConfiguration?.financeContacts &&
                              removeQuotesFromString(JSON.stringify(formik.errors.infoPanelPageConfiguration?.financeContacts))}
                          </StatusIndicator>
                        )
                      }
                      actions={
                        <>
                          <SpaceBetween size="m" direction="horizontal">
                            <Button
                              formAction="none"
                              onClick={() => {
                                editFinanceContactRow();
                              }}
                              disabled={selectedContactDetails?.length !== 1}
                            >
                              {`Edit`}
                            </Button>
                            <Button
                              formAction="none"
                              disabled={selectedContactDetails?.length === 0 || !formik.values.infoPanelPageConfiguration?.hasFinanceContacts}
                              onClick={() => {
                                setShowModalInfo({
                                  showModal: true,
                                  eventHeader: 'Delete finance contact',
                                  eventContentType: 'DELETE_FINANCE_CONTACTS'
                                });
                              }}
                            >
                              {`Delete (${selectedContactDetails?.length}) selected`}
                            </Button>
                          </SpaceBetween>
                        </>
                      }
                    ></Header>
                  }
                  empty={<EmptyData />}
                  columnDefinitions={APP_FINANCE_CONTACT_DEFINITION}
                  items={contactDetails}
                  selectionType="multi"
                  selectedItems={selectedContactDetails}
                  onSelectionChange={({ detail }) => setSelectedContactDetails(detail.selectedItems)}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>

        {/* Contacts - FinTech    */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`FinTech team key contacts and optional descriptions to differentiate each contact (e.g., PMO or Tech)`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.hasFinTechContacts', detail.checked);
                        clearFinTechContactsSelection();
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.hasFinTechContacts', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration?.hasFinTechContacts || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                Contacts - FinTech
              </Header>
            }
          >
            {/* FinTech Contacts  */}
            {formik.values.infoPanelPageConfiguration?.hasFinTechContacts && (
              <SpaceBetween size="m" direction="vertical">
                <ContactsForm
                  ref={finTechContactFormRef}
                  hasContacts={formik.values.infoPanelPageConfiguration.hasFinTechContacts}
                  addNewContact={addNewFinTechContact}
                  updateContact={updateFinTechContact}
                ></ContactsForm>

                <Table
                  variant="embedded"
                  header={
                    <Header
                      description={
                        formik.touched.infoPanelPageConfiguration?.finTechContacts &&
                        formik.errors.infoPanelPageConfiguration?.finTechContacts && (
                          <StatusIndicator type="error">
                            {formik.touched.infoPanelPageConfiguration?.finTechContacts &&
                              removeQuotesFromString(JSON.stringify(formik.errors.infoPanelPageConfiguration?.finTechContacts))}
                          </StatusIndicator>
                        )
                      }
                      actions={
                        <>
                          <SpaceBetween size="m" direction="horizontal">
                            <Button
                              formAction="none"
                              onClick={() => {
                                editFinTechContactRow();
                              }}
                              disabled={selectedFinTechContactDetails?.length !== 1}
                            >
                              {`Edit`}
                            </Button>
                            <Button
                              formAction="none"
                              disabled={selectedFinTechContactDetails?.length === 0 || !formik.values.infoPanelPageConfiguration?.hasFinTechContacts}
                              onClick={() => {
                                setShowModalInfo({
                                  showModal: true,
                                  eventHeader: 'Delete fintech contact',
                                  eventContentType: 'DELETE_FINTECH_CONTACTS'
                                });
                              }}
                            >
                              {`Delete (${selectedFinTechContactDetails?.length}) selected`}
                            </Button>
                          </SpaceBetween>
                        </>
                      }
                    ></Header>
                  }
                  empty={<EmptyData />}
                  columnDefinitions={APP_FINANCE_CONTACT_DEFINITION}
                  items={finTechContactDetails}
                  selectionType="multi"
                  selectedItems={selectedFinTechContactDetails}
                  onSelectionChange={({ detail }) => setSelectedFinTechContactDetails(detail.selectedItems)}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>

        {/* Office Hours */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`Recurring meetings or one-time event for users to learn, ask questions, or provide feedback about the app`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.hasOfficeHours', detail.checked);
                        clearOfficeHours();
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.hasOfficeHours', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration?.hasOfficeHours || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                Office Hours
              </Header>
            }
          >
            {formik.values.infoPanelPageConfiguration?.hasOfficeHours && (
              <SpaceBetween size="m" direction="vertical">
                <OfficeHoursFormV2
                  ref={officeFormRef}
                  hasOfficeHours={formik.values.infoPanelPageConfiguration.hasOfficeHours}
                  addNewOfficeHours={addNewOfficeHours}
                  updateOfficeHours={updateOfficeHours}
                />

                <Table
                  variant="embedded"
                  header={
                    <Header
                      description={
                        formik.touched.infoPanelPageConfiguration?.officeHoursV2 &&
                        formik.errors.infoPanelPageConfiguration?.officeHoursV2 && (
                          <StatusIndicator type="error">
                            {formik.touched.infoPanelPageConfiguration?.officeHoursV2 &&
                              removeQuotesFromString(JSON.stringify(formik.errors.infoPanelPageConfiguration?.officeHoursV2))}
                          </StatusIndicator>
                        )
                      }
                      actions={
                        <>
                          <SpaceBetween size="m" direction="horizontal">
                            <Button
                              formAction="none"
                              disabled={selectedOfficeHours?.length !== 1}
                              onClick={() => {
                                editOfficeHoursRow();
                              }}
                            >
                              {`Edit`}
                            </Button>
                            <Button
                              formAction="none"
                              disabled={selectedOfficeHours?.length === 0}
                              onClick={() => {
                                setShowModalInfo({
                                  showModal: true,
                                  eventHeader: 'Delete office hours',
                                  eventContentType: 'DELETE_OFFICE_HOURS'
                                });
                              }}
                            >
                              {`Delete (${selectedOfficeHours?.length}) selected`}
                            </Button>
                          </SpaceBetween>
                        </>
                      }
                    ></Header>
                  }
                  empty={<EmptyData />}
                  columnDefinitions={APP_OFFICE_HOURS_DEFINITION}
                  items={officeHoursV2}
                  selectionType="multi"
                  selectedItems={selectedOfficeHours}
                  onSelectionChange={({ detail }) => setSelectedOfficeHours(detail.selectedItems)}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>

        {/* Report Issues - SIM  */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`Support ticket links where users can report bugs or ask for additional support`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.hasReportIssues', detail.checked);
                        clearReportIssues();
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.hasReportIssues', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration?.hasReportIssues || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                Report Issues - SIM
              </Header>
            }
          >
            {formik.values.infoPanelPageConfiguration?.hasReportIssues && (
              <SpaceBetween size="m" direction="vertical">
                <ReportsAndAdditionalResourcesForm
                  ref={reportFormRef}
                  hasEntities={formik.values.infoPanelPageConfiguration.hasReportIssues}
                  addNewEntity={addNewReportIssues}
                  updateEntity={updateReportIssues}
                ></ReportsAndAdditionalResourcesForm>

                <Table
                  variant="embedded"
                  header={
                    <Header
                      description={
                        formik.touched.infoPanelPageConfiguration?.reportIssues &&
                        formik.errors.infoPanelPageConfiguration?.reportIssues && (
                          <StatusIndicator type="error">
                            {formik.touched.infoPanelPageConfiguration?.reportIssues &&
                              removeQuotesFromString(JSON.stringify(formik.errors.infoPanelPageConfiguration?.reportIssues))}
                          </StatusIndicator>
                        )
                      }
                      actions={
                        <>
                          <SpaceBetween size="m" direction="horizontal">
                            <Button
                              formAction="none"
                              onClick={() => {
                                editReportIssue();
                              }}
                              disabled={selectedReportIssues?.length !== 1}
                            >
                              {`Edit`}
                            </Button>
                            <Button
                              formAction="none"
                              disabled={selectedReportIssues?.length === 0 || !formik.values.infoPanelPageConfiguration?.hasReportIssues}
                              onClick={() => {
                                setShowModalInfo({
                                  showModal: true,
                                  eventHeader: 'Delete report issue',
                                  eventContentType: 'DELETE_REPORT_ISSUES'
                                });
                              }}
                            >
                              {`Delete (${selectedReportIssues?.length}) selected`}
                            </Button>
                          </SpaceBetween>
                        </>
                      }
                    ></Header>
                  }
                  empty={<EmptyData />}
                  columnDefinitions={APP_REPORT_ISSUES_ADDITIONAL_RESOURCES_DEFINITION}
                  items={reportIssues}
                  selectionType="multi"
                  selectedItems={selectedReportIssues}
                  onSelectionChange={({ detail }) => setSelectedReportIssues(detail.selectedItems)}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>

        {/* Additional Resources  */}
        <Box margin={{ bottom: 'l' }}>
          <Container
            header={
              <Header
                variant="h2"
                description={`Guides and documentation outside of the portal or other useful external links (e.g., wikis)`}
                actions={
                  <>
                    <Checkbox
                      onChange={({ detail }) => {
                        formik.setFieldValue('infoPanelPageConfiguration.hasAdditionalResources', detail.checked);
                        clearAdditionalResources();
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('infoPanelPageConfiguration.hasAdditionalResources', true);
                      }}
                      checked={formik.values?.infoPanelPageConfiguration?.hasAdditionalResources || false}
                    >
                      Enable
                    </Checkbox>
                  </>
                }
              >
                Additional Resources
              </Header>
            }
          >
            {formik.values.infoPanelPageConfiguration?.hasAdditionalResources && (
              <SpaceBetween size="m" direction="vertical">
                <ReportsAndAdditionalResourcesForm
                  ref={additionalFormRef}
                  hasEntities={formik.values.infoPanelPageConfiguration.hasAdditionalResources}
                  addNewEntity={addAdditionalResources}
                  updateEntity={updateAdditionalResourcesIssues}
                ></ReportsAndAdditionalResourcesForm>

                <Table
                  variant="embedded"
                  header={
                    <Header
                      description={
                        formik.touched.infoPanelPageConfiguration?.additionalResources &&
                        formik.errors.infoPanelPageConfiguration?.additionalResources && (
                          <StatusIndicator type="error">
                            {formik.touched.infoPanelPageConfiguration?.additionalResources &&
                              removeQuotesFromString(JSON.stringify(formik.errors.infoPanelPageConfiguration?.additionalResources))}
                          </StatusIndicator>
                        )
                      }
                      actions={
                        <>
                          <SpaceBetween size="m" direction="horizontal">
                            <Button
                              formAction="none"
                              onClick={() => {
                                editAdditionalResource();
                              }}
                              disabled={selectedAdditionalResources?.length !== 1}
                            >
                              {`Edit`}
                            </Button>
                            <Button
                              formAction="none"
                              disabled={
                                selectedAdditionalResources?.length === 0 || !formik.values.infoPanelPageConfiguration?.hasAdditionalResources
                              }
                              onClick={() => {
                                setShowModalInfo({
                                  showModal: true,
                                  eventHeader: 'Delete additional resource',
                                  eventContentType: 'DELETE_ADDITIONAL_RESOURCES'
                                });
                              }}
                            >
                              {`Delete (${selectedAdditionalResources?.length}) selected`}
                            </Button>
                          </SpaceBetween>
                        </>
                      }
                    ></Header>
                  }
                  empty={<EmptyData />}
                  columnDefinitions={APP_REPORT_ISSUES_ADDITIONAL_RESOURCES_DEFINITION}
                  items={additionalResources}
                  selectionType="multi"
                  selectedItems={selectedAdditionalResources}
                  onSelectionChange={({ detail }) => setSelectedAdditionalResources(detail.selectedItems)}
                />
              </SpaceBetween>
            )}
          </Container>
        </Box>
      </div>
    </>
  );
};

export default HelpPanelConfiguration;
