import { Box, Button, ColumnLayout, Container, FormField, Multiselect, MultiselectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import { logger } from 'src/logger';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';
import { dateTimeComparatorMMMYYYYFormat, getCurrentTime, getTimeDifference, sortedDateArrayMMMYYYYFormat } from 'src/utilities/DateTimeUtilities';
import { filtersLabel } from '../FinTechOpsCommonElements';
import { FinTechOpsMessages, GraphDateFormat_MMM_YY } from '../FinTechOpsConstants';
import {
  CreateBarChartData,
  CreateLineChartData,
  objectToOptions,
  optionsToObject,
  parseObjectToMultiSelectOptions_Sorted,
  returnFilterAgeSorted,
  returnSelectedOptionsAge
} from '../FinTechOpsDataProcessingFunctions';
import { useUserMetricsContext } from './UserMetricsContext';
import {
  applyFiltersUserMetrics,
  createUserMetricsDetailsTabData,
  createUserMetricsGraphDataV2,
  createUserMetricsMasterFilterData,
  returnSelectedAllAppNameCustom
} from './UserMetricsDataProcessing';
import { UserMetricsMasterFilters, UserMetricsParsedData } from './UserMetricsInterfaces';

export const UserMetricsSelectionFilters: React.FC = () => {
  const userMetricsContext = useUserMetricsContext();
  const finOpsContext = useFinTechContext();

  const [lastRefreshed, setLastRefreshed] = useState('');
  const [masterFilters, setMasterFilters] = useState<UserMetricsMasterFilters>({} as UserMetricsMasterFilters);

  const [managers, setManagers] = useState<MultiselectProps.Options>([]);
  const [team, setTeam] = useState<MultiselectProps.Options>([]);
  const [applicationType, setApplicationType] = useState<MultiselectProps.Options>([]);
  const [applicationName, setApplicationName] = useState<MultiselectProps.Options>([]);
  const [month, setMonth] = useState<MultiselectProps.Options>([]);
  const [userLogin, setUserLogin] = useState<MultiselectProps.Options>([]);

  const [rawmanagers, setRawManagers] = useState<MultiselectProps.Options>([]);
  const [rawteam, setRawTeam] = useState<MultiselectProps.Options>([]);
  const [rawapplicationType, setRawApplicationType] = useState<MultiselectProps.Options>([]);
  const [rawapplicationName, setRawApplicationName] = useState<MultiselectProps.Options>([]);
  const [rawmonth, setRawMonth] = useState<MultiselectProps.Options>([]);
  const [rawUserLogin, setRawUserLogin] = useState<MultiselectProps.Options>([]);

  const [isRawFilter, setIsRawFilter] = useState(true);

  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    userMetricsContext.setDetailsStatus('loading');
    userMetricsContext.setOverViewStatus('loading');
    if (finOpsContext.finTechOpsUserMetricsDataStatus == 'finished') {
      const userMetricsFilterValues = getUserMetricsMasterFilters(finOpsContext.userMetricsWithUserData.data);
      updateAllUserMetricsData(
        userMetricsFilterValues.managerStr,
        userMetricsFilterValues.teamStr,
        userMetricsFilterValues.applicationTypeStr,
        userMetricsFilterValues.applicationNameStr,
        userMetricsFilterValues.monthStr,
        userMetricsFilterValues.userLoginStr
      );
      setLastRefreshed(finOpsContext?.userMetricsWithUserData?.metadata?.last_updated_at);
      setIsResetFilter(false);
    }
    if (finOpsContext.finTechOpsUserMetricsDataStatus == 'error') {
      userMetricsContext.displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
      userMetricsContext.setDetailsStatus('error');
      userMetricsContext.setOverViewStatus('error');
    }
  }, [finOpsContext.finTechOpsUserMetricsDataStatus, isResetFilter]);

  const getUserMetricsMasterFilters = (userMetricsData: UserMetricsParsedData[]) => {
    const getFilters: UserMetricsMasterFilters = createUserMetricsMasterFilterData(userMetricsData);

    setMasterFilters(getFilters);
    const _managers = objectToOptions(getFilters.managers);
    const _team = objectToOptions(getFilters.team);
    const _applicationType = objectToOptions(getFilters.application_type);
    const _applicationName = returnSelectedAllAppNameCustom(getFilters.application_name);
    const _month = objectToOptions(getFilters.month);
    const _userLogin = objectToOptions(getFilters.user_login);

    setManagers(_managers);
    setTeam(_team);
    setApplicationType(_applicationType);
    setApplicationName(_applicationName);
    setMonth(_month);
    setUserLogin(_userLogin);

    //one time setting values for placeholder value comparison with raw filter and currently applied filter
    if (isRawFilter === true) {
      setRawManagers(_managers);
      setRawTeam(_team);
      setRawApplicationType(_applicationType);
      setRawApplicationName(_applicationName);
      setRawMonth(_month);
      setRawUserLogin(_userLogin);
    }
    setIsRawFilter(false);

    const managerStr = optionsToObject(_managers);
    const teamStr = optionsToObject(_team);
    const applicationTypeStr = optionsToObject(_applicationType);
    const applicationNameStr = optionsToObject(_applicationName);
    const monthStr = returnSelectedOptionsAge(_month);
    const userLoginStr = optionsToObject(_userLogin);

    return { managerStr, teamStr, applicationTypeStr, applicationNameStr, monthStr, userLoginStr };
  };

  const updateAllUserMetricsData = (
    managers: string[],
    team: string[],
    application_type: string[],
    application_name: string[],
    month: string[],
    user_login: string[]
  ) => {
    userMetricsContext.setDetailsStatus('loading');
    userMetricsContext.setOverViewStatus('loading');
    try {
      const start = getCurrentTime();
      const selectedFilters: UserMetricsMasterFilters = {
        managers: managers,
        team: team,
        application_type: application_type,
        application_name: application_name,
        month: month,
        user_login: user_login
      };
      // Getting Filtered user metrics data with users grain
      const [filteredWithoutMonthData, appliedFiltersData]: [UserMetricsParsedData[], UserMetricsParsedData[]] = applyFiltersUserMetrics(
        finOpsContext.userMetricsWithUserData.data,
        selectedFilters
      );

      getUserMetricsMasterFilters(appliedFiltersData);

      const calculatedUserMetricsGraphData = createUserMetricsGraphDataV2(finOpsContext.userMetricsWithUserData.data, selectedFilters);
      const calculatedUserMetricsTableData = createUserMetricsDetailsTabData(appliedFiltersData, filteredWithoutMonthData, month);

      const totalApplicationViewsByTeamGraph = CreateBarChartData(calculatedUserMetricsGraphData.appViewerShipTeamGraph);
      const top5ApplicationViews = CreateLineChartData(calculatedUserMetricsGraphData.top5AppViewershipGraph, GraphDateFormat_MMM_YY, 'month', false);
      const totalUniqueViewsGraph = CreateLineChartData(calculatedUserMetricsGraphData.uniqueUsersByAppGraph, GraphDateFormat_MMM_YY, 'month', false);
      const totalViewsGraph = CreateLineChartData(calculatedUserMetricsGraphData.totalAppViewsGraph, GraphDateFormat_MMM_YY, 'month', false);

      // console.log('Calculated Tital App View by Team - Bar chart ', JSON.stringify(totalApplicationViewsByTeamGraph));

      userMetricsContext.setAppViewershipByTeamData(totalApplicationViewsByTeamGraph.dataSeries);
      userMetricsContext.setAppViewershipByTeamXDomain(totalApplicationViewsByTeamGraph.xDomain);
      userMetricsContext.setAppViewershipByTeamYDomain(totalApplicationViewsByTeamGraph.yDomain);

      userMetricsContext.setTop5AppsViewership(top5ApplicationViews.dataSeries);
      userMetricsContext.setTop5AppsViewershipXDomain(top5ApplicationViews.xDomain);
      userMetricsContext.setTop5AppsViewershipYDomain(top5ApplicationViews.yDomain);

      userMetricsContext.setUniqueUsersByApplication(totalUniqueViewsGraph.dataSeries);
      userMetricsContext.setUniqueUsersByApplicationXDomain(totalUniqueViewsGraph.xDomain);
      userMetricsContext.setUniqueUsersByApplicationYDomain(totalUniqueViewsGraph.yDomain);

      userMetricsContext.setTotalApplicationViews(totalViewsGraph.dataSeries);
      userMetricsContext.setTotalApplicationViewsXDomain(totalViewsGraph.xDomain);
      userMetricsContext.setTotalApplicationViewsYDomain(totalViewsGraph.yDomain);

      userMetricsContext.setAggregatedViewsTableData(calculatedUserMetricsTableData.aggregatedViewsTable);
      userMetricsContext.setApplicationUsersTableData(calculatedUserMetricsTableData.aggregatedUsersTable);

      userMetricsContext.setAggregatedSubApplicationUniqueUsersTableData(calculatedUserMetricsTableData.aggregatedSubApplicationUniqueUsersTableData);
      userMetricsContext.setAggregatedSubApplicationViewsTableData(calculatedUserMetricsTableData.aggregatedSubApplicationViewsTableData);

      //Setting table data for application footprint details
      userMetricsContext.setApplicationFootprintTableData(calculatedUserMetricsTableData.applicationFootprintTable);
      //Setting table data for sub application footprint details
      userMetricsContext.setSubApplicationFootprintTableData(calculatedUserMetricsTableData.subApplicationFootprintTable);
      userMetricsContext.setApplicationFootprintContainer(calculatedUserMetricsTableData.applicationFootprintContainer);
      //Setting table data for application user view details
      userMetricsContext.setApplicationUserViewTableData(calculatedUserMetricsTableData.applicationUserViewTable);
      //Setting table data for sub application user view details
      userMetricsContext.setSubApplicationUserViewTableData(calculatedUserMetricsTableData.subApplicationUserViewTable);

      userMetricsContext.setDetailsStatus('finished');
      userMetricsContext.setOverViewStatus('finished');
      const elapsed = getTimeDifference(start);
      logger.info(`Fetched calculated data for UserMetrics in ${elapsed} ms`);
    } catch (error: any) {
      logger.error('Unable to Process User Metrics Data', error);
      userMetricsContext.displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
      userMetricsContext.setDetailsStatus('error');
      userMetricsContext.setOverViewStatus('error');
    }
  };

  const triggerCall = () => {
    const managerStr = optionsToObject(managers);
    const teamStr = optionsToObject(team);
    const applicationTypeStr = optionsToObject(applicationType);
    const applicationNameStr = optionsToObject(applicationName);
    const monthStr = returnSelectedOptionsAge(month);
    const userLoginStr = optionsToObject(userLogin);
    updateAllUserMetricsData(managerStr, teamStr, applicationTypeStr, applicationNameStr, monthStr, userLoginStr);
  };

  return (
    <div>
      <SpaceBetween size="l">
        <Box padding={{ top: 'xl' }}>
          <SpaceBetween size="s">
            <Container
              fitHeight
              header={
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Box variant="h2">User Metrics</Box>
                  <LastRefreshedAt lastRefreshedDateTime={lastRefreshed} />
                  <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
                </SpaceBetween>
              }
            >
              <Box>
                <ColumnLayout columns={3} minColumnWidth={20}>
                  <FormField label={filtersLabel('Team')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(team, 'Team', optionsToObject(rawteam))}
                      options={parseObjectToMultiSelectOptions_Sorted(masterFilters.team, 'All Teams')}
                      selectedOptions={team}
                      onChange={({ detail }) => setTeam(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Teams"
                      statusType={finOpsContext.finTechOpsUserMetricsDataStatus}
                      disabled={userMetricsContext.overViewStatus === 'loading' || userMetricsContext.detailsStatus === 'loading' ? true : false}
                    />
                  </FormField>

                  <FormField label={filtersLabel('Managers')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(managers, 'Managers', optionsToObject(rawmanagers))}
                      options={parseObjectToMultiSelectOptions_Sorted(masterFilters.managers, 'All Managers')}
                      selectedOptions={managers}
                      onChange={({ detail }) => setManagers(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Managers"
                      statusType={finOpsContext.finTechOpsUserMetricsDataStatus}
                      disabled={userMetricsContext.overViewStatus === 'loading' || userMetricsContext.detailsStatus === 'loading' ? true : false}
                    />
                  </FormField>

                  <FormField label={filtersLabel('Application')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(applicationType, 'Application', optionsToObject(rawapplicationType))}
                      options={parseObjectToMultiSelectOptions_Sorted(masterFilters.application_type, 'All Application')}
                      selectedOptions={applicationType}
                      onChange={({ detail }) => setApplicationType(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Application"
                      statusType={finOpsContext.finTechOpsUserMetricsDataStatus}
                      disabled={userMetricsContext.overViewStatus === 'loading' || userMetricsContext.detailsStatus === 'loading' ? true : false}
                    />
                  </FormField>

                  <FormField label={filtersLabel('Sub Application')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(applicationName, 'Sub Application', optionsToObject(rawapplicationName))}
                      options={parseObjectToMultiSelectOptions_Sorted(masterFilters.application_name, 'All Sub Application')}
                      selectedOptions={applicationName}
                      onChange={({ detail }) => setApplicationName(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Sub Application"
                      statusType={finOpsContext.finTechOpsUserMetricsDataStatus}
                      disabled={userMetricsContext.overViewStatus === 'loading' || userMetricsContext.detailsStatus === 'loading' ? true : false}
                    />
                  </FormField>

                  <FormField label={filtersLabel('Month')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(month, 'Months', optionsToObject(rawmonth))}
                      options={returnFilterAgeSorted(sortedDateArrayMMMYYYYFormat(masterFilters.month), 'All Months')}
                      selectedOptions={month}
                      onChange={({ detail }) => setMonth(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading Months"
                      statusType={finOpsContext.finTechOpsUserMetricsDataStatus}
                      disabled={userMetricsContext.overViewStatus === 'loading' || userMetricsContext.detailsStatus === 'loading' ? true : false}
                    />
                  </FormField>

                  <FormField label={filtersLabel('User Login')}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(userLogin, 'User Login', optionsToObject(rawUserLogin))}
                      options={returnFilterAgeSorted(masterFilters.user_login, 'All User Login')}
                      selectedOptions={userLogin}
                      onChange={({ detail }) => setUserLogin(detail.selectedOptions)}
                      onBlur={({ detail }) => triggerCall()}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText="Loading User Login"
                      statusType={finOpsContext.finTechOpsUserMetricsDataStatus}
                      disabled={userMetricsContext.overViewStatus === 'loading' || userMetricsContext.detailsStatus === 'loading' ? true : false}
                    />
                  </FormField>
                </ColumnLayout>
              </Box>
            </Container>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </div>
  );
};
