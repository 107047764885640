import { Button, CollectionPreferencesProps, Input, Select, SelectProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { ApplicationFlatTableEntity } from 'src/components/context/AppContextModels';
import { DEFAULT_LARGE_PAGE_SIZE } from 'src/components/generic-components/TableCommons';
import { AppNameWithLink } from '../../all-apps/AllApps';
import { SHORT_DESCRIPTION_ERROR_MESSAGE, shortDescriptionRegexPattern } from './admin-manage-application/StepsValidations';
import React from 'react';

export const DEFAULT_VISIBLE_CONTENT = [
  'status',
  'applicationName',
  'applicationLink',
  'shortApplicationDescription'
  // 'applicationOwner',
  // 'glAccount',
  // 'toolUI'
];

export const DEFAULT_VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Properties',
    options: [
      { id: 'status', label: 'Status', editable: false },
      { id: 'applicationName', label: 'Application', editable: false },
      { id: 'applicationLink', label: 'Link', editable: false },
      { id: 'shortApplicationDescription', label: 'Description', editable: false },
      { id: 'applicationOwner', label: 'Team', editable: true },
      { id: 'glAccount', label: 'GL Account', editable: true },
      { id: 'toolUI', label: 'Product UI', editable: true }
    ]
  }
];

export const APPLICATIONS_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: DEFAULT_LARGE_PAGE_SIZE,
  visibleContent: DEFAULT_VISIBLE_CONTENT,
  wrapLines: false,
  stripedRows: false
};

export const serverSideErrorsStore = new Map();
export const APPLICATION_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ApplicationFlatTableEntity>[] = [
  {
    id: 'status',
    header: 'Status',
    width: 176, // Cloudscape recommends to set a minimum width of at least 176px for columns that are editable.
    cell: (item) => (
      <StatusIndicator colorOverride={item.status === 'Active' ? 'green' : 'red'} type={item.status === 'Active' ? 'success' : 'error'}>
        {item.status}
      </StatusIndicator>
    ),
    editConfig: {
      ariaLabel: 'Status',
      editIconAriaLabel: 'editable',
      errorIconAriaLabel: 'Error',
      editingCell: (item, { currentValue, setValue }) => {
        const options = [
          { value: 'Active', label: 'Active' },
          { value: 'Inactive', label: 'Inactive' }
        ] as SelectProps.Option[];
        return (
          <Select
            autoFocus={true}
            expandToViewport={true}
            ariaLabel="Select desired state"
            options={options}
            onChange={(event) => {
              setValue(event.detail.selectedOption.value);
            }}
            selectedOption={options.find((option) => option.value === (currentValue ?? item.status)) || {}}
          />
        );
      }
    },
    sortingField: 'status'
  },
  {
    id: 'applicationName',
    header: 'Application',
    width: 200, // width verified
    cell: (item) => <AppNameWithLink appName={item.applicationName} appId={item.id} />,
    sortingField: 'applicationName'
  },
  {
    id: 'applicationLink',
    header: 'Link',
    width: 70,
    cell: (item) => (
      <>
        {item.applicationLink && (
          <Button iconName="external" variant="inline-icon" onClick={() => window.open(item.applicationLink, '_blank')}></Button>
        )}
        {!item.applicationLink && <></>}
      </>
    )
  },
  {
    id: 'shortApplicationDescription',
    header: 'Short Description',
    width: 480,
    cell: (item) => item.shortApplicationDescription,
    editConfig: {
      ariaLabel: 'Short Description',
      editIconAriaLabel: 'editable',
      errorIconAriaLabel: 'Error',
      validation(item, value) {
        if (serverSideErrorsStore.has(item)) {
          if (value) {
            serverSideErrorsStore.set(item, shortDescriptionRegexPattern.test(value) ? undefined : SHORT_DESCRIPTION_ERROR_MESSAGE);
          }
          return serverSideErrorsStore.get(item);
        }
      },
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={true}
            ariaLabel="Edit short description"
            value={currentValue ?? item.shortApplicationDescription}
            onChange={(event) => {
              setValue(event.detail.value);
            }}
            placeholder="Enter short description"
          />
        );
      }
    },
    sortingField: 'shortApplicationDescription'
  },
  {
    id: 'applicationOwner',
    header: 'Team',
    width: 120,
    cell: (item) => item.applicationOwner,
    sortingField: 'applicationOwner'
  },
  {
    id: 'glAccount',
    header: 'GL Account',
    width: 200,
    cell: (item: ApplicationFlatTableEntity) => item.glAccount,
    sortingField: 'glAccount'
  },
  {
    id: 'toolUI',
    header: 'Product UI',
    width: 50,
    cell: (item) => item.toolUI,
    sortingField: 'toolUI'
  }
];
