import {
  Box,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Grid,
  Icon,
  Multiselect,
  MultiselectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import { logger } from 'src/logger';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';
import { getCurrentTime, getTimeDifference } from 'src/utilities/DateTimeUtilities';
import { filtersLabel } from '../FinTechOpsCommonElements';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import {
  CreateBarChartData,
  CreateLineChartData,
  parseObjectToMultiSelectOptions_Sorted,
  objectToOptions,
  optionsToObject
} from '../FinTechOpsDataProcessingFunctions';
import { useSasContext } from './SasContext';
import { applyFiltersSas, createMasterFilterDataSas, createSasDetailsTabDataV2, createSasGraphDatav2 } from './SasDataProcessing';
import { SasMasterFilters, SasParsedData } from './SasInterfaces';

export function SasSelectionFilters() {
  const [masterFilters, setMasterFilters] = useState<SasMasterFilters>({} as SasMasterFilters);
  const [managers, setManagers] = useState<MultiselectProps.Options>([]);
  const [assignees, setAssignees] = useState<MultiselectProps.Options>([]);
  const [team, setTeam] = useState<MultiselectProps.Options>([]);

  const [rawmanagers, setRawManagers] = useState<MultiselectProps.Options>([]);
  const [rawassignees, setRawAssignees] = useState<MultiselectProps.Options>([]);
  const [rawteam, setRawTeam] = useState<MultiselectProps.Options>([]);

  const finOpsContext = useFinTechContext();
  const sasContext = useSasContext();

  const [lastRefreshed, setLastRefreshed] = useState('');
  const [isRawFilter, setIsRawFilter] = useState(true);

  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    sasContext.setDetailsStatus('loading');
    sasContext.setOverViewStatus('loading');
    if (finOpsContext.finTechOpsSasDataStatus == 'finished') {
      const sasFilterValues = getSasMasterFilters(finOpsContext.sasData.data);
      updateSasAllData(sasFilterValues.managerStr, sasFilterValues.assigneeStr, sasFilterValues.teamStr);
      setLastRefreshed(finOpsContext?.sasData?.metadata?.last_updated_at);
      setIsResetFilter(false);
    }
    if (finOpsContext.finTechOpsSasDataStatus == 'error') {
      sasContext.displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
      sasContext.setDetailsStatus('error');
      sasContext.setOverViewStatus('error');
    }
  }, [finOpsContext.finTechOpsSasDataStatus, isResetFilter]);

  const getSasMasterFilters = (sasData: SasParsedData[]) => {
    try {
      const getFilters: SasMasterFilters = createMasterFilterDataSas(sasData);
      setMasterFilters(getFilters);

      const _managers = objectToOptions(getFilters.managers);
      const _assignees = objectToOptions(getFilters.assignees);
      const _teamName = objectToOptions(getFilters.team);

      setManagers(_managers);
      setAssignees(_assignees);
      setTeam(_teamName);

      //one time setting values for placeholder value comparison with raw filter and currently applied filter
      if (isRawFilter === true) {
        setRawManagers(_managers);
        setRawAssignees(_assignees);
        setRawTeam(_teamName);
      }
      setIsRawFilter(false);

      const managerStr = optionsToObject(_managers);
      const assigneeStr = optionsToObject(_assignees);
      const teamStr = optionsToObject(_teamName);

      return { managerStr, assigneeStr, teamStr };
    } catch (error: any) {
      logger.error('getSasMasterFilters: Unable to update SAS filters', error);
      sasContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      sasContext.setDetailsStatus('error');
      sasContext.setOverViewStatus('error');
      return { managerStr: [], assigneeStr: [], teamStr: [] };
    }
  };

  const updateSasAllData = (_managers: string[], _assignees: string[], _team: string[]) => {
    sasContext.setDetailsStatus('loading');
    sasContext.setOverViewStatus('loading');
    try {
      const appliedFiltersData: SasParsedData[] = applyFiltersSas(finOpsContext.sasData.data, {
        managers: _managers,
        assignees: _assignees,
        team: _team
      });

      getSasMasterFilters(appliedFiltersData);
      const start = getCurrentTime();

      const calculatedGraphData = createSasGraphDatav2(appliedFiltersData);

      const calculatedDetalsTableDataV2 = createSasDetailsTabDataV2(appliedFiltersData);
      // console.log('calculatedDetalsTableDataV2 ', JSON.stringify(calculatedDetalsTableDataV2));

      const openRiskResourceGraphData = CreateBarChartData(calculatedGraphData.openRiskResourceTotalGraph);
      const openRiskTotalGraphData = CreateBarChartData(calculatedGraphData.openRiskTotalMonthlyGraph);
      const campaignsGraphData = CreateBarChartData(calculatedGraphData.campaignsGraph);
      const openRecommendationGraphData = CreateBarChartData(calculatedGraphData.openRecommendationGraph);
      const openRecommendationsGraphLineData = CreateLineChartData(calculatedGraphData.openRecommendationBlockedGraph);
      const averageRiskDowGraphData = CreateBarChartData(calculatedGraphData.averageRiskDOWGraph, 'ddd', 'day', false, true);

      const openRecommendationDataSeriesCombines: any[] = [];

      openRecommendationGraphData.dataSeries.forEach((item: any) => {
        openRecommendationDataSeriesCombines.push(item);
      });
      openRecommendationsGraphLineData.dataSeries.forEach((item: any) => {
        openRecommendationDataSeriesCombines.push(item);
      });

      const elapsed = getTimeDifference(start);
      logger.info(`Fetched calculated data for Sas in ${elapsed} ms`);

      sasContext.setRiskResponseGraphData(openRiskResourceGraphData.dataSeries);
      sasContext.setRiskResponseGraphDataXDomain(openRiskResourceGraphData.xDomain);
      sasContext.setRiskResponseGraphDataYDomain(openRiskResourceGraphData.yDomain);

      sasContext.setTotalOpenRiskGraphData(openRiskTotalGraphData.dataSeries);
      sasContext.setTotalOpenRiskGraphDataXDomain(openRiskTotalGraphData.xDomain);
      sasContext.setTotalOpenRiskGraphDataYDomain(openRiskTotalGraphData.yDomain);

      sasContext.setCampaignsGraphData(campaignsGraphData.dataSeries);
      sasContext.setCampaignsGraphDataXDomain(campaignsGraphData.xDomain);
      sasContext.setCampaignsGraphDataYDomain(campaignsGraphData.yDomain);

      sasContext.setTotalOpenRecommendationsGraphData(openRecommendationDataSeriesCombines);
      sasContext.setTotalOpenRecommendationsGraphDataXDomain(openRecommendationGraphData.xDomain);
      sasContext.setTotalOpenRecommendationsGraphDataYDomain(openRecommendationGraphData.yDomain);

      sasContext.setAverageRiskDowGraphData(averageRiskDowGraphData.dataSeries);
      sasContext.setAverageRiskDowGraphDataXDomain(averageRiskDowGraphData.xDomain);
      sasContext.setAverageRiskDowGraphDataYDomain(averageRiskDowGraphData.yDomain);

      sasContext.setSasSummaryContainer(calculatedDetalsTableDataV2.sasSummaryContainer);

      sasContext.setSasDetailsTableData(calculatedDetalsTableDataV2.annualTotalRisksBreakoutByTeamManagers);
      sasContext.setSasManagerMomTableData(calculatedDetalsTableDataV2.aggregatedRisksByTeamManagers);
      sasContext.setSasOpenTotalRiskTableData(calculatedDetalsTableDataV2.openTotalRiskTable);
      sasContext.setSasTotalRiskResourceTableData(calculatedDetalsTableDataV2.totalRisksByResourceTypeAndTeam);

      sasContext.setDetailsStatus('finished');
      sasContext.setOverViewStatus('finished');
    } catch (error: any) {
      logger.error('Unable to process Sas data', error);
      sasContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      sasContext.setDetailsStatus('error');
      sasContext.setOverViewStatus('error');
    }
  };

  const triggerCall = () => {
    const managerStr = optionsToObject(managers);
    const assigneeStr = optionsToObject(assignees);
    const teamStr = optionsToObject(team);
    updateSasAllData(managerStr, assigneeStr, teamStr);
  };

  return (
    <Box padding={{ top: 'xl' }}>
      <Container
        fitHeight
        header={
          <SpaceBetween size="m" direction="horizontal" alignItems="center">
            <Box variant="h2">SAS</Box>
            <LastRefreshedAt lastRefreshedDateTime={lastRefreshed} />
            <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
          </SpaceBetween>
        }
      >
        <Box>
          <ColumnLayout columns={4}>
            <FormField label={filtersLabel('Team')}>
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(team, 'Team', optionsToObject(rawteam))}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilters.team, 'All Teams')}
                selectedOptions={team}
                onChange={({ detail }) => setTeam(detail.selectedOptions)}
                onBlur={({ detail }) => triggerCall()}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                selectedAriaLabel="Selected"
                expandToViewport
                hideTokens
                virtualScroll
                filteringType="auto"
                loadingText="Loading Team"
                statusType={finOpsContext.finTechOpsSasDataStatus}
                disabled={sasContext.overViewStatus === 'loading' || sasContext.detailsStatus === 'loading' ? true : false}
              />
            </FormField>

            <FormField label={filtersLabel('Managers')}>
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(managers, 'Managers', optionsToObject(rawmanagers))}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilters.managers, 'All Managers')}
                selectedOptions={managers}
                onChange={({ detail }) => setManagers(detail.selectedOptions)}
                onBlur={({ detail }) => triggerCall()}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                selectedAriaLabel="Selected"
                expandToViewport
                hideTokens
                virtualScroll
                filteringType="auto"
                loadingText="Loading Managers"
                statusType={finOpsContext.finTechOpsSasDataStatus}
                disabled={sasContext.overViewStatus === 'loading' || sasContext.detailsStatus === 'loading' ? true : false}
              />
            </FormField>
            <FormField label={filtersLabel('Assignees')}>
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(assignees, 'Assignees', optionsToObject(rawassignees))}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilters.assignees, 'All Assignees')}
                selectedOptions={assignees}
                onChange={({ detail }) => setAssignees(detail.selectedOptions)}
                onBlur={({ detail }) => triggerCall()}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                selectedAriaLabel="Selected"
                expandToViewport
                hideTokens
                virtualScroll
                filteringType="auto"
                loadingText="Loading Assignees"
                statusType={finOpsContext.finTechOpsSasDataStatus}
                disabled={sasContext.overViewStatus === 'loading' || sasContext.detailsStatus === 'loading' ? true : false}
              />
            </FormField>
          </ColumnLayout>
        </Box>
      </Container>
    </Box>
  );
}
