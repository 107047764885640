import {
  Box,
  CollectionPreferencesProps,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Link,
  PropertyFilterProps,
  SelectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { CardsProps } from '@amzn/awsui-components-react/polaris/cards';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppNameWithLink, useAllApps } from './AllApps';
import { AllAppsActionButtonDropdown } from './AllAppsConfig';
import { ApplicationFlatTableEntity, GroupedData } from 'src/components/context/AppContextModels';
import { EmptyState } from 'src/components/generic-components/empty-state';

interface AllAppsCategoryViewProps {
  applicationEntity: ApplicationFlatTableEntity[];
  filteringProperties: PropertyFilterProps.FilteringProperty[];
  preferences: CollectionPreferencesProps.Preferences<any>;
  setPreferences: (preferences: CollectionPreferencesProps.Preferences<any>) => void;
  query: PropertyFilterProps.Query;
  setQuery: (query: PropertyFilterProps.Query) => void;
  appCardDef: CardsProps.CardDefinition<ApplicationFlatTableEntity>;
  applicationCollection: any;
  groupBy: SelectProps.Option;
}

export const AllAppsCategoryView: React.FC<AllAppsCategoryViewProps> = ({
  applicationEntity,
  filteringProperties,
  preferences,
  setPreferences,
  query,
  setQuery,
  appCardDef,
  applicationCollection,
  groupBy
}: AllAppsCategoryViewProps) => {
  const allAppsContext = useAllApps();
  const navigate = useNavigate();

  const [filteredApplications, setFilteredApplications] = React.useState<ApplicationFlatTableEntity[]>([]);
  const [groupedApplications, setGroupedApplications] = useState<GroupedData>({});

  useEffect(() => {
    const applicationsList = applicationCollection.items as ApplicationFlatTableEntity[];
    setFilteredApplications(applicationsList);
    if (groupBy.label === 'GL Account') {
      groupByGlAccount(applicationsList);
    } else if (groupBy.label === 'Product UI') {
      groupByToolUIAccount(applicationsList);
    } else if (groupBy.label === 'Team') {
      groupByTeam(applicationsList);
    }
  }, [applicationCollection.items, groupBy]);

  const groupByGlAccount = (applicationsList: ApplicationFlatTableEntity[]) => {
    const groupedData = applicationsList.reduce((application, appData) => {
      if (!application[appData.glAccount]) {
        application[appData.glAccount] = [];
      }
      application[appData.glAccount].push(appData);
      return application;
    }, {} as { [key: string]: ApplicationFlatTableEntity[] });
    setGroupedApplications(sortGroupedData(groupedData));
  };

  const groupByToolUIAccount = (applicationsList: ApplicationFlatTableEntity[]) => {
    const groupedData = applicationsList.reduce((application, appData) => {
      if (!application[appData.toolUI]) {
        application[appData.toolUI] = [];
      }
      application[appData.toolUI].push(appData);
      return application;
    }, {} as { [key: string]: ApplicationFlatTableEntity[] });
    setGroupedApplications(sortGroupedData(groupedData));
  };

  const groupByTeam = (applicationsList: ApplicationFlatTableEntity[]) => {
    const groupedData = applicationsList.reduce((application, appData) => {
      if (!application[appData.applicationOwner]) {
        application[appData.applicationOwner] = [];
      }
      application[appData.applicationOwner].push(appData);
      return application;
    }, {} as { [key: string]: ApplicationFlatTableEntity[] });
    setGroupedApplications(sortGroupedData(groupedData));
  };

  const sortGroupedData = (unSortedGroupedData: GroupedData) => {
    // Convert the object keys (main categories) to an array
    const categories = Object.keys(unSortedGroupedData);

    // Sort the categories alphabetically
    categories.sort((a, b) => a.localeCompare(b));

    // Create a new object with sorted categories
    const sortedGroupedData: GroupedData = {};
    categories.forEach((category) => {
      sortedGroupedData[category] = unSortedGroupedData[category];
    });
    return sortedGroupedData;
  };

  return (
    <>
      <Box margin={{ top: 'm' }}>
        {Object.entries(groupedApplications).length === 0 && <EmptyState title="No applications" description="" verticalCenter={true} />}
        {Object.entries(groupedApplications).length !== 0 &&
          Object.entries(groupedApplications).map(([glAccount, apps], index) => (
            <SpaceBetween size="l" direction="vertical" key={index}>
              <Header headingTagOverride="h4" className="margin-top-10px" counter={`(${apps.length})`}>
                {glAccount}
              </Header>
              <Box margin={{ top: 's', bottom: 's' }}>
                <Grid>
                  {apps.map((app) => (
                    <Container
                      className="all-apps-category-container"
                      key={app.id}
                      header={
                        <Header
                          className="all-apps-category-container-header"
                          variant="h3"
                          headingTagOverride="h3"
                          actions={<AllAppsActionButtonDropdown item={app} />}
                        >
                          <AppNameWithLink appName={app.applicationName} appId={app.id} fontSize="heading-s" padding={{ bottom: 'xxxs' }} />
                        </Header>
                      }
                    >
                      <Box variant="p" fontSize="body-s">
                        {app.shortApplicationDescription}
                      </Box>
                    </Container>
                  ))}
                </Grid>
              </Box>
            </SpaceBetween>
          ))}
      </Box>
    </>
  );
};
