import { Button, ButtonDropdown, Container, FlashbarProps, Header, SpaceBetween } from '@amzn/awsui-components-react';
import 'ag-grid-enterprise';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../../../styles/ag-grid-override.scss';
import { AgGridReact } from 'ag-grid-react';
import { exportAsExcelFile } from 'src/utilities/FileServices';
import { MappingImportModal } from './MappingImportModal';
import { compareTwoArrayOfObjects } from '../../../utilities/CommonHelpers';
import { stringToBoolean, updateImportRowData } from './MappingValidations';
import './styles.module.scss';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { useAppContext } from 'src/components/context/AppContextProvider';
import * as mutations from 'src/graphql/mutations';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import { getCurrentUserTimeZone } from 'src/utilities/DateTimeUtilities';
import * as queries from 'src/graphql/queries';
import { logger } from 'src/logger';
import {
  BUTTON_DROPDOWN_ITEMS,
  ButtonActions,
  DUPLICATE_OR_NULL_RECORD_MSG,
  MUTATION_MAPPING_ERROR,
  OTHERS_APPLICATION_NAME,
  VALIDATION_ERROR_MSG,
  isActiveBoolValues,
  isActiveStrValues,
  isActiveValues,
  stageValues,
  pocTeamArr
} from './MappingConstants';
import { MappingRawDetails, MappingParsedDetails } from './MappingInterface';
import {
  appSrcTypeMap,
  createOrUpdateMapping,
  fetchAppSyncAPIData,
  formatTimestampColumns,
  getArrWithoutOpsCol,
  getCellEditorValuesArr,
  getDependentColFunc,
  getParsedAndFormattedMappingData,
  parseColumnDefinitionData,
  teamMap,
  mapEmployeeLogintoEmployeeNames,
  validateDuplicateAndNull,
  fetchAwsAccountDetails,
  fetchAvailableIp
} from './MappingDataProcessing';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import { ApplicationFlatTableEntity } from 'src/components/context/AppContextModels';

export function MappingTable(props: {
  lastRefreshedVal: (val?: string) => void;
  mappingConfig: string;
  mappingName: any;
  notifyUser: (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => void;
  resetNotification: any;
}) {
  // Ag grid properties
  const gridRef = useRef<AgGridReact>({} as AgGridReact);
  const gridStyle = useMemo(() => ({ height: '70vh', width: '100%' }), []);
  const userAuthenticationDetails = useAuth();

  const gridOptions: any = {
    suppressContextMenu: true,
    suppressCopyRowsToClipboard: true,
    suppressClipboardPaste: false,
    rowSelection: 'multiple',
    defaultColDef: {
      flex: 1,
      sortable: true,
      filter: true,
      resizable: true,
      editable: true
    }
  };

  const [showMappingImportModal, setShowMappingImportModal] = useState(false);
  const [mappingKey, setMappingKey] = useState<string>('');
  const [mappingName, setMappingName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(true);

  const appContext = useAppContext();
  const [mappingRawData, setMappingRawData] = useState<MappingRawDetails>({} as MappingRawDetails);

  //Configurations grid
  const [gridApi, setGridApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [initialData, setInitialData] = useState<any[]>([]);

  const [requiredFileHeaders, setRequiredFileHeaders] = useState<string[]>([]);
  const [keyColumn, setKeyColumn] = useState<string[]>([]);
  const [columnDefinition, setColumnDefinition] = useState<any[]>([]);
  const [isBoolCol, setIsBoolCol] = useState(false);

  const [applicationListArr, setApplicationListArr] = useState<string[]>([]);
  const [teamManagerLoginArr, setTeamManagerLoginArr] = useState<string[]>([]);
  const [teamPocLoginArr, setTeamPocLoginArr] = useState<string[]>([]);
  const [mappingValidationConfig, setMappingValidationConfig] = useState<string[]>([]);

  const [accountIdArr, setAccountIdArr] = useState<string[]>([]);
  const [vpcIpCountArr, setVpcIpCountArr] = useState<string[]>([]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    };
  }, []);

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  //Function to auto size all the columns of the grid based on the content of the grid
  const autoSizeAllColumns = () => {
    if (gridRef.current && gridRef.current.api) {
      const allColumnIds = gridRef.current.api.getAllDisplayedColumns().map((col: any) => {
        return col.getColId();
      });
      gridRef.current.api.autoSizeColumns(allColumnIds, false);
    }
  };

  //useEffect with setTimeout() to call the autoSizeAllColumns() function after a delay of 50ms of rowData being loaded
  useEffect(() => {
    if (rowData.length > 0) {
      setTimeout(() => autoSizeAllColumns(), 200);
    }
  }, [rowData]);

  // One time fetching mapping API data from backend and setRowData and mappingKey
  useEffect(() => {
    props.resetNotification();
    //setting rowData to blank before the mapping individual table load
    setRowData([]);
    setIsBoolCol(false);
    getMappingData(props.mappingConfig);
    setMappingKey(props.mappingConfig);
    setMappingName(props.mappingName);
    getMappingValidationConfigData();
  }, [props.mappingConfig]);

  useEffect(() => {
    validateData(
      rowData,
      applicationListArr,
      teamManagerLoginArr,
      teamPocLoginArr,
      accountIdArr,
      vpcIpCountArr,
      mappingKey,
      mappingValidationConfig,
      isActiveValues,
      isActiveBoolValues,
      keyColumn
    );
  }, [mappingRawData, applicationListArr, teamManagerLoginArr, teamPocLoginArr, accountIdArr, vpcIpCountArr, mappingValidationConfig, keyColumn]);

  //Function to retrieve the column definition from backend for AG Grid Table
  const getMappingColData = async (
    mappingKey: string,
    teamManagerLoginArr: string[],
    teamPocLoginArr: string[],
    accountIdArr: string[],
    vpcIpCountArr: string[],
    applicationListArr: string[],
    activeParsedApplications: ApplicationFlatTableEntity[]
  ) => {
    try {
      const parsedColData = await fetchAppSyncAPIData(queries.listMappingColDetails, 'listMappingColDetails', mappingKey, 'col_def');

      const { colList, keyColumn, colDefinitionArr, isBoolCol } = parseColumnDefinitionData(
        parsedColData,
        mappingKey,
        teamManagerLoginArr,
        teamPocLoginArr,
        accountIdArr,
        vpcIpCountArr,
        applicationListArr,
        isActiveValues,
        isActiveBoolValues
      );

      setIsBoolCol(isBoolCol);

      setKeyColumn(keyColumn);

      setRequiredFileHeaders(colList);

      setColumnDefinition(colDefinitionArr);
    } catch (error: any) {
      logger.error(`Unable to load ${mappingName} data`, error);
      props.notifyUser(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  //Function to retrieve the mapping validation config from backend for AG Grid Table
  const getMappingValidationConfigData = async () => {
    try {
      const parsedColData = await fetchAppSyncAPIData(
        queries.listMappingValidationConfig,
        'listMappingValidationConfig',
        mappingKey,
        'mapping_validation_config'
      );

      setMappingValidationConfig(parsedColData);
    } catch (error: any) {
      logger.error(`Unable to load Mapping Validation Config Data for ${mappingName}`, error);
      props.notifyUser(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  //Function to retrieve the mapping data from backend
  const getMappingData = async (mappingKey: string) => {
    try {
      const parsedData = await fetchAppSyncAPIData(queries.listMappingDetails, 'listMappingDetails', mappingKey, 'mapping_details', {
        table_name: mappingKey
      });

      setMappingRawData(parsedData[0]);
      const parsedMappingData: MappingParsedDetails = getParsedAndFormattedMappingData(parsedData[0]);

      const initData = JSON.parse(JSON.stringify(parsedMappingData.data));
      setInitialData(initData);
      props.lastRefreshedVal(parsedMappingData.metadata.last_updated_at);

      const activeParsedApplications = appContext.parsedApplications.filter((parsedApps) => parsedApps.isActive);

      const applicationListArr = Array.from(new Set(activeParsedApplications.map((item) => item.applicationName)))
        .concat(OTHERS_APPLICATION_NAME)
        .sort();

      setApplicationListArr(applicationListArr);

      setTeamManagerLoginArr(parsedMappingData.team_manager_login.sort());
      const teamPocLoginSorted = parsedMappingData.team_poc_login ? parsedMappingData.team_poc_login.sort() : [];
      setTeamPocLoginArr(teamPocLoginSorted);
      const sortedAwsAccountIds = parsedMappingData.vpc_mapping_details?.aws_account_name
        ? parsedMappingData.vpc_mapping_details.aws_account_name.sort()
        : [];
      setAccountIdArr(sortedAwsAccountIds);
      const sortedVpcIpCountArr = parsedMappingData.vpc_mapping_details?.vpc_ip_count
        ? parsedMappingData.vpc_mapping_details.vpc_ip_count.sort((a: string, b: string) => Number(a) - Number(b))
        : [];
      setVpcIpCountArr(sortedVpcIpCountArr);
      await getMappingColData(
        mappingKey,
        parsedMappingData.team_manager_login.sort(),
        teamPocLoginSorted,
        sortedAwsAccountIds,
        sortedVpcIpCountArr,
        applicationListArr,
        activeParsedApplications
      );
      //setting rowData after setting the dynamic column definition, getMappingColData is called in this function since it needs team manager list which is fetched along with mapping data
      setRowData(parsedMappingData.data);
    } catch (error: any) {
      logger.error(`Unable to load ${mappingName} data`, error);
      props.notifyUser(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  const addOperationalColData = (item: any) => {
    item['isEdited'] = true;
    return item;
  };

  //Function to validate the data based on mapping validation config
  const validateData = async (
    rowData: any,
    applicationListArr: string[],
    teamManagerLoginArr: string[],
    teamPocLoginArr: string[],
    accountIdArr: string[],
    vpcIpCountArr: string[],
    mappingKey: string,
    mappingValidationConfig: any[],
    isActiveValues: string[],
    isActiveBoolValues: boolean[],
    keyColumn: string[]
  ) => {
    const rawRowData = JSON.parse(JSON.stringify(rowData));
    rawRowData.forEach((item: any) => {
      mappingValidationConfig.forEach((config) => {
        if (config.mapping_key === mappingKey) {
          const colName = config.column_val;
          const dependentCol = config.dependent_column_val;
          const targetArray = config.target_array;
          const dependentColFunc = config.dependent_column_func;
          const targetArr: any[] = getCellEditorValuesArr(
            targetArray,
            applicationListArr,
            teamManagerLoginArr,
            teamPocLoginArr,
            accountIdArr,
            vpcIpCountArr,
            isActiveValues,
            isActiveBoolValues,
            stageValues
          );
          // //
          if (colName === 'is_active') {
            if (!targetArr?.includes(item[colName])) {
              if (typeof item[colName] === 'string' && isActiveStrValues.includes(item[colName].toLowerCase())) {
                item[colName] = stringToBoolean(item[colName]);
              } else {
                item[colName] = null;
              }

              addOperationalColData(item);
            }
          } else if (targetArr.some((ele: string) => item[colName]?.toLowerCase().includes(ele?.toLowerCase()))) {
            if (dependentCol) {
              const dependentColVal = getDependentColFunc(dependentColFunc, item[colName], mappingRawData, appContext?.parsedApplications);
              if (item[dependentCol] !== dependentColVal) {
                item[dependentCol] = dependentColVal;
                addOperationalColData(item);
                props.notifyUser(`error`, VALIDATION_ERROR_MSG);
              }
            }
          } else {
            item[colName] = null;
            addOperationalColData(item);
            if (dependentCol) {
              item[dependentCol] = null;
            }
            props.notifyUser(`error`, VALIDATION_ERROR_MSG);
          }
        }
      });
    });

    setRowData(rawRowData);
    gridRef?.current?.api?.refreshCells();
    const { isValid, isNull } = await validateDuplicateAndNull(rawRowData, true, keyColumn);
    if (isValid || isNull) {
      setIsDisabled(true);
      props.notifyUser('error', `${DUPLICATE_OR_NULL_RECORD_MSG} ${mappingKey}`);
    } else {
      setIsDisabled(false);
    }
  };

  //Get current rowData from the grid
  const getCurrentRowData = () => {
    let updatedConfig: any[] = [];
    gridRef?.current?.api.forEachNode((rowNode, index) => {
      if (rowNode.data.team_poc_login) {
        rowNode.data.team_poc = rowNode.data.team_poc?.toString();
        rowNode.data.team_poc_login = rowNode.data.team_poc_login?.toString();
      }
      updatedConfig.push(rowNode.data);
    });
    return updatedConfig;
  };

  //Function to create or update mapping data in the backend
  const onSubmit = async () => {
    let updatedConfig: any[] = getCurrentRowData();

    let updatedRecords: any[] = compareTwoArrayOfObjects(
      getArrWithoutOpsCol(JSON.parse(JSON.stringify(initialData)), false),
      getArrWithoutOpsCol(JSON.parse(JSON.stringify(updatedConfig)), false)
    );

    //parsing only required columns to send to backend
    let recordsToUpdate = updatedRecords
      .map((item: any) => {
        if (item.newRow) {
          return { ...item, created_by: userAuthenticationDetails.Alias, updated_by: userAuthenticationDetails.Alias };
        } else {
          return { ...item, updated_by: userAuthenticationDetails.Alias, created_by: null, created_at: null };
        }
      })
      .map(({ isEdited, newRow, last_updated_at, last_updated_by, ...remainingFields }) => remainingFields);

    if (recordsToUpdate.length === 0) {
      props.notifyUser(`warning`, 'No changes to submit');
    } else {
      const createORupdateResponse = createOrUpdateMapping(
        mutations.createOrUpdateMapping,
        'createOrUpdateMapping',
        mappingKey,
        'numberOfRecordsUpdated',
        { input: recordsToUpdate, table_name: mappingKey }
      );

      if (createORupdateResponse) {
        createORupdateResponse.then((noOfRecords) => {
          if (noOfRecords) {
            logger.info(`Successfully added/updated ${noOfRecords} record/s. `, { info: noOfRecords });
            props.notifyUser(`success`, `${noOfRecords} record(s) added/updated successfully`);
            getMappingData(mappingKey);
          } else {
            logger.error(`${MUTATION_MAPPING_ERROR} for ${mappingName}`);
            props.notifyUser('error', `${MUTATION_MAPPING_ERROR} for ${mappingName}`, TICKETS_FOR_OE_ERROR_URL);
          }
        });
      }
    }
  };

  //Export function to export the data as excel
  const onBtnExport = () => {
    let allRowData: any[] = getCurrentRowData();
    var exportData = allRowData.map((item) => {
      const row: any = {};
      for (const prop of requiredFileHeaders) {
        row[prop] = item[prop];
      }
      return row;
    });
    exportAsExcelFile(exportData, mappingKey, mappingKey);
    props.notifyUser(`success`, `Data exported successfully for ${mappingName}`);
  };

  //Reset function to reset the grid data
  const onReset = () => {
    logger.info('resetting data');
    setRowData(JSON.parse(JSON.stringify(initialData)));
    gridRef?.current?.api?.refreshCells();
    setIsDisabled(true);
    props.notifyUser(`success`, `Grid reset successfully for ${mappingName}`);
  };

  //Function to enable the import modal
  const onBtnImport = () => {
    setShowMappingImportModal(true);
  };

  //Function to set style for edited row with CornSilk color
  const getRowStyle = (params: any) => {
    if (params.data?.isEdited === true || params.data?.newRow === true) {
      return { background: 'CornSilk' };
    }
    gridRef?.current?.api?.refreshCells();
  };

  //Function to add new records in the grid with 0 index
  const onAddrow = () => {
    gridApi.applyTransaction({
      add: [
        {
          newRow: true,
          created_at: getCurrentUserTimeZone(),
          created_by: userAuthenticationDetails.Alias,
          updated_at: getCurrentUserTimeZone(),
          updated_by: userAuthenticationDetails.Alias,
          last_updated_at: getCurrentUserTimeZone(),
          last_updated_by: userAuthenticationDetails.Alias
        }
      ],
      addIndex: 0
    });
    //added isDisabled to true when new row is added
    setIsDisabled(true);
  };

  //Function to update the cell values for team manager and team name from team manager login and application name respectively
  const updateCellValueMapping = async (params: any) => {
    if (params.colDef.field === 'team_manager_login' && requiredFileHeaders.includes('team_manager')) {
      if (params.newValue) {
        const teamManager = mapEmployeeLogintoEmployeeNames(mappingRawData.emp_hierarchy, 'team_manager_login', params.newValue);
        params.data.team_manager = teamManager;
      } else {
        params.data.team_manager = null;
      }
    } else if (params.colDef.field === 'application_name' || params.colDef.field === 'application_type') {
      if (params.newValue) {
        const teamName = teamMap(appContext?.parsedApplications, params.newValue);
        params.data.team_name = teamName;
        const appSrcTypeName = appSrcTypeMap(appContext?.parsedApplications, params.newValue);

        params.data.app_src_type = appSrcTypeName;
      } else {
        params.data.team_name = null;
        params.data.app_src_type = null;
      }
    }
    if (params.colDef.field === 'team_poc_login' && requiredFileHeaders.includes('team_poc')) {
      const teamPoc: string[] = [];
      if (params.newValue) {
        params.newValue.forEach((item: any) => {
          if (mappingRawData.poc_employee_details) {
            teamPoc.push(mapEmployeeLogintoEmployeeNames(mappingRawData.poc_employee_details, 'team_poc_login', item));
          }
        });
      }
      params.data.team_poc = params.newValue ? teamPoc : '';
      params.data.team_poc_login = params.newValue ? params.newValue : '';
    }
    if (params.colDef.field === 'aws_account_name' && requiredFileHeaders.includes('account_id')) {
      if (params.newValue && mappingRawData.vpc_mapping_config?.aws_account_details) {
        [params.data.account_id, params.data.application_name, params.data.team_manager_login, params.data.team_name] = fetchAwsAccountDetails(
          mappingRawData.vpc_mapping_config.aws_account_details,
          'aws_account_name',
          params.newValue
        );
        const teamManager = mapEmployeeLogintoEmployeeNames(mappingRawData.emp_hierarchy, 'team_manager_login', params.data.team_manager_login);
        params.data.team_manager = teamManager;
      }
    }
    if (params.colDef.field === 'ip_count_estimate' && requiredFileHeaders.includes('vpc_cidr')) {
      if (params.newValue && mappingRawData.vpc_mapping_config?.available_ip) {
        // To make sure same ips are not picked during addition of new rows
        let updatedConfig = getCurrentRowData();
        const exclude_ips = updatedConfig.map((row) => row.vpc_cidr);
        params.data.vpc_cidr = fetchAvailableIp(mappingRawData.vpc_mapping_config.available_ip, params.newValue, exclude_ips);
      }
    }
  };

  const onCellEditingStopped = async (params: any) => {
    updateCellValueMapping(params);
    gridRef?.current?.api?.refreshCells();
    params.api.redrawRows();
  };

  //Function to trigger when cell values will change
  const onCellValueChanged = async (params: any) => {
    params.node.data['isEdited'] = true;
    updateCellValueMapping(params);

    gridRef?.current?.api?.refreshCells();

    params.api.redrawRows();

    let updatedConfig: any[] = getCurrentRowData();

    validateData(
      updatedConfig,
      applicationListArr,
      teamManagerLoginArr,
      teamPocLoginArr,
      accountIdArr,
      vpcIpCountArr,
      mappingKey,
      mappingValidationConfig,
      isActiveValues,
      isActiveBoolValues,
      keyColumn
    );
  };

  //Function to close modal on cancel
  const onCancel = () => {
    setShowMappingImportModal(false);
  };

  // File Import confirm
  const onConfirm = async (uploadFileData: any[]) => {
    setShowMappingImportModal(false);
    let updatedData = [];

    if (isBoolCol) {
      updatedData = uploadFileData.map((item) => {
        return { ...item, is_active: stringToBoolean(item.is_active) };
      });
    } else {
      updatedData = uploadFileData.map((item) => {
        return { ...item };
      });
    }

    props.notifyUser(`success`, `Data imported successfully for ${mappingName}`);

    const updatedRowData = JSON.parse(JSON.stringify(formatTimestampColumns(updatedData)));

    let updatedRecords: any[] = compareTwoArrayOfObjects(
      getArrWithoutOpsCol(JSON.parse(JSON.stringify(initialData)), false),
      getArrWithoutOpsCol(JSON.parse(JSON.stringify(updatedRowData)), false)
    );

    const updatedRecordsLen = updatedRecords.length;

    const { isValid, isNull } = await validateDuplicateAndNull(JSON.parse(JSON.stringify(updatedRowData)), true, keyColumn);

    if (isValid || isNull || updatedRecordsLen === 0) {
      setIsDisabled(true);
      if (updatedRecordsLen === 0) {
        props.notifyUser('warning', `No changes to submit for ${mappingName}`);
      } else {
        props.notifyUser('error', `${DUPLICATE_OR_NULL_RECORD_MSG} ${mappingName}`);
      }
    } else {
      setIsDisabled(false);
      props.resetNotification();
    }

    const updatedRow = updateImportRowData(
      updatedRowData,
      JSON.parse(JSON.stringify(updatedRecords)),
      userAuthenticationDetails.Alias,
      JSON.parse(JSON.stringify(initialData)),
      keyColumn
    );
    setRowData(updatedRow);

    gridRef?.current?.api?.refreshCells();
    validateData(
      updatedRow,
      applicationListArr,
      teamManagerLoginArr,
      teamPocLoginArr,
      accountIdArr,
      vpcIpCountArr,
      mappingKey,
      mappingValidationConfig,
      isActiveValues,
      isActiveBoolValues,
      keyColumn
    );
  };

  //Function for the action button
  const onItemClick = (actions: any) => {
    switch (actions.detail.id) {
      case ButtonActions.Export:
        return onBtnExport();
      case ButtonActions.Import:
        return onBtnImport();
      case ButtonActions.Reset:
        return onReset();
    }
  };

  const getMappingTableHeaderActions = () => {
    return userAuthenticationDetails.isDev || userAuthenticationDetails.isAdmin ? (
      <SpaceBetween size="m" direction="horizontal">
        <Button iconName="add-plus" onClick={() => onAddrow()} ariaLabel="Add New Record">
          New Row
        </Button>
        <ButtonDropdown items={BUTTON_DROPDOWN_ITEMS} onItemClick={onItemClick}>
          Actions
        </ButtonDropdown>
        <Button disabled={isDisabled} variant="primary" onClick={() => onSubmit()} ariaLabel="Submit Changes">
          Submit
        </Button>
      </SpaceBetween>
    ) : (
      <Button ariaLabel="Export Data" onClick={() => onBtnExport()}>
        Export Data
      </Button>
    );
  };

  return (
    <>
      <MappingImportModal
        showModal={showMappingImportModal}
        onCancel={onCancel}
        onConfirm={onConfirm}
        mappingKey={mappingKey}
        requiredFileHeaders={requiredFileHeaders}
        keyColumn={keyColumn}
      />

      <Container
        fitHeight
        disableContentPaddings
        header={
          <>
            <Header actions={getMappingTableHeaderActions()}>
              <SpaceBetween size="s" direction="vertical">
                <span>{props.mappingName}</span>
              </SpaceBetween>
            </Header>
          </>
        }
      >
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            rowData={rowData}
            onCellValueChanged={onCellValueChanged}
            onCellEditingStopped={onCellEditingStopped}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              minWidth: 100
            }}
            enableRangeSelection={true}
            rowSelection={'multiple'}
            onGridReady={onGridReady}
            ref={gridRef}
            gridOptions={gridOptions}
            getRowStyle={getRowStyle}
            statusBar={statusBar}
            stopEditingWhenCellsLoseFocus={true}
            suppressScrollOnNewData={true}
            columnDefs={columnDefinition}
            enableFillHandle={true}
            fillHandleDirection={'x'}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            suppressExcelExport={true}
            suppressCsvExport={true}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            rowHeight={50}
          ></AgGridReact>
        </div>
      </Container>
    </>
  );
}
