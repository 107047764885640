import { PieChartDataSeries, PieData, TicketsParsedDataItem } from './tickets/TicketsInterfaces';
import { SelectProps, TableProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import {
  getCurrentUTCTimeZone,
  getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps,
  sortedDateArrayMMMYYYYFormat
} from 'src/utilities/DateTimeUtilities';
import { FINTECH_MONTH_COUNT, FilterDateFormat_MMM_YYYY, GraphDateFormat_MMM_YY } from './FinTechOpsConstants';
import { getColorByStatus, getDashboardFiltersFromTickets } from './tickets/TicketsDataProcessing';
import {
  DataForGraphProcessing,
  GenericBar,
  GenericDataSeriesBar,
  GenericDataSeriesLine,
  GenericLine,
  SasParsedData,
  SasSeriesDatum
} from './sas/SasInterfaces';
import { getDashboardFiltersFromSAS, setDomainOrder } from './sas/SasDataProcessing';
import { DogmaParsedData } from './dogma/DogmaInterface';
import { PolicyEngineParsedDataItem } from './policy-engine/PolicyEngineInterface';
import { UserMetricsParsedData } from './user-metrics/UserMetricsInterfaces';
import { getDashboardFiltersFromDogma } from './dogma/DogmaDataProcessing';
import { getDashboardFiltersFromPolicyEngine } from './policy-engine/PolicyEngineDataProcessing';
import { getDashboardFiltersFromUserMetrics } from './user-metrics/UserMetricsDataProcessing';
import { CostMetricsParsedData } from './cost-metrics/CostMetricsInterface';
import { getDashboardFiltersFromCostMetrics } from './cost-metrics/CostMetricsDataProcessing';
import { getUniqueFieldValues } from 'src/utilities/CommonUtilities';

export type OptionItem = {
  label: string;
  value: string;
};

export function getFinOpsAllowedMonths(format: string, isDescending = false): string[] {
  const newXDomain: string[] = [];
  const lastSixMonthsIncludingCurrentMonth = getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps();

  for (let i = 0; i < FINTECH_MONTH_COUNT; i++) {
    newXDomain.push(lastSixMonthsIncludingCurrentMonth.format(format));
    lastSixMonthsIncludingCurrentMonth.add(1, 'months');
  }
  // console.log('getLastTwelveMonthsDomain ', isDescending ? newXDomain.reverse() : newXDomain);
  return isDescending ? newXDomain.reverse() : newXDomain;
}

// Function special case to correct format of multiselect age options
export function returnFilterAgeSorted(items: string[], labelForFilter: string): SelectProps.Options {
  const itemsOptions: OptionItem[] = [];
  if (items != undefined && items.length > 0) {
    items.map((item) => {
      itemsOptions.push({ label: item, value: item });
    });
  }
  return [
    {
      label: labelForFilter,
      value: labelForFilter,
      options: itemsOptions
    }
  ];
}

// Used in Multi select to convert from Object to Options Dropdown
export function parseObjectToMultiSelectOptions_Sorted(items: string[], labelForFilter: string): SelectProps.Options {
  return [
    {
      label: labelForFilter,
      value: labelForFilter,
      options: objectToOptions(items)
    }
  ];
}

// Object to Options
export function objectToOptions(items: string[]): OptionItem[] {
  const itemsOptions: OptionItem[] = [];
  if (items != undefined && items.length > 0) {
    items.forEach((element) => {
      itemsOptions.push({ label: element, value: element });
    });
  }
  itemsOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
  return itemsOptions;
}

// Object to Options (For Months)
export function objectToOptionsForMonths(items: string[]): OptionItem[] {
  const itemsOptions: OptionItem[] = [];
  if (items != undefined && items.length > 0) {
    items.forEach((element) => {
      itemsOptions.push({ label: element, value: element });
    });
  }
  itemsOptions.sort((a, b) => {
    // Custom sorting function for "YYYY-MM" format
    const dateA = a.value.split('-').reverse().join('');
    const dateB = b.value.split('-').reverse().join('');
    return moment(dateA, 'YYYY-MM').isBefore(moment(dateB, 'YYYY-MM')) ? 1 : -1;
  });
  return itemsOptions;
}

// Options to Object
export function optionsToObject(items: SelectProps.Options): string[] {
  const returnItemsStrArray: string[] = [];
  items.forEach((element) => {
    returnItemsStrArray.push(element.label || '');
  });
  return returnItemsStrArray;
}

export function returnPieSeries(items: PieData[]): PieChartDataSeries[] {
  const returnPieData: PieChartDataSeries[] = [];
  items.forEach((element) => {
    returnPieData.push({
      title: element.status,
      value: element.count,
      lastUpdate: getCurrentUTCTimeZone(),
      color: getColorByStatus(element.status)
    });
  });
  return returnPieData.sort((a, b) => (a.title > b.title ? 1 : -1));
}

export function returnSelectedOptionsAge(items: SelectProps.Options): string[] {
  const returnItemsStrArray: string[] = [];
  items.forEach((element) => {
    const selectedMonth: string = moment(element.label as unknown as string, FilterDateFormat_MMM_YYYY).format(FilterDateFormat_MMM_YYYY);
    returnItemsStrArray.push(selectedMonth);
  });
  return returnItemsStrArray;
}

export const CreateDictionaryForGraphDataProcessing = (items: DataForGraphProcessing[], format: string): Map<string, SasSeriesDatum[]> => {
  const dictionary = new Map<string, SasSeriesDatum[]>();
  items.forEach((item) => {
    const category = moment(item.category).format(format) != 'Invalid date' ? moment(item.category).format(format) : item.category;
    const newData: SasSeriesDatum = { x: category, y: item.count };
    dictionary.get(item.title) != undefined ? dictionary.get(item.title)?.push(newData) : dictionary.set(item.title, [newData]);
  });
  return dictionary;
};

export const CreateBarChartData = (
  sasData: DataForGraphProcessing[],
  categoryFormat: string = GraphDateFormat_MMM_YY,
  categoryType = 'month',
  isStacked = true,
  addTotal = false
): GenericBar => {
  const dictionary: Map<string, SasSeriesDatum[]> = CreateDictionaryForGraphDataProcessing(sasData, categoryFormat);
  const dataSeries: GenericDataSeriesBar[] = [];
  const monthlyTotalDictionary = new Map<string, number>();
  const monthlyTotalList: number[] = [];
  let max = 0;
  const domain: string[] = [];
  if (addTotal) {
    isStacked = true;
  }

  let categoryDomain: string[] = [];
  if (categoryType == 'month') {
    categoryDomain = getFinOpsAllowedMonths(categoryFormat);
  } else if (categoryType == 'day') {
    categoryDomain = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }

  dictionary.forEach((value: SasSeriesDatum[], key: string) => {
    value = setDomainOrder(categoryDomain, value);
    // let hasNonZeroValue = false;
    value.forEach((month: SasSeriesDatum) => {
      // month.y > 0 ? (hasNonZeroValue = true) : (hasNonZeroValue = false);
      // if (month.y > 0) {
      monthlyTotalDictionary.get(month.x) == undefined
        ? monthlyTotalDictionary.set(month.x, month.y)
        : monthlyTotalDictionary.set(month.x, month.y + monthlyTotalDictionary.get(month.x)!);
      // }
      if (!isStacked) {
        if (month.y > max) {
          max = month.y;
        }
      }
    });
    // if (hasNonZeroValue) {
    dataSeries.push({
      title: key,
      data: value,
      type: 'bar'
    });
    // }
  });

  for (let i = 0; i < categoryDomain.length; i++) {
    monthlyTotalDictionary.get(categoryDomain[i])! ? monthlyTotalList.push(monthlyTotalDictionary.get(categoryDomain[i])!) : monthlyTotalList.push(0);

    if (monthlyTotalDictionary.get(categoryDomain[i])! > 0) {
      domain.push(categoryDomain[i]);
    }
  }
  if (addTotal) {
    const totalValue: SasSeriesDatum[] = [];
    for (let i = 0; i < domain.length; i++) {
      totalValue.push({ x: domain[i], y: monthlyTotalList[i] });
    }
    dataSeries.push({
      title: 'Total',
      data: totalValue,
      type: 'bar'
    });
  }
  return {
    dataSeries: dataSeries,
    xDomain: domain,
    yDomain: [0, (isStacked ? Math.max(...monthlyTotalList.map((item) => item)) : max) * 1.2]
  };
};

export const CreateLineChartData = (
  sasData: DataForGraphProcessing[],
  categoryFormat: string = GraphDateFormat_MMM_YY,
  categoryType = 'month',
  isStacked = true,
  addTotal = false
): GenericLine => {
  const dictionary: Map<string, SasSeriesDatum[]> = CreateDictionaryForGraphDataProcessing(sasData, categoryFormat);
  const dataSeries: GenericDataSeriesLine[] = [];
  const monthlyTotalDictionary = new Map<string, number>();
  const monthlyTotalList: number[] = [];
  let max = 0;
  const domain: string[] = [];
  if (addTotal) {
    isStacked = true;
  }

  let categoryDomain: string[] = [];
  if (categoryType == 'month') {
    categoryDomain = getFinOpsAllowedMonths(categoryFormat);
  } else if (categoryType == 'day') {
    categoryDomain = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }

  dictionary.forEach((value: SasSeriesDatum[], key: string) => {
    value = setDomainOrder(categoryDomain, value);
    let hasNonZeroValue = false;
    value.forEach((month: SasSeriesDatum) => {
      month.y > 0 ? (hasNonZeroValue = true) : (hasNonZeroValue = false);
      if (month.y > 0) {
        monthlyTotalDictionary.get(month.x) == undefined
          ? monthlyTotalDictionary.set(month.x, month.y)
          : monthlyTotalDictionary.set(month.x, month.y + monthlyTotalDictionary.get(month.x)!);
      }
      if (!isStacked) {
        if (month.y > max) {
          max = month.y;
        }
      }
    });
    if (hasNonZeroValue) {
      dataSeries.push({
        title: key,
        data: value,
        type: 'line'
      });
    }
  });

  for (let i = 0; i < categoryDomain.length; i++) {
    monthlyTotalDictionary.get(categoryDomain[i])! ? monthlyTotalList.push(monthlyTotalDictionary.get(categoryDomain[i])!) : monthlyTotalList.push(0);

    if (monthlyTotalDictionary.get(categoryDomain[i])! > 0) {
      domain.push(categoryDomain[i]);
    }
  }
  if (addTotal) {
    const totalValue: SasSeriesDatum[] = [];
    for (let i = 0; i < domain.length; i++) {
      totalValue.push({ x: domain[i], y: monthlyTotalList[i] });
    }
    dataSeries.push({
      title: 'Total',
      data: totalValue,
      type: 'line'
    });
  }
  return {
    dataSeries: dataSeries,
    xDomain: domain,
    yDomain: [0, (isStacked ? Math.max(...monthlyTotalList.map((item) => item)) : max) * 1.2]
  };
};

export const sortDistinctMonths_MM_YYYY = (months_MM_YYYY: string[], asc = true): string[] => {
  return months_MM_YYYY.sort((a, b) => {
    const dateA = moment(`01-${a}`, 'DD-MMM-YYYY');
    const dateB = moment(`01-${b}`, 'DD-MMM-YYYY');

    // Use moment's isBefore method for comparison
    if (dateA.isBefore(dateB)) {
      return asc ? 1 : -1; // Sort in descending order
    } else if (dateB.isBefore(dateA)) {
      return asc ? -1 : 1; // Sort in descending order
    }
    return 0;
  });
};

export const getDashboardFilters = (
  ticketsData: TicketsParsedDataItem[],
  userMetricsData: UserMetricsParsedData[],
  sasData: SasParsedData[],
  policyEngineData: PolicyEngineParsedDataItem[],
  dogmaData: DogmaParsedData[],
  costMetricsData: CostMetricsParsedData[]
) => {
  const allManagers = new Set<string>();
  const allAssignees = new Set<string>();
  const allTeams = new Set<string>();

  const { team_name: ticket_Teams, managers: ticket_managers, assignees: ticket_assignees } = getDashboardFiltersFromTickets(ticketsData);

  const {
    team_name: user_metrics_Teams,
    managers: user_metrics_managers,
    assignees: user_metrics_assignees
  } = getDashboardFiltersFromUserMetrics(userMetricsData);

  const { team_name: sasTeams, managers: sasManagers, assignees: sasAssignees } = getDashboardFiltersFromSAS(sasData);

  const {
    team_name: policyEngineTeams,
    managers: policyEngineManagers,
    assignees: policyEngineAssignees
  } = getDashboardFiltersFromPolicyEngine(policyEngineData);

  const { team_name: dogmaTeams, managers: dogmaManagers, assignees: dogmaAssignees } = getDashboardFiltersFromDogma(dogmaData);

  const {
    team_name: costMetricsTeams,
    managers: costMetricsManagers,
    assignees: costMetricsAssignees
  } = getDashboardFiltersFromCostMetrics(costMetricsData);

  // Filter and add values to the respective Sets
  filterAndAddValues(allTeams, [...ticket_Teams, ...user_metrics_Teams, ...sasTeams, ...policyEngineTeams, ...dogmaTeams, ...costMetricsTeams]);
  filterAndAddValues(allManagers, [
    ...ticket_managers,
    ...user_metrics_managers,
    ...sasManagers,
    ...policyEngineManagers,
    ...dogmaManagers,
    ...costMetricsManagers
  ]);
  filterAndAddValues(allAssignees, [
    ...ticket_assignees,
    ...user_metrics_assignees,
    ...sasAssignees,
    ...policyEngineAssignees,
    ...dogmaAssignees,
    ...costMetricsAssignees
  ]);

  // Convert Sets back to arrays if needed
  const uniqueTeams = [...allTeams];
  const uniqueManagers = [...allManagers];
  const uniqueAssignees = [...allAssignees];

  return { uniqueTeams, uniqueManagers, uniqueAssignees };
};

const filterAndAddValues = (set: Set<string>, values: string[]) => {
  values.forEach((value: string) => {
    if (value !== null && value !== '') {
      set.add(value);
    }
  });
  return set;
};

//Function to add unique values in filterArray
export const uniqueValues = (rowSet: Set<string>, value: string) => {
  if (!rowSet.has(value)) {
    rowSet.add(value);
  }
  return rowSet;
};

//Function to get MoM Data for Details Summary Page
export const getMoMData = (
  filterColumn: string,
  additionalFilterColumn: string,
  monthFilterColumn: string,
  measureColumn: string,
  currentMonthCount: number,
  previousMontnNumber: number,
  filteredWithoutMonthData: any[]
): string => {
  const previousMonthFilteredData = filteredWithoutMonthData.filter(
    (item) => item[monthFilterColumn] === previousMontnNumber && item[additionalFilterColumn] === filterColumn
  );

  let previousMonthCount = 0;
  previousMonthFilteredData.forEach((item) => {
    previousMonthCount = previousMonthCount + item[measureColumn];
  });

  return (currentMonthCount - previousMonthCount).toFixed();
};

//Function to get aggreagted data based on dateColumnField & keyColumnField
export const getAggregatedArray = (keyColumnField: string, dateColumnField: string, measureColumnField: string, aggregatedDataValue: any) => {
  const aggregatedData: Record<string, any> = {};
  for (const item of aggregatedDataValue) {
    const keyColumn = item[keyColumnField];
    const dateColumn = item[dateColumnField];
    let measureColumn = 0;
    if (item.hasOwnProperty(measureColumnField)) {
      measureColumn = item[measureColumnField];
    }

    const key = `${keyColumn}`;

    if (aggregatedData[key]) {
      aggregatedData[key][dateColumn] = (aggregatedData[key][dateColumn] || 0) + measureColumn;
    } else {
      aggregatedData[key] = {
        [keyColumnField]: keyColumn,
        [dateColumn]: measureColumn
      };
    }
  }
  const aggregatedArray = Object.values(aggregatedData);
  return aggregatedArray;
};

//Function to get Top N records by filter column
export const getTopNRecordsByFilterColumn = (aggregatedData: any, limit: number, measureColumn: string, filterColumn: string) => {
  const topNrecords = aggregatedData.reduce((accumulator: any, record: any) => {
    const existingRecords = accumulator.filter((row: any) => row[filterColumn] == record[filterColumn]);
    if (existingRecords.length < limit) {
      accumulator.push(record);
    } else {
      const minRecord = existingRecords.reduce((minRow: any, currentRow: any) =>
        minRow[measureColumn] < currentRow[measureColumn] ? minRow : currentRow
      );
      if (record[measureColumn] > minRecord[measureColumn]) {
        const minRecordIndex = accumulator.findIndex((row: any) => row == minRecord);
        accumulator[minRecordIndex] = record;
      }
    }
    return accumulator;
  }, []);
  return topNrecords;
};

//Function to get max and previous month values
export const getMaxAndPreviousMonth = (rowParsedData: any[], dateColumn: string, dateFormat = FilterDateFormat_MMM_YYYY) => {
  //initializing maxMonth & previousMonth to default value to handle empty rowParsedData
  let maxMonth = moment().month();
  let previousMonth = moment().month() - 1;
  if (rowParsedData.length > 0) {
    const sortedMonths = sortedDateArrayMMMYYYYFormat(getUniqueFieldValues(rowParsedData, dateColumn));
    const currentMonthValue = moment().format(dateFormat);
    // consider the 0th index month only if its the only value selected in filter
    const maxMonthValue = sortedMonths.length > 1 && sortedMonths.includes(currentMonthValue) ? sortedMonths[1] : sortedMonths[0];
    maxMonth = moment(maxMonthValue, dateFormat).month() + 1;
    previousMonth = moment(maxMonthValue, dateFormat).subtract(1, 'months').month() + 1;
  }
  return [maxMonth, previousMonth];
};

//Function to include only allowed months of data
export function filterDataByCreateDates(rawData: any[], fieldName: string): any[] {
  const currentDate = moment();
  const lastSixMonthsIncludingCurrentMonth = getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps();

  const included: any[] = [];
  const excluded: any[] = [];

  rawData.forEach((item: any) => {
    if (item[fieldName]) {
      const createDate = moment(item[fieldName]);
      if (createDate.isBetween(lastSixMonthsIncludingCurrentMonth, currentDate, 'day', '[]')) {
        included.push(item);
      } else {
        excluded.push(item);
      }
    } else {
      console.debug('Item without create_date ', item);
    }
  });

  return included;
}
