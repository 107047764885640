import moment from 'moment-timezone';
import {
  DogmaDashboardItems,
  DogmaDetailsTabData,
  DogmaFilters,
  DogmaGraphData,
  DogmaParsedData,
  DogmaParsedDetails,
  DogmaRawData,
  DogmaRawDataForGraphProcessing,
  DogmaRawDetails,
  DogmaSummaryContainer,
  DogmaTableData,
  Metadata
} from './DogmaInterface';
import {
  getBackAgeInPeriod,
  getCurrentAndPreviousMonth,
  getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps
} from 'src/utilities/DateTimeUtilities';
import { DashboardFilters } from '../fintech-ops-dashboard/FinTechOpsDataInterfaces';
import { findCommonElements3, indicatorIcon, indicatorIconVariant } from '../fintech-ops-dashboard/FinTechOpsUtils';
import { numberFormatter, getSortedArrayWithNumber, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import { Icon, TableProps } from '@amzn/awsui-components-react';
import * as React from 'react';
import { sortDistinctMonths_MM_YYYY } from '../FinTechOpsDataProcessingFunctions';
import { capitalizeFirstLetter } from '../FinTechOpsCommonElements';
import { DOGMA_DETAILS_ISSUES_VISIBLE_CONTENT, DOGMA_DETAILS_TABLE } from './DogmaTableConfig';

const EMPTY_TABLE_DATA: DogmaTableData = {
  visibleColumns: [],
  columnData: [],
  rowData: []
};

export function filterDataByCreateDates(rawData: DogmaRawData[]): DogmaRawData[] {
  const currentDate = moment();
  const lastSixMonthsIncludingCurrentMonth = getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps();

  const included: DogmaRawData[] = [];
  const excluded: DogmaRawData[] = [];

  rawData.forEach((item) => {
    if (item.created_date) {
      const createDate = moment(item.created_date);
      if (createDate.isBetween(lastSixMonthsIncludingCurrentMonth, currentDate, 'day', '[]')) {
        included.push(item);
      } else {
        excluded.push(item);
      }
    } else {
      console.debug('Item without create_date ', item);
    }
  });

  // For debugging
  // console.log('Dogma Excluded Records:', JSON.stringify(excluded.length));
  // const uniqueMonthsSet = new Set(included.map((item) => moment(item.created_date).format('YYYY-MM')));
  // const uniqueMonths = Array.from(uniqueMonthsSet).sort();
  // console.log('Dogma Included uniqueMonths ', JSON.stringify(uniqueMonths));

  return included;
}

/* tslint:disable */
/* eslint-disable */
export const returnParsedAndFormattedDogmaData = (dogmaRawDetails: DogmaRawData[], lastUpdatedAt: Metadata): DogmaParsedDetails => {
  const parsedDogmaData: DogmaParsedData[] = [];

  const filteredDogmaRawData: DogmaRawData[] = filterDataByCreateDates(dogmaRawDetails);

  filteredDogmaRawData.forEach((item: DogmaRawData) => {
    const parsedItem: DogmaParsedData = {
      created_date_YYYY_MM_DD: item.created_date,
      created_date_MMM_YYYY: moment(item.created_date).format('MMM-YYYY'),
      created_date_YYYYMM: moment(item.created_date, 'YYYY-MM-DD').format('YYYYMM'),
      created_on_month_YYYY_MM_DD: moment(item.created_date).startOf('month').format('YYYY-MM-DD'),
      violation_age_in_minutes: getBackAgeInPeriod(moment(item.created_date, 'YYYY-MM-DD'), 'minutes'),

      team_name: item.team_name,

      dogma_manager_login_hierarchy_concat: item.manager_login_hierarchy_concat.split(':'),
      dogma_manager_login: item.manager_login_hierarchy_concat.split(':')[0],

      dogma_manager_name_hierarchy_concat: item.manager_name_hierarchy_concat.split(':'),
      dogma_manager_name: item.manager_name_hierarchy_concat.split(':')[0],

      dogma_assignee_login: item.risk_owner_login,
      dogma_assignee_name: item.risk_owner_name,

      risk_best_practices: item.risk_best_practices,
      risk_entity_type: item.risk_entity_type,
      risk_entity_value: item.risk_entity_value,
      risk_pipeline_name: item.risk_pipeline_name,
      risk_rule_id: item.risk_rule_id,
      risk_severity: item.risk_severity,
      status: item.status,

      resolved_date_YYYY_MM_DD: item.resolved_date
    };
    parsedDogmaData.push(parsedItem);
  });

  return {
    metadata: lastUpdatedAt,
    data: parsedDogmaData
  };
};

export const getDashboardFiltersFromDogma = (items: DogmaParsedData[]): DashboardFilters => {
  const dogmaMasterFilterData: DashboardFilters = {
    team_name: [],
    managers: [],
    assignees: []
  };

  const uniqueValues = (filterArray: string[], value: string) => {
    if (!filterArray.includes(value)) {
      filterArray.push(value);
    }
  };

  items?.forEach((item: DogmaParsedData) => {
    uniqueValues(dogmaMasterFilterData.team_name, item.team_name);
    uniqueValues(dogmaMasterFilterData.managers, item.dogma_manager_name);
    uniqueValues(dogmaMasterFilterData.assignees, item.dogma_assignee_name);
  });

  return dogmaMasterFilterData;
};

export const applyDogmaDashboardFiltering = (dogmaParsedData: DogmaParsedData[], filters: DashboardFilters): DogmaParsedData[] => {
  return dogmaParsedData.filter((item: DogmaParsedData) => {
    return (
      findCommonElements3(filters.managers, item.dogma_manager_name_hierarchy_concat) &&
      filters.team_name.includes(item.team_name) &&
      filters.assignees.includes(item.dogma_assignee_name)
    );
  });
};

export const createOpsDogmaDashboardData = (dogmaParsedData: DogmaParsedData[]): DogmaDashboardItems => {
  let totalDogmaViolations = 0;
  let solvedDogmaViolations = 0;
  let unSolvedDogmaViolations = 0;

  let totalOpenHighSeverity = 0;

  dogmaParsedData?.forEach((item: DogmaParsedData) => {
    totalDogmaViolations++;

    if (item.status === 'Resolved') {
      solvedDogmaViolations++;
    } else {
      unSolvedDogmaViolations++;
    }

    if (item.status !== 'Resolved' && item.risk_severity.toLowerCase() === 'High'.toLowerCase()) {
      totalOpenHighSeverity++;
    }
  });

  return {
    totalDogmaViolations: totalDogmaViolations,
    resolvedViolations: solvedDogmaViolations,
    openViolations: unSolvedDogmaViolations,
    openWithHishRisksViolations: totalOpenHighSeverity,
    resolutionRate:
      unSolvedDogmaViolations + solvedDogmaViolations > 0
        ? (((solvedDogmaViolations / (unSolvedDogmaViolations + solvedDogmaViolations)) * 100).toFixed(2) as unknown as number)
        : 0
  };
};

export const createDogmaFilterData = (dogmaParsedData: DogmaParsedData[]): DogmaFilters => {
  const policyEngineMasterFilterData: DogmaFilters = {
    team: [],
    managers: [],
    assignees: [],
    severityTypes: [],
    timePeriods: []
  };
  const uniqueValues = (filterArray: string[], value: string) => {
    if (!filterArray.includes(value)) {
      filterArray.push(value);
    }
  };

  dogmaParsedData.forEach((item: DogmaParsedData) => {
    uniqueValues(policyEngineMasterFilterData.team, item.team_name);
    uniqueValues(policyEngineMasterFilterData.managers, item.dogma_manager_name);
    uniqueValues(policyEngineMasterFilterData.assignees, item.dogma_assignee_name);
    uniqueValues(policyEngineMasterFilterData.severityTypes, item.risk_severity);
    uniqueValues(policyEngineMasterFilterData.timePeriods, item.created_date_MMM_YYYY);
  });

  return policyEngineMasterFilterData;
};

export const applyFiltersOnDogmaDataMetrics = (
  parsedDogmaData: DogmaParsedData[],
  dogmaFilters: DogmaFilters
): [DogmaParsedData[], DogmaParsedData[]] => {
  const filteredWithoutMonthData: DogmaParsedData[] = parsedDogmaData.filter((item: DogmaParsedData) => {
    return (
      dogmaFilters.team.includes(item.team_name) &&
      findCommonElements3(dogmaFilters.managers, item.dogma_manager_name_hierarchy_concat) &&
      dogmaFilters.assignees.includes(item.dogma_assignee_name) &&
      dogmaFilters.severityTypes.includes(item.risk_severity)
    );
  });
  const filteredWithMonthData: DogmaParsedData[] = filteredWithoutMonthData.filter((item) => {
    return dogmaFilters.timePeriods.includes(item.created_date_MMM_YYYY);
  });
  return [filteredWithoutMonthData, filteredWithMonthData];
};

export const getDogmaCalculatedGraphData = (dogmaParsedData: DogmaParsedData[]): DogmaGraphData => {
  const totalViolationByMonth = getDogmaViolationsByMonth(dogmaParsedData);
  const totalOpenViolationsByTeam = getOpenDogmaViolationsByTeam(dogmaParsedData);
  const totalOpenViolationsBySeverityType = getOpenDogmaViolationsBySeverityType(dogmaParsedData);
  const totalOpenViolationsByRiskEntityType = getOpenDogmaViolationsByRiskEntityType(dogmaParsedData);

  return {
    TotalViolationsByMonth: totalViolationByMonth,
    TotalOpenViolationsByTeam: totalOpenViolationsByTeam,
    TotalOpenViolationsBySeverityType: totalOpenViolationsBySeverityType,
    TotalOpenViolationsByRiskEntityType: totalOpenViolationsByRiskEntityType
  };
};

const getDogmaViolationsByMonth = (dogmaParsedData: DogmaParsedData[]): DogmaRawDataForGraphProcessing[] => {
  const aggregatedData: { [monthCategory: string]: { [status: string]: number } } = {};

  dogmaParsedData.forEach((item) => {
    const { status, created_date_YYYY_MM_DD } = item;
    const monthCategory = moment(created_date_YYYY_MM_DD, 'YYYY-MM-DD').format('YYYYMM');

    if (!aggregatedData[monthCategory]) {
      aggregatedData[monthCategory] = {
        Open: 0,
        Resolved: 0
      };
    }

    aggregatedData[monthCategory][status]++;
  });

  // Convert aggregated data to the desired format
  const result: DogmaRawDataForGraphProcessing[] = [];

  for (const monthCategory in aggregatedData) {
    result.push({
      count: aggregatedData[monthCategory].Open,
      title: 'Open',
      category: monthCategory
    });
    result.push({
      count: aggregatedData[monthCategory].Resolved,
      title: 'Resolved',
      category: monthCategory
    });
  }

  return result;
};

const getOpenDogmaViolationsByTeam = (dogmaParsedData: DogmaParsedData[]): DogmaRawDataForGraphProcessing[] => {
  const aggregatedData: { [monthCategory: string]: { [resolutionType: string]: number } } = {};

  // Iterate through the raw data and aggregate by month and resolution_type
  dogmaParsedData
    .filter((item) => item.status !== 'Resolved')
    .forEach((item) => {
      const { team_name, created_date_YYYYMM } = item;

      if (!aggregatedData[created_date_YYYYMM]) {
        aggregatedData[created_date_YYYYMM] = {};
      }

      if (!aggregatedData[created_date_YYYYMM][team_name]) {
        aggregatedData[created_date_YYYYMM][team_name] = 0;
      }

      aggregatedData[created_date_YYYYMM][team_name]++;
    });

  // Convert aggregated data to the desired format
  const result: DogmaRawDataForGraphProcessing[] = [];

  for (const monthCategory in aggregatedData) {
    for (const resolutionType in aggregatedData[monthCategory]) {
      result.push({
        count: aggregatedData[monthCategory][resolutionType],
        title: resolutionType,
        category: monthCategory
      });
    }
  }
  return getSortedArrayWithNumber(result, 'count', 'desc');
};

const getOpenDogmaViolationsBySeverityType = (dogmaParsedData: DogmaParsedData[]): DogmaRawDataForGraphProcessing[] => {
  const aggregatedData: { [monthCategory: string]: { [resolutionType: string]: number } } = {};

  // Iterate through the raw data and aggregate by month and resolution_type
  dogmaParsedData
    .filter((item) => item.status !== 'Resolved')
    .forEach((item) => {
      const { risk_severity, created_date_YYYYMM } = item;

      if (!aggregatedData[created_date_YYYYMM]) {
        aggregatedData[created_date_YYYYMM] = {};
      }

      if (!aggregatedData[created_date_YYYYMM][risk_severity]) {
        aggregatedData[created_date_YYYYMM][risk_severity] = 0;
      }

      aggregatedData[created_date_YYYYMM][risk_severity]++;
    });

  // Convert aggregated data to the desired format
  const result: DogmaRawDataForGraphProcessing[] = [];

  for (const monthCategory in aggregatedData) {
    for (const resolutionType in aggregatedData[monthCategory]) {
      result.push({
        count: aggregatedData[monthCategory][resolutionType],
        title: resolutionType,
        category: monthCategory
      });
    }
  }
  return result;
};

const getOpenDogmaViolationsByRiskEntityType = (dogmaParsedData: DogmaParsedData[]): DogmaRawDataForGraphProcessing[] => {
  const aggregatedData: { [monthCategory: string]: { [resolutionType: string]: number } } = {};

  // Iterate through the raw data and aggregate by month and resolution_type
  dogmaParsedData
    .filter((item) => item.status !== 'Resolved')
    .forEach((item) => {
      const { risk_entity_type, created_date_YYYYMM } = item;

      if (!aggregatedData[created_date_YYYYMM]) {
        aggregatedData[created_date_YYYYMM] = {};
      }

      if (!aggregatedData[created_date_YYYYMM][risk_entity_type]) {
        aggregatedData[created_date_YYYYMM][risk_entity_type] = 0;
      }

      aggregatedData[created_date_YYYYMM][risk_entity_type]++;
    });

  // Convert aggregated data to the desired format
  const result: DogmaRawDataForGraphProcessing[] = [];

  for (const monthCategory in aggregatedData) {
    for (const resolutionType in aggregatedData[monthCategory]) {
      result.push({
        count: aggregatedData[monthCategory][resolutionType],
        title: resolutionType,
        category: monthCategory
      });
    }
  }
  return getSortedArrayWithNumber(result, 'count', 'desc');
};

export const getDogmaCalculatedDetailsTabData = (
  dogmaParsedData: DogmaParsedData[],
  finTechOpsDogmaParsedRawData: DogmaParsedData[]
): DogmaDetailsTabData => {
  const dogmaSummaryContainer = getDogmaSummaryContainer(dogmaParsedData, finTechOpsDogmaParsedRawData);
  const totalAggregatedViolationsByManager = getTotalAggregatedViolationsByManager(dogmaParsedData);
  const totalOpenViolationsByRiskSeverity = getOpenViolationsByRiskSeverity(dogmaParsedData);
  const totalAggregatedViolationsByRiskSeverity = getTotalAggregatedViolationsByRiskSeverity(dogmaParsedData);
  const totalOpenViolationDetails = getOpenViolationDetails(dogmaParsedData);

  return {
    dogmaSummaryContainer: dogmaSummaryContainer,
    totalAggregatedViolationsByManager: totalAggregatedViolationsByManager,
    totalOpenViolationsByRiskSeverity: totalOpenViolationsByRiskSeverity,
    totalAggregatedViolationsByRiskSeverity: totalAggregatedViolationsByRiskSeverity,
    totalOpenViolationDetails: totalOpenViolationDetails
  };
};

const getDogmaSummaryContainer = (dogmaParsedData: DogmaParsedData[], finTechOpsDogmaParsedRawData: DogmaParsedData[]): DogmaSummaryContainer => {
  const openRisks = dogmaParsedData.filter((item: DogmaParsedData) => item.status !== 'Resolved');

  const [currentMonth, previousMonth] = getCurrentAndPreviousMonth(dogmaParsedData, 'created_on_month_YYYY_MM_DD');
  const distinctTeams = getUniqueFieldValues(dogmaParsedData, 'team_name');

  const totalRisksInCurrentMonth = finTechOpsDogmaParsedRawData.filter(
    (item) => item.created_on_month_YYYY_MM_DD === currentMonth && item.status === 'Open'
  );
  const totalRisksInPrevMnth = finTechOpsDogmaParsedRawData.filter(
    (item) => item.created_on_month_YYYY_MM_DD === previousMonth && item.status === 'Open'
  );

  const aggregatedValuesByTeam: any[] = [];

  distinctTeams.forEach((team) => {
    const teamsRisksInCurrentMonth = totalRisksInCurrentMonth.filter((item) => item.team_name === team);
    const teamsRisksInPreviousMonth = totalRisksInPrevMnth.filter((item) => item.team_name === team);
    aggregatedValuesByTeam.push({
      team: team,
      totalRisksInCurrentMonth: openRisks.filter((item) => item.team_name === team).length,
      MoM: teamsRisksInCurrentMonth?.length - teamsRisksInPreviousMonth?.length
    });
  });

  const colDef: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'team',
      header: 'Team',
      sortingField: 'team',
      width: '90',
      cell: (item: any) => item.team
    },
    {
      id: 'totalRisksInCurrentMonth',
      header: 'Risks',
      sortingField: 'totalRisksInCurrentMonth',
      width: '90',
      cell: (item: any) => numberFormatter(item.totalRisksInCurrentMonth) || 0
    },
    {
      id: 'MoM',
      header: 'MoM',
      sortingField: 'MoM',
      width: '90',
      cell: (item: any) => (
        <>
          {numberFormatter(Math.abs(item.MoM)) || '-'}
          <Icon name={indicatorIcon(item.MoM)} variant={indicatorIconVariant(item.MoM)} />
        </>
      )
    }
  ];

  const visibleColumns = colDef.map((item) => {
    return item.id || '';
  });

  return {
    dogmaOpenRiskCount: openRisks.length,
    dogmaSummaryTable: {
      rowData: aggregatedValuesByTeam || [],
      columnData: colDef || [],
      visibleColumns: visibleColumns || []
    }
  };
};

const getTotalAggregatedViolationsByManager = (dogmaParsedData: DogmaParsedData[]): DogmaTableData => {
  const totalRiskData: Record<string, any> = {};
  let distinctMonths: string[] = [];

  dogmaParsedData.forEach((item: DogmaParsedData) => {
    const { created_date_MMM_YYYY, dogma_manager_name, status } = item;

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(created_date_MMM_YYYY)) {
      distinctMonths.push(created_date_MMM_YYYY);
    }

    const monthYear = created_date_MMM_YYYY;
    const key = `${dogma_manager_name}`;

    if (!totalRiskData[key]) {
      totalRiskData[key] = {
        dogma_manager_name: dogma_manager_name
      };
    }

    if (!totalRiskData[key][monthYear]) {
      totalRiskData[key][monthYear] = 1;
    } else {
      totalRiskData[key][monthYear]++;
    }
  });

  const result: any[] = Object.values(totalRiskData);
  distinctMonths = sortDistinctMonths_MM_YYYY(distinctMonths);

  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'dogma_manager_name',
      header: 'Manager',
      sortingField: 'dogma_manager_name',
      width: '150',
      cell: (item: any) => <>{item?.dogma_manager_name}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      width: '125',
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month]) : 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    visibleColumns: visbleColumns,
    columnData: columnDefinition,
    rowData: result
  };
};

const getOpenViolationsByRiskSeverity = (dogmaParsedData: DogmaParsedData[]): DogmaTableData => {
  const uniqueRiskSeverities = new Set<string>();

  dogmaParsedData.forEach((item) => {
    const { risk_severity } = item;
    uniqueRiskSeverities.add(risk_severity);
  });

  const uniqueRiskSeveritiesArray = Array.from(uniqueRiskSeverities);

  const aggregatedDataByTeam: { [team_name: string]: any } = {};

  dogmaParsedData
    .filter((item) => item.status === 'Open')
    .forEach((item) => {
      const { team_name, risk_severity } = item;

      if (!aggregatedDataByTeam[team_name]) {
        aggregatedDataByTeam[team_name] = {
          team_name
        };
        uniqueRiskSeveritiesArray.forEach((severity) => {
          aggregatedDataByTeam[team_name][severity] = 0;
        });
      }

      aggregatedDataByTeam[team_name][risk_severity]++;
    });

  // Convert the dictionary into a flat array
  const flatAggregatedData: any[] = Object.values(aggregatedDataByTeam);

  const severityDef = uniqueRiskSeveritiesArray.map((severity) => {
    return {
      id: severity,
      header: capitalizeFirstLetter(severity),
      sortingField: severity,
      cell: (item: any) => numberFormatter(item[severity]) || 0
    };
  });

  const colDef: any[] = [
    {
      id: 'team_name',
      header: 'Team',
      sortingField: 'team_name',
      width: '100',
      cell: (item: any) => item.team_name
    },
    ...severityDef
  ];

  const visibleColumns = colDef.map((item) => item?.id || '');

  return {
    columnData: colDef || [],
    rowData: flatAggregatedData || [],
    visibleColumns: visibleColumns || []
  };
};

const getTotalAggregatedViolationsByRiskSeverity = (dogmaParsedData: DogmaParsedData[]): DogmaTableData => {
  const totalRiskData: Record<string, any> = {};
  let distinctMonths: string[] = [];

  dogmaParsedData.forEach((item: DogmaParsedData) => {
    const { created_date_MMM_YYYY, risk_severity, status } = item;

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(created_date_MMM_YYYY)) {
      distinctMonths.push(created_date_MMM_YYYY);
    }

    const monthYear = created_date_MMM_YYYY;
    const key = `${risk_severity}`;

    if (!totalRiskData[key]) {
      totalRiskData[key] = {
        risk_severity: risk_severity
      };
    }

    if (!totalRiskData[key][monthYear]) {
      totalRiskData[key][monthYear] = 1;
    } else {
      totalRiskData[key][monthYear]++;
    }
  });

  const result: any[] = Object.values(totalRiskData);
  distinctMonths = sortDistinctMonths_MM_YYYY(distinctMonths);

  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'risk_severity',
      header: 'Risk Severity',
      sortingField: 'risk_severity',
      cell: (item: any) => <>{item?.risk_severity}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month]) : 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    visibleColumns: visbleColumns,
    columnData: columnDefinition,
    rowData: result
  };
};

const getOpenViolationDetails = (dogmaParsedData: DogmaParsedData[]): DogmaTableData => {
  return {
    columnData: DOGMA_DETAILS_TABLE || [],
    rowData: dogmaParsedData.filter((item) => item.status !== 'Resolved') || [],
    visibleColumns: DOGMA_DETAILS_ISSUES_VISIBLE_CONTENT || []
  };
};
