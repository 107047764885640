import * as React from 'react';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import { InfoPanelPageConfigurations } from '../../AdminModels';
import { PHONE_TOOL_URL, eFinSuiteMessages } from 'src/constants/AppConstants';
import { Box, Link } from '@amzn/awsui-components-react';

interface InfoPanelDetailsHelpPanelProps {
  headerName: string;
  infoPanelPageConfiguration: InfoPanelPageConfigurations | undefined;
}

export const InfoPanelDetailsHelpPanel = ({ headerName, infoPanelPageConfiguration }: InfoPanelDetailsHelpPanelProps) => {
  // Sort the array based on position
  const sortedAdditionalResources = infoPanelPageConfiguration?.additionalResources?.sort((a, b) => (a.position || 0) - (b.position || 0));

  const sortedFinanceContacts = infoPanelPageConfiguration?.financeContacts?.sort((a, b) => (a.position || 0) - (b.position || 0));

  const sortedFinTechContacts = infoPanelPageConfiguration?.finTechContacts?.sort((a, b) => (a.position || 0) - (b.position || 0));

  const sortedIssues = infoPanelPageConfiguration?.reportIssues?.sort((a, b) => (a.position || 0) - (b.position || 0));
  const sortedResources = infoPanelPageConfiguration?.additionalResources?.sort((a, b) => (a.position || 0) - (b.position || 0));

  // Checking if any of the information is provided for this App
  const isNoInformationResourcesAvailable = () => {
    return (
      !infoPanelPageConfiguration?.hasFinanceContacts &&
      !infoPanelPageConfiguration?.hasFinTechContacts &&
      !infoPanelPageConfiguration?.hasOfficeHours &&
      !infoPanelPageConfiguration?.hasReportIssues &&
      !infoPanelPageConfiguration?.hasAdditionalResources
    );
  };

  return (
    <>
      {!isNoInformationResourcesAvailable() && (
        <>
          <HelpPanel
            footer={
              <>
                {infoPanelPageConfiguration?.hasAdditionalResources && (
                  <>
                    <h3>
                      Additional resources <Icon name="external" />
                    </h3>
                    <ul>
                      {sortedAdditionalResources?.map((sortedAdditionalResource) => (
                        <li key={sortedAdditionalResource.id}>
                          <a href={sortedAdditionalResource.link || ''} target="_blank">
                            {sortedAdditionalResource.displayName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            }
            header={<h2>{headerName}</h2>}
          >
            <div>
              {infoPanelPageConfiguration?.hasFinanceContacts && (
                <>
                  <h3>Contacts - Finance</h3>
                  <ul>
                    {sortedFinanceContacts?.map((sortedFinanceContact) => (
                      <li key={sortedFinanceContact.id}>
                        {sortedFinanceContact.contactDescription ? `${sortedFinanceContact.contactDescription}: ` : ``}
                        <a href={PHONE_TOOL_URL + sortedFinanceContact.alias || ''} target="_blank">
                          {sortedFinanceContact.alias}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {infoPanelPageConfiguration?.hasFinTechContacts && (
                <>
                  <h3>Contacts - FinTech</h3>
                  <ul>
                    {sortedFinTechContacts?.map((sortedFinTechContact) => (
                      <li key={sortedFinTechContact.id}>
                        {sortedFinTechContact.contactDescription ? `${sortedFinTechContact.contactDescription}: ` : ``}
                        <a href={PHONE_TOOL_URL + sortedFinTechContact.alias || ''} target="_blank">
                          {sortedFinTechContact.alias}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {infoPanelPageConfiguration?.hasOfficeHours && (
                <>
                  <h3>Office Hours</h3>
                  <ul>
                    {infoPanelPageConfiguration?.officeHoursV2?.map((officeHour) => (
                      <li key={officeHour.id}>
                        <Box fontWeight="normal" variant="awsui-key-label">{`${officeHour.dateTimeRecurrence}`}</Box>

                        <Box fontWeight="normal" variant="awsui-key-label">
                          {officeHour.meetingLink && (
                            <Link target="_blank" external href={officeHour.meetingLink}>{`${officeHour.locationOrLinkName}`}</Link>
                          )}
                          {!officeHour.meetingLink && officeHour.locationOrLinkName}
                        </Box>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {infoPanelPageConfiguration?.hasReportIssues && (
                <>
                  <h3>Report Issues - SIM</h3>
                  <ul>
                    {sortedIssues?.map((sortedIssue) => (
                      <li key={sortedIssue.id}>
                        <a href={`${sortedIssue.link}`} target="_blank">
                          {sortedIssue.displayName}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </HelpPanel>
        </>
      )}
      {isNoInformationResourcesAvailable() && (
        <>
          <HelpPanel header={<h2>{headerName}</h2>}>
            <div>
              <p>{eFinSuiteMessages.NO_INFORMATION_INFO_PANEL}</p>
            </div>
          </HelpPanel>
        </>
      )}
    </>
  );
};
