import React from 'react';
import { Badge, Box, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { WidgetConfig } from '../interface';
import { AnnouncementEntity, AppRedirectInfo } from 'src/components/context/AppContextModels';
import MonthDate from 'src/components/generic-components/MonthDate';
import { useNavigate } from 'react-router-dom';
import DFPPopover from 'src/shared/components/Popover';

export const AnnouncementWidget = (
  title: string,
  description: string,
  announcements: AnnouncementEntity[],
  appRedirectInfo?: AppRedirectInfo
): WidgetConfig => {
  return {
    definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 3 },
    data: {
      icon: 'list',
      title: 'Announcements',
      description: description,
      header: <AnnouncementsHeader title={title} description={description} />,
      content: <AnnouncementWidgetContent announcements={announcements} />,
      footer: <AnnouncementsFooter appRedirectInfo={appRedirectInfo} />
    }
  };
};

interface AnnouncementsHeaderProps {
  title: string;
  description: string;
}
const AnnouncementsHeader: React.FC<AnnouncementsHeaderProps> = ({ title, description }) => {
  return (
    <Header description={description}>
      <Box variant="h2">{title}</Box>
    </Header>
  );
};

interface AnnouncementWidgetProps {
  announcements: AnnouncementEntity[];
}
const AnnouncementWidgetContent: React.FC<AnnouncementWidgetProps> = ({ announcements }) => {
  const navigate = useNavigate();

  const clickedOnAppNameBadge = (announcement: AnnouncementEntity) => {
    const appId = announcement.applicationName.id;
    const redirectUrl = `/all-apps/${appId}`;
    navigate(redirectUrl);
  };

  return (
    <div>
      {announcements.length === 0 && (
        <>
          <Box textAlign="center">No content</Box>
        </>
      )}

      {announcements.length != 0 && (
        <SpaceBetween size="xxxs" direction="vertical">
          {announcements?.map((announcement, index) => {
            return (
              <React.Fragment key={index}>
                <SpaceBetween size="m" direction="horizontal" alignItems="center" className="announcement-widget-row">
                  <MonthDate dateTime={announcement.announcementDate || announcement.itemMetadata.createdTime} />

                  <Box className="appNameInAnnouncementWidget" display="block" variant="awsui-key-label" float="left" textAlign="left">
                    <span onClick={() => clickedOnAppNameBadge(announcement)}>
                      <Badge className="app-name-in-badge">{announcement.applicationName.label}</Badge>
                    </span>
                  </Box>

                  <Box display="block" variant="awsui-key-label" float="left" color="text-status-info">
                    <DFPPopover
                      title={announcement.title}
                      link={announcement.announcementLink}
                      linkText={'Link for full announcement'}
                      popoverBody={announcement.announcement}
                    ></DFPPopover>
                  </Box>
                </SpaceBetween>

                {announcements.length - 1 !== index && <div className="horizontal-bar"></div>}
              </React.Fragment>
            );
          })}
        </SpaceBetween>
      )}
    </div>
  );
};

interface AnnouncementsFooterProps {
  appRedirectInfo?: AppRedirectInfo;
}
const AnnouncementsFooter = ({ appRedirectInfo }: AnnouncementsFooterProps) => {
  const navigate = useNavigate();
  return (
    <Box textAlign="center">
      <Link
        onFollow={() => {
          navigate('/announcements', { replace: true, state: appRedirectInfo });
        }}
      >
        View all announcements
      </Link>
    </Box>
  );
};
