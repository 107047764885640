import { CostMetricsDashboardItems } from './CostMetricsInterface';

export const TOP_3_PRODUCTS_VISIBLE_COLUMNS = ['product', 'cost', 'MoM'];
export const TOP_3_ACCOUNTS_VISIBLE_COLUMNS = ['account', 'cost', 'MoM'];

export const COST_METRICS_RESOURCE_TYPE_COLUMN_LISTS = ['metrics_date_YYYYMM', 'resource_type'];

export const COST_METRICS_PRODUCT_COLUMN_LISTS = ['metrics_date_YYYYMM', 'product'];

export const COST_METRICS_RESOURCE_COLUMN_LISTS = ['metrics_date_YYYYMM', 'resource'];

export const COST_METRICS_APPLICATION_COLUMN_LISTS = ['metrics_date_YYYYMM', 'applications'];

export const COST_METRICS_AGGREGATED_BY_PRODUCT_COLUMN_LISTS = ['metrics_date_MMM_YYYY', 'product'];

export const COST_METRICS_AGGREGATED_BY_APPLICATION_COLUMN_LISTS = ['metrics_date_MMM_YYYY', 'applications'];

export const COST_METRICS_AGGREGATED_BY_ACCOUNT_COLUMN_LISTS = ['metrics_date_MMM_YYYY', 'resource'];

export const COST_METRICS_COST_MEASURE_COLUMN_LISTS = [{ name: 'usage_cost', operation: 'floatSum' }];

export const METRICS_DATE_YYYYMM = ['metrics_date_YYYYMM'];

export const COST_METRICS_RESOURCE_COUNT_MEASURE_COLUMN_LISTS = [{ name: 'resource', operation: 'countDistinct' }];

export const COST_METRICS_DETAILS_COLUMN_LISTS = [
  'applications',
  'metrics_date_YYYY_MM_DD',
  'product',
  'fleet_name',
  'fleet_owner',
  'resource',
  'resource_type',
  'team_name',
  'usage_unit',
  'cost_metrics_manager_login',
  'cost_metrics_manager_name'
];

export const COST_METRICS_DETAILS_MEASURE_COLUMN_LISTS = [...COST_METRICS_COST_MEASURE_COLUMN_LISTS, { name: 'usage_value', operation: 'floatSum' }];

export const COST_METRICS_DASHBOARD_COLUMN_LISTS = ['metrics_date_YYYY_MM_DD', 'product'];

export const COST_METRICS_TOP_10_PRODUCTS_DETAILS_COLUMN_LISTS = [...COST_METRICS_DASHBOARD_COLUMN_LISTS, 'team_name', 'fleet_name', 'fleet_owner'];

export const AWS_ACCOUNTS_RESOURCE_TYPE = 'AWS Accounts';

export const BDT_RESOURCE_TYPE = 'BDT';

export const CRADLE_PRODUCT = 'Cradle';

export const INITIAL_COST_METRICS_TAB_DATA: CostMetricsDashboardItems = {
  totalCurrentMonthCost: '0',
  totalPreviousMonthCost: '0',
  topProductContributingToCost: '',
  avgMonthlyCost: '0'
};
