import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';

export const RiskRemediatorSelectionFilters = (props: any) => {
  return (
    <div>
      <SpaceBetween size="l">
        <Box padding={{ top: 'xl' }}>
          <SpaceBetween size="xs">
            {props.header}
            {props.selector}
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </div>
  );
};
