import { FlashbarProps } from '@amzn/awsui-components-react';
import { API, graphqlOperation } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { listAnnouncements, listApplicationFavorites, listApplications, listGeneralContent, listLabels } from 'src/graphql/queries';
import { logger } from 'src/logger';
import { LoadingSpinner } from '../generic-components/LoadingSpinner';
import { usePersistedState } from '../../utilities/useLocalStorage';
import {
  AnnouncementEntity,
  AnnouncementFlatTableEntity,
  AppContext,
  Application,
  ApplicationDropdowns,
  ApplicationFlatTableEntity,
  FavoriteApplications,
  GeneralContent,
  LoadingStatus
} from './AppContextModels';
import { getApplicationDropdownsInSorted, parseAllAnnouncements, parseAllApplications, sortAndFilterAnnouncements } from './AppContextUtils';
import { useAuth } from './AuthContextProvider';
import { eLocalStorageKeys } from 'src/constants/AppConstants';
import { getCurrentTime, getTimeDifference } from 'src/utilities/DateTimeUtilities';
import { recordApiRequest } from 'src/analytics/CloudWatchRumClient';
import { configureAgGridLicense } from 'src/utilities/AWSServices';

const AppContextInitialData: AppContext = {
  isAdminControlsEnabled: false,
  setIsAdminControlsEnabled: () => {},
  contextLoadingError: null,
  contextLoadingStatus: LoadingStatus.NotInitiated,
  listOfApplications: [],
  setListOfApplications: () => {},
  parsedApplications: [],
  setParsedApplications: () => {},
  favoriteApplications: [],
  setFavoriteApplications: () => {},
  announcements: [],
  setAnnouncements: () => {},
  announcementsFlatEntity: [],
  setAnnouncementsFlatEntity: () => {},
  applicationMessages: [],
  displayApplicationFlashMessage: () => {},
  listApplicationDropdowns: {},
  setListApplicationDropdowns: () => {},
  listGeneralContent: [],
  setListGeneralContent: () => {}
};

const AppContextDetails = createContext(AppContextInitialData);
export const useAppContext = () => {
  return useContext(AppContextDetails);
};

interface AppContextProviderProps {
  children: React.ReactNode;
}
// AppContextProvider is used to create context that are useful across the application.
export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const userAuthData = useAuth();

  const [isAdminControlsEnabled, setIsAdminControlsEnabled] = usePersistedState(eLocalStorageKeys.IsAdminControlsEnabled, false);
  const [contextLoadingError, setContextLoadingError] = React.useState<string | null>(null);
  const [appContextLoadingStatus, setAppContextLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NotInitiated);
  const [listApplicationDropdowns, setListApplicationDropdowns] = useState<ApplicationDropdowns>({});

  const [listOfApplications, setListOfApplications] = useState<Application[]>([]);
  const [parsedApplications, setParsedApplications] = useState<ApplicationFlatTableEntity[]>([]);
  const [favoriteApplications, setFavoriteApplications] = useState<string[]>([]);
  const [applicationMessages, setApplicationMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [announcements, setAnnouncements] = useState<AnnouncementEntity[]>([]);
  const [announcementsFlatEntity, setAnnouncementsFlatEntity] = useState<AnnouncementFlatTableEntity[]>([]);
  const [listGeneralContent, setListGeneralContent] = useState<GeneralContent[]>([]);

  useEffect(() => {
    if (userAuthData.userAuthDataLoadingStatus === LoadingStatus.Completed) {
      logger.info('User authenticated successfully!');
      configureAgGridLicense();
      setAppContextLoadingStatus(LoadingStatus.Loading);
      Promise.all([fetchAllApplications(), fetchApplicationDropdowns(), fetchFavoriteApplications(userAuthData.Alias), fetchGeneralContent()])
        .then(async (values) => {
          setListOfApplications(values[0].allApplications);
          setParsedApplications(values[0].applicationFlatTableEntity);
          setListApplicationDropdowns(values[1]);
          setFavoriteApplications(values[2]);

          setListGeneralContent(values[3]);

          fetchAnnouncements(values[0].allApplications)
            .then((parsedAnnouncements) => {
              setAnnouncements(parsedAnnouncements.announcementList);
              setAnnouncementsFlatEntity(parsedAnnouncements.announcementsFlatTableEntity);
              setAppContextLoadingStatus(LoadingStatus.Completed);
            })
            .catch(() => {
              setAnnouncements([]);
              setAnnouncementsFlatEntity([]);
              setAppContextLoadingStatus(LoadingStatus.Failed);
            });
        })
        .catch(() => {
          const errorMessage = 'Error while loading context. Some of the features in Application might not work as expected.';
          setListOfApplications([]);
          setParsedApplications([]);
          setListGeneralContent([]);
          setContextLoadingError(errorMessage);
          logger.error(errorMessage);
          setAppContextLoadingStatus(LoadingStatus.Failed);
        });
    }
  }, [userAuthData]);

  const displayApplicationFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    const newFlashMessage = {
      type: flashBarType,
      content: content,
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setApplicationMessages([])
    };
    addFlashbarItem(newFlashMessage);
  };

  const addFlashbarItem = (item: FlashbarProps.MessageDefinition) => {
    if (applicationMessages.length >= 1) {
      // Remove the oldest from Array
      const newFlashBarItems = applicationMessages.slice(1);

      // Add the new item to the Array
      newFlashBarItems.push(item);

      // Update the state
      setApplicationMessages(newFlashBarItems);
    } else {
      // Add the new item to the Array
      setApplicationMessages([...applicationMessages, item]);
    }
  };

  return (
    <>
      {userAuthData.userAuthDataLoadingStatus === LoadingStatus.Loading || appContextLoadingStatus === LoadingStatus.Loading ? (
        <LoadingSpinner />
      ) : (
        <AppContextDetails.Provider
          value={{
            isAdminControlsEnabled,
            setIsAdminControlsEnabled,
            contextLoadingStatus: appContextLoadingStatus,
            contextLoadingError,
            listOfApplications,
            setListOfApplications,
            parsedApplications,
            setParsedApplications,
            favoriteApplications,
            setFavoriteApplications,
            announcements,
            setAnnouncements,
            announcementsFlatEntity,
            setAnnouncementsFlatEntity,
            applicationMessages,
            displayApplicationFlashMessage,
            listApplicationDropdowns,
            setListApplicationDropdowns,
            listGeneralContent,
            setListGeneralContent
          }}
        >
          {children}
        </AppContextDetails.Provider>
      )}
    </>
  );
};

interface AllAppsWithParsed {
  allApplications: Application[];
  applicationFlatTableEntity: ApplicationFlatTableEntity[];
}
export const fetchAllApplications = async (): Promise<AllAppsWithParsed> => {
  let allApplications: Application[] = [];
  let applicationFlatTableEntity: ApplicationFlatTableEntity[] = [];
  try {
    const start = getCurrentTime();
    const listOfApplicationsAPIResponse: any = await API.graphql(graphqlOperation(listApplications));
    allApplications = listOfApplicationsAPIResponse.data.listApplications;
    applicationFlatTableEntity = parseAllApplications(allApplications);
    const elapsed = getTimeDifference(start);
    logger.info(`Fetched Application Data in ${elapsed} ms`);
    recordApiRequest('listApplications', elapsed);
    return {
      allApplications: allApplications.sort((a: Application, b: Application) => {
        const nameA = a.applicationName ?? ''; // Use empty string as fallback for null values
        const nameB = b.applicationName ?? ''; // Use empty string as fallback for null values
        return nameA.localeCompare(nameB);
      }),
      applicationFlatTableEntity: applicationFlatTableEntity
    };
  } catch (error: any) {
    logger.error('error on fetching applications', error);
    throw new Error('Unable to fetch applications');
  }
};

export const fetchApplicationDropdowns = async () => {
  try {
    const start = getCurrentTime();
    const listOfApplicationDropdownsResponse: any = await API.graphql(graphqlOperation(listLabels));
    const listAppDropdownResponse: any[] = listOfApplicationDropdownsResponse.data.listLabels;
    const elapsed = getTimeDifference(start);
    logger.info(`Fetched Labels in ${elapsed} ms`);
    recordApiRequest('listLabels', elapsed);
    return getApplicationDropdownsInSorted(listAppDropdownResponse);
  } catch (error: any) {
    logger.error('error while fetchApplicationDropdowns', error);
    throw new Error('Unable to fetch application dropdowns');
  }
};

export const fetchFavoriteApplications = async (userAlias: string) => {
  try {
    const start = getCurrentTime();
    const listApplicationFavoritesResponse: any = await API.graphql({
      query: listApplicationFavorites,
      variables: { input: { userAlias: userAlias } }
    });
    const favoritesOfAllUsers = listApplicationFavoritesResponse.data.listApplicationFavorites as FavoriteApplications[];
    const elapsed = getTimeDifference(start);
    logger.info(`Fetched User Favorite Applications in ${elapsed} ms`);
    recordApiRequest('listApplicationFavorites', elapsed);
    return favoritesOfAllUsers.find((favorite) => favorite.userAlias === userAlias)?.applicationId || [];
  } catch (error: any) {
    logger.error('error while fetchFavoriteApplications', error);
    return [];
  }
};

export const fetchAnnouncements = async (listOfApplications: Application[]) => {
  let sortedAndFilteredAnnouncements: AnnouncementEntity[] = [];
  let announcementsFlatTableEntity: AnnouncementFlatTableEntity[] = [];
  try {
    const start = getCurrentTime();
    const listOfAnnouncementsAPIResponse: any = await API.graphql(graphqlOperation(listAnnouncements));
    const elapsed = getTimeDifference(start);
    logger.info(`Fetched Announcements in ${elapsed} ms`);
    recordApiRequest('listAnnouncements', elapsed);

    sortedAndFilteredAnnouncements = sortAndFilterAnnouncements(listOfApplications, listOfAnnouncementsAPIResponse.data.listAnnouncements);
    announcementsFlatTableEntity = parseAllAnnouncements(listOfApplications, listOfAnnouncementsAPIResponse.data.listAnnouncements);
    return {
      announcementList: sortedAndFilteredAnnouncements,
      announcementsFlatTableEntity: announcementsFlatTableEntity
    };
  } catch (error: any) {
    logger.error(`Unable to fetch the Announcement list`, error);
    return { announcementList: [], announcementsFlatTableEntity: [] };
  }
};

export const fetchGeneralContent = async () => {
  let generalContents: any[] = [];
  try {
    const start = getCurrentTime();
    const listOfGeneralContentAPIResponse: any = await API.graphql(graphqlOperation(listGeneralContent));
    const elapsed = getTimeDifference(start);
    logger.info(`Fetched General Content in ${elapsed} ms`);
    recordApiRequest('listGeneralContent', elapsed);
    generalContents = listOfGeneralContentAPIResponse.data.listGeneralContent;
    return generalContents;
  } catch (error: any) {
    logger.error(`Unable to fetch the General Content list`, error);
    return [];
  }
};
