import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';

export const FinTechOpsDashboardHelpPanel = () => {
  return (
    <div>
      <HelpPanel header={<h3>Operational Excellence</h3>}></HelpPanel>
    </div>
  );
};
