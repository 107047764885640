import { AppLayout, Box, Button, Container, ContentLayout, Flashbar, FlashbarProps, Grid, Header } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as mutations from 'src/graphql/mutations';
import { appLayoutAriaLabels } from 'src/i18n-strings';
import { logger } from 'src/logger';
import { getCurrentUTCTimeZoneInISO } from 'src/utilities/DateTimeUtilities';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../context/AuthContextProvider';
import { DFPBreadcrumbs } from '../../generic-components/DFPBreadcrumb';
import { AdminSideNavigation } from './AdminSideNavigation';
import { Feedback } from './feedback/FeedbacksModel';
import { FeedbackContainer } from '../widgets/feedback-widget/FeedbackWidget';

export const AdminBaseBreadcrumbs = [
  {
    text: 'Admin console',
    href: '/admin'
  }
];

const AdminConsoleContainers = [
  {
    id: uuidv4(),
    Header: 'New application',
    ActionButtonText: 'Start',
    Content: 'Use the guided steps to onboard an app to DaS FinSuite.',
    navigation: '/admin/manage-applications'
  },
  {
    id: uuidv4(),
    Header: 'Manage applications',
    ActionButtonText: 'Go',
    Content: 'View all active apps and make edits to app-specific info fields and landing page widgets.',
    navigation: '/admin/applications'
  },
  {
    id: uuidv4(),
    Header: 'Manage announcements',
    ActionButtonText: 'Go',
    Content: 'Post general or product specific announcements which users can see on the homepage and app-specific landing pages.',
    navigation: '/admin/announcements'
  },
  {
    id: uuidv4(),
    Header: 'Manage feedback',
    ActionButtonText: 'Go',
    Content: 'View feedback submitted by users directly in DaS FinSuite.',
    navigation: '/admin/feedbacks'
  }
];

export const AdminHomePage = () => {
  const navigate = useNavigate();
  const userAuthDetails = useAuth();

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = React.useState('');

  const submitFeedback = () => {
    const feedbackInfo: Feedback = {
      id: uuidv4(),
      applicationName: { id: '', label: '', labelType: 'ApplicationName' },
      feedback: feedback,
      feedbackType: 'Admin Console',
      rating: rating,
      itemMetadata: {
        isActive: true,
        createdBy: userAuthDetails.Alias,
        createdTime: getCurrentUTCTimeZoneInISO(),
        updatedBy: userAuthDetails.Alias,
        updatedTime: getCurrentUTCTimeZoneInISO()
      }
    };
    createFeedback(feedbackInfo)
      .then((response) => {
        logger.info(`Successfully submitted feedback`, { info: response });
        displayFlashMessage(`Successfully submitted your feedback`, 'success');
        clearFeedback();
      })
      .catch((error: any) => {
        displayFlashMessage(`Unable to submit your feedback`, 'error');
        logger.error(`Unable to submit feedback.`, error);
      });
  };

  const clearFeedback = () => {
    setRating(0);
    setFeedback('');
  };

  const createFeedback = async (feedback: Feedback) => {
    const createResponse: any = await API.graphql({
      query: mutations.createFeedback,
      variables: { input: feedback }
    });
    return createResponse?.data?.createFeedback;
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  return (
    <AppLayout
      headerSelector="#h"
      contentType="cards"
      ariaLabels={appLayoutAriaLabels}
      toolsHide={true}
      navigation={<AdminSideNavigation />}
      stickyNotifications
      breadcrumbs={<DFPBreadcrumbs items={[]} />}
      notifications={<Flashbar items={flashbarItems} />}
      content={
        <div className="app-layout-body">
          <ContentLayout
            header={
              <Header
                description="The Admin console allows users with admin permissions to create and maintain info presented for each app in DaS FinSuite"
                variant="h1"
              >
                {'Admin console'}
              </Header>
            }
          >
            <Grid>
              {AdminConsoleContainers.map((container) => {
                return (
                  <Container
                    key={container.id}
                    className="admin-console-container-item"
                    header={
                      <Header
                        variant="h3"
                        actions={
                          <Button
                            onClick={() => {
                              navigate(container.navigation);
                            }}
                          >
                            {container.ActionButtonText}
                          </Button>
                        }
                      >
                        {container.Header}
                      </Header>
                    }
                  >
                    <Box variant="p" margin={'xs'}>
                      {container.Content}
                    </Box>
                  </Container>
                );
              })}
            </Grid>
          </ContentLayout>

          <Box margin={{ top: 'l' }}>
            <Grid>
              <Container
                className="admin-console-feedback-container"
                header={
                  <Header variant="h3" description="Help us improve DaS FinSuite by rating your experience and sending your comments/request!">
                    Feedback
                  </Header>
                }
              >
                <FeedbackContainer id={''} appName={''} displayFlashMessage={displayFlashMessage} feedbackType={'Admin Console'}></FeedbackContainer>
              </Container>
            </Grid>
          </Box>
        </div>
      }
    />
  );
};
