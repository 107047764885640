// import { assignedString, closedString, pendingString, researchingString, resolvedString, workInProgressString } from '../FinTechOpsConstants';
import { PieData, TicketsParsedDataItem } from '../tickets/TicketsInterfaces';

// Other Utilities
export const transformPieData = (status: string, list: PieData[]): PieData[] => {
  const returnList: PieData[] = list;
  let hasStatusStatus = false;
  for (let i = 0; i < list.length; i++) {
    if (list[i].status == status) {
      returnList[i].count = returnList[i].count + 1;
      hasStatusStatus = true;
      break;
    }
  }
  if (!hasStatusStatus) {
    returnList.push({
      status: status,
      count: 1
    });
  }
  return returnList;
};

export const returnStatusList = (items: TicketsParsedDataItem[]): string[] => {
  const statusList: string[] = [];
  items.forEach((item: TicketsParsedDataItem) => {
    if (!statusList.includes(item.status)) {
      statusList.push(item.status);
    }
  });
  return statusList;
};

export function findCommonElements3(arr1: string[], arr2: string[]) {
  return arr1.some((item) => arr2.includes(item));
}

export const indicatorIcon = (icon: number) => (icon > 0 ? 'caret-up-filled' : 'caret-down-filled');

export const indicatorIconVariant = (icon: number) => (icon <= 0 ? 'success' : 'error');

export function MinutesToHoursConverter(mins: number) {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours} hr ${minutes} min`;
}

// Transforms "Aug-2023" to "Aug-23"
export const transformedMonthsArray = (monthsArray: string[]) => {
  return monthsArray.map((month: string) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.slice(-2); // Extract the last two characters of the year
    return `${monthName}-${shortYear}`;
  });
};
