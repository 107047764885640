import { HelpPanel, Icon } from '@amzn/awsui-components-react';
import React from 'react';

export const AnnouncementTools: React.FC<any> = () => {
  return (
    <>
      <div></div>
    </>
  );
};
