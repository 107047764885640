import { Box, Container, Grid, Header } from '@amzn/awsui-components-react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { DetailsTableWithProps, DetailsTableWithPropsNoPag } from 'src/components/fintech-ops/fintech-ops-charts/TableWithPropsCommonComponent';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { useSasContext } from './SasContext';
import React from 'react';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import styles from './styles.module.scss';

export const SasDetailsTab: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const sasContext = useSasContext();

  return (
    <div>
      {finOpsContext.finTechOpsSasDataStatus == 'loading' && <LoadingSpinner />}
      {finOpsContext.finTechOpsSasDataStatus == 'pending' && <NotInitializedContainer />}
      {finOpsContext.finTechOpsSasDataStatus == 'error' && <ErrorContainer />}
      {finOpsContext.finTechOpsSasDataStatus == 'finished' && (
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 3, l: 3, xl: 3 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 9, l: 9, xl: 9 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } }
          ]}
        >
          <Container fitHeight header={<Header variant="h2">Total Open Risks</Header>}>
            <Box variant="h3" textAlign="center">
              {numberFormatter(sasContext.sasSummaryContainer.totalOpenRisks)}
            </Box>
            <div className={styles.UserMetricsFootPrintContainerTable}>
              <DetailsTableWithPropsNoPag
                pageSize={5}
                emptyMessage="No SAS data to display"
                FILTERING_PROPERTIES={[]}
                VISIBLE_CONTENT={sasContext.sasSummaryContainer.totalRisksByTeam.visibleColumns}
                COLUMN_DEFINITIONS={sasContext.sasSummaryContainer.totalRisksByTeam.columnData}
                Data={sasContext.sasSummaryContainer.totalRisksByTeam.rowData}
                headerName=""
              />
            </div>
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={8}
              stickyHeader={false}
              emptyMessage="No SAS data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={sasContext.sasTotalRiskResourceTableData.visibleColumns}
              COLUMN_DEFINITIONS={sasContext.sasTotalRiskResourceTableData.columnData}
              Data={sasContext.sasTotalRiskResourceTableData.rowData}
              headerName="Total Open Risk by Resource Type and Team"
            />
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={6}
              emptyMessage="No SAS data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={sasContext.sasDetailsTableData.visibleColumns}
              COLUMN_DEFINITIONS={sasContext.sasDetailsTableData.columnData}
              Data={sasContext.sasDetailsTableData.rowData}
              headerName="Total Risks Breakout By Team Managers"
            />
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={6}
              emptyMessage="No SAS data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={sasContext.sasManagerMomTableData.visibleColumns}
              COLUMN_DEFINITIONS={sasContext.sasManagerMomTableData.columnData}
              Data={sasContext.sasManagerMomTableData.rowData}
              headerName="Aggregated Risks by Team Managers"
            />
          </Container>
          <Container>
            <DetailsTableWithProps
              pageSize={10}
              emptyMessage="No SAS data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={sasContext.sasOpenTotalRiskTableData.visibleColumns}
              COLUMN_DEFINITIONS={sasContext.sasOpenTotalRiskTableData.columnData}
              Data={sasContext.sasOpenTotalRiskTableData.rowData}
              headerName="Total Open Risk's With Recommendation"
            />
          </Container>
        </Grid>
      )}
    </div>
  );
};
