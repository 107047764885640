import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from '../generic-components/LoadingSpinner';
import { eCacheKeys, eLDAPGroup } from '../../constants/AppConstants';
import { UserAuthContext, LoadingStatus } from './AppContextModels';
import { getApiConfig, getAppSyncConfig, retrieveAPIKey } from 'src/components/context/AuthContextUtils';
import { configureTheLogger } from 'src/logger';
import { Cache } from 'aws-amplify';
import { RumClientInitialize } from 'src/analytics/CloudWatchRumClient';
import { DasFinGenieApp } from '../das-finsuite/widgets/chat-bot-widget/das-fin-genie';

const initialData: UserAuthContext = {
  userAuthDataLoadingStatus: LoadingStatus.Loading
} as UserAuthContext;
const AuthContextDetails = createContext(initialData);
export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthContextProvider = ({ children }: any) => {
  const userAuthData: UserAuthContext = useAuthProvider();
  if (userAuthData?.userAuthDataLoadingStatus === LoadingStatus.Loading) {
    return <LoadingSpinner />;
  } else {
    configureTheLogger(userAuthData);
    RumClientInitialize(userAuthData.Alias);
    return (
      <AuthContextDetails.Provider value={userAuthData}>
        <DasFinGenieApp />
        {children}
      </AuthContextDetails.Provider>
    );
  }
};

const useAuthProvider = () => {
  const [userSession, setUserSession] = useState<any>();
  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...initialData,
    auth_status_message: 'Loading',
    userAuthDataLoadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {
    Auth.configure(getAuthConfig());

    const configureAppSync = async () => {
      const apiKeySecret = (await retrieveAPIKey())?.SecretString;

      try {
        const awsAppSync = getAppSyncConfig(apiKeySecret);
        Amplify.configure(awsAppSync.Appsync);

        const apiConfig = getApiConfig();
        Amplify.configure(apiConfig);
      } catch (error: any) {
        // logger isn't configured at this point.
        console.error('Unable to fetch Config', error);
      }
    };

    const signInWithAmazonFederate = async () => {
      try {
        const userSessionDetails = await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        // logger isn't configured at this point.
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          auth_status_message: 'Unable to sign in with AmazonFederate',
          userAuthDataLoadingStatus: LoadingStatus.Loading
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async (userSessionDetails) => {
        await configureAppSync();
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      })
      .catch(() => {
        signInWithAmazonFederate();
      });
  }, []);

  const getSessionDetails = (credentials: CognitoUserSession) => {
    if (Cache.getItem(eCacheKeys.UserAuthDetails)) {
      return Cache.getItem(eCacheKeys.UserAuthDetails) as UserAuthContext;
    }

    const sessionDetails = {
      Alias: credentials.getIdToken().payload['identities'][0].userId,
      DisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      GivenName: credentials.getIdToken().payload['custom:GIVEN_NAME'],
      Email: credentials.getIdToken().payload['custom:EMAIL'],
      userLDAPGroups: credentials.getIdToken().payload['custom:LDAP_GROUPS'],
      isDev: credentials.getIdToken().payload['custom:LDAP_GROUPS'].includes(eLDAPGroup.DEV_LDAP),
      isAdmin: credentials.getIdToken().payload['custom:LDAP_GROUPS'].includes(eLDAPGroup.ADMIN_LDAP),
      isOEReadOnly: credentials.getIdToken().payload['custom:LDAP_GROUPS'].includes(eLDAPGroup.OE_RO_LDAP),
      auth_status_message: 'SSO completed',
      userAuthDataLoadingStatus: LoadingStatus.Completed
    } as UserAuthContext;

    return sessionDetails;
  };

  return userCognitoAuthData;
};

const getAuthConfig = () => {
  return {
    Auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_D6VvUWsrZ',
      userPoolWebClientId: '5sng7dqb0j8bpojejroh1aovjh',
      identityPoolId: 'us-west-2:f6037eb4-644d-429c-ad8f-f440416a0f6c',
      awsAccountId: '850882930567',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'das-finsuite-application-alpha-v1.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://alpha.fintech.device.finance.amazon.dev',
        redirectSignOut: 'https://alpha.fintech.device.finance.amazon.dev',
        responseType: 'code'
      }
    }
  };
};
