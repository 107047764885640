import { TableProps, PropertyFilterProps } from '@amzn/awsui-components-react';
import { addColumnSortLabels } from 'src/components/fintech-ops/labels';

export const CRADLE_COST_BY_PROFILE_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Account',
    key: 'account',
    groupValuesLabel: 'Account',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Profile',
    key: 'profile',
    groupValuesLabel: 'Profile',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Last Updated By',
    key: 'lastUpdatedBy',
    groupValuesLabel: 'Last Updated By',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Cost',
    key: 'cost',
    groupValuesLabel: 'Cost',
    operators: [':', '!:', '=', '!=']
  }
];

export const CRADLE_COST_BY_PROFILE_COLUMN_DEFINITIONS: any = addColumnSortLabels([
  {
    id: 'account',
    sortingField: 'account',
    header: 'Account',
    cell: (item: any) => item.account
  },
  {
    id: 'profile',
    sortingField: 'profile',
    header: 'Profile',
    cell: (item: any) => item.profile
  },
  {
    id: 'lastUpdatedBy',
    sortingField: 'lastUpdatedBy',
    cell: (item: any) => item.lastUpdatedBy,
    header: 'Last Updated By'
  },
  {
    id: 'cost',
    sortingField: 'cost',
    cell: (item: any) => item.cost,
    header: 'Cost',
    width: 160
  }
]);

export const CRADLE_COST_BY_PROFILE_VISIBLE_CONTENT = ['account', 'profile', 'lastUpdatedBy', 'cost'];
