import React from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import styles from './styles.module.scss';
import { usePolicyEngineContext } from './PolicyEngineContext';
import { Box, Container, Grid, Header } from '@amzn/awsui-components-react';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { DetailsTableWithProps, DetailsTableWithPropsNoPag } from '../fintech-ops-charts/TableWithPropsCommonComponent';

export const PolicyEngineDetailsTab = () => {
  const finOpsContext = useFinTechContext();
  const policyEngineContext = usePolicyEngineContext();

  return (
    <div>
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'loading' && <LoadingSpinner />}
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'error' && <ErrorContainer />}
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'pending' && <NotInitializedContainer />}
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'finished' && (
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 4, l: 4, xl: 4 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 8, l: 8, xl: 8 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } }
          ]}
        >
          <Container fitHeight header={<Header variant="h2">Total Open Risks</Header>}>
            <Box variant="h3" textAlign="center">
              {numberFormatter(policyEngineContext.policyEngineDetailsTabData.policyEngineSummaryContainer.policyEngineOpenRiskCount)}
            </Box>
            <div className={styles.PolicyEngineDetailsTable}>
              <DetailsTableWithPropsNoPag
                pageSize={5}
                emptyMessage="No Policy Engine data to display"
                FILTERING_PROPERTIES={[]}
                VISIBLE_CONTENT={
                  policyEngineContext?.policyEngineDetailsTabData?.policyEngineSummaryContainer?.policyEngineSummaryTable.visibleColumns
                }
                COLUMN_DEFINITIONS={
                  policyEngineContext?.policyEngineDetailsTabData?.policyEngineSummaryContainer?.policyEngineSummaryTable.columnData
                }
                Data={policyEngineContext?.policyEngineDetailsTabData?.policyEngineSummaryContainer?.policyEngineSummaryTable.rowData}
                headerName=""
              />
            </div>
          </Container>
          <Container fitHeight>
            <div className={styles.PolicyEngineDetailsTable}>
              <DetailsTableWithProps
                pageSize={100}
                stickyHeader={false}
                emptyMessage="No Policy Engine data to display"
                FILTERING_PROPERTIES={[]}
                VISIBLE_CONTENT={policyEngineContext?.policyEngineDetailsTabData?.risksByTeamAndManager_Monthly?.visibleColumns}
                COLUMN_DEFINITIONS={policyEngineContext?.policyEngineDetailsTabData?.risksByTeamAndManager_Monthly?.columnData}
                Data={policyEngineContext?.policyEngineDetailsTabData?.risksByTeamAndManager_Monthly?.rowData}
                headerName="Aggregated Risks by Team & Manager"
              />
            </div>
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={6}
              emptyMessage="No Policy Engine data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={policyEngineContext?.policyEngineDetailsTabData?.risksByPolicyClass_Monthly?.visibleColumns}
              COLUMN_DEFINITIONS={policyEngineContext?.policyEngineDetailsTabData?.risksByPolicyClass_Monthly?.columnData}
              Data={policyEngineContext?.policyEngineDetailsTabData?.risksByPolicyClass_Monthly?.rowData}
              headerName="Total Aggregated Risks by Policy Class"
            />
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={6}
              emptyMessage="No Policy Engine data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={policyEngineContext?.policyEngineDetailsTabData?.risksBySeverityType_Monthly?.visibleColumns}
              COLUMN_DEFINITIONS={policyEngineContext?.policyEngineDetailsTabData?.risksBySeverityType_Monthly?.columnData}
              Data={policyEngineContext?.policyEngineDetailsTabData?.risksBySeverityType_Monthly?.rowData}
              headerName="Total Aggregated Risks by Severity Type"
            />
          </Container>
          <Container>
            <DetailsTableWithProps
              pageSize={10}
              emptyMessage="No Policy Engine data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={policyEngineContext?.policyEngineDetailsTabData?.totalOpenRisksDetails?.visibleColumns}
              COLUMN_DEFINITIONS={policyEngineContext?.policyEngineDetailsTabData?.totalOpenRisksDetails?.columnData}
              Data={policyEngineContext?.policyEngineDetailsTabData?.totalOpenRisksDetails?.rowData}
              headerName="Total Open Risk Details"
            />
          </Container>
        </Grid>
      )}
    </div>
  );
};
