import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { useDogmaContext } from './DogmaContext';
import { DogmaParsedData, DogmaFilters } from './DogmaInterface';
import { Box, Button, ColumnLayout, Container, FormField, Multiselect, MultiselectProps, SpaceBetween } from '@amzn/awsui-components-react';
import {
  applyFiltersOnDogmaDataMetrics,
  createDogmaFilterData,
  getDogmaCalculatedDetailsTabData,
  getDogmaCalculatedGraphData
} from './DogmaDataProcessing';
import {
  objectToOptions,
  objectToOptionsForMonths,
  optionsToObject,
  parseObjectToMultiSelectOptions_Sorted,
  returnFilterAgeSorted,
  sortDistinctMonths_MM_YYYY
} from '../FinTechOpsDataProcessingFunctions';
import { logger } from 'src/logger';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import { dateTimeComparatorMMMYYYYFormat, getCurrentTime, getTimeDifference, sortedDateArrayMMMYYYYFormat } from 'src/utilities/DateTimeUtilities';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';

export const DogmaSelectionFilters: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const dogmaContext = useDogmaContext();

  const dogmaParsedData: DogmaParsedData[] = finOpsContext?.finTechOpsDogmaParsedData?.data || [];

  const [lastRefreshed, setLastRefreshed] = useState('');

  const [masterFilterData, setMasterFilterData] = React.useState<DogmaFilters>({
    team: [],
    managers: [],
    assignees: [],
    severityTypes: [],
    timePeriods: []
  });

  const [teamsDropdown, setTeamsDropdown] = useState<MultiselectProps.Options>([]);
  const [managersDropdown, setManagersDropdown] = useState<MultiselectProps.Options>([]);
  const [assigneesDropdown, setAssigneesDropdown] = useState<MultiselectProps.Options>([]);
  const [severityTypesDropdown, setSeverityTypesDropdown] = useState<MultiselectProps.Options>([]);
  const [timePeriodsDropdown, setTimePeriodsDropdown] = useState<MultiselectProps.Options>([]);

  const [rawteamsDropdown, setRawTeamsDropdown] = useState<MultiselectProps.Options>([]);
  const [rawmanagersDropdown, setRawManagersDropdown] = useState<MultiselectProps.Options>([]);
  const [rawassigneesDropdown, setRawAssigneesDropdown] = useState<MultiselectProps.Options>([]);
  const [rawseverityTypesDropdown, setRawSeverityTypesDropdown] = useState<MultiselectProps.Options>([]);
  const [rawtimePeriodsDropdown, setRawTimePeriodsDropdown] = useState<MultiselectProps.Options>([]);

  const [isRawFilter, setIsRawFilter] = useState(true);

  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    if (finOpsContext.finTechOpsDogmaDataStatus === 'finished') {
      const dogmaFilterValues = getDogmaFilters(dogmaParsedData);
      updateDogmaCalculation(dogmaFilterValues);
      setLastRefreshed(finOpsContext?.finTechOpsPolicyEngineParsedData?.metadata?.last_updated_at);
      setIsResetFilter(false);
    }
  }, [finOpsContext.finTechOpsPolicyEngineDataStatus, isResetFilter]);

  const getDogmaFilters = (dogmaParsedData: DogmaParsedData[]) => {
    try {
      const _masterFilterData = createDogmaFilterData(dogmaParsedData);
      setMasterFilterData(_masterFilterData);

      setTeamsDropdown(objectToOptions(_masterFilterData.team));
      setManagersDropdown(objectToOptions(_masterFilterData.managers));
      setAssigneesDropdown(objectToOptions(_masterFilterData.assignees));
      setSeverityTypesDropdown(objectToOptions(_masterFilterData.severityTypes));
      setTimePeriodsDropdown(objectToOptionsForMonths(_masterFilterData.timePeriods));

      //one time setting values for placeholder value comparison with raw filter and currently applied filter
      if (isRawFilter === true) {
        setRawTeamsDropdown(objectToOptions(_masterFilterData.team));
        setRawManagersDropdown(objectToOptions(_masterFilterData.managers));
        setRawAssigneesDropdown(objectToOptions(_masterFilterData.assignees));
        setRawSeverityTypesDropdown(objectToOptions(_masterFilterData.severityTypes));
        setRawTimePeriodsDropdown(objectToOptionsForMonths(_masterFilterData.timePeriods));
      }
      setIsRawFilter(false);

      return _masterFilterData;
    } catch (error: any) {
      logger.error('getDogmaFilters: Unable to update Dogma filters', error);
      dogmaContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      dogmaContext.setDogmaGraphStatus('error');
      dogmaContext.setDogmaDetailsTabStatus('error');
      return { team: [], managers: [], assignees: [], severityTypes: [], timePeriods: [] };
    }
  };

  const triggerCall = () => {
    const selectedTeams = optionsToObject(teamsDropdown);
    const selectedManagers = optionsToObject(managersDropdown);
    const selectedAssignees = optionsToObject(assigneesDropdown);
    const selectedSeverityTypes = optionsToObject(severityTypesDropdown);
    const selectedTimePeriods = optionsToObject(timePeriodsDropdown);

    updateDogmaCalculation({
      team: selectedTeams,
      managers: selectedManagers,
      assignees: selectedAssignees,
      severityTypes: selectedSeverityTypes,
      timePeriods: selectedTimePeriods
    });
  };

  const updateDogmaCalculation = (selectedFilterData: DogmaFilters) => {
    dogmaContext.setDogmaGraphStatus('loading');
    dogmaContext.setDogmaDetailsTabStatus('loading');

    try {
      const start = getCurrentTime();

      const [filteredWithoutMonthData, filteredDogmaData]: [DogmaParsedData[], DogmaParsedData[]] = applyFiltersOnDogmaDataMetrics(
        finOpsContext.finTechOpsDogmaParsedData.data,
        selectedFilterData
      );
      getDogmaFilters(filteredDogmaData);

      const calculatedGraphData: any = getDogmaCalculatedGraphData(filteredDogmaData);
      dogmaContext.setDogmaGraphData(calculatedGraphData);

      const calculatedDetalsTableData: any = getDogmaCalculatedDetailsTabData(filteredDogmaData, filteredWithoutMonthData);
      dogmaContext.setDogmaDetailsTabData(calculatedDetalsTableData);

      const elapsed = getTimeDifference(start);
      logger.info(`Fetched calculated data for Dogma in ${elapsed} ms`);

      dogmaContext.setDogmaGraphStatus('finished');
      dogmaContext.setDogmaDetailsTabStatus('finished');
    } catch (error: any) {
      logger.error('Unable to process Dogma data', error);
      dogmaContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      dogmaContext.setDogmaGraphStatus('error');
      dogmaContext.setDogmaDetailsTabStatus('error');
    }
  };

  return (
    <Box padding={{ top: 'xl' }}>
      <Container
        fitHeight
        header={
          <SpaceBetween size="m" direction="horizontal" alignItems="center">
            <Box variant="h2">Dogma</Box>
            <LastRefreshedAt lastRefreshedDateTime={lastRefreshed} />
            <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
          </SpaceBetween>
        }
      >
        <ColumnLayout columns={5} minColumnWidth={20}>
          <FormField label="Team">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(teamsDropdown, 'Team', optionsToObject(rawteamsDropdown))}
              selectedOptions={teamsDropdown}
              onChange={({ detail }) => setTeamsDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.team, 'All Teams')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Teams"
              errorText="Unable to load data"
              statusType={dogmaContext.dogmaGraphStatus}
            />
          </FormField>

          <FormField label="Manager">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(managersDropdown, 'Manager', optionsToObject(rawmanagersDropdown))}
              selectedOptions={managersDropdown}
              onChange={({ detail }) => setManagersDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.managers, 'All Managers')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Managers"
              errorText="Unable to load data"
              statusType={dogmaContext.dogmaGraphStatus}
            />
          </FormField>

          <FormField label="Assignee">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(assigneesDropdown, 'Assignee', optionsToObject(rawassigneesDropdown))}
              selectedOptions={assigneesDropdown}
              onChange={({ detail }) => setAssigneesDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.assignees, 'All Assignees')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Assignees"
              errorText="Unable to load data"
              statusType={dogmaContext.dogmaGraphStatus}
            />
          </FormField>

          <FormField label="Severity">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(severityTypesDropdown, 'Severity', optionsToObject(rawseverityTypesDropdown))}
              selectedOptions={severityTypesDropdown}
              onChange={({ detail }) => setSeverityTypesDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.severityTypes, 'All Severity')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Severity"
              errorText="Unable to load data"
              statusType={dogmaContext.dogmaGraphStatus}
            />
          </FormField>

          <FormField label="Month">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(timePeriodsDropdown, 'Months', optionsToObject(rawtimePeriodsDropdown))}
              selectedOptions={timePeriodsDropdown}
              onChange={({ detail }) => setTimePeriodsDropdown(detail.selectedOptions)}
              options={returnFilterAgeSorted(sortedDateArrayMMMYYYYFormat(masterFilterData.timePeriods), 'All Months')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Months"
              errorText="Unable to load data"
              statusType={dogmaContext.dogmaGraphStatus}
            />
          </FormField>
        </ColumnLayout>
      </Container>
    </Box>
  );
};
