import { FlashbarProps, MixedLineBarChartProps, SelectProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import {
  BarResDataSeries,
  LineAppDataSeries,
  PieChartDataSeries,
  AreaDataSeries,
  TicketAggregationTableData,
  TotalTicketsOpenComponent,
  OpenTicketsTableData,
  TicketsParsedDataItem,
  StatusType
} from './TicketsInterfaces';

export type TicketContextType = {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => void;
  //For Graphs
  statusOfGraphTab: StatusType;
  setStatusOfGraphTab: (statusOfGraph: StatusType) => void;

  // Filters
  // ticketAge: SelectProps.Options;
  // setTicketAge: (ticketAge: SelectProps.Options) => void;

  //1
  statusPieData: PieChartDataSeries[];
  setStatusPieData: (statusPieData: PieChartDataSeries[]) => void;
  //3
  ageTicketData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[];
  setAgeTicketData: (ageTicketData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void;
  ageTicketYDomain: number[];
  setAgeTicketYDomain: (ticketsByApplicationDomain: number[]) => void;
  ageTicketXDomain: string[];
  setAgeTicketXDomain: (ticketsByDatesStatusDomain: string[]) => void;
  //5
  sevTicketsByDateData: BarResDataSeries[];
  setSevTicketsByDateData: (ticketsByDateData: BarResDataSeries[]) => void;
  sevTicketsByDateDomain: string[];
  setSevTicketsByDateDomain: (newDates: string[]) => void;
  //6
  ticketsByApplication: LineAppDataSeries[];
  setTicketsByApplication: (ticketsByApplication: LineAppDataSeries[]) => void;
  ticketsByApplicationYDomain: number[];
  setTicketsByApplicationYDomain: (ticketsByApplicationDomain: number[]) => void;
  ticketsByApplicationXDomain: string[];
  setTicketsByApplicationXDomain: (ticketsByApplicationXDomain: string[]) => void;
  //7
  ticketsByDateStatus: BarResDataSeries[];
  setTicketsByDateStatus: (ticketsByResolutionType: BarResDataSeries[]) => void;
  ticketsByDatesStatusDomain: string[];
  setTicketsByDatesStatusDomain: (ticketsByDatesStatusDomain: string[]) => void;
  ticketsByStatusYDomain: number[];
  setTicketsByStatusYDomain: (ticketsByApplicationDomain: number[]) => void;

  //8
  ticketsByResolution: BarResDataSeries[];
  setTicketsByResolution: (ticketsByResolution: BarResDataSeries[]) => void;
  ticketsByResolutionDomain: string[];
  setTicketsByResolutionDomain: (ticketsByResolutionDomain: string[]) => void;

  //9
  ticketsByTimeSpent: AreaDataSeries[];
  setTicketsByTimeSpent: (ticketsByTimeSpent: AreaDataSeries[]) => void;
  ticketsByTimeSpentDomain: string[];
  setTicketsByTimeSpentDomain: (ticketsByTimeSpentDomain: string[]) => void;

  //10
  ticketsByTeam: BarResDataSeries[];
  setTicketsByTeam: (ticketsByTeam: BarResDataSeries[]) => void;
  ticketsByTeamYDomain: number[];
  setTicketsByTeamYDomain: (ticketsByTeamDomain: number[]) => void;
  ticketsByTeamXDomain: string[];
  setTicketsByTeamXDomain: (ticketsByTeamXDomain: string[]) => void;

  //For Tables Details Tab
  statusOfDetailsTab: StatusType;
  setStatusOfDetailsTab: (statusOfDetailsTab: StatusType) => void;

  aggregatedDataTableApplications: TicketAggregationTableData;
  setAggregatedDataTableApplications: (aggregatedDataTable: TicketAggregationTableData) => void;

  aggregatedDataTableTeams: TicketAggregationTableData;
  setAggregatedDataTableTeams: (aggregatedDataTable: TicketAggregationTableData) => void;

  ticketDetailsTable: OpenTicketsTableData;
  setTicketDetailsTable: (ticketDetailsTable: OpenTicketsTableData) => void;

  // Row Data, column definition & visible columns for Ticket Resolved Details Table
  ticketResolvedDetailsTable: OpenTicketsTableData;
  setTicketResolvedDetailsTable: (ticketDetailsTable: OpenTicketsTableData) => void;

  // Row Data for Ticket Resolved Details Table with top filter values, it will be used to filter the row data in resolved details table multi select drop down
  filteredResolvedDetailsTable: TicketsParsedDataItem[];
  setFilteredResolvedDetailsTable: (ticketDetailsTable: TicketsParsedDataItem[]) => void;

  openTicketContainer: TotalTicketsOpenComponent[];
  setOpenTicketContainer: (openTicketContainer: TotalTicketsOpenComponent[]) => void;

  openTickets: number;
  setOpenTickets: (openTickets: number) => void;

  openTicketsByTeam: Record<string, number>;
  setOpenTicketsByTeam: (openTicketsByTeam: Record<string, number>) => void;

  // original resolved month lists
  resolvedMonthsList: string[];
  setResolvedMonthsList: (monthList: string[]) => void;

  //filtered resolved month list based on top filters values
  filteredResolvedMonthList: string[];
  setFilteredResolvedMonthsList: (monthList: string[]) => void;

  // original resolved month lists
  resolvedResolutionTypeList: string[];
  setResolvedResolutionTypeList: (resolutionList: string[]) => void;

  //filtered resolved month list based on top filters values
  filteredResolvedResolutionTypeList: string[];
  setFilteredResolvedResolutionTypeList: (resolutionList: string[]) => void;
};

export const TicketContext = createContext<TicketContextType>({
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => {},
  statusOfGraphTab: 'loading',

  // ticketAge: [],
  // setTicketAge: (ticketAge: SelectProps.Options) => {},

  setStatusOfGraphTab: (statusOfGraph: StatusType) => {},
  statusPieData: [],
  setStatusPieData: (statusPieData: PieChartDataSeries[]) => {},
  ageTicketData: [],
  setAgeTicketData: (ageTicketData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  ageTicketYDomain: [],
  setAgeTicketYDomain: (ticketsByApplicationDomain: number[]) => {},
  ageTicketXDomain: [],
  setAgeTicketXDomain: (ticketsByDatesStatusDomain: string[]) => {},
  sevTicketsByDateData: [],
  setSevTicketsByDateData: (ticketsByDateData: BarResDataSeries[]) => {},
  sevTicketsByDateDomain: [],
  setSevTicketsByDateDomain: (newDates: string[]) => {},
  ticketsByApplication: [],
  setTicketsByApplication: (ticketsByApplication: LineAppDataSeries[]) => {},
  ticketsByApplicationYDomain: [],
  setTicketsByApplicationYDomain: (ticketsByApplicationDomain: number[]) => {},
  ticketsByApplicationXDomain: [],
  setTicketsByApplicationXDomain: (ticketsByApplicationXDomain: string[]) => {},
  ticketsByDateStatus: [],
  setTicketsByDateStatus: (ticketsByResolutionType: BarResDataSeries[]) => {},
  ticketsByDatesStatusDomain: [],
  setTicketsByDatesStatusDomain: (ticketsByDatesStatusDomain: string[]) => {},
  ticketsByStatusYDomain: [],
  setTicketsByStatusYDomain: (ticketsByApplicationDomain: number[]) => {},

  ticketsByTeam: [],
  setTicketsByTeam: (ticketsByTeam: BarResDataSeries[]) => {},
  ticketsByTeamYDomain: [],
  setTicketsByTeamYDomain: (ticketsByTeamDomain: number[]) => {},
  ticketsByTeamXDomain: [],
  setTicketsByTeamXDomain: (ticketsByTeamXDomain: string[]) => {},

  ticketsByResolution: [],
  setTicketsByResolution: (ticketsByResolution: BarResDataSeries[]) => {},
  ticketsByResolutionDomain: [],
  setTicketsByResolutionDomain: (ticketsByResolutionDomain: string[]) => {},

  ticketsByTimeSpent: [],
  setTicketsByTimeSpent: (ticketsByTimeSpent: AreaDataSeries[]) => {},
  ticketsByTimeSpentDomain: [],
  setTicketsByTimeSpentDomain: (ticketsByTimeSpentDomain: string[]) => {},

  statusOfDetailsTab: 'loading',
  setStatusOfDetailsTab: (statusOfDetailsTab: StatusType) => {},
  aggregatedDataTableApplications: {} as TicketAggregationTableData,
  setAggregatedDataTableApplications: (aggregatedDataTable: TicketAggregationTableData) => {},
  aggregatedDataTableTeams: {} as TicketAggregationTableData,
  setAggregatedDataTableTeams: (aggregatedDataTable: TicketAggregationTableData) => {},
  ticketDetailsTable: {} as OpenTicketsTableData,
  setTicketDetailsTable: (ticketDetailsTable: OpenTicketsTableData) => {},
  ticketResolvedDetailsTable: {} as OpenTicketsTableData,
  setTicketResolvedDetailsTable: (ticketResolvedDetailsTable: OpenTicketsTableData) => {},

  filteredResolvedDetailsTable: {} as TicketsParsedDataItem[],
  setFilteredResolvedDetailsTable: (ticketFilteredResolvedDetailsTable: TicketsParsedDataItem[]) => {},
  openTicketContainer: [],
  setOpenTicketContainer: (openTicketContainer: TotalTicketsOpenComponent[]) => {},
  openTickets: 0,
  setOpenTickets: (openTickets: number) => {},
  openTicketsByTeam: {},
  setOpenTicketsByTeam: (openTicketsByTeam: Record<string, number>) => {},

  resolvedMonthsList: [],
  setResolvedMonthsList: (monthList: string[]) => {},

  filteredResolvedMonthList: [],
  setFilteredResolvedMonthsList: (filteredResolvedMonthList: string[]) => {},

  resolvedResolutionTypeList: [],
  setResolvedResolutionTypeList: (filteredResolutionList: string[]) => {},

  filteredResolvedResolutionTypeList: [],
  setFilteredResolvedResolutionTypeList: (filteredResolutionList: string[]) => {}
});

export const useTicketContext = () => useContext(TicketContext);
