import { PropertyFilterProps } from '@amzn/awsui-components-react';
import React from 'react';
import { DFPHomeBreadcrumbs } from 'src/components/dfp-home-page/DFPHomePage';

export const getTutorialsBreadcrumbItems = () => {
  return [
    ...DFPHomeBreadcrumbs,
    {
      text: 'Tutorials',
      href: '/tutorials'
    }
  ];
};

export const TUTORIALS_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'category',
    propertyLabel: 'Category',
    groupValuesLabel: 'Category values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'applicationName',
    propertyLabel: 'Application',
    groupValuesLabel: 'Application values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'displayTitle',
    propertyLabel: 'displayTitle',
    groupValuesLabel: 'Title values',
    operators: [':', '!:', '=', '!=']
  }
];
