import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Icon,
  Link,
  Popover,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
  TableProps
} from '@amzn/awsui-components-react';
import Multiselect, { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import React from 'react';
import styles from './tickets/styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { FinTechOpsMessages } from './FinTechOpsConstants';
import { indicatorIcon, indicatorIconVariant } from './fintech-ops-dashboard/FinTechOpsUtils';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { PHONE_TOOL_URL } from 'src/constants/AppConstants';

type MultiSelectProps = {
  optionList: SelectProps.Options;
  optionType: string;
  href?: string;
};

type MetricContainerProps = {
  title: string;
  value: string;
  unit: string;
  href?: string;
  color?: 'grey' | 'blue' | 'green' | 'red' | undefined;
  id?: string;
  description?: string;
};

type AdditionMetricContainerProps = {
  title: string;
  value: string;
  unit: string;
  iconVal: string;
  successIcon: boolean;
  href: string;
};

export type ScaleType = 'linear' | 'log' | 'time' | 'categorical';

export const MultiselectCreator = ({ optionList, optionType, href }: MultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = React.useState<MultiselectProps.Options>([]);
  return (
    <div>
      <Multiselect
        selectedOptions={selectedOptions}
        onChange={({ detail }) => {
          if (detail.selectedOptions.includes(optionList[0])) {
            setSelectedOptions(optionList);
            if (detail.selectedOptions.length == optionList.length - 1) {
              setSelectedOptions(detail.selectedOptions.filter((e) => e != optionList[0]));
            }
          } else {
            setSelectedOptions(detail.selectedOptions);
          }
        }}
        deselectAriaLabel={(e) => `Remove ${e.label}`}
        options={optionList}
        placeholder={optionType}
        selectedAriaLabel="Selected"
        expandToViewport
        tokenLimit={1}
        virtualScroll
      />
    </div>
  );
};

export const BasicMetricContainer = ({ title, value, unit, href, color, id, description }: MetricContainerProps) => {
  const navigate = useNavigate();
  return (
    <Container fitHeight>
      <Badge color={color ? color : 'grey'}>{id}</Badge>
      {description ? (
        <Box color="text-body-secondary" float="right">
          <Popover content={description}>
            <StatusIndicator type="info" colorOverride="grey" />
          </Popover>
        </Box>
      ) : null}
      <Box textAlign="center" variant="awsui-key-label" fontSize="heading-s" fontWeight="normal" padding={'s'}>
        {title}
      </Box>
      <Link
        onFollow={() => {
          navigate(href || '');
        }}
      >
        <Box textAlign="center" variant="h2" fontWeight="normal">
          {value}
          {unit}
        </Box>
      </Link>
    </Container>
  );
};

export const filtersLabel = (value: string) => {
  return <Box color="text-body-secondary">{value}</Box>;
};

export const ComingSoonContainer = () => {
  return (
    <div>
      <Container
        header={
          <Box textAlign="center">
            <h2>Coming Soon</h2>
          </Box>
        }
      >
        <Box textAlign="center">
          <h1>Page is currently under Construction</h1>
        </Box>
      </Container>
    </div>
  );
};

export const NotInitializedContainer = () => {
  return (
    <div>
      <Container
        header={
          <Box textAlign="center">
            <h2>{FinTechOpsMessages.nonInitializedMessageMain}</h2>
          </Box>
        }
      >
        <Box textAlign="center">
          <h1>{FinTechOpsMessages.nonInitializedMessageDescription}</h1>
        </Box>
      </Container>
    </div>
  );
};

export const AdditionalMetricContainer = ({ title, value, unit, iconVal, href }: AdditionMetricContainerProps) => {
  function returnIconName(icon: number) {
    if (icon > 0) {
      return 'caret-up-filled';
    } else {
      return 'caret-down-filled';
    }
  }

  function returnIconVariant(icon: number) {
    if (icon > 0) {
      return 'success';
    } else {
      return 'error';
    }
  }

  return (
    <div>
      <Container
        header={
          <Box textAlign="center">
            <h2>{title}</h2>
          </Box>
        }
      >
        <Link href={href}>
          <Box textAlign="center">
            <h1>
              {value}
              {unit}
            </h1>
          </Box>
        </Link>
        <Box textAlign="center">
          <h4>
            <strong>
              {iconVal} <Icon name={returnIconName(parseFloat(iconVal))} variant={returnIconVariant(parseFloat(iconVal))} />
            </strong>
          </h4>
        </Box>
      </Container>
    </div>
  );
};
export const ErrorContainer = () => {
  return (
    <Box textAlign="center" color="inherit">
      <b>{FinTechOpsMessages.errorContainerMain}</b>
      <Box variant="p" color="inherit">
        {FinTechOpsMessages.errorContainerDescription}
      </Box>
      <Button onClick={() => window.location.reload()}>Refresh</Button>
    </Box>
  );
};
export const displayStringList = (stringList: string[]) => {
  const elements = [];
  for (let i = 0; i < stringList.length; i++) {
    elements.push(
      <Box variant="awsui-key-label" fontSize="heading-s" fontWeight="normal">
        {stringList[i]}
      </Box>
    );
  }
  return elements;
};

interface KeyValueDisplayProps {
  data: Record<string, number>;
}

export const KeyValueDisplay: React.FC<KeyValueDisplayProps> = ({ data }) => {
  return (
    <SpaceBetween direction="horizontal" size="s">
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          <span>{key}:</span>
          <span>{value}</span>
        </div>
      ))}
    </SpaceBetween>
  );
};

export function capitalizeFirstLetter(inputString: string): string {
  if (!inputString) {
    return inputString; // Return an empty string or null/undefined as is
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export const getCellValueWithLink = (hrefPrefix: string, hrefSuffix: string, cellLabel: string, target = '_blank') => {
  return (
    <Link target={target} href={`${hrefPrefix}${hrefSuffix}`}>
      {cellLabel}
    </Link>
  );
};

//Function to create column definition for aggregated tables
export const getColumnDefinitionForAggregatedTable = (
  fieldName: string,
  headerName: string,
  monthList: string[],
  isCurrencyFormatter: boolean = false
): TableProps.ColumnDefinition<any>[] => {
  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: fieldName,
      header: headerName,
      sortingField: fieldName,
      width: 150,
      cell: (item: any) => <>{item[fieldName]}</>,
      isRowHeader: true
    },
    ...monthList.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      width: 125,
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month], true) : isCurrencyFormatter ? '$0' : 0}</>
    }))
  ];

  return columnDefinition;
};

//Function to get Phone tool url link
export const getPhoneToolUrl = (userLogin: any, userName: any) => {
  return (
    <>
      {userLogin ? (
        <Link target="_blank" href={`${PHONE_TOOL_URL}${userLogin}`}>
          {userName}
        </Link>
      ) : (
        <>{''}</>
      )}
    </>
  );
};
