import React, { useState, ReactNode } from 'react';
import { Box, Button, Table, TableProps } from '@amzn/awsui-components-react';
import { Header, Pagination } from '@amzn/awsui-components-react';
import { RISK_REMEDIATOR_DETAILS_FILTERING_PROPERTIES } from './RiskRemediatorTableFilterConfig';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { EmptyStateMessage } from '../EmptyStateMessage';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import { propertyFilterI18nStrings } from 'src/i18n-strings';
import { getPanelContent } from 'src/components/fintech-ops/risk-remediator/utils';

export const getTextFilterCounterText = (count: any) => `${count} ${count === 1 ? 'match' : 'matches'}`;

export const RiskDetailsTable = (props: {
  emptyMessage: ReactNode;
  FILTERING_PROPERTIES?: any;
  VISIBLE_CONTENT?: readonly string[] | undefined;
  Data: readonly unknown[];
  COLUMN_DEFINITIONS: readonly TableProps.ColumnDefinition<unknown>[] | any;
  pageSize?: number;
  headerName?: string | undefined;
  isLoading?: boolean;
  isSplitToggleEnabled: (isSplitPanelOpen: boolean, header: any, body: any) => void;
  shouldEnableSelection: boolean;
  defaultSortColumnKey: string;
  dataType: string;
}) => {
  const [filteringProperties, setFilteringProperties] = useState(props.FILTERING_PROPERTIES);
  const [preferences, setPreferences] = useState({
    pageSize: props.pageSize || 3,
    visibleContent: props.VISIBLE_CONTENT
  });
  const [selectedItem, setSelectedItem] = useState<any>();

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(props.Data, {
    propertyFiltering: {
      filteringProperties: filteringProperties,
      empty: <EmptyStateMessage title="No data" subtitle="No data to display." />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="No match found to display."
          action={
            <Button variant="primary" onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      )
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: props.defaultSortColumnKey
        },
        isDescending: true
      }
    },
    selection: {}
  });

  let emptyMsg = 'No risks to show';
  if (!items) {
    emptyMsg = 'No risks to show';
  }

  const tableOutput = !props.shouldEnableSelection ? (
    <Table
      variant="embedded"
      {...collectionProps}
      columnDefinitions={props.COLUMN_DEFINITIONS}
      items={items}
      loading={props.isLoading}
      loadingText={`Loading ${props.dataType} Details`}
      visibleColumns={props.VISIBLE_CONTENT}
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          countText={getTextFilterCounterText(filteredItemsCount)}
          expandToViewport={true}
          i18nStrings={propertyFilterI18nStrings(props.dataType)}
        />
      }
      resizableColumns
      stickyHeader
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            {emptyMsg}
          </Box>
        </Box>
      }
      header={<Header counter={`(${props.Data.length})`}>{props.headerName}</Header>}
      pagination={<Pagination {...paginationProps} />}
    />
  ) : (
    <Table
      variant="embedded"
      {...collectionProps}
      columnDefinitions={props.COLUMN_DEFINITIONS}
      items={items}
      loading={props.isLoading}
      loadingText={`Loading ${props.dataType} Details`}
      visibleColumns={props.VISIBLE_CONTENT}
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          countText={getTextFilterCounterText(filteredItemsCount)}
          expandToViewport={true}
          i18nStrings={propertyFilterI18nStrings(props.dataType)}
        />
      }
      resizableColumns
      stickyHeader
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            {emptyMsg}
          </Box>
        </Box>
      }
      header={<Header counter={`(${props.Data.length})`}>{props.headerName}</Header>}
      pagination={<Pagination {...paginationProps} />}
      selectedItems={selectedItem}
      selectionType="single"
      ariaLabels={{
        selectionGroupLabel: 'Item selection',
        allItemsSelectionLabel: ({ selectedItems }) => `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
        itemSelectionLabel: ({ selectedItems }, item: any) => item.applicationName
      }}
      trackBy="asr_application_name"
      onSelectionChange={({ detail }) => {
        setSelectedItem(detail.selectedItems);
        props.isSplitToggleEnabled(
          true,
          getPanelContent(detail.selectedItems, 'single').header,
          getPanelContent(detail.selectedItems, 'single').body
        );
      }}
    />
  );

  return tableOutput;
};
