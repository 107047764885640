import React from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { useCostMetricsContext } from './CostMetricsContext';
import { BarLineChartWithProps } from '../fintech-ops-charts/BarChartWithProps';
import { Grid } from '@amzn/awsui-components-react';
import { FinTechOpsMessages, GRAPH_WIDTHS_BREAK_POINTS } from '../FinTechOpsConstants';
import { EmptyStateMessage } from '../EmptyStateMessage';
import { i18nStringNumberFormat } from '../i18nStrings';

export const CostMetricsOverviewPage: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const costMetricsContext = useCostMetricsContext();

  return (
    <div>
      {(() => {
        if (finOpsContext.finTechOpsCostMetricsDataStatus == 'loading') {
          return (
            <>
              <LoadingSpinner />
            </>
          );
        } else if (finOpsContext.finTechOpsCostMetricsDataStatus == 'error') {
          return (
            <div>
              <ErrorContainer />
            </div>
          );
        } else if (finOpsContext.finTechOpsCostMetricsDataStatus == 'pending') {
          return (
            <>
              <NotInitializedContainer />
            </>
          );
        } else if (finOpsContext.finTechOpsCostMetricsDataStatus == 'finished') {
          return (
            <Grid
              gridDefinition={[
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS }
              ]}
            >
              <BarLineChartWithProps
                series={costMetricsContext.costMetricsTrendsByMonthGraphData}
                domainType="string"
                xDomain={costMetricsContext.costMetricsTrendsByMonthGraphDataXDomain}
                yDomain={costMetricsContext.costMetricsTrendsByMonthGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Total Cost(USD)/Accounts by month"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringNumberFormat()}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Cost"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Total Cost(USD)/Accounts by month"
              />
              <BarLineChartWithProps
                series={costMetricsContext.costByResourceTypeGraphData}
                domainType="string"
                xDomain={costMetricsContext.costByResourceTypeGraphDataXDomain}
                yDomain={costMetricsContext.costByResourceTypeGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Cost By Resource Type"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringNumberFormat()}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Cost"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Cost By Resource Type"
                isCurrencyFormatter={true}
              />

              <BarLineChartWithProps
                series={costMetricsContext.costByApplicationGraphData}
                domainType="string"
                xDomain={costMetricsContext.costByApplicationGraphDataXDomain}
                yDomain={costMetricsContext.costByApplicationGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Cost By Application"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringNumberFormat()}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Cost"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Cost By Application"
                isCurrencyFormatter={true}
              />
              <BarLineChartWithProps
                series={costMetricsContext.costByProductGraphData}
                domainType="string"
                xDomain={costMetricsContext.costByProductGraphDataXDomain}
                yDomain={costMetricsContext.costByProductGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Top 10 Products By Cost"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringNumberFormat()}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Cost"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Top 10 Products By Cost"
                isCurrencyFormatter={true}
              />
            </Grid>
          );
        }
      })()}
    </div>
  );
};
