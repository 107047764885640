import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { BarLineChartWithProps } from '../fintech-ops-charts/BarChartWithProps';
import { EmptyStateMessage } from '../EmptyStateMessage';
import { i18nStringHour } from '../i18nStrings';
import { FinTechOpsMessages, GRAPH_WIDTHS_BREAK_POINTS } from '../FinTechOpsConstants';
import { useSasContext } from './SasContext';

export function SasOverViewContent() {
  const finOpsContext = useFinTechContext();
  const sasContext = useSasContext();

  return (
    <div>
      {(() => {
        if (finOpsContext.finTechOpsSasDataStatus == 'loading') {
          return (
            <>
              <LoadingSpinner />
            </>
          );
        } else if (finOpsContext.finTechOpsSasDataStatus == 'error') {
          return (
            <div>
              <ErrorContainer />
            </div>
          );
        } else if (finOpsContext.finTechOpsSasDataStatus == 'pending') {
          return (
            <>
              <NotInitializedContainer />
            </>
          );
        } else if (finOpsContext.finTechOpsSasDataStatus == 'finished') {
          return (
            <Grid
              gridDefinition={[
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS }
              ]}
            >
              <BarLineChartWithProps
                series={sasContext.riskResponseGraphData}
                domainType="string"
                xDomain={sasContext.riskResponseGraphDataXDomain}
                yDomain={sasContext.riskResponseGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Resource risks by type"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringHour('Resource Type')}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Violations"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Total Open Risks by Resources"
              />
              <BarLineChartWithProps
                series={sasContext.totalOpenRiskGraphData}
                domainType="string"
                xDomain={sasContext.totalOpenRiskGraphDataXDomain}
                yDomain={sasContext.totalOpenRiskGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Resource risks by type"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringHour('Resource Type')}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Violations"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Total Open Risks by Team"
              />
              <BarLineChartWithProps
                series={sasContext.campaignsGraphData}
                domainType="string"
                xDomain={sasContext.campaignsGraphDataXDomain}
                yDomain={sasContext.campaignsGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Resource risks by type"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringHour('Resource Type')}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Violations"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Total Open Risks by Campaigns"
              />
              <BarLineChartWithProps
                series={sasContext.totalOpenRecommendationsGraphData}
                domainType="string"
                xDomain={sasContext.totalOpenRecommendationsGraphDataXDomain}
                yDomain={sasContext.totalOpenRecommendationsGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Resource risks by type"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringHour('Resource Type')}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Violations"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Total Open Recommendations and Blocked Risks"
              />
              {/* <BarChartWithProps
                series={sasContext.averageRiskDowGraphData}
                domainType="string"
                xDomain={sasContext.averageRiskDowGraphDataXDomain} //more than likely change this to DOW list
                yDomain={sasContext.averageRiskDowGraphDataYDomain}
                xScaleType="categorical"
                arialLabel="Resource risks by type"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={false}
                // hideFilter={true}
                i18nStrings={i18nStringHour('Resource Type')}
                emptyMessage={
                  <EmptyStateMessage
                    title={FinTechOpsMessages.emptyStateMessage}
                    subtitle={FinTechOpsMessages.emptyStateMessageDescription}
                  />
                }
                xTitle="Day"
                yTitle="Violations"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Average risk by DOW"
              /> */}
            </Grid>
          );
        }
      })()}
    </div>
  );
}
