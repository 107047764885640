/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements {
    listAnnouncements {
      announcement
      announcementCategory {
        id
        label
        labelType
      }
      announcementDate
      announcementLink
      applicationName {
        id
        label
        labelType
      }
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      title
    }
  }
`;
export const listApplicationFavorites = /* GraphQL */ `
  query ListApplicationFavorites($userAlias: String) {
    listApplicationFavorites(userAlias: $userAlias) {
      applicationId
      id
      userAlias
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications {
    listApplications {
      additionalResources {
        displayName
        id
        link
        position
      }
      applicationLink
      applicationName
      applicationOwner {
        id
        label
        labelType
      }
      finTechContacts {
        alias
        contactDescription
        id
        position
      }
      financeContacts {
        alias
        contactDescription
        id
        position
      }
      fullApplicationDescription
      glAccounts {
        id
        label
        labelType
      }
      hasAdditionalResources
      hasAnnouncements
      hasFeedback
      hasFinTechContacts
      hasFinanceContacts
      hasNonProductionEnvironments
      hasOfficeHours
      hasReportIssues
      hasTutorials
      hasWelcomeContent
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      keyDimension {
        id
        label
        labelType
      }
      lastEnhanced {
        id
        label
        labelType
      }
      nonProductionEnvironments {
        accessLevel {
          id
          label
          labelType
        }
        displayText
        id
        link
      }
      officeHoursV2 {
        dateTimeRecurrence
        id
        locationOrLinkName
        meetingLink
      }
      process {
        id
        label
        labelType
      }
      reportIssues {
        displayName
        id
        link
        position
      }
      scenario {
        id
        label
        labelType
      }
      shortApplicationDescription
      timeGranularity {
        id
        label
        labelType
      }
      toolUI {
        id
        label
        labelType
      }
      tutorialContentDetails {
        category
        displayTitle
        id
        link
        position
      }
      welcomeContentDetails {
        description
        displayText
        id
        link
      }
      welcomeTitle
    }
  }
`;
export const listDogmaDetail = /* GraphQL */ `
  query ListDogmaDetail {
    listDogmaDetail {
      dogma_details {
        data {
          created_date
          manager_login_hierarchy_concat
          manager_name_hierarchy_concat
          resolved_date
          risk_best_practices
          risk_entity_type
          risk_entity_value
          risk_owner_login
          risk_owner_name
          risk_pipeline_name
          risk_rule_id
          risk_severity
          status
          team_name
        }
        metadata {
          last_updated_at
        }
      }
    }
  }
`;
export const listFeedback = /* GraphQL */ `
  query ListFeedback {
    listFeedback {
      applicationName {
        id
        label
        labelType
      }
      feedback
      feedbackType
      id
      itemMetadata {
        createdBy
        createdTime
        isActive
        updatedBy
        updatedTime
      }
      rating
    }
  }
`;
export const listGeneralContent = /* GraphQL */ `
  query ListGeneralContent {
    listGeneralContent {
      content
      id
      name
    }
  }
`;
export const listLabels = /* GraphQL */ `
  query ListLabels {
    listLabels {
      id
      label
      labelType
    }
  }
`;
export const listPolicyEngineDetail = /* GraphQL */ `
  query ListPolicyEngineDetail {
    listPolicyEngineDetail {
      policy_engine_details {
        data {
          create_date
          manager_name_hierarchy_concat
          policy_class
          policy_type
          resolution_type
          resolved_date
          severity
          status
          team_name
          violation_entity_type
          violation_name
          violation_pretty_name
          violator_id
          violator_manager_login
          violator_manager_name
          violator_name
          violator_owner
          violator_owner_name
          violator_type
        }
        metadata {
          last_updated_at
        }
      }
    }
  }
`;
export const listSASDetail = /* GraphQL */ `
  query ListSASDetail {
    listSASDetail {
      sas_details {
        data {
          assignee_name
          assignee_login
          team_name
          supervisor_login_name_hierarchy
          supervisor_name_hierarchy
          campaign_name
          resource_type
          resource_name
          is_blocked_campaign
          has_recommendation
          snapshot_month
          risk_status_at_eom
          oldest_risk_age
          oldest_recommendation_age
        }
        metadata {
          last_updated_at
        }
      }
    }
  }
`;
export const listTicketMetricDetail = /* GraphQL */ `
  query ListTicketMetricDetail {
    listTicketMetricDetail {
      ticket_details {
        data {
          application_name
          assigned_date
          assigned_to_group
          assigned_to_individual_name
          assignee_login
          case_id
          create_date
          impact
          last_modified_by
          manager_login_hierarchy_concat
          manager_name_hierarchy_concat
          modified_date
          resolution_type
          resolved_date
          short_description
          sim_issue_alias
          status
          team_name
          total_time_spent
        }
        metadata {
          last_updated_at
        }
      }
    }
  }
`;
export const listUserMetric = /* GraphQL */ `
  query ListUserMetric {
    listUserMetric {
      user_metrics_details {
        data {
          application
          application_type
          distinct_users
          log_month
          measure_value
          supervisor_login_name_hierarchy
          supervisor_name_hierarchy
          team_name
        }
        metadata {
          last_updated_at
        }
      }
    }
  }
`;
export const listMappingConfig = /* GraphQL */ `
  query ListMappingConfig {
    listMappingConfig {
      mapping_config {
        data {
          mapping_key
          mapping_name
          target_table
        }
      }
    }
  }
`;
export const listMappingDetails = /* GraphQL */ `
  query ListMappingDetails($table_name: String) {
    listMappingDetails(table_name: $table_name) {
      mapping_details {
        data
      }
    }
  }
`;
export const listMappingColDetails = /* GraphQL */ `
  query ListMappingColDetails {
    listMappingColDetails {
      col_def {
        data
      }
    }
  }
`;
export const listMappingValidationConfig = /* GraphQL */ `
  query ListMappingValidationConfig {
    listMappingValidationConfig {
      mapping_validation_config {
        data
      }
    }
  }
`;
