import { Box, Popover, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';

interface FormFieldPopoverProps {
  header: string;
  messages: string[];
}
const FormFieldPopover: React.FC<FormFieldPopoverProps> = ({ header, messages }: FormFieldPopoverProps) => {
  return (
    <>
      <Popover
        dismissButton={true}
        position="top"
        size="medium"
        triggerType="custom"
        header={header}
        content={
          <Box>
            {messages.map((message, index) => {
              return <p key={index}>{message}</p>;
            })}
          </Box>
        }
      >
        <StatusIndicator type="info"></StatusIndicator>
      </Popover>
    </>
  );
};

export default FormFieldPopover;
