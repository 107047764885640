import {
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  CardsProps,
  CollectionPreferencesProps,
  NonCancelableCustomEvent,
  TableProps
} from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationFlatTableEntity } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { useAuth } from 'src/components/context/AuthContextProvider';
import * as mutations from 'src/graphql/mutations';
import { logger } from 'src/logger';
import { AppNameWithLink, useAllApps } from './AllApps';

export const DEFAULT_VISIBLE_CONTENT = ['applicationName', 'applicationLink', 'shortApplicationDescription', 'actions'];

export const DEFAULT_VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Properties',
    options: [
      { id: 'applicationName', label: 'Application', editable: false },
      { id: 'applicationLink', label: 'Link', editable: false },
      { id: 'shortApplicationDescription', label: 'Description', editable: false },
      { id: 'applicationOwner', label: 'Team', editable: true },
      { id: 'glAccount', label: 'GL Account', editable: true },
      { id: 'toolUI', label: 'Product UI', editable: true }
    ]
  }
];

export const APPLICATIONS_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  visibleContent: DEFAULT_VISIBLE_CONTENT,
  wrapLines: false,
  stripedRows: false,
  stickyColumns: { first: 0, last: 1 }
};

export const APPLICATION_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ApplicationFlatTableEntity>[] = [
  {
    id: 'applicationName',
    header: 'Application',
    width: 200,
    cell: (item) => <AppNameWithLink appName={item.applicationName} appId={item.id} />,
    sortingField: 'applicationName'
  },
  {
    id: 'applicationLink',
    header: 'Link',
    width: 70,
    cell: (item) => (
      <>
        {item.applicationLink && (
          <Button iconName="external" variant="inline-icon" onClick={() => window.open(item.applicationLink, '_blank')}></Button>
        )}
        {!item.applicationLink && <></>}
      </>
    )
  },
  {
    id: 'shortApplicationDescription',
    header: 'Description',
    cell: (item) => item.shortApplicationDescription,
    sortingField: 'shortApplicationDescription'
  },
  {
    id: 'applicationOwner',
    header: 'Team',
    width: 120,
    cell: (item) => item.applicationOwner,
    sortingField: 'applicationOwner'
  },
  {
    id: 'glAccount',
    header: 'GL Account',
    width: 200,
    cell: (item: ApplicationFlatTableEntity) => item.glAccount,
    sortingField: 'glAccount'
  },
  {
    id: 'toolUI',
    header: 'Product UI',
    width: 150,
    cell: (item) => item.toolUI,
    sortingField: 'toolUI'
  },
  {
    id: 'actions',
    header: '',
    maxWidth: 50,
    cell: (item) => <AllAppsActionButtonDropdown item={item} />
  }
];

export const ALL_APPS_CARD_DEFINITION: CardsProps.CardDefinition<ApplicationFlatTableEntity> = {
  header: (item) => (
    <div className="all-apps-category-view-card-header">
      <h3>{item.applicationName}</h3>
      <AllAppsActionButtonDropdown item={item} />
    </div>
  ),
  sections: [
    {
      id: 'shortApplicationDescription',
      content: (item) => <h5>{item.shortApplicationDescription}</h5>
    }
  ]
};

interface AllAppsActionButtonDropdownProps {
  item: ApplicationFlatTableEntity;
}
export const AllAppsActionButtonDropdown: React.FC<AllAppsActionButtonDropdownProps> = ({ item }: AllAppsActionButtonDropdownProps) => {
  const userAuthData = useAuth();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const allAppsContext = useAllApps();

  const isAdmin = userAuthData.isAdmin || false;
  const buttonActions: ButtonDropdownProps.ItemOrGroup[] = [
    {
      text: 'General actions',
      items: [
        {
          id: 'go_to_application',
          text: 'Go to application',
          disabled: item.applicationLink === '',
          external: true,
          href: item.applicationLink,
          externalIconAriaLabel: ''
        },
        {
          id: 'manage_favorites' + '#' + item.id,
          text: isFavorite(item.id) ? 'Remove from favorites' : 'Add to favorites',
          disabled: false
        }
      ]
    }
  ];
  const adminActions: ButtonDropdownProps.ItemOrGroup[] = [
    {
      text: 'Admin actions',
      items: [
        {
          id: 'editInAdminConsole',
          text: 'Edit app in admin console',
          href: '/admin/manage-applications/' + item.id
        }
      ]
    }
  ];

  function isFavorite(id: string) {
    return appContext.favoriteApplications.find((favoriteApp) => favoriteApp === id);
  }

  const appName = (id: string) => {
    return appContext.listOfApplications.find((app) => app.id === id)?.applicationName;
  };

  const actionType = (id: string) => {
    return isFavorite(id) ? 'removed from' : 'added to';
  };

  const manageFavorites = async (id: string) => {
    let finalArray: string[] = [];
    if (isFavorite(id)) {
      finalArray = appContext.favoriteApplications.filter((item) => item !== id);
    } else {
      finalArray = [...appContext.favoriteApplications, id];
    }

    try {
      const createOrUpdateApplicationFavoritesResponse: any = await API.graphql({
        query: mutations.createOrUpdateApplicationFavorites,
        variables: {
          input: {
            userAlias: userAuthData.Alias,
            applicationId: finalArray
          }
        }
      });
      const response = createOrUpdateApplicationFavoritesResponse.data.createOrUpdateApplicationFavorites;
      appContext.setFavoriteApplications(finalArray);
      allAppsContext.displayFlashMessage(`${appName(id)} has successfully been ${actionType(id)} your Favorites.`, 'success');
      logger.info(`Updated application favorites for user ${userAuthData.Alias}`);
    } catch (error: any) {
      logger.error('Error while updating application favorites ', error);
      allAppsContext.displayFlashMessage(`${appName(id)} could not be ${actionType(id)} your Favorites. Refresh your page to try again.`, 'error');
    }
  };

  return (
    <ButtonDropdown
      variant="icon"
      expandToViewport
      onItemClick={(event: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        event.detail.id.startsWith('manage_favorites') && manageFavorites(event.detail.id.split('#')[1]);
      }}
      onItemFollow={(event: any) => {
        event.preventDefault();
        if (event?.detail?.external && event?.detail?.href) {
          window.open(event?.detail?.href, '_blank');
        } else {
          event?.detail?.href && navigate(event?.detail?.href, { state: { from: '/all-apps/#' + (allAppsContext.viewType?.value || '') } });
        }
      }}
      items={isAdmin ? buttonActions.concat(adminActions) : buttonActions}
    />
  );
};
