import { numericalAbbreviationsFormatter } from 'src/utilities/CommonUtilities';

export function i18nStringDayMonth() {
  return {
    filterLabel: 'Filter displayed data',
    filterPlaceholder: 'Filter data',
    xTickFormatter: (e: Date) =>
      e
        .toLocaleDateString('en-US', {
          year: '2-digit',
          month: 'short',
          day: 'numeric'
        })
        .split(',')
        .join('\n'),
    yTickFormatter: function (e: number) {
      if (e % 1 != 0) {
        return ' ';
      } else {
        return e.toFixed(0);
      }
    }
  };
}

export function i18nStringPieChart() {
  return {
    detailsValue: 'Value',
    detailsPercentage: 'Percentage',
    filterLabel: 'Filter displayed data',
    filterPlaceholder: 'Filter Status',
    filterSelectedAriaLabel: 'selected',
    detailPopoverDismissAriaLabel: 'Dismiss',
    legendAriaLabel: 'Legend',
    chartAriaRoleDescription: 'pie chart',
    segmentAriaRoleDescription: 'segment'
  };
}

export function i18nStringHour(value: string) {
  return {
    filterLabel: 'Filter displayed data',
    filterPlaceholder: `Filter ${value}`,
    yTickFormatter: function (e: number) {
      if (e % 1 != 0) {
        return ' ';
      } else {
        return e.toFixed(0);
      }
    }
  };
}

export function i18nStringTimeSpent(value: string) {
  return {
    filterLabel: 'Filter displayed data',
    filterPlaceholder: `Filter ${value}`,
    yTickFormatter: (e: number) => e.toFixed(2),
    detailTotalLabel: 'Total',
    detailTotalFormatter: (e: number) => e.toFixed(2)
  };
}

//Function to format i18nString to numerical abbreviation
export function i18nStringNumberFormat() {
  return {
    yTickFormatter: (e: number) => numericalAbbreviationsFormatter(e)
  };
}
