import { PropertyFilterProps } from '@amzn/awsui-components-react';
import {
  AnnouncementEntity,
  AnnouncementFlatTableEntity,
  Application,
  ApplicationDropdowns,
  ApplicationFlatTableEntity,
  LabelInput,
  LabelType
} from './AppContextModels';
import { logger } from 'src/logger';
import { dateTimeComparator } from 'src/utilities/DateTimeUtilities';

// Mapped to ApplicationFlatTableEntity
// Sorted as per Application Name
export const parseAllApplications = (listOfApplications: Application[]): ApplicationFlatTableEntity[] => {
  try {
    const parsedApplications = listOfApplications
      ?.sort((a: Application, b: Application) => {
        const nameA = a.applicationName ?? ''; // Use empty string as fallback for null values
        const nameB = b.applicationName ?? ''; // Use empty string as fallback for null values
        return nameA.localeCompare(nameB);
      })
      ?.map((app: Application) => {
        return {
          id: app.id,
          status: app.itemMetadata.isActive ? 'Active' : 'Inactive',
          applicationName: app.applicationName,
          applicationLink: app.applicationLink,
          shortApplicationDescription: app.shortApplicationDescription,
          fullApplicationDescription: app.fullApplicationDescription,
          applicationOwner: app.applicationOwner[0].label,

          // Application categories
          glAccount: app.glAccounts[0].label,
          toolUI: app.toolUI[0].label,

          // Non-production environments

          // Application tags
          timeGranularity: app.timeGranularity?.map((time: LabelInput) => time.label),
          keyDimension: app.keyDimension?.map((time: LabelInput) => time.label),
          process: app.process?.map((time: LabelInput) => time.label),
          scenario: app.scenario?.map((time: LabelInput) => time.label),
          lastEnhanced: app.lastEnhanced?.map((time: LabelInput) => time.label),

          tutorialContentDetails: app.tutorialContentDetails,

          isActive: app.itemMetadata.isActive,
          updatedTime: app.itemMetadata.updatedTime
        } as ApplicationFlatTableEntity;
      });
    return parsedApplications;
  } catch (error: any) {
    logger.error('Unable to parse all applications to flat. ', error);
    return [];
  }
};

// sort only Active Announcements
// sort Announcements based on Announcement Date
// Filtering out Announcements with Inactive Application
export const sortAndFilterAnnouncements = (listOfApplications: Application[], listOfAnnouncements: AnnouncementEntity[]): AnnouncementEntity[] => {
  try {
    const listOfActiveApplicationIds = listOfApplications
      .filter((application: Application) => application.itemMetadata.isActive)
      .map((app) => app.id);

    const sortedAnnouncements = listOfAnnouncements
      // ?.filter((item) => item.itemMetadata.isActive)
      ?.filter((announcement) => listOfActiveApplicationIds.includes(announcement.applicationName.id))
      ?.map((announcement) => {
        return {
          ...announcement,
          applicationName: {
            ...announcement.applicationName,
            label: listOfApplications.find((app) => app.id === announcement.applicationName.id)?.applicationName || '-'
          }
        };
      })
      ?.sort((a: AnnouncementEntity, b: AnnouncementEntity) => {
        return dateTimeComparator(a.announcementDate, b.announcementDate);
      });
    return sortedAnnouncements;
  } catch (error: any) {
    logger.error('Unable to parse announcements.', error);
    return [];
  }
};

// considering only Active Announcements (No need to consider Inactive Announcements anywhere in the App)
// Sorting the Announcements in Descending Order of Announcement Date
// Mapping the Announcements to the Flat Table Entity
// Filtering out Announcements with Inactive Application
export const parseAllAnnouncements = (
  listOfApplications: Application[],
  listOfAnnouncements: AnnouncementEntity[]
): AnnouncementFlatTableEntity[] => {
  try {
    const listOfActiveApplicationIds = listOfApplications
      .filter((application: Application) => application.itemMetadata.isActive)
      .map((app) => app.id);

    const parsedAnnouncements = listOfAnnouncements
      // ?.filter((item) => item.itemMetadata.isActive)
      ?.filter((announcement) => listOfActiveApplicationIds.includes(announcement.applicationName.id))
      ?.map((announcement) => {
        return {
          ...announcement,
          applicationName: {
            ...announcement.applicationName,
            label: listOfApplications.find((app) => app.id === announcement.applicationName.id)?.applicationName || '-'
          }
        };
      })
      ?.sort((a: AnnouncementEntity, b: AnnouncementEntity) => {
        return dateTimeComparator(a.announcementDate, b.announcementDate);
      })
      ?.map((announcement) => {
        return {
          id: announcement.id,
          title: announcement.title,

          applicationId: announcement.applicationName.id,
          applicationName: announcement.applicationName.label,

          announcementCategory: announcement.announcementCategory.label,
          announcement: announcement.announcement,
          announcementDate: announcement.announcementDate,
          announcementLink: announcement.announcementLink,

          status: announcement.itemMetadata.isActive ? 'Active' : 'Inactive',
          isActive: announcement.itemMetadata.isActive,
          createdBy: announcement.itemMetadata.createdBy,
          createdTime: announcement.itemMetadata.createdTime,
          updatedBy: announcement.itemMetadata.updatedBy,
          updatedTime: announcement.itemMetadata.updatedTime
        } as AnnouncementFlatTableEntity;
      });
    return parsedAnnouncements;
  } catch (error: any) {
    logger.error('Unable to parse all announcements to flat. ', error);
    return [];
  }
};

const OPERATORS: PropertyFilterProps.ComparisonOperator[] = [':', '!:', '=', '!='];

const BaseFilteringProperties: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'applicationName',
    propertyLabel: 'Application',
    groupValuesLabel: 'Application values',
    operators: OPERATORS
  },
  {
    key: 'shortApplicationDescription',
    propertyLabel: 'Description',
    groupValuesLabel: 'Description values',
    operators: OPERATORS
  },
  {
    key: 'applicationOwner',
    propertyLabel: 'Team',
    groupValuesLabel: 'FinTech values',
    operators: OPERATORS
  },
  {
    key: 'glAccount',
    propertyLabel: 'GL Account',
    groupValuesLabel: 'GL Account values',
    operators: OPERATORS
  },
  {
    key: 'toolUI',
    propertyLabel: 'Product UI',
    groupValuesLabel: 'Product UI values',
    operators: OPERATORS
  },
  {
    key: 'timeGranularity',
    propertyLabel: 'Lowest Time Grain',
    groupValuesLabel: 'Lowest Time Grain values',
    operators: OPERATORS
  },
  {
    key: 'process',
    propertyLabel: 'Finance Cycle',
    groupValuesLabel: 'Finance Cycle values',
    operators: OPERATORS
  }
];

export const getFilteringProperties = (includeStatus = true): PropertyFilterProps.FilteringProperty[] => {
  const properties = [...BaseFilteringProperties];

  if (includeStatus) {
    properties.unshift({
      key: 'status',
      propertyLabel: 'Status',
      groupValuesLabel: 'Status values',
      operators: OPERATORS
    });
  }

  return properties.sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));
};

export const getUniqueApplicationFilteringOptionsForAllApps = (
  activeParsedApplications: ApplicationFlatTableEntity[],
  includeStatus = true
): PropertyFilterProps.FilteringOption[] => {
  const uniqueOptions: Record<string, string[]> = {};

  const propertiesToExtract: (keyof ApplicationFlatTableEntity)[] = [
    'applicationName',
    'shortApplicationDescription',
    'applicationOwner',
    'glAccount',
    'toolUI',
    'timeGranularity',
    'keyDimension',
    'process',
    'scenario',
    'lastEnhanced'
  ];

  if (includeStatus) {
    propertiesToExtract.unshift('status');
  }

  propertiesToExtract.forEach((property) => {
    uniqueOptions[property] = Array.from(new Set(activeParsedApplications.flatMap((app) => app[property] as string[]))).sort((a, b) =>
      a.localeCompare(b)
    );
  });

  const filteringOptions: PropertyFilterProps.FilteringOption[] = [];
  Object.entries(uniqueOptions).forEach(([propertyKey, values]) => {
    values.forEach((value) => {
      filteringOptions.push({
        propertyKey: propertyKey,
        value: value,
        label: value
      });
    });
  });
  return filteringOptions;
};

export const getApplicationDropdownsInSorted = (listAppDropdownResponse: LabelInput[]): ApplicationDropdowns => {
  const applicationDropdowns: ApplicationDropdowns = {
    listApplicationOwners: sortAndFilterDropdowns(listAppDropdownResponse, 'ApplicationOwner'),
    listGLAccounts: sortAndFilterDropdowns(listAppDropdownResponse, 'GLAccount'),
    listToolUI: sortAndFilterDropdowns(listAppDropdownResponse, 'ToolUI'),
    listAccessLevels: sortAndFilterDropdowns(listAppDropdownResponse, 'AccessLevel'),
    listKeyDimension: sortAndFilterDropdowns(listAppDropdownResponse, 'KeyDimension'),
    listLastEnhanced: sortAndFilterDropdowns(listAppDropdownResponse, 'LastEnhanced'),
    listProcess: sortAndFilterDropdowns(listAppDropdownResponse, 'Process'),
    listScenario: sortAndFilterDropdowns(listAppDropdownResponse, 'Scenario'),
    listTimeGranularity: sortAndFilterDropdowns(listAppDropdownResponse, 'TimeGranularity')
  };
  return applicationDropdowns;
};

const sortAndFilterDropdowns = (listAppDropdownResponse: LabelInput[], labelType: LabelType): LabelInput[] => {
  return listAppDropdownResponse.filter((appDropdown) => appDropdown.labelType === labelType).sort((a, b) => a.label.localeCompare(b.label));
};
