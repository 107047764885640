import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import React, { useEffect, useState } from 'react';
import { eFinSuiteMessages, eGENERAL_APP_CONTENT_KEY } from 'src/constants/AppConstants';
import { AdditionalLinksEntity, DFPInfoPanelContent, GeneralContent } from '../context/AppContextModels';
import { useAppContext } from '../context/AppContextProvider';

export const DFPInfoPanel: React.FC = () => {
  const { listGeneralContent } = useAppContext();
  const helpPanelContent = listGeneralContent
    .find((generalContent: GeneralContent) => generalContent.name === eGENERAL_APP_CONTENT_KEY.DFP_HOME_PAGE_INFO_PANEL_CONTENT)
    ?.content.toString();

  const [helpPanelInfo, setHelpPanelInfo] = useState<DFPInfoPanelContent | null>(null);

  useEffect(() => {
    configureHelpPanel(helpPanelContent);
  }, [helpPanelContent]);

  const configureHelpPanel = (helpPanelContent: string | undefined) => {
    if (!helpPanelContent) {
      setHelpPanelInfo(null);
      return;
    }

    try {
      const parsedJson = JSON.parse(helpPanelContent);

      // Check if the parsed JSON has the expected schema
      if (typeof parsedJson.header === 'string' && typeof parsedJson.description === 'string') {
        const helpPanelInfo: DFPInfoPanelContent = {
          header: parsedJson.header,
          description: parsedJson.description,
          additionalLinks: []
        };

        // Verify and process additionalLinks if available
        if (Array.isArray(parsedJson.additionalLinks)) {
          const additionalLinks: AdditionalLinksEntity[] = parsedJson.additionalLinks;

          const isValidAdditionalLinks = additionalLinks.every((link) => typeof link.text === 'string' && typeof link.href === 'string');

          if (isValidAdditionalLinks) {
            helpPanelInfo.additionalLinks = additionalLinks;
          }
        }

        // Assign the help panel info object to the state variable
        setHelpPanelInfo(helpPanelInfo);
      } else {
        setHelpPanelInfo(null); // JSON does not have the expected schema
      }
    } catch (error) {
      setHelpPanelInfo(null); // Error occurred while parsing JSON
    }
  };

  return (
    <>
      {helpPanelInfo && (
        <HelpPanel
          footer={
            <div>
              <h3>
                Additional Resources <Icon name="external" />
              </h3>
              <ul>
                {helpPanelInfo.additionalLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.href} target="_blank">
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          }
          header={<h2>{helpPanelInfo.header}</h2>}
        >
          <div>
            <p>{helpPanelInfo.description}</p>
          </div>
        </HelpPanel>
      )}
      {!helpPanelContent && (
        <HelpPanel header={<h2>DaS FinSuite</h2>}>
          <div>
            <p>{eFinSuiteMessages.NO_INFORMATION_INFO_PANEL}</p>
          </div>
        </HelpPanel>
      )}
    </>
  );
};
