import { AppLayout, Flashbar, FlashbarProps, Link, MixedLineBarChartProps, Tabs } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { DFPHomePageSideNavigation } from '../../dfp-home-page/DFPHomePageSideNavigation';
import { FinTechOpsHref } from '../FinTechOpsConstants';
import { SasContext } from './SasContext';
import { SaSDetailsTabTableData, SaSSummaryContainerInfo, SasParsedData, StatusType, sasDataDetailsTable } from './SasInterfaces';
import { SasOverViewContent } from './SasOverview';
import { SasSelectionFilters } from './SasSelectionFilters';
import { SasDetailsTab } from './SasDetailsTab';

export function SasPageMain() {
  const finOpsContext = useFinTechContext();

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [multiselectStatus, setMultiselectStatus] = useState<StatusType>('loading');
  const [overViewStatus, setOverViewStatus] = useState<StatusType>('loading');
  const [detailsStatus, setDetailsStatus] = useState<StatusType>('loading');

  //Graphs and Domains
  const [riskResponseGraphData, setRiskResponseGraphData] = useState<MixedLineBarChartProps.BarDataSeries<string | number | Date>[]>([]);
  const [riskResponseGraphDataXDomain, setRiskResponseGraphDataXDomain] = useState<string[]>([]);
  const [riskResponseGraphDataYDomain, setRiskResponseGraphDataYDomain] = useState<number[]>([]);
  const [totalOpenRiskGraphData, setTotalOpenRiskGraphData] = useState<MixedLineBarChartProps.BarDataSeries<string | number | Date>[]>([]);
  const [totalOpenRiskGraphDataXDomain, setTotalOpenRiskGraphDataXDomain] = useState<string[]>([]);
  const [totalOpenRiskGraphDataYDomain, setTotalOpenRiskGraphDataYDomain] = useState<number[]>([]);
  const [campaignsGraphData, setCampaignsGraphData] = useState<MixedLineBarChartProps.BarDataSeries<string | number | Date>[]>([]);
  const [campaignsGraphDataXDomain, setCampaignsGraphDataXDomain] = useState<string[]>([]);
  const [campaignsGraphDataYDomain, setCampaignsGraphDataYDomain] = useState<number[]>([]);
  const [totalOpenRecommendationsGraphData, setTotalOpenRecommendationsGraphData] = useState<
    MixedLineBarChartProps.BarDataSeries<string | number | Date>[]
  >([]);
  const [totalOpenRecommendationsGraphDataXDomain, setTotalOpenRecommendationsGraphDataXDomain] = useState<string[]>([]);
  const [totalOpenRecommendationsGraphDataYDomain, setTotalOpenRecommendationsGraphDataYDomain] = useState<number[]>([]);
  const [averageRiskDowGraphData, setAverageRiskDowGraphData] = useState<MixedLineBarChartProps.BarDataSeries<string | number | Date>[]>([]);
  const [averageRiskDowGraphDataXDomain, setAverageRiskDowGraphDataXDomain] = useState<string[]>([]);
  const [averageRiskDowGraphDataYDomain, setAverageRiskDowGraphDataYDomain] = useState<number[]>([]);

  // Details Tab
  const [sasSummaryContainer, setSasSummaryContainer] = useState<SaSSummaryContainerInfo>({} as SaSSummaryContainerInfo);

  //Tables
  const [sasDetailsTableData, setSasDetailsTableData] = useState<SaSDetailsTabTableData>({} as SaSDetailsTabTableData);
  const [sasTotalRiskResourceTableData, setSasTotalRiskResourceTableData] = useState<SaSDetailsTabTableData>({} as SaSDetailsTabTableData);
  const [sasManagerMomTableData, setSasManagerMomTableData] = useState<SaSDetailsTabTableData>({} as SaSDetailsTabTableData);
  const [sasOpenTotalRiskTableData, setSasOpenTotalRiskTableData] = useState<SaSDetailsTabTableData>({} as SaSDetailsTabTableData);

  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            {' '}
            submit a ticket
          </Link>
        );
      }
    }
    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} 'or ' {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={flashbarItems} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: FinTechOpsHref.finTechOps },
            {
              text: 'SAS',
              href: ''
            }
          ]}
        />
      }
      maxContentWidth={Number.MAX_VALUE}
      content={
        <div>
          <SasContext.Provider
            value={{
              displayNotification,
              multiselectStatus,
              setMultiselectStatus,

              overViewStatus,
              setOverViewStatus,

              detailsStatus,
              setDetailsStatus,

              sasSummaryContainer,
              setSasSummaryContainer,

              riskResponseGraphData,
              setRiskResponseGraphData,
              riskResponseGraphDataXDomain,
              setRiskResponseGraphDataXDomain,
              riskResponseGraphDataYDomain,
              setRiskResponseGraphDataYDomain,

              totalOpenRiskGraphData,
              setTotalOpenRiskGraphData,
              totalOpenRiskGraphDataXDomain,
              setTotalOpenRiskGraphDataXDomain,
              totalOpenRiskGraphDataYDomain,
              setTotalOpenRiskGraphDataYDomain,

              campaignsGraphData,
              setCampaignsGraphData,
              campaignsGraphDataXDomain,
              setCampaignsGraphDataXDomain,
              campaignsGraphDataYDomain,
              setCampaignsGraphDataYDomain,

              totalOpenRecommendationsGraphData,
              setTotalOpenRecommendationsGraphData,
              totalOpenRecommendationsGraphDataXDomain,
              setTotalOpenRecommendationsGraphDataXDomain,
              totalOpenRecommendationsGraphDataYDomain,
              setTotalOpenRecommendationsGraphDataYDomain,

              averageRiskDowGraphData,
              setAverageRiskDowGraphData,
              averageRiskDowGraphDataXDomain,
              setAverageRiskDowGraphDataXDomain,
              averageRiskDowGraphDataYDomain,
              setAverageRiskDowGraphDataYDomain,

              sasDetailsTableData,
              setSasDetailsTableData,
              sasTotalRiskResourceTableData,
              setSasTotalRiskResourceTableData,
              sasManagerMomTableData,
              setSasManagerMomTableData,
              sasOpenTotalRiskTableData,
              setSasOpenTotalRiskTableData
            }}
          >
            <SasSelectionFilters />
            <Tabs
              tabs={[
                {
                  label: 'Overview',
                  id: 'sas-OV',
                  content: <SasOverViewContent />
                },
                {
                  label: 'Details',
                  id: 'sas-DT',
                  content: <SasDetailsTab />
                }
              ]}
            />
          </SasContext.Provider>
        </div>
      }
    />
  );
}
