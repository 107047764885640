import { SpaceBetween, Box, Badge, Link } from '@amzn/awsui-components-react';
import React, { Fragment, useState } from 'react';
import CLICK_TO_PLAY from 'src/images/click-to-play.svg';
import NO_THUMBNAIL_WITH_TEXT from 'src/images/no-video-img.svg';
import TutorialVideoModal from './TutorialVideoModal';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from '../../das-finsuite-constants';

interface TutorialThumbnailProps {
  link: string | null;
  displayTitle: string | null;
  category: string | null;
}
const TutorialThumbnail: React.FC<TutorialThumbnailProps> = ({ link, displayTitle, category }) => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);

  const handleTutorialLinkClick = () => {
    logger.info(`DaS FinSuite -> User Action: Tutorials -> Video Click`, {
      event_type: UserActionsEventTypes.TUTORIALS_VIDEO_CLICKED,
      value: displayTitle
    });
    setShowTutorialModal(true);
  };

  const onCancelVideo = () => {
    setShowTutorialModal(false);
  };

  return (
    <Fragment>
      <TutorialVideoModal
        showTutorialModal={showTutorialModal}
        onCancel={onCancelVideo}
        onConfirm={() => setShowTutorialModal(false)}
        link={link}
        displayTitle={displayTitle}
        category={category}
      />
      <Box className="tutorial-link-container">
        <Box>
          {link && link.includes('embed') ? (
            <img
              onClick={() => handleTutorialLinkClick()}
              className="tutorial-link-img-thumbnail click-top-play"
              src={CLICK_TO_PLAY}
              alt="Image"
              width="240"
              height="141"
            />
          ) : (
            <img className="tutorial-link-img-thumbnail" src={NO_THUMBNAIL_WITH_TEXT} alt="Image" width="240" height="141" />
          )}
        </Box>
        <SpaceBetween size="xxs" direction="vertical">
          {category && <Badge>{`${category}`}</Badge>}

          <Link variant="secondary" external href={link || undefined} target="_blank" rel="noopener noreferrer">
            {displayTitle}
          </Link>
        </SpaceBetween>
      </Box>
    </Fragment>
  );
};

export default TutorialThumbnail;
