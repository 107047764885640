import { Button, FormField, Input, StatusIndicator } from '@amzn/awsui-components-react';
import React, { forwardRef, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ContactsEntity } from '../../AdminModels';
import { contactDetailsSchema } from './StepsValidations';
import FormFieldPopover from 'src/components/generic-components/FormFieldPopover';
import { RestAPI } from '@aws-amplify/api-rest';
import { PAPIUserInfo } from 'src/components/context/AppContextModels';
import { logger } from 'src/logger';

// TODO - Yet to implement this
export const validateUserAlias = async (alias: string | undefined) => {
  if (!alias) return false;

  try {
    const response = await RestAPI.get('PEOPLE_API', '/employee', {
      response: true,
      queryStringParameters: {
        login: alias,
        expandOptions: ''
      }
    });

    const employeeBasicInfo: PAPIUserInfo = response.data.basicInfo;
    logger.info(`Alias validated using PAPI for ${employeeBasicInfo.firstName + ' ' + employeeBasicInfo.lastName}`);
    return true;
  } catch (error: any) {
    logger.error(`Alias validation failed using PAPI for ${alias}`, error);
    return false;
  }
};

interface ContactsFormProps {
  hasContacts: boolean;
  addNewContact: (addNewContact: ContactsEntity) => void;
  updateContact: (updateContact: ContactsEntity) => void;
}

export interface ContactsFormMethods {
  editSelectedRow: (addNewTutorialContent: ContactsEntity) => void;
}

const ContactsForm = forwardRef<ContactsFormMethods, ContactsFormProps>(({ hasContacts, addNewContact, updateContact }: ContactsFormProps, ref) => {
  const [actionType, setActionType] = useState<'add' | 'edit'>('add');
  const [contactsValid, setContactsValid] = useState(false);
  const [newContact, setNewContact] = useState<ContactsEntity>({
    id: uuidv4(),
    position: null,
    alias: '',
    contactDescription: ''
  });
  const [newContactErrors, setNewContactErrors] = useState({
    id: '',
    position: '',
    alias: '',
    contactDescription: ''
  });

  const editSelectedRow = (selectedRow: ContactsEntity) => {
    if (selectedRow) {
      setActionType('edit');
      setNewContact({
        id: selectedRow.id,
        position: selectedRow.position,
        alias: selectedRow.alias,
        contactDescription: selectedRow.contactDescription
      });
    } else {
      setActionType('add');
      setNewContact({
        id: uuidv4(),
        position: null,
        alias: '',
        contactDescription: ''
      });
    }
  };

  React.useImperativeHandle(ref, () => ({
    editSelectedRow
  }));

  const addNewRow = () => {
    const newRow: ContactsEntity = {
      id: uuidv4(),
      position: newContact.position ? +newContact.position : null,
      alias: newContact.alias,
      contactDescription: newContact.contactDescription
    };
    setNewContact({ id: null, position: null, alias: '', contactDescription: '' });
    setContactsValid(false);
    addNewContact(newRow);
  };

  const isValidNewContactData = async () => {
    try {
      await contactDetailsSchema().validate(newContact, { abortEarly: false });
      setContactsValid(true);
    } catch (error: any) {
      setContactsValid(false);
    }
  };

  const clearForm = () => {
    setNewContact({ id: uuidv4(), position: null, alias: '', contactDescription: '' });
    setNewContactErrors({ id: '', position: '', alias: '', contactDescription: '' });
    setContactsValid(false);
  };

  const cancelEdit = () => {
    clearForm();
    setActionType('add');
  };

  const updateChanges = () => {
    updateContact(newContact);
    clearForm();
    setActionType('add');
  };

  return (
    <>
      <div className="inline-table-horizontal-form">
        <FormField
          className="width-130px"
          label="Position"
          errorText={newContactErrors?.position}
          stretch={true}
          info={
            <FormFieldPopover
              header={'Position'}
              messages={[
                'Enter a number to specify the list position. ',
                'Items with duplicate numbers will be organized in alphabetical order and leaving it blank will add the item to the end of the list.'
              ]}
            />
          }
        >
          <Input
            placeholder="Enter number"
            disabled={!hasContacts}
            onChange={({ detail }) => {
              setNewContact({
                ...newContact,
                position: isNaN(+detail.value) ? null : +detail.value
              });
            }}
            value={newContact.position == null ? '' : newContact.position.toString()}
            onBlur={async () => {
              contactDetailsSchema()
                .validateAt('position', { position: newContact.position })
                .then(() => {
                  setNewContactErrors({
                    ...newContactErrors,
                    position: ''
                  });
                })
                .catch((error) => {
                  setNewContactErrors({
                    ...newContactErrors,
                    position: error.message
                  });
                })
                .finally(() => {
                  isValidNewContactData();
                });
            }}
          />
        </FormField>

        <FormField
          className="flex-grow-field"
          label={
            <>
              Description<i> - optional</i>
            </>
          }
          errorText={newContactErrors?.contactDescription}
          stretch={true}
        >
          <Input
            placeholder="Enter description"
            disabled={!hasContacts}
            onChange={({ detail }) => {
              setNewContact({ ...newContact, contactDescription: detail.value });
            }}
            value={newContact.contactDescription || ''}
            onBlur={async () => {
              contactDetailsSchema()
                .validateAt('contactDescription', { contactDescription: newContact.contactDescription })
                .then(() => {
                  setNewContactErrors({
                    ...newContactErrors,
                    contactDescription: ''
                  });
                })
                .catch((error) => {
                  setNewContactErrors({
                    ...newContactErrors,
                    contactDescription: error.message
                  });
                })
                .finally(() => {
                  isValidNewContactData();
                });
            }}
          />
        </FormField>

        <FormField className="flex-grow-field" label="Alias" errorText={newContactErrors?.alias} stretch={true}>
          <Input
            placeholder="Enter alias"
            disabled={!hasContacts}
            onChange={({ detail }) => {
              setNewContact({
                ...newContact,
                alias: detail.value
              });
            }}
            value={newContact.alias || ''}
            onBlur={async () => {
              contactDetailsSchema()
                .validateAt('alias', { alias: newContact.alias })
                .then(() => {
                  setNewContactErrors({
                    ...newContactErrors,
                    alias: ''
                  });
                })
                .catch((error) => {
                  setNewContactErrors({
                    ...newContactErrors,
                    alias: error.message
                  });
                })
                .finally(() => {
                  isValidNewContactData();
                });
            }}
          />
        </FormField>

        {actionType === 'add' && (
          <Button className="add-new-button" formAction="none" disabled={!contactsValid} onClick={addNewRow}>
            {`Add`}
          </Button>
        )}
        {actionType === 'edit' && (
          <>
            <Button className="add-new-button" formAction="none" onClick={cancelEdit}>
              {`Cancel`}
            </Button>
            <Button className="add-new-button" variant="primary" formAction="none" disabled={!contactsValid} onClick={updateChanges}>
              {`Save`}
            </Button>
          </>
        )}
      </div>
    </>
  );
});

export default ContactsForm;
