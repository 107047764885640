import { MixedLineBarChartProps, TableProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import React from 'react';
import {
  convertUTCtoUserTimeZone,
  getBackAgeInPeriod,
  getDiffInTime,
  getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps,
  getMomentTime
} from 'src/utilities/DateTimeUtilities';
import {
  FINTECH_MONTH_COUNT,
  FilterDateFormat_MMM_YYYY,
  FunctionDateFormat_YYYYMM,
  GraphDateFormat_MMM_YY,
  OEEmptyStringMappings,
  blue,
  green,
  orange,
  red
} from '../FinTechOpsConstants';
import { getFinOpsAllowedMonths } from '../FinTechOpsDataProcessingFunctions';
import { findCommonElements3, transformPieData } from '../fintech-ops-dashboard/FinTechOpsUtils';
import {
  AgeData,
  BarResDataSeries,
  LineAppDataSeries,
  OpenTicketsTableData,
  PieData,
  ResByDayData,
  TimeSpentByDayData,
  SevTicketsData,
  StatusByMonthData,
  TicketDetailsTabData,
  TicketFiltersResponse,
  TicketGraphsData,
  TicketParsedDetails,
  TicketRawDetails,
  TicketsApplicationData,
  TicketsApplicationDatum,
  TicketsByDayDataCollection,
  TicketsDashboard,
  TicketsDayCount,
  TicketsParsedDataItem,
  TicketsPerDayData,
  TicketsPerHourDataSeries,
  TicketsPerHouseData,
  TicketsRawDataItem,
  MonthResolutionType,
  AreaDataSeries,
  Metadata
} from './TicketsInterfaces';
import {
  TICKET_DETAILS_COLUMN_DEFINITIONS,
  TICKET_DETAILS_VISIBLE_CONTENT,
  TICKET_RESOLVED_DETAILS_COLUMN_DEFINITIONS,
  TICKET_RESOLVED_DETAILS_VISIBLE_CONTENT
} from './TicketsTableFilterConfig';
import { DashboardFilters } from '../fintech-ops-dashboard/FinTechOpsDataInterfaces';
import { getUniqueValues } from 'src/utilities/CommonHelpers';
import { logger } from 'src/logger';
import { FinTechOpsMessages } from 'src/components/fintech-ops/FinTechOpsConstants';
import { aggregateMetricsData, getSortedArrayWithNumber, transformAggMetricsToSeries, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import { TICKETS_RESOLUTION_COLUMN_LIST, TICKETS_RESOLUTION_MEASURE_COLUMN_LIST } from './TicketsConstants';

export function filterDataByCreateDates(rawData: TicketsRawDataItem[]): TicketsRawDataItem[] {
  const currentDate = moment();
  const lastSixMonthsIncludingCurrentMonth = getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps();

  const included: TicketsRawDataItem[] = [];
  const excluded: TicketsRawDataItem[] = [];

  rawData.forEach((item) => {
    if (item.create_date) {
      const createDate = moment(item.create_date);
      if (createDate.isBetween(lastSixMonthsIncludingCurrentMonth, currentDate, 'day', '[]')) {
        included.push(item);
      } else {
        excluded.push(item);
      }
    } else {
      console.debug('Item without create_date ', item);
    }
  });

  // For debugging
  // console.log('Tickets Excluded Records:', JSON.stringify(excluded));
  // const uniqueMonthsSet = new Set(included.map((item) => moment(item.create_date).format('YYYY-MM')));
  // const uniqueMonths = Array.from(uniqueMonthsSet).sort();
  // console.log('Tickets Included uniqueMonths ', JSON.stringify(uniqueMonths));

  return included;
}

// Parses raw data to Formatted data
export const returnUsefulRawDataTickets = (ticketsRawDetails: TicketsRawDataItem[], lastUpdatedAt: Metadata): TicketParsedDetails => {
  let parsedTicketsRawData: TicketsParsedDataItem[] = [];

  const filteredTicketsData = filterDataByCreateDates(ticketsRawDetails);

  filteredTicketsData.forEach((rawTicketInfo: TicketsRawDataItem) => {
    const createdDate = convertUTCtoUserTimeZone(rawTicketInfo.create_date);
    const assignedDate = convertUTCtoUserTimeZone(rawTicketInfo.assigned_date);
    const modifiedDate = convertUTCtoUserTimeZone(rawTicketInfo.modified_date);
    const resolvedDate = rawTicketInfo.resolved_date ? convertUTCtoUserTimeZone(rawTicketInfo.resolved_date) : null;

    parsedTicketsRawData.push({
      team_name: rawTicketInfo.team_name || OEEmptyStringMappings.Unassigned_Team,

      manager_name_hierarchy_concat: rawTicketInfo.manager_name_hierarchy_concat?.split(':') || [],
      manager_name: rawTicketInfo.manager_name_hierarchy_concat?.split(':')[0] || OEEmptyStringMappings.Unassigned_Manager,

      manager_login_hierarchy_concat: rawTicketInfo.manager_login_hierarchy_concat?.split(':') || [],
      manager_login: rawTicketInfo.manager_login_hierarchy_concat?.split(':')[0] || OEEmptyStringMappings.Unassigned_Manager_Login,

      assignee_login: rawTicketInfo.assignee_login || OEEmptyStringMappings.Generic_Mapping,
      assignee_name: rawTicketInfo.assigned_to_individual_name || OEEmptyStringMappings.Generic_Mapping,

      assigned_to_group: rawTicketInfo.assigned_to_group,

      application_name: rawTicketInfo.application_name || OEEmptyStringMappings.Unassigned_Application,

      create_date_UserTimeZone: createdDate,
      create_date_UserTimeZone_formatted: moment(createdDate).format(FilterDateFormat_MMM_YYYY),
      resolved_date_UserTimeZone_formatted: moment(resolvedDate).format(FilterDateFormat_MMM_YYYY),
      created_on_week: moment(createdDate).isoWeek(),
      created_on_month: moment(createdDate).month() + 1, // Add 1 to get one-based month number
      ticket_age_in_minutes: getBackAgeInPeriod(createdDate, 'minutes'),
      resolved_ticket_age_in_seconds: getBackAgeInPeriod(createdDate, 'seconds', resolvedDate),

      last_modified_by: rawTicketInfo.last_modified_by,
      modified_date: modifiedDate,

      assigned_date: assignedDate,

      case_id: rawTicketInfo.case_id,
      short_description: rawTicketInfo.short_description,
      impact: rawTicketInfo.impact,

      resolution_type: rawTicketInfo.resolution_type || OEEmptyStringMappings.Not_Entered,
      resolved_date: resolvedDate,

      sim_issue_alias: rawTicketInfo.sim_issue_alias,
      status: rawTicketInfo.status,
      total_time_spent: rawTicketInfo.total_time_spent
    } as TicketsParsedDataItem);
  });

  parsedTicketsRawData = parsedTicketsRawData
    .slice()
    .sort((a, b) => moment(a.create_date_UserTimeZone).valueOf() - moment(b.create_date_UserTimeZone).valueOf());

  // console.debug('Tickets Raw data', JSON.stringify(filteredTicketsData));
  // console.debug('Tickets Parsed data', JSON.stringify(parsedTicketsRawData));

  return {
    metadata: lastUpdatedAt,
    data: parsedTicketsRawData
  } as TicketParsedDetails;
};

export const getDashboardFiltersFromTickets = (items: TicketsParsedDataItem[]): DashboardFilters => {
  const dashboardTicketFilters: DashboardFilters = {
    team_name: [],
    managers: [],
    assignees: []
  };

  const uniqueValues = (filterArray: string[], value: string) => {
    if (!filterArray.includes(value)) {
      filterArray.push(value);
    }
  };

  items.forEach((item: TicketsParsedDataItem) => {
    uniqueValues(dashboardTicketFilters.team_name, item.team_name);
    uniqueValues(dashboardTicketFilters.managers, item.manager_name);
    uniqueValues(dashboardTicketFilters.assignees, item.assignee_name);
  });

  return dashboardTicketFilters;
};

// Creates Filters for Tickets
export const createMasterFilterDataTickets = (items: TicketsParsedDataItem[]): TicketFiltersResponse => {
  const ticketFilters: TicketFiltersResponse = {
    ticket_managers: [],
    ticket_assignees: [],
    ticket_severity: [],
    ticket_status: [],
    ticket_age: [],
    ticket_application: [],
    ticket_resolution_type: [],
    team_name: []
  };

  const uniqueValues = (filterArray: string[], value: string) => {
    if (!filterArray.includes(value)) {
      filterArray.push(value);
    }
  };

  items.forEach((item: TicketsParsedDataItem) => {
    uniqueValues(ticketFilters.ticket_managers, item.manager_name);
    uniqueValues(ticketFilters.ticket_assignees, item.assignee_name);
    uniqueValues(ticketFilters.ticket_severity, item.impact);
    uniqueValues(ticketFilters.ticket_status, item.status);
    uniqueValues(ticketFilters.ticket_age, item.create_date_UserTimeZone_formatted);
    uniqueValues(ticketFilters.ticket_application, item.application_name);
    uniqueValues(ticketFilters.ticket_resolution_type, item.resolution_type);
    uniqueValues(ticketFilters.team_name, item.team_name);
  });
  return ticketFilters;
};

export const applyDashboardFiltersTickets = (ticketParsedDetails: TicketsParsedDataItem[], filters: DashboardFilters): TicketsParsedDataItem[] => {
  return ticketParsedDetails.filter((item) => {
    return (
      filters.team_name.includes(item.team_name) &&
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.assignees.includes(item.assignee_name)
    );
  });
};

export const applyFiltersTickets = (
  ticketParsedDetails: TicketsParsedDataItem[],
  filters: TicketFiltersResponse
): [TicketsParsedDataItem[], TicketsParsedDataItem[]] => {
  const filteredWithoutMonthData: TicketsParsedDataItem[] = ticketParsedDetails.filter((item) => {
    return (
      findCommonElements3(filters.ticket_managers, item.manager_name_hierarchy_concat) &&
      filters.ticket_assignees.includes(item.assignee_name) &&
      filters.ticket_severity.includes(item.impact) &&
      filters.ticket_status.includes(item.status) &&
      filters.ticket_application.includes(item.application_name) &&
      filters.ticket_resolution_type.includes(item.resolution_type) &&
      filters.team_name.includes(item.team_name)
    );
  });
  const filteredWithMonthData: TicketsParsedDataItem[] = filteredWithoutMonthData.filter((item) => {
    return filters.ticket_age.includes(moment(item.create_date_UserTimeZone).format(FilterDateFormat_MMM_YYYY));
  });
  return [filteredWithoutMonthData, filteredWithMonthData];
};

// Calculations for FinTechOps DashBoard Tickets section
export const getTicketsDashboardData = (items: TicketsParsedDataItem[]): TicketsDashboard => {
  const dashboardData: TicketsDashboard = {
    solvedTicketCount: 0,
    unSolvedTicketCount: 0,
    resolutionRate: 0,
    medianResolutionTimeInMinutes: 0,
    firstResponseTimeAvgInMinutes: 0,
    totalTicketsCount: items.length // Calculate and assign totalTicketsCount
  };

  let firstResponseTimeTotal = 0;

  let resolutionTimeArray: number[] = [];

  items.reduce((acc, item: TicketsParsedDataItem) => {
    const ticketStatus = item.status;

    if (ticketStatus === 'Resolved') {
      acc.solvedTicketCount++;

      if (ticketStatus === 'Resolved' && item.resolved_date && item.create_date_UserTimeZone) {
        resolutionTimeArray.push(moment(item.resolved_date).diff(moment(item.create_date_UserTimeZone), 'minutes'));
      } else {
        console.debug('Ticket API with incomplete information ', item.case_id || '');
      }
    } else {
      acc.unSolvedTicketCount++;
    }

    if (item.assigned_date && item.create_date_UserTimeZone) {
      firstResponseTimeTotal += moment(item.assigned_date).diff(moment(item.create_date_UserTimeZone), 'minutes');
    } else {
      console.debug('Ticket API with incomplete information ', item.case_id || '');
    }

    return acc;
  }, dashboardData);

  dashboardData.resolutionRate =
    dashboardData.totalTicketsCount > 0 ? Number(((dashboardData.solvedTicketCount / dashboardData.totalTicketsCount) * 100).toFixed(2)) : 0;

  dashboardData.firstResponseTimeAvgInMinutes =
    dashboardData.totalTicketsCount > 0 ? Number((firstResponseTimeTotal / dashboardData.totalTicketsCount).toFixed(0)) : 0;

  // Check if solvedTicketCount is greater than zero before calculating the average
  const medianResolutionTimeValue = getMedian(resolutionTimeArray);

  dashboardData.medianResolutionTimeInMinutes = dashboardData.solvedTicketCount > 0 ? medianResolutionTimeValue : 0;
  return dashboardData;
};

//Function to get median value from an array
export const getMedian = (array: number[]) => {
  array.sort((a, b) => a - b);
  const arrayLength = array.length;
  const mid = Math.floor(arrayLength / 2);
  if (arrayLength % 2) {
    return array[mid];
  } else {
    return (array[mid] + array[mid] + 1) / 2.0;
  }
};

// Transforms Tickets Data to Graph Data
export const createTicketGraphData = (appliedFilteredFormattedData: TicketsParsedDataItem[]): TicketGraphsData => {
  if (appliedFilteredFormattedData.length == 0) {
    const emptyGraphData: TicketGraphsData = {
      ticketsByTeam: [], // Graph - Tickets by Team
      incomingTickets: [], // Graph - Incoming Tickets by Month
      severityTickets: [], // Graph - Tickets by Severity
      dayOfWeekTickets: [], // Graph - Average Tickets created by day of Week
      statusByMonthTickets: [], // Graph - Tickets Status by Month
      overAllStatusTickets: [], // Graph - Tickets by Status
      applicationTickets: [], // Graph - Tickets by Application
      resolutionTickets: [], // Graph - Tickets by Resolution Type
      timeSpentTickets: [] // Graph - Time Spent on Tickets by Resolution Type
    };
    return emptyGraphData;
  }

  let ticketsByTeamList: ResByDayData[] = [];
  let incomingTicketsList: StatusByMonthData[] = [];
  let severityTicketsList: SevTicketsData[] = [];
  const dayOfWeekTicketsMap = new Map<string, TicketsByDayDataCollection>();
  let statusByMonthTicketsList: StatusByMonthData[] = [];
  let overAllStatusTicketsList: PieData[] = [];
  let applicationTicketsList: TicketsApplicationData[] = [];
  let resolutionTicketsList: ResByDayData[] = [];
  let timeSpentTicketsList: TimeSpentByDayData[] = [];

  const dayOfWeekAvgTicketsList: TicketsByDayDataCollection[] = [];
  const ticketDetailsList: any[] = [];

  let mostRecentTicketDay = getMomentTime(appliedFilteredFormattedData[0].create_date_UserTimeZone);
  let oldestTicketDay = getMomentTime(appliedFilteredFormattedData[0].create_date_UserTimeZone);

  const transformedTimeSpentList = appliedFilteredFormattedData
    .filter((item) => {
      return item.status === 'Resolved';
    })
    .map((item) => {
      return {
        resolution_type: item.resolution_type,
        time_spent: item.total_time_spent,
        function_formatted_resolved_date: moment(item.resolved_date).format(FunctionDateFormat_YYYYMM),
        resolved_date: moment(item.resolved_date).format(GraphDateFormat_MMM_YY)
      };
    });

  timeSpentTicketsList =
    aggregateMetricsData(TICKETS_RESOLUTION_COLUMN_LIST, transformedTimeSpentList, TICKETS_RESOLUTION_MEASURE_COLUMN_LIST)
      ?.sort((a: any, b: any) => b.time_spent - a.time_spent)
      .filter((item: any) => item.time_spent !== 0) || [];
  appliedFilteredFormattedData.forEach((item: TicketsParsedDataItem, index, array) => {
    const statusOpenResolved = item.status;
    const itemCreateDate = moment(item.create_date_UserTimeZone).format(FunctionDateFormat_YYYYMM);
    const itemDowDay = moment(item.create_date_UserTimeZone).format('ddd');
    const basicStatus = item.status;
    const age = getBackAgeInPeriod(item.create_date_UserTimeZone, 'days');
    const team = item.team_name;

    if (!dayOfWeekTicketsMap.has(team)) {
      dayOfWeekTicketsMap.set(team, {
        title: team,
        data: [
          {
            avg: 1,
            dow: itemDowDay
          }
        ]
      });
    } else {
      const dayOfWeekTickets = dayOfWeekTicketsMap.get(team);
      let hadDay = false;
      dayOfWeekTickets?.data.forEach((day: TicketsPerDayData) => {
        if (day.dow === itemDowDay) {
          day.avg++;
          hadDay = true;
        }
      });
      if (!hadDay) {
        dayOfWeekTickets?.data.push({
          avg: 1,
          dow: itemDowDay
        });
      }
    }

    let month = moment().month() - moment(item.create_date_UserTimeZone).month() + 1;
    if (moment().year() - moment(item.create_date_UserTimeZone).year() > 0) {
      month += 12;
    }

    incomingTicketsList = transformStatusByMonthData(statusOpenResolved, itemCreateDate, incomingTicketsList);
    severityTicketsList = transformSevTicketsData(item.impact, itemCreateDate, severityTicketsList);
    statusByMonthTicketsList = transformStatusByMonthData(basicStatus, itemCreateDate, statusByMonthTicketsList);
    overAllStatusTicketsList = transformPieData(basicStatus, overAllStatusTicketsList);
    applicationTicketsList = transformTicketsApplicationData(item.application_name, itemCreateDate, applicationTicketsList);
    if (statusOpenResolved === 'Resolved') {
      resolutionTicketsList = transformResByDayData(item.resolution_type, itemCreateDate, resolutionTicketsList);
    }

    ticketsByTeamList = transformResByDayData(team, itemCreateDate, ticketsByTeamList);

    //details table logic
    if (basicStatus != 'Resolved') {
      ticketDetailsList.push({
        assigned_to: item.assignee_name,
        impact_level: item.impact,
        short_description: item.short_description,
        sim_issue_alias: item.sim_issue_alias,
        status: basicStatus,
        ticket_age: age,
        ticket_resolution_type: item.resolution_type,
        assignee_login: item.assignee_login,
        case_id: item.case_id
      });
    }

    if (moment(item.create_date_UserTimeZone).isAfter(mostRecentTicketDay)) {
      mostRecentTicketDay = getMomentTime(item.create_date_UserTimeZone);
    } else if (moment(item.create_date_UserTimeZone).isBefore(oldestTicketDay)) {
      oldestTicketDay = getMomentTime(item.create_date_UserTimeZone);
    }
  });

  for (const [key, value] of dayOfWeekTicketsMap) {
    const avgDOW: TicketsPerDayData[] = [];
    value.data.forEach((item: TicketsPerDayData) => {
      //Math.round(item.count / mostRecentTicketDay.diff(oldestTicketDay, 'week')),
      const average = Math.round(item.avg / getDiffInTime(oldestTicketDay, mostRecentTicketDay, 'week'));
      avgDOW.push({
        avg: average,
        dow: item.dow
      });
    });
    dayOfWeekAvgTicketsList.push({
      title: key,
      data: avgDOW
    });
  }

  // console.debug('ticketsByTeamList: ', JSON.stringify(ticketsByTeamList));
  // console.debug('incomingTicketsList: ', JSON.stringify(incomingTicketsList));
  // console.debug('severityTicketsList: ', JSON.stringify(severityTicketsList));
  // console.debug('dayOfWeekAvgTicketsList: ', JSON.stringify(dayOfWeekAvgTicketsList));
  // console.debug('statusByMonthTicketsList: ', JSON.stringify(statusByMonthTicketsList));
  // console.debug('overAllStatusTicketsList: ', JSON.stringify(overAllStatusTicketsList));
  // console.debug('applicationTicketsList: ', JSON.stringify(applicationTicketsList));
  // console.debug('resolutionTicketsList: ', JSON.stringify(resolutionTicketsList));
  // console.debug('timeSpentTicketsList: ', JSON.stringify(timeSpentTicketsList));

  return {
    ticketsByTeam: ticketsByTeamList,
    incomingTickets: incomingTicketsList,
    severityTickets: severityTicketsList,
    dayOfWeekTickets: dayOfWeekAvgTicketsList,
    statusByMonthTickets: statusByMonthTicketsList,
    overAllStatusTickets: overAllStatusTicketsList,
    applicationTickets: applicationTicketsList,
    resolutionTickets: resolutionTicketsList,
    timeSpentTickets: timeSpentTicketsList
  };
};

export const createTicketDetailedTabDataV2 = (
  appliedFilteredFormattedData: TicketsParsedDataItem[],
  finTechOpsTicketsRawData: TicketsParsedDataItem[]
): TicketDetailsTabData => {
  const ticketDetailsTabData: TicketDetailsTabData = {
    // Summary container
    ticketSummaryContainer: {
      totalTickets: 0,
      openTicketsByTeam: {},
      comparisonData: {
        dod: 0,
        wow: 0,
        mom: 0
      }
    },
    aggregatedDataByApplications: {
      rowData: [],
      columnData: [],
      visibleColumns: []
    },
    aggregatedDataByTeams: {
      // Table - Ticket Aggregated Details - by Teams
      rowData: [],
      columnData: [],
      visibleColumns: []
    },
    openTicketDetailsTableData: {
      // Table - Open Ticket Details
      rowData: [],
      columnData: [],
      visibleColumns: []
    },
    resolvedTicketDetailsTableData: {
      // Table - Resolved Ticket Details
      rowData: [],
      columnData: [],
      visibleColumns: []
    }
  };

  if (appliedFilteredFormattedData.length == 0) {
    return ticketDetailsTabData;
  }

  // Ticket Details Tab calculations

  // Ticket Summary Container - Calculations
  ticketDetailsTabData.ticketSummaryContainer.totalTickets = appliedFilteredFormattedData.length;
  ticketDetailsTabData.ticketSummaryContainer.openTicketsByTeam = distinctTeamsWithOpenTickets(appliedFilteredFormattedData);
  const comparisionDataOfTickets = comparisionData(appliedFilteredFormattedData, finTechOpsTicketsRawData);
  ticketDetailsTabData.ticketSummaryContainer.comparisonData.dod = comparisionDataOfTickets.DoD;
  ticketDetailsTabData.ticketSummaryContainer.comparisonData.wow = comparisionDataOfTickets.WoW;
  ticketDetailsTabData.ticketSummaryContainer.comparisonData.mom = comparisionDataOfTickets.MoM;

  // Aggregated Ticket Details by Applications
  const aggregatedApplicationsData = aggregateTicketsDataByApplications(appliedFilteredFormattedData);
  ticketDetailsTabData.aggregatedDataByApplications.visibleColumns = aggregatedApplicationsData.visibleColumns;
  ticketDetailsTabData.aggregatedDataByApplications.rowData = aggregatedApplicationsData.rowData;
  ticketDetailsTabData.aggregatedDataByApplications.columnData = aggregatedApplicationsData.columnData;

  // Aggregated Ticket Details by Teams
  const aggregatedTeamData = aggregateTicketsDataByTeam(appliedFilteredFormattedData);
  ticketDetailsTabData.aggregatedDataByTeams.visibleColumns = aggregatedTeamData.visibleColumns;
  ticketDetailsTabData.aggregatedDataByTeams.rowData = aggregatedTeamData.rowData;
  ticketDetailsTabData.aggregatedDataByTeams.columnData = aggregatedTeamData.columnData;

  // Open Ticket details Table
  ticketDetailsTabData.openTicketDetailsTableData = openTicketsTable(appliedFilteredFormattedData);

  // Resolved Ticket details Table
  ticketDetailsTabData.resolvedTicketDetailsTableData = resolvedTicketsTable(appliedFilteredFormattedData);

  return ticketDetailsTabData;
};

const openTicketsTable = (appliedFilteredFormattedData: TicketsParsedDataItem[]): OpenTicketsTableData => {
  const openTicketsTableData = appliedFilteredFormattedData.filter((ticket) => ticket.status !== 'Resolved');
  return {
    visibleColumns: TICKET_DETAILS_VISIBLE_CONTENT,
    columnData: TICKET_DETAILS_COLUMN_DEFINITIONS,
    rowData: openTicketsTableData.sort((a, b) => b.modified_date.localeCompare(a.modified_date))
  };
};

// Function to fetch the value of Resolved Ticket Details Table
const resolvedTicketsTable = (appliedFilteredFormattedData: TicketsParsedDataItem[]): OpenTicketsTableData => {
  const openTicketsTableData = appliedFilteredFormattedData.filter((ticket) => ticket.status === 'Resolved');
  return {
    visibleColumns: TICKET_RESOLVED_DETAILS_VISIBLE_CONTENT,
    columnData: TICKET_RESOLVED_DETAILS_COLUMN_DEFINITIONS,
    rowData: openTicketsTableData.sort((a, b) => b.modified_date.localeCompare(a.modified_date))
  };
};

// v2
const aggregateTicketsDataByTeam = (appliedFilteredFormattedData: TicketsParsedDataItem[]) => {
  // Initialize an object to aggregate the data by team_name
  const aggregationByTeams: Record<string, Record<string, number>> = {};

  // Initialize an array to store distinct teams
  const distinctTeams: string[] = [];

  // Initialize an array to store distinct months
  const distinctMonths: string[] = [];

  // Iterate through the data and update the aggregation object by team_name
  appliedFilteredFormattedData.forEach((ticket) => {
    const team = ticket.team_name;
    const month = ticket.create_date_UserTimeZone_formatted;

    // Ensure team is in the distinct array
    if (!distinctTeams.includes(team)) {
      distinctTeams.push(team);
    }

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(month)) {
      distinctMonths.push(month);
    }

    // Update the aggregation object by team_name
    if (!aggregationByTeams[team]) {
      aggregationByTeams[team] = {};
    }
    if (!aggregationByTeams[team][month]) {
      aggregationByTeams[team][month] = 0;
    }
    aggregationByTeams[team][month]++;
  });

  // Custom comparison function to sort in descending order
  distinctMonths.sort((a, b) => {
    const dateA = moment(`01-${a}`, 'DD-MMM-YYYY');
    const dateB = moment(`01-${b}`, 'DD-MMM-YYYY');

    // Use moment's isBefore method for comparison
    if (dateA.isBefore(dateB)) {
      return 1; // Sort in descending order
    } else if (dateB.isBefore(dateA)) {
      return -1; // Sort in descending order
    }
    return 0;
  });

  // Generate the final data structure for the table by team_name
  const aggregatedDataByTeams = distinctTeams.map((team) => {
    const teamData: Record<string, any> = {
      team
    };
    distinctMonths.forEach((month) => {
      teamData[month] = aggregationByTeams[team][month] || 0;
    });
    return teamData;
  });

  // Calculate the total aggregation for each month
  const totalAggregation: Record<string, number> = {};
  distinctMonths.forEach((month) => {
    totalAggregation[month] = distinctTeams.reduce((total, team) => {
      return total + (aggregationByTeams[team][month] || 0);
    }, 0);
  });

  // Add "Total" team to the aggregatedDataByTeams array
  aggregatedDataByTeams.push({
    team: 'Total',
    ...totalAggregation // Include the total counts for each month
  });

  // Generate the column definition dynamically
  const columnDefinition: TableProps.ColumnDefinition<any[]>[] = [
    {
      id: 'team',
      header: 'Team',
      sortingField: 'team',
      width: 150,
      cell: (item: any) => <>{item?.team === 'Total' ? <strong>{item?.team}</strong> : item?.team || ''}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      width: 120,
      cell: (item: any) => <>{item?.team === 'Total' ? <strong>{item[month] || 0}</strong> : item[month] || 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  // console.log('aggregatedData ByTeam ', JSON.stringify(aggregatedDataByTeams));
  // console.log('columnDefinition ByTeam ', JSON.stringify(columnDefinition));
  // console.log('visbleColumns ByTeam ', JSON.stringify(visbleColumns));

  return {
    visibleColumns: visbleColumns,
    columnData: columnDefinition,
    rowData: aggregatedDataByTeams
  };
};

// This function will aggregated the tickets data by application for each month
const aggregateTicketsDataByApplications = (appliedFilteredFormattedData: TicketsParsedDataItem[]) => {
  // Initialize an object to aggregate the data by application_name
  const aggregationByApplications: Record<string, Record<string, number>> = {};

  // Initialize an array to store distinct applications
  const distinctApplications: string[] = [];

  // Initialize an array to store distinct months
  const distinctMonths: string[] = [];

  // Iterate through the data and update the aggregation object by application_name
  appliedFilteredFormattedData.forEach((ticket) => {
    const applicationName = ticket.application_name;
    const month = ticket.create_date_UserTimeZone_formatted;

    // Ensure application is in the distinct array
    if (!distinctApplications.includes(applicationName)) {
      distinctApplications.push(applicationName);
    }

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(month)) {
      distinctMonths.push(month);
    }

    // Update the aggregation object by application_name
    if (!aggregationByApplications[applicationName]) {
      aggregationByApplications[applicationName] = {};
    }
    if (!aggregationByApplications[applicationName][month]) {
      aggregationByApplications[applicationName][month] = 0;
    }
    aggregationByApplications[applicationName][month]++;
  });

  // Custom comparison function to sort in descending order
  distinctMonths.sort((a, b) => {
    const dateA = moment(`01-${a}`, 'DD-MMM-YYYY');
    const dateB = moment(`01-${b}`, 'DD-MMM-YYYY');

    // Use moment's isBefore method for comparison
    if (dateA.isBefore(dateB)) {
      return 1; // Sort in descending order
    } else if (dateB.isBefore(dateA)) {
      return -1; // Sort in descending order
    }
    return 0;
  });

  // Generate the final data structure for the table by application_name
  const aggregatedDataByApplications = distinctApplications.map((application) => {
    const applicationData: Record<string, any> = {
      application: application
    };
    distinctMonths.forEach((month) => {
      applicationData[month] = aggregationByApplications[application][month] || 0;
    });
    return applicationData;
  });

  // Calculate the total aggregation for each month
  const totalAggregation: Record<string, number> = {};
  distinctMonths.forEach((month) => {
    totalAggregation[month] = distinctApplications.reduce((total, application) => {
      return total + (aggregationByApplications[application][month] || 0);
    }, 0);
  });

  // Add "Total" application to the aggregatedDataByApplications array
  aggregatedDataByApplications.push({
    application: 'Total',
    ...totalAggregation // Include the total counts for each month
  });

  // Generate the column definition dynamically
  const columnDefinition = [
    {
      id: 'application',
      header: 'Application',
      sortingField: 'application',
      width: 150,
      cell: (item: any) => <>{item?.application === 'Total' ? <strong>{item?.application}</strong> : item?.application || ''}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      width: 120,
      cell: (item: any) => <>{item?.application === 'Total' ? <strong>{item[month] || 0}</strong> : item[month] || 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id);

  return {
    visibleColumns: visbleColumns,
    columnData: columnDefinition,
    rowData: aggregatedDataByApplications
  };
};

// v2
const distinctTeamsWithOpenTickets = (appliedFilteredFormattedData: TicketsParsedDataItem[]): Record<string, number> => {
  const teamNameCounts: Record<string, number> = {};

  appliedFilteredFormattedData.forEach((item) => {
    if (item.status !== 'Resolved') {
      const teamName = item.team_name;
      teamNameCounts[teamName] = (teamNameCounts[teamName] || 0) + 1;
    }
  });

  // Convert the object into an array of key-value pairs
  const sortedCountsArray = Object.entries(teamNameCounts);

  // Sort the array by keys (field names)
  sortedCountsArray.sort((a, b) => a[0].localeCompare(b[0]));

  // Construct a new object from the sorted array
  const sortedTeamNameCounts: Record<string, number> = {};
  sortedCountsArray.forEach(([teamName, count]) => {
    sortedTeamNameCounts[teamName] = count;
  });

  return sortedTeamNameCounts;
};

// v2
export const comparisionData = (appliedFilteredFormattedData: TicketsParsedDataItem[], finTechOpsTicketsRawData: TicketsParsedDataItem[]) => {
  //initializing maxDate to default value to handle empty appliedFilteredFormattedData
  let maxDate = moment('9999-12-31');
  if (appliedFilteredFormattedData.length > 0) {
    //maxdate in the filtered data
    maxDate = moment(
      appliedFilteredFormattedData.reduce((a, b) => (a.create_date_UserTimeZone > b.create_date_UserTimeZone ? a : b)).create_date_UserTimeZone
    );
  }
  // Get the current date
  const currDate = moment();
  let currentDate = moment();

  /* Comparing current date with the max filtered selcted month
  for months other than current month currentDate will be the last day of max month & 
  for wow for current week it may overlap next month since we are using the week number*/
  if (currDate.format('YYYY-MM') == maxDate.format('YYYY-MM')) {
    currentDate = currDate;
  } else {
    currentDate = maxDate.endOf('month');
  }

  const previousDate = currentDate.clone().subtract(1, 'days'); // Subtract 1 day from the current date;

  const currentWeekNumber = currentDate.isoWeek();
  const currentMontnNUmber = currentDate.month() + 1; // Add 1 to get one-based month number

  // Calculate the date range for the past two months
  const twoMonthsAgo = currentDate.clone().subtract(2, 'months');

  // Filter the data to include only records from the past two months
  const relevantData = finTechOpsTicketsRawData.filter((item) => moment(item.create_date_UserTimeZone).isAfter(twoMonthsAgo));

  // Initialize counts for current and previous week and month
  let newTicketsCountDoD = 0;
  let newTicketsCountPreviousDay = 0;

  let countOfTicketsInCurrentWeek = 0;
  let countOfTicketsInPreviousWeek = 0;

  let countOfTicketsInCurrentMonth = 0;
  let countOfTicketsInPreviousMonth = 0;

  // Iterate through the filtered data
  for (const item of relevantData) {
    const ticketCreatedDate = moment(item.create_date_UserTimeZone).format('YYYY-MMM-DD');

    // Check if the ticket is within each date range
    if (ticketCreatedDate === currentDate.format('YYYY-MMM-DD')) {
      newTicketsCountDoD++;
    }
    if (ticketCreatedDate === previousDate.format('YYYY-MMM-DD')) {
      newTicketsCountPreviousDay++;
    }

    if (item.created_on_week === currentWeekNumber) {
      countOfTicketsInCurrentWeek++;
    }

    if (item.created_on_week === currentWeekNumber - 1) {
      countOfTicketsInPreviousWeek++;
    }

    if (item.created_on_month === currentMontnNUmber) {
      countOfTicketsInCurrentMonth++;
    }

    if (item.created_on_month === currentMontnNUmber - 1) {
      countOfTicketsInPreviousMonth++;
    }
  }
  // Calculate the differences
  const differences = {
    DoD: newTicketsCountDoD - newTicketsCountPreviousDay,
    WoW: countOfTicketsInCurrentWeek - countOfTicketsInPreviousWeek,
    MoM: countOfTicketsInCurrentMonth - countOfTicketsInPreviousMonth
  };
  return differences;
};

export const transformResByDayData = (resolution: string, month: string, list: ResByDayData[]): ResByDayData[] => {
  const returnList: ResByDayData[] = list;
  let hasMonthStatus = false;
  for (let i = 0; i < list.length; i++) {
    if (list[i].create_date == month && list[i].resolution_type == resolution) {
      returnList[i].count = returnList[i].count + 1;
      hasMonthStatus = true;
      break;
    }
  }
  if (!hasMonthStatus) {
    returnList.push({
      count: 1,
      create_date: month,
      resolution_type: resolution
    });
  }
  return getSortedArrayWithNumber(returnList, 'count', 'desc');
};

export const createTicketsApplicationData = (items: TicketsParsedDataItem[]): TicketsApplicationData[] => {
  //each entry into StatusByMonthData[] is for a different month
  const returnMonthDate: TicketsApplicationData[] = [];

  items.forEach((item: TicketsParsedDataItem) => {
    const application = item.application_name;
    let hasMonthApplication = false;

    const itemCreateDate = moment(item.create_date_UserTimeZone).format(GraphDateFormat_MMM_YY);

    for (let i = 0; i < returnMonthDate.length; i++) {
      if (returnMonthDate[i].month === itemCreateDate && returnMonthDate[i].application === application) {
        returnMonthDate[i].count = returnMonthDate[i].count + 1;
        hasMonthApplication = true;
        break;
      }
    }
    if (!hasMonthApplication) {
      returnMonthDate.push({
        count: 1,
        month: itemCreateDate,
        application: application
      });
    }
  });
  return returnMonthDate;
};

export const transformSevTicketsData = (severity: string, month: string, list: SevTicketsData[]): SevTicketsData[] => {
  const returnList: SevTicketsData[] = list;
  let hasMonthStatus = false;
  for (let i = 0; i < list.length; i++) {
    if (list[i].create_date == month && list[i].severity == severity) {
      returnList[i].count = returnList[i].count + 1;
      hasMonthStatus = true;
      break;
    }
  }
  if (!hasMonthStatus) {
    returnList.push({
      count: 1,
      create_date: month,
      severity: severity
    });
  }
  return returnList;
};

export const transformTicketsApplicationData = (application: string, month: string, list: TicketsApplicationData[]): TicketsApplicationData[] => {
  const returnList: TicketsApplicationData[] = list;
  let hasMonthStatus = false;
  for (let i = 0; i < list.length; i++) {
    if (list[i].month == month && list[i].application == application) {
      returnList[i].count = returnList[i].count + 1;
      hasMonthStatus = true;
      break;
    }
  }
  if (!hasMonthStatus) {
    returnList.push({
      count: 1,
      month: month,
      application: application
    });
  }
  return getSortedArrayWithNumber(returnList, 'count', 'desc');
};

export const transformStatusByMonthData = (status: string, month: string, list: StatusByMonthData[]): StatusByMonthData[] => {
  const returnList: StatusByMonthData[] = list;
  let hasMonthStatus = false;
  for (let i = 0; i < list.length; i++) {
    if (list[i].create_date == month && list[i].status == status) {
      returnList[i].count = returnList[i].count + 1;
      hasMonthStatus = true;
      break;
    }
  }
  if (!hasMonthStatus) {
    returnList.push({
      count: 1,
      create_date: month,
      status: status
    });
  }
  return returnList;
};

export const returnTeamString = (teamItems: TicketsPerDayData[]): string[] => {
  if (teamItems.length == 0) {
    return ['No Open Tickets'];
  }
  const returnStr: string[] = [];
  teamItems.forEach((item: TicketsPerDayData) => {
    returnStr.push(`${item.dow}: ${item.avg}`);
  });
  return returnStr;
};

export function getColorForSeverity(arg0: string): string | undefined {
  switch (arg0) {
    case '2':
      return red; //red
    case '3':
      return blue; //blue
    case '4':
      return orange; //orange
    case '5':
      return green; //green
  }
}

export function getColorByStatus(key: string): string | undefined {
  switch (key) {
    case 'Assigned':
      return orange; //orange
    case 'Work In Progress':
      return green; //Green
    case 'Pending':
      return red; //red
    case 'Resolved':
      return blue; //Blue
    case 'Researching':
    case 'Open':
      return green; //green
    case 'Total':
      return orange; //orange
  }
}

export function TicketsPerHourDataProcess(responseBody: TicketsPerHouseData[]): {
  series: TicketsPerHourDataSeries[];
  yDomain: number[];
} {
  if (responseBody.length === 0) {
    return {
      series: [],
      yDomain: []
    };
  }
  const apiData: MixedLineBarChartProps.Datum<number>[] = [];
  const series: TicketsPerHourDataSeries[] = [];
  let max = 0;
  responseBody.forEach((element: TicketsPerHouseData) => {
    apiData.push({
      x: moment().hour() - element.hour,
      y: element.count
    });
    if (element.count > max) {
      max = element.count;
    }
  });
  series.push({
    title: 'Tickets count',
    type: 'bar',
    data: apiData
  });
  return { series: series, yDomain: [0, max + 5] };
}

export function TicketAgeDataProcess(response: AgeData[]): MixedLineBarChartProps.BarDataSeries<string | number | Date>[] {
  if (response.length === 0) {
    return [];
  }
  const formatData: MixedLineBarChartProps.BarDataSeries<string>[] = [];
  response.forEach((element: AgeData) => {
    formatData.push({
      title: 'Tickets',
      data: [
        { x: '0-30 days', y: element.v_0_30_days },
        { x: '31-90 days', y: element.v_31_90_days },
        { x: '90+ days', y: element.v_90plus_days }
      ],
      type: 'bar'
    });
  });
  return formatData;
}

export function CreateResolutionDataDictionary(data: ResByDayData[]): Map<string, TicketsApplicationDatum[]> {
  const dictionary = new Map<string, TicketsApplicationDatum[]>();
  data.forEach((element) => {
    const newData: TicketsApplicationDatum = { x: moment(element.create_date).format(GraphDateFormat_MMM_YY), y: element.count };
    if (dictionary.has(element.resolution_type)) {
      let elementHasMonth = false;
      dictionary.get(element.resolution_type)?.forEach((month: TicketsApplicationDatum) => {
        if (month.x === element.create_date) {
          month.y = month.y + element.count;
          elementHasMonth = true;
        }
      });
      if (!elementHasMonth) dictionary.get(element.resolution_type)?.push(newData);
    } else {
      dictionary.set(element.resolution_type, [newData]);
    }
  });
  return dictionary;
}

export function TicketResolutionDataProcess(resData: ResByDayData[]): { dataSeries: BarResDataSeries[]; XDomain: string[] } {
  const dataDictionary = CreateResolutionDataDictionary(resData);
  const ticketsApplicationSeries: BarResDataSeries[] = [];
  const newXDomain: string[] = [];
  const months = getFinOpsAllowedMonths(GraphDateFormat_MMM_YY);
  const monthlyTotal = Array(months.length).fill(0);
  for (const [key, value] of dataDictionary) {
    months.forEach((month) => {
      let hasMonth = false;
      value.forEach((data) => {
        if (data.x == month) {
          hasMonth = true;
        }
      });
      if (!hasMonth) {
        value.push({ x: month, y: 0 });
      }
    });
    let newVal: TicketsApplicationDatum[] = [];
    months.forEach((month) => {
      value.forEach((data) => {
        if (data.x == month) {
          newVal.push(data);
        }
      });
    });
    for (let i = 0; i < FINTECH_MONTH_COUNT; i++) {
      monthlyTotal[i] = monthlyTotal[i] + newVal[i].y;
    }
    newVal = newVal.filter((item) => {
      return item.y != 0;
    });
    if (!key) {
      ticketsApplicationSeries.push({
        title: OEEmptyStringMappings.Not_Entered,
        type: 'bar',
        data: newVal,
        color: orange
      });
    } else {
      ticketsApplicationSeries.push({
        title: key,
        type: 'bar',
        data: newVal
      });
    }
  }
  // TODO
  for (let i = 0; i < 14; i++) {
    // TODO
    if (monthlyTotal[i] > 0) {
      newXDomain.push(months[i]);
    }
  }
  return { dataSeries: ticketsApplicationSeries, XDomain: newXDomain };
}

export function TicketTimeSpentDataProcess(
  timeSpentData: TimeSpentByDayData[],
  ticket_age_min_formatted: string
): { dataSeries: AreaDataSeries[]; XDomain: string[] } {
  try {
    const ticket_age_min: number = moment(ticket_age_min_formatted, FilterDateFormat_MMM_YYYY).valueOf();

    const ticket_age_max: number = moment(
      getUniqueFieldValues(timeSpentData, 'function_formatted_resolved_date').sort().slice(-1).toString(),
      FunctionDateFormat_YYYYMM
    ).valueOf();

    const months = getFinOpsAllowedMonths(GraphDateFormat_MMM_YY).filter((month) => {
      return moment(month, GraphDateFormat_MMM_YY).valueOf() >= ticket_age_min && moment(month, GraphDateFormat_MMM_YY).valueOf() <= ticket_age_max;
    });

    const monthsWithFormat = months.map((month) => {
      return {
        rawX: moment(month, GraphDateFormat_MMM_YY).format(FunctionDateFormat_YYYYMM),
        x: month
      };
    });

    const ticketsApplicationSeries: AreaDataSeries[] = transformAggMetricsToSeries({
      aggData: timeSpentData,
      titleColumn: 'resolution_type',
      xColumnProps: {
        rawX: 'function_formatted_resolved_date',
        x: 'resolved_date'
      },
      yColumn: 'time_spent',
      type: 'area',
      valueFormatter: (e: number) => e.toFixed(2),
      requiredXColumnValues: monthsWithFormat,
      defaultYValue: 0
    });

    return { dataSeries: ticketsApplicationSeries, XDomain: months };
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.dataProcessingError} for function TicketTimeSpentDataProcess`, error);
    return {
      dataSeries: [],
      XDomain: []
    };
  }
}

export function CreateStatusDataDictionary(data: StatusByMonthData[]): Map<string, TicketsApplicationDatum[]> {
  const dictionary = new Map<string, TicketsApplicationDatum[]>();
  data.forEach((element) => {
    const newData: TicketsApplicationDatum = { x: moment(element.create_date).format(GraphDateFormat_MMM_YY), y: element.count };
    if (dictionary.has(element.status)) {
      let elementHasMonth = false;
      dictionary.get(element.status)?.forEach((month: TicketsApplicationDatum) => {
        if (month.x === element.create_date) {
          month.y = month.y + element.count;
          elementHasMonth = true;
        }
      });
      if (!elementHasMonth) dictionary.get(element.status)?.push(newData);
    } else {
      dictionary.set(element.status, [newData]);
    }
  });
  return dictionary;
}

export function TicketStatusDataProcess(
  resData: StatusByMonthData[],
  addTotal: boolean
): {
  dataSeries: BarResDataSeries[];
  xDomain: string[];
  yDomain: number;
} {
  if (resData.length == 0) {
    addTotal = false;
  }
  // { dataSeries: BarResDataSeries[], str: string[]} //
  const dataDictionary = CreateStatusDataDictionary(resData);
  const ticketsApplicationSeries: BarResDataSeries[] = [];
  const newXDomain: string[] = [];
  const months = getFinOpsAllowedMonths(GraphDateFormat_MMM_YY);
  const monthlyTotal: number[] = Array(months.length).fill(0);
  for (const [key, value] of dataDictionary) {
    months.forEach((month) => {
      let hasMonth = false;
      value.forEach((data) => {
        if (data.x == month) {
          hasMonth = true;
        }
      });
      if (!hasMonth) {
        value.push({ x: month, y: 0 });
      }
    });
    let newVal: TicketsApplicationDatum[] = [];
    months.forEach((month) => {
      value.forEach((data) => {
        if (data.x == month) {
          newVal.push(data);
        }
      });
    });
    for (let i = 0; i < months.length; i++) {
      monthlyTotal[i] = monthlyTotal[i] + newVal[i].y;
    }
    newVal = newVal.filter((item) => {
      return item.y != 0;
    });
    ticketsApplicationSeries.push({
      title: key,
      type: 'bar',
      data: newVal,
      color: getColorByStatus(key)
    });
  }
  if (addTotal) {
    const newVal: TicketsApplicationDatum[] = [];
    months.forEach((month) => {
      newVal.push({ x: month, y: monthlyTotal[months.indexOf(month)] });
    });
    ticketsApplicationSeries.push({
      title: 'Total',
      type: 'bar',
      data: newVal,
      color: getColorByStatus('Total')
    });
  }
  for (let i = 0; i < 14; i++) {
    // TODO
    if (monthlyTotal[i] > 0) {
      newXDomain.push(months[i]);
    }
  }
  const maxValOfData = monthlyTotal.reduce((a, b) => Math.max(a, b), -Infinity);
  return {
    dataSeries: ticketsApplicationSeries.sort((a, b) => (a.title > b.title ? 1 : -1)),
    xDomain: newXDomain,
    yDomain: maxValOfData * 1.2
  };
}

export function getTicketsByDayData(apiData: TicketsByDayDataCollection[]): {
  dataSeries: MixedLineBarChartProps.BarDataSeries<string>[];
  yDomain: number[];
} {
  if (apiData.length == 0)
    return {
      dataSeries: [],
      yDomain: [0, 0]
    };
  const dayOfWeekReturn: MixedLineBarChartProps.BarDataSeries<string>[] = [];
  const total: MixedLineBarChartProps.Datum<string>[] = [];
  apiData.forEach((element) => {
    const seriesDataResponse: MixedLineBarChartProps.Datum<string>[] = [];
    element.data.forEach((dayOfWeek) => {
      if (dayOfWeek.avg != 0) {
        const newData = {
          x: dayOfWeek.dow,
          y: dayOfWeek.avg
        };
        seriesDataResponse.push(newData);
        let totalContains = false;
        for (let i = 0; i < total.length; i++) {
          if (total[i].x === dayOfWeek.dow) {
            totalContains = true;
            total[i] = {
              x: dayOfWeek.dow,
              y: total[i].y + dayOfWeek.avg
            };
          }
        }
        if (!totalContains) {
          total.push(newData);
        }
      }
    });
    if (seriesDataResponse.length > 0) {
      dayOfWeekReturn.push({
        title: element.title,
        type: 'bar',
        data: seriesDataResponse
      });
    }
  });
  return {
    dataSeries: dayOfWeekReturn,
    yDomain: [0, Math.max(...total.map((item) => item.y)) * 1.25]
  };
}

export function getTicketByApplicationSeries(ticketsByApplication: TicketsApplicationData[]): {
  dataSeries: LineAppDataSeries[];
  YDomain: number;
  XDomain: string[];
} {
  const dataDictionary = new Map<string, TicketsApplicationDatum[]>();
  const ticketsApplicationSeries: LineAppDataSeries[] = [];
  const newXDomain: string[] = [];
  const months = getFinOpsAllowedMonths(GraphDateFormat_MMM_YY);
  ticketsByApplication.forEach((element) => {
    const newData: TicketsApplicationDatum = { x: moment(element.month).format(GraphDateFormat_MMM_YY), y: element.count };
    let application = element.application;
    if (element.application == 'true') {
      application = 'No Entry';
    }
    if (dataDictionary.has(application)) {
      dataDictionary.get(application)?.push(newData);
    } else {
      dataDictionary.set(application, [newData]);
    }
  });
  const monthlyTotal = Array(months.length).fill(0);
  let max = 0;
  for (const [key, value] of dataDictionary) {
    months.forEach((month) => {
      let hasMonth = false;
      value.forEach((data) => {
        if (data.x == month) {
          hasMonth = true;
        }
      });
      if (!hasMonth) {
        value.push({ x: month, y: 0 });
      }
    });
    let newVal: TicketsApplicationDatum[] = [];
    months.forEach((month) => {
      value.forEach((data) => {
        if (data.x == month) {
          newVal.push(data);
        }
      });
    });
    for (let i = 0; i < FINTECH_MONTH_COUNT; i++) {
      monthlyTotal[i] = monthlyTotal[i] + newVal[i].y;
      newVal[i].y > max ? (max = newVal[i].y) : max;
    }
    newVal.forEach((data) => {
      if (data.y == 0) {
        newVal.filter;
      }
    });
    newVal = newVal.filter((item) => {
      return item.y != 0;
    });
    ticketsApplicationSeries.push({
      title: key,
      type: 'line',
      data: newVal
    });
  }
  for (let i = 0; i < 14; i++) {
    // TODO
    if (monthlyTotal[i] > 0) {
      newXDomain.push(months[i]);
    }
  }
  return {
    dataSeries: ticketsApplicationSeries,
    YDomain: max * 1.2,
    XDomain: newXDomain
  };
}

export function countByMonthBar(ticketsByApplication: SevTicketsData[]): {
  dataSeries: BarResDataSeries[];
  YDomain: number;
  XDomain: string[];
} {
  const dataDictionary = new Map<string, TicketsApplicationDatum[]>();
  const ticketsApplicationSeries: BarResDataSeries[] = [];
  const newXDomain: string[] = [];
  const months = getFinOpsAllowedMonths(GraphDateFormat_MMM_YY);
  ticketsByApplication.forEach((element) => {
    const newData: TicketsApplicationDatum = { x: moment(element.create_date).format(GraphDateFormat_MMM_YY), y: element.count };
    if (dataDictionary.has(element.severity)) {
      let elementHasMonth = false;
      dataDictionary.get(element.severity)?.forEach((month: TicketsApplicationDatum) => {
        if (month.x === element.create_date) {
          month.y = month.y + element.count;
          elementHasMonth = true;
        }
      });
      if (!elementHasMonth) dataDictionary.get(element.severity)?.push(newData);
    } else {
      dataDictionary.set(element.severity, [newData]);
    }
  });
  const monthlyTotal = Array(months.length).fill(0);
  for (const [key, value] of dataDictionary) {
    months.forEach((month) => {
      let hasMonth = false;
      value.forEach((data) => {
        if (data.x == month) {
          hasMonth = true;
        }
      });
      if (!hasMonth) {
        value.push({ x: month, y: 0 });
      }
    });
    let newVal: TicketsApplicationDatum[] = [];
    months.forEach((month) => {
      value.forEach((data) => {
        if (data.x == month) {
          newVal.push(data);
        }
      });
    });
    for (let i = 0; i < FINTECH_MONTH_COUNT; i++) {
      monthlyTotal[i] = monthlyTotal[i] + newVal[i].y;
    }
    newVal = newVal.filter((item) => {
      return item.y != 0;
    });
    ticketsApplicationSeries.push({
      title: 'Sev ' + key,
      type: 'bar',
      data: newVal,
      color: getColorForSeverity(key[0])
    });
  }
  for (let i = 0; i < 14; i++) {
    // TODO
    if (monthlyTotal[i] > 0) {
      newXDomain.push(months[i]);
    }
  }
  const maxValOfData = monthlyTotal.reduce((a, b) => Math.max(a, b), -Infinity);
  return {
    dataSeries: ticketsApplicationSeries.sort((a, b) => (a.title > b.title ? 1 : -1)),
    YDomain: maxValOfData * 1.25,
    XDomain: newXDomain
  };
}

export const transformTicketDayCountData = (dow: string, list: TicketsDayCount[]): TicketsDayCount[] => {
  const returnList: TicketsDayCount[] = list;
  let hasDowStatus = false;
  for (let i = 0; i < list.length; i++) {
    if (list[i].dow == dow) {
      returnList[i].count = returnList[i].count + 1;
      hasDowStatus = true;
      break;
    }
  }
  if (!hasDowStatus) {
    returnList.push({
      count: 1,
      dow: dow
    });
  }
  return returnList;
};

//Function to retrieve the resolved month list
export const getResolvedMonthUniqueList = (ticketsData: TicketsParsedDataItem[]) => {
  const resolvedMonthList: string[] = [];
  ticketsData.forEach((row) => {
    if (row.resolved_date_UserTimeZone_formatted.toLowerCase() !== 'invalid date') {
      getUniqueValues(resolvedMonthList, row.resolved_date_UserTimeZone_formatted);
    }
  });
  return resolvedMonthList;
};
