import React from 'react';
import { TicketStatusPieGraph } from './TicketsStatusPieGraph';
import { BarLineChartWithProps } from '../fintech-ops-charts/BarChartWithProps';
import { AreaChartWithProps } from '../fintech-ops-charts/AreaGraphWithProps';
import { EmptyStateMessage } from '../EmptyStateMessage';
import { Box, Container, Grid } from '@amzn/awsui-components-react';
import { useTicketContext } from './TicketsContext';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { NotInitializedContainer } from '../FinTechOpsCommonElements';
import { FinTechOpsMessages, GRAPH_WIDTHS_BREAK_POINTS } from '../FinTechOpsConstants';
import { ErrorContainer } from '../FinTechOpsCommonElements';
import { i18nStringHour, i18nStringTimeSpent } from '../i18nStrings';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';

export const OverViewContent = () => {
  const finOpsContext = useFinTechContext();
  const ticketContext = useTicketContext();

  return (
    <div>
      {(() => {
        if (ticketContext.statusOfGraphTab == 'loading') {
          return (
            <>
              <LoadingSpinner />
            </>
          );
        } else if (ticketContext.statusOfGraphTab == 'error') {
          return (
            <div>
              <ErrorContainer />
            </div>
          );
        }
        if (ticketContext.statusOfGraphTab == 'pending') {
          return (
            <>
              <LoadingSpinner />
            </>
          );
        } else if (ticketContext.statusOfGraphTab == 'finished') {
          return (
            <Grid
              gridDefinition={[
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS },
                { colspan: GRAPH_WIDTHS_BREAK_POINTS }
              ]}
            >
              <BarLineChartWithProps
                series={ticketContext.ticketsByTeam}
                xDomain={ticketContext.ticketsByApplicationXDomain}
                domainType="string"
                xScaleType="categorical"
                arialLabel="Tickets by Team"
                errorText={FinTechOpsMessages.errorText}
                hideLegend={false}
                stackedBars={true}
                hideFilter={true}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="No. of Tickets"
                i18nStrings={i18nStringHour('Resolution Type')}
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Tickets by Team"
              />
              {/* Age Graph */}
              <BarLineChartWithProps
                series={ticketContext.ageTicketData}
                domainType="string"
                xScaleType="categorical"
                arialLabel="Incoming Tickets by Month"
                errorText={FinTechOpsMessages.errorText}
                yDomain={ticketContext.ageTicketYDomain}
                xDomain={ticketContext.ageTicketXDomain}
                stackedBars={false}
                hideFilter={true}
                hideLegend={false}
                i18nStrings={i18nStringHour('')}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="No. of Tickets"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Incoming Tickets by Month"
              />
              {/* Severity Graph */}
              <BarLineChartWithProps
                series={ticketContext.sevTicketsByDateData}
                domainType="string"
                xDomain={ticketContext.sevTicketsByDateDomain}
                xScaleType="categorical"
                arialLabel="Tickets by Severity"
                errorText={FinTechOpsMessages.errorText}
                stackedBars={true}
                hideFilter={true}
                i18nStrings={i18nStringHour('Severity')}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="No. of Tickets"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Tickets by Severity"
              />

              {/* Tickets Status by Month */}
              {/* <BarLineChartWithProps
                series={ticketContext.ticketsByDateStatus}
                domainType="string"
                xDomain={ticketContext.ticketsByDatesStatusDomain}
                yDomain={ticketContext.ticketsByStatusYDomain}
                xScaleType="categorical"
                arialLabel="Tickets by resolution by date"
                errorText={FinTechOpsMessages.errorText}
                i18nStrings={i18nStringHour('Status')}
                hideFilter={true}
                stackedBars={true}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="No. of Tickets"
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Tickets Status by Month"
              /> */}

              {/* Tickets by Application Graph  */}
              <BarLineChartWithProps
                headerName="Tickets by Application"
                series={ticketContext.ticketsByApplication}
                arialLabel="Tickets by Application"
                // height={300}
                xDomain={ticketContext.ticketsByApplicationXDomain}
                yDomain={ticketContext.ticketsByApplicationYDomain}
                xScaleType="categorical"
                xTitle={'Months'}
                yTitle={'No. of Tickets'}
                hideFilter={true}
                hideLegend={false}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                i18nStrings={i18nStringHour('Application')}
                domainType={'string'}
                errorText={FinTechOpsMessages.errorText}
              />
              {/* Tickets by Resolution Graph  */}
              <BarLineChartWithProps
                series={ticketContext.ticketsByResolution}
                xDomain={ticketContext.ticketsByResolutionDomain}
                domainType="string"
                xScaleType="categorical"
                arialLabel="Tickets by Resolution Type"
                errorText={FinTechOpsMessages.errorText}
                hideLegend={false}
                stackedBars={true}
                hideFilter={true}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="No. of Tickets"
                i18nStrings={i18nStringHour('Resolution Type')}
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Tickets by Resolution Type"
              />
              {/* Tickets by Time Spent  */}
              <AreaChartWithProps
                series={ticketContext.ticketsByTimeSpent}
                xDomain={ticketContext.ticketsByTimeSpentDomain}
                domainType="string"
                xScaleType="categorical"
                arialLabel="Time Spent on Tickets by Resolution Type"
                errorText={FinTechOpsMessages.errorText}
                hideLegend={false}
                stackedBars={true}
                hideFilter={true}
                emptyMessage={
                  <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
                }
                xTitle="Months"
                yTitle="Time Spent (hours)"
                i18nStrings={i18nStringTimeSpent('Resolution Type')}
                noMatchMessage={FinTechOpsMessages.noMatchMessage}
                headerName="Tickets by Time Spent"
              />

              {/* Status Pie Graph */}
              <TicketStatusPieGraph data={ticketContext.statusPieData} />
            </Grid>
          );
        }
      })()}
    </div>
  );
};
