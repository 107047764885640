import { MultiselectProps, SelectProps } from '@amzn/awsui-components-react';
import { LabelInput, LabelType } from '../context/AppContextModels';

export const labelInputToSelectDropdown = (entity: LabelInput | undefined): SelectProps.Option => {
  return {
    label: entity?.label || '',
    value: entity?.id || ''
  };
};

export const selectDropdownToLabel = (entity: SelectProps.Option, labelType: LabelType): LabelInput => {
  return {
    label: entity?.label || '',
    id: entity?.value || '',
    labelType: labelType
  };
};

export const labelInputToMultiSelectDropdown = (labelInputs: LabelInput[]): MultiselectProps.Options => {
  return labelInputs?.map((labelInput) => {
    return {
      label: labelInput?.label || '',
      value: labelInput?.id || ''
    };
  });
};

export const multiSelectDropdownToLabel = (labelInputs: MultiselectProps.MultiselectChangeDetail, labelType: LabelType): LabelInput[] => {
  const changeDetail = labelInputs;
  return changeDetail.selectedOptions?.map((labelInput) => {
    return {
      label: labelInput?.label || '',
      id: labelInput.value || '',
      labelType: labelType
    };
  });
};

export const labelInputToLabelDropdownOptions = (labelInputs: LabelInput[] | null | undefined): SelectProps.Options | undefined => {
  if (!labelInputs) return undefined;

  return labelInputs?.map((labelInput) => {
    return {
      label: labelInput?.label || '',
      value: labelInput?.id || ''
    };
  });
};
