import { Link, PropertyFilterProps, TableProps } from '@amzn/awsui-components-react';
import { PHONE_TOOL_URL } from 'src/constants/AppConstants';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { UserMetricsParsedData } from './UserMetricsInterfaces';
import React from 'react';
import { getPhoneToolUrl } from '../FinTechOpsCommonElements';

export const USER_METRICS_FOOTPRINT_VISIBLE_CONTENT = ['team_name', 'app_type', 'distinctUsers', 'userCount', 'avgPageViewPerDay', 'active_since'];

export const USER_METRICS_FOOTPRINT_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Team',
    key: 'team_name',
    groupValuesLabel: 'Team',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Application',
    key: 'app_type',
    groupValuesLabel: 'Application',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Distinct Users',
    key: 'distinctUsers',
    groupValuesLabel: 'Distinct Users',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'User Activity',
    key: 'userCount',
    groupValuesLabel: 'User Activity',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Active Since',
    key: 'active_since',
    groupValuesLabel: 'Active Since',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Avg PageViews Per Day',
    key: 'avgPageViewPerDay',
    groupValuesLabel: 'Avg PageViews Per Day',
    operators: [':', '!:', '=', '!=']
  }
];

export const USER_METRICS_FOOTPRINT_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: 'app_type',
    sortingField: 'app_type',
    header: 'Application',
    cell: (item: any) => item.app_type,
    width: 200
  },
  {
    id: 'team_name',
    sortingField: 'team_name',
    header: 'Team',
    cell: (item: any) => item.team_name,
    width: 130
  },

  {
    id: 'distinctUsers',
    sortingComparator: (a: any, b: any) => {
      return a.distinctUsers - b.distinctUsers;
    },
    header: 'Distinct User Count',
    cell: (item: any) => numberFormatter(item.distinctUsers),
    width: 200
  },
  {
    id: 'userCount',
    sortingComparator: (a: any, b: any) => {
      return a.userCount - b.userCount;
    },
    header: 'User Activity',
    cell: (item: any) => numberFormatter(item.userCount),
    width: 150
  },
  {
    id: 'active_since',
    sortingField: 'active_since',
    header: 'Active Since',
    cell: (item: any) => item.active_since,
    width: 220
  },
  {
    id: 'avgPageViewPerDay',
    sortingComparator: (a: any, b: any) => {
      return a.avgPageViewsPerDay - b.avgPageViewsPerDay;
    },
    header: 'Avg PageViews Per Day',
    cell: (item: any) => numberFormatter(item.avgPageViewsPerDay),
    width: 150
  }
];

export const USER_METRICS_SUB_APPLICATION_FOOTPRINT_VISIBLE_CONTENT = ['app_name', ...USER_METRICS_FOOTPRINT_VISIBLE_CONTENT];

export const USER_METRICS_SUB_APPLICATION_FOOTPRINT_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Sub Application',
    key: 'app_name',
    groupValuesLabel: 'Sub Application',
    operators: [':', '!:', '=', '!=']
  },
  ...USER_METRICS_FOOTPRINT_FILTERING_PROPERTIES
];

export const USER_METRICS_SUB_APPLICATION_FOOTPRINT_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: 'app_name',
    sortingField: 'app_name',
    header: 'Sub Application',
    cell: (item: any) => item.app_name,
    width: 200
  },
  ...USER_METRICS_FOOTPRINT_COLUMN_DEFINITIONS
];

export const USER_METRICS_APPLICATION_USER_VIEW_VISIBLE_CONTENT = [
  'team_name',
  'application_type',
  'user_login',
  'active_since',
  'measure_value',
  'last_active'
];

export const USER_METRICS_APPLICATION_USER_VIEW__FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Team',
    key: 'team_name',
    groupValuesLabel: 'Team',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Application',
    key: 'application_type',
    groupValuesLabel: 'Application',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'User Alias',
    key: 'user_login',
    groupValuesLabel: 'User Alias',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Active since',
    key: 'active_since',
    groupValuesLabel: 'Active since',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Last Accessed',
    key: 'last_active',
    groupValuesLabel: 'Last Accessed',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'View Count',
    key: 'measure_value',
    groupValuesLabel: 'View Count',
    operators: [':', '!:', '=', '!=']
  }
];

export const USER_METRICS_APPLICATION_USER_VIEW__COLUMN_DEFINITIONS: TableProps.ColumnDefinition<UserMetricsParsedData>[] = [
  {
    id: 'application_type',
    sortingField: 'application_type',
    header: 'Application',
    cell: (item: UserMetricsParsedData) => item.application_type,
    minWidth: 150
  },
  {
    id: 'team_name',
    sortingField: 'team_name',
    header: 'Team',
    cell: (item: UserMetricsParsedData) => item.team_name,
    width: 130
  },
  {
    id: 'user_login',
    sortingField: 'user_login',
    header: 'User Alias',
    maxWidth: 100,
    cell: (item: UserMetricsParsedData) => getPhoneToolUrl(item.user_login, item.user_login)
  },
  {
    id: 'active_since',
    sortingField: 'active_since',
    header: 'Active Since',
    cell: (item: UserMetricsParsedData) => item.active_since,
    width: 170
  },
  {
    id: 'last_active',
    sortingField: 'last_active',
    header: 'Last Accessed',
    cell: (item: UserMetricsParsedData) => (item.last_active ? item.last_active : ''),
    width: 170
  },
  {
    id: 'measure_value',
    sortingComparator: (a: any, b: any) => {
      return a.measure_value - b.measure_value;
    },
    header: 'View count',
    cell: (item: UserMetricsParsedData) => numberFormatter(item.measure_value),
    width: 150
  }
];

export const USER_METRICS_SUB_APPLICATION_USER_VIEW_VISIBLE_CONTENT = ['application', ...USER_METRICS_APPLICATION_USER_VIEW_VISIBLE_CONTENT];

export const USER_METRICS_SUB_APPLICATION_USER_VIEW__FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Sub Application',
    key: 'application',
    groupValuesLabel: 'Sub Application',
    operators: [':', '!:', '=', '!=']
  },
  ...USER_METRICS_APPLICATION_USER_VIEW__FILTERING_PROPERTIES
];

export const USER_METRICS_SUB_APPLICATION_USER_VIEW__COLUMN_DEFINITIONS: TableProps.ColumnDefinition<UserMetricsParsedData>[] = [
  {
    id: 'application',
    sortingField: 'application',
    header: 'Sub Application',
    cell: (item: UserMetricsParsedData) => item.application,
    width: 200
  },
  ...USER_METRICS_APPLICATION_USER_VIEW__COLUMN_DEFINITIONS
];
