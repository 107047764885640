import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  Box,
  Button,
  Container,
  ContentLayout,
  ExpandableSection,
  Flashbar,
  FlashbarProps,
  Grid,
  Header,
  Pagination,
  PropertyFilter,
  PropertyFilterProps,
  Select,
  SelectProps,
  SpaceBetween,
  Table,
  TableProps,
  TextFilterProps
} from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appLayoutAriaLabels, paginationAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import { dateTimeComparatorForTable } from 'src/utilities/DateTimeUtilities';
import * as AnnouncementsTableConfig from './AnnouncementsTableConfig';
import { AppRedirectInfo, AnnouncementFlatTableEntity, Application } from 'src/components/context/AppContextModels';
import { useAppContext, fetchAnnouncements } from 'src/components/context/AppContextProvider';
import { DFPHomeBreadcrumbs } from 'src/components/dfp-home-page/DFPHomePage';
import { DFPHomePageSideNavigation } from 'src/components/dfp-home-page/DFPHomePageSideNavigation';
import { CategoryCheckbox } from 'src/components/generic-components/CategoryCustomCheckboxFilter';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import {
  BLANK_SEARCH_AND,
  TableEmptyState,
  TableNoMatchState,
  Preferences,
  getMatchesCountText
} from 'src/components/generic-components/TableCommons';
import { AnnouncementCategories } from '../admin-components/admin-announcements/AdminManageAnnouncements';
import { usePersistedState } from 'src/utilities/useLocalStorage';
import { eUserPreferenceKeys } from 'src/constants/AppConstants';
import { useColumnWidths } from 'src/utilities/useColumnWidths';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from '../das-finsuite-constants';

export const getAnnouncementsBreadcrumbItems = () => {
  return [
    ...DFPHomeBreadcrumbs,
    {
      text: 'Announcements',
      href: '/announcements'
    }
  ];
};

const Announcements: React.FC = () => {
  const appLayout = useRef<any>();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const { listOfApplications, setAnnouncements, announcementsFlatEntity, setAnnouncementsFlatEntity, isAdminControlsEnabled } = useAppContext();
  const [appsInAnnouncements, setAppsInAnnouncements] = useState<Application[]>([]);

  const [announcementsLoadingStatus, setAnnouncementsLoadingStatus] = useState(false);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [appSpecificNavigationState, setAppSpecificNavigationState] = useState<AppRedirectInfo>();

  // Table properties
  const textFilterRef = useRef<TextFilterProps.Ref>(null);
  const [colDefs, saveWidths] = useColumnWidths(
    eUserPreferenceKeys.ANNOUNCEMENTS_TABLE_WIDTHS,
    AnnouncementsTableConfig.ANNOUNCEMENT_COLUMN_DEFINITIONS
  );

  const [preferences, setPreferences] = usePersistedState(
    eUserPreferenceKeys.ANNOUNCEMENTS_TABLE_PREFERENCES,
    AnnouncementsTableConfig.ANNOUNCEMENTS_DEFAULT_PREFERENCES
  );
  const [filteringProperties, setFilteringProperties] = useState<any[]>(AnnouncementsTableConfig.ANNOUNCEMENTS_FILTERING_PROPERTIES);
  const [query, setQuery] = useState<PropertyFilterProps.Query>(BLANK_SEARCH_AND);

  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  // Filters
  const [selectedApplications, setSelectedApplications] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  // Based on the redirected from, to this page, we will set Breadcrumb
  useEffect(() => {
    setAppSpecificNavigationState({
      from: state?.from,
      redirectFromHomePage: state?.redirectFromHomePage,
      fromAppName: state?.fromAppName,
      fromAppId: state?.fromAppId
    });
    state?.fromAppName && setSelectedApplications([state?.fromAppName]);
  }, [pathname, state]);

  // generates "appsInAnnouncements" from announcements
  useEffect(() => {
    // Step 1: Fetching AppIds
    const appIds = announcementsFlatEntity.filter((item) => item.isActive).map((announcement) => announcement.applicationId);

    // Step 2: Fetching AppNames
    const applicationsInAnnouncements: Application[] = listOfApplications
      .filter((application: Application) => application.itemMetadata.isActive)
      .filter((application: Application) => appIds.includes(application.id));
    // .map((application) => {
    //   return { applicationId: application.id, applicationName: application.applicationName } as AppNameAndId;
    // });

    // Step 3: Fetching Uniques Apps
    const uniqueApps: Application[] = Array.from(new Set(applicationsInAnnouncements.map((item) => JSON.stringify(item)))).map((item) =>
      JSON.parse(item)
    );

    // Step 4: sorting based on App Name
    const sortedItems: Application[] = uniqueApps.sort((a, b) =>
      a.applicationName && b.applicationName ? a.applicationName.localeCompare(b.applicationName) : -1
    );
    setAppsInAnnouncements(sortedItems);
  }, [announcementsFlatEntity]);

  useEffect(() => {
    refreshAnnouncements();
  }, []);

  const refreshAnnouncements = async () => {
    setAnnouncementsLoadingStatus(true);
    const parsedAnnouncements = await fetchAnnouncements(listOfApplications);
    setAnnouncements(parsedAnnouncements.announcementList);
    setAnnouncementsFlatEntity(parsedAnnouncements.announcementsFlatTableEntity);
    setAnnouncementsLoadingStatus(false);
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const navigateToNewAnnouncement = () => {
    navigate('/admin/announcements/new');
  };

  const handleApplicationChange = (applicationId: string, checked: boolean) => {
    setSelectedApplications((prevSelected) => {
      if (checked) {
        return [...prevSelected, applicationId];
      } else {
        return prevSelected.filter((id) => id !== applicationId);
      }
    });
  };

  const handleCategoryChange = (value: string, checked: boolean) => {
    setSelectedCategories((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        return prevSelected.filter((category) => category !== value);
      }
    });
    logger.info(`DaS FinSuite -> User Action: Announcements -> Application Filter Category Change`, {
      event_type: UserActionsEventTypes.ANNOUNCEMENTS_FILTER_BY_CATEGORY,
      value: value
    });
  };

  const clearAllFilters = () => {
    setSelectedApplications([]);
    setSelectedCategories([]);
    actions.setFiltering('');
  };

  useEffect(() => {
    // Update the query based on the selected categories and applications
    setQuery((prevQuery: PropertyFilterProps.Query) => {
      // const newTokens: any[] = [];
      const updatedTokens: any = prevQuery.tokens.filter(
        (token) => token.propertyKey !== 'announcementCategory' && token.propertyKey !== 'applicationName'
      );

      // Add tokens for selected categories
      if (selectedCategories.length > 0) {
        const categoryTokens = selectedCategories.map((category) => ({
          value: category,
          propertyKey: 'announcementCategory',
          operator: ':'
        }));
        updatedTokens.push(...categoryTokens);
      }

      // Add tokens for selected applications
      if (selectedApplications.length > 0) {
        const applicationTokens = selectedApplications.map((application) => ({
          value: application,
          propertyKey: 'applicationName',
          operator: ':'
        }));
        updatedTokens.push(...applicationTokens);
      }

      return { ...prevQuery, tokens: updatedTokens };
    });
  }, [selectedCategories, selectedApplications]);

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps, filterProps } = useCollection(
    announcementsFlatEntity.filter((item) => item.isActive),
    {
      filtering: {
        empty: <TableEmptyState resourceName="Announcements" />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              clearAllFilters();
            }}
          />
        )
      },
      propertyFiltering: {
        filteringProperties,
        empty: <TableEmptyState resourceName="Announcements" />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'or' });
            }}
          />
        )
      },
      pagination: {
        pageSize: preferences.pageSize
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: 'announcementDate',
            sortingComparator: (a: AnnouncementFlatTableEntity, b: AnnouncementFlatTableEntity) => {
              return dateTimeComparatorForTable(a.announcementDate, b.announcementDate);
            }
          },
          isDescending: true
        }
      }
    }
  );

  return (
    <>
      <AppLayout
        ref={appLayout}
        headerSelector="#h"
        contentType="default"
        ariaLabels={appLayoutAriaLabels}
        toolsHide={true}
        breadcrumbs={
          <>
            {appSpecificNavigationState?.redirectFromHomePage && <DFPBreadcrumbs items={getAnnouncementsBreadcrumbItems()} />}
            {(!appSpecificNavigationState?.redirectFromHomePage as boolean) && (
              <Button
                className="custom-breadcrumb-button"
                variant="link"
                iconAlign="left"
                iconName="angle-left"
                onClick={() => navigate(appSpecificNavigationState?.from || '')}
              >
                {`Back to ${appSpecificNavigationState?.fromAppName || ''}`}
              </Button>
            )}
          </>
        }
        navigation={<DFPHomePageSideNavigation />}
        stickyNotifications
        notifications={<Flashbar items={flashbarItems} />}
        content={
          <div className="app-layout-body">
            <ContentLayout
              disableOverlap
              header={
                <>
                  <Header
                    actions={
                      <SpaceBetween size="m" direction="horizontal">
                        {isAdminControlsEnabled && (
                          <Button variant="primary" onClick={navigateToNewAnnouncement} iconName="add-plus">
                            {'New Announcement'}
                          </Button>
                        )}
                        <Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />
                        <Preferences
                          preferences={preferences}
                          setPreferences={setPreferences}
                          visibleContentOptions={AnnouncementsTableConfig.DEFAULT_ANNOUNCEMENT_VISIBLE_CONTENT_OPTIONS}
                          disablePageSizeOptions={false}
                          disableResizableColumnsOptions={true}
                        />
                      </SpaceBetween>
                    }
                    variant="h1"
                  >
                    {'Announcements'}
                  </Header>
                  <PropertyFilter
                    i18nStrings={propertyFilterI18nStrings('Announcements')}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    expandToViewport={true}
                    {...propertyFilterProps}
                    query={query}
                    onChange={(event) => {
                      setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH_AND : event.detail);
                    }}
                  />
                </>
              }
            >
              <Box margin={{ top: 'l', bottom: 'l' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                  <Container>
                    <SpaceBetween size="m" direction="vertical">
                      <Button formAction="none" variant="link" onClick={clearAllFilters}>
                        Clear all filters
                      </Button>

                      <ExpandableSection headerText={'Filter by application'} defaultExpanded>
                        <Box padding={{ left: 'm', right: 'm' }} margin={{ right: 'm' }}>
                          <Select
                            placeholder="Select Application"
                            expandToViewport
                            options={appsInAnnouncements?.map((application) => {
                              return {
                                value: application.applicationName,
                                label: application.applicationName
                              } as { label: string; value: string };
                            })}
                            selectedOption={
                              selectedApplications?.length > 0
                                ? ({
                                    label: selectedApplications[0],
                                    value: selectedApplications[0]
                                  } as SelectProps.Option)
                                : null
                            }
                            filteringType="auto"
                            onChange={({ detail }) => {
                              setSelectedApplications([detail.selectedOption.label as string]);
                              logger.info(`DaS FinSuite -> User Action: Announcements -> Application Filter Change`, {
                                event_type: UserActionsEventTypes.ANNOUNCEMENTS_FILTER_BY_APP,
                                value: detail.selectedOption.label
                              });
                            }}
                          ></Select>
                        </Box>
                      </ExpandableSection>

                      <ExpandableSection headerText={'Filter by categories'} defaultExpanded>
                        <div className="margin-left-20px">
                          {AnnouncementCategories.map((announcementCategory) => (
                            <CategoryCheckbox
                              key={announcementCategory.label}
                              category={announcementCategory}
                              onChange={handleCategoryChange}
                              checked={selectedCategories.includes(announcementCategory.label || '')}
                            />
                          ))}
                        </div>
                      </ExpandableSection>
                    </SpaceBetween>
                  </Container>
                  <div>
                    <Table
                      {...collectionProps}
                      variant="stacked"
                      loading={announcementsLoadingStatus}
                      loadingText={'Loading announcements...'}
                      items={items}
                      columnDefinitions={colDefs as TableProps.ColumnDefinition<AnnouncementFlatTableEntity>[]}
                      onColumnWidthsChange={saveWidths as any}
                      visibleColumns={preferences.visibleContent}
                      resizableColumns={true}
                      wrapLines={preferences.wrapLines}
                      stripedRows={preferences.stripedRows}
                      contentDensity={preferences.contentDensity}
                    />
                  </div>
                </Grid>
              </Box>
            </ContentLayout>
          </div>
        }
      />
    </>
  );
};

export default Announcements;
