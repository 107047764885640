import { Container, Header, PieChart } from '@amzn/awsui-components-react';
import React from 'react';
import { EmptyStateMessage } from '../EmptyStateMessage';
import { PieChartDataSeries } from './TicketsInterfaces';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import { i18nStringPieChart } from '../i18nStrings';

type dataPieProps = {
  data: PieChartDataSeries[];
};
export const TicketStatusPieGraph = ({ data }: dataPieProps) => {
  return (
    <Container fitHeight={true} header={<Header variant="h2">Tickets by Status</Header>}>
      <PieChart
        data={data}
        segmentDescription={(datum: { value: number }, sum: number) => ` ${((datum.value / sum) * 100).toFixed(0)}%`}
        i18nStrings={i18nStringPieChart()}
        ariaDescription="Pie chart showing how many resources are currently in which state."
        ariaLabel="Pie chart"
        size="medium"
        errorText="Error loading data."
        loadingText="Loading chart"
        hideFilter
        recoveryText="Retry"
        empty={<EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
        noMatch={FinTechOpsMessages.noMatchMessage}
      />
    </Container>
  );
};
