import { Box } from '@amzn/awsui-components-react';
import React, { useRef, useState } from 'react';

interface EmptyDataProps {
  message?: string;
}
const EmptyData = ({ message }: EmptyDataProps) => {
  return (
    <>
      <Box textAlign="center" color="inherit">
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          {message ? message : 'No data to display.'}
        </Box>
      </Box>
    </>
  );
};

export default EmptyData;
