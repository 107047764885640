import React, { useState } from 'react';
import { AppLayout, Flashbar, FlashbarProps, Link, MixedLineBarChartProps, Tabs } from '@amzn/awsui-components-react';
import { DFPHomePageSideNavigation } from '../../dfp-home-page/DFPHomePageSideNavigation';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { FinTechOpsCategories, FinTechOpsHref } from '../FinTechOpsConstants';
import { CostMetricsContext } from './CostMetricsContext';
import { StatusType } from './CostMetricsInterface';
import { CostMetricsOverviewPage } from './CostMetricsOverview';
import { CostMetricsSelectionFilters } from './CostMetricsSelectionFilters';
import { CostMetricsDetailsPage } from './CostMetricsDetailsTab';
import { CostMetricsSummaryContainer, CostMetricsTableData } from './CostMetricsInterface';

export function CostMetricsMainPage() {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [costMetricsGraphStatus, setCostMetricsGraphStatus] = useState<StatusType>('loading');

  const [costMetricsDetailsTabStatus, setCostMetricsDetailsTabStatus] = useState<StatusType>('loading');
  const [costMetricsDetailsTabData, setCostMetricsDetailsTabData] = useState<any>();

  const [costMetricsTrendsByMonthGraphData, setCostMetricsTrendsByMonthGraphData] = useState<
    MixedLineBarChartProps.BarDataSeries<string | number | Date>[]
  >([]);
  const [costMetricsTrendsByMonthGraphDataXDomain, setCostMetricsTrendsByMonthGraphDataXDomain] = useState<string[]>([]);
  const [costMetricsTrendsByMonthGraphDataYDomain, setCostMetricsTrendsByMonthGraphDataYDomain] = useState<number[]>([]);

  const [costByProductGraphData, setCostByProductGraphData] = useState<MixedLineBarChartProps.LineDataSeries<string | number | Date>[]>([]);
  const [costByProductGraphDataXDomain, setCostByProductGraphDataXDomain] = useState<string[]>([]);
  const [costByProductGraphDataYDomain, setCostByProductGraphDataYDomain] = useState<number[]>([]);

  const [costByResourceTypeGraphData, setcostByResourceTypeGraphData] = useState<MixedLineBarChartProps.LineDataSeries<string | number | Date>[]>([]);
  const [costByResourceTypeGraphDataXDomain, setcostByResourceTypeGraphDataXDomain] = useState<string[]>([]);
  const [costByResourceTypeGraphDataYDomain, setcostByResourceTypeGraphDataYDomain] = useState<number[]>([]);

  const [costByApplicationGraphData, setCostByApplicationGraphData] = useState<MixedLineBarChartProps.LineDataSeries<string | number | Date>[]>([]);
  const [costByApplicationGraphDataXDomain, setCostByApplicationGraphDataXDomain] = useState<string[]>([]);
  const [costByApplicationGraphDataYDomain, setCostByApplicationGraphDataYDomain] = useState<number[]>([]);

  const [costMetricsDetailsTable, setCostMetricsDetailsTable] = useState<CostMetricsTableData>({} as CostMetricsTableData);
  const [aggregatedCostByProductDetailsTable, setAggregatedCostByProductDetailsTable] = useState<CostMetricsTableData>({} as CostMetricsTableData);
  const [aggregatedCostByAccountDetailsTable, setAggregatedCostByAccountDetailsTable] = useState<CostMetricsTableData>({} as CostMetricsTableData);
  const [aggregatedCostByApplicationsDetailsTable, setAggregatedCostByApplicationsDetailsTable] = useState<CostMetricsTableData>(
    {} as CostMetricsTableData
  );
  const [costMetricsSummaryContainer, setCostMetricsSummaryContainer] = useState<CostMetricsSummaryContainer>({} as CostMetricsSummaryContainer);

  //Function to display flash bar nottifications
  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            {' '}
            submit a ticket
          </Link>
        );
      }
    }
    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} 'or ' {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={flashbarItems} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: FinTechOpsHref.finTechOps },
            {
              text: FinTechOpsCategories.costMetrics,
              href: ''
            }
          ]}
        />
      }
      maxContentWidth={Number.MAX_VALUE}
      content={
        <>
          <CostMetricsContext.Provider
            value={{
              displayNotification,
              costMetricsGraphStatus,
              setCostMetricsGraphStatus,
              costMetricsDetailsTabStatus,
              setCostMetricsDetailsTabStatus,
              costMetricsDetailsTabData,
              setCostMetricsDetailsTabData,
              costMetricsTrendsByMonthGraphData,
              setCostMetricsTrendsByMonthGraphData,
              costMetricsTrendsByMonthGraphDataXDomain,
              setCostMetricsTrendsByMonthGraphDataXDomain,
              costMetricsTrendsByMonthGraphDataYDomain,
              setCostMetricsTrendsByMonthGraphDataYDomain,
              costByProductGraphData,
              setCostByProductGraphData,
              costByProductGraphDataXDomain,
              setCostByProductGraphDataXDomain,
              costByProductGraphDataYDomain,
              setCostByProductGraphDataYDomain,
              costByResourceTypeGraphData,
              setcostByResourceTypeGraphData,
              costByResourceTypeGraphDataXDomain,
              setcostByResourceTypeGraphDataXDomain,
              costByResourceTypeGraphDataYDomain,
              setcostByResourceTypeGraphDataYDomain,
              costByApplicationGraphData,
              setCostByApplicationGraphData,
              costByApplicationGraphDataXDomain,
              setCostByApplicationGraphDataXDomain,
              costByApplicationGraphDataYDomain,
              setCostByApplicationGraphDataYDomain,
              costMetricsDetailsTable,
              setCostMetricsDetailsTable,
              aggregatedCostByProductDetailsTable,
              setAggregatedCostByProductDetailsTable,
              aggregatedCostByAccountDetailsTable,
              setAggregatedCostByAccountDetailsTable,
              aggregatedCostByApplicationsDetailsTable,
              setAggregatedCostByApplicationsDetailsTable,
              costMetricsSummaryContainer,
              setCostMetricsSummaryContainer
            }}
          >
            <CostMetricsSelectionFilters />
            <Tabs
              tabs={[
                {
                  label: 'Overview',
                  id: 'cost-metrics-OV',
                  content: <CostMetricsOverviewPage />
                },
                {
                  label: 'Details',
                  id: 'cost-metrics-DT',
                  content: <CostMetricsDetailsPage />
                }
              ]}
            />
          </CostMetricsContext.Provider>
        </>
      }
    />
  );
}
