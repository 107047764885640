import { Alert, Box, Button, FormField, Modal, Select, SelectProps, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import * as mutations from 'src/graphql/mutations';
import { logger } from 'src/logger';
import { getCurrentUTCTimeZoneInISO } from 'src/utilities/DateTimeUtilities';
import { v4 as uuidv4 } from 'uuid';
import { Feedback, FeedbackType } from '../das-finsuite/admin-components/feedback/FeedbacksModel';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContextProvider';
import { eFinSuiteFeedbackMessages } from 'src/constants/AppConstants';
import { LoadingSpinner } from '../generic-components/LoadingSpinner';

interface FeedbackModalProps {
  showFeedbackModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const FEEDBACK_TYPE_OPTIONS: SelectProps.Options = [
  { label: 'General', value: 'General' },
  { label: 'Application', value: 'Application' }
];

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ showFeedbackModal, onCancel, onConfirm }) => {
  const userAuthDetails = useAuth();
  const { listOfApplications } = useAppContext();

  const activeApplicationsList = listOfApplications
    ?.filter((app) => app.itemMetadata.isActive)
    ?.map((app) => {
      return { label: app.applicationName || '', value: app.id || '' };
    });

  const [selectedFeedBackType, setSelectedFeedBackType] = useState<FeedbackType>('General');
  const [feedbackApp, setFeedbackApp] = useState<SelectProps.Option | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = React.useState('');

  const [feedbackSubmitStatus, setFeedbackSubmitStatus] = useState<'not-initiated' | 'submitting' | 'success' | 'failed'>('not-initiated');

  const onSubmit = () => {
    setFeedbackSubmitStatus('submitting');
    const feedbackInfo: Feedback = {
      id: uuidv4(),
      applicationName: {
        id: feedbackApp?.value || '',
        label: feedbackApp?.label || '',
        labelType: 'ApplicationName'
      },
      feedback: feedback,
      feedbackType: selectedFeedBackType,
      rating: rating,
      itemMetadata: {
        isActive: true,
        createdBy: userAuthDetails.Alias,
        createdTime: getCurrentUTCTimeZoneInISO(),
        updatedBy: userAuthDetails.Alias,
        updatedTime: getCurrentUTCTimeZoneInISO()
      }
    };
    createFeedback(feedbackInfo)
      .then((response) => {
        logger.info(`Successfully submitted feedback`, { info: response });
        clearFeedback();
        setFeedbackSubmitStatus('success');
        setTimeout(() => {
          setFeedbackSubmitStatus('not-initiated');
          onConfirm();
        }, 1000);
      })
      .catch((error: any) => {
        setFeedbackSubmitStatus('failed');
        logger.error(`Unable to submit feedback from footer modal.`, error);
      });
  };

  const createFeedback = async (feedback: Feedback) => {
    const createResponse: any = await API.graphql({
      query: mutations.createFeedback,
      variables: { input: feedback }
    });
    return createResponse?.data?.createFeedback;
  };

  const clearFeedback = () => {
    setSelectedFeedBackType('General');
    setFeedbackApp(null);
    setRating(0);
    setFeedback('');
  };

  return (
    <Modal
      onDismiss={() => {
        clearFeedback();
        setFeedbackSubmitStatus('not-initiated');
        onCancel();
      }}
      visible={showFeedbackModal}
      footer={
        <Box float="right" textAlign="center">
          <SpaceBetween direction="horizontal" size="xs" alignItems="center">
            {feedbackSubmitStatus === 'success' && (
              <Alert statusIconAriaLabel="Info" type="success">
                {eFinSuiteFeedbackMessages.FEEDBACK_SUCCESSFULLY_ADDED}
              </Alert>
            )}
            {feedbackSubmitStatus === 'failed' && (
              <Alert statusIconAriaLabel="Info" type="error">
                {eFinSuiteFeedbackMessages.FEEDBACK_CREATION_FAILED}
              </Alert>
            )}
            {feedbackSubmitStatus === 'submitting' && (
              <Alert statusIconAriaLabel="Info" type="info">
                {`submitting feedback`}
              </Alert>
            )}
            <Button
              variant="link"
              onClick={() => {
                clearFeedback();
                setFeedbackSubmitStatus('not-initiated');
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={() => onSubmit()} disabled={rating == 0}>
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Feedback"
    >
      <SpaceBetween direction="vertical" size="m">
        <FormField label="Feedback type" description="Select the category of feedback you are submitting">
          <Select
            placeholder="Select Feedback Type"
            options={FEEDBACK_TYPE_OPTIONS}
            selectedOption={{ label: selectedFeedBackType, value: selectedFeedBackType }}
            onChange={({ detail }) => setSelectedFeedBackType(detail.selectedOption.label as FeedbackType)}
          />
        </FormField>

        {selectedFeedBackType === 'Application' && (
          <FormField label="Application">
            <Select
              filteringType="auto"
              placeholder="Select Application"
              options={activeApplicationsList}
              selectedOption={feedbackApp}
              onChange={({ detail }) => setFeedbackApp(detail.selectedOption)}
            />
          </FormField>
        )}

        <SpaceBetween direction="vertical" size="xxs">
          <h4>{'How satisfied are you with your experience?'}</h4>
          <div className="star-rating">
            {Array.from({ length: 5 }, (_, index) => (
              <div className="star-wrapper-medium" key={index}>
                <FontAwesomeIcon icon={index < rating ? faStar : faStarRegular} onClick={() => setRating(index + 1)} key={index} />
              </div>
            ))}
          </div>
        </SpaceBetween>

        <FormField label={<>Enter your message below</>} description="Leave any comments, requests or questions below">
          <Textarea onChange={({ detail }) => setFeedback(detail.value)} value={feedback} />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};
