import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  AppLayoutProps,
  Box,
  BoxProps,
  Button,
  CardsProps,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  Header,
  Link,
  PropertyFilter,
  PropertyFilterProps,
  Select,
  SelectProps,
  SpaceBetween,
  TableProps
} from '@amzn/awsui-components-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appLayoutAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import { dateTimeComparatorForTable } from 'src/utilities/DateTimeUtilities';
import { AllAppsCategoryView } from './AllAppsCategoryView';
import {
  ALL_APPS_CARD_DEFINITION,
  APPLICATIONS_DEFAULT_PREFERENCES,
  APPLICATION_COLUMN_DEFINITIONS,
  DEFAULT_VISIBLE_CONTENT_OPTIONS
} from './AllAppsConfig';
import { AllAppsTableView } from './AllAppsTableView';
import { v4 as uuidv4 } from 'uuid';
import { DFPHomeBreadcrumbs } from 'src/components/dfp-home-page/DFPHomePage';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { getFilteringProperties, getUniqueApplicationFilteringOptionsForAllApps } from 'src/components/context/AppContextUtils';
import {
  BLANK_SEARCH_AND,
  Preferences,
  TableEmptyState,
  TableNoMatchState,
  getMatchesCountText
} from 'src/components/generic-components/TableCommons';
import { ApplicationFlatTableEntity } from 'src/components/context/AppContextModels';
import { DFPHomePageSideNavigation } from 'src/components/dfp-home-page/DFPHomePageSideNavigation';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { usePersistedState } from 'src/utilities/useLocalStorage';
import { eUserPreferenceKeys } from 'src/constants/AppConstants';
import { useColumnWidths } from 'src/utilities/useColumnWidths';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from '../das-finsuite-constants';

export const getAllAppsBreadcrumbItems = () => {
  return [
    ...DFPHomeBreadcrumbs,
    {
      text: 'All Apps',
      href: '/all-apps/#list'
    }
  ];
};

export const DefaultViewType: SelectProps.Option = { label: 'List view', value: 'list' };
export const ALL_APPS_VIEW_TYPES: SelectProps.Options = [DefaultViewType, { label: 'Category view', value: 'category' }];

export const DefaultGroupByType: SelectProps.Option = { label: 'GL Account', value: 'glAccount' };
export const GROUP_BY_TYPES: SelectProps.Options = [
  DefaultGroupByType,
  { label: 'Product UI', value: 'productUI' },
  { label: 'Team', value: 'team' }
];

export type AllAppsContextType = {
  appLayoutContentType: AppLayoutProps.ContentType;
  setAppLayoutContentType: (set: AppLayoutProps.ContentType) => void;
  viewType: SelectProps.Option | null;
  setViewType: (viewType: SelectProps.Option) => void;
  groupBy: SelectProps.Option | null;
  setGroupBy: (viewType: SelectProps.Option) => void;
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void;
};

export const AllAppsContext = createContext<AllAppsContextType>({
  appLayoutContentType: 'table',
  setAppLayoutContentType: (set: AppLayoutProps.ContentType) => {},
  viewType: ALL_APPS_VIEW_TYPES[0],
  setViewType: (viewType: SelectProps.Option) => {},
  groupBy: GROUP_BY_TYPES[0],
  setGroupBy: (groupByTye: SelectProps.Option) => {},
  displayFlashMessage: () => {}
});
export const useAllApps = () => useContext(AllAppsContext);

const AllApps: React.FC = () => {
  const appContext = useAppContext();
  const activeParsedApplications = appContext.parsedApplications.filter((parsedApps) => parsedApps.isActive);
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const [appLayoutContentType, setAppLayoutContentType] = React.useState<AppLayoutProps.ContentType>('default');

  const [groupBy, setGroupBy] = React.useState<SelectProps.Option>(GROUP_BY_TYPES[0]);
  const [viewType, setViewType] = React.useState<SelectProps.Option>(ALL_APPS_VIEW_TYPES[0]);

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  // Properties
  const [filteringProperties, setFilteringProperties] = useState<PropertyFilterProps.FilteringProperty[]>(getFilteringProperties(false));
  const [filteringOptions, setFilteringOptions] = useState<PropertyFilterProps.FilteringOption[]>([]);

  const [preferences, setPreferences] = usePersistedState(eUserPreferenceKeys.ALL_APPS_TABLE_PREFERENCES, APPLICATIONS_DEFAULT_PREFERENCES);
  const [query, setQuery] = useState(BLANK_SEARCH_AND);
  useEffect(() => {
    applicationCollection.actions.setPropertyFiltering(query);
  }, [query]);

  const [appColDef, saveWidths, columnDefinitionsLoading] = useColumnWidths(
    eUserPreferenceKeys.ALL_APPS_TABLE_WIDTHS,
    APPLICATION_COLUMN_DEFINITIONS
  );
  const [appCardDef, setAppCardDef] = useState<CardsProps.CardDefinition<ApplicationFlatTableEntity>>(ALL_APPS_CARD_DEFINITION);

  useEffect(() => {
    configureViewType();
  }, [pathname, hash]);

  const configureViewType = () => {
    const selectedViewType =
      // @ts-ignore
      ALL_APPS_VIEW_TYPES.find((viewType) => viewType.value === hash.substring(1)) || ALL_APPS_VIEW_TYPES[0];
    setViewType(selectedViewType);
  };

  useEffect(() => {
    if (activeParsedApplications.length > 0) {
      const uniqueObjects = getUniqueApplicationFilteringOptionsForAllApps(activeParsedApplications);
      setFilteringOptions(uniqueObjects);
    }
  }, [appContext.parsedApplications]);

  const applicationCollection = useCollection(activeParsedApplications, {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName="Applications" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            applicationCollection.actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        />
      )
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'applicationOwner',
          sortingComparator: (a: ApplicationFlatTableEntity, b: ApplicationFlatTableEntity) => {
            return a.applicationOwner.localeCompare(b.applicationOwner);
          }
        },
        isDescending: false
      }
    }
  });

  const viewTypeChanged = (detail: SelectProps.ChangeDetail) => {
    logger.info(`DaS FinSuite -> User Action: View Type Change`, {
      event_type: UserActionsEventTypes.VIEW_CHANGE,
      value: detail.selectedOption.label
    });
    setViewType(detail.selectedOption);
    navigate('/all-apps/#' + detail.selectedOption.value);
  };

  const categoryTypeChanged = (detail: SelectProps.ChangeDetail) => {
    logger.info(`DaS FinSuite -> User Action: Category Type Change`, {
      event_type: UserActionsEventTypes.CATEGORY_TYPE_CHANGE,
      value: detail.selectedOption.label
    });
    setGroupBy(detail.selectedOption);
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    const id = uuidv4();
    setFlashbarItems((prevItems) => [
      ...prevItems,
      {
        id: id,
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => removeFlashMessage(id)
      }
    ]);
    setTimeout(() => removeFlashMessage(id), 10000);
  };

  const removeFlashMessage = (id: string) => {
    setFlashbarItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  return (
    <>
      <AppLayout
        ariaLabels={appLayoutAriaLabels}
        contentType={appLayoutContentType}
        headerSelector="#h"
        navigation={<DFPHomePageSideNavigation />}
        toolsHide={true}
        stickyNotifications={true}
        notifications={<Flashbar items={flashbarItems} />}
        breadcrumbs={<DFPBreadcrumbs items={getAllAppsBreadcrumbItems()} />}
        content={
          <div className="app-layout-body">
            <AllAppsContext.Provider
              value={{
                appLayoutContentType,
                setAppLayoutContentType,
                viewType,
                setViewType,
                groupBy,
                setGroupBy,
                displayFlashMessage
              }}
            >
              <ContentLayout
                disableOverlap
                header={
                  <>
                    <Header
                      actions={
                        <>
                          {appContext.isAdminControlsEnabled && (
                            <SpaceBetween size="m" direction="horizontal">
                              <Button
                                variant="normal"
                                onClick={() => {
                                  navigate('/admin/applications');
                                }}
                              >
                                {'Manage in admin console'}
                              </Button>
                              <Button
                                iconName="add-plus"
                                variant="primary"
                                onClick={() => {
                                  navigate('/admin/manage-applications');
                                }}
                              >
                                {'New application'}
                              </Button>
                            </SpaceBetween>
                          )}
                        </>
                      }
                      variant="h1"
                    >
                      {'All apps'}
                    </Header>
                    <div className="all-apps-header-action-bar">
                      <PropertyFilter
                        className="width-45rem all-apps-header-action-property"
                        i18nStrings={propertyFilterI18nStrings('Applications')}
                        countText={getMatchesCountText(applicationCollection.filteredItemsCount!)}
                        expandToViewport={true}
                        {...applicationCollection.propertyFilterProps}
                        query={query}
                        onChange={(event) => {
                          setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH_AND : event.detail);
                        }}
                        filteringOptions={filteringOptions}
                        filteringProperties={filteringProperties}
                      />
                      <div className="flex-grow"></div>
                      {viewType.label === 'Category view' && (
                        <Select
                          className="width-15rem all-apps-header-action-item"
                          expandToViewport
                          selectedAriaLabel="Selected"
                          selectedOption={groupBy}
                          onChange={({ detail }) => categoryTypeChanged(detail)}
                          options={GROUP_BY_TYPES}
                        />
                      )}
                      <Select
                        className="width-15rem all-apps-header-action-item"
                        expandToViewport
                        selectedAriaLabel="Selected"
                        selectedOption={viewType}
                        onChange={({ detail }) => viewTypeChanged(detail)}
                        options={ALL_APPS_VIEW_TYPES}
                      />
                      {viewType.label === 'List view' && (
                        <Preferences
                          preferences={preferences}
                          setPreferences={setPreferences}
                          visibleContentOptions={DEFAULT_VISIBLE_CONTENT_OPTIONS}
                          disablePageSizeOptions={true}
                          disableResizableColumnsOptions={true}
                        />
                      )}
                    </div>
                  </>
                }
              >
                {viewType.value === 'list' && (
                  <AllAppsTableView
                    applicationEntity={appContext.parsedApplications}
                    filteringProperties={filteringProperties}
                    preferences={preferences}
                    setPreferences={setPreferences}
                    query={query}
                    setQuery={setQuery}
                    appColDef={appColDef as TableProps.ColumnDefinition<ApplicationFlatTableEntity>[]}
                    saveWidths={saveWidths}
                    applicationCollection={applicationCollection}
                    resizableColumns={false}
                  />
                )}
                {viewType.value === 'category' && (
                  <AllAppsCategoryView
                    applicationEntity={appContext.parsedApplications}
                    filteringProperties={filteringProperties}
                    preferences={preferences}
                    setPreferences={setPreferences}
                    query={query}
                    setQuery={setQuery}
                    appCardDef={appCardDef}
                    applicationCollection={applicationCollection}
                    groupBy={groupBy}
                  />
                )}
              </ContentLayout>
            </AllAppsContext.Provider>
          </div>
        }
      />
    </>
  );
};

export default AllApps;

interface AppNameWithLinkProps {
  appName: string;
  appId: string;
  fontSize?: BoxProps.FontSize;
  padding?: BoxProps.Spacing;
}
export const AppNameWithLink = ({ appName, appId, fontSize, padding }: AppNameWithLinkProps) => {
  const navigate = useNavigate();
  return (
    <Box
      className="app-name-with-link"
      variant="awsui-key-label"
      fontWeight="normal"
      color="text-status-info"
      fontSize={fontSize || 'body-m'}
      padding={padding}
    >
      <span
        onClick={() => {
          navigate('/all-apps/' + appId);
        }}
      >
        {appName}
      </span>
    </Box>
  );
};
