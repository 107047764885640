import { CollectionPreferencesProps, PropertyFilterProps, Table, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import { ApplicationFlatTableEntity } from 'src/components/context/AppContextModels';

interface AllAppsTableViewProps {
  applicationEntity: ApplicationFlatTableEntity[];
  filteringProperties: PropertyFilterProps.FilteringProperty[];
  preferences: CollectionPreferencesProps.Preferences<any>;
  setPreferences: (preferences: CollectionPreferencesProps.Preferences<any>) => void;
  query: PropertyFilterProps.Query;
  setQuery: (query: PropertyFilterProps.Query) => void;
  appColDef: TableProps.ColumnDefinition<ApplicationFlatTableEntity>[];
  applicationCollection: any;
  saveWidths: any;
  resizableColumns: boolean;
}

export const AllAppsTableView: React.FC<AllAppsTableViewProps> = ({
  preferences,
  appColDef,
  applicationCollection,
  saveWidths,
  resizableColumns
}: AllAppsTableViewProps) => {
  return (
    <>
      <Table
        variant="embedded"
        onColumnWidthsChange={saveWidths}
        stickyColumns={preferences.stickyColumns}
        items={applicationCollection.items}
        columnDefinitions={appColDef}
        visibleColumns={preferences.visibleContent}
        resizableColumns={resizableColumns}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        {...applicationCollection.collectionProps}
      />
    </>
  );
};
