import React, { useEffect } from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Checkbox } from '@amzn/awsui-components-react';
import { useAuth } from '../context/AuthContextProvider';
import { LoadingStatus } from '../context/AppContextModels';
import { Cache } from 'aws-amplify';
import { eCacheKeys } from 'src/constants/AppConstants';
import { useAppContext } from '../context/AppContextProvider';

interface DFPModifyPermissionsProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
const DFPModifyPermissions: React.FC<DFPModifyPermissionsProps> = ({ showModal, onCancel, onConfirm }) => {
  const userAuthDetails = useAuth();
  const appContext = useAppContext();

  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isOEReadOnly, setIsOEReadOnly] = React.useState(false);

  useEffect(() => {
    if (userAuthDetails.userAuthDataLoadingStatus === LoadingStatus.Completed) {
      setIsAdmin(userAuthDetails.isAdmin || false);
      setIsOEReadOnly(userAuthDetails.isOEReadOnly || false);
    }
  }, [showModal]);

  const onConfirmFromModal = () => {
    appContext.setIsAdminControlsEnabled(false);
    userAuthDetails.isAdmin = isAdmin;
    userAuthDetails.isOEReadOnly = isOEReadOnly;
    Cache.setItem(eCacheKeys.UserAuthDetails, userAuthDetails, { priority: 1 });
    onConfirm();
  };

  const onReset = () => {
    Cache.removeItem(eCacheKeys.UserAuthDetails);
    onConfirm();
  };

  return (
    <>
      <Modal
        onDismiss={() => onCancel()}
        visible={showModal}
        footer={
          <>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={() => onReset()}>
                  Reset to Default
                </Button>
                <Button variant="primary" onClick={() => onConfirmFromModal()}>
                  Confirm
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
        header="Customize Permissions"
      >
        <SpaceBetween direction="vertical" size="m">
          <Checkbox onChange={({ detail }) => setIsAdmin(detail.checked)} checked={isAdmin}>
            Admin
          </Checkbox>

          <Checkbox onChange={({ detail }) => setIsOEReadOnly(detail.checked)} checked={isOEReadOnly}>
            FinTech OPS
          </Checkbox>
        </SpaceBetween>
      </Modal>
    </>
  );
};

export default DFPModifyPermissions;
