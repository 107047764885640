import React, { useState } from 'react';
import { AppLayout, Box, ContentLayout, Flashbar, FlashbarProps, Header, Link, Tabs } from '@amzn/awsui-components-react';
import { DFPHomePageSideNavigation } from '../../dfp-home-page/DFPHomePageSideNavigation';
import { PolicyEngineSelectionFilters } from './PolicyEngineSelectionFilters';
import { PolicyEngineOverview } from './PolicyEngineOverview';
import { PolicyEngineDetailsTab } from './PolicyEngineDetailsTab';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { FinTechOpsHref } from '../FinTechOpsConstants';
import { PolicyEngineContext } from './PolicyEngineContext';
import { StatusType } from '../sas/SasInterfaces';

export function PolicyEnginePage() {
  const finOpsContext = useFinTechContext();

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [policyEngineGraphStatus, setPolicyEngineGraphStatus] = useState<StatusType>('loading');
  const [policyEngineGraphData, setPolicyEngineGraphData] = useState<any>();

  const [policyEngineDetailsTabStatus, setPolicyEngineDetailsTabStatus] = useState<StatusType>('loading');
  const [policyEngineDetailsTabData, setPolicyEngineDetailsTabData] = useState<any>();

  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            {' '}
            submit a ticket
          </Link>
        );
      }
    }
    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} 'or ' {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={flashbarItems} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: FinTechOpsHref.finTechOps },
            {
              text: 'Policy Engine',
              href: ''
            }
          ]}
        />
      }
      maxContentWidth={Number.MAX_VALUE}
      content={
        <>
          <PolicyEngineContext.Provider
            value={{
              displayNotification,
              policyEngineGraphStatus,
              setPolicyEngineGraphStatus,
              policyEngineGraphData,
              setPolicyEngineGraphData,
              policyEngineDetailsTabStatus,
              setPolicyEngineDetailsTabStatus,
              policyEngineDetailsTabData,
              setPolicyEngineDetailsTabData
            }}
          >
            <PolicyEngineSelectionFilters />
            <Tabs
              tabs={[
                {
                  label: 'Overview',
                  id: 'policy-engine-OV',
                  content: <PolicyEngineOverview />
                },
                {
                  label: 'Details',
                  id: 'policy-engine-DT',
                  content: <PolicyEngineDetailsTab />
                }
              ]}
            />
          </PolicyEngineContext.Provider>
        </>
      }
    />
  );
}
