/* eslint-disable prettier/prettier */
import moment from 'moment-timezone';
import { findCommonElements3, indicatorIcon, indicatorIconVariant } from '../fintech-ops-dashboard/FinTechOpsUtils';
import {
  PolicyEngineParsedDataItem,
  PolicyEngineParsedDetails,
  PolicyEngineRawData,
  PolicyEngineRawDetails,
  PolicyEngineFilters,
  PolicyEngineDetailsTabInfo,
  PolicyEngineTableData,
  PolicyEngineSummaryContainer,
  PolicyEngineGraphData,
  PolicyDataForGraphProcessing,
  Metadata
} from './PolicyEngineInterface';
import {
  convertUTCtoUserTimeZone,
  convertUTCtoUserTimeZoneMoment,
  getBackAgeInPeriod,
  getCurrentAndPreviousMonth,
  getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps
} from 'src/utilities/DateTimeUtilities';
import { FilterDateFormat_MMM_YYYY, OEEmptyStringMappings, POLICY_ENGINE_URL } from '../FinTechOpsConstants';
import { DashboardFilters, PolicyEngineDashboardItems } from '../fintech-ops-dashboard/FinTechOpsDataInterfaces';
import * as React from 'react';
import { Icon, TableProps } from '@amzn/awsui-components-react';
import { POLICY_ENGINE_DETAILS_ISSUES_VISIBLE_CONTENT, POLICY_ENGINE_DETAILS_TABLE } from './PolicyEngineTableFilterConfig';
import { numberFormatter, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import { capitalizeFirstLetter } from '../FinTechOpsCommonElements';

const EMPTY_TABLE_DATA: PolicyEngineTableData = {
  visibleColumns: [],
  columnData: [],
  rowData: []
};

export function filterDataByCreateDates(rawData: PolicyEngineRawData[]): PolicyEngineRawData[] {
  const currentDate = moment();
  const lastSixMonthsIncludingCurrentMonth = getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps();

  const included: PolicyEngineRawData[] = [];
  const excluded: PolicyEngineRawData[] = [];

  rawData.forEach((item) => {
    if (item.create_date) {
      const createDate = moment(item.create_date);
      if (createDate.isBetween(lastSixMonthsIncludingCurrentMonth, currentDate, 'day', '[]')) {
        included.push(item);
      } else {
        excluded.push(item);
      }
    } else {
      console.debug('Item without create_date ', item);
    }
  });

  // For debugging
  // console.log('Excluded Records:', JSON.stringify(excluded));
  // const uniqueMonthsSet = new Set(included.map((item) => moment(item.create_date).format('YYYY-MM')));
  // const uniqueMonths = Array.from(uniqueMonthsSet).sort();
  // console.log('Policy Engine Included uniqueMonths ', JSON.stringify(uniqueMonths));

  return included;
}

// Formatting raw data from API to formatted
export const returnUsefulRawPolicyEngineData = (policyEngineRawData: PolicyEngineRawData[], lastUpdatedAt: Metadata): PolicyEngineParsedDetails => {
  const parsedPolicyEngineData: PolicyEngineParsedDataItem[] = [];

  const filteredPolicyEngineData = filterDataByCreateDates(policyEngineRawData);

  filteredPolicyEngineData.forEach((rawPolicyEngineInfo: PolicyEngineRawData) => {
    const createdDate = convertUTCtoUserTimeZone(rawPolicyEngineInfo.create_date);
    const resolvedDate = rawPolicyEngineInfo.resolved_date ? convertUTCtoUserTimeZone(rawPolicyEngineInfo.resolved_date) : null;

    const pardsedData: PolicyEngineParsedDataItem = {
      team_name: rawPolicyEngineInfo.team_name || OEEmptyStringMappings.Unassigned_Team,

      manager_name_hierarchy_concat: rawPolicyEngineInfo.manager_name_hierarchy_concat.split(':') || [],
      manager_name: rawPolicyEngineInfo.manager_name_hierarchy_concat.split(':')[0] || OEEmptyStringMappings.Unassigned_Manager,

      assignee_name: rawPolicyEngineInfo.violator_owner_name || OEEmptyStringMappings.Generic_Mapping,

      resolved_date: resolvedDate,
      created_on_week: moment(createdDate).isoWeek(),
      create_date_time: createdDate,
      created_on_month_YYYY_MM_DD: moment(createdDate).startOf('month').format('YYYY-MM-DD'),
      create_date_formatted_MMM_YYYY: moment(createdDate).format(FilterDateFormat_MMM_YYYY),
      created_on_month_number: moment(createdDate).month() + 1,
      create_date_formatted_YYYYMM: moment(createdDate).format('YYYYMM'),

      policy_age_in_minutes: getBackAgeInPeriod(createdDate, 'minutes'),
      url: `${POLICY_ENGINE_URL}${rawPolicyEngineInfo.violator_id}#${rawPolicyEngineInfo.violation_name}`,

      policy_class: rawPolicyEngineInfo.policy_class,
      policy_type: rawPolicyEngineInfo.policy_type,
      severity: rawPolicyEngineInfo.severity,
      status: rawPolicyEngineInfo.status,
      violator_id: rawPolicyEngineInfo.violator_id,
      violator_name: rawPolicyEngineInfo.violator_name,
      violation_entity_type: rawPolicyEngineInfo.violation_entity_type,
      violation_name: rawPolicyEngineInfo.violation_name,
      violation_pretty_name: rawPolicyEngineInfo.violation_pretty_name,
      violator_type: rawPolicyEngineInfo.violator_type,
      resolution_type: rawPolicyEngineInfo.resolution_type
    };

    parsedPolicyEngineData.push(pardsedData);
  });

  // when violator_type": "awsAccount", then violator_name is aws account id
  // console.log('rawPolicyEngineData ', JSON.stringify(policyEngineRawData.data));
  // console.log('parsedPolicyEngineData ', JSON.stringify(parsedPolicyEngineData));

  return {
    metadata: lastUpdatedAt,
    data: parsedPolicyEngineData
  };
};

// Preparing Policy Engine Filters for Dashboard
export const getDashboardFiltersFromPolicyEngine = (items: PolicyEngineParsedDataItem[]): DashboardFilters => {
  const policyEngineMasterFilterData: DashboardFilters = {
    team_name: [],
    managers: [],
    assignees: []
  };

  const uniqueValues = (filterArray: string[], value: string) => {
    if (!filterArray.includes(value)) {
      filterArray.push(value);
    }
  };

  items.forEach((item: PolicyEngineParsedDataItem) => {
    uniqueValues(policyEngineMasterFilterData.team_name, item.team_name);
    uniqueValues(policyEngineMasterFilterData.managers, item.manager_name);
    uniqueValues(policyEngineMasterFilterData.assignees, item.assignee_name);
  });

  return policyEngineMasterFilterData;
};

// Preparing Policy Engine Filters
export const createPolicyEngineFilterData = (items: PolicyEngineParsedDataItem[]): PolicyEngineFilters => {
  const policyEngineMasterFilterData: PolicyEngineFilters = {
    team: [],
    managers: [],
    assignees: [],
    resolutionTypes: [],
    severityTypes: [],
    timePeriods: [],
    policyClasses: []
  };

  const uniqueValues = (filterArray: string[], value: string) => {
    if (!filterArray.includes(value)) {
      filterArray.push(value);
    }
  };

  items.forEach((item: PolicyEngineParsedDataItem) => {
    uniqueValues(policyEngineMasterFilterData.team, item.team_name);
    uniqueValues(policyEngineMasterFilterData.managers, item.manager_name);
    uniqueValues(policyEngineMasterFilterData.assignees, item.assignee_name);
    uniqueValues(policyEngineMasterFilterData.resolutionTypes, item.resolution_type);
    uniqueValues(policyEngineMasterFilterData.severityTypes, item.severity);
    uniqueValues(policyEngineMasterFilterData.timePeriods, item.create_date_formatted_MMM_YYYY);
    uniqueValues(policyEngineMasterFilterData.policyClasses, item.policy_class);
  });

  return policyEngineMasterFilterData;
};

export const applyFiltersPolicyEngineMetrics = (
  parsedPolicyEngineData: PolicyEngineParsedDataItem[],
  filters: PolicyEngineFilters
): [PolicyEngineParsedDataItem[], PolicyEngineParsedDataItem[]] => {
  const filteredWithoutMonthData: PolicyEngineParsedDataItem[] = parsedPolicyEngineData.filter((item: PolicyEngineParsedDataItem) => {
    return (
      filters.team.includes(item.team_name) &&
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.assignees.includes(item.assignee_name) &&
      filters.resolutionTypes.includes(item.resolution_type) &&
      filters.severityTypes.includes(item.severity) &&
      filters.policyClasses.includes(item.policy_class)
    );
  });
  const filteredWithMonthData: PolicyEngineParsedDataItem[] = filteredWithoutMonthData.filter((item) => {
    return filters.timePeriods.includes(item.create_date_formatted_MMM_YYYY);
  });
  return [filteredWithoutMonthData, filteredWithMonthData];
};

export const applyDashboardFiltersPolicyEngineMetrics = (
  items: PolicyEngineParsedDataItem[],
  filters: DashboardFilters
): PolicyEngineParsedDataItem[] => {
  return items.filter((item: PolicyEngineParsedDataItem) => {
    return (
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.team_name.includes(item.team_name) &&
      filters.assignees.includes(item.assignee_name)
    );
  });
};

// Calculations for FinTechOps Dashboard Policy Engine section
export const createOpsPolicyEngineDashboardData = (policyEngineRawData: PolicyEngineParsedDataItem[]): PolicyEngineDashboardItems => {
  let solved = 0;
  let unsolved = 0;
  let totalOpenCritical = 0;

  policyEngineRawData.forEach((item: PolicyEngineParsedDataItem) => {
    if (item.status === 'Resolved') {
      solved++;
    }

    if (item.status === 'Open') {
      unsolved++;
    }

    if (item.status === 'Open' && item.severity === 'Critical') {
      totalOpenCritical++;
    }
  });

  return {
    totalPolicyEngineRisks: policyEngineRawData.length,
    solved: solved,
    unsolved: unsolved,
    totalOpenCriticalRisks: totalOpenCritical,
    resolutionRate: unsolved + solved > 0 ? (((solved / (unsolved + solved)) * 100).toFixed(2) as unknown as number) : 0
  } as PolicyEngineDashboardItems;
};

export const getCalculatedGraphData = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyEngineGraphData => {
  const totalPERisksByMonth = getTotalPERisksByMonth(parsedPolicyEngineData);
  const totalOpenRisksByResolutionType = getTotalOpenRisksByResolutionType(parsedPolicyEngineData);
  const totalOpenRisksByTeam = getTotalOpenRisksByTeam(parsedPolicyEngineData);
  const totalOpenRisksBySeverityType = getTotalOpenRisksBySeverityType(parsedPolicyEngineData);
  const totalOpenRisksByPolicyClass = getTotalOpenRisksByPolicyClass(parsedPolicyEngineData);
  const totalOpenRisksByAWSAccount = getTotalOpenRisksByAWSAccount(parsedPolicyEngineData);
  return {
    TotalPERisksByMonth: totalPERisksByMonth,
    TotalOpenRisksByResolutionType: totalOpenRisksByResolutionType,
    TotalOpenRisksByTeam: totalOpenRisksByTeam,
    TotalOpenRisksBySeverityType: totalOpenRisksBySeverityType,
    TotalOpenRisksByPolicyClass: totalOpenRisksByPolicyClass,
    TotalOpenRisksByAWSAccount: totalOpenRisksByAWSAccount
  };
};

// Total PE risks by month
const getTotalPERisksByMonth = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyDataForGraphProcessing[] => {
  const aggregatedData: { [monthCategory: string]: { [status: string]: number } } = {};

  // Iterate through the raw data and aggregate by month and status
  parsedPolicyEngineData.forEach((item) => {
    const { status, create_date_formatted_YYYYMM } = item;

    if (!aggregatedData[create_date_formatted_YYYYMM]) {
      aggregatedData[create_date_formatted_YYYYMM] = {
        Open: 0,
        Resolved: 0
      };
    }

    aggregatedData[create_date_formatted_YYYYMM][status]++;
  });

  // Convert aggregated data to the desired format
  const result: PolicyDataForGraphProcessing[] = [];

  for (const monthCategory in aggregatedData) {
    result.push({
      count: aggregatedData[monthCategory].Open,
      title: 'Open',
      category: monthCategory
    });
    result.push({
      count: aggregatedData[monthCategory].Resolved,
      title: 'Resolved',
      category: monthCategory
    });
  }

  return result;
};

const getTotalOpenRisksByResolutionType = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyDataForGraphProcessing[] => {
  const aggregatedData: { [monthCategory: string]: { [resolutionType: string]: number } } = {};

  // Iterate through the raw data and aggregate by month and resolution_type
  parsedPolicyEngineData
    //filtering for Open status only
    .filter((item) => item.status === 'Open')
    .forEach((item) => {
      const { resolution_type, create_date_time } = item;
      const monthCategory = moment(create_date_time).format('YYYYMM');

      if (!aggregatedData[monthCategory]) {
        aggregatedData[monthCategory] = {};
      }

      if (!aggregatedData[monthCategory][resolution_type]) {
        aggregatedData[monthCategory][resolution_type] = 0;
      }

      aggregatedData[monthCategory][resolution_type]++;
    });

  // Convert aggregated data to the desired format
  const result: PolicyDataForGraphProcessing[] = [];

  for (const monthCategory in aggregatedData) {
    for (const resolutionType in aggregatedData[monthCategory]) {
      result.push({
        count: aggregatedData[monthCategory][resolutionType],
        title: resolutionType,
        category: monthCategory
      });
    }
  }

  // console.debug('getTotalOpenRisksByResolutionType ', JSON.stringify(result));
  return result;
};

// Total Open Risks by Team
const getTotalOpenRisksByTeam = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyDataForGraphProcessing[] => {
  // Create an object to store aggregated data by month, team_name, and "Open" status count
  const aggregatedData: { [key: string]: { [key: string]: number } } = {};

  // Loop through the data and populate the aggregatedData object
  parsedPolicyEngineData
    .filter((item) => item.status === 'Open')
    .forEach((item) => {
      const monthKey = moment(item.create_date_time).format('YYYYMM');

      if (!aggregatedData[monthKey]) {
        aggregatedData[monthKey] = {};
      }

      if (!aggregatedData[monthKey][item.team_name]) {
        aggregatedData[monthKey][item.team_name] = 0;
      }

      aggregatedData[monthKey][item.team_name]++;
    });

  // Transform the aggregatedData object into GraphData array
  const graphData: any[] = [];

  for (const monthKey in aggregatedData) {
    for (const team_name in aggregatedData[monthKey]) {
      graphData.push({
        count: aggregatedData[monthKey][team_name],
        title: team_name,
        category: monthKey
      });
    }
  }
  return graphData;
};

// Total open risks by Severity type
const getTotalOpenRisksBySeverityType = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyDataForGraphProcessing[] => {
  // Create an object to store aggregated data by month, severity, and "Open" status count
  const aggregatedData: { [key: string]: { [key: string]: number } } = {};

  // Loop through the data and populate the aggregatedData object
  parsedPolicyEngineData.forEach((item) => {
    const monthKey = moment(item.create_date_time).format('YYYYMM');

    if (!aggregatedData[monthKey]) {
      aggregatedData[monthKey] = {};
    }

    if (!aggregatedData[monthKey][item.severity]) {
      aggregatedData[monthKey][item.severity] = 0;
    }

    if (item.status === 'Open') {
      aggregatedData[monthKey][item.severity]++;
    }
  });

  // Transform the aggregatedData object into GraphData array
  const graphData: any[] = [];

  for (const monthKey in aggregatedData) {
    for (const severity in aggregatedData[monthKey]) {
      graphData.push({
        count: aggregatedData[monthKey][severity],
        title: severity,
        category: monthKey
      });
    }
  }

  return graphData;
};

// Total Open Risks by Policy Class
const getTotalOpenRisksByPolicyClass = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyDataForGraphProcessing[] => {
  // Create an object to store aggregated data by month, policy_class, and "Open" status count
  const aggregatedData: { [key: string]: { [key: string]: number } } = {};

  // Loop through the data and populate the aggregatedData object
  parsedPolicyEngineData.forEach((item) => {
    const monthKey = moment(item.create_date_time).format('YYYYMM');

    if (!aggregatedData[monthKey]) {
      aggregatedData[monthKey] = {};
    }

    if (!aggregatedData[monthKey][item.policy_class]) {
      aggregatedData[monthKey][item.policy_class] = 0;
    }

    if (item.status === 'Open') {
      aggregatedData[monthKey][item.policy_class]++;
    }
  });

  // Transform the aggregatedData object into GraphData array
  const graphData: any[] = [];

  for (const monthKey in aggregatedData) {
    for (const policy_class in aggregatedData[monthKey]) {
      graphData.push({
        count: aggregatedData[monthKey][policy_class],
        title: policy_class,
        category: monthKey
      });
    }
  }

  return graphData;
};

const getTotalOpenRisksByAWSAccount = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyDataForGraphProcessing[] => {
  // Create an object to store aggregated data by month, violator_name, and "Open" status count
  const aggregatedData: { [key: string]: { [key: string]: number } } = {};

  // Loop through the data and populate the aggregatedData object
  parsedPolicyEngineData.forEach((item) => {
    const monthKey = moment(item.create_date_time).format('YYYYMM');

    if (!aggregatedData[monthKey]) {
      aggregatedData[monthKey] = {};
    }

    if (!aggregatedData[monthKey][item.violator_name] && item.violator_type === 'awsAccount') {
      aggregatedData[monthKey][item.violator_name] = 0;
    }

    if (item.violator_type === 'awsAccount' && item.status === 'Open') {
      aggregatedData[monthKey][item.violator_name]++;
    }
  });

  // Transform the aggregatedData object into GraphData array
  const graphData: any[] = [];

  for (const monthKey in aggregatedData) {
    for (const violator_name in aggregatedData[monthKey]) {
      graphData.push({
        count: aggregatedData[monthKey][violator_name],
        title: violator_name,
        category: monthKey
      });
    }
  }
  return graphData;
};

export const getCalculatedDetailsTabData = (
  parsedPolicyEngineData: PolicyEngineParsedDataItem[],
  finTechOpsPolicyEngineParsedRawData: PolicyEngineParsedDataItem[]
): PolicyEngineDetailsTabInfo => {
  const policyEngineSummaryContainer = getPolicyEngineSummaryContainer(parsedPolicyEngineData, finTechOpsPolicyEngineParsedRawData);

  const risksByTeamAndManager_Monthly = getRisksByTeamAndManager_Monthly(parsedPolicyEngineData);
  const risksByPolicyClass_Monthly = getRisksByPolicyClass_Monthly(parsedPolicyEngineData);
  const risksBySeverityType_Monthly = getRisksBySeverityType_Monthly(parsedPolicyEngineData);
  const totalOpenRisks = getTotalOpenRisksDetails(parsedPolicyEngineData);

  return {
    policyEngineSummaryContainer: policyEngineSummaryContainer,
    risksByTeamAndManager_Monthly: risksByTeamAndManager_Monthly,
    risksByPolicyClass_Monthly: risksByPolicyClass_Monthly,
    risksBySeverityType_Monthly: risksBySeverityType_Monthly,
    totalOpenRisksDetails: totalOpenRisks
  };
};

const getPolicyEngineSummaryContainer = (
  parsedPolicyEngineData: PolicyEngineParsedDataItem[],
  finTechOpsPolicyEngineParsedRawData: PolicyEngineParsedDataItem[]
): PolicyEngineSummaryContainer => {
  const totalOpenRisks = parsedPolicyEngineData.filter((item: PolicyEngineParsedDataItem) => item.status === 'Open');
  const [currentMonth, previousMonth] = getCurrentAndPreviousMonth(parsedPolicyEngineData, 'created_on_month_YYYY_MM_DD');

  const distinctTeams = getUniqueFieldValues(parsedPolicyEngineData, 'team_name');

  const totalRisksInCurrentMonth = finTechOpsPolicyEngineParsedRawData.filter(
    (item) => item.created_on_month_YYYY_MM_DD === currentMonth && item.status === 'Open'
  );
  const totalRisksInPrevMnth = finTechOpsPolicyEngineParsedRawData.filter(
    (item) => item.created_on_month_YYYY_MM_DD === previousMonth && item.status === 'Open'
  );

  const aggregatedValuesByTeam: any[] = [];

  distinctTeams.forEach((team) => {
    const teamsRisksInCurrentMonth = totalRisksInCurrentMonth.filter((item) => item.team_name === team);
    const teamsRisksInPreviousMonth = totalRisksInPrevMnth.filter((item) => item.team_name === team);

    aggregatedValuesByTeam.push({
      team: team,
      totalRisksInCurrentMonth: totalOpenRisks.filter((item) => item.team_name === team).length,
      MoM: teamsRisksInCurrentMonth?.length - teamsRisksInPreviousMonth?.length
    });
  });

  const colDef: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'team',
      header: 'Team',
      sortingField: 'team',
      cell: (item: any) => item.team,
      width: 120
    },
    {
      id: 'totalRisksInCurrentMonth',
      header: 'Risks',
      sortingField: 'totalRisksInCurrentMonth',
      cell: (item: any) => numberFormatter(item.totalRisksInCurrentMonth) || 0,
      width: 120
    },
    {
      id: 'MoM',
      header: 'MoM',
      sortingField: 'MoM',
      cell: (item: any) => (
        <>
          {numberFormatter(Math.abs(item.MoM)) || '-'}
          <Icon name={indicatorIcon(item.MoM)} variant={indicatorIconVariant(item.MoM)} />
        </>
      ),
      width: 120
    }
  ];

  const visibleColumns = colDef.map((item) => {
    return item.id || '';
  });

  return {
    policyEngineOpenRiskCount: totalOpenRisks.length,
    policyEngineSummaryTable: {
      columnData: colDef || [],
      rowData: aggregatedValuesByTeam || [],
      visibleColumns: visibleColumns || []
    }
  };
};

interface AggregatedData {
  team_name: string;
  manager_name: string;
  openCount: number;
  resolvedCount: number;
}

// Aggregated Risks by Team & Manager
const getRisksByTeamAndManager_Monthly = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyEngineTableData => {
  // Create a dictionary to store aggregated data
  const aggregatedData: { [key: string]: AggregatedData } = {};

  // Iterate through the parsedPolicyEngineData
  parsedPolicyEngineData.forEach((item) => {
    const { team_name, manager_name, status } = item;
    const key = `${team_name}-${manager_name}`;

    // Initialize the aggregated data if it doesn't exist
    if (!aggregatedData[key]) {
      aggregatedData[key] = {
        team_name,
        manager_name,
        openCount: 0,
        resolvedCount: 0
      };
    }

    // Increment counts based on status
    if (status === 'Open') {
      aggregatedData[key].openCount++;
    } else if (status === 'Resolved') {
      aggregatedData[key].resolvedCount++;
    }
  });

  // Convert the dictionary into an array of AggregatedData objects
  const aggregatedDataArray: AggregatedData[] = Object.values(aggregatedData);

  const colDef: TableProps.ColumnDefinition<AggregatedData>[] = [
    {
      id: 'team_name',
      header: 'Team',
      sortingField: 'team_name',
      width: 150,
      cell: (item: AggregatedData) => item.team_name
    },
    {
      id: 'manager_name',
      header: 'Manager',
      sortingField: 'manager_name',
      width: 250,
      cell: (item: AggregatedData) => item.manager_name
    },
    {
      id: 'openCount',
      header: 'Open',
      sortingField: 'openCount',
      width: 150,
      cell: (item: AggregatedData) => numberFormatter(item.openCount) || 0
    },
    {
      id: 'resolvedCount',
      header: 'Resolved',
      sortingField: 'resolvedCount',
      width: 150,
      cell: (item: AggregatedData) => numberFormatter(item.resolvedCount) || 0
    }
  ];

  const visibleColumns = colDef.map((item) => item.id || '');

  return {
    columnData: colDef || [],
    rowData: aggregatedDataArray || [],
    visibleColumns: visibleColumns || []
  };
};

const getRisksByPolicyClass_Monthly = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyEngineTableData => {
  // Create a Set to keep track of all unique policy classes
  const uniquePolicyClasses = new Set<string>();

  // Iterate through the parsedPolicyEngineData to collect unique policy classes
  parsedPolicyEngineData.forEach((item) => {
    const { policy_class } = item;
    uniquePolicyClasses.add(policy_class);
  });

  // Create an array of unique policy classes
  const policyClassesArray = Array.from(uniquePolicyClasses);

  // Create a dictionary to store aggregated data by team and policy class
  const aggregatedDataByTeam: { [team_name: string]: any } = {};

  // Iterate through the parsedPolicyEngineData
  parsedPolicyEngineData.forEach((item) => {
    const { team_name, policy_class } = item;

    // Initialize the aggregated data if it doesn't exist
    if (!aggregatedDataByTeam[team_name]) {
      aggregatedDataByTeam[team_name] = {
        team_name
      };
      // Initialize all policy classes with 0
      policyClassesArray.forEach((pc) => {
        aggregatedDataByTeam[team_name][pc] = 0;
      });
    }

    // Increment the count for the policy class
    aggregatedDataByTeam[team_name][policy_class]++;
  });

  // Convert the dictionary into a flat array
  let flatAggregatedData: any[] = Object.values(aggregatedDataByTeam);
  flatAggregatedData = flatAggregatedData.sort((a, b) => a.team_name.localeCompare(b.team_name));

  const policClassesDef = policyClassesArray.map((policClass) => {
    return {
      id: policClass,
      header: capitalizeFirstLetter(policClass),
      sortingField: policClass,
      cell: (item: any) => numberFormatter(item[policClass]) || 0
    };
  });

  const colDef: any[] = [
    {
      id: 'team_name',
      header: 'Team',
      sortingField: 'team_name',
      cell: (item: any) => item.team_name
    },
    ...policClassesDef
  ];

  const visibleColumns = colDef.map((item) => item?.id || '');

  return {
    columnData: colDef || [],
    rowData: flatAggregatedData || [],
    visibleColumns: visibleColumns || []
  };
};

const getRisksBySeverityType_Monthly = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyEngineTableData => {
  // Create a Set to keep track of all unique severities
  const uniqueSeverities = new Set<string>();

  // Iterate through the parsedPolicyEngineData to collect unique severities
  parsedPolicyEngineData.forEach((item) => {
    const { severity } = item;
    uniqueSeverities.add(severity);
  });

  // Create an array of unique severities
  const severitiesArray = Array.from(uniqueSeverities);

  // Create a dictionary to store aggregated data by team and severity
  const aggregatedDataByTeam: { [team_name: string]: any } = {};

  // Iterate through the parsedPolicyEngineData
  parsedPolicyEngineData.forEach((item) => {
    const { team_name, severity } = item;

    // Initialize the aggregated data if it doesn't exist
    if (!aggregatedDataByTeam[team_name]) {
      aggregatedDataByTeam[team_name] = {
        team_name
      };
      // Initialize all severities with 0
      severitiesArray.forEach((sev) => {
        aggregatedDataByTeam[team_name][sev] = 0;
      });
    }

    // Increment the count for the severity
    aggregatedDataByTeam[team_name][severity]++;
  });

  // Convert the dictionary into a flat array
  const flatAggregatedData: any[] = Object.values(aggregatedDataByTeam);
  const severityDef = severitiesArray.map((severity) => {
    return {
      id: severity,
      header: capitalizeFirstLetter(severity),
      sortingField: severity,
      cell: (item: any) => numberFormatter(item[severity]) || 0
    };
  });

  const colDef: any[] = [
    {
      id: 'team_name',
      header: 'Team',
      sortingField: 'team_name',
      cell: (item: any) => item.team_name
    },
    ...severityDef
  ];

  const visibleColumns = colDef.map((item) => item?.id || '');

  return {
    columnData: colDef || [],
    rowData: flatAggregatedData || [],
    visibleColumns: visibleColumns || []
  };
};

const getTotalOpenRisksDetails = (parsedPolicyEngineData: PolicyEngineParsedDataItem[]): PolicyEngineTableData => {
  return {
    columnData: POLICY_ENGINE_DETAILS_TABLE || [],
    rowData: parsedPolicyEngineData.filter((item) => item.status === 'Open') || [],
    visibleColumns: POLICY_ENGINE_DETAILS_ISSUES_VISIBLE_CONTENT || []
  };
};
