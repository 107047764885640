import { Box } from '@amzn/awsui-components-react';
import React from 'react';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utilities/DateTimeUtilities';

interface LastRefreshedAtProps {
  lastRefreshedDateTime?: string;
}
const LastRefreshedAt: React.FC<LastRefreshedAtProps> = ({ lastRefreshedDateTime }) => {
  return (
    <>
      {!lastRefreshedDateTime && <></>}
      {lastRefreshedDateTime && (
        <div className={lastRefreshedDateTime === '' ? 'refreshBorderClear' : 'refreshBorder'}>
          <Box color="text-body-secondary">{`Last data refresh: ${getCurrentUserLocalTimeReadableFormat(lastRefreshedDateTime)}`}</Box>
        </div>
      )}
    </>
  );
};

export default LastRefreshedAt;
