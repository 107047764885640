import React from 'react';
import { Box, ColumnLayout, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { BasicMetricContainer, ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { FinTechOpsCategories, FinTechOpsCategoriesLabelColors, FinTechOpsHref, FinTechOpsMetricsDescriptions } from '../FinTechOpsConstants';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';

export const FinTechOpsCostMetrics = () => {
  const finOpsContext = useFinTechContext();
  const costMetricsData = finOpsContext.finTechOpsCostMetricsDashboardData;

  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          Cost Metrics
        </Box>
        <LastRefreshedAt lastRefreshedDateTime={finOpsContext.userMetricsWithUserData.metadata.last_updated_at} />
      </SpaceBetween>

      <div>
        {finOpsContext.finTechOpsCostMetricsDataStatus === 'loading' && <LoadingSpinner />}
        {finOpsContext.finTechOpsCostMetricsDataStatus === 'pending' && <LoadingSpinner />}
        {finOpsContext.finTechOpsCostMetricsDataStatus === 'error' && <ErrorContainer />}
        {finOpsContext.finTechOpsCostMetricsDataStatus === 'finished' && (
          <ColumnLayout columns={4} minColumnWidth={10}>
            <BasicMetricContainer
              title="Total Current Month Cost"
              value={costMetricsData.totalCurrentMonthCost ? (costMetricsData.totalCurrentMonthCost as unknown as string) : '0'}
              unit=""
              href={FinTechOpsHref.costMetrics}
              id={FinTechOpsCategories.costMetrics}
              color={FinTechOpsCategoriesLabelColors.costMetrics}
              description={FinTechOpsMetricsDescriptions.costMetricsTotalCurrentMonthCost}
            />
            <BasicMetricContainer
              title="Total Previous Month Cost"
              value={costMetricsData.totalPreviousMonthCost ? (costMetricsData.totalPreviousMonthCost as unknown as string) : '0'}
              unit=""
              href={FinTechOpsHref.costMetrics}
              id={FinTechOpsCategories.costMetrics}
              color={FinTechOpsCategoriesLabelColors.costMetrics}
              description={FinTechOpsMetricsDescriptions.costMetricsTotalPreviousMonthCost}
            />
            <BasicMetricContainer
              title="Avg Monthly Cost"
              value={costMetricsData.avgMonthlyCost ? (costMetricsData.avgMonthlyCost as unknown as string) : '0'}
              unit=""
              href={FinTechOpsHref.costMetrics}
              id={FinTechOpsCategories.costMetrics}
              color={FinTechOpsCategoriesLabelColors.costMetrics}
              description={FinTechOpsMetricsDescriptions.costMetricsAvgMonthlyCost}
            />
            <BasicMetricContainer
              title="Current Month - Top Product Contributing to Cost"
              value={costMetricsData.topProductContributingToCost ? (costMetricsData.topProductContributingToCost as unknown as string) : ''}
              unit=""
              href={FinTechOpsHref.costMetrics}
              id={FinTechOpsCategories.costMetrics}
              color={FinTechOpsCategoriesLabelColors.costMetrics}
              description={FinTechOpsMetricsDescriptions.costMetricsTopProductContributingToCost}
            />
          </ColumnLayout>
        )}
      </div>
    </Box>
  );
};
