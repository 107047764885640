import { FlashbarProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import { StatusType } from '../sas/SasInterfaces';
import { DogmaDetailsTabData, DogmaGraphData } from './DogmaInterface';

export type DogmaContextType = {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => void;

  dogmaGraphStatus: StatusType;
  setDogmaGraphStatus: (status: StatusType) => void;

  dogmaGraphData: DogmaGraphData;
  setDogmaGraphData: (graphData: DogmaGraphData) => void;

  dogmaDetailsTabStatus: StatusType;
  setDogmaDetailsTabStatus: (status: StatusType) => void;

  dogmaDetailsTabData: DogmaDetailsTabData;
  setDogmaDetailsTabData: (dogmaDetailsData: DogmaDetailsTabData) => void;
};

export const DogmaContext = createContext<DogmaContextType>({
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => {},

  dogmaGraphStatus: 'pending',
  setDogmaGraphStatus: (status: StatusType) => {},

  dogmaGraphData: {} as DogmaGraphData,
  setDogmaGraphData: (graphData: DogmaGraphData) => {},

  dogmaDetailsTabStatus: 'pending',
  setDogmaDetailsTabStatus: (status: StatusType) => {},

  dogmaDetailsTabData: {} as DogmaDetailsTabData,
  setDogmaDetailsTabData: (dogmaDetailsData: DogmaDetailsTabData) => {}
});

export const useDogmaContext = () => useContext(DogmaContext);
