import React from 'react';
import { Link, TableProps } from '@amzn/awsui-components-react';
import { PHONE_TOOL_URL, TICKETS_TOOL_URL } from 'src/constants/AppConstants';
import { getMinutesAgoDetails, getReadableDateTimeFormat } from 'src/utilities/DateTimeUtilities';
import { TicketsParsedDataItem } from './TicketsInterfaces';
import { getCellValueWithLink } from '../FinTechOpsCommonElements';

export const TICKET_DETAILS_VISIBLE_CONTENT = [
  'application_name',
  'manager_name',
  'assignee_name',
  'case_id',
  'short_description',
  'impact',
  'status',
  'ticket_age_in_minutes',
  'last_modified_by',
  'modified_date',
  'create_date_UserTimeZone'
];

export const TICKET_DETAILS_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TicketsParsedDataItem>[] = [
  {
    id: 'application_name',
    header: 'Application',
    sortingField: 'application_name',
    maxWidth: 180,
    cell: (item: any) => item.application_name
  },
  {
    id: 'case_id',
    header: 'Case ID',
    sortingField: 'case_id',
    maxWidth: 150,
    cell: (item: any) => (
      <>
        {item.case_id && (
          <div>
            <Link target="_blank" href={`${TICKETS_TOOL_URL}${item.case_id}`}>
              {item.sim_issue_alias}
            </Link>
          </div>
        )}
        {!item.case_id && <></>}
      </>
    )
  },
  {
    id: 'short_description',
    header: 'Description',
    sortingField: 'short_description',
    maxWidth: 180,
    cell: (item: any) => item.short_description
  },
  {
    id: 'impact',
    header: 'Severity',
    sortingField: 'impact',
    cell: (item: any) => item.impact
  },
  {
    id: 'status',
    header: 'Status',
    sortingField: 'status',
    cell: (item: any) => item.status
  },
  {
    id: 'ticket_age_in_minutes',
    header: 'Age',
    sortingField: 'ticket_age_in_minutes',
    cell: (item: any) => {
      return getMinutesAgoDetails(item.ticket_age_in_minutes);
    }
  },
  {
    id: 'assignee_name',
    header: 'Assigned To',
    sortingField: 'assignee_name',
    maxWidth: 200,
    cell: (item: TicketsParsedDataItem) => (
      <>
        {item.assignee_name && item.assignee_login ? (
          <Link target="_blank" href={`${PHONE_TOOL_URL}${item.assignee_login}`}>
            {item.assignee_name}
          </Link>
        ) : (
          <>{''}</>
        )}
      </>
    )
  },
  {
    id: 'manager_name',
    header: 'Manager',
    sortingField: 'manager_name',
    maxWidth: 150,
    cell: (item: TicketsParsedDataItem) => (
      <>
        {item.manager_name && item.manager_login ? (
          <Link target="_blank" href={`${PHONE_TOOL_URL}${item.manager_login}`}>
            {item.manager_name}
          </Link>
        ) : (
          <>{''}</>
        )}
      </>
    )
  },
  {
    id: 'last_modified_by',
    header: 'Last modified by',
    sortingField: 'last_modified_by',
    cell: (item: any) => {
      return item.last_modified_by;
    }
  },
  {
    id: 'modified_date',
    header: 'Last modified on',
    sortingField: 'modified_date',
    maxWidth: 270,
    cell: (item: any) => {
      return getReadableDateTimeFormat(item.modified_date);
    }
  },
  {
    id: 'create_date_UserTimeZone',
    header: 'Created on',
    sortingField: 'create_date_UserTimeZone',
    maxWidth: 270,
    cell: (item: any) => {
      return getReadableDateTimeFormat(item.create_date_UserTimeZone);
    }
  }
];

//All visible columns list for Resolved Ticket Details
export const TICKET_RESOLVED_DETAILS_VISIBLE_CONTENT = [
  'application_name',
  'manager_name',
  'assignee_name',
  'case_id',
  'short_description',
  'impact',
  'resolved_date',
  'resolved_ticket_age_in_seconds',
  'resolution_type',
  'modified_date',
  'create_date_UserTimeZone',
  'total_time_spent'
];

//Column Definition for Resolved Ticket Details
export const TICKET_RESOLVED_DETAILS_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TicketsParsedDataItem>[] = [
  {
    id: 'application_name',
    header: 'Application',
    sortingField: 'application_name',
    maxWidth: 180,
    cell: (item: any) => item.application_name
  },
  {
    id: 'case_id',
    header: 'Case ID',
    sortingField: 'case_id',
    maxWidth: 150,
    cell: (item: any) => (
      <>
        {item.case_id && getCellValueWithLink(TICKETS_TOOL_URL, item.case_id, item.sim_issue_alias)}
        {!item.case_id && <></>}
      </>
    )
  },
  {
    id: 'short_description',
    header: 'Description',
    sortingField: 'short_description',
    maxWidth: 180,
    cell: (item: any) => item.short_description
  },
  {
    id: 'impact',
    header: 'Severity',
    sortingField: 'impact',
    cell: (item: any) => item.impact
  },
  {
    id: 'resolved_date',
    header: 'Resolved Date',
    sortingField: 'resolved_date',
    maxWidth: 270,
    cell: (item: any) => {
      return getReadableDateTimeFormat(item.resolved_date);
    }
  },
  {
    id: 'resolved_ticket_age_in_seconds',
    header: 'Resolution Time',
    sortingField: 'resolved_ticket_age_in_seconds',
    cell: (item: any) => {
      return getMinutesAgoDetails(item.resolved_ticket_age_in_seconds, true);
    }
  },
  {
    id: 'resolution_type',
    header: 'Resolution Type',
    sortingField: 'resolution_type',
    cell: (item: any) => {
      return item.resolution_type;
    }
  },
  {
    id: 'total_time_spent',
    header: 'Time Spent(Hours)',
    sortingField: 'total_time_spent',
    maxWidth: 270,
    cell: (item: any) => {
      return item.total_time_spent;
    }
  },
  {
    id: 'assignee_name',
    header: 'Assigned To',
    sortingField: 'assignee_name',
    maxWidth: 200,
    cell: (item: TicketsParsedDataItem) => (
      <>{item.assignee_name && item.assignee_login ? getCellValueWithLink(PHONE_TOOL_URL, item.assignee_login, item.assignee_name) : <>{''}</>}</>
    )
  },
  {
    id: 'manager_name',
    header: 'Manager',
    sortingField: 'manager_name',
    maxWidth: 150,
    cell: (item: TicketsParsedDataItem) => (
      <>{item.manager_name && item.manager_login ? getCellValueWithLink(PHONE_TOOL_URL, item.manager_login, item.manager_name) : <>{''}</>}</>
    )
  },

  {
    id: 'create_date_UserTimeZone',
    header: 'Created on',
    sortingField: 'create_date_UserTimeZone',
    maxWidth: 270,
    cell: (item: any) => {
      return getReadableDateTimeFormat(item.create_date_UserTimeZone);
    }
  }
];
