import {
  AppLayout,
  Box,
  Flashbar,
  Header,
  Tabs,
  Grid,
  Container,
  SpaceBetween,
  Alert,
  Link,
  HelpPanel,
  Multiselect,
  Button,
  Table,
  SplitPanel,
  KeyValuePairs,
  ProgressBar,
  Pagination
} from '@amzn/awsui-components-react';
import { DFPHomePageSideNavigation } from '../../dfp-home-page/DFPHomePageSideNavigation';
import React, { useContext, useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { RiskRemediatoASRDetails, RiskRemediatorOpenRisks, RiskRemediatorVpcDetails } from './RiskRemediatorDetailsPage';
import { RiskRemediatorOverview } from './RiskRemediatorOverviewPage';
import { RiskRemediatorSelectionFilters } from 'src/components/fintech-ops/risk-remediator/RiskRemediatorSelectionFilters';
import Select from '@amzn/awsui-components-react/polaris/select';
import { RiskRemediatorRemediatedToggleRisks } from './RiskRemediatorDetailsPage';
import {
  DURATION_DATA,
  L8_MANAGER_DETAILS,
  RISK_TYPE_FLAG,
  RISK_REMEDIATOR_API_ERROR,
  USER_GUIDE_URL,
  BEST_PRACTICES_URL,
  RISK_TYPE,
  SPLIT_PANEL_CONFIG
} from 'src/components/fintech-ops/risk-remediator/constants';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';
import { fetchRiskRemediatorRisks, filterDatabyRiskCategory, filterDataByType } from 'src/components/fintech-ops/risk-remediator/utils';
import { CreateBarChartData, CreateLineChartData } from 'src/components/fintech-ops/FinTechOpsDataProcessingFunctions';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from 'src/components/fintech-ops/FinTechOpsCommonElements';
import { splitPanelI18nStrings } from 'src/i18n-strings';

export const RiskRemediatorMain = () => {
  const finOpsContext = useFinTechContext();
  const [managersList, selectManagerList] = useState<any>([{ label: L8_MANAGER_DETAILS.name, value: L8_MANAGER_DETAILS.login }]);
  const [managerSelector, selectManagerSelector] = useState<any>({ label: L8_MANAGER_DETAILS.name, value: L8_MANAGER_DETAILS.login });
  const [durationSelector, setDurationSelector] = useState<any>({ label: DURATION_DATA[0]['label'], value: DURATION_DATA[0]['value'] });
  const [riskTypeSelector, setRiskTypeSelector] = useState<any>([
    { label: RISK_TYPE[0]['options'][0]['label'], value: RISK_TYPE[0]['options'][0]['value'] },
    { label: RISK_TYPE[0]['options'][1]['label'], value: RISK_TYPE[0]['options'][1]['value'] }
  ]);
  const [flashMsgStatus, setFlashMsgStatus] = useState<any>([]);
  const [remediatedRiskData, setRemediatedRiskData] = useState<any>([]);
  const [devRemediatedRiskData, setDevRemediatedRiskData] = useState<any>([]);
  const [openRiskData, setOpenRiskData] = useState<any>([]);
  const [refreshedTime, setRefreshedTime] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isResetFilter, setIsResetFilter] = useState<boolean>(false);
  const [riskRemediatorData, setRiskRemediatorData] = useState<any>('');
  const [directsData, setDirectsData] = useState<string>('');
  const [toolsOpen, setToolsOpen] = useState<any>(false);
  const [asrData, setAsrData] = useState<any>([{}]);
  const [vpcData, setVpcData] = useState<any>([{}]);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [splitPanelOpen, setSplitOpen] = useState<any>(false);
  const [splitPanelSize, setSplitPanelSize] = useState<any>(SPLIT_PANEL_CONFIG.panelWidth);
  const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);
  const [panelHeader, setPanelHeader] = useState<any>(SPLIT_PANEL_CONFIG.EMPTY_PANEL_CONTENT.header);
  const [panelBody, setPanelBody] = useState<any>(SPLIT_PANEL_CONFIG.EMPTY_PANEL_CONTENT.body);
  const isSplitToggleEnabled = (isSplitPanelOpen: boolean, header: any, body: any) => {
    setSplitOpen(isSplitPanelOpen);
    setPanelBody(body);
    setPanelHeader(header);
    if (isSplitPanelOpen) {
      setToolsOpen(false);
    }
  };

  let props = {
    openRiskData: openRiskData,
    remediatedRiskData: remediatedRiskData,
    devRemediatedRiskData: devRemediatedRiskData,
    refreshedTime: refreshedTime,
    selectedManager: managerSelector,
    duration: durationSelector.value,
    isLoading: isLoading,
    riskType: riskTypeSelector,
    directsData: directsData,
    asrData: asrData,
    vpcData: vpcData,
    isSplitToggleEnabled: isSplitToggleEnabled
  };

  const flashBarErrorHandler = (errorMsg: string) => {
    setFlashMsgStatus([{ header: errorMsg, dismissible: true, type: 'error', onDismiss: () => setFlashMsgStatus([]) }]);
  };

  useEffect(() => {
    if (finOpsContext.finTechOpsRiskRemediatorStatus == 'finished') {
      selectManagerList(finOpsContext.finTechOpsRemediatorManagerData);
      setDirectsData(finOpsContext.finTechOpsRemediatorDirectsData[managerSelector.value] || '');
    }
  }, [managerSelector]);

  useEffect(() => {
    setLoading(true);
    if (finOpsContext.finTechOpsRiskRemediatorStatus == 'finished') {
      finOpsContext.setFinTechOpsRiskRemediatorStatus('loading');
      fetchRiskRemediatorRisks(durationSelector.value, L8_MANAGER_DETAILS.login).then((risks) => {
        setRefreshedTime(risks.refresh_time);
        setRiskRemediatorData(risks);
        finOpsContext.setFinTechOpsRiskRemediatorStatus('finished');
      });
    }
    setLoading(false);
  }, [durationSelector]);

  useEffect(() => {
    if (finOpsContext.finTechOpsRiskRemediatorStatus == 'error') {
      flashBarErrorHandler(RISK_REMEDIATOR_API_ERROR);
    }
  }, [finOpsContext.finTechOpsRiskRemediatorStatus]);

  useEffect(() => {
    if (finOpsContext.finTechOpsRiskRemediatorStatus == 'finished') {
      if (riskTypeSelector.length == 0) {
        setOpenRiskData([]);
        setRemediatedRiskData([]);
        setDevRemediatedRiskData([]);
        setAsrData([]);
        setVpcData([]);
      } else if (riskTypeSelector.length == 1) {
        setOpenRiskData(
          filterDatabyRiskCategory(
            filterDataByType(riskRemediatorData.details, RISK_TYPE_FLAG.OPEN, managerSelector.value),
            riskTypeSelector[0].value
          )
        );
        setRemediatedRiskData(
          filterDatabyRiskCategory(
            filterDataByType(riskRemediatorData.details, RISK_TYPE_FLAG['AUTO-REMEDIATED'], managerSelector.value),
            riskTypeSelector[0].value
          )
        );
        setDevRemediatedRiskData(
          filterDatabyRiskCategory(
            filterDataByType(riskRemediatorData.details, RISK_TYPE_FLAG['DEV-REMEDIATED'], managerSelector.value),
            riskTypeSelector[0].value
          )
        );
        if (finOpsContext.finTechOpsRemediatorAsrData.details !== undefined && finOpsContext.finTechOpsRemediatorAsrData.details != '') {
          setAsrData(
            filterDatabyRiskCategory(
              filterDataByType(finOpsContext.finTechOpsRemediatorAsrData.details, 'asr_details', managerSelector.value),
              riskTypeSelector[0].value
            )
          );
        }
        if (finOpsContext.finTechOpsRemediatorVpcData.details !== undefined && finOpsContext.finTechOpsRemediatorVpcData.details != '') {
          setVpcData(
            filterDatabyRiskCategory(
              filterDataByType(finOpsContext.finTechOpsRemediatorVpcData.details, 'vpc_details', managerSelector.value),
              riskTypeSelector[0].value
            )
          );
        }
      } else {
        setOpenRiskData(filterDataByType(riskRemediatorData.details, RISK_TYPE_FLAG.OPEN, managerSelector.value));
        setRemediatedRiskData(filterDataByType(riskRemediatorData.details, RISK_TYPE_FLAG['AUTO-REMEDIATED'], managerSelector.value));
        setDevRemediatedRiskData(filterDataByType(riskRemediatorData.details, RISK_TYPE_FLAG['DEV-REMEDIATED'], managerSelector.value));
        if (finOpsContext.finTechOpsRemediatorAsrData.details !== undefined && finOpsContext.finTechOpsRemediatorAsrData.details != '') {
          setAsrData(filterDataByType(finOpsContext.finTechOpsRemediatorAsrData.details, 'asr_details', managerSelector.value));
        }
        if (finOpsContext.finTechOpsRemediatorVpcData.details !== undefined && finOpsContext.finTechOpsRemediatorVpcData.details != '') {
          setVpcData(filterDataByType(finOpsContext.finTechOpsRemediatorVpcData.details, 'vpc_details', managerSelector.value));
        }
      }
    }
  }, [riskTypeSelector, managerSelector, riskRemediatorData]);

  useEffect(() => {
    if (isResetFilter && finOpsContext.finTechOpsRiskRemediatorStatus == 'finished') {
      selectManagerSelector({ label: L8_MANAGER_DETAILS.name, value: L8_MANAGER_DETAILS.login });
      setDurationSelector({ label: DURATION_DATA[0]['label'], value: DURATION_DATA[0]['value'] });
      setRiskTypeSelector([
        { label: RISK_TYPE[0]['options'][0]['label'], value: RISK_TYPE[0]['options'][0]['value'] },
        { label: RISK_TYPE[0]['options'][1]['label'], value: RISK_TYPE[0]['options'][1]['value'] }
      ]);
      setRiskRemediatorData(finOpsContext.finTechOpsRiskRemediatorData);
      if (finOpsContext.finTechOpsRemediatorAsrData.details !== undefined && finOpsContext.finTechOpsRemediatorAsrData.details != '') {
        setAsrData(JSON.parse(finOpsContext.finTechOpsRemediatorAsrData.details).asr_details);
      }
      if (finOpsContext.finTechOpsRemediatorVpcData.details !== undefined && finOpsContext.finTechOpsRemediatorVpcData.details != '') {
        setVpcData(JSON.parse(finOpsContext.finTechOpsRemediatorVpcData.details).vpc_details);
      }
    }
    setIsResetFilter(false);
  }, [isResetFilter]);

  useEffect(() => {
    if (finOpsContext.finTechOpsRiskRemediatorStatus == 'finished') {
      if (riskRemediatorData === '') {
        setRiskRemediatorData(finOpsContext.finTechOpsRiskRemediatorData);
      }
      selectManagerList(finOpsContext.finTechOpsRemediatorManagerData);
      setDirectsData(finOpsContext.finTechOpsRemediatorDirectsData[managerSelector.value].replace('"', '') || '');
      if (finOpsContext.finTechOpsRemediatorAsrData.details !== undefined && finOpsContext.finTechOpsRemediatorAsrData.details != '') {
        setAsrData(JSON.parse(finOpsContext.finTechOpsRemediatorAsrData.details).asr_details);
      }
      if (finOpsContext.finTechOpsRemediatorVpcData.details !== undefined && finOpsContext.finTechOpsRemediatorVpcData.details != '') {
        setVpcData(JSON.parse(finOpsContext.finTechOpsRemediatorVpcData.details).vpc_details);
      }
      setLoading(false);
    }
  }, [
    finOpsContext.finTechOpsRiskRemediatorStatus,
    finOpsContext.finTechOpsRiskRemediatorData.details,
    finOpsContext.finTechOpsRemediatorAsrData.details,
    finOpsContext.finTechOpsRemediatorVpcData.details
  ]);

  const selector = (
    <Select
      selectedOption={managerSelector}
      onChange={({ detail }) => selectManagerSelector(detail.selectedOption)}
      options={managersList}
      autoFocus
    />
  );
  const timeSelector = (
    <Select
      selectedOption={durationSelector}
      onChange={({ detail }) => setDurationSelector(detail.selectedOption)}
      options={DURATION_DATA}
      autoFocus
    />
  );
  const riskTypeMultiSelector = (
    <Multiselect
      selectedOptions={riskTypeSelector}
      onChange={({ detail }) => setRiskTypeSelector(detail.selectedOptions)}
      options={RISK_TYPE}
      placeholder={getMultiSelectPlaceHolderValue(riskTypeSelector, 'Risk Type', RISK_TYPE[0].options)}
      hideTokens
    />
  );

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      tools={
        <HelpPanel
          header={<h2>Risk Remediator</h2>}
          footer={
            <div>
              <Link external href={USER_GUIDE_URL}>
                User Guide
              </Link>
              <br />
              <Link external href={BEST_PRACTICES_URL}>
                DasFintech Best practices
              </Link>
            </div>
          }
        >
          <i>
            Risk Remediator is a tool to assess compliance status of AWS resources across all our AWS Accounts in real-time. Tool will remediate risks
            that doesnt need developer effort, else notify about the risks.
            <br />
            This tool does not fetch risks/metrics reported by other security portals and its not a replacement of currently used security tools like
            Shepherd,SAS,PolicyEngine,Dogma.
          </i>
        </HelpPanel>
      }
      notifications={<Flashbar items={flashMsgStatus} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: '/fintech-ops' },
            {
              text: 'Risk Remediator',
              href: ''
            }
          ]}
        />
      }
      onToolsChange={({ detail }) => {
        if (detail.open) {
          setSplitOpen(false);
        }
        setToolsOpen(detail.open);
      }}
      splitPanelPreferences={{ position: SPLIT_PANEL_CONFIG.position }}
      splitPanelOpen={splitPanelOpen}
      onSplitPanelToggle={({ detail }) => {
        if (detail.open) {
          setToolsOpen(false);
        }
        setSplitOpen(detail.open);
      }}
      splitPanelSize={splitPanelSize}
      onSplitPanelPreferencesChange={() => {}}
      onSplitPanelResize={(event) => setSplitPanelSize(Math.min(event.detail.size, SPLIT_PANEL_CONFIG.maxPanelWidth))}
      splitPanel={
        <SplitPanel i18nStrings={splitPanelI18nStrings} header={panelHeader}>
          {panelBody}
        </SplitPanel>
      }
      toolsOpen={toolsOpen}
      content={
        <>
          <SpaceBetween size="xxxs">
            <Container>
              <SpaceBetween size="xxxs">
                <SpaceBetween size="l" direction="horizontal" alignItems="center">
                  <Box variant="h2">Risk Remediator</Box>
                  <LastRefreshedAt lastRefreshedDateTime={refreshedTime} />
                  <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
                </SpaceBetween>

                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
                  <RiskRemediatorSelectionFilters selector={selector} header=<b>Manager</b> />
                  <RiskRemediatorSelectionFilters selector={timeSelector} header=<b>Duration</b> />
                  <RiskRemediatorSelectionFilters selector={riskTypeMultiSelector} header=<b>Risk Type</b> />
                </Grid>
              </SpaceBetween>
            </Container>
          </SpaceBetween>
          {finOpsContext.finTechOpsRiskRemediatorStatus == 'loading' && <LoadingSpinner />}
          {finOpsContext.finTechOpsRiskRemediatorStatus == 'error' && <ErrorContainer />}
          {finOpsContext.finTechOpsRiskRemediatorStatus == 'finished' && (
            <Tabs
              tabs={[
                {
                  label: 'Overview',
                  id: 'risk-remediator-OV',
                  content: <RiskRemediatorOverview {...props} />
                },
                {
                  label: 'Open Risks',
                  id: 'risk-remediator-OR',
                  content: (
                    <div>
                      <Container fitHeight>
                        <RiskRemediatorOpenRisks {...props} />
                      </Container>
                    </div>
                  )
                },
                {
                  label: 'Remediated Risks',
                  id: 'risk-remediator-RR',
                  content: (
                    <div>
                      <RiskRemediatorRemediatedToggleRisks {...props} />
                    </div>
                  )
                },
                {
                  label: 'ASR Details',
                  id: 'risk-remediator-ASR',
                  content: (
                    <div>
                      <Container fitHeight>
                        <RiskRemediatoASRDetails {...props} />
                      </Container>
                    </div>
                  )
                },
                {
                  label: 'VPC Details',
                  id: 'risk-remediator-VPC',
                  content: (
                    <div>
                      <Container fitHeight>
                        <RiskRemediatorVpcDetails {...props} />
                      </Container>
                    </div>
                  )
                }
              ]}
            />
          )}
        </>
      }
    />
  );
};
