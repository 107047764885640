import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Widget,
  addLinkSnippet,
  addResponseMessage,
  deleteMessages,
  renderCustomComponent,
  setQuickButtons,
  toggleInputDisabled,
  toggleMsgLoader
} from 'react-chat-widget-react-18';
import { useAuth } from 'src/components/context/AuthContextProvider';
import './styles.css';
import { createSession, sendMessage } from './lex-connections';
import { SpaceBetween, Button, Icon } from '@amzn/awsui-components-react';
import { logger } from 'src/logger';
import {
  errorFromBackLex,
  errorMsg,
  goodResponseMsg,
  incorrectResponseMsg,
  inputPlaceHolderMsg,
  properErrorFromLex,
  sessionId,
  ticketLink
} from './lex-constants';
import ENVIRONMENT_VARIABLES from 'src/constants/EnvironmentConstants';
import { LoadingStatus } from 'src/components/context/AppContextModels';

export const DasFinGenieApp = () => {
  const userAuthDetails = useAuth();
  const initialMsg = `Hello ${userAuthDetails.GivenName}, I am your Financials Genie!I can help you with P&L Finanicals information from Prophecy. Please ask any question related to Prophecy P&L deck by providing product type, Line item, Period, Scenario and Deck type.`;
  const [idMsg, setIdMsg] = useState<number>(0);
  const [showQuickButtons, setShowQuickButtons] = useState<boolean>(false);
  const [sessionID, setSessionID] = useState<string>('');
  const messageFilterLst = ['<sources>', '</sources>', 'das_fin_genie_table', 'Knowledgebase', 'knowledgebase', 'SQL queries'];
  const Stage: string = ENVIRONMENT_VARIABLES.env.Stage;
  const isProd: boolean = Stage === 'prod';

  useEffect(() => {
    console.info('Das-Fin-Genie App Environment check ' + Stage);
  }, []);

  useEffect(() => {
    if (!isProd && userAuthDetails.userAuthDataLoadingStatus === LoadingStatus.Completed) {
      console.info('Initializing Das-Fin-Genie App');
      postInitialMessage();
      const _sessionID = sessionId();
      setSessionID(_sessionID);
      createSession(_sessionID);
    }
  }, [userAuthDetails]);

  const postInitialMessage = () => {
    addResponseMessage(initialMsg, idMsg.toString());
  };

  const handleBotResponse = (botResponse: string) => {
    setIdMsg(idMsg + 1);
    switch (botResponse) {
      case errorMsg:
        addResponseMessage(botResponse);
        addLinkSnippet({
          title: 'Raise a ticket',
          link: ticketLink,
          target: '_blank'
        });
        break;
      case incorrectResponseMsg:
        addResponseMessage(botResponse);
        addLinkSnippet({
          title: 'Raise a ticket',
          link: ticketLink,
          target: '_blank'
        });
        break;
      case errorFromBackLex:
      case properErrorFromLex:
        addResponseMessage(properErrorFromLex);
        // setBotResponse('');
        addLinkSnippet({
          title: 'Raise a ticket',
          link: ticketLink,
          target: '_blank'
        });
        break;
      default:
        if (botResponse !== '' && botResponse != initialMsg) {
          addResponseMessage(botResponse);
          if (showQuickButtons) {
            renderCustomComponent(ButtonResponse, {});
            setShowQuickButtons(false);
          }
          logger.info(botResponse);
        }
        break;
    }
  };

  const handleNewUserMessage = async (newMessage: string) => {
    toggleInputDisabled();
    toggleMsgLoader();
    let messageContent;

    for (let i = 0; i < 2; i++) {
      messageContent = await contactLex(newMessage);
      if (messageContent != undefined && messageContent != errorMsg) {
        break;
      }
    }
    if (messageContent == undefined) {
      messageContent = errorMsg;
    }
    handleBotResponse(messageContent);
    setShowQuickButtons(true);
    toggleMsgLoader();
    toggleInputDisabled();
  };

  /**This is a function that will attempt to contact lex and recieve a response
  // There is a possibility that lex will time out and not return something, in that case this function
  // will return undefined. If this function returns undefined 2 times then we will set the error message and break
  // otherwise if we have recieved an answer we will return that to the screen**/
  const contactLex = async (request: string): Promise<string | undefined> => {
    let messageContent;
    try {
      const message = await sendMessage(request, sessionID);
      messageContent = message ? (message.messages && message.messages.length > 0 ? message.messages[0].content : undefined) : undefined;
    } catch (error) {
      logger.error('Error sending message:' + String(error));
      return errorMsg;
    }
    if (typeof messageContent === 'string') {
      return cleanString(messageContent, messageFilterLst);
    } else {
      return undefined;
    }
  };

  function cleanString(input: string, keywords: string[]): string {
    let cleanedString = input;
    for (const keyword of keywords) {
      cleanedString = cleanedString.replace(new RegExp(keyword, 'g'), '');
    }
    return cleanedString;
  }

  const ButtonResponse = () => {
    return (
      <>
        <SpaceBetween size="s" direction="horizontal">
          <Button variant="normal" onClick={() => handleQuickButtonClicked('goodResponse')}>
            <Icon name="thumbs-up" />
          </Button>
          <Button variant="normal" onClick={() => handleQuickButtonClicked('incorrect')}>
            <Icon name="thumbs-down" />
          </Button>
        </SpaceBetween>
      </>
    );
  };

  const handleQuickButtonClicked = (data: string) => {
    setQuickButtons([]);
    if (data == 'incorrect') {
      // setBotResponse(incorrectResponseMsg);
      handleBotResponse(incorrectResponseMsg);
    }
    if (data == 'error') {
      // setBotResponse(errorMsg);
      handleBotResponse(errorMsg);
    }
    if (data == 'goodResponse') {
      // setBotResponse(goodResponseMsg);
      handleBotResponse(goodResponseMsg);
    }
  };

  return (
    <>
      {!isProd && (
        <div draggable="true">
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            title="DaS-FinGenie"
            subtitle=" "
            senderPlaceHolder={inputPlaceHolderMsg}
            handleQuickButtonClicked={handleQuickButtonClicked}
            renderCustomComponent={ButtonResponse}
            resizable={false}
          />
        </div>
      )}
    </>
  );
};
