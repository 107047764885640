import {
  UserMetricsDataForGraphProcessing,
  UserMetricsParsedDetails,
  UserMetricsMasterFilters,
  UserMetricsParsedData,
  UserMetricsRawData,
  CalculatedUserMetricsGraphInfo,
  CalculatedUserMetricsDetailsTabInfo,
  Top3Apps,
  UserMetricsAggregatedViewsTable,
  MetricsMeasure,
  Metadata
} from './UserMetricsInterfaces';
import { findCommonElements3 } from '../fintech-ops-dashboard/FinTechOpsUtils';
import { ALL_APPLICATIONS, FilterDateFormat_MMM_YYYY, OEEmptyStringMappings, SKYNET_ALL } from '../FinTechOpsConstants';
import { getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps, sortedDateArrayMMMYYYYFormat } from 'src/utilities/DateTimeUtilities';
import moment from 'moment';
import React from 'react';
import { DashboardFilters, UserMetricsDashboardItems } from '../fintech-ops-dashboard/FinTechOpsDataInterfaces';
import { OptionItem, sortDistinctMonths_MM_YYYY } from '../FinTechOpsDataProcessingFunctions';
import { TableProps } from '@amzn/awsui-components-react';
import {
  USER_METRICS_APPLICATION_USER_VIEW_VISIBLE_CONTENT,
  USER_METRICS_APPLICATION_USER_VIEW__COLUMN_DEFINITIONS,
  USER_METRICS_FOOTPRINT_COLUMN_DEFINITIONS,
  USER_METRICS_FOOTPRINT_VISIBLE_CONTENT,
  USER_METRICS_SUB_APPLICATION_FOOTPRINT_COLUMN_DEFINITIONS,
  USER_METRICS_SUB_APPLICATION_FOOTPRINT_VISIBLE_CONTENT,
  USER_METRICS_SUB_APPLICATION_USER_VIEW_VISIBLE_CONTENT,
  USER_METRICS_SUB_APPLICATION_USER_VIEW__COLUMN_DEFINITIONS
} from './UserMetricsTableFilterConfig';
import { numberFormatter, aggregateMetricsData, getSortedArrayWithNumber, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import {
  APPLICATION_FOOTPRINT_COLUMN_LISTS,
  APPLICATION_FOOTPRINT_MEASURE_COLUMN_LISTS,
  SUB_APPLICATION_FOOTPRINT_COLUMN_LISTS,
  SUB_APPLICATION_FOOTPRINT_MEASURE_COLUMN_LISTS,
  APPLICATION_USER_VIEW_COLUMN_LISTS,
  APPLICATION_USER_VIEW_MEASURE_COLUMN_LISTS,
  SUB_APPLICATION_USER_VIEW_COLUMN_LISTS,
  SUB_APPLICATION_USER_VIEW_MEASURE_COLUMN_LISTS,
  APPLICATION,
  USER_METRICS_AGG_APPLICATION_BY_USERS_COLUMN_LIST,
  USER_METRICS_AGG_APPLICATION_BY_USERS_MEASURE_COLUMN_LIST,
  USER_METRICS_DASHBOARD_COLUMN_LIST,
  USER_METRICS_DASHBOARD_MEASURE_COLUMN_LIST,
  USER_METRICS_TOP_5_APPLICATION_BY_MONTH_COLUMN_LIST,
  USER_METRICS_TOP_5_APPLICATION_BY_MONTH_MEASURE_COLUMN_LIST,
  USER_METRICS_TOTAL_UNIQUE_USERS_BY_MONTH_COLUMN_LIST,
  USER_METRICS_TOTAL_UNIQUE_USERS_BY_MONTH_MEASURE_COLUMN_LIST,
  USER_METRICS_AGG_SUB_APPLICATION_COLUMN_LIST
} from './UserMetricsConstants';

export function filterDataByCreateDates(rawData: UserMetricsRawData[]): UserMetricsRawData[] {
  const currentDate = moment();
  const lastSixMonthsIncludingCurrentMonth = getLastFewMonthsIncludingCurrentMonth_AllowedByFinOps();

  const included: UserMetricsRawData[] = [];
  const excluded: UserMetricsRawData[] = [];

  rawData.forEach((item) => {
    const createDate = moment(item.log_month, 'YYYY-MM-DD');
    if (createDate.isBetween(lastSixMonthsIncludingCurrentMonth, currentDate, 'day', '[]')) {
      included.push(item);
    } else {
      excluded.push(item);
    }
  });

  // For debugging
  // //console.log('Excluded Records:', JSON.stringify(excluded));
  // const uniqueMonthsSet = new Set(included.map((item) => moment(item.create_date).format('YYYY-MM')));
  // const uniqueMonths = Array.from(uniqueMonthsSet).sort();
  // ('Included uniqueMonths ', JSON.stringify(uniqueMonths));

  return included;
}

export const returnUsefulRawUserMetricsData = (userMetricsRawDetails: UserMetricsRawData[], lastUpdatedAt: Metadata): UserMetricsParsedDetails => {
  const returnItems: UserMetricsParsedData[] = [];

  const last6MonthData = filterDataByCreateDates(userMetricsRawDetails);

  last6MonthData.forEach((item: UserMetricsRawData) => {
    const logMonth_YYYY_MM_DD = moment(item.log_month, 'YYYY-MM-DD');

    returnItems.push({
      team_name: item.team_name || OEEmptyStringMappings.Unassigned_Team,

      manager_name_hierarchy_concat: item.supervisor_name_hierarchy
        ? item.supervisor_name_hierarchy.split(':')
        : [OEEmptyStringMappings.Unassigned_Manager],
      manager_login_hierarchy_concat: item.supervisor_login_name_hierarchy
        ? item.supervisor_login_name_hierarchy.split(':')
        : [OEEmptyStringMappings.Unassigned_Login],

      manager_name: item.supervisor_name_hierarchy ? item.supervisor_name_hierarchy.split(':')[0] : OEEmptyStringMappings.Unassigned_Manager,
      manage_login: item.supervisor_login_name_hierarchy
        ? item.supervisor_login_name_hierarchy.split(':')[0]
        : OEEmptyStringMappings.Unassigned_Login,

      application: item.application, // item.application_type == SKYNET ? SKYNET_ALL : item.application,
      application_type: item.application_type,

      distinct_users: item.distinct_users,
      log_month: item.log_month,
      create_date_formatted: logMonth_YYYY_MM_DD.format(FilterDateFormat_MMM_YYYY),
      created_on_month: logMonth_YYYY_MM_DD.month() + 1, // Add 1 to get one-based month number

      measure_value: parseInt(item.measure_value?.toString()),
      user_login: item.user_login,
      active_since: item.active_since,
      last_active: item.last_active
    } as UserMetricsParsedData);
  });

  return {
    metadata: lastUpdatedAt,
    data: returnItems
  } as UserMetricsParsedDetails;
};

export const applyFiltersUserMetrics = (
  items: UserMetricsParsedData[],
  filters: UserMetricsMasterFilters
): [UserMetricsParsedData[], UserMetricsParsedData[]] => {
  const filteredWithoutMonthData: UserMetricsParsedData[] = items.filter((item: UserMetricsParsedData) => {
    return (
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.team.includes(item.team_name) &&
      filters.application_type.includes(item.application_type) &&
      filters.application_name.includes(item.application) &&
      filters.user_login.includes(item.user_login)
    );
  });

  const filteredWithMonthData: UserMetricsParsedData[] = filteredWithoutMonthData.filter((item) => {
    return filters.month.includes(moment(item.log_month).format(FilterDateFormat_MMM_YYYY));
  });
  return [filteredWithoutMonthData, filteredWithMonthData];
};

export const applyDashboardFiltersUserMetrics = (items: UserMetricsParsedData[], filters: DashboardFilters): UserMetricsParsedData[] => {
  return items.filter((item: UserMetricsParsedData) => {
    return (
      filters.team_name.includes(item.team_name) &&
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      item.application !== ALL_APPLICATIONS
    );
  });
};

export const getDashboardFiltersFromUserMetrics = (items: UserMetricsParsedData[]): DashboardFilters => {
  const dashboardTicketFilters: DashboardFilters = {
    team_name: [],
    managers: [],
    assignees: []
  };

  items.forEach((item: UserMetricsParsedData) => {
    if (!dashboardTicketFilters.team_name.includes(item.team_name)) {
      dashboardTicketFilters.team_name.push(item.team_name);
    }

    if (!dashboardTicketFilters.managers.includes(item.manager_name)) {
      dashboardTicketFilters.managers.push(item.manager_name);
    }
  });
  return dashboardTicketFilters;
};

export const createUserMetricsMasterFilterData = (items: UserMetricsParsedData[]): UserMetricsMasterFilters => {
  const userMetricsMasterFilterData: UserMetricsMasterFilters = {
    managers: [],
    team: [],
    application_type: [],
    application_name: [],
    month: [],
    user_login: []
  };

  items.forEach((item: UserMetricsParsedData) => {
    if (!userMetricsMasterFilterData.managers.includes(item.manager_name)) {
      userMetricsMasterFilterData.managers.push(item.manager_name);
    }
    if (!userMetricsMasterFilterData.team.includes(item.team_name)) {
      userMetricsMasterFilterData.team.push(item.team_name);
    }
    if (!userMetricsMasterFilterData.application_type.includes(item.application_type)) {
      userMetricsMasterFilterData.application_type.push(item.application_type);
    }
    if (!userMetricsMasterFilterData.application_name.includes(item.application) && item.application !== ALL_APPLICATIONS) {
      userMetricsMasterFilterData.application_name.push(item.application);
    }
    if (!userMetricsMasterFilterData.month.includes(item.create_date_formatted)) {
      userMetricsMasterFilterData.month.push(item.create_date_formatted);
    }
    if (!userMetricsMasterFilterData.user_login.includes(item.user_login)) {
      userMetricsMasterFilterData.user_login.push(item.user_login);
    }
  });
  return userMetricsMasterFilterData;
};

export function returnSelectedAllAppNameCustom(items: string[]): OptionItem[] {
  const itemsOptions: OptionItem[] = [];
  let valueForSelector = 1;
  if (items != undefined && items.length > 0) {
    items.forEach((element) => {
      if (element != SKYNET_ALL && element != 'common') {
        itemsOptions.push({ label: element, value: element });
      }
      valueForSelector++;
    });
  }
  return itemsOptions;
}

// Calculations for FinTechOps DashBoard User Metrics section
export const createOpsUserMetricsData = (appliedFiltersData: UserMetricsParsedData[]): UserMetricsDashboardItems => {
  const considerableData = appliedFiltersData.filter((item: UserMetricsParsedData) => {
    return item.application !== ALL_APPLICATIONS && item.application !== 'common' && !item.application.toLowerCase().includes('skynet');
  });

  const distinctApplications: string[] = [];
  const distinctMonths_YYYY_MM_DD: string[] = [];
  const currentMonth_YYYY_MM_DD = moment().startOf('month').format('YYYY-MM-DD');

  let appplicationViewCount = 0;

  let currentMonthAppWithMoreUniqueUsersCount = 0;
  let currentMonthAppWithMoreUniqueUsers = '';

  let currentMonthMostViewed = 0;
  let currentMonthMostViewedApplication = '';

  const aggregatedData = aggregateMetricsData(USER_METRICS_DASHBOARD_COLUMN_LIST, considerableData, USER_METRICS_DASHBOARD_MEASURE_COLUMN_LIST) || [];

  aggregatedData.forEach((item: UserMetricsParsedData) => {
    //changed logic to application_type wise top application and application count
    const { application_type, log_month } = item;
    appplicationViewCount += item.measure_value;

    if (!distinctApplications.includes(application_type)) {
      distinctApplications.push(application_type);
    }

    if (!distinctMonths_YYYY_MM_DD.includes(log_month)) {
      distinctMonths_YYYY_MM_DD.push(log_month);
    }

    if (item.log_month === currentMonth_YYYY_MM_DD && item.user_login_count_distinct > currentMonthAppWithMoreUniqueUsersCount) {
      currentMonthAppWithMoreUniqueUsersCount = item.user_login_count_distinct;
      currentMonthAppWithMoreUniqueUsers = item.application_type;
    }

    if (item.log_month === currentMonth_YYYY_MM_DD && item.measure_value > currentMonthMostViewed) {
      currentMonthMostViewed = item.measure_value;
      currentMonthMostViewedApplication = item.application_type;
    }
  });

  let noOfDays = 0;
  distinctMonths_YYYY_MM_DD.forEach((eachDistinctMonth: string) => {
    if (eachDistinctMonth !== currentMonth_YYYY_MM_DD) {
      const daysInThisMOnth = moment(eachDistinctMonth, 'YYYY-MM-DD').daysInMonth();
      noOfDays += daysInThisMOnth;
    } else {
      const daysInThisMOnth = moment(eachDistinctMonth, 'YYYY-MM-DD').daysInMonth() - moment().date();
      noOfDays += daysInThisMOnth;
    }
  });

  const avgViewCountPerApplication = appplicationViewCount / (noOfDays * distinctApplications?.length);

  return {
    total_applications: distinctApplications?.length || 0,
    avg_view_per_application: isNaN(avgViewCountPerApplication) ? '0' : `${Number(avgViewCountPerApplication).toFixed(0)}`, // Avergae viewcount of Application excluding "All Applications" "Skynet" and "common" divided by total number of days  `${Number(avgViewCountPerApplication).toFixed(0)}`, // Avergae viewcount of Application excluding "All Applications" "Skynet" and "common" divided by total number of days
    app_most_unique_users: currentMonthAppWithMoreUniqueUsers,
    most_viewed_application: currentMonthMostViewedApplication
  };
};

export const createUserMetricsGraphDataV2 = (
  actualUserMetricsParsedData: UserMetricsParsedData[],
  filters: UserMetricsMasterFilters
): CalculatedUserMetricsGraphInfo => {
  const totalAppViewByTeam: UserMetricsDataForGraphProcessing[] = getTotalApplicationViewByTeam(actualUserMetricsParsedData, filters);

  const top5AppViwership: UserMetricsDataForGraphProcessing[] = getTop5ApplicationsByMonth(actualUserMetricsParsedData, filters);

  const uniqueUsersByAppAndMonth: UserMetricsDataForGraphProcessing[] = getTotalUniqueUsersByApplicationAndMonth(
    actualUserMetricsParsedData,
    filters
  );
  const totalViewByApplicationType: UserMetricsDataForGraphProcessing[] = getTotalViewsByApplicationType(actualUserMetricsParsedData, filters);

  return {
    appViewerShipTeamGraph: totalAppViewByTeam,
    top5AppViewershipGraph: top5AppViwership,

    uniqueUsersByAppGraph: uniqueUsersByAppAndMonth,
    totalAppViewsGraph: totalViewByApplicationType
  };
};

// Total Application Views by Team
function getTotalApplicationViewByTeam(
  actualUserMetricsParsedData: UserMetricsParsedData[],
  userMetricsMasterFilters: UserMetricsMasterFilters
): UserMetricsDataForGraphProcessing[] {
  const result: UserMetricsDataForGraphProcessing[] = [];

  const excludingAllApplications = actualUserMetricsParsedData.filter((item: UserMetricsParsedData) => item.application !== ALL_APPLICATIONS);
  const filteredData = excludingAllApplications.filter((item: UserMetricsParsedData) => {
    return (
      findCommonElements3(userMetricsMasterFilters.managers, item.manager_name_hierarchy_concat) &&
      userMetricsMasterFilters.team.includes(item.team_name) &&
      userMetricsMasterFilters.application_type.includes(item.application_type) &&
      userMetricsMasterFilters.application_name.includes(item.application) &&
      userMetricsMasterFilters.month.includes(moment(item.log_month).format(FilterDateFormat_MMM_YYYY)) &&
      userMetricsMasterFilters.user_login.includes(item.user_login)
    );
  });

  const dataMap = new Map<string, number>();

  for (const item of filteredData) {
    const team = item.team_name;
    const month = item.log_month.substring(0, 7); // Extract YYYY-MM from log_month (eg: "2023-04-01")

    const key = `${team}_${month}`;
    const count = item.measure_value;

    if (dataMap.has(key)) {
      dataMap.set(key, dataMap.get(key)! + count);
    } else {
      dataMap.set(key, count);
    }
  }

  dataMap.forEach((count, key) => {
    const [team, month] = key.split('_');
    result.push({ title: team, category: month.replace('-', ''), count });
  });

  return getSortedArrayWithNumber(result, 'count', 'desc');
}

// Top 5 Application - Unique Users
function getTop5ApplicationsByMonth(
  actualUserMetricsParsedData: UserMetricsParsedData[],
  userMetricsMasterFilters: UserMetricsMasterFilters
): UserMetricsDataForGraphProcessing[] {
  const result: UserMetricsDataForGraphProcessing[] = [];
  const excludingAllApplications = actualUserMetricsParsedData.filter((item: UserMetricsParsedData) => item.application !== ALL_APPLICATIONS);
  const filteredData = excludingAllApplications.filter((item: UserMetricsParsedData) => {
    return (
      findCommonElements3(userMetricsMasterFilters.managers, item.manager_name_hierarchy_concat) &&
      userMetricsMasterFilters.team.includes(item.team_name) &&
      userMetricsMasterFilters.application_type.includes(item.application_type) &&
      userMetricsMasterFilters.application_name.includes(item.application) &&
      userMetricsMasterFilters.month.includes(moment(item.log_month).format(FilterDateFormat_MMM_YYYY)) &&
      userMetricsMasterFilters.user_login.includes(item.user_login)
    );
  });
  const aggregatedData =
    aggregateMetricsData(
      USER_METRICS_TOP_5_APPLICATION_BY_MONTH_COLUMN_LIST,
      filteredData,
      USER_METRICS_TOP_5_APPLICATION_BY_MONTH_MEASURE_COLUMN_LIST
    ) || [];
  const dataMap = new Map<string, Map<string, number>>();
  // for (const item of filteredData) {
  for (const item of aggregatedData) {
    const month = item.log_month.substring(0, 7); // Extract YYYY-MM from log_month (eg: "2023-04-01")
    const application = item.application;
    const distinctUsers = item.user_login_count_distinct;

    if (dataMap.has(month)) {
      const monthMap = dataMap.get(month)!;
      if (monthMap.has(application)) {
        monthMap.set(application, monthMap.get(application)! + distinctUsers);
      } else {
        monthMap.set(application, distinctUsers);
      }
    } else {
      const monthMap = new Map<string, number>();
      monthMap.set(application, distinctUsers);
      dataMap.set(month, monthMap);
    }
  }
  // Iterate over the map and get the top 5 applications for each month
  dataMap.forEach((monthMap, month) => {
    const sortedApplications = [...monthMap.entries()].sort((a, b) => b[1] - a[1]).slice(0, 5);

    for (const [application, count] of sortedApplications) {
      result.push({
        title: application,
        category: month,
        count: count
      });
    }
  });

  return getSortedArrayWithNumber(result, 'count', 'desc');
}

// Total Unique Users by Application Type
// include only All_Apps
function getTotalUniqueUsersByApplicationAndMonth(
  actualUserMetricsParsedData: UserMetricsParsedData[],
  filters: UserMetricsMasterFilters
): UserMetricsDataForGraphProcessing[] {
  const filteredData = actualUserMetricsParsedData.filter((item: UserMetricsParsedData) => {
    return (
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.team.includes(item.team_name) &&
      filters.application_type.includes(item.application_type) &&
      item.application === ALL_APPLICATIONS &&
      filters.month.includes(moment(item.log_month).format(FilterDateFormat_MMM_YYYY)) &&
      filters.user_login.includes(item.user_login)
    );
  });

  const result: UserMetricsDataForGraphProcessing[] = [];

  const dataMap = new Map<string, Map<string, number>>();

  const aggregatedData =
    aggregateMetricsData(
      USER_METRICS_TOTAL_UNIQUE_USERS_BY_MONTH_COLUMN_LIST,
      filteredData,
      USER_METRICS_TOTAL_UNIQUE_USERS_BY_MONTH_MEASURE_COLUMN_LIST
    ) || [];

  for (const item of aggregatedData) {
    const month = item.log_month.substring(0, 7); // Extract YYYY-MM from log_month (eg: "2023-04-01")
    const applicationType = item.application_type;
    const distinctUsers = item.user_login_count_distinct;

    if (dataMap.has(month)) {
      const monthMap = dataMap.get(month)!;
      if (monthMap.has(applicationType)) {
        monthMap.set(applicationType, monthMap.get(applicationType)! + distinctUsers);
      } else {
        monthMap.set(applicationType, distinctUsers);
      }
    } else {
      const monthMap = new Map<string, number>();
      monthMap.set(applicationType, distinctUsers);
      dataMap.set(month, monthMap);
    }
  }

  // Iterate over the map and get the total unique user count for each application type in each month
  dataMap.forEach((monthMap, month) => {
    monthMap.forEach((count, applicationType) => {
      result.push({
        title: applicationType,
        category: month,
        count
      });
    });
  });

  return getSortedArrayWithNumber(result, 'count', 'desc');
}

// Total Views by Application Type
function getTotalViewsByApplicationType(
  actualUserMetricsParsedData: UserMetricsParsedData[],
  filters: UserMetricsMasterFilters
): UserMetricsDataForGraphProcessing[] {
  const filteredData = actualUserMetricsParsedData.filter((item: UserMetricsParsedData) => {
    return (
      findCommonElements3(filters.managers, item.manager_name_hierarchy_concat) &&
      filters.team.includes(item.team_name) &&
      filters.application_type.includes(item.application_type) &&
      item.application === ALL_APPLICATIONS &&
      filters.month.includes(moment(item.log_month).format(FilterDateFormat_MMM_YYYY)) &&
      filters.user_login.includes(item.user_login)
    );
  });

  const result: UserMetricsDataForGraphProcessing[] = [];

  const dataMap = new Map<string, Map<string, number>>();

  for (const item of filteredData) {
    const month = item.log_month.substring(0, 7); // Extract YYYY-MM from log_month (eg: "2023-04-01")
    const applicationType = item.application_type;
    const measureValue = item.measure_value;

    if (dataMap.has(month)) {
      const monthMap = dataMap.get(month)!;
      if (monthMap.has(applicationType)) {
        monthMap.set(applicationType, monthMap.get(applicationType)! + measureValue);
      } else {
        monthMap.set(applicationType, measureValue);
      }
    } else {
      const monthMap = new Map<string, number>();
      monthMap.set(applicationType, measureValue);
      dataMap.set(month, monthMap);
    }
  }

  // Iterate over the map and get the total unique user count for each application type in each month
  dataMap.forEach((monthMap, month) => {
    monthMap.forEach((count, applicationType) => {
      result.push({
        title: applicationType,
        category: month,
        count
      });
    });
  });

  return getSortedArrayWithNumber(result, 'count', 'desc');
}

//Function to get user metrics details tab data
export const createUserMetricsDetailsTabData = (
  appliedFiltersData: UserMetricsParsedData[],
  filteredWithoutMonthData: UserMetricsParsedData[],
  month: string[]
): CalculatedUserMetricsDetailsTabInfo => {
  const considerableFilterData: UserMetricsParsedData[] = appliedFiltersData.filter((item) => item.application !== ALL_APPLICATIONS);

  const userMetricsDetailsTabInfo: CalculatedUserMetricsDetailsTabInfo = {
    applicationFootprintContainer: {
      top3Apps: [],
      total_views: 0,
      currentMonth: ''
    },
    aggregatedViewsTable: {
      visibleColumns: [],
      columnData: [],
      rowData: []
    },
    aggregatedUsersTable: {
      visibleColumns: [],
      columnData: [],
      rowData: []
    },
    aggregatedSubApplicationViewsTableData: {
      visibleColumns: [],
      columnData: [],
      rowData: []
    },
    aggregatedSubApplicationUniqueUsersTableData: {
      visibleColumns: [],
      columnData: [],
      rowData: []
    },
    applicationFootprintTable: {
      visibleColumns: [],
      columnData: [],
      rowData: []
    },
    subApplicationFootprintTable: {
      visibleColumns: [],
      columnData: [],
      rowData: []
    },
    applicationUserViewTable: {
      visibleColumns: USER_METRICS_APPLICATION_USER_VIEW_VISIBLE_CONTENT,
      columnData: USER_METRICS_APPLICATION_USER_VIEW__COLUMN_DEFINITIONS,
      rowData: []
    },
    subApplicationUserViewTable: {
      visibleColumns: USER_METRICS_SUB_APPLICATION_USER_VIEW_VISIBLE_CONTENT,
      columnData: USER_METRICS_SUB_APPLICATION_USER_VIEW__COLUMN_DEFINITIONS,
      rowData: []
    }
  };

  let totalViewCount = 0;

  considerableFilterData.forEach((item) => {
    totalViewCount += item.measure_value;
  });

  //Aggregate the sub application for each month with views
  const aggregatedSubApplicationViewsTableData = getAggregatedUserMetricsBySubApplication(
    considerableFilterData,
    USER_METRICS_AGG_SUB_APPLICATION_COLUMN_LIST,
    SUB_APPLICATION_USER_VIEW_MEASURE_COLUMN_LISTS
  );
  //Aggregate the sub application for each month with unique users
  const aggregatedSubApplicationUniqueUsersTableData = getAggregatedUserMetricsBySubApplication(
    considerableFilterData,
    USER_METRICS_AGG_SUB_APPLICATION_COLUMN_LIST,
    USER_METRICS_TOP_5_APPLICATION_BY_MONTH_MEASURE_COLUMN_LIST
  );
  const sortedMonths = sortedDateArrayMMMYYYYFormat(getUniqueFieldValues(considerableFilterData, 'create_date_formatted'));
  const currentMonthValue = moment().format(FilterDateFormat_MMM_YYYY);
  // consider current month only if its the only value selected in filter
  const currentMonth = sortedMonths.length > 1 && sortedMonths.includes(currentMonthValue) ? sortedMonths[1] : sortedMonths[0];
  const top3Applications = getTop3ApplicationsInCurrentMonth(considerableFilterData, filteredWithoutMonthData);
  const aggregatedUserMetricsByApplicationWithUniqueUsers = getAggregatedUserMetricsByApplicationWithUniqueUsers(considerableFilterData);
  const aggregatedUserMetricsByApplicationWithUserCount = getAggregatedUserMetricsByApplicationWithUserCount(considerableFilterData);
  const applicationFootprintTableData = aggregateMetricsData(
    APPLICATION_FOOTPRINT_COLUMN_LISTS,
    appliedFiltersData,
    APPLICATION_FOOTPRINT_MEASURE_COLUMN_LISTS
  );
  const appFootprintTable = applicationFootprintTableData
    ? getAppFootPrintTableDetails(applicationFootprintTableData, month, 'application')
    : userMetricsDetailsTabInfo.applicationFootprintTable;

  const subApplicationFootprintTableData = aggregateMetricsData(
    SUB_APPLICATION_FOOTPRINT_COLUMN_LISTS,
    appliedFiltersData,
    SUB_APPLICATION_FOOTPRINT_MEASURE_COLUMN_LISTS
  );
  const subAppFootprintTable = subApplicationFootprintTableData
    ? getAppFootPrintTableDetails(subApplicationFootprintTableData, month, 'subApplication')
    : userMetricsDetailsTabInfo.subApplicationFootprintTable;

  const applicationUserViewTableData = aggregateMetricsData(
    APPLICATION_USER_VIEW_COLUMN_LISTS,
    appliedFiltersData,
    APPLICATION_USER_VIEW_MEASURE_COLUMN_LISTS
  );
  userMetricsDetailsTabInfo.applicationUserViewTable.rowData = applicationUserViewTableData ? applicationUserViewTableData : [];
  const subApplicationUserViewTableData = aggregateMetricsData(
    SUB_APPLICATION_USER_VIEW_COLUMN_LISTS,
    appliedFiltersData,
    SUB_APPLICATION_USER_VIEW_MEASURE_COLUMN_LISTS
  );
  userMetricsDetailsTabInfo.subApplicationUserViewTable.rowData = subApplicationUserViewTableData ? subApplicationUserViewTableData : [];
  return {
    ...userMetricsDetailsTabInfo,
    applicationFootprintContainer: {
      ...userMetricsDetailsTabInfo.applicationFootprintContainer,
      total_views: totalViewCount,
      top3Apps: top3Applications,
      currentMonth: currentMonth
    },
    aggregatedViewsTable: aggregatedUserMetricsByApplicationWithUserCount,
    aggregatedUsersTable: aggregatedUserMetricsByApplicationWithUniqueUsers,
    aggregatedSubApplicationViewsTableData: aggregatedSubApplicationViewsTableData,
    aggregatedSubApplicationUniqueUsersTableData: aggregatedSubApplicationUniqueUsersTableData,
    applicationFootprintTable: appFootprintTable,
    subApplicationFootprintTable: subAppFootprintTable,
    applicationUserViewTable: userMetricsDetailsTabInfo.applicationUserViewTable,
    subApplicationUserViewTable: userMetricsDetailsTabInfo.subApplicationUserViewTable
  };
};

const getTop3ApplicationsInCurrentMonth = (
  userMetricsData: UserMetricsParsedData[],
  actualUserMetricsParsedData: UserMetricsParsedData[]
): Top3Apps[] => {
  const result: Top3Apps[] = [];
  //initializing maxMonth & previousMonth to default value to handle empty userMetricsData
  let maxMonth = moment().month();
  let previousMonth = moment().month();
  if (userMetricsData.length > 0) {
    const sortedMonths = sortedDateArrayMMMYYYYFormat(getUniqueFieldValues(userMetricsData, 'create_date_formatted'));
    const currentMonthValue = moment().format(FilterDateFormat_MMM_YYYY);
    // consider the 0th index month only if its the only value selected in filter
    const maxMonthValue = sortedMonths.length > 1 && sortedMonths.includes(currentMonthValue) ? sortedMonths[1] : sortedMonths[0];
    maxMonth = moment(maxMonthValue, FilterDateFormat_MMM_YYYY).month() + 1;
    previousMonth = moment(maxMonthValue, FilterDateFormat_MMM_YYYY).subtract(1, 'months').month() + 1;
  }

  const dataMap = new Map<string, number>();

  const currentMonthFilteredData = userMetricsData.filter((item) => item.created_on_month === maxMonth);

  for (const item of currentMonthFilteredData) {
    const application = item.application;
    const count = item.measure_value;
    if (dataMap.has(application)) {
      dataMap.set(application, dataMap.get(application)! + count);
    } else {
      dataMap.set(application, count);
    }
  }

  const sortedApplications = [...dataMap.entries()].sort((a, b) => b[1] - a[1]).slice(0, 3);
  for (const [application, count] of sortedApplications) {
    result.push({
      application: application,
      views: count,
      MoM: getMoMForThisAppInPreviousMonth(application, count, previousMonth, actualUserMetricsParsedData)
    } as Top3Apps);
  }
  return result;
};

const getMoMForThisAppInPreviousMonth = (
  application: string,
  currentMonthCount: number,
  previousMontnNumber: number,
  userMetricsData: UserMetricsParsedData[]
) => {
  const currentMonthFilteredData = userMetricsData.filter(
    (item) => item.created_on_month === previousMontnNumber && item.application === application
  );

  let previousMonthCount = 0;
  currentMonthFilteredData.forEach((item) => {
    previousMonthCount = previousMonthCount + item.measure_value;
  });

  return currentMonthCount - previousMonthCount;
};

const getAggregatedUserMetricsByApplicationWithUniqueUsers = (userMetricsData: UserMetricsParsedData[]): UserMetricsAggregatedViewsTable => {
  const aggregatedData: Record<string, any> = {};

  const aggregatedDataValue =
    aggregateMetricsData(
      USER_METRICS_AGG_APPLICATION_BY_USERS_COLUMN_LIST,
      userMetricsData,
      USER_METRICS_AGG_APPLICATION_BY_USERS_MEASURE_COLUMN_LIST
    ) || [];

  let distinctMonths: string[] = [];

  for (const entry of aggregatedDataValue) {
    const { application_type, create_date_formatted, user_login_count_distinct } = entry;

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(create_date_formatted)) {
      distinctMonths.push(create_date_formatted);
    }

    const key = `${application_type}`;

    if (aggregatedData[key]) {
      aggregatedData[key][create_date_formatted] = (aggregatedData[key][create_date_formatted] || 0) + user_login_count_distinct;
    } else {
      // Otherwise, create a new entry with userCount for the corresponding month
      aggregatedData[key] = {
        application_type,
        [create_date_formatted]: user_login_count_distinct
      };
    }
  }

  // Convert the aggregation object into an array of aggregated data
  const aggregatedArray = Object.values(aggregatedData);

  distinctMonths = sortDistinctMonths_MM_YYYY(distinctMonths);

  // Generate the column definition dynamically
  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'application_type',
      header: 'Application',
      sortingField: 'application_type',
      width: 150,
      cell: (item: any) => <>{item?.application_type}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      width: 125,
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month]) : 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    rowData: aggregatedArray,
    columnData: columnDefinition,
    visibleColumns: visbleColumns
  };
};

const getAggregatedUserMetricsByApplicationWithUserCount = (userMetricsData: UserMetricsParsedData[]): UserMetricsAggregatedViewsTable => {
  const aggregatedData: Record<string, any> = {};

  // Initialize an array to store distinct months
  let distinctMonths: string[] = [];

  for (const entry of userMetricsData) {
    const { application_type, create_date_formatted, measure_value } = entry;

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(create_date_formatted)) {
      distinctMonths.push(create_date_formatted);
    }

    // Create a unique key based on application and application_type
    const key = `${application_type}`;

    // Check if an entry with this key already exists
    if (aggregatedData[key]) {
      // If it exists, add userCount to the existing entry for the corresponding month
      aggregatedData[key][create_date_formatted] = (aggregatedData[key][create_date_formatted] || 0) + measure_value;
    } else {
      // Otherwise, create a new entry with userCount for the corresponding month
      aggregatedData[key] = {
        application_type,
        [create_date_formatted]: measure_value
      };
    }
  }

  // Convert the aggregation object into an array of aggregated data
  const aggregatedArray = Object.values(aggregatedData);

  distinctMonths = sortDistinctMonths_MM_YYYY(distinctMonths);

  // Generate the column definition dynamically
  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'application_type',
      header: 'Application',
      sortingField: 'application_type',
      cell: (item: any) => <>{item?.application_type}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month]) : 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    rowData: aggregatedArray,
    columnData: columnDefinition,
    visibleColumns: visbleColumns
  };
};

//Function to aggregated the sub application for each month by views or unique users
const getAggregatedUserMetricsBySubApplication = (
  userMetricsData: UserMetricsParsedData[],
  columnList: string[],
  measureColumnList: MetricsMeasure[]
): UserMetricsAggregatedViewsTable => {
  const aggregatedData: Record<string, any> = {};
  const aggregatedDataValue = aggregateMetricsData(columnList, userMetricsData, measureColumnList) || [];

  let distinctMonths: string[] = [];

  for (const entry of aggregatedDataValue) {
    const application = entry.application;
    const application_type = entry.application_type;
    const create_date_formatted = entry.create_date_formatted;
    let measureColumn = '';
    if (entry.hasOwnProperty('user_login_count_distinct')) {
      measureColumn = entry.user_login_count_distinct;
    } else if (entry.hasOwnProperty('measure_value')) {
      measureColumn = entry.measure_value;
    }

    // Ensure month is in the distinct array
    if (!distinctMonths.includes(create_date_formatted)) {
      distinctMonths.push(create_date_formatted);
    }

    const key = `${application}-${application_type}`;

    if (aggregatedData[key]) {
      aggregatedData[key][create_date_formatted] = (aggregatedData[key][create_date_formatted] || 0) + measureColumn;
    } else {
      // Otherwise, create a new entry with userCount for the corresponding month
      aggregatedData[key] = {
        application,
        application_type,
        [create_date_formatted]: measureColumn
      };
    }
  }

  // Convert the aggregation object into an array of aggregated data
  const aggregatedArray = Object.values(aggregatedData);

  distinctMonths = sortDistinctMonths_MM_YYYY(distinctMonths);

  // Generate the column definition dynamically
  const columnDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'application',
      header: 'Sub Application',
      sortingField: 'application',
      width: 150,
      cell: (item: any) => <>{item?.application}</>,
      isRowHeader: true
    },
    {
      id: 'application_type',
      header: 'Application',
      sortingField: 'application_type',
      width: 150,
      cell: (item: any) => <>{item?.application_type}</>,
      isRowHeader: true
    },
    ...distinctMonths.map((month) => ({
      id: month,
      header: month,
      sortingField: month,
      width: 125,
      cell: (item: any) => <>{item[month] ? numberFormatter(item[month]) : 0}</>
    }))
  ];

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    rowData: aggregatedArray,
    columnData: columnDefinition,
    visibleColumns: visbleColumns
  };
};

// Total Application & Sub Application Footprint Details
const getAppFootPrintTableDetails = (
  userMetricsData: UserMetricsParsedData[],
  monthList: string[],
  type: string
): UserMetricsAggregatedViewsTable => {
  let footPrintTableData = [];
  const userMetricsDataArr: any = [];
  let numberofDays = 0;
  numberofDays = monthList.reduce((totalDays, month) => totalDays + moment(month, FilterDateFormat_MMM_YYYY).daysInMonth(), 0);

  //calculating avg page views per day
  userMetricsData.forEach(function (item) {
    const obj: any = item;
    const avgPageViews = (item.measure_value / (item.user_login_count_distinct * numberofDays)).toFixed(2);
    obj['avgPageViewsPerDay'] = parseFloat(avgPageViews);
    obj['distinct_users'] = item['user_login_count_distinct'];
    userMetricsDataArr.push(obj);
  });

  footPrintTableData = userMetricsDataArr.map((item: any) => {
    if (type === APPLICATION) {
      return {
        team_name: item.team_name,
        app_type: item.application_type,
        distinctUsers: item.distinct_users,
        userCount: item.measure_value,
        avgPageViewsPerDay: item.avgPageViewsPerDay,
        active_since: item.active_since
      };
    } else {
      return {
        team_name: item.team_name,
        app_type: item.application_type,
        app_name: item.application,
        distinctUsers: item.distinct_users,
        userCount: item.measure_value,
        avgPageViewsPerDay: item.avgPageViewsPerDay,
        active_since: item.active_since
      };
    }
  });
  if (type === APPLICATION) {
    return {
      rowData: footPrintTableData?.sort((a: any, b: any) => b.distinctUsers - a.distinctUsers) || [],
      columnData: USER_METRICS_FOOTPRINT_COLUMN_DEFINITIONS,
      visibleColumns: USER_METRICS_FOOTPRINT_VISIBLE_CONTENT
    } as UserMetricsAggregatedViewsTable;
  } else {
    return {
      rowData: footPrintTableData?.sort((a: any, b: any) => b.distinctUsers - a.distinctUsers) || [],
      columnData: USER_METRICS_SUB_APPLICATION_FOOTPRINT_COLUMN_DEFINITIONS,
      visibleColumns: USER_METRICS_SUB_APPLICATION_FOOTPRINT_VISIBLE_CONTENT
    } as UserMetricsAggregatedViewsTable;
  }
};
