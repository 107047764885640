import React, { useState, ReactNode, useEffect } from 'react';
import { Box, Button, FormField, Multiselect, MultiselectProps, SpaceBetween, Table, TableProps } from '@amzn/awsui-components-react';
import { Header, Pagination } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { EmptyStateMessage } from '../EmptyStateMessage';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import { filtersLabel } from '../FinTechOpsCommonElements';
import { getMultiSelectPlaceHolderValue, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import { objectToOptions, optionsToObject, returnFilterAgeSorted, returnSelectedOptionsAge } from '../FinTechOpsDataProcessingFunctions';
import { TicketContextType, useTicketContext } from '../tickets/TicketsContext';
import { TICKET_RESOLVED_DETAILS_COLUMN_DEFINITIONS, TICKET_RESOLVED_DETAILS_VISIBLE_CONTENT } from '../tickets/TicketsTableFilterConfig';

type StatusType = 'pending' | 'loading' | 'finished' | 'error';

//AuthConfig
export const DetailsTableWithProps = (props: {
  emptyMessage: ReactNode;
  FILTERING_PROPERTIES: any;
  VISIBLE_CONTENT: readonly string[] | undefined;
  Data: readonly any[];
  COLUMN_DEFINITIONS: readonly TableProps.ColumnDefinition<any>[];
  pageSize?: number;
  headerName: string | undefined;
  stickyHeader?: boolean;
}) => {
  const [preferences, setPreferences] = useState({
    pageSize: props.pageSize || 3,
    visibleContent: props.VISIBLE_CONTENT
  });

  const { items, actions, collectionProps, paginationProps } = useCollection(props.Data, {
    propertyFiltering: {
      filteringProperties: props.FILTERING_PROPERTIES,
      empty: <EmptyStateMessage title="No data" subtitle="No data to display." />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="No match found to display."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {}
  });

  const { selectedItems } = collectionProps;

  return (
    <Table
      variant="embedded"
      {...collectionProps}
      columnDefinitions={props.COLUMN_DEFINITIONS}
      items={items}
      loadingText="Loading Ticket Aggregated Details"
      visibleColumns={props.VISIBLE_CONTENT}
      // resizableColumns
      resizableColumns={true}
      stickyHeader={props.stickyHeader ? props.stickyHeader : true}
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            {props.emptyMessage}
          </Box>
        </Box>
      }
      header={
        <Box>
          <Box float="left">{props.headerName ? <Header counter={`(${props.Data.length})`}>{props.headerName}</Header> : ''}</Box>
          <Box float="right">
            <Pagination {...paginationProps} />
          </Box>
        </Box>
      }
    />
  );
};

export const DetailsTableWithPropsNoPag = (props: {
  emptyMessage: ReactNode;
  FILTERING_PROPERTIES: any;
  VISIBLE_CONTENT: readonly string[] | undefined;
  Data: readonly unknown[];
  COLUMN_DEFINITIONS: readonly TableProps.ColumnDefinition<unknown>[];
  pageSize?: number;
  headerName?: string | undefined;
  variant?: TableProps.Variant | undefined;
  stickyHeader?: boolean;
}) => {
  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(props.Data, {
    sorting: {},
    selection: {}
  });

  return (
    <Table
      variant={props.variant ? props.variant : 'full-page'}
      stickyHeader={props.stickyHeader ? props.stickyHeader : true}
      {...collectionProps}
      columnDefinitions={props.COLUMN_DEFINITIONS}
      items={items}
      loadingText="Loading Ticket Aggregated Details"
      visibleColumns={props.VISIBLE_CONTENT}
      resizableColumns={true}
      empty={<EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
    />
  );
};

//Common Table props with Multi Select Drop Down
export const DetailsTableWithPropsMultiSelectDropDown = (props: {
  emptyMessage: ReactNode;
  FILTERING_PROPERTIES: any;
  VISIBLE_CONTENT: readonly string[] | undefined;
  Data: readonly any[];
  COLUMN_DEFINITIONS: readonly TableProps.ColumnDefinition<any>[];
  pageSize?: number;
  headerName: string | undefined;
  stickyHeader?: boolean;
  tableLoadingText: string;
  detailsType: string;
  multiSelectPlaceholder: string[];
  rawDropDownValues: any[];
  statusType: StatusType;
  disabledValue: string;
}) => {
  const ticketContext = useTicketContext();

  const [preferences, setPreferences] = useState({
    pageSize: props.pageSize || 3,
    visibleContent: props.VISIBLE_CONTENT
  });

  const [selectedOptionOne, setSelectedOptionOne] = useState<MultiselectProps.Options>([]);
  const [selectedOptionTwo, setSelectedOptionTwo] = useState<MultiselectProps.Options>([]);

  const { items, actions, collectionProps, paginationProps } = useCollection(props.Data, {
    propertyFiltering: {
      filteringProperties: props.FILTERING_PROPERTIES,
      empty: <EmptyStateMessage title="No data" subtitle="No data to display." />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="No match found to display."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {}
  });

  const { selectedItems } = collectionProps;

  //useEffect to set the filtered resolved month list for multi select drop down
  useEffect(() => {
    props.detailsType === 'ticketResolvedDetails'
      ? (setSelectedOptionOne(objectToOptions(ticketContext.filteredResolvedMonthList)),
        setSelectedOptionTwo(objectToOptions(ticketContext.filteredResolvedResolutionTypeList)))
      : '';
  }, [ticketContext.filteredResolvedMonthList, ticketContext.filteredResolvedResolutionTypeList]);

  //Function to retrieve the onBlur event for Multiselect drop down
  const getOnBlurEvent = (value: string) => {
    switch (value) {
      case 'ticketResolvedDetails':
        return onTicketResolvedDetailsFilterBlur(ticketContext, selectedOptionOne, selectedOptionTwo);
      default:
        return null;
    }
  };

  //Function to update the value of Ticket Resolved Details Table whenever the table action multi select drop down value changes
  const onTicketResolvedDetailsFilterBlur = (
    ticketContext: TicketContextType,
    selectedOption: MultiselectProps.Options,
    resolutionTypeSelectedOption: MultiselectProps.Options
  ) => {
    const resolutionTypeArray = optionsToObject(resolutionTypeSelectedOption);
    const ticketAgeArray = returnSelectedOptionsAge(selectedOption);
    const filteredResolvedMonthData = ticketContext.filteredResolvedDetailsTable.filter((item) => {
      return ticketAgeArray.includes(item.resolved_date_UserTimeZone_formatted) && resolutionTypeArray.includes(item.resolution_type);
    });
    const parseData = {
      columnData: TICKET_RESOLVED_DETAILS_COLUMN_DEFINITIONS,
      visibleColumns: TICKET_RESOLVED_DETAILS_VISIBLE_CONTENT,
      rowData: filteredResolvedMonthData
    };
    ticketContext.setTicketResolvedDetailsTable(parseData);
    const uniqueFilteredResolvedMonthList = getUniqueFieldValues(filteredResolvedMonthData, 'resolved_date_UserTimeZone_formatted');
    const uniqueFilteredResolvedResolutionTypeList = getUniqueFieldValues(filteredResolvedMonthData, 'resolution_type');
    setSelectedOptionOne(objectToOptions(uniqueFilteredResolvedMonthList));
    setSelectedOptionTwo(objectToOptions(uniqueFilteredResolvedResolutionTypeList));
  };

  return (
    <Table
      variant="embedded"
      {...collectionProps}
      columnDefinitions={props.COLUMN_DEFINITIONS}
      items={items}
      loadingText={props.tableLoadingText}
      visibleColumns={props.VISIBLE_CONTENT}
      resizableColumns={true}
      stickyHeader={props.stickyHeader ? props.stickyHeader : true}
      empty={
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            {props.emptyMessage}
          </Box>
        </Box>
      }
      header={
        <Header
          counter={`(${props.Data.length})`}
          actions={
            <SpaceBetween direction="horizontal" size="m">
              {props.rawDropDownValues.length === 1 ? (
                <>
                  <FormField label={filtersLabel(props.multiSelectPlaceholder[0])}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(selectedOptionOne, props.multiSelectPlaceholder[0], props.rawDropDownValues[0])}
                      options={returnFilterAgeSorted(props.rawDropDownValues[0], `All ${props.multiSelectPlaceholder[0]}`)}
                      selectedOptions={selectedOptionOne}
                      onChange={({ detail }) => {
                        setSelectedOptionOne(detail.selectedOptions);
                      }}
                      onBlur={() => getOnBlurEvent(props.detailsType)}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText={`Loading ${props.multiSelectPlaceholder[0]}`}
                      errorText={`Unable to load ${props.multiSelectPlaceholder[0]}`}
                      statusType={props.statusType}
                      disabled={props.disabledValue === 'loading' || props.disabledValue === 'loading' ? true : false}
                    />
                  </FormField>
                </>
              ) : props.rawDropDownValues.length === 2 ? (
                <>
                  <FormField label={filtersLabel(props.multiSelectPlaceholder[0])}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(selectedOptionOne, props.multiSelectPlaceholder[0], props.rawDropDownValues[0])}
                      options={returnFilterAgeSorted(props.rawDropDownValues[0], `All ${props.multiSelectPlaceholder[0]}`)}
                      selectedOptions={selectedOptionOne}
                      onChange={({ detail }) => {
                        setSelectedOptionOne(detail.selectedOptions);
                      }}
                      onBlur={() => getOnBlurEvent(props.detailsType)}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText={`Loading ${props.multiSelectPlaceholder[0]}`}
                      errorText={`Unable to load ${props.multiSelectPlaceholder[0]}`}
                      statusType={props.statusType}
                      disabled={props.disabledValue === 'loading' || props.disabledValue === 'loading' ? true : false}
                    />
                  </FormField>
                  <FormField label={filtersLabel(props.multiSelectPlaceholder[1])}>
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(selectedOptionTwo, props.multiSelectPlaceholder[1], props.rawDropDownValues[1])}
                      options={returnFilterAgeSorted(props.rawDropDownValues[1], `All ${props.multiSelectPlaceholder[1]}`)}
                      selectedOptions={selectedOptionTwo}
                      onChange={({ detail }) => {
                        setSelectedOptionTwo(detail.selectedOptions);
                      }}
                      onBlur={() => getOnBlurEvent(props.detailsType)}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      selectedAriaLabel="Selected"
                      expandToViewport
                      hideTokens
                      virtualScroll
                      filteringType="auto"
                      loadingText={`Loading ${props.multiSelectPlaceholder[1]}`}
                      errorText={`Unable to load ${props.multiSelectPlaceholder[1]}`}
                      statusType={props.statusType}
                      disabled={props.disabledValue === 'loading' || props.disabledValue === 'loading' ? true : false}
                    />
                  </FormField>
                </>
              ) : (
                ''
              )}
              <Pagination {...paginationProps} />
            </SpaceBetween>
          }
        >
          {props.headerName}
        </Header>
      }
    />
  );
};
