import { Box, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { BasicMetricContainer, ErrorContainer } from '../FinTechOpsCommonElements';
import { FinTechOpsCategories, FinTechOpsCategoriesLabelColors, FinTechOpsHref, FinTechOpsMetricsDescriptions } from '../FinTechOpsConstants';
import React from 'react';

export const FinTechOpsPolicyEngine: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const policyEngineDashboardData = finOpsContext.finTechOpsPolicyEngineDashboardData;
  const finTechOpsPolicyEngineParsedData = finOpsContext.finTechOpsPolicyEngineParsedData;

  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          Policy Engine
        </Box>
        <LastRefreshedAt lastRefreshedDateTime={finTechOpsPolicyEngineParsedData.metadata.last_updated_at} />
      </SpaceBetween>
      <div>
        {finOpsContext.finTechOpsPolicyEngineDataStatus === 'loading' && <LoadingSpinner />}
        {finOpsContext.finTechOpsPolicyEngineDataStatus === 'pending' && <LoadingSpinner />}
        {finOpsContext.finTechOpsPolicyEngineDataStatus === 'error' && <ErrorContainer />}
        {finOpsContext.finTechOpsPolicyEngineDataStatus === 'finished' && (
          <ColumnLayout columns={5} minColumnWidth={10}>
            <BasicMetricContainer
              title="Total risks"
              value={`${policyEngineDashboardData.totalPolicyEngineRisks || '0'}`}
              unit=""
              href={FinTechOpsHref.policyEngine}
              id={FinTechOpsCategories.policyEngine}
              color={FinTechOpsCategoriesLabelColors.policyEngine}
              description={FinTechOpsMetricsDescriptions.policyEngineTotal}
            />
            <BasicMetricContainer
              title="Resolved Risks"
              value={`${policyEngineDashboardData.solved || '0'}`}
              unit=""
              href={FinTechOpsHref.policyEngine}
              id={FinTechOpsCategories.policyEngine}
              color={FinTechOpsCategoriesLabelColors.policyEngine}
              description={FinTechOpsMetricsDescriptions.policyEngineSolved}
            />
            <BasicMetricContainer
              title="Open Risks"
              value={`${policyEngineDashboardData.unsolved || '0'}`}
              unit=""
              href={FinTechOpsHref.policyEngine}
              id={FinTechOpsCategories.policyEngine}
              color={FinTechOpsCategoriesLabelColors.policyEngine}
              description={FinTechOpsMetricsDescriptions.policyEngineUnsolved}
            />
            <BasicMetricContainer
              title="Open Critical Risks"
              value={`${policyEngineDashboardData.totalOpenCriticalRisks || '0'}`}
              unit=""
              href={FinTechOpsHref.policyEngine}
              id={FinTechOpsCategories.policyEngine}
              color={FinTechOpsCategoriesLabelColors.policyEngine}
              description={FinTechOpsMetricsDescriptions.totalOpenCriticalRisks}
            />
            <BasicMetricContainer
              title="Resolution rate"
              value={`${policyEngineDashboardData.resolutionRate || 0}%`}
              unit=""
              href={FinTechOpsHref.policyEngine}
              id={FinTechOpsCategories.policyEngine}
              color={FinTechOpsCategoriesLabelColors.policyEngine}
              description={FinTechOpsMetricsDescriptions.resolutionRate}
            />
          </ColumnLayout>
        )}
      </div>
    </Box>
  );
};
