import React from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import styles from './styles.module.scss';
import { useDogmaContext } from './DogmaContext';
import { Box, Container, Grid, Header } from '@amzn/awsui-components-react';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { DetailsTableWithProps, DetailsTableWithPropsNoPag } from '../fintech-ops-charts/TableWithPropsCommonComponent';

export const DogmaDetailsPage: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const dogmaContext = useDogmaContext();

  return (
    <div>
      {finOpsContext?.finTechOpsPolicyEngineDataStatus == 'loading' && <LoadingSpinner />}
      {finOpsContext?.finTechOpsPolicyEngineDataStatus == 'error' && <ErrorContainer />}
      {finOpsContext?.finTechOpsPolicyEngineDataStatus == 'pending' && <NotInitializedContainer />}
      {finOpsContext?.finTechOpsPolicyEngineDataStatus == 'finished' && (
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 4, l: 3, xl: 4 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 8, l: 9, xl: 8 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } }
          ]}
        >
          <Container fitHeight header={<Header variant="h2">Total Open Violations</Header>}>
            <Box variant="h3" textAlign="center">
              {numberFormatter(dogmaContext?.dogmaDetailsTabData?.dogmaSummaryContainer?.dogmaOpenRiskCount)}
            </Box>
            <div className={styles.DogmaDetailsTable}>
              <DetailsTableWithPropsNoPag
                pageSize={4}
                emptyMessage="No dogma data to display"
                FILTERING_PROPERTIES={[]}
                VISIBLE_CONTENT={dogmaContext?.dogmaDetailsTabData?.dogmaSummaryContainer?.dogmaSummaryTable?.visibleColumns}
                COLUMN_DEFINITIONS={dogmaContext?.dogmaDetailsTabData?.dogmaSummaryContainer?.dogmaSummaryTable?.columnData}
                Data={dogmaContext?.dogmaDetailsTabData?.dogmaSummaryContainer?.dogmaSummaryTable?.rowData}
                headerName=""
              />
            </div>
          </Container>
          <Container fitHeight>
            <div className={styles.DogmaDetailsTable}>
              <DetailsTableWithProps
                pageSize={100}
                stickyHeader={false}
                emptyMessage="No dogma data to display"
                FILTERING_PROPERTIES={[]}
                VISIBLE_CONTENT={dogmaContext?.dogmaDetailsTabData?.totalAggregatedViolationsByManager?.visibleColumns}
                COLUMN_DEFINITIONS={dogmaContext?.dogmaDetailsTabData?.totalAggregatedViolationsByManager?.columnData}
                Data={dogmaContext?.dogmaDetailsTabData?.totalAggregatedViolationsByManager?.rowData}
                headerName="Total Aggregated Violations by Manager"
              />
            </div>
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={4}
              stickyHeader={false}
              emptyMessage="No dogma data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={dogmaContext?.dogmaDetailsTabData?.totalOpenViolationsByRiskSeverity?.visibleColumns}
              COLUMN_DEFINITIONS={dogmaContext?.dogmaDetailsTabData?.totalOpenViolationsByRiskSeverity?.columnData}
              Data={dogmaContext?.dogmaDetailsTabData?.totalOpenViolationsByRiskSeverity?.rowData}
              headerName="Total Open Violations by Risk severity"
            />
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={4}
              stickyHeader={false}
              emptyMessage="No dogma data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={dogmaContext?.dogmaDetailsTabData?.totalAggregatedViolationsByRiskSeverity?.visibleColumns}
              COLUMN_DEFINITIONS={dogmaContext?.dogmaDetailsTabData?.totalAggregatedViolationsByRiskSeverity?.columnData}
              Data={dogmaContext?.dogmaDetailsTabData?.totalAggregatedViolationsByRiskSeverity?.rowData}
              headerName="Total Aggregated Violations by Risk Severity"
            />
          </Container>
          <Container fitHeight>
            <DetailsTableWithProps
              pageSize={10}
              stickyHeader={false}
              emptyMessage="No dogma data to display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={dogmaContext?.dogmaDetailsTabData?.totalOpenViolationDetails?.visibleColumns}
              COLUMN_DEFINITIONS={dogmaContext?.dogmaDetailsTabData?.totalOpenViolationDetails?.columnData}
              Data={dogmaContext?.dogmaDetailsTabData?.totalOpenViolationDetails?.rowData}
              headerName="Total Open Violation Details"
            />
          </Container>
        </Grid>
      )}
    </div>
  );
};
