import React, { useEffect, useState } from 'react';
import { BasicMetricContainer, ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { Box, ColumnLayout, Container, Header, PieChart, SpaceBetween, Table, Grid, BarChart, Multiselect } from '@amzn/awsui-components-react';
import { FinTechOpsMessages, FinTechOpsMetricsDescriptions } from 'src/components/fintech-ops/FinTechOpsConstants';
import { i18nStringHour } from 'src/components/fintech-ops/i18nStrings';
import { EmptyStateMessage } from 'src/components/fintech-ops/EmptyStateMessage';
import { RestAPI } from '@aws-amplify/api-rest';
import Button from '@amzn/awsui-components-react/polaris/button';
import MixedLineBarChart from '@amzn/awsui-components-react/polaris/mixed-line-bar-chart';
import {
  L8_MANAGER_DETAILS,
  RISK_COUNT_TREND_METRICS_FLAGS,
  RISK_REMEDIATION_TYPE_FILTER,
  RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS,
  RISK_TYPE,
  UNMAPPED_STRING
} from './constants';
import { summaryByReporteeColumnDef, topRiskCategoriesColumnDef } from 'src/components/fintech-ops/risk-remediator/RiskRemediatorTableFilterConfig';
import moment from 'moment';
import { fetchSamplingDate, getTop5RiskCategories } from 'src/components/fintech-ops/risk-remediator/utils';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';
import { RiskRemediatorSelectionFilters } from 'src/components/fintech-ops/risk-remediator/RiskRemediatorSelectionFilters';
import styles from 'src/components/fintech-ops/risk-remediator/styles.module.scss';

export function RiskRemediatorMetricCount(props: any) {
  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          Risk Details
        </Box>
      </SpaceBetween>
      <div>
        <ColumnLayout columns={6} minColumnWidth={10}>
          <BasicMetricContainer
            title="Total Risks"
            value={props.remediatedRiskData.length + props.openRiskData.length + props.devRemediatedRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorTotalRisk}
          />
          <BasicMetricContainer
            title="Open Risks"
            value={props.openRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorOpenRisk}
          />
          <BasicMetricContainer
            title="Auto-Remediated"
            value={props.remediatedRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorTotalRemediatedRisk}
          />
          <BasicMetricContainer
            title="Developer Remediated Risk"
            value={props.devRemediatedRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorDeveloperRemediatedRisk}
          />
          <BasicMetricContainer
            title="Auto-Remediation Rate"
            value={(
              (props.remediatedRiskData.length /
                (props.remediatedRiskData.length + props.openRiskData.length + props.devRemediatedRiskData.length || 1)) *
              100
            ).toPrecision(4)}
            unit="%"
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorRemediationRate}
          />
          <BasicMetricContainer
            title="ASR Certification Rate"
            value={(
              (props.asrData.filter((obj: Record<string, string>) => obj.application_certification_status == 'CERTIFIED').length /
                (props.asrData.length || 1)) *
              100
            ).toPrecision(4)}
            unit="%"
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorASRMetrics}
          />
        </ColumnLayout>
      </div>
    </Box>
  );
}

export function riskCountTrend(props: any) {
  var frequency = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].frequency;
  var totalDays = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].totalDays;
  var monthFlag = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].monthFlag;
  var dateFlag = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].dateFlag;
  var yearFlag = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].yearFlag;

  const pollingDateList: string[] = [];
  const barData: any = [];
  const lineData: any = [];
  for (let i = 0; i <= totalDays; i = i + frequency) {
    var pollingDate: string = '';
    var filterDate: string = '';
    var prevFilterDate: string = '';
    var sampleDate = moment().set('date', moment().date() - i);
    var prevFilterDate = fetchSamplingDate(i + frequency, 'Previous');
    var filterDate = fetchSamplingDate(i);
    var openRisk = props.openRiskData.filter(
      (obj: Record<string, string>) => obj.event_timestamp <= filterDate && obj.event_timestamp > prevFilterDate
    ).length;
    var remediatedRisk = props.remediatedRiskData.filter(
      (obj: Record<string, string>) => obj.event_timestamp <= filterDate && obj.event_timestamp > prevFilterDate
    ).length;
    var devRemediatedRisk = props.devRemediatedRiskData.filter(
      (obj: Record<string, string>) => obj.event_timestamp <= filterDate && obj.event_timestamp > prevFilterDate
    ).length;

    if (yearFlag) {
      pollingDate = sampleDate.year().toString();
    }
    pollingDate = ('0' + (sampleDate.month() + 1)).slice(-2).toString() + '/' + pollingDate;
    if (dateFlag) {
      pollingDate = pollingDate + ('0' + sampleDate.date()).slice(-2).toString();
    }

    pollingDateList.push(pollingDate);
    barData.push({ x: pollingDate, y: devRemediatedRisk + openRisk });
    lineData.push({ x: pollingDate, y: remediatedRisk });
  }
  return (
    <BarChart
      series={[
        { title: 'Non-Auto Remediated Risk', type: 'bar', data: barData.reverse() },
        { title: 'Auto Remediated Risk', type: 'bar', data: lineData.reverse() }
      ]}
      xDomain={pollingDateList.reverse()}
      xScaleType="categorical"
      ariaLabel="Risk Trend"
      errorText={FinTechOpsMessages.errorText}
      hideFilter={true}
      i18nStrings={i18nStringHour('Severity')}
      empty={<EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
      xTitle="Time Period"
      yTitle="No. of Risks"
      height={300}
      noMatch={FinTechOpsMessages.noMatchMessage}
    />
  );
}
export function riskSummaryByType(props: any) {
  const [categorySummaryData, selectCategorySummaryData] = useState<any>([]);
  const [riskRemediationTypeSelector, setRiskRemediationTypeSelector] = useState<any>([
    { label: RISK_REMEDIATION_TYPE_FILTER[0]['options'][0]['label'], value: RISK_REMEDIATION_TYPE_FILTER[0]['options'][0]['value'] },
    { label: RISK_REMEDIATION_TYPE_FILTER[0]['options'][1]['label'], value: RISK_REMEDIATION_TYPE_FILTER[0]['options'][1]['value'] }
  ]);
  const [isResetFilter, setIsResetFilter] = useState<boolean>(false);
  if (props.selectedManager.value == 'All') {
    props.selectedManager.value = 'degardm';
  }
  useEffect(() => {
    const aggregatedRiskCategories: Record<string, any> = {};
    let riskList: any[] = [];
    if (!riskRemediationTypeSelector.length) {
      riskList = [];
    } else if (riskRemediationTypeSelector.length == 2) {
      riskList = [props.openRiskData, props.remediatedRiskData, props.devRemediatedRiskData];
    } else if (riskRemediationTypeSelector[0].value == RISK_REMEDIATION_TYPE_FILTER[0]['options'][1]['value']) {
      riskList = [props.openRiskData, props.devRemediatedRiskData];
    } else {
      riskList = [props.remediatedRiskData];
    }
    let riskCategories: string[] = [];
    riskList.forEach((riskData: Record<string, string>[]) => {
      riskCategories = [...new Set(riskData.map((obj: any) => obj.issue_detected))];
      riskCategories.forEach((category: string) => {
        let count: any = riskData.filter((obj: Record<string, string>) => obj.issue_detected == category).length;
        if (category in aggregatedRiskCategories && aggregatedRiskCategories[category]) {
          count = count + aggregatedRiskCategories[category];
        }
        aggregatedRiskCategories[category] = count;
      });
    });
    let pie_data: Record<string, string>[] = [];
    for (let key in aggregatedRiskCategories) {
      pie_data.push({ title: key, value: aggregatedRiskCategories[key] });
    }
    selectCategorySummaryData(pie_data);
  }, [props.openRiskData, props.remediatedRiskData, props.devRemediatedRiskData, riskRemediationTypeSelector]);

  useEffect(() => {
    if (isResetFilter) {
      setRiskRemediationTypeSelector([
        { label: RISK_REMEDIATION_TYPE_FILTER[0]['options'][0]['label'], value: RISK_REMEDIATION_TYPE_FILTER[0]['options'][0]['value'] },
        { label: RISK_REMEDIATION_TYPE_FILTER[0]['options'][1]['label'], value: RISK_REMEDIATION_TYPE_FILTER[0]['options'][1]['value'] }
      ]);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const multiSelected = (
    <Multiselect
      selectedOptions={riskRemediationTypeSelector}
      onChange={({ detail }) => setRiskRemediationTypeSelector(detail.selectedOptions)}
      options={RISK_REMEDIATION_TYPE_FILTER}
      placeholder={getMultiSelectPlaceHolderValue(riskRemediationTypeSelector, 'Remediation Type', RISK_REMEDIATION_TYPE_FILTER[0].options)}
      hideTokens
    />
  );

  return (
    <div>
      <PieChart
        data={categorySummaryData}
        detailPopoverContent={(datum, sum) => [
          { key: 'Risk count', value: datum.value },
          {
            key: 'Percentage',
            value: `${((datum.value / sum) * 100).toFixed(0)}%`
          }
        ]}
        segmentDescription={(datum, sum) => `${((datum.value / sum) * 100).toFixed(0)}%`}
        ariaDescription="Pie chart showing count and types of risks"
        ariaLabel="Risk Category metrics"
        hideFilter
        hideLegend
        hideDescriptions
        hideTitles
        size="medium"
        additionalFilters={<RiskRemediatorSelectionFilters selector={multiSelected} header=<b>Risk Remediation Type</b> />}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data available</b>
            {!riskRemediationTypeSelector.length && (
              <div>
                <br />
                <Button onClick={() => setIsResetFilter(true)}>Clear Filter</Button>
              </div>
            )}
          </Box>
        }
        noMatch={
          <Box textAlign="center" color="inherit">
            <b>No matching data</b>
            <Box variant="p" color="inherit">
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
      />
      {(riskRemediationTypeSelector.length > 0 || (categorySummaryData && categorySummaryData.length > 0)) &&
        categorySummaryData &&
        categorySummaryData.length > 0 && (
          <>
            <b>Count of Distinct Risk Categories:</b> {Object.keys(categorySummaryData).length}
            <br />
            <br />
            <Table
              className={styles.riskMetricsTable}
              columnDefinitions={topRiskCategoriesColumnDef}
              items={getTop5RiskCategories(categorySummaryData)}
              variant={'borderless'}
              wrapLines
              resizableColumns
            />
          </>
        )}
    </div>
  );
}

export function asrMetrics(props: any) {
  const [asrMetricsData, setAsrMetricsData] = useState<any>([]);

  useEffect(() => {
    let certificationPieData: Record<any, any>[] = [];
    const aggregatedAppCategories: Record<string, any> = { certificateClassification: {} };
    let certificationStatus: string = '';
    props.asrData.forEach((asrApp: Record<string, string>) => {
      certificationStatus = asrApp.application_certification_status?.replace(/_/g, ' ');
      let count: any = props.asrData.filter(
        (obj: Record<string, string>) => obj.application_certification_status?.replace(/_/g, ' ') == certificationStatus
      ).length;
      aggregatedAppCategories['certificateClassification'][certificationStatus] = count;
    });

    for (let key in aggregatedAppCategories['certificateClassification']) {
      certificationPieData.push({ title: key, value: aggregatedAppCategories['certificateClassification'][key] });
    }

    setAsrMetricsData(certificationPieData);
  }, [props.asrData]);

  return (
    <PieChart
      data={asrMetricsData}
      detailPopoverContent={(datum, sum) => [
        { key: 'App count', value: datum.value },
        {
          key: 'Percentage',
          value: `${((datum.value / sum) * 100).toFixed(0)}%`
        }
      ]}
      segmentDescription={(datum, sum) => `${((datum.value / sum) * 100).toFixed(0)}%`}
      ariaDescription="Pie chart showing ASR and certification details"
      ariaLabel="ASR certification metrics"
      size="medium"
      variant="donut"
      hideFilter
      hideDescriptions
      hideTitles
      //fitHeight
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
}

export const riskMetricGraphs = (props: any) => {
  return (
    <Grid
      gridDefinition={[
        { colspan: RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS },
        { colspan: RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS },
        { colspan: RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS }
      ]}
    >
      <Container fitHeight={true} className={styles.risMmetricsContainer}>
        <Header variant="h2">Risk Count Trend</Header> <br />
        <br />
        {riskCountTrend(props)}
      </Container>
      <Container fitHeight={true} className={styles.risMmetricsContainer}>
        <Header variant="h2">Risk Category Spectrum</Header>
        {riskSummaryByType(props)}
      </Container>
      <Container fitHeight={true} className={styles.risMmetricsContainer}>
        <Header variant="h2">ASR Certification Metrics</Header>
        <Box margin={{ vertical: 'xxxl' }} padding={{ vertical: 'xxxl' }}>
          {asrMetrics(props)}
        </Box>
      </Container>
    </Grid>
  );
};

export const fetchSummaryDetailsByReports = (props: any) => {
  const [ownerSummaryData, selectOwnerSummaryData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  if (props.selectedManager.value == 'All') {
    props.selectedManager.value = 'degardm';
  }
  useEffect(() => {
    const calculateSummary = () => {
      setIsLoading(true);
      const summaryData: Record<any, any>[] = [];
      const totalCount = {
        openRiskCount: 0,
        remediatedRiskCount: 0,
        devRemediatedRiskCount: 0,
        unCertifiedAsrAppCount: 0,
        totalAppCount: 0
      };

      // Prepare reportee list
      const parentList = props.selectedManager.value === L8_MANAGER_DETAILS.login ? `${props.directsData},${UNMAPPED_STRING}` : props.directsData;

      const reporteeList = [...parentList.split(','), `${props.selectedManager.value}:${props.selectedManager.label}`];

      // Process each reportee
      reporteeList.forEach((reportee) => {
        const [reporteeId, reporteeName] = reportee.split(':');
        const isManager = reporteeId === props.selectedManager.value;

        // Initialize counts for this reportee
        let counts = {
          openRisk: 0,
          remediatedRisk: 0,
          devRemediatedRisk: 0,
          unCertifiedAsr: 0,
          totalApp: 0
        };

        if (isManager) {
          // For manager, only count direct ownership
          counts = {
            openRisk: props.openRiskData.filter((obj: Record<string, any>) => obj.owner_alias.split(',')[0] === reporteeId).length,
            remediatedRisk: props.remediatedRiskData.filter((obj: Record<string, any>) => obj.owner_alias.split(',')[0] === reporteeId).length,
            devRemediatedRisk: props.devRemediatedRiskData.filter((obj: Record<string, any>) => obj.owner_alias.split(',')[0] === reporteeId).length,
            unCertifiedAsr:
              props.asrData?.filter(
                (obj: Record<string, any>) => obj.application_certification_status !== 'CERTIFIED' && obj.owner_alias === reporteeId
              ).length || 0,
            totalApp: props.asrData?.filter((obj: Record<string, any>) => obj.owner_alias === reporteeId).length || 0
          };
        } else {
          // For reportees, include both owned and rolled up risks
          const ownershipFilter = (obj: Record<string, any>) =>
            obj.leadership_rollup.split(',').includes(reporteeId) || obj.owner_alias.split(',')[0] === reporteeId;

          counts = {
            openRisk: props.openRiskData.filter(ownershipFilter).length,
            remediatedRisk: props.remediatedRiskData.filter(ownershipFilter).length,
            devRemediatedRisk: props.devRemediatedRiskData.filter(ownershipFilter).length,
            unCertifiedAsr:
              props.asrData?.filter(
                (obj: Record<string, any>) =>
                  obj.application_certification_status !== 'CERTIFIED' &&
                  (obj.leadership_rollup?.split(',').includes(reporteeId) || obj.owner_alias === reporteeId)
              ).length || 0,
            totalApp:
              props.asrData?.filter(
                (obj: Record<string, any>) => obj.leadership_rollup?.split(',').includes(reporteeId) || obj.owner_alias === reporteeId
              ).length || 0
          };
        }

        // Only add to summary if there's data
        if (Object.values(counts).some((count) => count > 0)) {
          const individualSummary = {
            login: reporteeName || reporteeId,
            openRiskCount: counts.openRisk,
            remediatedRiskCount: counts.remediatedRisk,
            devRemediatedRiskCount: counts.devRemediatedRisk,
            unCertifiedAsrAppCount: counts.unCertifiedAsr,
            totalAppCount: counts.totalApp
          };

          // Update total counts
          totalCount.openRiskCount += counts.openRisk;
          totalCount.remediatedRiskCount += counts.remediatedRisk;
          totalCount.devRemediatedRiskCount += counts.devRemediatedRisk;
          totalCount.unCertifiedAsrAppCount += counts.unCertifiedAsr;
          totalCount.totalAppCount += counts.totalApp;

          summaryData.push(individualSummary);
        }
      });

      // First sort the data by login
      summaryData.sort((a, b) => {
        // Skip if either is the Total row (has React elements)
        if (React.isValidElement(a.login) || React.isValidElement(b.login)) {
          return 0;
        }
        // Keep Unmapped row at the bottom
        if (a.login === UNMAPPED_STRING.split(':')[1]) return 1;
        if (b.login === UNMAPPED_STRING.split(':')[1]) return -1;

        // Sort alphabetically by login
        return a.login.toLowerCase().localeCompare(b.login.toLowerCase());
      });

      // Add total row with bold values
      summaryData.push({
        login: <b>Total</b>,
        openRiskCount: <b>{totalCount.openRiskCount}</b>,
        remediatedRiskCount: <b>{totalCount.remediatedRiskCount}</b>,
        devRemediatedRiskCount: <b>{totalCount.devRemediatedRiskCount}</b>,
        unCertifiedAsrAppCount: <b>{totalCount.unCertifiedAsrAppCount}</b>,
        totalAppCount: <b>{totalCount.totalAppCount}</b>
      });

      setIsLoading(false);
      selectOwnerSummaryData(summaryData);
    };

    calculateSummary();
  }, [props.openRiskData, props.remediatedRiskData, props.devRemediatedRiskData, props.selectedManager.value, props.asrData, props.directsData]);

  return (
    <Table
      columnDefinitions={summaryByReporteeColumnDef}
      items={ownerSummaryData}
      loading={isLoading}
      loadingText="Loading Reportee details"
      sortingDisabled
      empty="No Data"
      wrapLines
      header={<Header> Risk Summary by Owner </Header>}
    />
  );
};

export const RiskRemediatorOverview = (props: any) => {
  return (
    <div>
      {RiskRemediatorMetricCount(props)}
      <br />
      {fetchSummaryDetailsByReports(props)}
      <br />
      {riskMetricGraphs(props)}
    </div>
  );
};
