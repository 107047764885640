import { Box, Button, FormField, Input, StatusIndicator } from '@amzn/awsui-components-react';
import React, { forwardRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { OfficeHoursV2 } from '../../AdminModels';
import { OFFICE_HOURS_DATE_TIME_RECURRENCE_MAX_LENGTH, OfficeHoursSchemaV2 } from './StepsValidations';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import FormFieldPopover from 'src/components/generic-components/FormFieldPopover';

interface OfficeHoursFormProps {
  hasOfficeHours: boolean;
  addNewOfficeHours: (newOfficeHour: OfficeHoursV2) => void;
  updateOfficeHours: (updateOfficeHour: OfficeHoursV2) => void;
}

export interface OfficeHoursFormMethod {
  editSelectedRow: (selectedOfficeHour: OfficeHoursV2) => void;
}

const INITIAL_OFFICE_HOURS: OfficeHoursV2 = {
  id: uuidv4(),
  dateTimeRecurrence: null,
  locationOrLinkName: null,
  meetingLink: null
};

const INITIAL_OFFICE_HOURS_ERRORS = {
  dateTimeRecurrence: '',
  locationOrLinkName: '',
  meetingLink: ''
};

const OfficeHoursFormV2 = forwardRef<OfficeHoursFormMethod, OfficeHoursFormProps>(
  ({ hasOfficeHours, addNewOfficeHours, updateOfficeHours }: OfficeHoursFormProps, ref) => {
    const [actionType, setActionType] = useState<'add' | 'edit'>('add');
    const [validOfficeHours, setValidOfficeHours] = useState(false);
    const [newOfficeHour, setNewOfficeHour] = useState<OfficeHoursV2>(INITIAL_OFFICE_HOURS);
    const [newOfficeHourErrors, setNewOfficeHourErrors] = useState<any>(INITIAL_OFFICE_HOURS_ERRORS);

    const editSelectedRow = (selectedOfficeHour: OfficeHoursV2) => {
      if (selectedOfficeHour) {
        setActionType('edit');
        setNewOfficeHour(selectedOfficeHour);
      } else {
        setActionType('add');
        setNewOfficeHour(INITIAL_OFFICE_HOURS);
      }
    };

    React.useImperativeHandle(ref, () => ({
      editSelectedRow
    }));

    const addOfficeHours = () => {
      const newRow: OfficeHoursV2 = {
        ...newOfficeHour,
        id: uuidv4()
      };
      setNewOfficeHour(INITIAL_OFFICE_HOURS);
      setValidOfficeHours(false);
      addNewOfficeHours(newRow);
    };

    const isValidOfficeHours = async () => {
      try {
        const validationStatus = await OfficeHoursSchemaV2().validate(newOfficeHour, { abortEarly: false });
        setValidOfficeHours(true);
        setNewOfficeHourErrors(INITIAL_OFFICE_HOURS_ERRORS);
      } catch (error: any) {
        const errors: Record<string, string> = {};
        error.inner.forEach((err: Yup.ValidationError) => {
          const path = err.path;
          const message = err.message;
          setNestedObjectField(errors, path, message);
        });
        setNewOfficeHourErrors(errors);
        setValidOfficeHours(false);
      }
    };

    // Helper function to set nested object field
    const setNestedObjectField = (obj: Record<string, any>, path: string | undefined, message: any) => {
      const pathArray = path ? path.split('.') : [];
      const lastIndex = pathArray.length - 1;

      pathArray.reduce((nestedObj, key, index) => {
        if (index === lastIndex) {
          nestedObj[key] = message;
        } else if (!nestedObj[key]) {
          nestedObj[key] = {};
        }
        return nestedObj[key];
      }, obj);
    };

    const clearForm = () => {
      setNewOfficeHour(INITIAL_OFFICE_HOURS);
      setNewOfficeHourErrors(INITIAL_OFFICE_HOURS_ERRORS);
      setValidOfficeHours(false);
    };

    const cancelEdit = () => {
      clearForm();
      setActionType('add');
    };

    const updateChanges = () => {
      updateOfficeHours(newOfficeHour);
      clearForm();
      setActionType('add');
    };

    return (
      <>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            window.location.reload();
          }}
        >
          <div className="inline-table-horizontal-form">
            <FormField
              className="width-25rem"
              label="Date, time and recurrence"
              errorText={newOfficeHourErrors?.dateTimeRecurrence}
              stretch={true}
              constraintText={characterCountConstraintMessage(
                OFFICE_HOURS_DATE_TIME_RECURRENCE_MAX_LENGTH,
                newOfficeHour?.dateTimeRecurrence?.length || 0
              )}
              info={
                <FormFieldPopover
                  header={'Date, time and recurrence'}
                  messages={['Enter the schedule of your office hour.', 'Example: M / W / F @ 10:30 AM PST']}
                />
              }
            >
              <Input
                placeholder="Enter date, time or recurrence"
                disabled={!hasOfficeHours}
                onChange={({ detail }) => {
                  setNewOfficeHour({
                    ...newOfficeHour,
                    dateTimeRecurrence: detail.value != '' ? detail.value : null
                  });
                }}
                value={newOfficeHour.dateTimeRecurrence || ''}
                onBlur={isValidOfficeHours}
              />
            </FormField>

            <FormField
              className="width-20rem"
              label="Location or link name"
              errorText={newOfficeHourErrors?.locationOrLinkName}
              stretch={true}
              info={<FormFieldPopover header={'Location or link name'} messages={['Enter the location of your meeting.']} />}
            >
              <Input
                placeholder="Enter name"
                disabled={!hasOfficeHours}
                onChange={({ detail }) => {
                  setNewOfficeHour({
                    ...newOfficeHour,
                    locationOrLinkName: detail.value
                  });
                }}
                value={newOfficeHour.locationOrLinkName || ''}
                onBlur={isValidOfficeHours}
              />
            </FormField>

            <FormField
              className="flex-grow-field"
              label={
                <>
                  Meeting link<i> - optional</i>
                </>
              }
              errorText={newOfficeHourErrors?.meetingLink}
              stretch={true}
              info={<FormFieldPopover header={'Meeting link'} messages={['Enter a URL to hyperlink your meeting link name.']} />}
            >
              <Input
                placeholder="Paste URL"
                disabled={!hasOfficeHours}
                onChange={({ detail }) => {
                  setNewOfficeHour({ ...newOfficeHour, meetingLink: detail.value });
                }}
                value={newOfficeHour.meetingLink || ''}
                onBlur={isValidOfficeHours}
              />
            </FormField>

            {actionType === 'add' && (
              <Button className="add-new-button" formAction="none" disabled={!validOfficeHours} onClick={addOfficeHours}>
                {`Add`}
              </Button>
            )}
            {actionType === 'edit' && (
              <>
                <Button className="add-new-button" formAction="none" disabled={!validOfficeHours} onClick={cancelEdit}>
                  {`Cancel`}
                </Button>
                <Button variant="primary" className="add-new-button" formAction="none" disabled={!validOfficeHours} onClick={updateChanges}>
                  {`Save`}
                </Button>
              </>
            )}
          </div>
        </ErrorBoundary>
      </>
    );
  }
);

export default OfficeHoursFormV2;
