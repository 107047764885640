import moment from 'moment';
import { RestAPI } from '@aws-amplify/api-rest';
import {
  ASR_DEFAULT_MESSAGE,
  L8_MANAGER_DETAILS,
  RISK_REMEDIATOR_API_ERROR,
  RISK_REMEDIATOR_DIRECTS_API_ERROR,
  RISK_REMEDIATOR_MANAGER_API_ERROR,
  RISK_TYPE_FLAG,
  SPLIT_PANEL_CONFIG
} from 'src/components/fintech-ops/risk-remediator/constants';
import { logger } from 'src/logger';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { Button, KeyValuePairs, ProgressBar } from '@amzn/awsui-components-react';
import React, { useState } from 'react';

export const fetchSamplingDate = (relativeNumberofDays: number, flag: string = 'Current') => {
  const timestamp = flag === 'Previous' ? 'T00:00:00Z' : 'T23:59:59Z';

  const sampleDate = moment().subtract(relativeNumberofDays, 'days');
  const filterDate = sampleDate.format('YYYY-MM-DD') + timestamp;
  return filterDate;
};

export const fetchRiskRemediatorRisks = (duration: string, manager: string): Promise<any> => {
  return RestAPI.get('RISK_REMEDIATOR_API', '/fetch_risks', {
    response: true,
    queryStringParameters: {
      duration: duration,
      manager: manager
    }
  })
    .then((response) => {
      if (response.data && response.data.details) {
        return response.data;
      } else {
        const errorMessage = 'Unexpected response format';
        throw new Error('Unexpected response format');
      }
    })
    .catch(function (error) {
      logger.error('Failed to fetch risks', error);
      useFinTechContext().displayNotification(error, RISK_REMEDIATOR_API_ERROR);
    });
};

export const filterDataByType = (data: string, dataType: string, manager: string) => {
  if (data == undefined) return [];
  const jsonObj = JSON.parse(data);
  return jsonObj[dataType].filter((obj: Record<string, string>) => obj.leadership_rollup.split(',').includes(manager) || obj.owner_alias == manager);
};

export const fetchManagers = (): Promise<any> => {
  const managerlist: Record<string, string>[] = [{ label: L8_MANAGER_DETAILS.name, value: L8_MANAGER_DETAILS.login }];
  return RestAPI.get('RISK_REMEDIATOR_API', '/fetch_managers', {
    response: true
  })
    .then((response) => {
      response.data.details.split(',').forEach((manager: string) => {
        managerlist.push({ label: manager.split(':')[1], value: manager.split(':')[0] });
      });
      managerlist.sort((a, b) => a.label.localeCompare(b.label));
      return managerlist;
    })
    .catch((error) => {
      logger.error('Failed to fetch managers', error);
      useFinTechContext().displayNotification(error, RISK_REMEDIATOR_MANAGER_API_ERROR);
    });
};

export const filterDatabyRiskCategory = (data: any, riskCategory: string) => {
  if (riskCategory == 'MAWS') {
    return data.filter((obj: any) => obj.resource_type.includes('maws'));
  } else {
    return data.filter((obj: any) => !obj.resource_type.includes('maws'));
  }
};

export const fetchDirects = (manager: string): Promise<any> => {
  return RestAPI.get('RISK_REMEDIATOR_API', '/fetch_direct_reports', {
    response: true,
    queryStringParameters: {
      manager: manager
    }
  })
    .then((response) => {
      if (response.data.details) {
        return JSON.parse(response.data.details);
      }
    })
    .catch((error) => {
      logger.error('Failed to fetch direct reports', error);
      useFinTechContext().displayNotification(error, RISK_REMEDIATOR_DIRECTS_API_ERROR);
    });
};
export const getPanelContent: any = (items: any, type: any, openRisk: [] = []) => {
  if (type === 'single') {
    if (!items.length) {
      return SPLIT_PANEL_CONFIG.EMPTY_PANEL_CONTENT;
    }

    const item = items[0];
    const review_status = item.review_current_status || item.application_certification_status;
    const default_msg_key = ['INPROGRESS', 'INITIATED', 'COMPLETE'].includes(review_status) ? 'notApplicable' : 'reviewNotInitiated';
    return {
      header: item.asr_application_name,
      body: (
        <KeyValuePairs
          columns={3}
          items={[
            { label: 'Application', value: item.asr_application_name },
            { label: 'Current review status', value: review_status },
            { label: 'Review Progress Status', value: <ProgressBar value={item.review_progress_percentage} /> },
            { label: 'Profiling Task Status', value: item.profiling_task_status || ASR_DEFAULT_MESSAGE[default_msg_key] },
            { label: 'Review Start Date', value: item.review_start_date || ASR_DEFAULT_MESSAGE[default_msg_key] },
            { label: 'Review End Date', value: item.review_end_date || ASR_DEFAULT_MESSAGE[default_msg_key] },
            { label: 'Pending Task Count', value: item.total_task_count - item.completed_task_count || ASR_DEFAULT_MESSAGE.notApplicable },
            { label: 'Review Classification', value: item.review_classification || ASR_DEFAULT_MESSAGE.dataNotClassified }
          ]}
        />
      )
    };
  }
};

export const fetchRiskRemediatorAsrData = (manager: string): Promise<any> => {
  return RestAPI.get('RISK_REMEDIATOR_API', '/fetch_asr_details', {
    response: true,
    queryStringParameters: {
      manager: manager
    }
  })
    .then((response) => {
      if (response.data.details) {
        return response.data;
      }
    })
    .catch((error) => {
      logger.error('Failed to fetch direct reports', error);
      useFinTechContext().displayNotification(error, RISK_REMEDIATOR_DIRECTS_API_ERROR);
    });
};

export const fetchRiskRemediatorVpcData = (manager: string): Promise<any> => {
  return RestAPI.get('RISK_REMEDIATOR_API', '/fetch_vpc_details', {
    response: true,
    queryStringParameters: {
      manager: manager
    }
  })
    .then((response) => {
      if (response.data.details) {
        return response.data;
      }
    })
    .catch((error) => {
      logger.error('Failed to fetch vpc details', error);
      useFinTechContext().displayNotification(error, RISK_REMEDIATOR_DIRECTS_API_ERROR);
    });
};

export const fetchOpenRisksForAsrApp = (asrAppName: string, openRisk: []) => {
  const violationList: string[] = [];
  const openRiskDetails: Record<string, string>[] = openRisk.filter((obj: Record<string, string>) => obj.resource_arn == `${asrAppName}`);
  openRiskDetails.forEach((risk: Record<string, string>) => {
    violationList.push(risk.issue_detected);
  });
  return violationList.toString();
};

export const getTop5RiskCategories = (selectCategorySummaryData: Array<{ title: string; value: number }>) => {
  // Sort the array in descending order based on the 'value' (risk count)
  const sortedData = [...selectCategorySummaryData].sort((a, b) => b.value - a.value);

  // Slice the array to get only the top 5 items
  const top5 = sortedData.slice(0, 5);

  return top5;
};
