import React from 'react';
import { Box, ColumnLayout, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { BasicMetricContainer, ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { FinTechOpsCategories, FinTechOpsCategoriesLabelColors, FinTechOpsHref, FinTechOpsMetricsDescriptions } from '../FinTechOpsConstants';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';

export const FinTechOpsUseMetrics = () => {
  const finOpsContext = useFinTechContext();
  const userMetrics = finOpsContext.finTechOpsUserMetricsDashBoard;

  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          User Metrics
        </Box>
        <LastRefreshedAt lastRefreshedDateTime={finOpsContext.userMetricsWithUserData.metadata.last_updated_at} />
      </SpaceBetween>

      <div>
        {finOpsContext.finTechOpsUserMetricsDataStatus === 'loading' && <LoadingSpinner />}
        {finOpsContext.finTechOpsUserMetricsDataStatus === 'pending' && <LoadingSpinner />}
        {finOpsContext.finTechOpsUserMetricsDataStatus === 'error' && <ErrorContainer />}
        {finOpsContext.finTechOpsUserMetricsDataStatus === 'finished' && (
          <ColumnLayout columns={5} minColumnWidth={10}>
            <BasicMetricContainer
              title="Total Applications"
              value={userMetrics.total_applications ? (userMetrics.total_applications as unknown as string) : '0'}
              unit=""
              href={FinTechOpsHref.userMetrics}
              id={FinTechOpsCategories.userMetrics}
              color={FinTechOpsCategoriesLabelColors.userMetrics}
              description={FinTechOpsMetricsDescriptions.userMetricsTotalApplications}
            />
            <BasicMetricContainer
              title="Avg App Viewship Count"
              value={userMetrics.avg_view_per_application ? (userMetrics.avg_view_per_application as unknown as string) : '0'}
              unit=""
              href={FinTechOpsHref.userMetrics}
              id={FinTechOpsCategories.userMetrics}
              color={FinTechOpsCategoriesLabelColors.userMetrics}
              description={FinTechOpsMetricsDescriptions.userMetricsAverageViewership}
            />
            <BasicMetricContainer
              title="Current Month - Top App with Unique Users"
              value={userMetrics.app_most_unique_users ? (userMetrics.app_most_unique_users as unknown as string) : '0'}
              unit=""
              href={FinTechOpsHref.userMetrics}
              id={FinTechOpsCategories.userMetrics}
              color={FinTechOpsCategoriesLabelColors.userMetrics}
              description={FinTechOpsMetricsDescriptions.currentMontnTopAppWithUniqueUsers}
            />
            <BasicMetricContainer
              title="Current Month - Most Viewed App"
              value={userMetrics.most_viewed_application ? (userMetrics.most_viewed_application as unknown as string) : '0'}
              unit=""
              href={FinTechOpsHref.userMetrics}
              id={FinTechOpsCategories.userMetrics}
              color={FinTechOpsCategoriesLabelColors.userMetrics}
              description={FinTechOpsMetricsDescriptions.currentMontnTopAppWithUsers}
            />
          </ColumnLayout>
        )}
      </div>
    </Box>
  );
};
