import { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';

const appLayoutNavigationLabels: Partial<AppLayoutProps.Labels> = {
  navigationClose: 'Close navigation',
  navigationToggle: 'Toggle navigation'
};

const appLayoutToolsLabels: Partial<AppLayoutProps.Labels> = {
  tools: 'Tool bar',
  toolsClose: 'Close tool bar',
  toolsToggle: 'Open tool bar'
};

const distributionSelectionLabels = {
  itemSelectionLabel: (data: any, row: { id: any }) => `select ${row.id}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Distribution selection'
};
const headerLabel = (title: any, sorted: any, descending: any) => {
  return `${title}, ${sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted'}.`;
};

export const addColumnSortLabels = (columns: any[]) =>
  columns.map((col: { sortingField: any; header: any }) => ({
    ariaLabel: col.sortingField
      ? (sortState: { sorted: any; descending: any }) => headerLabel(col.header, sortState.sorted, sortState.descending)
      : undefined,
    ...col
  }));

const appLayoutLabels: AppLayoutProps.Labels = { ...appLayoutNavigationLabels, ...appLayoutToolsLabels };

export { appLayoutNavigationLabels, appLayoutToolsLabels, appLayoutLabels, distributionSelectionLabels };
