import { AppLayout, Box, Flashbar, Grid } from '@amzn/awsui-components-react';
import React from 'react';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { DFPHomePageSideNavigation } from '../../dfp-home-page/DFPHomePageSideNavigation';
import { FinTechOpsSelectionFilters } from './FinTechOpsSelectionFilters';
import { FinTechOpsTicket } from './FinTechOpsTicket';
import { FinTechOpsSas } from './FinTechOpsSas';
import { FinTechOpsUseMetrics } from './FinTechOpsUserMetrics';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer } from '../FinTechOpsCommonElements';
import { FinTechOpsDashboardHelpPanel } from './FinTechOpsDashBoardHelpPanel';
import { FinTechOpsPolicyEngine } from './FinTechOpsPolicyEngine';
import { FinTechOpsDogma } from './FinTechOpsDogma';
import { FinTechOpsCostMetrics } from './FinTechOpsCostMetrics';

export const FinTechOpsPage: React.FC = () => {
  const finOpsContext = useFinTechContext();

  return (
    <AppLayout
      contentType="cards"
      tools={<FinTechOpsDashboardHelpPanel />}
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={finOpsContext.flashbarItems} />}
      maxContentWidth={Number.MAX_VALUE}
      content={
        <Box margin={{ bottom: 'xxxl' }} padding={{ bottom: 'xxxl' }}>
          <FinTechOpsSelectionFilters />
          {finOpsContext.dashboardFilterDataCalculationStatus == 'loading' && <LoadingSpinner />}
          {finOpsContext.dashboardFilterDataCalculationStatus == 'error' && <ErrorContainer />}
          {finOpsContext.dashboardFilterDataCalculationStatus == 'finished' && (
            <>
              <FinTechOpsTicket />
              <FinTechOpsUseMetrics />
              <FinTechOpsSas />
              <FinTechOpsPolicyEngine />
              <FinTechOpsDogma />
              <FinTechOpsCostMetrics />
            </>
          )}
        </Box>
      }
    />
  );
};
