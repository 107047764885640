import React from 'react';
import { Box, ColumnLayout, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { BasicMetricContainer, ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { FinTechOpsCategories, FinTechOpsCategoriesLabelColors, FinTechOpsHref, FinTechOpsMetricsDescriptions } from '../FinTechOpsConstants';

export const FinTechOpsDogma: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const dogmaDashboardData = finOpsContext.finTechOpsDogmaDashboardData;
  const finTechOpsGogmaParsedData = finOpsContext.finTechOpsDogmaParsedData;

  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          Dogma
        </Box>
        <LastRefreshedAt lastRefreshedDateTime={finTechOpsGogmaParsedData.metadata.last_updated_at} />
      </SpaceBetween>
      <div>{finOpsContext.finTechOpsDogmaDataStatus === 'loading' && <LoadingSpinner />}</div>
      <div>{finOpsContext.finTechOpsDogmaDataStatus === 'pending' && <LoadingSpinner />}</div>
      <div>{finOpsContext.finTechOpsDogmaDataStatus === 'error' && <LoadingSpinner />}</div>
      <div>
        {finOpsContext.finTechOpsDogmaDataStatus === 'finished' && (
          <ColumnLayout columns={5} minColumnWidth={10}>
            <BasicMetricContainer
              title="Total Risks"
              value={dogmaDashboardData.totalDogmaViolations ? numberFormatter(dogmaDashboardData.totalDogmaViolations) : '0'}
              unit=""
              href={FinTechOpsHref.dogma}
              id={FinTechOpsCategories.dogma}
              color={FinTechOpsCategoriesLabelColors.dogma}
              description={FinTechOpsMetricsDescriptions.dogmaTotalRisks}
            />
            <BasicMetricContainer
              title="Resolved Risks"
              value={dogmaDashboardData.resolvedViolations ? numberFormatter(dogmaDashboardData.resolvedViolations) : '0'}
              unit=""
              href={FinTechOpsHref.dogma}
              id={FinTechOpsCategories.dogma}
              color={FinTechOpsCategoriesLabelColors.dogma}
              description={FinTechOpsMetricsDescriptions.dogmaSolved}
            />
            <BasicMetricContainer
              title="Open Risks"
              value={dogmaDashboardData.openViolations ? numberFormatter(dogmaDashboardData.openViolations) : '0'}
              unit=""
              href={FinTechOpsHref.dogma}
              id={FinTechOpsCategories.dogma}
              color={FinTechOpsCategoriesLabelColors.dogma}
              description={FinTechOpsMetricsDescriptions.dogmaUnsolved}
            />
            <BasicMetricContainer
              title="Open High Risks"
              value={dogmaDashboardData.openWithHishRisksViolations ? numberFormatter(dogmaDashboardData.openWithHishRisksViolations) : '0'}
              unit=""
              href={FinTechOpsHref.dogma}
              id={FinTechOpsCategories.dogma}
              color={FinTechOpsCategoriesLabelColors.dogma}
              description={FinTechOpsMetricsDescriptions.dogmaTotalOpenHighRisks}
            />
            <BasicMetricContainer
              title="Resolution rate"
              value={dogmaDashboardData.resolutionRate ? `${dogmaDashboardData.resolutionRate}%` : '0'}
              unit=""
              href={FinTechOpsHref.dogma}
              id={FinTechOpsCategories.dogma}
              color={FinTechOpsCategoriesLabelColors.dogma}
              description={FinTechOpsMetricsDescriptions.dogmaResolutionRate}
            />
          </ColumnLayout>
        )}
      </div>
    </Box>
  );
};
