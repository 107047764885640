import {
  Button,
  CodeEditorProps,
  ColumnLayout,
  Container,
  ContentLayout,
  DatePicker,
  Form,
  FormField,
  Header,
  Input,
  Link,
  Select,
  SelectProps,
  SpaceBetween,
  Textarea
} from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { getCurrentUTCTimeZoneInISO } from 'src/utilities/DateTimeUtilities';
import { useNavigate, useParams } from 'react-router-dom';
import { useAnnouncements } from './AdminAnnouncements';
import { labelInputToSelectDropdown, selectDropdownToLabel } from 'src/components/generic-components/LabelDropdownUtility';
import * as mutations from 'src/graphql/mutations';
import { API } from 'aws-amplify';
import { logger } from 'src/logger';
import { AnnouncementEntity, ApplicationFlatTableEntity } from 'src/components/context/AppContextModels';
import { eFinSuiteAnnouncementMessages } from 'src/constants/AppConstants';
// import CodeEditor from '@amzn/awsui-components-react/polaris/code-editor';
// import * as ace from 'ace-builds/src-noconflict/ace';
// import 'ace-builds/css/ace.css';
// import 'ace-builds/css/theme/dawn.css';
// import 'ace-builds/css/theme/tomorrow_night_bright.css';
// import ReactMarkdown from 'react-markdown';
const VALID_URL = 'Enter a valid URL';

const INITIAL_VALUES: AnnouncementEntity = {
  id: '',
  title: '',
  applicationName: { id: '', label: '', labelType: 'ApplicationName' },
  announcementCategory: { id: '', label: '', labelType: 'AnnouncementCategory' },
  announcement: '',
  announcementDate: '',
  announcementLink: '',
  itemMetadata: {
    isActive: true,
    createdBy: '',
    createdTime: '',
    updatedBy: '',
    updatedTime: ''
  }
};
const REQUIRED_FIELD = 'Required field';
const MAX_ANNOUNCEMENT_TITLE = 126;
const AnnouncementSchema = yup.object().shape({
  applicationName: yup.object().shape({
    label: yup.string().required(REQUIRED_FIELD),
    id: yup.string().required(REQUIRED_FIELD)
  }),
  announcementCategory: yup.object().shape({
    label: yup.string().required(REQUIRED_FIELD),
    id: yup.string().required(REQUIRED_FIELD)
  }),
  title: yup.string().required(REQUIRED_FIELD).max(MAX_ANNOUNCEMENT_TITLE, `Max allowed characters are ${MAX_ANNOUNCEMENT_TITLE}`),
  announcement: yup.string().required(REQUIRED_FIELD),
  announcementDate: yup.string().required(REQUIRED_FIELD),
  announcementLink: yup.string().url(VALID_URL).nullable()
});

export const AnnouncementCategories: SelectProps.Options = [
  {
    label: 'Launch update', // Keep the the same value for label & value
    value: 'Launch update'
  },
  {
    label: 'General',
    value: 'General'
  }
];

const ManageAnnouncements: React.FC = () => {
  const appContext = useAppContext();
  const userAuthDetails = useAuth();
  const { id: announcementId } = useParams();
  const navigate = useNavigate();
  const [action, setAction] = useState<'Create' | 'Update'>();
  const { refreshAnnouncements, displayFlashMessage } = useAnnouncements();

  const announcementFormRef = useRef<FormikProps<AnnouncementEntity>>(null);
  const [announcementFormInitialValues, setAnnouncementFormInitialValues] = useState<AnnouncementEntity>(INITIAL_VALUES);

  // Will be adding these features in P1 release.
  // const [value, setValue] = useState('');
  // const [preferences, setPreferences] = useState<CodeEditorProps.Preferences>({ wrapLines: true, theme: 'dawn' });
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (announcementId) {
      const announcement = appContext.announcements.find((announcement) => announcement.id === announcementId);
      if (announcement) {
        setAction('Update');
        setAnnouncementFormInitialValues({ ...announcement });
      } else {
        setAction('Create');
        setAnnouncementFormInitialValues({
          ...INITIAL_VALUES,
          itemMetadata: fetchDateTime()
        });
      }
    } else {
      setAction('Create');
      setAnnouncementFormInitialValues({
        ...INITIAL_VALUES,
        itemMetadata: fetchDateTime()
      });
    }
  }, [appContext.announcements]);

  const fetchDateTime = () => {
    return {
      isActive: true,
      createdBy: userAuthDetails.Alias,
      createdTime: getCurrentUTCTimeZoneInISO(),
      updatedBy: userAuthDetails.Alias,
      updatedTime: getCurrentUTCTimeZoneInISO()
    };
  };

  const onSubmit = async (values: AnnouncementEntity, helpers: FormikHelpers<AnnouncementEntity>) => {
    announcementFormRef?.current
      ?.validateForm(values)
      .then((response) => {
        let announcement = announcementFormRef.current?.values as AnnouncementEntity;
        announcement = {
          ...announcement,
          itemMetadata: {
            ...announcement.itemMetadata,
            isActive: announcement.itemMetadata.isActive,
            createdBy: action === 'Create' ? userAuthDetails.Alias : announcement.itemMetadata.createdBy,
            createdTime: action === 'Create' ? getCurrentUTCTimeZoneInISO() : announcement.itemMetadata.createdTime,
            updatedBy: userAuthDetails.Alias,
            updatedTime: getCurrentUTCTimeZoneInISO()
          }
        };

        const crudMutation = action !== 'Create' ? updateAnnouncement(announcement) : createAnnouncement(announcement);

        crudMutation
          .then(async (response) => {
            const successMessage =
              action !== 'Create'
                ? eFinSuiteAnnouncementMessages.ANNOUNCEMENT_SUCCESSFULLY_UPDATED
                : eFinSuiteAnnouncementMessages.ANNOUNCEMENT_SUCCESSFULLY_ADDED;

            helpers.resetForm();
            logger.info(`Successfully ${action}d announcement.`, { info: response });
            displayFlashMessage(successMessage, 'success');
            refreshAnnouncements();
            navigate('/admin/announcements');
          })
          .catch((error: any) => {
            const failureMessage =
              action !== 'Create'
                ? eFinSuiteAnnouncementMessages.ANNOUNCEMENT_UPDATE_FAILED
                : eFinSuiteAnnouncementMessages.ANNOUNCEMENT_CREATION_FAILED;

            displayFlashMessage(failureMessage, 'error');
            logger.error(`Unable to submit Announcement request.`, error);
          });
      })
      .catch((error) => {
        logger.error('Validation error ', error);
      });
  };

  const createAnnouncement = async (announcement: AnnouncementEntity) => {
    const createResponse: any = await API.graphql({
      query: mutations.createAnnouncement,
      variables: { input: announcement }
    });
    return createResponse?.data?.createAnnouncement;
  };

  const updateAnnouncement = async (announcement: AnnouncementEntity) => {
    const updateApplicationResponse: any = await API.graphql({
      query: mutations.updateAnnouncement,
      variables: { input: announcement }
    });
    return updateApplicationResponse?.data?.updateAnnouncement;
  };

  const applicationsDropdown =
    appContext.parsedApplications
      ?.filter((app: ApplicationFlatTableEntity) => app.isActive)
      .map((application) => {
        return {
          label: application.applicationName || '',
          value: application.id || ''
        };
      }) || [];

  return (
    <>
      <Formik<AnnouncementEntity>
        innerRef={announcementFormRef}
        enableReinitialize
        initialValues={announcementFormInitialValues}
        validationSchema={AnnouncementSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Form>
                  <ContentLayout
                    header={
                      <Header
                        actions={
                          <SpaceBetween size="m" direction="horizontal">
                            <Button variant="normal" formAction="none" onClick={() => navigate('/admin/announcements')}>
                              Cancel
                            </Button>
                            <Button variant="primary" formAction="submit" disabled={!isValid}>
                              Save announcement
                            </Button>
                          </SpaceBetween>
                        }
                        variant="h1"
                      >
                        New Announcement
                      </Header>
                    }
                  >
                    <Container>
                      <SpaceBetween size="m" direction="vertical">
                        <FormField
                          label="Application name"
                          description=""
                          stretch={true}
                          errorText={touched.applicationName?.label && errors?.applicationName?.label}
                        >
                          <Select
                            placeholder="Choose application"
                            selectedOption={labelInputToSelectDropdown(values.applicationName)}
                            onChange={({ detail }) =>
                              setFieldValue('applicationName', selectDropdownToLabel(detail.selectedOption, 'ApplicationName'))
                            }
                            onBlur={() => setFieldTouched('applicationName.label', true, true)}
                            options={applicationsDropdown}
                            filteringType="auto"
                            selectedAriaLabel="Selected"
                          />
                        </FormField>

                        <FormField
                          label="Announcement category"
                          description=""
                          stretch={true}
                          errorText={touched.announcementCategory?.label && errors?.announcementCategory?.label}
                        >
                          <Select
                            placeholder="Choose category"
                            selectedOption={labelInputToSelectDropdown(values.announcementCategory)}
                            onChange={({ detail }) =>
                              setFieldValue('announcementCategory', selectDropdownToLabel(detail.selectedOption, 'AnnouncementCategory'))
                            }
                            onBlur={() => setFieldTouched('announcementCategory.label', true, true)}
                            options={AnnouncementCategories}
                            selectedAriaLabel="Selected"
                          />
                        </FormField>

                        <FormField label="Announcement date" description="" stretch={true}>
                          <DatePicker
                            onChange={({ detail }) => setFieldValue('announcementDate', detail.value)}
                            value={values.announcementDate}
                            openCalendarAriaLabel={(selectedDate) =>
                              'Choose certificate expiry date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                          />
                        </FormField>

                        <FormField
                          label="Title"
                          description=""
                          stretch={true}
                          constraintText={characterCountConstraintMessage(MAX_ANNOUNCEMENT_TITLE, values.title.length)}
                          errorText={touched.title && errors.title}
                        >
                          <Input
                            placeholder="Enter announcement title"
                            value={values.title}
                            onChange={(event) => setFieldValue('title', event.detail.value)}
                            onBlur={() => setFieldTouched('title', true, true)}
                          />
                        </FormField>

                        {/* Will be adding these features in P1 release.  */}
                        {/* <FormField
                          label="Announcement"
                          description=""
                          stretch={true}
                          errorText={touched.announcement && errors.announcement}
                          constraintText={
                            <Link external externalIconAriaLabel="Opens in a new tab" href="https://commonmark.org/help/">
                              {'Markdown guide'}
                            </Link>
                          }
                        >
                          <div className="code-editor">
                            <CodeEditor
                              ace={ace}
                              language="markdown"
                              value={values.announcement}
                              onChange={({ detail }) => setFieldValue('announcement', detail.value)}
                              preferences={preferences}
                              onPreferencesChange={(e) => setPreferences(e.detail)}
                              loading={loading}
                              i18nStrings={{
                                loadingState: 'Loading code editor',
                                errorState: 'There was an error loading the code editor.',
                                errorStateRecovery: 'Retry',
                                editorGroupAriaLabel: 'Code editor',
                                statusBarGroupAriaLabel: 'Status bar',
                                cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
                                errorsTab: 'Errors',
                                warningsTab: 'Warnings',
                                preferencesButtonAriaLabel: 'Preferences',
                                paneCloseButtonAriaLabel: 'Close',
                                preferencesModalHeader: 'Preferences',
                                preferencesModalCancel: 'Cancel',
                                preferencesModalConfirm: 'Confirm',
                                preferencesModalWrapLines: 'Wrap lines',
                                preferencesModalTheme: 'Theme',
                                preferencesModalLightThemes: 'Light themes',
                                preferencesModalDarkThemes: 'Dark themes'
                              }}
                            />

                            <Container>{<ReactMarkdown>{values.announcement}</ReactMarkdown>}</Container>
                          </div>
                        </FormField> */}

                        <FormField
                          label={
                            <>
                              Announcement Link<i> - optional</i>
                            </>
                          }
                          description=""
                          stretch={true}
                          errorText={touched.announcementLink && errors.announcementLink}
                        >
                          <Input
                            onChange={({ detail }) => {
                              setFieldValue('announcementLink', detail.value);
                            }}
                            onBlur={() => setFieldTouched('announcementLink', true, true)}
                            value={values.announcementLink}
                            placeholder="Enter a valid URL"
                          />
                        </FormField>

                        <FormField label="Announcement" description="" stretch={true} errorText={touched.announcement && errors.announcement}>
                          <Textarea
                            onChange={({ detail }) => {
                              setFieldValue('announcement', detail.value);
                            }}
                            onBlur={() => setFieldTouched('announcement', true, true)}
                            value={values.announcement}
                            placeholder="Short description"
                          />
                        </FormField>

                        {/* Just for debugging purposes in dev environment  */}
                        {/* <DisplayContentInCode details={{ ...values, errors, touched }}></DisplayContentInCode> */}
                      </SpaceBetween>
                    </Container>
                  </ContentLayout>
                </Form>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ManageAnnouncements;

/*
"*Italic*\n\n**Bold**\n\n# Heading 1\n\n## Heading 2\n\n[Link](http://a.com)\n\n> Blockquote\n\n* List\n* List\n* List\n\n1. One\n2. Two\n3. Three\n\nHorizontal rule:\n\n---\n\n`Inline code` with backticks\t\n\n```\n# code block\nprint '3 backticks or'\nprint 'indent 4 spaces'\n```\n\n\n\n"
*/
