import { Box, Container, Header, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import React, { useState } from 'react';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import {
  DetailsTableWithProps,
  DetailsTableWithPropsMultiSelectDropDown,
  DetailsTableWithPropsNoPag
} from '../fintech-ops-charts/TableWithPropsCommonComponent';
import { indicatorIcon, indicatorIconVariant } from '../fintech-ops-dashboard/FinTechOpsUtils';
import { useTicketContext } from './TicketsContext';
import styles from './styles.module.scss';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';

export const TicketDetailsPage: React.FC = () => {
  const ticketContext = useTicketContext();
  const finOpsContext = useFinTechContext();
  const aggTableApplicationsColumnDefinitions = ticketContext.aggregatedDataTableApplications.columnData;
  const aggTableTeamsColumnDefinitions = ticketContext.aggregatedDataTableTeams.columnData;

  const [checked, setChecked] = useState(false);

  return (
    <div>
      {ticketContext.statusOfDetailsTab == 'loading' && <LoadingSpinner />}
      {ticketContext.statusOfDetailsTab == 'error' && <ErrorContainer />}
      {ticketContext.statusOfDetailsTab == 'pending' && <NotInitializedContainer />}
      {ticketContext.statusOfDetailsTab == 'finished' && (
        <SpaceBetween direction="vertical" size="xxl">
          <Grid
            gridDefinition={[
              { colspan: { default: 12, xxs: 12, xs: 12, s: 3, m: 3, l: 3, xl: 3 } },
              { colspan: { default: 12, xxs: 12, xs: 12, s: 9, m: 9, l: 9, xl: 9 } }
            ]}
          >
            <Container variant="stacked" fitHeight header={<Header>Total Tickets</Header>}>
              <div className={styles.marginBottom}>
                <Box textAlign="center" variant="awsui-key-label" fontSize="heading-xl">
                  {ticketContext.openTickets ? numberFormatter(ticketContext.openTickets) : '-'}
                </Box>
              </div>
              <Box textAlign="center">
                <div className={styles.openTicketWidthContainer}>
                  <div className={styles.openTicketWidth}>
                    <Box variant="awsui-key-label">DoD</Box>
                    <p>
                      {numberFormatter(Math.abs(ticketContext.openTicketContainer[0].dod)) || '-'}
                      <Icon
                        name={indicatorIcon(ticketContext.openTicketContainer[0].dod)}
                        variant={indicatorIconVariant(ticketContext.openTicketContainer[0].dod)}
                      />
                    </p>
                  </div>
                  <div className={styles.openTicketWidth}>
                    <Box variant="awsui-key-label">WoW</Box>
                    <p>
                      {numberFormatter(Math.abs(ticketContext.openTicketContainer[0].wow)) || '-'}
                      <Icon
                        name={indicatorIcon(ticketContext.openTicketContainer[0].wow)}
                        variant={indicatorIconVariant(ticketContext.openTicketContainer[0].wow)}
                      />
                    </p>
                  </div>
                  <div className={styles.openTicketWidth}>
                    <Box variant="awsui-key-label">MoM</Box>
                    <p>
                      {numberFormatter(Math.abs(ticketContext.openTicketContainer[0].mom)) || '-'}
                      <Icon
                        name={indicatorIcon(ticketContext.openTicketContainer[0].mom)}
                        variant={indicatorIconVariant(ticketContext.openTicketContainer[0].mom)}
                      />
                    </p>
                  </div>
                </div>
              </Box>
              <Box variant="h4" fontWeight="normal" textAlign="left" padding={{ bottom: 'm' }}>
                Total Open Tickets
              </Box>
              <SpaceBetween direction="horizontal" size="xl" alignItems="center">
                {Object.entries(ticketContext.openTicketsByTeam).map(([key, value]) => (
                  <Box key={key}>
                    <Box variant="awsui-key-label" color="text-label">
                      {key}
                    </Box>
                    <Box fontWeight="bold" textAlign="center">
                      {value}
                    </Box>
                  </Box>
                ))}
              </SpaceBetween>
            </Container>
            <Container
              fitHeight
              header={
                <>
                  <SpaceBetween size="m" direction="horizontal" alignItems="center">
                    <Header>Ticket Aggregated Details</Header>
                    <Toggle onChange={({ detail }) => setChecked(detail.checked)} checked={checked}>
                      {!checked ? 'Applications' : 'Teams'}
                    </Toggle>
                  </SpaceBetween>
                </>
              }
            >
              {!checked ? (
                <div className={styles.ticketAggregatedDetailsTable}>
                  <DetailsTableWithPropsNoPag
                    pageSize={100}
                    emptyMessage="No Tickets to Display"
                    FILTERING_PROPERTIES={[]}
                    VISIBLE_CONTENT={ticketContext.aggregatedDataTableApplications.visibleColumns}
                    COLUMN_DEFINITIONS={aggTableApplicationsColumnDefinitions}
                    Data={ticketContext.aggregatedDataTableApplications.rowData}
                  />
                </div>
              ) : (
                <DetailsTableWithPropsNoPag
                  pageSize={100}
                  emptyMessage="No Tickets to Display"
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={ticketContext.aggregatedDataTableTeams.visibleColumns}
                  COLUMN_DEFINITIONS={aggTableTeamsColumnDefinitions}
                  Data={ticketContext.aggregatedDataTableTeams.rowData}
                />
              )}
            </Container>
          </Grid>
          <Container>
            <DetailsTableWithProps
              pageSize={10}
              headerName="Open Ticket Details"
              emptyMessage="No Tickets to Display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={ticketContext.ticketDetailsTable.visibleColumns}
              COLUMN_DEFINITIONS={ticketContext.ticketDetailsTable.columnData}
              Data={ticketContext.ticketDetailsTable.rowData}
            />
          </Container>
          {/* Container for Resolved Ticket Details  */}
          <Container>
            <DetailsTableWithPropsMultiSelectDropDown
              pageSize={10}
              headerName="Resolved Ticket Details"
              emptyMessage="No Tickets to Display"
              FILTERING_PROPERTIES={[]}
              VISIBLE_CONTENT={ticketContext.ticketResolvedDetailsTable.visibleColumns}
              COLUMN_DEFINITIONS={ticketContext.ticketResolvedDetailsTable.columnData}
              Data={ticketContext.ticketResolvedDetailsTable.rowData}
              tableLoadingText="Loading Resolved Ticket Details"
              detailsType="ticketResolvedDetails"
              multiSelectPlaceholder={['Resolved Month', 'Resolution Type']}
              rawDropDownValues={[ticketContext.resolvedMonthsList, ticketContext.resolvedResolutionTypeList]}
              statusType={finOpsContext.dashboardFilterStatus}
              disabledValue={ticketContext.statusOfGraphTab}
            />
          </Container>
        </SpaceBetween>
      )}
    </div>
  );
};
