import { Box, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { BasicMetricContainer, ErrorContainer } from '../FinTechOpsCommonElements';
import { FinTechOpsCategories, FinTechOpsCategoriesLabelColors, FinTechOpsHref, FinTechOpsMetricsDescriptions } from '../FinTechOpsConstants';
import { MinutesToHoursConverter } from './FinTechOpsUtils';
import React from 'react';
import { numberFormatter } from 'src/utilities/CommonUtilities';

export const FinTechOpsTicket: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const ticketDetails = finOpsContext.finTechOpsTicketDashboard;

  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          Tickets
        </Box>
        <LastRefreshedAt lastRefreshedDateTime={finOpsContext.finTechOpsTicketsData.metadata.last_updated_at} />
      </SpaceBetween>
      <div>
        {finOpsContext.finTechOpsTicketDataStatus == 'loading' && <LoadingSpinner />}
        {finOpsContext.finTechOpsTicketDataStatus == 'pending' && <LoadingSpinner />}
        {finOpsContext.finTechOpsTicketDataStatus == 'error' && <ErrorContainer />}
        {finOpsContext.finTechOpsTicketDataStatus == 'finished' && (
          <ColumnLayout columns={5} minColumnWidth={10}>
            <BasicMetricContainer
              title="Total Tickets"
              value={ticketDetails.totalTicketsCount ? numberFormatter(ticketDetails.totalTicketsCount) : '0'}
              unit=""
              href={FinTechOpsHref.tickets}
              id={FinTechOpsCategories.tickets}
              color={FinTechOpsCategoriesLabelColors.tickets}
              description={FinTechOpsMetricsDescriptions.totalTickets}
            />
            <BasicMetricContainer
              title="Resolved Tickets"
              value={ticketDetails.solvedTicketCount ? numberFormatter(ticketDetails.solvedTicketCount) : '0'}
              unit=""
              href={FinTechOpsHref.tickets}
              id={FinTechOpsCategories.tickets}
              color={FinTechOpsCategoriesLabelColors.tickets}
              description={FinTechOpsMetricsDescriptions.ticketsSolvedTickets}
            />
            <BasicMetricContainer
              title="Open Tickets"
              value={ticketDetails.unSolvedTicketCount ? numberFormatter(ticketDetails.unSolvedTicketCount) : '0'}
              unit=""
              href={FinTechOpsHref.tickets}
              id={FinTechOpsCategories.tickets}
              color={FinTechOpsCategoriesLabelColors.tickets}
              description={FinTechOpsMetricsDescriptions.ticketsUnsolvedTickets}
            />
            <BasicMetricContainer
              title="Resolution Rate"
              value={ticketDetails.resolutionRate ? `${numberFormatter(ticketDetails.resolutionRate)}%` : '0'}
              unit=""
              href={FinTechOpsHref.tickets}
              id={FinTechOpsCategories.tickets}
              color={FinTechOpsCategoriesLabelColors.tickets}
              description={FinTechOpsMetricsDescriptions.ticketsResolutionRate}
            />
            <BasicMetricContainer
              title="Median Resolution Time"
              value={ticketDetails.medianResolutionTimeInMinutes ? MinutesToHoursConverter(ticketDetails.medianResolutionTimeInMinutes) : '0'}
              unit=""
              href={FinTechOpsHref.tickets}
              id={FinTechOpsCategories.tickets}
              color={FinTechOpsCategoriesLabelColors.tickets}
              description={FinTechOpsMetricsDescriptions.ticketsFirstResolutionTimeAvg}
            />
            {/* <BasicMetricContainer
              title="First Reply Time AVG"
              value={ticketDetails.firstResponseTimeAvgInMinutes ? MinutesToHoursConverter(ticketDetails.firstResponseTimeAvgInMinutes) : '0'}
              unit=""
              href={FinTechOpsHref.tickets}
              id={FinTechOpsCategories.tickets}
              color={FinTechOpsCategoriesLabelColors.tickets}
              description={FinTechOpsMetricsDescriptions.ticketsFirstReplyTimeAvg}
            /> */}
          </ColumnLayout>
        )}
      </div>
    </Box>
  );
};
