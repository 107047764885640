import { Box, Button, ColumnLayout, Container, FormField, Multiselect, MultiselectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';
import {
  objectToOptions,
  objectToOptionsForMonths,
  optionsToObject,
  parseObjectToMultiSelectOptions_Sorted,
  returnFilterAgeSorted,
  sortDistinctMonths_MM_YYYY
} from '../FinTechOpsDataProcessingFunctions';
import {
  applyFiltersPolicyEngineMetrics,
  createPolicyEngineFilterData,
  getCalculatedDetailsTabData,
  getCalculatedGraphData
} from './PolicyEngineDataProcessing';
import { PolicyEngineFilters, PolicyEngineGraphData, PolicyEngineParsedDataItem } from './PolicyEngineInterface';
import { dateTimeComparatorMMMYYYYFormat, getCurrentTime, getTimeDifference, sortedDateArrayMMMYYYYFormat } from 'src/utilities/DateTimeUtilities';
import { usePolicyEngineContext } from './PolicyEngineContext';
import { logger } from 'src/logger';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';

export const PolicyEngineSelectionFilters: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const policyEngineContext = usePolicyEngineContext();
  const policyEngineParsedData: PolicyEngineParsedDataItem[] = finOpsContext?.finTechOpsPolicyEngineParsedData?.data || [];

  const [lastRefreshed, setLastRefreshed] = useState('');

  const [masterFilterData, setMasterFilterData] = useState<PolicyEngineFilters>({
    team: [],
    managers: [],
    assignees: [],
    resolutionTypes: [],
    severityTypes: [],
    timePeriods: [],
    policyClasses: []
  });

  const [teamsDropdown, setTeamsDropdown] = useState<MultiselectProps.Options>([]);
  const [managersDropdown, setManagersDropdown] = useState<MultiselectProps.Options>([]);
  const [assigneesDropdown, setAssigneesDropdown] = useState<MultiselectProps.Options>([]);
  const [resolutionTypesDropdown, setResolutionTypesDropdown] = useState<MultiselectProps.Options>([]);
  const [severityTypesDropdown, setSeverityTypesDropdown] = useState<MultiselectProps.Options>([]);
  const [timePeriodsDropdown, setTimePeriodsDropdown] = useState<MultiselectProps.Options>([]);
  const [policyClassesDropdown, setPolicyClassesDropdown] = useState<MultiselectProps.Options>([]);

  const [rawteamsDropdown, setRawTeamsDropdown] = useState<MultiselectProps.Options>([]);
  const [rawmanagersDropdown, setRawManagersDropdown] = useState<MultiselectProps.Options>([]);
  const [rawassigneesDropdown, setRawAssigneesDropdown] = useState<MultiselectProps.Options>([]);
  const [rawresolutionTypesDropdown, setRawResolutionTypesDropdown] = useState<MultiselectProps.Options>([]);
  const [rawseverityTypesDropdown, setRawSeverityTypesDropdown] = useState<MultiselectProps.Options>([]);
  const [rawtimePeriodsDropdown, setRawTimePeriodsDropdown] = useState<MultiselectProps.Options>([]);
  const [rawpolicyClassesDropdown, setRawPolicyClassesDropdown] = useState<MultiselectProps.Options>([]);

  const [isRawFilter, setIsRawFilter] = useState(true);

  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    if (finOpsContext.finTechOpsPolicyEngineDataStatus === 'finished') {
      const policyEngineFilterValues = getPolicyEngineFilters(policyEngineParsedData);
      updatePolicyEngineCalculation(policyEngineFilterValues);
      setLastRefreshed(finOpsContext?.finTechOpsPolicyEngineParsedData?.metadata?.last_updated_at);
      setIsResetFilter(false);
    }

    if (finOpsContext.finTechOpsPolicyEngineDataStatus == 'error') {
      policyEngineContext.displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
      policyEngineContext.setPolicyEngineDetailsTabStatus('error');
      policyEngineContext.setPolicyEngineGraphStatus('error');
    }
  }, [finOpsContext.finTechOpsPolicyEngineDataStatus, isResetFilter]);

  const getPolicyEngineFilters = (policyEngineParsedData: PolicyEngineParsedDataItem[]) => {
    try {
      const _masterFilterData = createPolicyEngineFilterData(policyEngineParsedData);
      setMasterFilterData(_masterFilterData);

      setTeamsDropdown(objectToOptions(_masterFilterData.team));
      setManagersDropdown(objectToOptions(_masterFilterData.managers));
      setAssigneesDropdown(objectToOptions(_masterFilterData.assignees));
      setResolutionTypesDropdown(objectToOptions(_masterFilterData.resolutionTypes));
      setSeverityTypesDropdown(objectToOptions(_masterFilterData.severityTypes));
      setTimePeriodsDropdown(objectToOptionsForMonths(_masterFilterData.timePeriods));
      setPolicyClassesDropdown(objectToOptions(_masterFilterData.policyClasses));

      //one time setting values for placeholder value comparison with raw filter and currently applied filter
      if (isRawFilter === true) {
        setRawTeamsDropdown(objectToOptions(_masterFilterData.team));
        setRawManagersDropdown(objectToOptions(_masterFilterData.managers));
        setRawAssigneesDropdown(objectToOptions(_masterFilterData.assignees));
        setRawResolutionTypesDropdown(objectToOptions(_masterFilterData.resolutionTypes));
        setRawSeverityTypesDropdown(objectToOptions(_masterFilterData.severityTypes));
        setRawTimePeriodsDropdown(objectToOptionsForMonths(_masterFilterData.timePeriods));
        setRawPolicyClassesDropdown(objectToOptions(_masterFilterData.policyClasses));
      }
      setIsRawFilter(false);

      return _masterFilterData;
    } catch (error: any) {
      logger.error('getPolicyEngineFilters: Unable to update Policy Engine filters', error);
      policyEngineContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      policyEngineContext.setPolicyEngineGraphStatus('error');
      policyEngineContext.setPolicyEngineDetailsTabStatus('error');
      return { team: [], managers: [], assignees: [], resolutionTypes: [], severityTypes: [], timePeriods: [], policyClasses: [] };
    }
  };

  const triggerCall = () => {
    const selectedTeams = optionsToObject(teamsDropdown);
    const selectedManagers = optionsToObject(managersDropdown);
    const selectedAssignees = optionsToObject(assigneesDropdown);
    const selectedResolutionTypes = optionsToObject(resolutionTypesDropdown);
    const selectedSeverityTypes = optionsToObject(severityTypesDropdown);
    const selectedTimePeriods = optionsToObject(timePeriodsDropdown);
    const selectedPolicyClasses = optionsToObject(policyClassesDropdown);
    updatePolicyEngineCalculation({
      team: selectedTeams,
      managers: selectedManagers,
      assignees: selectedAssignees,
      resolutionTypes: selectedResolutionTypes,
      severityTypes: selectedSeverityTypes,
      timePeriods: selectedTimePeriods,
      policyClasses: selectedPolicyClasses
    });
  };

  const updatePolicyEngineCalculation = (selectedFilterData: PolicyEngineFilters) => {
    policyEngineContext.setPolicyEngineGraphStatus('loading');
    policyEngineContext.setPolicyEngineDetailsTabStatus('loading');

    try {
      const start = getCurrentTime();

      const [filteredWithoutMonthData, appliedFiltersData]: [PolicyEngineParsedDataItem[], PolicyEngineParsedDataItem[]] =
        applyFiltersPolicyEngineMetrics(finOpsContext.finTechOpsPolicyEngineParsedData.data, selectedFilterData);

      getPolicyEngineFilters(appliedFiltersData);

      const calculatedGraphData: PolicyEngineGraphData = getCalculatedGraphData(appliedFiltersData);
      policyEngineContext.setPolicyEngineGraphData(calculatedGraphData);

      const calculatedDetalsTableData = getCalculatedDetailsTabData(appliedFiltersData, filteredWithoutMonthData);
      policyEngineContext.setPolicyEngineDetailsTabData(calculatedDetalsTableData);

      const elapsed = getTimeDifference(start);
      logger.info(`Fetched calculated data for Policy Engine in ${elapsed} ms`);

      policyEngineContext.setPolicyEngineGraphStatus('finished');
      policyEngineContext.setPolicyEngineDetailsTabStatus('finished');
    } catch (error: any) {
      logger.error('Unable to process Policy ENgine data', error);
      policyEngineContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      policyEngineContext.setPolicyEngineGraphStatus('error');
      policyEngineContext.setPolicyEngineDetailsTabStatus('error');
    }
  };

  // Manager, Assignee, Resolution type, severity type, month/time rollups, team, policy_class
  return (
    <Box padding={{ top: 'xl' }}>
      <Container
        fitHeight
        header={
          <SpaceBetween size="m" direction="horizontal" alignItems="center">
            <Box variant="h2">Policy Engine</Box>
            <LastRefreshedAt lastRefreshedDateTime={lastRefreshed} />
            <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="m" direction="vertical">
          <ColumnLayout columns={4} minColumnWidth={10}>
            <FormField label="Team">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(teamsDropdown, 'Team', optionsToObject(rawteamsDropdown))}
                selectedOptions={teamsDropdown}
                onChange={({ detail }) => setTeamsDropdown(detail.selectedOptions)}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.team, 'All Teams')}
                onBlur={() => triggerCall()}
                hideTokens
                expandToViewport
                loadingText="Loading Teams"
                errorText="Unable to load data"
                statusType={policyEngineContext.policyEngineGraphStatus}
              />
            </FormField>

            <FormField label="Manager">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(managersDropdown, 'Manager', optionsToObject(rawmanagersDropdown))}
                selectedOptions={managersDropdown}
                onChange={({ detail }) => setManagersDropdown(detail.selectedOptions)}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.managers, 'All Managers')}
                onBlur={() => triggerCall()}
                hideTokens
                expandToViewport
                loadingText="Loading Managers"
                errorText="Unable to load data"
                statusType={policyEngineContext.policyEngineGraphStatus}
              />
            </FormField>

            <FormField label="Assignee">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(assigneesDropdown, 'Assignee', optionsToObject(rawassigneesDropdown))}
                selectedOptions={assigneesDropdown}
                onChange={({ detail }) => setAssigneesDropdown(detail.selectedOptions)}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.assignees, 'All Assignees')}
                onBlur={() => triggerCall()}
                hideTokens
                expandToViewport
                loadingText="Loading Assignees"
                errorText="Unable to load data"
                statusType={policyEngineContext.policyEngineGraphStatus}
              />
            </FormField>

            <FormField label="Resolution Type">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(resolutionTypesDropdown, 'Resolution Type', optionsToObject(rawresolutionTypesDropdown))}
                selectedOptions={resolutionTypesDropdown}
                onChange={({ detail }) => setResolutionTypesDropdown(detail.selectedOptions)}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.resolutionTypes, 'All Resolution Types')}
                onBlur={() => triggerCall()}
                hideTokens
                expandToViewport
                loadingText="Loading Resolution Types"
                errorText="Unable to load data"
                statusType={policyEngineContext.policyEngineGraphStatus}
              />
            </FormField>
          </ColumnLayout>
          <ColumnLayout columns={4} minColumnWidth={10}>
            <FormField label="Severity">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(severityTypesDropdown, 'Severity', optionsToObject(rawseverityTypesDropdown))}
                selectedOptions={severityTypesDropdown}
                onChange={({ detail }) => setSeverityTypesDropdown(detail.selectedOptions)}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.severityTypes, 'All Severity')}
                onBlur={() => triggerCall()}
                hideTokens
                expandToViewport
                loadingText="Loading Severity"
                errorText="Unable to load data"
                statusType={policyEngineContext.policyEngineGraphStatus}
              />
            </FormField>

            <FormField label="Month">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(timePeriodsDropdown, 'Months', optionsToObject(rawtimePeriodsDropdown))}
                selectedOptions={timePeriodsDropdown}
                onChange={({ detail }) => setTimePeriodsDropdown(detail.selectedOptions)}
                options={returnFilterAgeSorted(sortedDateArrayMMMYYYYFormat(masterFilterData.timePeriods), 'All Months')}
                onBlur={() => triggerCall()}
                hideTokens
                expandToViewport
                loadingText="Loading Months"
                errorText="Unable to load data"
                statusType={policyEngineContext.policyEngineGraphStatus}
              />
            </FormField>

            <FormField label="Policy Class">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(policyClassesDropdown, 'Policy Class', optionsToObject(rawpolicyClassesDropdown))}
                selectedOptions={policyClassesDropdown}
                onChange={({ detail }) => setPolicyClassesDropdown(detail.selectedOptions)}
                options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.policyClasses, 'All Policy Classes')}
                onBlur={() => triggerCall()}
                hideTokens
                expandToViewport
                loadingText="Loading Policy Classes"
                errorText="Unable to load data"
                statusType={policyEngineContext.policyEngineGraphStatus}
              />
            </FormField>
          </ColumnLayout>
        </SpaceBetween>
      </Container>
    </Box>
  );
};
