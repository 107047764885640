import { BoardProps } from '@amzn/awsui-board-components';
import { StoredWidgetPlacement } from 'src/components/context/AppContextModels';
import { WidgetDataType } from '../../widgets/interface';

export function merge<T extends { id: string }>(src: ReadonlyArray<T>, overrides: ReadonlyArray<Partial<T> & { id: string }>): ReadonlyArray<T> {
  return src.map((item) => {
    const match = overrides.find((override) => override.id === item.id);
    return match ? { ...item, ...match } : item;
  });
}

export function exportLayout(items: ReadonlyArray<BoardProps.Item<WidgetDataType>>): ReadonlyArray<StoredWidgetPlacement> {
  return items.map((item) => ({
    id: item.id,
    columnSpan: item.columnSpan,
    columnOffset: item.columnOffset,
    rowSpan: item.rowSpan
  }));
}
