import { AppLayout, BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import Wizard from '@amzn/awsui-components-react/polaris/wizard';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { appLayoutAriaLabels, wizardI18nStrings } from 'src/i18n-strings';
import { AdminBaseBreadcrumbs } from '../../AdminHomePage';
import { ApplicationWizard, InfoPanelPageConfigurations } from '../../AdminModels';
import { AdminSideNavigation } from '../../AdminSideNavigation';
import ApplicationDetails from './Step1';
import LinksAndTags from './Step2';
import ConfigureHomepage from './Step3';
import { APIToWizard, INITIAL_STEP_INFO, useWizard } from './StepsUtilities';
import { ApplicationFormSchema } from './StepsValidations';
import DisplayContentInCode from 'src/components/generic-components/DisplayContentInCode';
import { LoadingStatus } from 'src/components/context/AppContextModels';
import { ConfirmationModal } from 'src/components/generic-components/ConfirmationModal';
import HelpPanelConfiguration from './Step4';
import { useTools } from './AdminNewAppToolContext';
import { InfoPanelDetailsHelpPanel } from './InfoPanelDetails';

const getNewAppBreadcrumbItems = (appName: string): BreadcrumbGroupProps.Item[] => {
  return [...AdminBaseBreadcrumbs, { text: 'Applications', href: '/admin/applications' }, { text: appName, href: '/admin/manage-application' }];
};

export const AdminNewAppWizardSteps: any[] = [
  {
    title: 'Enter application details',
    description: '',
    stateKey: 'applicationDetails',
    StepContent: ApplicationDetails,
    isOptional: false
  },
  {
    title: 'Enter links and tags',
    description: '',
    stateKey: 'linksAndTags',
    StepContent: LinksAndTags,
    isOptional: true
  },
  {
    title: 'Configure app widgets',
    description: '',
    stateKey: 'homePageConfigurations',
    StepContent: ConfigureHomepage,
    isOptional: true
  },
  {
    title: 'Enter info panel details',
    description: '',
    stateKey: 'helpPanelConfiguration',
    StepContent: HelpPanelConfiguration,
    isOptional: true
  }
];

export const AdminNewApp: React.FC = () => {
  const { id: applicationId } = useParams();
  const { pathname, state } = useLocation();
  const appContext = useAppContext();
  const existingApplicationNames = appContext?.listOfApplications?.map((application) => application.applicationName || '');
  const navigate = useNavigate();

  useEffect(() => {
    setFromUrl(state?.from ? state?.from : '');
  }, [pathname, state]);

  const [infoPanelPageConfiguration, setInfoPanelPageConfiguration] = useState<InfoPanelPageConfigurations>();

  const { toolsContent, isToolsOpen, setHelpPanelContent, closeTools, setFormattedToolsContent, onToolsChange, headerName, setHeaderName } =
    useTools();

  const {
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    action,
    setAction,
    applicationFormRef,
    onNavigate,
    onWizardCancel,
    onSubmit,
    showModalInfo,
    onCancel,
    onConfirm,
    fromUrl,
    setFromUrl
  } = useWizard();

  const [applicationWizardInitialValues, setApplicationWizardInitialValues] = useState<ApplicationWizard>(INITIAL_STEP_INFO);

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed) {
      if (applicationId) {
        setAction('Update');
        handleEditApplication(applicationId);
      } else {
        setAction('Create');
        setApplicationWizardInitialValues(INITIAL_STEP_INFO);
      }
    }
  }, [applicationId, appContext]);

  const handleEditApplication = async (applicationId: string) => {
    const selectedApplication = appContext.listOfApplications.find((application) => application.id === applicationId);
    selectedApplication ? setApplicationWizardInitialValues(APIToWizard(selectedApplication)) : navigate('/admin/manage-applications');
  };

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
      <AppLayout
        className="admin-console-app-wizard"
        headerSelector="#h"
        ariaLabels={appLayoutAriaLabels}
        navigation={<AdminSideNavigation />}
        breadcrumbs={
          <DFPBreadcrumbs
            items={getNewAppBreadcrumbItems(
              applicationWizardInitialValues.applicationDetails.applicationName === ''
                ? 'New Application'
                : applicationWizardInitialValues.applicationDetails.applicationName || ''
            )}
          />
        }
        contentType="wizard"
        // only show the tools panel when the active step is the Info Panel
        tools={<InfoPanelDetailsHelpPanel headerName="Preview" infoPanelPageConfiguration={infoPanelPageConfiguration} />}
        toolsOpen={activeStepIndex === 3}
        onToolsChange={onToolsChange}
        content={
          <div className="app-layout-body">
            <Formik<ApplicationWizard>
              innerRef={applicationFormRef}
              enableReinitialize
              initialValues={applicationWizardInitialValues}
              validationSchema={ApplicationFormSchema(
                existingApplicationNames,
                applicationWizardInitialValues.applicationDetails.applicationName !== ''
              )}
              onSubmit={onSubmit}
            >
              {(formik) => {
                useEffect(() => {
                  setInfoPanelPageConfiguration(formik.values.infoPanelPageConfiguration);
                }),
                  [formik];
                return (
                  <>
                    <form onSubmit={formik.handleSubmit}>
                      <Wizard
                        steps={AdminNewAppWizardSteps.map(({ title, description, stateKey, StepContent, isOptional }) => ({
                          title,
                          description,
                          isOptional: isOptional,
                          content: (
                            <StepContent
                              setActiveStepIndex={setActiveStepIndexAndCloseTools}
                              applicationFormRef={applicationFormRef}
                              setHelpPanelContent={setHelpPanelContent}
                              formik={formik}
                            />
                          ),
                          errorText: stepError !== '' ? stepError : undefined
                        }))}
                        activeStepIndex={activeStepIndex}
                        i18nStrings={wizardI18nStrings(action)}
                        onNavigate={(event) =>
                          onNavigate(event, activeStepIndex, applicationWizardInitialValues.applicationDetails.applicationName !== '')
                        }
                        onCancel={onWizardCancel}
                        onSubmit={onSubmit}
                      />
                    </form>

                    {/* Just for debugging purposes in dev environment  */}
                    <DisplayContentInCode details={formik}></DisplayContentInCode>
                  </>
                );
              }}
            </Formik>
          </div>
        }
      />
    </>
  );
};
