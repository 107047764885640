import { Board } from '@amzn/awsui-board-components';
import { Alert, Box, ContentLayout, FlashbarProps, SplitPanel } from '@amzn/awsui-components-react';
import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect, useRef, useState } from 'react';
import { eGENERAL_APP_CONTENT_KEY, eLocalStorageKeys } from 'src/constants/AppConstants';
import { appLayoutAriaLabels, splitPanelI18nStrings } from 'src/i18n-strings';
import { boardI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import {
  AnnouncementEntity,
  AllAppsWelcomeContentDetailsEntity,
  StoredWidgetPlacement,
  LoadingStatus,
  DFPHomePageGettingStarted
} from '../context/AppContextModels';
import { useAppContext } from '../context/AppContextProvider';
import { exportLayout } from '../das-finsuite/configurable-dashboard/Utils/ConfigurableDashboardUtils';
import { ResetButton } from '../das-finsuite/configurable-dashboard/components/ResetButton';
import { ConfigurableWidget } from '../das-finsuite/configurable-dashboard/components/configurable-widget';
import Palette from '../das-finsuite/configurable-dashboard/components/palette';
import { AnnouncementWidget } from '../das-finsuite/widgets/announcements-widget/AnnouncementWidget';
import { WidgetConfig } from '../das-finsuite/widgets/interface';
import { WelcomeWidget } from '../das-finsuite/widgets/welcome-widget/WelcomeWidget';
import { DFPBreadcrumbs } from '../generic-components/DFPBreadcrumb';
import { EmptyState } from '../generic-components/empty-state';
import { DFPHomePageSideNavigation } from './DFPHomePageSideNavigation';
import { DFPInfoPanel } from './DFPInfoPanel';
import { useDFPHomePageItemsLayout } from './UseDFPHomePageItemsLayout';
import { GettingStartedWidget } from '../das-finsuite/widgets/getting-started/GettingStartedWidget';

export const DFPHomeBreadcrumbs = [
  {
    text: 'DaS FinSuite',
    href: '/'
  }
];
const splitPanelMaxSize = 360;

export const DFPHomePage = () => {
  const [toolsOpen, setToolsOpen] = useState(false);
  const appLayoutRef = useRef<AppLayoutProps.Ref>(null);
  const appContext = useAppContext();
  const allActiveAnnouncements: AnnouncementEntity[] = appContext.announcements.filter((item) => item.itemMetadata.isActive);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [ref, layout, setLayout, resetLayout] = useDFPHomePageItemsLayout(eLocalStorageKeys.DFP_HOME_PAGE_WIDGETS_LAYOUT);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelSize, setSplitPanelSize] = useState(360);

  const latestFiveActiveAnnouncements = allActiveAnnouncements.slice(0, 5);

  const [gettingStarted, setGettingStarted] = useState<DFPHomePageGettingStarted>({} as DFPHomePageGettingStarted);
  const [dfpHomePageWelcomeContent, setDFPHomePageWelcomeContent] = useState<AllAppsWelcomeContentDetailsEntity[]>([]);

  useEffect(() => {
    if (appContext.listGeneralContent.length > 0) {
      const welcomeContent = appContext.listGeneralContent.find(
        (item) => item.name === eGENERAL_APP_CONTENT_KEY.DFP_HOME_PAGE_WELCOME_CONTENT
      )?.content;
      if (welcomeContent) {
        setDFPHomePageWelcomeContent(JSON.parse(welcomeContent) as AllAppsWelcomeContentDetailsEntity[]);
      }

      const gettingStartedContent = appContext.listGeneralContent.find(
        (item) => item.name === eGENERAL_APP_CONTENT_KEY.DFP_HOME_PAGE_GETTING_STARTED
      )?.content;
      if (gettingStartedContent) {
        const dfpHomePageInfo = JSON.parse(gettingStartedContent) as DFPHomePageGettingStarted;
        dfpHomePageInfo && dfpHomePageInfo.link ? setGettingStarted(dfpHomePageInfo) : setGettingStarted({} as DFPHomePageGettingStarted);
      }
    }
  }, [appContext]);

  const welcomeWidget = WelcomeWidget('Welcome', '', dfpHomePageWelcomeContent);
  const announcementWidget = AnnouncementWidget('Announcements', '', latestFiveActiveAnnouncements, {
    from: '/',
    redirectFromHomePage: true
  });
  const gettingStartedWidget = GettingStartedWidget('Getting Started', '', gettingStarted);

  const allWidgetsOfDFPHomePage: Record<string, WidgetConfig> = {
    welcomeWidget,
    announcementWidget,
    gettingStartedWidget
  };

  const getDFPBoardWidgets = (layout: ReadonlyArray<StoredWidgetPlacement>) => {
    return layout.map((position) => {
      const widget = allWidgetsOfDFPHomePage[position.id];
      return {
        ...position,
        ...widget,
        columnSpan: position.columnSpan ?? widget.definition?.defaultColumnSpan ?? 1,
        rowSpan: position.rowSpan ?? widget.definition?.defaultRowSpan ?? 2
      };
    });
  };

  const getDFPPaletteWidgets = (layout: ReadonlyArray<StoredWidgetPlacement>) => {
    return Object.entries(allWidgetsOfDFPHomePage)
      .filter(([id]) => !layout.find((position) => position.id === id))
      .map(([id, widget]) => ({ id, ...widget }));
  };

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Failed) {
      setFlashbarItems([
        {
          id: 'context_loading_error',
          header: 'Error loading context',
          type: 'error',
          content: appContext.contextLoadingError,
          dismissible: false
        }
      ]);
    }
  }, [appContext.contextLoadingStatus]);

  return (
    <>
      <AppLayout
        ref={appLayoutRef}
        headerSelector="#h"
        contentType="dashboard"
        ariaLabels={appLayoutAriaLabels}
        breadcrumbs={<DFPBreadcrumbs items={[]} />}
        tools={<DFPInfoPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        navigation={<DFPHomePageSideNavigation />}
        stickyNotifications
        notifications={
          appContext.contextLoadingStatus === LoadingStatus.Failed && (
            <Alert statusIconAriaLabel="Info" header="Known issues/limitations" type="error">
              {appContext.contextLoadingError}
            </Alert>
          )
        }
        splitPanel={
          <SplitPanel header="Add widgets" closeBehavior="hide" hidePreferencesButton={true} i18nStrings={splitPanelI18nStrings}>
            <Palette items={getDFPPaletteWidgets(layout)} />
          </SplitPanel>
        }
        splitPanelPreferences={{ position: 'side' }}
        onSplitPanelPreferencesChange={() => {}}
        splitPanelOpen={splitPanelOpen}
        onSplitPanelToggle={({ detail }) => setSplitPanelOpen(detail.open)}
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={(event) => setSplitPanelSize(Math.min(event.detail.size, splitPanelMaxSize))}
        maxContentWidth={Number.MAX_VALUE}
        content={
          <div className="app-layout-body">
            <ContentLayout
              header={
                <>
                  <Header
                    actions={
                      <SpaceBetween size="l" direction="horizontal">
                        <ResetButton onReset={resetLayout}>Reset to default layout</ResetButton>
                        {/* TODO: Disabling "Add widget" button for now. */}
                        {/* 
                            <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                              Add widget
                            </Button>  
                          */}
                      </SpaceBetween>
                    }
                  >
                    <Box variant="h1">{'DaS FinSuite'}</Box>
                  </Header>
                </>
              }
            >
              <div ref={ref}>
                <Board
                  empty={
                    <EmptyState
                      title="No home page contents"
                      description="There are no widgets on this page."
                      verticalCenter={true}
                      action={
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button onClick={resetLayout}>Reset to default layout</Button>
                          <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                            Add widget
                          </Button>
                        </SpaceBetween>
                      }
                    />
                  }
                  i18nStrings={boardI18nStrings}
                  items={getDFPBoardWidgets(layout)}
                  onItemsChange={({ detail: { items } }) => {
                    setLayout(exportLayout(items));
                  }}
                  renderItem={(item, actions) => {
                    const Wrapper = item.data.provider ?? React.Fragment;
                    return (
                      <Wrapper>
                        <ConfigurableWidget config={item.data} onRemove={actions.removeItem} />
                      </Wrapper>
                    );
                  }}
                />
              </div>
            </ContentLayout>
          </div>
        }
      />
    </>
  );
};
