import moment from 'moment-timezone';
import {
  CostMetricsDashboardItems,
  CostMetricsDetailsTabData,
  CostMetricsFilters,
  CostMetricsGraphData,
  CostMetricsParsedData,
  CostMetricsParsedDetails,
  CostMetricsRawData,
  CostMetricsRawDataForGraphProcessing,
  CostMetricsTableData,
  Metadata,
  Top3Accounts,
  Top3Products
} from './CostMetricsInterface';
import { sortedDateArrayMMMYYYYFormat } from 'src/utilities/DateTimeUtilities';
import { DashboardFilters } from '../fintech-ops-dashboard/FinTechOpsDataInterfaces';
import { findCommonElements3, indicatorIcon, indicatorIconVariant } from '../fintech-ops-dashboard/FinTechOpsUtils';
import { numberFormatter, aggregateMetricsData, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import { Icon, TableProps } from '@amzn/awsui-components-react';
import * as React from 'react';
import {
  CreateBarChartData,
  CreateLineChartData,
  filterDataByCreateDates,
  getAggregatedArray,
  getMaxAndPreviousMonth,
  getMoMData,
  getTopNRecordsByFilterColumn,
  sortDistinctMonths_MM_YYYY,
  uniqueValues
} from '../FinTechOpsDataProcessingFunctions';
import { COST_METRICS_DETAILS_COLUMN_DEFINITIONS, COST_METRICS_DETAILS_VISIBLE_CONTENT } from './CostMetricsTableFilterConfig';
import { MetricsMeasure } from '../user-metrics/UserMetricsInterfaces';
import { BaseDayFormat_YYYY_MM_DD, FilterDateFormat_MMM_YYYY, FunctionDateFormat_YYYYMM, GraphDateFormat_MMM_YY } from '../FinTechOpsConstants';
import { getColumnDefinitionForAggregatedTable } from '../FinTechOpsCommonElements';
import {
  COST_METRICS_RESOURCE_TYPE_COLUMN_LISTS,
  COST_METRICS_COST_MEASURE_COLUMN_LISTS,
  COST_METRICS_RESOURCE_COLUMN_LISTS,
  METRICS_DATE_YYYYMM,
  COST_METRICS_RESOURCE_COUNT_MEASURE_COLUMN_LISTS,
  COST_METRICS_DETAILS_COLUMN_LISTS,
  COST_METRICS_DETAILS_MEASURE_COLUMN_LISTS,
  COST_METRICS_DASHBOARD_COLUMN_LISTS,
  COST_METRICS_AGGREGATED_BY_ACCOUNT_COLUMN_LISTS,
  COST_METRICS_AGGREGATED_BY_PRODUCT_COLUMN_LISTS,
  AWS_ACCOUNTS_RESOURCE_TYPE,
  COST_METRICS_PRODUCT_COLUMN_LISTS,
  COST_METRICS_APPLICATION_COLUMN_LISTS,
  COST_METRICS_AGGREGATED_BY_APPLICATION_COLUMN_LISTS
} from './CostMetricsConstants';

//Function to parse the raw cost metrics data
export const returnParsedAndFormattedCostMetricsData = (
  costMetricsRawDetails: CostMetricsRawData[],
  lastUpdatedAt: Metadata
): CostMetricsParsedDetails => {
  const parsedCostMetricsData: CostMetricsParsedData[] = [];

  const filteredCostMetricsRawData: CostMetricsRawData[] = filterDataByCreateDates(costMetricsRawDetails, 'metrics_date');

  filteredCostMetricsRawData.forEach((item: CostMetricsRawData) => {
    const parsedItem: CostMetricsParsedData = {
      metrics_date_YYYY_MM_DD: item.metrics_date,
      metrics_date_MMM_YYYY: moment(item.metrics_date).format(FilterDateFormat_MMM_YYYY),
      metrics_date_YYYYMM: moment(item.metrics_date, BaseDayFormat_YYYY_MM_DD).format(FunctionDateFormat_YYYYMM),
      team_name: item.team_name,
      cost_metrics_manager_login_hierarchy_concat: item.manager_login_hierarchy_concat.split(':'),
      cost_metrics_manager_login: item.manager_login_hierarchy_concat.split(':')[0],
      cost_metrics_manager_name_hierarchy_concat: item.manager_name_hierarchy_concat.split(':'),
      cost_metrics_manager_name: item.manager_name_hierarchy_concat.split(':')[0],
      create_dt: item.create_dt,
      fleet_name: item.fleet_name,
      fleet_owner: item.fleet_owner,
      product: item.product,
      resource: item.resource,
      resource_type: item.resource_type,
      usage_cost: item.usage_cost,
      usage_unit: item.usage_unit,
      usage_value: item.usage_value,
      created_on_month: moment(item.metrics_date, BaseDayFormat_YYYY_MM_DD).month() + 1,
      stages: item.stage,
      applications: item.application
    };
    parsedCostMetricsData.push(parsedItem);
  });

  return {
    metadata: lastUpdatedAt,
    data: parsedCostMetricsData
  };
};

//Function to get unique dashboard filter values for each filter
export const getDashboardFiltersFromCostMetrics = (items: CostMetricsParsedData[]): DashboardFilters => {
  const costMetricsMasterFilterData: DashboardFilters = {
    team_name: [],
    managers: [],
    assignees: []
  };
  const teamNameSet = new Set<string>();
  const managersSet = new Set<string>();

  items?.forEach((item: CostMetricsParsedData) => {
    uniqueValues(teamNameSet, item.team_name);
    uniqueValues(managersSet, item.cost_metrics_manager_name);
  });

  costMetricsMasterFilterData.team_name = [...teamNameSet];
  costMetricsMasterFilterData.managers = [...managersSet];
  costMetricsMasterFilterData.assignees = [];
  return costMetricsMasterFilterData;
};

//Function to get filtered cost metrics dashboard data using dashboard global filters
export const applyCostMetricsDashboardFiltering = (
  costMetricsParsedData: CostMetricsParsedData[],
  filters: DashboardFilters
): CostMetricsParsedData[] => {
  return costMetricsParsedData.filter((item: CostMetricsParsedData) => {
    return findCommonElements3(filters.managers, item.cost_metrics_manager_name_hierarchy_concat) && filters.team_name.includes(item.team_name);
  });
};

//Function to create dashboard metrics data
export const createOpsCostMetricsDashboardData = (costMetricsParsedData: CostMetricsParsedData[], monthList: string[]): CostMetricsDashboardItems => {
  let totalCurrentMonthCost = 0;
  let totalPreviousMonthCost = 0;
  let topProductContributingToCost = '';
  let maxCost = 0;
  let totalCost = 0;

  const currentMonth_YYYY_MM_DD = moment().startOf('month').format(BaseDayFormat_YYYY_MM_DD);

  const previousMonth_YYYY_MM_DD = moment().subtract(1, 'months').startOf('month').format(BaseDayFormat_YYYY_MM_DD);

  const aggregateData = aggregateMetricsData(COST_METRICS_DASHBOARD_COLUMN_LISTS, costMetricsParsedData, COST_METRICS_COST_MEASURE_COLUMN_LISTS);

  aggregateData?.forEach((item: any) => {
    if (item.metrics_date_YYYY_MM_DD === currentMonth_YYYY_MM_DD && item.usage_cost > maxCost) {
      maxCost = item.usage_cost;
      topProductContributingToCost = item.product;
    }
  });

  costMetricsParsedData?.forEach((item: CostMetricsParsedData) => {
    totalCost = totalCost + item.usage_cost;

    if (item.metrics_date_YYYY_MM_DD === currentMonth_YYYY_MM_DD) {
      totalCurrentMonthCost = totalCurrentMonthCost + item.usage_cost;
    }
    if (item.metrics_date_YYYY_MM_DD === previousMonth_YYYY_MM_DD) {
      totalPreviousMonthCost = totalPreviousMonthCost + item.usage_cost;
    }
  });

  const avgMonthlyCostValue = totalCost / monthList?.length;

  return {
    totalCurrentMonthCost: numberFormatter(totalCurrentMonthCost, true),
    totalPreviousMonthCost: numberFormatter(totalPreviousMonthCost, true),
    topProductContributingToCost: topProductContributingToCost,
    avgMonthlyCost: numberFormatter(avgMonthlyCostValue, true)
  };
};

//Function to create unique filter values for each filter of Cost Metrics Page Global Filter
export const createCostMetricsFilterData = (costMetricsParsedData: CostMetricsParsedData[]): CostMetricsFilters => {
  const costMetricsMasterFilterData: CostMetricsFilters = {
    team: [],
    managers: [],
    products: [],
    resources: [],
    resourceType: [],
    timePeriods: [],
    stages: [],
    applications: []
  };
  const teamSet = new Set<string>();
  const managersSet = new Set<string>();
  const productsSet = new Set<string>();
  const resourcesSet = new Set<string>();
  const resourceTypeSet = new Set<string>();
  const timePeriodsSet = new Set<string>();
  const stagesSet = new Set<string>();
  const applicationsSet = new Set<string>();

  costMetricsParsedData.forEach((item: CostMetricsParsedData) => {
    uniqueValues(teamSet, item.team_name);
    uniqueValues(managersSet, item.cost_metrics_manager_name);
    uniqueValues(productsSet, item.product);
    uniqueValues(resourcesSet, item.resource);
    uniqueValues(resourceTypeSet, item.resource_type);
    uniqueValues(timePeriodsSet, item.metrics_date_MMM_YYYY);
    uniqueValues(stagesSet, item.stages);
    uniqueValues(applicationsSet, item.applications);
  });

  costMetricsMasterFilterData.team = [...teamSet];
  costMetricsMasterFilterData.managers = [...managersSet];
  costMetricsMasterFilterData.products = [...productsSet];
  costMetricsMasterFilterData.resources = [...resourcesSet];
  costMetricsMasterFilterData.resourceType = [...resourceTypeSet];
  costMetricsMasterFilterData.timePeriods = [...timePeriodsSet];
  costMetricsMasterFilterData.stages = [...stagesSet];
  costMetricsMasterFilterData.applications = [...applicationsSet];
  return costMetricsMasterFilterData;
};

//Function to return filtered data after applying the global filters with and without month filters values
export const applyFiltersOnCostMetricsDataMetrics = (
  parsedCostMetricsData: CostMetricsParsedData[],
  costMetricsFilters: CostMetricsFilters
): [CostMetricsParsedData[], CostMetricsParsedData[]] => {
  const filteredWithoutMonthData: CostMetricsParsedData[] = parsedCostMetricsData.filter((item: CostMetricsParsedData) => {
    return (
      costMetricsFilters.team.includes(item.team_name) &&
      findCommonElements3(costMetricsFilters.managers, item.cost_metrics_manager_name_hierarchy_concat) &&
      costMetricsFilters.products.includes(item.product) &&
      costMetricsFilters.resources.includes(item.resource) &&
      costMetricsFilters.resourceType.includes(item.resource_type) &&
      costMetricsFilters.stages.includes(item.stages) &&
      costMetricsFilters.applications.includes(item.applications)
    );
  });

  const filteredWithMonthData: CostMetricsParsedData[] = filteredWithoutMonthData.filter((item) => {
    return costMetricsFilters.timePeriods.includes(item.metrics_date_MMM_YYYY);
  });

  return [filteredWithoutMonthData, filteredWithMonthData];
};

//Function to get the graph data
export const getCostMetricsCalculatedGraphData = (costMetricsParsedData: CostMetricsParsedData[]): CostMetricsGraphData => {
  const costMetricsTrendsByMonth = getCostMetricsTrendsByMonth(costMetricsParsedData);
  const costByProduct = getCostByProduct(costMetricsParsedData);
  const costByResourceType = getCostByResourceType(costMetricsParsedData);
  const costByApplication = getCostByApplication(costMetricsParsedData);

  return {
    costMetricsTrendsByMonthGraphData: costMetricsTrendsByMonth.costMetricsTrendsByMonthGraphData,
    costMetricsTrendsByMonthGraphDataXDomain: costMetricsTrendsByMonth.costMetricsTrendsByMonthGraphDataXDomain,
    costMetricsTrendsByMonthGraphDataYDomain: costMetricsTrendsByMonth.costMetricsTrendsByMonthGraphDataYDomain,
    costByProductGraphData: costByProduct.costByProductGraphData,
    costByProductGraphDataXDomain: costByProduct.costByProductGraphDataXDomain,
    costByProductGraphDataYDomain: costByProduct.costByProductGraphDataYDomain,
    costByResourceTypeGraphData: costByResourceType.costByResourceTypeGraphData,
    costByResourceTypeGraphDataXDomain: costByResourceType.costByResourceTypeGraphDataXDomain,
    costByResourceTypeGraphDataYDomain: costByResourceType.costByResourceTypeGraphDataYDomain,
    costByApplicationGraphData: costByApplication.costByApplicationGraphData,
    costByApplicationGraphDataXDomain: costByApplication.costByApplicationGraphDataXDomain,
    costByApplicationGraphDataYDomain: costByApplication.costByApplicationGraphDataYDomain
  };
};

//Function to get cost vs count metrics trend data
const getCostMetricsTrendsByMonth = (costMetricsParsedData: CostMetricsParsedData[]) => {
  const aggregatedData = aggregateMetricsData(METRICS_DATE_YYYYMM, costMetricsParsedData, COST_METRICS_COST_MEASURE_COLUMN_LISTS);
  const barGraphResult: CostMetricsRawDataForGraphProcessing[] = [];

  aggregatedData.forEach((item: any) => {
    barGraphResult.push({
      count: parseInt(item['usage_cost']),
      title: 'Cost',
      category: item['metrics_date_YYYYMM']
    });
  });

  const filteredAWSAccountsData = costMetricsParsedData.filter((item) => item.resource_type === AWS_ACCOUNTS_RESOURCE_TYPE);
  const aggregatedAWSAccountsData = aggregateMetricsData(
    METRICS_DATE_YYYYMM,
    filteredAWSAccountsData,
    COST_METRICS_RESOURCE_COUNT_MEASURE_COLUMN_LISTS
  );

  const lineGraphResult: CostMetricsRawDataForGraphProcessing[] = [];

  aggregatedAWSAccountsData.forEach((item: any) => {
    lineGraphResult.push({
      count: item['resource_count_distinct'],
      title: '#AWS Accounts',
      category: item['metrics_date_YYYYMM']
    });
  });

  const costMetricsTrendByMonthDataSeriesCombines: any[] = [];
  const costMetricsTrendByMonthGraphData = CreateBarChartData(barGraphResult, GraphDateFormat_MMM_YY, 'month', false);
  const costMetricsTrendByMonthGraphLineData = CreateLineChartData(lineGraphResult, GraphDateFormat_MMM_YY, 'month', false);

  costMetricsTrendByMonthGraphData.dataSeries.forEach((item: any) => {
    costMetricsTrendByMonthDataSeriesCombines.push(item);
  });
  costMetricsTrendByMonthGraphLineData.dataSeries.forEach((item: any) => {
    costMetricsTrendByMonthDataSeriesCombines.push(item);
  });

  return {
    costMetricsTrendsByMonthGraphData: costMetricsTrendByMonthDataSeriesCombines,
    costMetricsTrendsByMonthGraphDataXDomain: costMetricsTrendByMonthGraphData.xDomain,
    costMetricsTrendsByMonthGraphDataYDomain: costMetricsTrendByMonthGraphData.yDomain
  };
};

//Function to get data for Top 10 Products By Cost Graph
const getCostByProduct = (costMetricsParsedData: CostMetricsParsedData[]) => {
  const aggregatedData = aggregateMetricsData(COST_METRICS_PRODUCT_COLUMN_LISTS, costMetricsParsedData, COST_METRICS_COST_MEASURE_COLUMN_LISTS)?.sort(
    (a: any, b: any) => b.usage_cost - a.usage_cost
  );

  const top10Product = getTopNRecordsByFilterColumn(aggregatedData, 10, 'usage_cost', 'metrics_date_YYYYMM');
  const lineGraphResult: CostMetricsRawDataForGraphProcessing[] = [];

  top10Product.forEach((item: any) => {
    lineGraphResult.push({
      count: item['usage_cost'],
      title: item['product'],
      category: item['metrics_date_YYYYMM']
    });
  });

  const costByProductGraphLineData = CreateLineChartData(lineGraphResult, GraphDateFormat_MMM_YY, 'month', false);

  return {
    costByProductGraphData: costByProductGraphLineData.dataSeries,
    costByProductGraphDataXDomain: costByProductGraphLineData.xDomain,
    costByProductGraphDataYDomain: costByProductGraphLineData.yDomain
  };
};

//Function to get data for Cost By Resource Type Graph
const getCostByResourceType = (costMetricsParsedData: CostMetricsParsedData[]) => {
  const aggregatedData = aggregateMetricsData(
    COST_METRICS_RESOURCE_TYPE_COLUMN_LISTS,
    costMetricsParsedData,
    COST_METRICS_COST_MEASURE_COLUMN_LISTS
  )?.sort((a: any, b: any) => b.usage_cost - a.usage_cost);

  const lineGraphResult: CostMetricsRawDataForGraphProcessing[] = [];

  aggregatedData.forEach((item: any) => {
    lineGraphResult.push({
      count: item['usage_cost'],
      title: item['resource_type'],
      category: item['metrics_date_YYYYMM']
    });
  });
  const costByResourceTypeGraphLineData = CreateLineChartData(lineGraphResult, GraphDateFormat_MMM_YY, 'month', false);

  return {
    costByResourceTypeGraphData: costByResourceTypeGraphLineData.dataSeries,
    costByResourceTypeGraphDataXDomain: costByResourceTypeGraphLineData.xDomain,
    costByResourceTypeGraphDataYDomain: costByResourceTypeGraphLineData.yDomain
  };
};

//Function to get data for Cost By Application Graph
const getCostByApplication = (costMetricsParsedData: CostMetricsParsedData[]) => {
  const aggregatedData = aggregateMetricsData(
    COST_METRICS_APPLICATION_COLUMN_LISTS,
    costMetricsParsedData,
    COST_METRICS_COST_MEASURE_COLUMN_LISTS
  )?.sort((a: any, b: any) => b.usage_cost - a.usage_cost);
  const lineGraphResult: CostMetricsRawDataForGraphProcessing[] = [];

  aggregatedData.forEach((item: any) => {
    lineGraphResult.push({
      count: item['usage_cost'],
      title: item['applications'],
      category: item['metrics_date_YYYYMM']
    });
  });
  const costByApplicationGraphLineData = CreateLineChartData(lineGraphResult, GraphDateFormat_MMM_YY, 'month', false);

  return {
    costByApplicationGraphData: costByApplicationGraphLineData.dataSeries,
    costByApplicationGraphDataXDomain: costByApplicationGraphLineData.xDomain,
    costByApplicationGraphDataYDomain: costByApplicationGraphLineData.yDomain
  };
};

//Function to get Cost Metrics Details Tab Data
export const getCostMetricsCalculatedDetailsTabData = (
  costMetricsParsedData: CostMetricsParsedData[],
  filteredWithoutMonthData: CostMetricsParsedData[]
): CostMetricsDetailsTabData => {
  const sortedMonths = sortedDateArrayMMMYYYYFormat(getUniqueFieldValues(costMetricsParsedData, 'metrics_date_MMM_YYYY'));
  const currentMonthValue = moment().format(FilterDateFormat_MMM_YYYY);
  let totalCost = 0;

  costMetricsParsedData.forEach((item) => {
    totalCost += item.usage_cost;
  });
  const currentMonth = sortedMonths.length > 1 && sortedMonths.includes(currentMonthValue) ? sortedMonths[1] : sortedMonths[0];
  const top3ProductsInCurrentMonth = getTop3ProductsInCurrentMonthSummaryData(costMetricsParsedData, filteredWithoutMonthData);
  const top3AccountsInCurrentMonth = getTop3AccountsInCurrentMonthSummaryData(costMetricsParsedData, filteredWithoutMonthData);
  const costMetricsDetailsTable = getCostMetricsDetailsTable(costMetricsParsedData);
  const previousMonthCost = getPreviousMonthCost(costMetricsParsedData);
  const aggregatedCostMetricsByProduct = getAggregatedCostMetricsByProductDetailsTable(
    costMetricsParsedData,
    COST_METRICS_AGGREGATED_BY_PRODUCT_COLUMN_LISTS,
    COST_METRICS_COST_MEASURE_COLUMN_LISTS
  );
  const aggregatedCostMetricsByAccount = getAggregatedCostMetricsByAccountDetailsTable(
    costMetricsParsedData,
    COST_METRICS_AGGREGATED_BY_ACCOUNT_COLUMN_LISTS,
    COST_METRICS_COST_MEASURE_COLUMN_LISTS
  );
  const aggregatedCostMetricsByApplications = getAggregatedCostMetricsByApplicationsDetailsTable(
    costMetricsParsedData,
    COST_METRICS_AGGREGATED_BY_APPLICATION_COLUMN_LISTS,
    COST_METRICS_COST_MEASURE_COLUMN_LISTS
  );

  return {
    costMetricsSummaryContainer: {
      totalCost: Number(totalCost).toFixed(),
      previousMonthCost: Number(previousMonthCost).toFixed(),
      currentMonth: currentMonth,
      top3Products: top3ProductsInCurrentMonth,
      top3Accounts: top3AccountsInCurrentMonth
    },
    costMetricsDetailsTable: costMetricsDetailsTable,
    aggregatedCostMetricsByProductDetailsTable: aggregatedCostMetricsByProduct,
    aggregatedCostMetricsByAccountDetailsTable: aggregatedCostMetricsByAccount,
    aggregatedCostMetricsByApplicationsDetailsTable: aggregatedCostMetricsByApplications
  };
};

//Function to retrieve Previous Month Cost
const getPreviousMonthCost = (costMetricsParsedData: CostMetricsParsedData[]) => {
  const previousMonth_YYYY_MM_DD = moment().subtract(1, 'months').startOf('month').format(BaseDayFormat_YYYY_MM_DD);
  let cost = 0;
  costMetricsParsedData?.forEach((item: any) => {
    if (item.metrics_date_YYYY_MM_DD === previousMonth_YYYY_MM_DD) {
      cost = cost + item.usage_cost;
    }
  });
  return cost;
};

//Function to get data for Cost Metrics Details Table
const getCostMetricsDetailsTable = (costMetricsParsedData: CostMetricsParsedData[]): CostMetricsTableData => {
  let costMetricsDetailsTableData = [];

  const aggregatedData = aggregateMetricsData(
    COST_METRICS_DETAILS_COLUMN_LISTS,
    costMetricsParsedData,
    COST_METRICS_DETAILS_MEASURE_COLUMN_LISTS
  )?.sort((a: any, b: any) => b.usage_cost - a.usage_cost);

  costMetricsDetailsTableData = aggregatedData.map((item: any) => {
    return {
      applications: item.applications,
      metrics_date_YYYY_MM_DD: item.metrics_date_YYYY_MM_DD,
      fleet_name: item.fleet_name,
      fleet_owner: item.fleet_owner,
      product: item.product,
      resource: item.resource,
      resource_type: item.resource_type,
      team_name: item.team_name,
      usage_value: item.usage_value,
      usage_cost: item.usage_cost,
      usage_unit: item.usage_unit,
      cost_metrics_manager_login: item.cost_metrics_manager_login,
      cost_metrics_manager_name: item.cost_metrics_manager_name
    };
  });

  return {
    rowData: costMetricsDetailsTableData?.sort((a: any, b: any) => b.usage_cost - a.usage_cost) || [],
    columnData: COST_METRICS_DETAILS_COLUMN_DEFINITIONS,
    visibleColumns: COST_METRICS_DETAILS_VISIBLE_CONTENT
  } as CostMetricsTableData;
};

//Function to get data for Cost Metrics Aggregated Details Table By Product
const getAggregatedCostMetricsByProductDetailsTable = (
  costMetricsData: CostMetricsParsedData[],
  columnList: string[],
  measureColumnList: MetricsMeasure[]
): CostMetricsTableData => {
  const aggregatedDataValue =
    aggregateMetricsData(columnList, costMetricsData, measureColumnList)?.sort((a: any, b: any) => b.usage_cost - a.usage_cost) || [];

  const distinctMonths: string[] = sortDistinctMonths_MM_YYYY(getUniqueFieldValues(costMetricsData, 'metrics_date_MMM_YYYY'));

  const aggregatedArray = getAggregatedArray('product', 'metrics_date_MMM_YYYY', 'usage_cost', aggregatedDataValue);

  const columnDefinition: TableProps.ColumnDefinition<any>[] = getColumnDefinitionForAggregatedTable('product', 'Product', distinctMonths, true);

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    rowData: aggregatedArray,
    columnData: columnDefinition,
    visibleColumns: visbleColumns
  };
};

//Function to get data for Cost Metrics Aggregated Details Table By Account
const getAggregatedCostMetricsByAccountDetailsTable = (
  costMetricsData: CostMetricsParsedData[],
  columnList: string[],
  measureColumnList: MetricsMeasure[]
): CostMetricsTableData => {
  const costMetricsAccountsFilteredData = costMetricsData.filter((item) => item.resource_type === AWS_ACCOUNTS_RESOURCE_TYPE);
  const aggregatedDataValue =
    aggregateMetricsData(columnList, costMetricsAccountsFilteredData, measureColumnList)?.sort((a: any, b: any) => b.usage_cost - a.usage_cost) || [];

  const distinctMonths: string[] = sortDistinctMonths_MM_YYYY(getUniqueFieldValues(costMetricsData, 'metrics_date_MMM_YYYY'));

  const aggregatedArray = getAggregatedArray('resource', 'metrics_date_MMM_YYYY', 'usage_cost', aggregatedDataValue);

  const columnDefinition: TableProps.ColumnDefinition<any>[] = getColumnDefinitionForAggregatedTable('resource', 'Account', distinctMonths, true);

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    rowData: aggregatedArray,
    columnData: columnDefinition,
    visibleColumns: visbleColumns
  };
};

//Function to get data for Cost Metrics Aggregated Details Table By Applications
const getAggregatedCostMetricsByApplicationsDetailsTable = (
  costMetricsData: CostMetricsParsedData[],
  columnList: string[],
  measureColumnList: MetricsMeasure[]
): CostMetricsTableData => {
  const aggregatedDataValue =
    aggregateMetricsData(columnList, costMetricsData, measureColumnList)?.sort((a: any, b: any) => b.usage_cost - a.usage_cost) || [];

  const distinctMonths: string[] = sortDistinctMonths_MM_YYYY(getUniqueFieldValues(costMetricsData, 'metrics_date_MMM_YYYY'));

  const aggregatedArray = getAggregatedArray('applications', 'metrics_date_MMM_YYYY', 'usage_cost', aggregatedDataValue);

  const columnDefinition: TableProps.ColumnDefinition<any>[] = getColumnDefinitionForAggregatedTable(
    'applications',
    'Applications',
    distinctMonths,
    true
  );

  const visbleColumns: string[] = columnDefinition.map((item) => item.id || '');

  return {
    rowData: aggregatedArray,
    columnData: columnDefinition,
    visibleColumns: visbleColumns
  };
};

//Function to get data for Top 3 Products in Summary Page
const getTop3ProductsInCurrentMonthSummaryData = (
  costMetricsParsedData: CostMetricsParsedData[],
  filteredWithoutMonthCostMetricsData: CostMetricsParsedData[]
): Top3Products[] => {
  const result: Top3Products[] = [];

  const [maxMonth, previousMonth] = getMaxAndPreviousMonth(costMetricsParsedData, 'metrics_date_MMM_YYYY');
  const dataMap = new Map<string, number>();

  const currentMonthFilteredData = costMetricsParsedData.filter((item) => item.created_on_month === maxMonth);

  for (const item of currentMonthFilteredData) {
    const product = item.product;
    const count = item.usage_cost;
    if (dataMap.has(product)) {
      dataMap.set(product, dataMap.get(product)! + count);
    } else {
      dataMap.set(product, count);
    }
  }

  const sortedProducts = [...dataMap.entries()].sort((a, b) => b[1] - a[1]).slice(0, 3);
  for (const [product, count] of sortedProducts) {
    result.push({
      products: product,
      cost: numberFormatter(count, true),
      MoM: getMoMData(product, 'product', 'created_on_month', 'usage_cost', count, previousMonth, filteredWithoutMonthCostMetricsData)
    } as Top3Products);
  }
  return result;
};

//Function to get data for Top 3 Accounts in Summary Page
const getTop3AccountsInCurrentMonthSummaryData = (
  costMetricsParsedData: CostMetricsParsedData[],
  filteredWithoutMonthCostMetricsData: CostMetricsParsedData[]
): Top3Accounts[] => {
  const result: Top3Accounts[] = [];

  const [maxMonth, previousMonth] = getMaxAndPreviousMonth(costMetricsParsedData, 'metrics_date_MMM_YYYY');

  const dataMap = new Map<string, number>();

  const currentMonthFilteredData = costMetricsParsedData.filter(
    (item) => item.created_on_month === maxMonth && item.resource_type === AWS_ACCOUNTS_RESOURCE_TYPE
  );

  for (const item of currentMonthFilteredData) {
    const account = item.resource;
    const count = item.usage_cost;
    if (dataMap.has(account)) {
      dataMap.set(account, dataMap.get(account)! + count);
    } else {
      dataMap.set(account, count);
    }
  }

  const sortedAccounts = [...dataMap.entries()].sort((a, b) => b[1] - a[1]).slice(0, 3);
  for (const [account, count] of sortedAccounts) {
    result.push({
      accounts: account,
      cost: numberFormatter(count, true),
      MoM: getMoMData(account, 'resource', 'created_on_month', 'usage_cost', count, previousMonth, filteredWithoutMonthCostMetricsData)
    } as Top3Accounts);
  }
  return result;
};

//Function to get column definition for Cost Metrics Summary Container
export const getColumnDefinitionCostMetricsSummaryContainer = (tableType: string, month: string) => {
  const fixedColumnDefinition: TableProps.ColumnDefinition<Top3Products | Top3Accounts>[] = [
    {
      id: 'cost',
      header: `Cost ${month}`,
      sortingField: 'cost',
      cell: (item: Top3Products | Top3Accounts) => <>{item.cost || '-'}</>,
      width: '160px'
    },
    {
      id: 'MoM',
      header: 'MoM',
      sortingField: 'MoM',
      cell: (item: Top3Products | Top3Accounts) => (
        <>
          {numberFormatter(Math.abs(parseInt(item.MoM)), true) || '-'}
          <Icon name={indicatorIcon(parseFloat(item.MoM))} variant={indicatorIconVariant(parseFloat(item.MoM) ? parseFloat(item.MoM) * -1 : 0)} />
        </>
      )
    }
  ];

  const accountColumnDefinition = {
    id: 'account',
    header: 'Top Accounts',
    sortingField: 'account',
    cell: (item: Top3Accounts) => <>{item.accounts}</>,
    width: '140px'
  };

  const productColumnDefinition = {
    id: 'product',
    header: `Top Products`,
    sortingField: 'product',
    cell: (item: Top3Products) => <>{item.products}</>,
    width: '140px'
  };

  return tableType === 'account' ? [accountColumnDefinition, ...fixedColumnDefinition] : [productColumnDefinition, ...fixedColumnDefinition];
};
