import {
  Box,
  Button,
  Checkbox,
  CollectionPreferences,
  CollectionPreferencesProps,
  PropertyFilterProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import React from 'react';

interface TableEmptyStateProps {
  resourceName: string;
}

export const TableEmptyState: React.FC<TableEmptyStateProps> = ({ resourceName }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}</b>
        {/* <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()}s associated with this resource.
        </Box> */}
      </div>
    </SpaceBetween>
  </Box>
);

export const TableNoMatchState = (props: any) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const BLANK_SEARCH_OR = { tokens: [], operation: 'or' } as PropertyFilterProps.Query;
export const BLANK_SEARCH_AND = { tokens: [], operation: 'and' } as PropertyFilterProps.Query;

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

/* table page default size */
export const DEFAULT_PAGE_SIZE = 100;

/* table page default large size */
export const DEFAULT_LARGE_PAGE_SIZE = 200;

/* table page size options */
export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 items' },
  { value: 20, label: '20 items' },
  { value: 30, label: '30 items' },
  { value: 50, label: '50 items' },
  { value: 100, label: '100 items' },
  { value: 200, label: '200 items' }
];

interface PreferencesProps {
  preferences: CollectionPreferencesProps.Preferences<any>;
  setPreferences: (preferences: CollectionPreferencesProps.Preferences<any>) => void;
  visibleContentOptions: CollectionPreferencesProps.VisibleContentOptionsGroup[];
  disablePageSizeOptions?: boolean;
  disableResizableColumnsOptions?: boolean;
  contentDisplayPreference?: CollectionPreferencesProps.ContentDisplayPreference;
}

export const Preferences: React.FC<PreferencesProps> = ({
  preferences,
  setPreferences,
  visibleContentOptions,
  disablePageSizeOptions = false,
  disableResizableColumnsOptions = false,
  contentDisplayPreference
}) => {
  const preferencesProps: CollectionPreferencesProps = {
    title: 'Preferences',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    preferences: preferences,
    onConfirm: ({ detail }) => setPreferences(detail),
    contentDisplayPreference: contentDisplayPreference,
    wrapLinesPreference: {
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    },
    stripedRowsPreference: {
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    },
    visibleContentPreference: {
      title: 'Select visible columns',
      options: visibleContentOptions
    }
  };

  // Conditionally adding pageSizePreference
  if (!disablePageSizeOptions) {
    preferencesProps.pageSizePreference = {
      title: 'Page size',
      options: PAGE_SIZE_OPTIONS
    };
  }

  if (!disableResizableColumnsOptions) {
    preferencesProps.customPreference = (checked, setChecked) => (
      <Checkbox onChange={({ detail }) => setChecked(detail.checked)} checked={checked}>
        Resizable Columns
      </Checkbox>
    );
  }

  return <CollectionPreferences {...preferencesProps} />;
};
