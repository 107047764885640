import { Box, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useFinTechContext } from '../../context/FintechContextProvider';
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { BasicMetricContainer, ErrorContainer } from '../FinTechOpsCommonElements';
import { FinTechOpsCategories, FinTechOpsCategoriesLabelColors, FinTechOpsHref, FinTechOpsMetricsDescriptions } from '../FinTechOpsConstants';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';

export const FinTechOpsSas: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const sasRisk = finOpsContext.finTechOpsSasDashBoard;

  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          SAS
        </Box>
        <LastRefreshedAt lastRefreshedDateTime={finOpsContext.sasData.metadata.last_updated_at} />
      </SpaceBetween>

      <div>
        {(() => {
          if (finOpsContext.finTechOpsSasDataStatus === 'loading') {
            return (
              <>
                <LoadingSpinner />
              </>
            );
          } else if (finOpsContext.finTechOpsSasDataStatus == 'error') {
            // opExContext.displayNotification('error', 'Failed to retrieve Page');
            return (
              <div>
                <ErrorContainer />
              </div>
            );
          } else if (finOpsContext.finTechOpsSasDataStatus == 'pending') {
            return (
              <>
                <LoadingSpinner />
              </>
            );
          } else if (finOpsContext.finTechOpsSasDataStatus == 'finished') {
            return (
              <ColumnLayout columns={5} minColumnWidth={10}>
                <BasicMetricContainer
                  title="Total Risks"
                  value={sasRisk.total_sas_risk ? (sasRisk.total_sas_risk as unknown as string) : '0'}
                  unit=""
                  href={FinTechOpsHref.sas}
                  id={FinTechOpsCategories.sas}
                  color={FinTechOpsCategoriesLabelColors.sas}
                  description={FinTechOpsMetricsDescriptions.sasTotalRisk}
                />
                <BasicMetricContainer
                  title="Resolved Risks"
                  value={sasRisk.solved ? (sasRisk.solved as unknown as string) : '0'}
                  unit=""
                  href={FinTechOpsHref.sas}
                  id={FinTechOpsCategories.sas}
                  color={FinTechOpsCategoriesLabelColors.sas}
                  description={FinTechOpsMetricsDescriptions.sasSolved}
                />
                <BasicMetricContainer
                  title="Open Risks"
                  value={sasRisk.unsolved ? (sasRisk.unsolved as unknown as string) : '0'}
                  unit=""
                  href={FinTechOpsHref.sas}
                  id={FinTechOpsCategories.sas}
                  color={FinTechOpsCategoriesLabelColors.sas}
                  description={FinTechOpsMetricsDescriptions.sasUnsolved}
                />
                <BasicMetricContainer
                  title="Open Risks With Recommendation"
                  value={sasRisk.open_with_recommendation ? (sasRisk.open_with_recommendation as unknown as string) : '0'}
                  unit=""
                  href={FinTechOpsHref.sas}
                  id={FinTechOpsCategories.sas}
                  color={FinTechOpsCategoriesLabelColors.sas}
                  description={FinTechOpsMetricsDescriptions.sasOpenWithRecommendation}
                />
                {/* <BasicMetricContainer
                  title="Open without Recommendations"
                  value={sasRisk.open_without_recommendation ? (sasRisk.open_without_recommendation as unknown as string) : '0'}
                  unit=""
                  href={FinTechOpsHref.sas}
                  id={FinTechOpsCategories.sas}
                  color={FinTechOpsCategoriesLabelColors.sas}
                  description={FinTechOpsMetricsDescriptions.sasOpenWithoutRecommendation}
                />
                <BasicMetricContainer
                  title="Average With Recommendation Age"
                  value={sasRisk.avg_with_recommendation_age ? (sasRisk.avg_with_recommendation_age as unknown as string) : '0'}
                  unit=""
                  href={FinTechOpsHref.sas}
                  id={FinTechOpsCategories.sas}
                  color={FinTechOpsCategoriesLabelColors.sas}
                  description={FinTechOpsMetricsDescriptions.sasAverageRecommendationAge}
                /> */}
                <BasicMetricContainer
                  title="Resolution Rate"
                  value={sasRisk.resolution_rate ? (sasRisk.resolution_rate as unknown as string) + '%' : '0'}
                  unit=""
                  href={FinTechOpsHref.sas}
                  id={FinTechOpsCategories.sas}
                  color={FinTechOpsCategoriesLabelColors.sas}
                  description={FinTechOpsMetricsDescriptions.sasResolutionRate}
                />
              </ColumnLayout>
            );
          }
        })()}
      </div>
    </Box>
  );
};
