export const WITHOUT_USERS_COLUMN_LIST = [
  'log_month',
  'application',
  'application_type',
  'team_name',
  'supervisor_login_name_hierarchy',
  'supervisor_name_hierarchy'
];

export const WITHOUT_USERS_AGGREGATE_COLUMN_LISTS = [
  { name: 'measure_value', operation: 'sum' },
  { name: 'distinct_users', operation: 'count' }
];

export const APPLICATION_FOOTPRINT_COLUMN_LISTS = ['application_type', 'team_name'];

export const APPLICATION_USER_VIEW_COLUMN_LISTS = [...APPLICATION_FOOTPRINT_COLUMN_LISTS, 'user_login'];

export const APPLICATION_USER_VIEW_MEASURE_COLUMN_LISTS = [
  { name: 'measure_value', operation: 'sum' },
  { name: 'active_since', operation: 'minDate' },
  { name: 'last_active', operation: 'maxDate' }
];

export const SUB_APPLICATION_FOOTPRINT_COLUMN_LISTS = [...APPLICATION_FOOTPRINT_COLUMN_LISTS, 'application'];

export const SUB_APPLICATION_FOOTPRINT_MEASURE_COLUMN_LISTS = [
  { name: 'measure_value', operation: 'sum' },
  { name: 'active_since', operation: 'minDate' },
  { name: 'user_login', operation: 'countDistinct' }
];

export const APPLICATION_FOOTPRINT_MEASURE_COLUMN_LISTS = [...SUB_APPLICATION_FOOTPRINT_MEASURE_COLUMN_LISTS];

export const SUB_APPLICATION_USER_VIEW_COLUMN_LISTS = [...SUB_APPLICATION_FOOTPRINT_COLUMN_LISTS, 'user_login', 'active_since', 'last_active'];

export const SUB_APPLICATION_USER_VIEW_MEASURE_COLUMN_LISTS = [{ name: 'measure_value', operation: 'sum' }];

export const APPLICATION = 'application';

export const USER_METRICS_DASHBOARD_COLUMN_LIST = ['application_type', 'log_month'];

export const USER_METRICS_DASHBOARD_MEASURE_COLUMN_LIST = [
  { name: 'user_login', operation: 'countDistinct' },
  { name: 'measure_value', operation: 'sum' }
];

export const USER_METRICS_TOP_5_APPLICATION_BY_MONTH_COLUMN_LIST = ['application', 'log_month'];

export const USER_METRICS_TOP_5_APPLICATION_BY_MONTH_MEASURE_COLUMN_LIST = [{ name: 'user_login', operation: 'countDistinct' }];

export const USER_METRICS_TOTAL_UNIQUE_USERS_BY_MONTH_COLUMN_LIST = ['log_month', 'application_type'];

export const USER_METRICS_TOTAL_UNIQUE_USERS_BY_MONTH_MEASURE_COLUMN_LIST = [...USER_METRICS_TOP_5_APPLICATION_BY_MONTH_MEASURE_COLUMN_LIST];

export const USER_METRICS_AGG_APPLICATION_BY_USERS_COLUMN_LIST = ['application_type', 'create_date_formatted'];

export const USER_METRICS_AGG_SUB_APPLICATION_COLUMN_LIST = ['application', ...USER_METRICS_AGG_APPLICATION_BY_USERS_COLUMN_LIST];

export const USER_METRICS_AGG_APPLICATION_BY_USERS_MEASURE_COLUMN_LIST = [...USER_METRICS_TOP_5_APPLICATION_BY_MONTH_MEASURE_COLUMN_LIST];
