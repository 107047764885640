import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { useUserMetricsContext } from './UserMetricsContext';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import styles from './styles.module.scss';
import {
  USER_METRICS_APPLICATION_USER_VIEW__FILTERING_PROPERTIES,
  USER_METRICS_FOOTPRINT_FILTERING_PROPERTIES,
  USER_METRICS_SUB_APPLICATION_FOOTPRINT_FILTERING_PROPERTIES,
  USER_METRICS_SUB_APPLICATION_USER_VIEW__FILTERING_PROPERTIES
} from './UserMetricsTableFilterConfig';
import { Box, Container, Grid, Header, Icon, SpaceBetween, TableProps, Toggle } from '@amzn/awsui-components-react';
import { DetailsTableWithProps, DetailsTableWithPropsNoPag } from '../fintech-ops-charts/TableWithPropsCommonComponent';
import { Top3Apps } from './UserMetricsInterfaces';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { indicatorIcon, indicatorIconVariant } from '../fintech-ops-dashboard/FinTechOpsUtils';

export const UserMetricsDetailsTab = () => {
  const finOpsContext = useFinTechContext();
  const userMetricsContext = useUserMetricsContext();

  const [checked, setChecked] = useState(false);
  const [applicationChecked, setApplicationChecked] = useState(false);

  const [footprintChecked, setFootprintChecked] = useState(false);
  const [userViewChecked, setUserViewChecked] = useState(false);

  // Top 3 Apps
  const top3AppVisibleColumns = ['application', 'views', 'MoM'];
  const [top3AppColumnDefinition, setTop3AppColumnDefinition] = useState<TableProps.ColumnDefinition<any>[]>([]);
  const [top3AppData, setTop3AppData] = useState<any[]>([]);

  // TODO
  // useEffect(() => {
  //   const transformedMonths = transformedMonthsArray(userMetricsContext.month.map((month: any) => month.label));
  //   const headersToInclude = ['Application Type', 'Application Name'];
  //   const filteredColumns = USER_METRICS_AGG_COLUMN_DEFINITIONS.filter((column: any) => {
  //     return headersToInclude.includes(column.header) || transformedMonths.includes(column.header);
  //   });
  //   setColumnAggregation(filteredColumns);
  // }, [userMetricsContext.month]);

  useEffect(() => {
    const currentMonth = userMetricsContext.applicationFootprintContainer.currentMonth;
    if (userMetricsContext.applicationFootprintContainer.top3Apps) {
      const colDef: TableProps.ColumnDefinition<Top3Apps>[] = [
        {
          id: 'application',
          header: 'Top 3',
          sortingField: 'application',
          cell: (item: Top3Apps) => <>{item.application}</>,
          width: '120px'
        },
        {
          id: 'views',
          header: 'Views ' + currentMonth,
          sortingField: 'views',
          cell: (item: Top3Apps) => <>{numberFormatter(item.views) || '-'}</>,
          width: '160px'
        },
        {
          id: 'MoM',
          header: 'MoM',
          sortingField: 'MoM',
          cell: (item: Top3Apps) => (
            <>
              {numberFormatter(Math.abs(item.MoM)) || '-'}
              <Icon name={indicatorIcon(item.MoM)} variant={indicatorIconVariant(item.MoM ? item.MoM * -1 : 0)} />
            </>
          )
        }
      ];
      setTop3AppColumnDefinition(colDef);
    }
    setTop3AppData(userMetricsContext.applicationFootprintContainer.top3Apps || []);
  }, [userMetricsContext.applicationFootprintContainer.top3Apps]);

  return (
    <div>
      {finOpsContext.finTechOpsUserMetricsDataStatus == 'loading' && <LoadingSpinner />}
      {finOpsContext.finTechOpsUserMetricsDataStatus == 'error' && <ErrorContainer />}
      {finOpsContext.finTechOpsUserMetricsDataStatus == 'pending' && <NotInitializedContainer />}
      {finOpsContext.finTechOpsUserMetricsDataStatus == 'finished' && (
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 4, l: 4, xl: 4 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 8, l: 8, xl: 8 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } },
            { colspan: { default: 12, xxs: 12, xs: 12, s: 12, m: 12, l: 12, xl: 12 } }
          ]}
        >
          <Container fitHeight header={<Header variant="h2">Total Sub Application Views</Header>}>
            <Box variant="h3" textAlign="center">
              {numberFormatter(userMetricsContext.applicationFootprintContainer.total_views)}
            </Box>
            {userMetricsContext.applicationFootprintContainer.top3Apps.length > 0 ? (
              <div className={styles.UserMetricsFootPrintContainerTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={3}
                  emptyMessage="No data to display"
                  Data={top3AppData}
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={top3AppVisibleColumns}
                  COLUMN_DEFINITIONS={top3AppColumnDefinition}
                  headerName=""
                />
              </div>
            ) : (
              <Box textAlign="center">No Data To Display</Box>
            )}
          </Container>
          <Container
            fitHeight
            header={
              <>
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Header>Aggregated Metrics by Application</Header>
                  <Toggle
                    onChange={({ detail }) => {
                      setChecked(detail.checked);
                    }}
                    checked={checked}
                  >
                    {!checked ? 'Unique Users' : 'Views'}
                  </Toggle>

                  <Toggle
                    onChange={({ detail }) => {
                      setApplicationChecked(detail.checked);
                    }}
                    checked={applicationChecked}
                  >
                    {!applicationChecked ? 'Sub Application' : 'Application'}
                  </Toggle>
                </SpaceBetween>
              </>
            }
          >
            {checked && applicationChecked ? (
              <div className={styles.UserMetricsAggregatedDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={6}
                  emptyMessage="No User Metrics to Display"
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={userMetricsContext.aggregatedViewsTableData.visibleColumns}
                  COLUMN_DEFINITIONS={userMetricsContext.aggregatedViewsTableData.columnData}
                  Data={userMetricsContext.aggregatedViewsTableData.rowData}
                  headerName=""
                />
              </div>
            ) : checked && !applicationChecked ? (
              <div className={styles.UserMetricsAggregatedDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={100}
                  emptyMessage="No User Metrics to Display"
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={userMetricsContext.aggregatedSubApplicationViewsTableData.visibleColumns}
                  COLUMN_DEFINITIONS={userMetricsContext.aggregatedSubApplicationViewsTableData.columnData}
                  Data={userMetricsContext.aggregatedSubApplicationViewsTableData.rowData}
                />
              </div>
            ) : !checked && applicationChecked ? (
              <div className={styles.UserMetricsAggregatedDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={100}
                  emptyMessage="No User Metrics to Display"
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={userMetricsContext.applicationUsersTableData.visibleColumns}
                  COLUMN_DEFINITIONS={userMetricsContext.applicationUsersTableData.columnData}
                  Data={userMetricsContext.applicationUsersTableData.rowData}
                />
              </div>
            ) : !checked && !applicationChecked ? (
              <div className={styles.UserMetricsAggregatedDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={100}
                  emptyMessage="No User Metrics to Display"
                  FILTERING_PROPERTIES={[]}
                  VISIBLE_CONTENT={userMetricsContext.aggregatedSubApplicationUniqueUsersTableData.visibleColumns}
                  COLUMN_DEFINITIONS={userMetricsContext.aggregatedSubApplicationUniqueUsersTableData.columnData}
                  Data={userMetricsContext.aggregatedSubApplicationUniqueUsersTableData.rowData}
                />
              </div>
            ) : (
              ''
            )}
          </Container>
          {/* Footprint Details */}
          <Container
            fitHeight
            header={
              <>
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Header>Footprint Details</Header>
                  <Toggle
                    onChange={({ detail }) => {
                      setFootprintChecked(detail.checked);
                    }}
                    checked={footprintChecked}
                  >
                    {!footprintChecked ? 'Sub Application' : 'Application'}
                  </Toggle>
                </SpaceBetween>
              </>
            }
          >
            {footprintChecked ? (
              <div className={styles.UserMetricsFootPrintDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={10}
                  emptyMessage="No User Metrics to Display"
                  FILTERING_PROPERTIES={USER_METRICS_FOOTPRINT_FILTERING_PROPERTIES}
                  VISIBLE_CONTENT={userMetricsContext.applicationFootprintTableData.visibleColumns}
                  COLUMN_DEFINITIONS={userMetricsContext.applicationFootprintTableData.columnData}
                  Data={userMetricsContext.applicationFootprintTableData.rowData}
                  headerName=""
                />
              </div>
            ) : (
              <div className={styles.UserMetricsFootPrintDetailsTable}>
                <DetailsTableWithPropsNoPag
                  pageSize={10}
                  emptyMessage="No User Metrics to Display"
                  FILTERING_PROPERTIES={USER_METRICS_SUB_APPLICATION_FOOTPRINT_FILTERING_PROPERTIES}
                  VISIBLE_CONTENT={userMetricsContext.subApplicationFootprintTableData.visibleColumns}
                  COLUMN_DEFINITIONS={userMetricsContext.subApplicationFootprintTableData.columnData}
                  Data={userMetricsContext.subApplicationFootprintTableData.rowData}
                  headerName=""
                />
              </div>
            )}
          </Container>
          {/* User View */}
          <Container
            fitHeight
            header={
              <>
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Header>User View</Header>
                  <Toggle
                    onChange={({ detail }) => {
                      setUserViewChecked(detail.checked);
                    }}
                    checked={userViewChecked}
                  >
                    {!userViewChecked ? 'Sub Application' : 'Application'}
                  </Toggle>
                </SpaceBetween>
              </>
            }
          >
            {userViewChecked ? (
              <DetailsTableWithProps
                pageSize={10}
                emptyMessage="No User Metrics to Display"
                FILTERING_PROPERTIES={USER_METRICS_APPLICATION_USER_VIEW__FILTERING_PROPERTIES}
                VISIBLE_CONTENT={userMetricsContext.applicationUserViewTableData.visibleColumns}
                COLUMN_DEFINITIONS={userMetricsContext.applicationUserViewTableData.columnData}
                Data={userMetricsContext.applicationUserViewTableData.rowData}
                headerName=""
              />
            ) : (
              <DetailsTableWithProps
                pageSize={10}
                emptyMessage="No User Metrics to Display"
                FILTERING_PROPERTIES={USER_METRICS_SUB_APPLICATION_USER_VIEW__FILTERING_PROPERTIES}
                VISIBLE_CONTENT={userMetricsContext.subApplicationUserViewTableData.visibleColumns}
                COLUMN_DEFINITIONS={userMetricsContext.subApplicationUserViewTableData.columnData}
                Data={userMetricsContext.subApplicationUserViewTableData.rowData}
                headerName=""
              />
            )}
          </Container>
        </Grid>
      )}
    </div>
  );
};
