import { Box } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action?: ReactNode;
}
export const EmptyStateMessage = ({ title, subtitle, action }: EmptyStateProps) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
};
