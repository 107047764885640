import { WizardProps } from '@amzn/awsui-components-react';

export const wizardI18nStrings: (action: 'Create' | 'Update') => WizardProps.I18nStrings = (action) => ({
  stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
  collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
  skipToButtonLabel: (step) => `Skip to ${step.title}`,
  navigationAriaLabel: 'Steps',
  cancelButton: 'Cancel',
  previousButton: 'Previous',
  nextButton: 'Next',
  submitButton: `${action} application`,
  optional: 'optional'
});
