import { useTicketContext } from './TicketsContext';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef, HeaderValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer } from '../FinTechOpsCommonElements';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { format, subMonths } from 'date-fns';
import { Button } from '@aws-amplify/ui-react';
import { FaDownload } from 'react-icons/fa';
import { TimeSpentTicketData, TimeSpentAggregatedData } from './TicketsInterfaces';

export const TimeSpentDetailsTab = () => {
  const ticketContext = useTicketContext();
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(
    () => ({
      height: '60vh',
      width: '100%'
    }),
    []
  );
  const localeText = useMemo(
    () => ({
      rowGroupColumnsEmptyMessage: 'Drag here for grouping'
    }),
    []
  );

  const [rowData, setRowData] = useState<any[]>([]);

  useEffect(() => {
    if (ticketContext.ticketResolvedDetailsTable.rowData) {
      const months = Array.from({ length: 6 }, (_, i) => {
        const date = subMonths(new Date(), i);
        return format(date, 'MMM yyyy');
      }).reverse();

      const initialData: TimeSpentAggregatedData = {};

      ticketContext.ticketResolvedDetailsTable.rowData.forEach((ticket: TimeSpentTicketData) => {
        const appKey = ticket.application_name;
        const resType = ticket.resolution_type;

        if (!initialData[`${appKey}-${resType}`]) {
          initialData[`${appKey}-${resType}`] = {
            application: appKey,
            'Resolution-type': resType
          };

          months.forEach((month) => {
            initialData[`${appKey}-${resType}`][`${month}_tickets`] = 0;
            initialData[`${appKey}-${resType}`][`${month}_time`] = 0;
          });
        }
      });

      ticketContext.ticketResolvedDetailsTable.rowData.forEach((ticket: TimeSpentTicketData) => {
        const ticketDate = new Date(ticket.create_date_UserTimeZone);
        const monthKey = format(ticketDate, 'MMM yyyy');

        if (months.includes(monthKey)) {
          const key = `${ticket.application_name}-${ticket.resolution_type}`;
          initialData[key][`${monthKey}_tickets`] = (initialData[key][`${monthKey}_tickets`] as number) + 1;
          initialData[key][`${monthKey}_time`] = (initialData[key][`${monthKey}_time`] as number) + (ticket.total_time_spent || 0);
        }
      });

      const processedData = Object.values(initialData);
      setRowData(Object.values(initialData));
    }
  }, [ticketContext.ticketResolvedDetailsTable.rowData]);

  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([]);
  useEffect(() => {
    const months = Array.from({ length: 6 }, (_, i) => {
      const date = subMonths(new Date(), i);
      return format(date, 'MMM yyyy');
    });

    const monthHasData = (month: string) => {
      return rowData.some((row) => row[`${month}_tickets`] > 0 || row[`${month}_time`] > 0);
    };

    const baseColumns = [
      {
        field: 'application',
        headerName: 'Application',
        enableRowGroup: true,
        rowGroup: true,
        rowGroupIndex: 0,
        hide: true,
        enablePivot: true
      },
      {
        field: 'Resolution-type',
        headerName: 'Resolution Type',
        enableRowGroup: true,
        hide: false,
        enablePivot: true
      }
    ];

    const monthColumns = months
      .filter((month) => monthHasData(month))
      .map((month) => ({
        headerName: month,
        children: [
          {
            field: `${month}_tickets`,
            headerName: 'Tickets',
            aggFunc: 'sum',
            valueFormatter: (params: ValueFormatterParams) => (params.value ? params.value.toString() : '0'),
            minWidth: 100
          },
          {
            field: `${month}_time`,
            headerName: 'Effort (Hrs)',
            aggFunc: 'sum',
            valueFormatter: (params: ValueFormatterParams) => (params.value ? `${params.value.toFixed(2)}` : '0'),
            minWidth: 100
          }
        ]
      }));

    setColumnDefs([...baseColumns, ...monthColumns]);
  }, [rowData]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      sortable: true,
      filter: true,
      resizable: true,
      enableRowGroup: true,
      enablePivot: true
    }),
    []
  );

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      minWidth: 300,
      flex: 1,
      headerValueGetter: (params: HeaderValueGetterParams): string => {
        const groupedCols = params.api.getRowGroupColumns();
        if (groupedCols.length > 0) {
          return groupedCols[0].getColDef().headerName || 'Group';
        }
        return 'Group';
      },
      cellRendererParams: {
        suppressCount: true,
        checkbox: false
      },
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') {
          return 'Total';
        }
        return params.value;
      },
      cellStyle: (params): { [key: string]: string | number } => {
        if (params.node?.rowPinned === 'bottom') {
          return { fontWeight: 'bold' };
        }
        return { fontWeight: 'normal' };
      }
    }),
    []
  );

  const onDownloadClick = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      const params = {
        fileName: `Time_Spent_Details_${format(new Date(), 'yyyy-MM-dd')}`,
        suppressQuotes: true
      };
      gridRef.current.api.exportDataAsCsv(params);
    }
  }, []);

  if (ticketContext.statusOfDetailsTab === 'loading') {
    return <LoadingSpinner />;
  }

  if (ticketContext.statusOfDetailsTab === 'error') {
    return <ErrorContainer />;
  }

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1rem',
          gap: '1rem'
        }}
      >
        <Button onClick={onDownloadClick} variation="primary">
          <FaDownload /> Download Report
        </Button>
      </div>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows={true}
          groupDefaultExpanded={0}
          enableRangeSelection={true}
          suppressRowClickSelection={true}
          rowSelection={undefined}
          suppressAggFuncInHeader={true}
          rowGroupPanelShow={'always'}
          pivotPanelShow={'always'}
          allowDragFromColumnsToolPanel={true}
          suppressDragLeaveHidesColumns={true}
          grandTotalRow={'bottom'}
          localeText={localeText}
        />
      </div>
    </div>
  );
};
