import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { getDay, getMonth } from 'src/utilities/DateTimeUtilities';

interface MonthDateProps {
  dateTime: string;
}
const MonthDate: React.FC<MonthDateProps> = ({ dateTime }) => {
  return (
    <SpaceBetween size="xxxs" direction="vertical" className="monthDate">
      <Box display="block" textAlign="center" fontSize="heading-s" fontWeight="light" variant="awsui-key-label">
        {getMonth(dateTime).toUpperCase()}
      </Box>
      <Box display="block" textAlign="center" fontSize="heading-m" fontWeight="bold" variant="awsui-key-label">
        {getDay(dateTime)}
      </Box>
    </SpaceBetween>
  );
};

export default MonthDate;
