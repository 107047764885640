import { Box, ColumnLayout, Header, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { WidgetConfig } from '../interface';
import { AllAppsWelcomeContentDetailsEntity } from 'src/components/context/AppContextModels';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from '../../das-finsuite-constants';

export const WelcomeWidget = (
  welcomeTitle: string,
  description: string,
  WelcomeContentDetails: AllAppsWelcomeContentDetailsEntity[]
): WidgetConfig => {
  return {
    definition: { defaultRowSpan: 4, defaultColumnSpan: 1 },
    data: {
      icon: 'list',
      title: welcomeTitle,
      description: description,
      header: <WelcomeHeader title={welcomeTitle} description={description} />,
      content: <WelcomeWidgetContent WelcomeContentDetails={WelcomeContentDetails} />
    }
  };
};

interface WelcomeHeaderProps {
  title: string;
  description: string;
}
const WelcomeHeader: React.FC<WelcomeHeaderProps> = ({ title, description }) => {
  return (
    <Header description={description}>
      <Box variant="h2">{title}</Box>
    </Header>
  );
};

interface WelcomeWidgetProps {
  WelcomeContentDetails: AllAppsWelcomeContentDetailsEntity[];
}
const WelcomeWidgetContent: React.FC<WelcomeWidgetProps> = ({ WelcomeContentDetails }) => {
  return (
    <div>
      {WelcomeContentDetails.length === 0 && <Box textAlign="center">No content</Box>}
      {WelcomeContentDetails.length !== 0 &&
        WelcomeContentDetails?.map((welcomeItem, index) => {
          return (
            <div key={index}>
              <ColumnLayout columns={1} variant="text-grid" borders="horizontal">
                <div>
                  <Box padding={{ vertical: 'xxs' }}>
                    {welcomeItem.link && (
                      <Link
                        variant="secondary"
                        external
                        href={welcomeItem.link || ''}
                        onFollow={() => {
                          logger.info(`DaS FinSuite -> User Action: Welcome Widget link clicked`, {
                            event_type: UserActionsEventTypes.WELCOME_WIDGET_LINK_CLICKED,
                            value: welcomeItem.displayText,
                            sub_value: welcomeItem.link
                          });
                        }}
                      >
                        {welcomeItem.displayText}
                      </Link>
                    )}
                    {!welcomeItem.link && (
                      <Box variant="awsui-key-label" color="text-status-info" fontSize="body-m" fontWeight="normal">
                        {welcomeItem.displayText}
                      </Box>
                    )}
                  </Box>
                  <Box variant="p" fontSize="body-s" fontWeight="light" margin={{ bottom: 's' }}>
                    {welcomeItem.description}
                  </Box>
                  {index !== WelcomeContentDetails?.length - 1 && <div className="horizontal-bar"></div>}
                </div>
              </ColumnLayout>
            </div>
          );
        })}
    </div>
  );
};
