import { AppLayout, Flashbar, FlashbarProps, Link, MixedLineBarChartProps, Tabs } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { DFPHomePageSideNavigation } from 'src/components/dfp-home-page/DFPHomePageSideNavigation';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { FinTechOpsHref } from '../FinTechOpsConstants';
import { StatusType } from '../sas/SasInterfaces';
import { UserMetricsContext } from './UserMetricsContext';
import { UserMetricsDetailsTab } from './UserMetricsDetailsTab';
import { UserMetricsAggregatedViewsTable, UserMetricsApplicationFootprintContainer } from './UserMetricsInterfaces';
import { UserMetricsOverview } from './UserMetricsOverview';
import { UserMetricsSelectionFilters } from './UserMetricsSelectionFilters';

export const UserMetricsPage = () => {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  //for Page
  const [multiselectStatus, setMultiselectStatus] = useState<StatusType>('loading');
  const [overViewStatus, setOverViewStatus] = useState<StatusType>('loading');
  const [detailsStatus, setDetailsStatus] = useState<StatusType>('loading');

  //for Graphs
  const [appViewershipByTeamData, setAppViewershipByTeamData] = useState<MixedLineBarChartProps.BarDataSeries<string | number | Date>[]>([]);
  const [appViewershipByTeamXDomain, setAppViewershipByTeamXDomain] = useState<string[]>([]);
  const [appViewershipByTeamYDomain, setAppViewershipByTeamYDomain] = useState<number[]>([]);

  const [top5AppsViewership, setTop5AppsViewership] = useState<MixedLineBarChartProps.LineDataSeries<string | number | Date>[]>([]);
  const [top5AppsViewershipXDomain, setTop5AppsViewershipXDomain] = useState<string[]>([]);
  const [top5AppsViewershipYDomain, setTop5AppsViewershipYDomain] = useState<number[]>([]);

  const [uniqueUsersByApplication, setUniqueUsersByApplication] = useState<MixedLineBarChartProps.LineDataSeries<string | number | Date>[]>([]);
  const [uniqueUsersByApplicationXDomain, setUniqueUsersByApplicationXDomain] = useState<string[]>([]);
  const [uniqueUsersByApplicationYDomain, setUniqueUsersByApplicationYDomain] = useState<number[]>([]);

  const [totalApplicationViews, setTotalApplicationViews] = useState<MixedLineBarChartProps.LineDataSeries<string | number | Date>[]>([]);
  const [totalApplicationViewsXDomain, setTotalApplicationViewsXDomain] = useState<string[]>([]);
  const [totalApplicationViewsYDomain, setTotalApplicationViewsYDomain] = useState<number[]>([]);

  const [aggregatedViewsTableData, setAggregatedViewsTableData] = useState<UserMetricsAggregatedViewsTable>({} as UserMetricsAggregatedViewsTable);

  const [applicationViewsTableData, setApplicationViewsTableData] = useState<UserMetricsAggregatedViewsTable>({} as UserMetricsAggregatedViewsTable);

  const [aggregatedSubApplicationViewsTableData, setAggregatedSubApplicationViewsTableData] = useState<UserMetricsAggregatedViewsTable>(
    {} as UserMetricsAggregatedViewsTable
  );

  const [aggregatedSubApplicationUniqueUsersTableData, setAggregatedSubApplicationUniqueUsersTableData] = useState<UserMetricsAggregatedViewsTable>(
    {} as UserMetricsAggregatedViewsTable
  );

  const [applicationFootprintTableData, setApplicationFootprintTableData] = useState<UserMetricsAggregatedViewsTable>(
    {} as UserMetricsAggregatedViewsTable
  );

  const [subApplicationFootprintTableData, setSubApplicationFootprintTableData] = useState<UserMetricsAggregatedViewsTable>(
    {} as UserMetricsAggregatedViewsTable
  );

  const [applicationFootprintContainer, setApplicationFootprintContainer] = useState<UserMetricsApplicationFootprintContainer>(
    {} as UserMetricsApplicationFootprintContainer
  );

  const [applicationUserViewTableData, setApplicationUserViewTableData] = useState<UserMetricsAggregatedViewsTable>(
    {} as UserMetricsAggregatedViewsTable
  );

  const [subApplicationUserViewTableData, setSubApplicationUserViewTableData] = useState<UserMetricsAggregatedViewsTable>(
    {} as UserMetricsAggregatedViewsTable
  );

  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            {' '}
            submit a ticket
          </Link>
        );
      }
    }
    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} 'or ' {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={flashbarItems} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: FinTechOpsHref.finTechOps },
            {
              text: 'User Metrics',
              href: ''
            }
          ]}
        />
      }
      maxContentWidth={Number.MAX_VALUE}
      content={
        <div>
          <UserMetricsContext.Provider
            value={{
              displayNotification,

              multiselectStatus,
              setMultiselectStatus,

              overViewStatus,
              setOverViewStatus,

              detailsStatus,
              setDetailsStatus,

              appViewershipByTeamData,
              setAppViewershipByTeamData,
              appViewershipByTeamXDomain,
              setAppViewershipByTeamXDomain,
              appViewershipByTeamYDomain,
              setAppViewershipByTeamYDomain,

              top5AppsViewership,
              setTop5AppsViewership,
              top5AppsViewershipXDomain,
              setTop5AppsViewershipXDomain,
              top5AppsViewershipYDomain,
              setTop5AppsViewershipYDomain,

              uniqueUsersByApplication,
              setUniqueUsersByApplication,
              uniqueUsersByApplicationXDomain,
              setUniqueUsersByApplicationXDomain,
              uniqueUsersByApplicationYDomain,
              setUniqueUsersByApplicationYDomain,

              totalApplicationViews,
              setTotalApplicationViews,
              totalApplicationViewsXDomain,
              setTotalApplicationViewsXDomain,
              totalApplicationViewsYDomain,
              setTotalApplicationViewsYDomain,

              aggregatedViewsTableData: aggregatedViewsTableData,
              setAggregatedViewsTableData: setAggregatedViewsTableData,

              applicationUsersTableData: applicationViewsTableData,
              setApplicationUsersTableData: setApplicationViewsTableData,

              aggregatedSubApplicationViewsTableData: aggregatedSubApplicationViewsTableData,
              setAggregatedSubApplicationViewsTableData,

              aggregatedSubApplicationUniqueUsersTableData,
              setAggregatedSubApplicationUniqueUsersTableData,

              applicationFootprintTableData,
              setApplicationFootprintTableData,

              subApplicationFootprintTableData,
              setSubApplicationFootprintTableData,

              applicationFootprintContainer,
              setApplicationFootprintContainer,

              applicationUserViewTableData,
              setApplicationUserViewTableData,

              subApplicationUserViewTableData,
              setSubApplicationUserViewTableData
            }}
          >
            <UserMetricsSelectionFilters />
            <Tabs
              tabs={[
                {
                  label: 'Overview',
                  id: 'userMetrics-OV',
                  content: <UserMetricsOverview />
                },
                {
                  label: 'Details',
                  id: 'userMetrics-DT',
                  content: <UserMetricsDetailsTab />
                }
              ]}
            />
          </UserMetricsContext.Provider>
        </div>
      }
    />
  );
};
