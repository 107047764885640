import { Box, ColumnLayout, Header, Link } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { WidgetConfig } from '../interface';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { eGENERAL_APP_CONTENT_KEY } from 'src/constants/AppConstants';
import { DFPHomePageGettingStarted } from 'src/components/context/AppContextModels';
import TutorialVideoModal from '../tutorials-widget/TutorialVideoModal';
import ON_HOVER from 'src/images/getting-started-das-onhover.svg';
import THUMBNAIL from 'src/images/getting-started-das-thumbnail.svg';
import { isDefinedAndNotEmptyObject } from 'src/utilities/CommonUtilities';
import EmptyData from 'src/components/generic-components/EmptyData';

export const GettingStartedWidget = (
  gettingStatedTitle: string,
  description: string,
  GettingStartedContentDetails: DFPHomePageGettingStarted
): WidgetConfig => {
  return {
    definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 2 },
    data: {
      icon: 'list',
      title: gettingStatedTitle,
      description: description,
      header: <GettingStartedWidgetHeader title={gettingStatedTitle} description={description} />,
      content: <GettingStartedWidgetContent DfpHomePageGettingStarted={GettingStartedContentDetails} />
    }
  };
};

interface GettingStartedWidgetHeaderProps {
  title: string;
  description: string;
}
const GettingStartedWidgetHeader: React.FC<GettingStartedWidgetHeaderProps> = ({ title, description }) => {
  return (
    <Header description={description}>
      <Box variant="h2">{title}</Box>
    </Header>
  );
};

interface GettingStartedWidgetContentProps {
  DfpHomePageGettingStarted: DFPHomePageGettingStarted;
}
const GettingStartedWidgetContent: React.FC<GettingStartedWidgetContentProps> = ({ DfpHomePageGettingStarted }) => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [imgSrc, setImgSrc] = useState(THUMBNAIL);

  const handleMouseEnter = () => {
    setImgSrc(ON_HOVER);
  };

  const handleMouseLeave = () => {
    setImgSrc(THUMBNAIL);
  };

  const handleTutorialLinkClick = () => {
    setShowTutorialModal(true);
  };

  const onCancelVideo = () => {
    setShowTutorialModal(false);
  };

  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const newWidth = Math.floor(entry.contentRect.width);
          const newHeight = Math.floor(entry.contentRect.height);

          // Only update if there's a significant change
          if (Math.abs(newWidth - containerSize.width) > 1 || Math.abs(newHeight - containerSize.height) > 1) {
            setContainerSize({
              width: newWidth,
              height: newHeight
            });
          }
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [containerSize]);

  return (
    <div ref={containerRef}>
      <TutorialVideoModal
        showTutorialModal={showTutorialModal}
        onCancel={onCancelVideo}
        onConfirm={() => setShowTutorialModal(false)}
        link={DfpHomePageGettingStarted?.link || ''}
        displayTitle={'Getting Started'}
        category={'Getting Started'}
      />
      {isDefinedAndNotEmptyObject(DfpHomePageGettingStarted) && (
        <Box className="getting-started-container">
          <img
            onClick={() => handleTutorialLinkClick()}
            className="img-thumbnail click-top-play"
            src={imgSrc}
            alt="Getting started thumbnail"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              width: `${containerSize.width}px`,
              height: `${containerSize.height}px`
            }}
          />
        </Box>
      )}

      {!isDefinedAndNotEmptyObject(DfpHomePageGettingStarted) && <EmptyData></EmptyData>}
    </div>
  );
};
