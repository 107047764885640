import { FlashbarProps, MixedLineBarChartProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import { SaSDetailsTabTableData, SaSSummaryContainerInfo, SasParsedData, StatusType, sasDataDetailsTable } from './SasInterfaces';

export type SasContextType = {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => void;
  multiselectStatus: StatusType;
  setMultiselectStatus: (status: StatusType) => void;

  overViewStatus: StatusType;
  setOverViewStatus: (status: StatusType) => void;

  detailsStatus: StatusType;
  setDetailsStatus: (status: StatusType) => void;

  //details page By team DOD...
  sasSummaryContainer: SaSSummaryContainerInfo;
  setSasSummaryContainer: (sasSummaryContainer: SaSSummaryContainerInfo) => void;

  //graphs
  //1
  riskResponseGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]; //Risk by Resource Type
  setRiskResponseGraphData: (riskResponseGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void; //Based on sevTicketsByDateData
  riskResponseGraphDataXDomain: string[];
  setRiskResponseGraphDataXDomain: (riskResponseGraphDataXDomain: string[]) => void;
  riskResponseGraphDataYDomain: number[];
  setRiskResponseGraphDataYDomain: (riskResponseGraphDataYDomain: number[]) => void;

  //2
  totalOpenRiskGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]; //Risk by Resource Type
  setTotalOpenRiskGraphData: (totalOpenRiskGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void; //Based on sevTicketsByDateData
  totalOpenRiskGraphDataXDomain: string[];
  setTotalOpenRiskGraphDataXDomain: (totalOpenRiskGraphDataXDomain: string[]) => void;
  totalOpenRiskGraphDataYDomain: number[];
  setTotalOpenRiskGraphDataYDomain: (totalOpenRiskGraphDataYDomain: number[]) => void;

  //3
  campaignsGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]; //Risk by Resource Type
  setCampaignsGraphData: (campaignsGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void; //Based on sevTicketsByDateData
  campaignsGraphDataXDomain: string[];
  setCampaignsGraphDataXDomain: (campaignsGraphDataXDomain: string[]) => void;
  campaignsGraphDataYDomain: number[];
  setCampaignsGraphDataYDomain: (campaignsGraphDataYDomain: number[]) => void;

  //4
  totalOpenRecommendationsGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]; //Risk by Resource Type
  setTotalOpenRecommendationsGraphData: (totalOpenRecommendationsGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void; //Based on sevTicketsByDateData
  totalOpenRecommendationsGraphDataXDomain: string[];
  setTotalOpenRecommendationsGraphDataXDomain: (totalOpenRecommendationsGraphDataXDomain: string[]) => void;
  totalOpenRecommendationsGraphDataYDomain: number[];
  setTotalOpenRecommendationsGraphDataYDomain: (totalOpenRecommendationsGraphDataYDomain: number[]) => void;

  //5
  averageRiskDowGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]; //Risk by Resource Type
  setAverageRiskDowGraphData: (averageRiskDowGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => void; //Based on sevTicketsByDateData
  averageRiskDowGraphDataXDomain: string[];
  setAverageRiskDowGraphDataXDomain: (averageRiskDowGraphDataXDomain: string[]) => void;
  averageRiskDowGraphDataYDomain: number[];
  setAverageRiskDowGraphDataYDomain: (averageRiskDowGraphDataYDomain: number[]) => void;

  //Tables
  //1
  sasDetailsTableData: SaSDetailsTabTableData;
  setSasDetailsTableData: (sasDetailsTableData: SaSDetailsTabTableData) => void;
  //2
  sasTotalRiskResourceTableData: SaSDetailsTabTableData;
  setSasTotalRiskResourceTableData: (sasTotalRiskResourceTableData: SaSDetailsTabTableData) => void;
  //3
  sasManagerMomTableData: SaSDetailsTabTableData;
  setSasManagerMomTableData: (sasManagerMomTableData: SaSDetailsTabTableData) => void;
  //4
  sasOpenTotalRiskTableData: SaSDetailsTabTableData;
  setSasOpenTotalRiskTableData: (sasOpenTotalRiskTableData: SaSDetailsTabTableData) => void;
};

export const SasContext = createContext<SasContextType>({
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => {},
  multiselectStatus: 'pending',
  setMultiselectStatus: (status: StatusType) => {},

  overViewStatus: 'pending',
  setOverViewStatus: (status: StatusType) => {},

  detailsStatus: 'pending',
  setDetailsStatus: (status: StatusType) => {},

  sasSummaryContainer: {} as SaSSummaryContainerInfo,
  setSasSummaryContainer: (sasSummaryContainer: SaSSummaryContainerInfo) => {},

  riskResponseGraphData: [],
  setRiskResponseGraphData: (riskResponseGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  riskResponseGraphDataXDomain: [],
  setRiskResponseGraphDataXDomain: (riskResponseGraphDataXDomain: string[]) => {},
  riskResponseGraphDataYDomain: [],
  setRiskResponseGraphDataYDomain: (riskResponseGraphDataYDomain: number[]) => {},

  totalOpenRiskGraphData: [],
  setTotalOpenRiskGraphData: (totalOpenRiskGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  totalOpenRiskGraphDataXDomain: [],
  setTotalOpenRiskGraphDataXDomain: (totalOpenRiskGraphDataXDomain: string[]) => {},
  totalOpenRiskGraphDataYDomain: [],
  setTotalOpenRiskGraphDataYDomain: (totalOpenRiskGraphDataYDomain: number[]) => {},

  campaignsGraphData: [],
  setCampaignsGraphData: (campaignsGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  campaignsGraphDataXDomain: [],
  setCampaignsGraphDataXDomain: (campaignsGraphDataXDomain: string[]) => {},
  campaignsGraphDataYDomain: [],
  setCampaignsGraphDataYDomain: (campaignsGraphDataYDomain: number[]) => {},

  totalOpenRecommendationsGraphData: [],
  setTotalOpenRecommendationsGraphData: (totalOpenRecommendationsGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  totalOpenRecommendationsGraphDataXDomain: [],
  setTotalOpenRecommendationsGraphDataXDomain: (totalOpenRecommendationsGraphDataXDomain: string[]) => {},
  totalOpenRecommendationsGraphDataYDomain: [],
  setTotalOpenRecommendationsGraphDataYDomain: (totalOpenRecommendationsGraphDataYDomain: number[]) => {},

  averageRiskDowGraphData: [],
  setAverageRiskDowGraphData: (averageRiskDowGraphData: MixedLineBarChartProps.BarDataSeries<string | number | Date>[]) => {},
  averageRiskDowGraphDataXDomain: [],
  setAverageRiskDowGraphDataXDomain: (averageRiskDowGraphDataXDomain: string[]) => {},
  averageRiskDowGraphDataYDomain: [],
  setAverageRiskDowGraphDataYDomain: (averageRiskDowGraphDataYDomain: number[]) => {},

  sasDetailsTableData: {} as SaSDetailsTabTableData,
  setSasDetailsTableData: (sasDetailsTableData: SaSDetailsTabTableData) => {},
  sasTotalRiskResourceTableData: {} as SaSDetailsTabTableData,
  setSasTotalRiskResourceTableData: (sasTotalRiskResourceTableData: SaSDetailsTabTableData) => {},
  sasManagerMomTableData: {} as SaSDetailsTabTableData,
  setSasManagerMomTableData: (sasManagerMomTableData: SaSDetailsTabTableData) => {},
  sasOpenTotalRiskTableData: {} as SaSDetailsTabTableData,
  setSasOpenTotalRiskTableData: (sasOpenTotalRiskTableData: SaSDetailsTabTableData) => {}
});

export const useSasContext = () => useContext(SasContext);
