import { GetObjectCommand, ListObjectsV2Command, S3Client, S3ClientConfig } from '@aws-sdk/client-s3';
import moment from 'moment-timezone';
import { getCredentialsFromAWSService } from 'src/components/context/AuthContextUtils';
import { MetricsMeasure, UserMetricsRawData } from 'src/components/fintech-ops/user-metrics/UserMetricsInterfaces';
import { logger } from 'src/logger';
import { getCurrentTime, getTimeDifference } from './DateTimeUtilities';
import { S3_ENV_CONSTANTS } from './AWSServices';
import { FilterDateFormat_MMM_YYYY, FinTechOpsMessages } from 'src/components/fintech-ops/FinTechOpsConstants';
import { recordApiRequest } from 'src/analytics/CloudWatchRumClient';

// To check if both array have common elements
export const hasCommonElements = (array1: string[], array2: string[]): boolean => {
  return array1?.filter((element) => array2?.includes(element))?.length > 0;
};

// To check if an object is not undefined and not empty
export const isDefinedAndNotEmptyObject = (obj: any): boolean => {
  return typeof obj !== 'undefined' && Object.keys(obj).length !== 0;
};

export const characterCountConstraintMessage = (max: number, fieldLength: number) => {
  return `Maximum ${max} characters (${Math.max(max - fieldLength, 0)} remaining)`;
};

export const getValidationErrorMessage = (touched: any, errors: any) => {
  return touched && errors ? errors : '';
};

export const getMultiSelectPlaceHolderValue = (entity: any, entityName: string, options: string[]): string => {
  let placeHolderValue = `Select ${entityName}`;
  if (entity?.length == 0) {
    return placeHolderValue;
  }

  if (entity?.length == 1) {
    placeHolderValue = entity[0].label;
  }

  if (entity?.length > 1) {
    placeHolderValue = `${entity[0].label} + ${entity?.length - 1} ${entity?.length - 1 === 1 ? ' Other' : ' Others'}`;
  }

  if (entity?.length == options?.length) {
    placeHolderValue = `All ${entityName} Selected`;
  }

  return `${placeHolderValue} `;
};

export const replaceSpaces = (input: string, replacementValue = '') => {
  return input.replace(/\s+/g, replacementValue);
};

export const escapeSelector = (selector: string) => {
  const withoutWhitespace = selector.replace(/\s/g, '');
  // eslint-disable-next-line no-useless-escape
  return withoutWhitespace.replace(/[!"#$%&'()*+,.\/:;<=>?@[\\\]^`{|}~]/g, '\\$&');
};

export const replaceSpecialCharacters = (input: string) => {
  return input.replace(/[^a-zA-Z0-9]+/g, '-');
};

export const getUniqueFieldValues = (arrayOfObjects: any[], fieldName: string): any[] => {
  const uniqueValuesSet = new Set();

  for (const obj of arrayOfObjects) {
    if (fieldName in obj) {
      uniqueValuesSet.add(obj[fieldName]);
    }
  }

  return Array.from(uniqueValuesSet);
};

export const getUniqueMonths = (arrayOfObjects: any[], fieldName: string, format?: string): any[] => {
  const uniqueMonthsSet = new Set(arrayOfObjects.map((item) => moment(item[fieldName]).format(format || 'YYYY-MM')));
  const uniqueMonths = Array.from(uniqueMonthsSet).sort();
  return Array.from(uniqueMonths);
};

//Function to format the number or currency
export const numberFormatter = (value: number | string | null | undefined, isCurrencyFormat = false, locale = 'en-US'): string => {
  if (value === null || value === undefined) {
    logger.error('numberFormatter returning without formatting ', { value: value ? value : '-' });
    return value ? value : '-';
  }

  // Parse numeric strings to numbers if needed
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  if (isNaN(numericValue)) {
    // If parsing fails or the input is not a valid number, return the original value
    logger.error('parsing fails returning without formatting ', { value: value ? value : '-' });
    return `${value}`;
  }

  let intlNumberFormatUS = new Intl.NumberFormat(locale);
  if (isCurrencyFormat) {
    intlNumberFormatUS = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
  }

  const formattedValue = intlNumberFormatUS.format(numericValue);

  // Log the initial and formatted values (for debugging)
  // //console.log('Initial Value ', value, ' New Formatted Value ', formattedValue);

  return formattedValue;
};

//Function to retrieve the s3 client
export async function getS3Client() {
  try {
    const REGION = 'us-west-2';
    const s3ClientConfig: S3ClientConfig = {
      region: REGION,
      credentials: await getCredentialsFromAWSService()
    };
    const client = new S3Client(s3ClientConfig);
    return client;
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.S3APIError} `, error);
  }
}

//Function to transform aggregated metrics data to data series, inserting default values as specified with requiredColumnValues list
export interface rawToFormattedProps {
  rawX: string;
  x: string;
}
export interface transformAggMetricsToSeriesProps {
  aggData: any;
  titleColumn: string;
  xColumnProps: rawToFormattedProps;
  yColumn: any;
  type: string;
  color?: string;
  valueFormatter?: Function;
  requiredXColumnValues?: rawToFormattedProps[];
  defaultYValue?: any;
}
export const transformAggMetricsToSeries = (props: transformAggMetricsToSeriesProps) => {
  const dataSeries: any[] = [];
  const dataDictionary = new Map<string, any[]>();

  props.aggData.forEach((datum: any) => {
    let titleExistingData: any[] | undefined = dataDictionary.get(datum[props.titleColumn]);
    if (Array.isArray(titleExistingData)) {
      dataDictionary.set(datum[props.titleColumn], [
        ...titleExistingData,
        { x: datum[props.xColumnProps.x], y: datum[props.yColumn], rawX: datum[props.xColumnProps.rawX] }
      ]);
    } else {
      dataDictionary.set(datum[props.titleColumn], [
        { x: datum[props.xColumnProps.x], y: datum[props.yColumn], rawX: datum[props.xColumnProps.rawX] }
      ]);
    }
  });

  dataDictionary.forEach((data, title) => {
    if (props.requiredXColumnValues) {
      let existingColumnValues: any[] = data.map((value) => {
        return JSON.stringify({
          rawX: value.rawX,
          x: value.x
        });
      });
      let missingColumnValues = props.requiredXColumnValues?.filter((requiredValue) => !existingColumnValues.includes(JSON.stringify(requiredValue)));
      missingColumnValues?.forEach((missingValue) => {
        dataDictionary.get(title)?.push({ rawX: missingValue.rawX, y: props.defaultYValue, x: missingValue.x });
      });
    }
    data.sort((a: any, b: any) => b.rawX - a.rawX);
    dataSeries.push({
      title: title,
      type: props.type,
      data: data,
      color: props.color,
      valueFormatter: props.valueFormatter
    });
  });

  return dataSeries;
};

//Function to aggregated the metrics data based on columnList key column and measureColumnList measures
export const aggregateMetricsData = (columnList: string[], rowData: any, measureColumnList: MetricsMeasure[]) => {
  try {
    const measureColumns = measureColumnList.map((column) => column.name);
    const transformedAggregatedArray: Record<any, any> = {};
    const returnColumnList = new Set(columnList);
    rowData.forEach((item: any) => {
      let key = columnList.map((column) => item[column]).join('-');
      //setting initial value to 0 for measure columns
      const measureColumnInititalValues = measureColumns.reduce((a, v) => ({ ...a, [v]: 0 }), {});
      measureColumnList.forEach((measureColumn) => {
        if (!transformedAggregatedArray[key]) {
          transformedAggregatedArray[key] = { ...item, ...measureColumnInititalValues };
        }
        const measureColumnName = measureColumn['name'];
        const measureColumnOperation = measureColumn['operation'];
        if (measureColumnOperation === 'sum') {
          transformedAggregatedArray[key][measureColumnName] += parseInt(item[measureColumnName]);
          returnColumnList.add(measureColumnName);
        } else if (measureColumnOperation === 'floatSum') {
          transformedAggregatedArray[key][measureColumnName] += parseFloat(item[measureColumnName]);
          returnColumnList.add(measureColumnName);
        } else if (measureColumnOperation === 'count') {
          transformedAggregatedArray[key][measureColumnName]++;
          returnColumnList.add(measureColumnName);
        } else if (measureColumnOperation === 'minDate') {
          if (transformedAggregatedArray[key][measureColumnName] && item[measureColumnName]) {
            transformedAggregatedArray[key][measureColumnName] = moment
              .min(moment(transformedAggregatedArray[key][measureColumnName]), moment(item[measureColumnName]))
              .format('YYYY-MM-DD');
          } else {
            if (item[measureColumnName]) {
              transformedAggregatedArray[key][measureColumnName] = moment(item[measureColumnName]).format('YYYY-MM-DD');
            }
          }
          returnColumnList.add(measureColumnName);
        } else if (measureColumnOperation === 'maxDate') {
          if (transformedAggregatedArray[key][measureColumnName] && item[measureColumnName]) {
            transformedAggregatedArray[key][measureColumnName] = moment
              .max(moment(transformedAggregatedArray[key][measureColumnName]), moment(item[measureColumnName]))
              .format('YYYY-MM-DD');
          } else {
            if (item[measureColumnName]) {
              transformedAggregatedArray[key][measureColumnName] = moment(item[measureColumnName]).format('YYYY-MM-DD');
            }
          }
          returnColumnList.add(measureColumnName);
        } else if (measureColumnOperation === 'countDistinct') {
          const colName = measureColumnName + '_count_distinct_array';
          const distinctColName = measureColumnName + '_count_distinct';
          const countArray = [];
          if (transformedAggregatedArray[key][colName]) {
            const existingArray = transformedAggregatedArray[key][colName];
            if (!existingArray.includes(item[measureColumnName])) {
              existingArray.push(item[measureColumnName]);
            }

            transformedAggregatedArray[key][colName] = existingArray;
            transformedAggregatedArray[key][distinctColName] = existingArray.length;
          } else {
            countArray.push(item[measureColumnName]);
            transformedAggregatedArray[key][colName] = countArray;
            transformedAggregatedArray[key][distinctColName] = 1;
          }
          returnColumnList.add(distinctColName);
        }
      });
    });
    const aggregatedData = Object.values(transformedAggregatedArray);
    const transformedArray = getSpecificPropertyOfArray(aggregatedData, Array.from(returnColumnList));
    return transformedArray;
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.dataProcessingError} for function aggregateMetricsData`, error);
    return [];
  }
};

// Function to retrieve the data from s3 object and parse it into json format
export const fetchS3Data = async (client: S3Client, command: GetObjectCommand) => {
  try {
    const response = await client.send(command);
    const start_api_call = getCurrentTime();
    const payload = (await response?.Body?.transformToString('utf-8')) || '';

    const elapsed_api = getTimeDifference(start_api_call);
    logger.info(`Fetched ${command.input.Key} Data in ${elapsed_api} ms`);
    const data: any[] = [];
    payload.split('\n').forEach((item) => {
      data.push(JSON.parse(item));
    });
    return data;
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.S3APIError} `, error);
    return [];
  }
};

// Function to make S3 API calls in parallel and aggregated the data from all s3 objects
export const s3ParallelAPICalls = async (client: S3Client, commandList: GetObjectCommand[]) => {
  try {
    const promises = commandList.map((command) => fetchS3Data(client, command));
    const responses = await Promise.all(promises);
    const allPayload: UserMetricsRawData[] | any[] = [];
    responses.forEach((response) => {
      allPayload.push(...response);
    });
    return { payload: allPayload };
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.S3APIError} `, error);
    return { payload: [] };
  }
};

//Function to list all the s3 blobs object
export const s3ListBlobs = async (metricsPrefix: string) => {
  try {
    const client = await getS3Client();
    if (client) {
      const command = new ListObjectsV2Command({
        Bucket: S3_ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Bucket,
        Prefix: `${S3_ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Prefix}${metricsPrefix}/`
      });
      let isTruncated: any = true;

      let commandList: GetObjectCommand[] = [];
      while (isTruncated) {
        const { Contents, IsTruncated, NextContinuationToken } = await client.send(command);
        const s3KeyList = Contents?.filter((c) => c.Key?.includes('run-')).map((item) => item.Key);
        isTruncated = IsTruncated;

        s3KeyList?.forEach((s3Key) => {
          let command = new GetObjectCommand({
            Bucket: S3_ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Bucket,
            Key: s3Key,
            ResponseCacheControl: 'no-cache'
          });
          commandList.push(command);
        });

        command.input.ContinuationToken = NextContinuationToken;
      }
      //console.log(commandList);
      return { client: client, commandList: commandList };
    }
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.S3APIError} for ${metricsPrefix}`, error);
  }
};

//Function to fetch the s3 data for a given metrics prefix
export const getS3Data = async (metricsPrefix: string) => {
  try {
    const s3Objects = await s3ListBlobs(metricsPrefix);

    if (s3Objects) {
      const start_api_call = getCurrentTime();
      const responses = await s3ParallelAPICalls(s3Objects.client, s3Objects.commandList);
      //console.log(responses.payload);
      const payload = responses.payload;
      const elapsed_api = getTimeDifference(start_api_call);
      logger.info(`Fetched all s3 Data for ${metricsPrefix} in ${elapsed_api} ms`);

      return payload;
    }
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.S3APIError} for ${metricsPrefix}`, error);
  }
};

//Function to retrieve the specific properties(columns) from array of objects
export const getSpecificPropertyOfArray = (rowData: string[], columnList: string[]): any => {
  try {
    const transformedArray = rowData.map((row) =>
      columnList.reduce((selectedArray: any, column: any) => {
        selectedArray[column] = row[column];
        return selectedArray;
      }, {})
    );
    return transformedArray;
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.dataProcessingError} for function getSpecificPropertyOfArray`, error);
  }
};

//Function to sort array based on number field value
export const getSortedArrayWithNumber = (array: any, sortingColumn: string, sortedOrder = 'asc') => {
  if (sortedOrder === 'asc') {
    return array?.sort((a: any, b: any) => a.sortingColumn - b.sortingColumn);
  } else {
    return array?.sort((a: any, b: any) => b[sortingColumn] - a[sortingColumn]);
  }
};

//Function to format number and date for graph pop over
export const GraphPopOverNumberFormatter = (value: number | string | null | undefined | Date): string => {
  if (value === null || value === undefined) {
    logger.error(`GraphPopOverNumberFormatter: value is either null or undefined: `, { value: value ? value : '-' });
    return value ? value : '-';
  }

  // Parse numeric strings to numbers if needed
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  if (!(numericValue instanceof Date) && isNaN(numericValue)) {
    // If parsing fails or the input is not a valid number or date, return the original value
    logger.error('parsing fails returning without formatting since value is neither number or date', { value: value ? value : '-' });
    return `${value}`;
  }
  let formattedValue: any = '';
  const intlNumberFormatUS = new Intl.NumberFormat('en-US');
  if (!(numericValue instanceof Date) && !isNaN(numericValue)) {
    formattedValue = intlNumberFormatUS.format(numericValue);
  }

  if (numericValue instanceof Date) {
    formattedValue = moment(numericValue, FilterDateFormat_MMM_YYYY).toString();
  }

  return formattedValue;
};

//Function to retrieve s3 payload and lastUpdatedAtPayload
export const getS3PayloadAndLastUpdatedAtData = async (s3Prefix: string, lastUpdatedAtS3Prefix: string, metricsName: string) => {
  try {
    let start = getCurrentTime();
    const s3Payload: any = await getS3Data(s3Prefix);
    let elapsed = getTimeDifference(start);

    logger.info(`Fetched ${metricsName} Data in ${elapsed} ms`);
    recordApiRequest(`getS3Data(${s3Prefix})`, elapsed);
    start = getCurrentTime();
    //Fetching last updated at data from s3
    const lastUpdatedAtPayload: any = await getS3Data(lastUpdatedAtS3Prefix);
    elapsed = getTimeDifference(start);

    logger.info(`Fetched ${metricsName} Last Updated At Data in ${elapsed} ms`);
    recordApiRequest(`getS3Data(${lastUpdatedAtS3Prefix})`, elapsed);
    return [s3Payload, lastUpdatedAtPayload[0]];
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.S3APIError} for ${metricsName}`, error);
  }
};

//Function to format numerical abbreviation
export const numericalAbbreviationsFormatter = (value: number): string => {
  const scales = [
    { limit: 1e9, divisor: 1e9, suffix: 'B' },
    { limit: 1e6, divisor: 1e6, suffix: 'M' },
    { limit: 1e3, divisor: 1e3, suffix: 'K' }
  ];

  for (const scale of scales) {
    if (Math.abs(value) >= scale.limit) {
      return (value / scale.divisor).toFixed(1).replace(/\.0$/, '') + scale.suffix;
    }
  }
  return value.toFixed();
};
