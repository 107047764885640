import {
  Box,
  Button,
  Checkbox,
  Container,
  Flashbar,
  FormField,
  Header,
  Input,
  RadioGroup,
  SpaceBetween,
  StatusIndicator,
  Table,
  Tabs
} from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { ApplicationWizard, TutorialContentDetailsEntity, WelcomeContentDetailsEntity } from '../../AdminModels';
import { APP_TUTORIAL_COLUMN_DEFINITIONS, APP_WELCOME_COLUMN_DEFINITIONS, removeQuotesFromString } from './StepsUtilities';
import { MAX_TUTORIAL_LINKS, WELCOME_CONTENT_LINKS_MAX_LENGTH, WELCOME_TITLE_MAX_LENGTH } from './StepsValidations';
import TutorialContentForm, { TutorialContentFormMethods } from './TutorialContentForm';
import WelcomeLinksContentForm, { WelcomeLinksContentFormMethods } from './WelcomeLinksContentForm';
// import WelcomeWidget from 'src/components/widgets/welcome-widget';

interface HomepageConfigurationProps {
  setHelpPanelContent: any;
  applicationFormRef: React.RefObject<FormikProps<ApplicationWizard>>;
  formik: FormikProps<ApplicationWizard>;
}

const HomepageConfiguration = ({ setHelpPanelContent, applicationFormRef, formik }: HomepageConfigurationProps) => {
  useEffect(() => {
    setHelpPanelContent();
  }, []);
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const welcomeContentFormRef = useRef<WelcomeLinksContentFormMethods>(null);
  const [welcomeTitleDecision, setWelcomeTitleDecision] = useState('simpleTitle');
  const [welcomeContentDetails, setWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>(
    formik.values?.homePageConfigurations?.welcomeContentDetails || []
  );
  const [selectedWelcomeContentDetails, setSelectedWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>([]);

  useEffect(() => {
    if (formik?.values?.homePageConfigurations?.welcomeTitle !== 'Welcome') {
      setWelcomeTitleDecision('customTitle');
    }
  }, [formik?.values?.homePageConfigurations?.welcomeTitle]);

  const addNewWelcomeContent = (nonProductionEnvironmentsEntity: WelcomeContentDetailsEntity) => {
    if (welcomeContentDetails.length < WELCOME_CONTENT_LINKS_MAX_LENGTH) {
      const newRow: WelcomeContentDetailsEntity[] = [nonProductionEnvironmentsEntity];
      const finalRows = welcomeContentDetails.concat(newRow);
      setWelcomeContentDetails(finalRows);
      formik.setFieldValue('homePageConfigurations.welcomeContentDetails', finalRows);
    }
  };

  const removeSelectedWelcomeContent = () => {
    const finalRow = welcomeContentDetails.filter((obj) => !selectedWelcomeContentDetails.includes(obj));
    setWelcomeContentDetails(finalRow);
    formik.setFieldValue('homePageConfigurations.welcomeContentDetails', finalRow);
    setSelectedWelcomeContentDetails([]);
  };

  const updatedWelcomeContent = (welcomeContent: WelcomeContentDetailsEntity) => {
    const updatedRow = welcomeContentDetails.map((obj) => {
      if (obj.id === welcomeContent.id) {
        return welcomeContent;
      }
      return obj;
    });
    setWelcomeContentDetails(updatedRow);
    formik.setFieldValue('homePageConfigurations.welcomeContentDetails', updatedRow);
  };

  const clearNewWelcomeDetails = () => {
    setSelectedWelcomeContentDetails([]);
  };

  const editWelcomeContent = () => {
    if (welcomeContentFormRef.current) {
      welcomeContentFormRef.current.editSelectedRow(selectedWelcomeContentDetails[0]);
      setSelectedWelcomeContentDetails([]);
    }
  };

  // Announcements
  const [announcementItems, setAnnouncementItems] = React.useState<any>([
    {
      type: 'info',
      dismissible: false,
      dismissLabel: 'Dismiss message',
      content: <>{`Announcements widget has been enabled`}</>,
      id: 'message_1'
    }
  ]);

  // Tutorials
  const tutorialContentFormRef = useRef<TutorialContentFormMethods>(null);
  const [tutorialContentActiveTabId, setTutorialContentActiveTabId] = useState('first');

  const [tutorialContentDetails, setTutorialContentDetails] = useState<TutorialContentDetailsEntity[]>(
    formik.values?.homePageConfigurations?.tutorialContentDetails ? formik.values?.homePageConfigurations?.tutorialContentDetails : []
  );
  const [selectedTutorialContentDetails, setSelectedTutorialContentDetails] = useState<any[]>([]);

  const addNewTutorialContent = (addNewTutorialContent: TutorialContentDetailsEntity) => {
    if (tutorialContentDetails.length < MAX_TUTORIAL_LINKS) {
      const newRow: TutorialContentDetailsEntity[] = [addNewTutorialContent];
      const finalRows = tutorialContentDetails.concat(newRow);
      setTutorialContentDetails(finalRows);
      formik.setFieldValue('homePageConfigurations.tutorialContentDetails', finalRows);
    }
  };

  const removeSelectedTutorialContent = () => {
    const finalRow = tutorialContentDetails.filter((obj) => !selectedTutorialContentDetails.includes(obj));
    setTutorialContentDetails(finalRow);
    formik.setFieldValue('homePageConfigurations.tutorialContentDetails', finalRow);
    setSelectedTutorialContentDetails([]);
  };

  const clearNewTutorialDetails = () => {
    setSelectedTutorialContentDetails([]);
  };

  const editTutorialContent = () => {
    if (tutorialContentFormRef.current) {
      tutorialContentFormRef.current.editTutorialContentChanged(selectedTutorialContentDetails[0]);
      setSelectedTutorialContentDetails([]);
    }
  };

  const updateTutorialContent = (updatedTutorialContent: TutorialContentDetailsEntity) => {
    const finalRow = tutorialContentDetails.map((obj) => (obj.id === updatedTutorialContent.id ? updatedTutorialContent : obj));
    setTutorialContentDetails(finalRow);
    formik.setFieldValue('homePageConfigurations.tutorialContentDetails', finalRow);
  };

  // Feedback
  const [feedbackItems, setFeedbackItems] = React.useState<any>([
    {
      type: 'info',
      dismissible: false,
      dismissLabel: 'Dismiss message',
      content: <>{`Feedback widget has been enabled`}</>,
      id: 'message_1'
    }
  ]);

  const onChangeOfWelcomeTitleDecision = (value: string) => {
    setWelcomeTitleDecision(value);
    formik.setFieldValue(
      'homePageConfigurations.welcomeTitle',
      value === 'simpleTitle' ? 'Welcome' : formik.values.homePageConfigurations?.welcomeTitle,
      true
    );
  };

  const onConfirm = (showModalInfo: ShowModalInfo) => {
    switch (showModalInfo.eventContentType) {
      case 'DELETE_WELCOME_LINKS':
        removeSelectedWelcomeContent();
        closeModal();
        break;
      case 'DELETE_TUTORIAL_LINK':
        removeSelectedTutorialContent();
        closeModal();
        break;
      default:
        break;
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />

      {/* Welcome widget  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description="Introduce users to this app by providing useful quick links (e.g., documentation, wikis)"
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasWelcomeContent', detail.checked);
                      clearNewWelcomeDetails();
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasWelcomeContent', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasWelcomeContent || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Welcome
            </Header>
          }
        >
          {(formik.values?.homePageConfigurations?.hasWelcomeContent || false) && (
            <SpaceBetween size="m" direction="vertical">
              {/* Welcome title selection  */}
              <RadioGroup
                onChange={({ detail }) => {
                  onChangeOfWelcomeTitleDecision(detail.value);
                }}
                value={welcomeTitleDecision}
                items={[
                  {
                    value: 'simpleTitle',
                    label: 'Simple',
                    disabled: !formik.values.homePageConfigurations.hasWelcomeContent,
                    description: '"Welcome"'
                  },
                  {
                    value: 'customTitle',
                    label: 'Custom',
                    disabled: !formik.values.homePageConfigurations.hasWelcomeContent,
                    description: <></>
                  }
                ]}
              />

              <FormField
                constraintText={characterCountConstraintMessage(
                  WELCOME_TITLE_MAX_LENGTH,
                  formik.values.homePageConfigurations?.welcomeTitle?.length || 0
                )}
                errorText={formik.touched.homePageConfigurations?.welcomeTitle && formik.errors.homePageConfigurations?.welcomeTitle}
                stretch={true}
              >
                <Input
                  placeholder="Enter custom title"
                  disabled={welcomeTitleDecision === 'simpleTitle'}
                  onChange={({ detail }) => {
                    formik.setFieldValue('homePageConfigurations.welcomeTitle', detail.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('homePageConfigurations.welcomeTitle', true);
                  }}
                  value={formik.values?.homePageConfigurations?.welcomeTitle || ''}
                />
              </FormField>

              {/* Welcome content  */}
              <>
                <SpaceBetween size="s" direction="vertical">
                  <Header
                    variant="h3"
                    description={`A maximum of ${WELCOME_CONTENT_LINKS_MAX_LENGTH} links and their descriptions or # total characters may be displayed.`}
                  >
                    {`Welcome links`}
                  </Header>

                  <WelcomeLinksContentForm
                    ref={welcomeContentFormRef}
                    hasWelcomeContent={formik.values.homePageConfigurations.hasWelcomeContent || false}
                    addNewWelcomeContent={addNewWelcomeContent}
                    updateRow={updatedWelcomeContent}
                    welcomeContents={formik.values.homePageConfigurations.welcomeContentDetails || []}
                  />
                  <Table
                    variant="embedded"
                    footer={
                      <Box textAlign="left">{`You can add up to ${WELCOME_CONTENT_LINKS_MAX_LENGTH - welcomeContentDetails.length} more links.`}</Box>
                    }
                    header={
                      <Header
                        description={
                          formik.touched.homePageConfigurations?.welcomeContentDetails &&
                          formik.errors.homePageConfigurations?.welcomeContentDetails && (
                            <StatusIndicator type="error">
                              {formik.touched.homePageConfigurations?.welcomeContentDetails &&
                                removeQuotesFromString(JSON.stringify(formik.errors.homePageConfigurations?.welcomeContentDetails))}
                            </StatusIndicator>
                          )
                        }
                        actions={
                          <>
                            <SpaceBetween size="m" direction="horizontal">
                              <Button
                                formAction="none"
                                disabled={selectedWelcomeContentDetails?.length !== 1}
                                onClick={() => {
                                  editWelcomeContent();
                                }}
                              >
                                {`Edit`}
                              </Button>
                              <Button
                                formAction="none"
                                disabled={selectedWelcomeContentDetails?.length === 0}
                                onClick={() => {
                                  setShowModalInfo({
                                    showModal: true,
                                    eventHeader: 'Delete welcome links',
                                    eventContentType: 'DELETE_WELCOME_LINKS'
                                  });
                                }}
                              >
                                {`Delete (${selectedWelcomeContentDetails?.length}) selected`}
                              </Button>
                            </SpaceBetween>
                          </>
                        }
                      ></Header>
                    }
                    empty={
                      <Box textAlign="center" color="inherit">
                        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                          No data to display.
                        </Box>
                      </Box>
                    }
                    columnDefinitions={APP_WELCOME_COLUMN_DEFINITIONS}
                    items={welcomeContentDetails}
                    selectionType="multi"
                    selectedItems={selectedWelcomeContentDetails}
                    onSelectionChange={({ detail }) => setSelectedWelcomeContentDetails(detail.selectedItems)}
                  />
                </SpaceBetween>
              </>
            </SpaceBetween>
          )}
        </Container>
      </Box>

      {/* Announcements  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description="Display to users all announcements that apply to this app"
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasAnnouncements', detail.checked);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasAnnouncements', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasAnnouncements || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Announcements
            </Header>
          }
        >
          {formik.values?.homePageConfigurations?.hasAnnouncements && <Flashbar items={announcementItems} />}
        </Container>
      </Box>

      {/* Tutorials  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              description={`Show tutorials or training videos for this app (8 links are showcased on the widget itself and the app’s tutorial page will display all links)`}
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasTutorials', detail.checked);
                      clearNewTutorialDetails();
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasTutorials', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasTutorials || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Tutorials
            </Header>
          }
        >
          {formik.values?.homePageConfigurations?.hasTutorials && (
            <>
              {/* Tutorials content  */}
              <Tabs
                onChange={({ detail }) => setTutorialContentActiveTabId(detail.activeTabId)}
                activeTabId={tutorialContentActiveTabId}
                tabs={[
                  {
                    label: 'Content',
                    id: 'first',
                    content: (
                      <>
                        <SpaceBetween size="m" direction="vertical">
                          <TutorialContentForm
                            ref={tutorialContentFormRef}
                            hasTutorialContent={formik.values.homePageConfigurations.hasTutorials || false}
                            addNewTutorialContent={addNewTutorialContent}
                            updateTutorialContent={updateTutorialContent}
                            tutorialContentDetails={formik.values.homePageConfigurations.tutorialContentDetails || []}
                          />

                          <Table
                            variant="embedded"
                            header={
                              <Header
                                description={
                                  formik.touched.homePageConfigurations?.tutorialContentDetails &&
                                  formik.errors.homePageConfigurations?.tutorialContentDetails && (
                                    <StatusIndicator type="error">
                                      {formik.touched.homePageConfigurations?.tutorialContentDetails &&
                                        removeQuotesFromString(JSON.stringify(formik.errors.homePageConfigurations?.tutorialContentDetails))}
                                    </StatusIndicator>
                                  )
                                }
                                actions={
                                  <>
                                    <SpaceBetween size="m" direction="horizontal">
                                      <Button
                                        formAction="none"
                                        disabled={selectedTutorialContentDetails?.length !== 1}
                                        onClick={() => {
                                          editTutorialContent();
                                        }}
                                      >
                                        {`Edit`}
                                      </Button>
                                      <Button
                                        formAction="none"
                                        disabled={selectedTutorialContentDetails?.length === 0}
                                        onClick={() => {
                                          setShowModalInfo({
                                            showModal: true,
                                            eventHeader: 'Delete tutorial links',
                                            eventContentType: 'DELETE_TUTORIAL_LINK'
                                          });
                                        }}
                                      >
                                        {`Delete (${selectedTutorialContentDetails?.length}) selected`}
                                      </Button>
                                    </SpaceBetween>
                                  </>
                                }
                              ></Header>
                            }
                            footer={
                              <Box textAlign="left">{`You can add up to ${MAX_TUTORIAL_LINKS - tutorialContentDetails.length} more links.`}</Box>
                            }
                            empty={
                              <Box textAlign="center" color="inherit">
                                <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                                  No data to display.
                                </Box>
                              </Box>
                            }
                            columnDefinitions={APP_TUTORIAL_COLUMN_DEFINITIONS}
                            items={tutorialContentDetails}
                            selectionType="multi"
                            selectedItems={selectedTutorialContentDetails}
                            onSelectionChange={({ detail }) => setSelectedTutorialContentDetails(detail.selectedItems)}
                          />
                        </SpaceBetween>
                      </>
                    )
                  },
                  {
                    label: 'Preview',
                    id: 'second',
                    content: (
                      <>
                        <div>Second tab content area</div>
                      </>
                    )
                  }
                ]}
              />
            </>
          )}
        </Container>
      </Box>

      {/* Feedback  */}
      <Box margin={{ bottom: 'l' }}>
        <Container
          header={
            <Header
              variant="h2"
              className="limited-width-header"
              description="Collects experience ratings and feedback for this app"
              actions={
                <>
                  <Checkbox
                    onChange={({ detail }) => {
                      formik.setFieldValue('homePageConfigurations.hasFeedback', detail.checked);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('homePageConfigurations.hasFeedback', true);
                    }}
                    checked={formik.values?.homePageConfigurations?.hasFeedback || false}
                  >
                    Enable
                  </Checkbox>
                </>
              }
            >
              Feedback
            </Header>
          }
        >
          {formik.values?.homePageConfigurations?.hasFeedback && <Flashbar items={feedbackItems} />}
        </Container>
      </Box>
    </>
  );
};

export default HomepageConfiguration;
