import { VALIDATION_FAILED, VALIDATION_SUCCESS, ValidationEntity, compareTwoArrayOfObjects, validateCols } from 'src/utilities/CommonHelpers';
import { logger } from 'src/logger';
import { DuplicateValuesValidationMessages, HeaderValidationMessages, RequiredFieldsValidationMessages, isActiveValues } from './MappingConstants';

// Excel file Import Validations

//validation of file header with the required header of the row data
export const validateHeaderData = (fileHeader: string[], mappingColumnList: string[]): Promise<ValidationEntity> => {
  logger.info('Mapping excel import fileHeader ', { info: fileHeader });
  logger.info('Mapping excel import fileHeaderToBe ', { info: mappingColumnList });

  return new Promise((resolve, reject) => {
    try {
      if (compareTwoArrayOfObjects(fileHeader, mappingColumnList).length === 0) {
        return resolve({
          ...VALIDATION_SUCCESS,
          validationMessage: HeaderValidationMessages.ValidHeader
        });
      } else {
        return resolve({
          ...VALIDATION_FAILED,
          validationMessage: HeaderValidationMessages.InvalidHeader
        });
      }
    } catch (error) {
      return reject({
        ...VALIDATION_FAILED,
        validationMessage: HeaderValidationMessages.RejectHeader
      });
    }
  });
};

//validation of required fields with the fields present in file
export const validateRequiredFields = (uploadFileHeader: string[], keyColumn: string[]): Promise<ValidationEntity> => {
  return new Promise((resolve, reject) => {
    const allValuesPresent = validateCols(uploadFileHeader, keyColumn);

    return resolve(
      allValuesPresent
        ? {
            ...VALIDATION_SUCCESS,
            validationMessage: RequiredFieldsValidationMessages.ValuesFound
          }
        : {
            ...VALIDATION_FAILED,
            validationMessage: RequiredFieldsValidationMessages.ValuesNotFound
          }
    );
  });
};

//Duplicate data validation for the key columns
export const validateDuplicate = (uploadFileData: any[], keyColumn: string[]): Promise<ValidationEntity> => {
  return new Promise((resolve, reject) => {
    try {
      const keyArr: Object[] = getKeyColumnDataArray(uploadFileData, keyColumn);

      // check if an array of objects have duplicates in it.
      return !hasDuplicates(keyArr)
        ? resolve({
            ...VALIDATION_SUCCESS,
            validationMessage: DuplicateValuesValidationMessages.NoDuplicates
          })
        : resolve({
            ...VALIDATION_FAILED,
            validationMessage: DuplicateValuesValidationMessages.DuplicatesFound
          });
    } catch (error: any) {
      logger.error(DuplicateValuesValidationMessages.APIError, error);
      resolve({
        ...VALIDATION_FAILED,
        validationMessage: DuplicateValuesValidationMessages.APIError
      });
    }
  });
};

//Function to check for duplicate values for the key columns
const hasDuplicates = (arr: any[]) => {
  const valuesSoFar = new Set();
  for (let row of arr) {
    let newRowValue: any = {};
    newRowValue = row;
    row = Object.keys(row).map((item) => {
      if (row[item] === '') {
        newRowValue[item] = null;
      }
      if (item === 'is_active') {
        if (typeof row[item] === 'string' && !isActiveValues.includes(row[item])) {
          newRowValue[item] = stringToBoolean(row[item]);
        }
      }
      //added logic to convert key column values to lower case and removing white spaces for case insensitive comparison
      if (item !== 'is_active') {
        if (typeof row[item] === 'string') {
          newRowValue[item] = row[item].toLowerCase().trim();
        }
      }
    });

    const stringified = JSON.stringify(newRowValue);
    if (valuesSoFar.has(stringified)) {
      return true;
    }
    valuesSoFar.add(stringified);
  }

  return false;
};

//null check for key column
export const hasNull = (arr: any[], keyColumn: string[]) => {
  const keyArr: Object[] = getKeyColumnDataArray(arr, keyColumn);
  for (const row of keyArr) {
    for (const [key, value] of Object.entries(row)) {
      if (value === null || value === undefined || value === '') {
        return true;
      }
    }
  }
  return false;
};

//return the array with key columns
export const getKeyColumnDataArray = (arr: any[], keyColumn: string[]) => {
  const keyArr: Object[] = [];
  arr?.map((row) => {
    let val: any = {};
    for (let i = 0; i < keyColumn.length; i++) {
      val[keyColumn[i]] = row[keyColumn[i]];
    }
    keyArr.push(val);
  });

  return keyArr;
};

export const isString = (value: any): value is string => typeof value === 'string';

export const trimEmptyString = <T>(value: T): T | null => (isString(value) && value.trim() === '' ? null : value);

//Function to convert string to boolean
export const stringToBoolean = (stringValue: any): Boolean => {
  if (typeof stringValue === 'string') {
    switch (stringValue.toLowerCase()?.trim()) {
      case 'true':
      case 'yes':
      case '1':
      case 'y':
        return true;

      case 'false':
      case 'no':
      case '0':
      case null:
      case undefined:
      case 'n':
        return false;

      default:
        return false;
    }
  } else {
    return false;
  }
};

//This function will update the isEdited, newRow and updated_by cols for import data which has changed or newly created
export const updateImportRowData = (
  initialDataArray: any[],
  updatedDataArray: string[],
  userAlias: string,
  initialData: string[],
  keyColumn: string[]
) => {
  let stringifiedUpdatedArr = updatedDataArray.map((row) => JSON.stringify(sortedObj(row)));

  let finalArr: any[] = [];
  let keyCol = '';
  let keyColumnCol = JSON.parse(JSON.stringify(keyColumn));
  const keyColumnData = initialData.map((item: any) => {
    const index = keyColumnCol.indexOf('is_active');
    if (index > -1) {
      keyColumnCol.splice(index, 1);
    }
    keyCol = keyColumnCol.toString();
    return item[keyCol];
  });

  initialDataArray.forEach((row: any) => {
    //created new copy to avoid actual row data getting removed these columns
    let newRowValue: any = JSON.parse(JSON.stringify(row));
    const { isEdited, newRow, created_at, created_by, last_updated_at, updated_at, updated_by, last_updated_by, ...remaining } = newRowValue;
    if (stringifiedUpdatedArr.includes(JSON.stringify(sortedObj(remaining)))) {
      if (!keyColumnData.includes(row[keyCol])) {
        row['newRow'] = true;
        finalArr.push(row);
      } else {
        row['isEdited'] = true;
        row['updated_by'] = userAlias;
        finalArr.push(row);
      }
    } else {
      finalArr.push(row);
    }
  });
  return finalArr;
};

//Function to sort the character keys of an object
export const sortedObj = (object: Object) => Object.fromEntries(Object.entries(object).sort());
