import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { DEFAULT_LOCALE_EN_US, TRANSLATION_ROUTE } from 'src/constants/AppConstants';
import arbManifest from '../i18n/translations/arbManifest';
import { AppRouter } from './AppRouter';
import { AppContextProvider } from './context/AppContextProvider';
import { AuthContextProvider } from './context/AuthContextProvider';
import { FintechOpsContextProvider } from './context/FintechContextProvider';
import { ErrorFallback } from './generic-components/ErrorFallback';

const localizationContext = new LocalizationContextBuilder().withLocale(DEFAULT_LOCALE_EN_US).withDefaultLocale(DEFAULT_LOCALE_EN_US).build();

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: localizationContext,
  urlPrefix: TRANSLATION_ROUTE
};

export const App: React.FC = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <MbmProvider {...mbmOptions}>
        <AuthContextProvider>
          <AppContextProvider>
            <FintechOpsContextProvider>
              <AppRouter />
            </FintechOpsContextProvider>
          </AppContextProvider>
        </AuthContextProvider>
      </MbmProvider>
    </ErrorBoundary>
  );
};
