import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Application } from 'src/components/context/AppContextModels';

interface AppSpecificSideNavigationProps {
  appSpecific: Application | undefined;
}

const AppSpecificSideNavigationItems: SideNavigationProps.Item[] = [
  {
    type: 'link',
    text: 'Announcements',
    href: '/announcements'
  },
  {
    type: 'link',
    text: 'Tutorials',
    href: '/tutorials'
  }
];

export const AppSpecificSideNavigation: React.FC<AppSpecificSideNavigationProps> = ({ appSpecific }: AppSpecificSideNavigationProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [sideNavigationItems, setSideNavigationItems] = useState<SideNavigationProps.Item[]>(AppSpecificSideNavigationItems);
  const [activeRef, setActiveRef] = useState<string>('');

  useEffect(() => {
    setActiveRef(pathname);
  }, [pathname]);

  return (
    <SideNavigation
      header={{
        href: '/',
        text: appSpecific?.applicationName || ''
      }}
      items={sideNavigationItems}
      activeHref={activeRef}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href, {
            replace: true,
            state: {
              from: '/all-apps/' + appSpecific?.id,
              redirectFromHomePage: false,
              fromAppName: appSpecific?.applicationName,
              fromAppId: appSpecific?.id
            }
          });
        }
      }}
    />
  );
};
