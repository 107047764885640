import React, { useEffect, useState } from 'react';
import { useFeedbacks } from './Feedbacks';
import { useNavigate } from 'react-router-dom';
import { Button, Header, Pagination, PropertyFilter, SpaceBetween, Table, TableProps } from '@amzn/awsui-components-react';
import { Feedback, FeedbackFlatTable } from './FeedbacksModel';
import {
  BLANK_SEARCH_AND,
  Preferences,
  TableEmptyState,
  TableNoMatchState,
  getMatchesCountText
} from 'src/components/generic-components/TableCommons';
import {
  DEFAULT_FEEDBACK_VISIBLE_CONTENT_OPTIONS,
  FEEDBACK_COLUMN_DEFINITIONS,
  FEEDBACK_DEFAULT_PREFERENCES,
  FEEDBACK_FILTERING_PROPERTIES
} from './FeedbackConfig';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { paginationAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import { API } from 'aws-amplify';
import * as mutations from 'src/graphql/mutations';
import { useAuth } from 'src/components/context/AuthContextProvider';
import { dateTimeComparatorForTable, getCurrentUTCTimeZoneInISO, getReadableDateFromDateTime } from 'src/utilities/DateTimeUtilities';
import { logger } from 'src/logger';
import { ConfirmationModal, ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { eFinSuiteFeedbackMessages, eUserPreferenceKeys } from 'src/constants/AppConstants';
import { usePersistedState } from 'src/utilities/useLocalStorage';
import { useColumnWidths } from 'src/utilities/useColumnWidths';

const FeedbackTable: React.FC = () => {
  const navigate = useNavigate();
  const userAuthDetails = useAuth();

  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const { feedbacks, setFeedbacks, feedbacksStatus, setFeedbacksStatus, refreshFeedbacks, displayFlashNotification } = useFeedbacks();

  // Table Properties
  const [feedbackFlatTable, setFeedbackFlatTable] = useState<FeedbackFlatTable[]>([]);
  const [feedbackColumnDefinitions, saveWidths] = useColumnWidths(eUserPreferenceKeys.ADMIN_FEEDBACK_TABLE_WIDTHS, FEEDBACK_COLUMN_DEFINITIONS);

  const [selectedFeedbacks, setSelectedFeedbacks] = useState<FeedbackFlatTable[]>([]);
  const [filteringProperties, setFilteringProperties] = useState<any>(FEEDBACK_FILTERING_PROPERTIES);
  const [preferences, setPreferences] = usePersistedState(eUserPreferenceKeys.ADMIN_FEEDBACK_TABLE_PREFERENCES, FEEDBACK_DEFAULT_PREFERENCES);
  const [query, setQuery] = useState(BLANK_SEARCH_AND);

  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  useEffect(() => {
    const _flatTable = feedbacks.map((feedback) => {
      return {
        id: feedback.id,
        applicationName: feedback?.applicationName?.label || '',
        rating: feedback.rating,
        feedback: feedback.feedback,
        feedbackType: feedback.feedbackType,
        createdBy: feedback.itemMetadata.createdBy,
        createdTime: getReadableDateFromDateTime(feedback.itemMetadata.createdTime),
        isActive: feedback.itemMetadata.isActive,
        updatedBy: feedback.itemMetadata.updatedBy,
        updatedTime: feedback.itemMetadata.updatedTime
      };
    });
    setFeedbackFlatTable(_flatTable);
  }, [feedbacks]);

  const removeSelectedFeedback = async () => {
    const feedbacksToUpdate: Feedback[] = feedbacks
      .filter((item1) => selectedFeedbacks.some((item2) => item2.id === item1.id))
      .map((selectedFeedback) => {
        return {
          ...selectedFeedback,
          itemMetadata: {
            ...selectedFeedback.itemMetadata,
            isActive: false,
            updatedBy: userAuthDetails.Alias,
            updatedTime: getCurrentUTCTimeZoneInISO()
          }
        };
      });
    setFeedbacksStatus(true);
    const feedbacksUpdated = await updateAllFeedbacks(feedbacksToUpdate);

    if (feedbacksUpdated.includes(null)) {
      displayFlashNotification(eFinSuiteFeedbackMessages.FEEDBACK_DELETION_FAILED, 'error');
    } else {
      displayFlashNotification(eFinSuiteFeedbackMessages.FEEDBACK_SUCCESSFULLY_DELETED, 'success');
      setSelectedFeedbacks([]);
      refreshFeedbacks();
    }
    closeModal();
    setFeedbacksStatus(false);
  };

  const updateAllFeedbacks = async (feedbacksToUpdate: Feedback[]): Promise<(Feedback | null)[]> => {
    const promises = feedbacksToUpdate.map((item) => updateFeedback(item));
    const results = await Promise.all(promises);
    return results;
  };

  const updateFeedback = async (feedback: Feedback): Promise<Feedback | null> => {
    try {
      const updateResponse: any = await API.graphql({
        query: mutations.updateFeedback,
        variables: { input: feedback }
      });
      return updateResponse?.data?.updateFeedback as Feedback;
    } catch (error: any) {
      logger.error(`Unable to update feedbacks `, error);
      return null;
    }
  };

  const deleteSelectedFeedback = () => {
    setShowModalInfo({
      showModal: true,
      eventHeader: 'Delete feedback(s)',
      eventContentType: 'DELETE_FEEDBACK'
    });
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  const onConfirm = async (showModalInfo: ShowModalInfo) => {
    if (showModalInfo.eventContentType === 'DELETE_FEEDBACK') {
      removeSelectedFeedback();
    }
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(feedbackFlatTable, {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName="Feedbacks" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        />
      )
    },
    pagination: {
      pageSize: preferences.pageSize
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'updatedTime',
          sortingComparator: (a: FeedbackFlatTable, b: FeedbackFlatTable) => {
            return dateTimeComparatorForTable(a.updatedTime, b.updatedTime);
          }
        },
        isDescending: true
      }
    }
  });

  return (
    <>
      <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
      <Table
        variant="full-page"
        stickyHeader={true}
        loading={feedbacksStatus}
        loadingText={'Loading feedbacks...'}
        items={items}
        selectionType={'multi'}
        selectedItems={selectedFeedbacks}
        onSelectionChange={({ detail }) => setSelectedFeedbacks(detail.selectedItems)}
        columnDefinitions={feedbackColumnDefinitions as TableProps.ColumnDefinition<FeedbackFlatTable>[]}
        onColumnWidthsChange={saveWidths as any}
        visibleColumns={preferences.visibleContent}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        header={
          <Header
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button variant="normal" disabled={selectedFeedbacks.length === 0} onClick={deleteSelectedFeedback} iconName="remove">
                  {selectedFeedbacks.length > 0 ? `Delete ${selectedFeedbacks.length} selected` : `Delete selected`}
                </Button>
              </SpaceBetween>
            }
            description="View in-app submitted feedback from users "
          >
            {`Feedback`}
          </Header>
        }
        filter={
          <PropertyFilter
            i18nStrings={propertyFilterI18nStrings('Feedback')}
            countText={getMatchesCountText(filteredItemsCount!)}
            expandToViewport={true}
            {...propertyFilterProps}
            query={query}
            onChange={(event) => {
              setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH_AND : event.detail);
            }}
          />
        }
        {...collectionProps}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />}
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            visibleContentOptions={DEFAULT_FEEDBACK_VISIBLE_CONTENT_OPTIONS}
            disablePageSizeOptions={false}
            disableResizableColumnsOptions={true}
          />
        }
      />
    </>
  );
};

export default FeedbackTable;
