/* eslint-disable prettier/prettier */
import { Box, ContentLayout, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APPS_SERVICE_ICON from 'src/shared/icons/th-solid.svg';
import { ApplicationFlatTableEntity } from '../context/AppContextModels';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContextProvider';
import { DFPTopNavigation } from './DFPTopNavigation';
import GlobalDropdown from './GlobalDropdown';

export const DFPGlobalTopNavigation = () => {
  const navigate = useNavigate();
  const userAuthenticationDetails = useAuth();
  const { favoriteApplications, parsedApplications } = useAppContext();
  const activeParsedApps = parsedApplications.filter((app: ApplicationFlatTableEntity) => app.isActive);
  const [value, setValue] = React.useState('');
  const [showGlobalDropdownModal, setShowGlobalDropdownModal] = useState(false);

  const [favoriteApplicationsList, setFavoriteApplicationsList] = useState<ApplicationFlatTableEntity[]>([]);

  const openGlobalDropdown = () => {
    setShowGlobalDropdownModal(true);
  };

  const onCancel = () => {
    setShowGlobalDropdownModal(false);
  };

  const onConfirm = () => {
    setShowGlobalDropdownModal(false);
  };

  useEffect(() => {
    const _favoriteApplicationsList: ApplicationFlatTableEntity[] = activeParsedApps.filter((app: any) => favoriteApplications.includes(app.id));
    setFavoriteApplicationsList(_favoriteApplicationsList);
  }, [favoriteApplications, parsedApplications]);

  const navigateToAppSpecific = (id: string) => {
    navigate('/all-apps/' + id);
  };

  return (
    <>
      <GlobalDropdown
        showModal={showGlobalDropdownModal}
        onCancel={onCancel}
        onConfirm={onConfirm}
        favoriteApplications={favoriteApplicationsList}
        parsedApplications={activeParsedApps}
      />

      <DFPTopNavigation />

      {/* Secondary Top Navigation  */}
      <ContentLayout className="app-secondary-top-navigation">
        <Box>
          <SpaceBetween direction="horizontal" size="s">
            <div className="app-secondary-nav-all-apps-button" onClick={openGlobalDropdown}>
              <SpaceBetween direction="horizontal" size="xs">
                <Box className="display-name-prefix-icon" padding={{ top: 'xxs' }}>
                  <img src={APPS_SERVICE_ICON} alt="app" />
                </Box>
                <Box className="display-name">{'Apps'}</Box>
                <Icon className="display-name-suffix-icon" size="small" name={showGlobalDropdownModal ? 'angle-up' : 'angle-down'} />
              </SpaceBetween>
            </div>

            {favoriteApplicationsList.map((app: ApplicationFlatTableEntity, index: number) => {
              return (
                <SpaceBetween direction="horizontal" size="xs" key={index}>
                  <div className="global-secondary-nav-item">
                    <Box margin={{ left: 's', bottom: 'm', right: 'xxxs' }}>
                      <span className="display-name" onClick={() => navigateToAppSpecific(favoriteApplicationsList[index]?.id)}>
                        {favoriteApplicationsList[index]?.applicationName || ''}
                      </span>
                    </Box>
                  </div>
                </SpaceBetween>
              );
            })}
          </SpaceBetween>
        </Box>
      </ContentLayout>
    </>
  );
};
