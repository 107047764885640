import * as Yup from 'yup';
import { TutorialContentDetailsEntity } from '../../AdminModels';
import { eFinSuiteAdminMessages } from 'src/constants/AppConstants';

export const APP_NAME_MAX_LENGTH = 30;
export const SHORT_DESCRIPTION_MAX_LENGTH = 60;
export const FULL_DESCRIPTION_MAX_LENGTH = 600;

export const WELCOME_TITLE_MAX_LENGTH = 21;
export const WELCOME_CONTENT_LINKS_MAX_LENGTH = 4;
export const WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH = 46;
export const WELCOME_LINK_DESCRIPTION_MAX_LENGTH = 168;

export const MAX_TUTORIAL_LINKS = 50;
export const TUTORIAL_CATEGORY_MAX_LENGTH = 30;
export const TUTORIAL_DISPLAY_TITLE_MAX_LENGTH = 60;

export const OFFICE_HOURS_DATE_TIME_RECURRENCE_MAX_LENGTH = 29;

export const OPTIONAL_MULTI_SELECT_DROPDOWN = Yup.array().of(
  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string()
  })
);

export const REQUIRED_MULTI_SELECT_DROPDOWN = Yup.array()
  .of(
    Yup.object().shape({
      label: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
      id: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD)
    })
  )
  .min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_SELECTION)
  .required(eFinSuiteAdminMessages.REQUIRED_FIELD);

export const REQUIRED_SELECT_DROPDOWN = Yup.object().shape({
  label: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  id: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD)
});

export const nonProductionEnvironmentSchema = Yup.object().shape({
  id: Yup.mixed().nullable(),
  accessLevel: REQUIRED_SELECT_DROPDOWN,
  displayText: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  link: Yup.string().url(eFinSuiteAdminMessages.VALID_URL).required(eFinSuiteAdminMessages.REQUIRED_FIELD)
});

export const welcomeContentDetailsSchema = Yup.object().shape({
  id: Yup.mixed().nullable(),
  displayText: Yup.string()
    .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
    .max(WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH, `Max ${WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH} characters`),
  link: Yup.string().url(eFinSuiteAdminMessages.VALID_URL).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  description: Yup.string()
    .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
    .max(WELCOME_LINK_DESCRIPTION_MAX_LENGTH, `Max ${WELCOME_LINK_DESCRIPTION_MAX_LENGTH} characters`)
});

export const tutorialContentDetailsSchema = (existingPositions: TutorialContentDetailsEntity[]) =>
  Yup.object().shape({
    id: Yup.mixed().nullable(),
    position: Yup.number()
      .typeError(eFinSuiteAdminMessages.ENTER_VALID_NUMBER)
      .nullable()
      .min(1, 'Value must be between 1 and 8')
      .max(8, 'Value must be between 1 and 8'),
    // .test('unique-positions', 'Position must be unique', (position) => {
    //   return !existingPositions.find((existingPosition) => existingPosition.position === position);
    // }),
    category: Yup.string().nullable().max(TUTORIAL_CATEGORY_MAX_LENGTH, `Max ${TUTORIAL_CATEGORY_MAX_LENGTH} characters`),
    displayTitle: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .max(TUTORIAL_DISPLAY_TITLE_MAX_LENGTH, `Max ${TUTORIAL_DISPLAY_TITLE_MAX_LENGTH} characters`),
    link: Yup.string().url(eFinSuiteAdminMessages.VALID_URL).required(eFinSuiteAdminMessages.REQUIRED_FIELD)
  });

export const contactDetailsSchema = () =>
  Yup.object().shape({
    id: Yup.mixed().nullable(),
    position: Yup.number().typeError(eFinSuiteAdminMessages.ENTER_VALID_NUMBER).nullable(),
    alias: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    // .test('alias', eFinSuiteAdminMessages.INVALID_ALIAS, async (alias) => {
    //   if (alias) {
    //     const isValid = await validateAlias(alias);
    //     return isValid;
    //   }
    //   return true;
    // }),
    contactDescription: Yup.string()
  });

export const reportIssuesAndAdditionalDetailsSchema = () =>
  Yup.object().shape({
    id: Yup.mixed().nullable(),
    position: Yup.number()
      .typeError(eFinSuiteAdminMessages.ENTER_VALID_NUMBER)
      .nullable()
      .min(1, 'Value must be between 1 and 8')
      .max(8, 'Value must be between 1 and 8'),
    // .test('unique-positions', 'Position must be unique', (position) => {
    //   return !existingReports.find((existingReport) => existingReport.position === position);
    // }),
    displayName: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    link: Yup.string().url(eFinSuiteAdminMessages.VALID_URL).required(eFinSuiteAdminMessages.REQUIRED_FIELD)
  });

// export const shortDescriptionSchema = Yup.string()
//   .required(eFinSuiteNewAppValidationErrorMessages.REQUIRED_FIELD)
//   .max(SHORT_DESCRIPTION_MAX_LENGTH, `Max ${SHORT_DESCRIPTION_MAX_LENGTH} characters`);
export const shortDescriptionRegexPattern = new RegExp(`^(?=.{1,${SHORT_DESCRIPTION_MAX_LENGTH}}$).*$`);
export const SHORT_DESCRIPTION_ERROR_MESSAGE = `Max ${SHORT_DESCRIPTION_MAX_LENGTH} characters`;

export const applicationDetailsSchema = (existingApplicationNames: string[], shouldSkipAppNameUniquenessValidation: boolean) => {
  return Yup.object().shape({
    applicationName: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .max(APP_NAME_MAX_LENGTH, `Max ${APP_NAME_MAX_LENGTH} characters`)
      .test('unique-application-name', eFinSuiteAdminMessages.UNIQUE_APP_NAME_ERROR, async function (value) {
        if (shouldSkipAppNameUniquenessValidation) {
          return true; // Skip validation
        }
        if (existingApplicationNames && existingApplicationNames.includes(value || '')) {
          return false; // Validation failed
        }
        return true; // Validation passed
      }),
    applicationLink: Yup.string().url(eFinSuiteAdminMessages.VALID_URL),
    applicationOwner: REQUIRED_SELECT_DROPDOWN,
    shortApplicationDescription: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .matches(shortDescriptionRegexPattern, `Max ${SHORT_DESCRIPTION_MAX_LENGTH} characters`),
    fullApplicationDescription: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .max(FULL_DESCRIPTION_MAX_LENGTH, `Max ${FULL_DESCRIPTION_MAX_LENGTH} characters`),
    glAccounts: REQUIRED_SELECT_DROPDOWN,
    toolUI: REQUIRED_SELECT_DROPDOWN
  });
};

export const linksAndTagsSchema = Yup.object().shape({
  hasNonProductionEnvironments: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  nonProductionEnvironments: Yup.array().when('hasNonProductionEnvironments', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(nonProductionEnvironmentSchema)
  }),
  timeGranularity: OPTIONAL_MULTI_SELECT_DROPDOWN,
  keyDimension: OPTIONAL_MULTI_SELECT_DROPDOWN,
  lastEnhanced: OPTIONAL_MULTI_SELECT_DROPDOWN,
  process: OPTIONAL_MULTI_SELECT_DROPDOWN,
  scenario: OPTIONAL_MULTI_SELECT_DROPDOWN
});

export const homepageConfigurationSchema = Yup.object().shape({
  hasWelcomeContent: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  welcomeTitle: Yup.string().when('hasWelcomeContent', {
    is: true,
    then: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD).max(WELCOME_TITLE_MAX_LENGTH, `Max ${WELCOME_TITLE_MAX_LENGTH} characters`),
    otherwise: Yup.string()
  }),
  welcomeContentDetails: Yup.array().when('hasWelcomeContent', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(welcomeContentDetailsSchema)
  }),
  hasAnnouncements: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  hasTutorials: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  tutorialContentDetails: Yup.array().when('hasTutorials', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(tutorialContentDetailsSchema([]))
  }),
  hasFeedback: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD)
});

// Archived
export const OfficeHoursSchema = () =>
  Yup.object().shape({
    meetingTime: Yup.mixed().nullable().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    meetingName: Yup.string().nullable().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    meetingLink: Yup.string().url(eFinSuiteAdminMessages.VALID_URL).nullable(),
    recurrence: Yup.object().shape({
      type: Yup.string().oneOf(['daily', 'weekly', 'monthly', 'none']).required('Recurrence type is required'),
      weekly: Yup.object().when('type', {
        is: 'weekly',
        then: Yup.object().shape({
          every: Yup.number().nullable().min(1, 'must be greater than or equal to 1').required(eFinSuiteAdminMessages.REQUIRED_FIELD),
          days: Yup.object()
            .shape({
              Sunday: Yup.boolean(),
              Monday: Yup.boolean(),
              Tuesday: Yup.boolean(),
              Wednesday: Yup.boolean(),
              Thursday: Yup.boolean(),
              Friday: Yup.boolean(),
              Saturday: Yup.boolean()
            })
            .test('at-least-one-day-selected', 'Please select at least one day', (value: any) => {
              const { Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday } = value;
              return Sunday || Monday || Tuesday || Wednesday || Thursday || Friday || Saturday;
            })
        })
      }),
      monthly: Yup.object().when('type', {
        is: (value: string) => value === 'monthly',
        then: Yup.object().shape({
          every: Yup.number().nullable().min(1, 'must be greater than or equal to 1').required('Required'),
          type: Yup.string()
            .oneOf(['Day', 'First', 'Second', 'Third', 'Last'], 'Monthly recurrence type is required')
            .required('Monthly recurrence type is required'),
          day: Yup.number().when('type', {
            is: 'Day',
            then: Yup.number()
              .nullable()
              .min(1, 'must be greater than or equal to 1')
              .max(31, 'must be less than or equal to 31')
              .required('enter a valid day of the month'),
            otherwise: Yup.number().notRequired()
          }),
          ordinal: Yup.string().when('type', {
            is: 'Day',
            then: Yup.string().notRequired(),
            otherwise: Yup.string()
              .oneOf(['Day', 'Weekday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], 'Ordinal is required')
              .required('Ordinal is required')
          })
        })
      }),
      none: Yup.object().notRequired()
    })
  });

export const OfficeHoursSchemaV2 = () =>
  Yup.object().shape({
    id: Yup.mixed().nullable(),
    dateTimeRecurrence: Yup.string()
      .nullable()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .max(OFFICE_HOURS_DATE_TIME_RECURRENCE_MAX_LENGTH, `Max ${OFFICE_HOURS_DATE_TIME_RECURRENCE_MAX_LENGTH} characters`),
    locationOrLinkName: Yup.string().nullable().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    meetingLink: Yup.string().nullable().url(eFinSuiteAdminMessages.VALID_URL)
  });

export const infoPanelPageConfigurationSchema = Yup.object().shape({
  hasFinanceContacts: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  financeContacts: Yup.array().when('hasFinanceContacts', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(contactDetailsSchema())
  }),
  hasFinTechContacts: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  finTechContacts: Yup.array().when('hasFinTechContacts', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(contactDetailsSchema())
  }),
  hasOfficeHours: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  officeHoursV2: Yup.array().when('hasOfficeHours', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(OfficeHoursSchemaV2())
  }),
  hasReportIssues: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  reportIssues: Yup.array().when('hasReportIssues', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(reportIssuesAndAdditionalDetailsSchema())
  }),
  hasAdditionalResources: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  additionalResources: Yup.array().when('hasAdditionalResources', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(reportIssuesAndAdditionalDetailsSchema())
  })
});

export const ApplicationFormSchema = (applicationNames: string[], shouldSkipAppNameUniquenessValidation: boolean) =>
  Yup.object().shape({
    id: Yup.mixed().nullable(),
    applicationDetails: applicationDetailsSchema(applicationNames, shouldSkipAppNameUniquenessValidation),
    linksAndTags: linksAndTagsSchema,
    homePageConfigurations: homepageConfigurationSchema,
    infoPanelPageConfiguration: infoPanelPageConfigurationSchema
  });
