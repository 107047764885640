// This object defines the different types of user action events that can occur in the application.
// Each property represents a specific type of user action event, and its value is a string
// that serves as a unique identifier for that event type.
export const UserActionsEventTypes = {
  VIEW_CHANGE: 'all_apps_view_type_change',
  CATEGORY_TYPE_CHANGE: 'all_apps_category_type_change',

  TOP_NAV_CATEGORY_CHANGE: 'top_nav_category_change',
  TOP_NAV_SUB_CATEGORY_CHANGE: 'top_nav_sub_category_change',
  TOP_NAV_VIEW_ALL_APPS_CLICKED: 'top_nav_view_all_apps_clicked',
  TOP_NAV_FAVORITE_APP: 'top_nav_favorite_app',
  TOP_NAV_UNFAVORITE_APP: 'top_nav_unfavorite_app',

  HOME_PAGE_SIDE_NAV_CLICK: 'home_page_side_nav_click',

  ANNOUNCEMENTS_FILTER_BY_APP: 'announcements_filter_by_app',
  ANNOUNCEMENTS_FILTER_BY_CATEGORY: 'announcements_filter_by_category',

  TUTORIALS_FILTER_BY_APP: 'tutorials_filter_by_app',
  TUTORIALS_FILTER_BY_CATEGORY: 'tutorials_filter_by_category',
  TUTORIALS_VIDEO_CLICKED: 'tutorials_video_clicked',

  APP_SPECIFIC_HELP_PANEL_CHANGE: 'app_specific_help_panel_change',
  APP_SPECIFIC_BOARD_RESIZE: 'app_specific_board_resize',

  WELCOME_WIDGET_LINK_CLICKED: 'welcome_widget_link_clicked',

  ANNOUNCEMENT_WIDGET_OPEN: 'announcement_widget_open',
  ANNOUNCEMENT_WIDGET_LINK_CLICKED: 'announcement_widget_link_clicked'
};
