import { PropertyFilterProps, TableProps } from '@amzn/awsui-components-react';
import { numberFormatter } from 'src/utilities/CommonUtilities';
import { CostMetricsParsedData } from './CostMetricsInterface';
import { getPhoneToolUrl } from '../FinTechOpsCommonElements';
export const USAGE_COST = ['usage_cost'];

export const COST_METRICS_DETAILS_VISIBLE_CONTENT_TEMPLATE = [
  'applications',
  'metrics_date_YYYY_MM_DD',
  'product',
  'team_name',
  'fleet_name',
  'fleet_owner'
];

export const USAGE_COST_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<CostMetricsParsedData>[] = [
  {
    id: 'usage_cost',
    sortingComparator: (a: CostMetricsParsedData, b: CostMetricsParsedData) => {
      return a.usage_cost - b.usage_cost;
    },
    header: 'Usage Cost',
    cell: (item: CostMetricsParsedData) => numberFormatter(item.usage_cost, true),
    width: 150
  }
];

export const COST_METRICS_DETAILS_COLUMN_DEFINITIONS_TEMPLATE: TableProps.ColumnDefinition<CostMetricsParsedData>[] = [
  {
    id: 'applications',
    sortingField: 'applications',
    header: 'Application',
    cell: (item: CostMetricsParsedData) => item.applications,
    width: 200
  },
  {
    id: 'metrics_date_YYYY_MM_DD',
    sortingField: 'metrics_date_YYYY_MM_DD',
    header: 'Metrics Date',
    cell: (item: CostMetricsParsedData) => item.metrics_date_YYYY_MM_DD,
    width: 180
  },
  {
    id: 'fleet_name',
    sortingField: 'fleet_name',
    header: 'Fleet Name',
    cell: (item: CostMetricsParsedData) => item.fleet_name,
    width: 200
  },

  {
    id: 'fleet_owner',
    sortingField: 'fleet_owner',
    header: 'Fleet Owner',
    cell: (item: CostMetricsParsedData) => item.fleet_owner,
    width: 160
  },
  {
    id: 'product',
    sortingField: 'product',
    header: 'Product',
    cell: (item: CostMetricsParsedData) => item.product,
    width: 180
  },
  {
    id: 'team_name',
    sortingField: 'team_name',
    header: 'Team Name',
    cell: (item: CostMetricsParsedData) => item.team_name,
    width: 150
  }
];

export const COST_METRICS_DETAILS_VISIBLE_CONTENT = [
  ...COST_METRICS_DETAILS_VISIBLE_CONTENT_TEMPLATE,
  'resource',
  'resource_type',
  'usage_unit',
  'usage_value',
  ...USAGE_COST,
  'cost_metrics_manager_name'
];

export const COST_METRICS_DETAILS_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Application',
    key: 'applications',
    groupValuesLabel: 'Application',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Metrics Date',
    key: 'metrics_date_YYYY_MM_DD',
    groupValuesLabel: 'Metrics Date',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Fleet Name',
    key: 'fleet_name',
    groupValuesLabel: 'Fleet Name',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Fleet Owner',
    key: 'fleet_owner',
    groupValuesLabel: 'Fleet Owner',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Product',
    key: 'product',
    groupValuesLabel: 'Product',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Team Name',
    key: 'team_name',
    groupValuesLabel: 'Team Name',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Usage Cost',
    key: 'usage_cost',
    groupValuesLabel: 'Usage Cost',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Resource',
    key: 'resource',
    groupValuesLabel: 'Resource',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Resource Type',
    key: 'resource_type',
    groupValuesLabel: 'Resource Type',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Usage Unit',
    key: 'usage_unit',
    groupValuesLabel: 'Usage Unit',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Usage Value',
    key: 'usage_value',
    groupValuesLabel: 'Usage Value',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Manager',
    key: 'cost_metrics_manager_name',
    groupValuesLabel: 'Manager',
    operators: [':', '!:', '=', '!=']
  }
];

export const COST_METRICS_DETAILS_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<CostMetricsParsedData>[] = [
  ...COST_METRICS_DETAILS_COLUMN_DEFINITIONS_TEMPLATE,
  {
    id: 'resource',
    sortingField: 'resource',
    header: 'Resource',
    cell: (item: CostMetricsParsedData) => item.resource,
    width: 200
  },
  {
    id: 'resource_type',
    sortingField: 'resource_type',
    header: 'Resource Type',
    cell: (item: CostMetricsParsedData) => item.resource_type,
    width: 220
  },
  ...USAGE_COST_COLUMN_DEFINITIONS,
  {
    id: 'usage_value',
    sortingComparator: (a: CostMetricsParsedData, b: CostMetricsParsedData) => {
      return a.usage_value - b.usage_value;
    },
    header: 'Usage Unit Value',
    cell: (item: CostMetricsParsedData) => numberFormatter(Number(item.usage_value).toFixed()),
    width: 180
  },
  {
    id: 'usage_unit',
    sortingField: 'usage_unit',
    header: 'Usage Unit',
    cell: (item: CostMetricsParsedData) => item.usage_unit,
    width: 220
  },
  {
    id: 'cost_metrics_manager_name',
    sortingField: 'cost_metrics_manager_name',
    header: 'Manager',
    cell: (item: CostMetricsParsedData) => getPhoneToolUrl(item.cost_metrics_manager_login, item.cost_metrics_manager_name),
    width: 220
  }
];
