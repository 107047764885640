import { FlashbarProps, Link } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { recordApiRequest } from 'src/analytics/CloudWatchRumClient';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import * as queries from 'src/graphql/queries';
import { logger } from 'src/logger';
import { getCurrentTime, getTimeDifference } from 'src/utilities/DateTimeUtilities';
import {
  FinTechOpsCategories,
  FinTechOpsMessages,
  InitialSasTabData,
  InitialTicketsTabData,
  InitialUserMetricsTabData
} from '../fintech-ops/FinTechOpsConstants';
import {
  DashboardFilters,
  PolicyEngineDashboardItems,
  SaSDashboardItems,
  UserMetricsDashboardItems
} from '../fintech-ops/fintech-ops-dashboard/FinTechOpsDataInterfaces';
import { returnUsefulRawPolicyEngineData } from '../fintech-ops/policy-engine/PolicyEngineDataProcessing';
import { PolicyEngineParsedDetails } from '../fintech-ops/policy-engine/PolicyEngineInterface';
import { returnUsefulRawDataSas } from '../fintech-ops/sas/SasDataProcessing';
import { SasDetails, SasParsedDetails } from '../fintech-ops/sas/SasInterfaces';
import { returnUsefulRawDataTickets } from '../fintech-ops/tickets/TicketsDataProcessing';
import { TicketParsedDetails, TicketsDashboard } from '../fintech-ops/tickets/TicketsInterfaces';
import { returnUsefulRawUserMetricsData } from '../fintech-ops/user-metrics/UserMetricsDataProcessing';
import { UserMetricsParsedDetails } from '../fintech-ops/user-metrics/UserMetricsInterfaces';
import { returnParsedAndFormattedDogmaData } from '../fintech-ops/dogma/DogmaDataProcessing';
import { DogmaDashboardItems, DogmaParsedDetails } from '../fintech-ops/dogma/DogmaInterface';
import { getDashboardFilters } from '../fintech-ops/FinTechOpsDataProcessingFunctions';
import { getS3PayloadAndLastUpdatedAtData, getUniqueFieldValues } from '../../../src/utilities/CommonUtilities';
import {
  COST_METRICS_LAST_UPDATED_AT_S3_PREFIX,
  COST_METRICS_S3_PREFIX,
  DOGMA_LAST_UPDATED_AT_S3_PREFIX,
  DOGMA_S3_PREFIX,
  POLICY_ENGINE_LAST_UPDATED_AT_S3_PREFIX,
  POLICY_ENGINE_S3_PREFIX,
  SAS_LAST_UPDATED_AT_S3_PREFIX,
  SAS_S3_PREFIX,
  TICKETS_LAST_UPDATED_AT_S3_PREFIX,
  TICKETS_S3_PREFIX,
  USER_METRICS_LAST_UPDATED_AT_S3_PREFIX,
  USER_METRICS_S3_PREFIX
} from '../fintech-ops/FinTechOpsConstants';
import { CostMetricsDashboardItems, CostMetricsParsedDetails } from '../fintech-ops/cost-metrics/CostMetricsInterface';
import { returnParsedAndFormattedCostMetricsData } from '../fintech-ops/cost-metrics/CostMetricsDataProcessing';
import { INITIAL_COST_METRICS_TAB_DATA } from '../fintech-ops/cost-metrics/CostMetricsConstants';
import {
  fetchDirects,
  fetchManagers,
  fetchRiskRemediatorAsrData,
  fetchRiskRemediatorRisks,
  fetchRiskRemediatorVpcData
} from 'src/components/fintech-ops/risk-remediator/utils';
import { RISK_REMEDIATOR_API_ERROR, RISK_REMEDIATOR_INITIAL_DATA } from 'src/components/fintech-ops/risk-remediator/constants';

type StatusType = 'pending' | 'loading' | 'finished' | 'error'; //For Multi Select Status
interface FintechContext {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => void;
  flashbarItems: FlashbarProps.MessageDefinition[];

  dashboardFilterStatus: StatusType;
  setDashboardFilterStatus: (multiSelectStatus: StatusType) => void;

  dashboardFilterData: DashboardFilters;
  setDashboardFilterData: (masterFilterData: DashboardFilters) => void;

  dashboardFilterDataCalculationStatus: StatusType;
  setDashboardFilterDataCalculationStatus: (statusOfTickets: StatusType) => void;

  // Tickets
  // V2 - Ready
  finTechOpsTicketDataStatus: StatusType;
  setFinTechOpsTicketDataStatus: (statusOfTicketData: StatusType) => void;

  finTechOpsTicketDashboard: TicketsDashboard;
  setFinTechOpsTicketDashboard: (ticketsTab: TicketsDashboard) => void;

  finTechOpsTicketsData: TicketParsedDetails;
  setFinTechOpsTicketsData: (ticketsData: TicketParsedDetails) => void;

  // User Metrics
  // V2 - Ready
  finTechOpsUserMetricsDataStatus: StatusType;
  setFinTechOpsUserMetricsDataStatus: (statusOfPolicyEngineDad: StatusType) => void;

  userMetricsWithUserData: UserMetricsParsedDetails;
  setUserMetricsWithUserData: (userMetricsWithUserData: UserMetricsParsedDetails) => void;

  finTechOpsUserMetricsDashBoard: UserMetricsDashboardItems;
  setFinTechOpsUserMetricsDashBoard: (userMetricsTab: UserMetricsDashboardItems) => void;

  // SAS
  // V2 - Ready
  finTechOpsSasDataStatus: StatusType;
  setFinTechOpsSasDataStatus: (statusOfSasDad: StatusType) => void;

  finTechOpsSasDashBoard: SaSDashboardItems;
  setFinTechOpsSasDashBoard: (sasTab: SaSDashboardItems) => void;

  sasData: SasParsedDetails;
  setSasData: (SasData: SasParsedDetails) => void;

  //TODO Policy Engine
  finTechOpsPolicyEngineDataStatus: StatusType;
  setFinTechOpsPolicyEngineDataStatus: (statusOfPolicyEngineDad: StatusType) => void;

  finTechOpsPolicyEngineParsedData: PolicyEngineParsedDetails;
  setFinTechOpsPolicyEngineParsedData: (finTechOpsPolicyEngineRawData: PolicyEngineParsedDetails) => void;

  finTechOpsPolicyEngineDashboardData: PolicyEngineDashboardItems;
  setFinTechOpsPolicyEngineDashboardData: (finTechOpsPolicyEngineDashboardData: PolicyEngineDashboardItems) => void;

  // TODO Dogma
  finTechOpsDogmaDataStatus: StatusType;
  setFinTechOpsDogmaDataStatus: (statusOfDogmaData: StatusType) => void;

  finTechOpsDogmaParsedData: DogmaParsedDetails;
  setFinTechOpsDogmaParsedData: (finTechOpsPolicyEngineRawData: DogmaParsedDetails) => void;

  finTechOpsDogmaDashboardData: DogmaDashboardItems;
  setFinTechOpsDogmaDashboardData: (finTechOpsPolicyEngineDashboardData: DogmaDashboardItems) => void;

  //Cost Metrics
  finTechOpsCostMetricsDataStatus: StatusType;
  setFinTechOpsCostMetricsDataStatus: (statusOfCostMetricsData: StatusType) => void;

  finTechOpsCostMetricsParsedData: CostMetricsParsedDetails;
  setFinTechOpsCostMetricsParsedData: (finTechOpsCostMetricsRawData: CostMetricsParsedDetails) => void;

  finTechOpsCostMetricsDashboardData: CostMetricsDashboardItems;
  setFinTechOpsCostMetricsDashboardData: (finTechOpsCostMetricsDashboardData: CostMetricsDashboardItems) => void;

  finTechOpsCostMetricsMonthList: string[];
  setFinTechOpsCostMetricsMonthList: (finTechOpsCostMetricsMonthList: string[]) => void;

  //TODO Cradle
  finTechOpsCradleDataStatus: StatusType;
  setFinTechOpsCradleDataStatus: (statusOfCradleDad: StatusType) => void;

  // Risk Remediator
  finTechOpsRiskRemediatorStatus: StatusType;
  setFinTechOpsRiskRemediatorStatus: (statusOfRiskRemediatorData: StatusType) => void;
  finTechOpsRiskRemediatorData: any;
  setFinTechOpsRiskRemediatorData: (riskRemediatorData: any) => void;
  finTechOpsRemediatorManagerData: any;
  setFinTechOpsRemediatorManagerData: (remediatorManagerData: any) => void;
  finTechOpsRemediatorDirectsData: any;
  setFinTechOpsDirectsData: (remediatorDirectsData: any) => void;
  finTechOpsRemediatorAsrData: any;
  setFinTechOpsRemediatorAsrData: (remediatorAsrData: any) => void;
  finTechOpsRemediatorVpcData: any;
  setFinTechOpsRemediatorVpcData: (remediatorVpcData: any) => void;
}

const FintechContextInitialData: FintechContext = {
  displayNotification: (status: FlashbarProps.Type, message: string, linkMessage?: string) => {},
  flashbarItems: [],

  dashboardFilterStatus: 'loading',
  setDashboardFilterStatus: (multiSelectStatus: StatusType) => {},

  dashboardFilterData: {} as DashboardFilters,
  setDashboardFilterData: (masterFilterData: DashboardFilters) => {},

  dashboardFilterDataCalculationStatus: 'loading',
  setDashboardFilterDataCalculationStatus: (statusOfTickets: StatusType) => {},

  // Tickets
  finTechOpsTicketDataStatus: 'loading',
  setFinTechOpsTicketDataStatus: (statusOfTicketData: StatusType) => {},

  finTechOpsTicketDashboard: InitialTicketsTabData,
  setFinTechOpsTicketDashboard: (ticketsTab: TicketsDashboard) => {},

  finTechOpsTicketsData: {} as TicketParsedDetails,
  setFinTechOpsTicketsData: (ticketsData: TicketParsedDetails) => {},

  // User Metrics
  finTechOpsUserMetricsDataStatus: 'loading',
  setFinTechOpsUserMetricsDataStatus: (statusOfDogmaData: StatusType) => {},

  userMetricsWithUserData: {} as UserMetricsParsedDetails,
  setUserMetricsWithUserData: (userMetricsWithUserData: UserMetricsParsedDetails) => {},

  finTechOpsUserMetricsDashBoard: InitialUserMetricsTabData,
  setFinTechOpsUserMetricsDashBoard: (userMetricsTab: UserMetricsDashboardItems) => {},

  // SAS
  finTechOpsSasDataStatus: 'loading',
  setFinTechOpsSasDataStatus: (statusOfSasDad: StatusType) => {},

  finTechOpsSasDashBoard: InitialSasTabData,
  setFinTechOpsSasDashBoard: (sasTab: SaSDashboardItems) => {},

  sasData: {} as SasParsedDetails,
  setSasData: (SasData: SasParsedDetails) => {},

  // Policy Engine
  finTechOpsPolicyEngineDataStatus: 'loading',
  setFinTechOpsPolicyEngineDataStatus: (statusOfPolicyEngineDad: StatusType) => {},

  finTechOpsPolicyEngineParsedData: {} as PolicyEngineParsedDetails,
  setFinTechOpsPolicyEngineParsedData: (finTechOpsPolicyEngineRawData: PolicyEngineParsedDetails) => {},

  finTechOpsPolicyEngineDashboardData: {} as PolicyEngineDashboardItems,
  setFinTechOpsPolicyEngineDashboardData: (finTechOpsPolicyEngineDashboardData: PolicyEngineDashboardItems) => {},

  // Dogma
  finTechOpsDogmaDataStatus: 'loading',
  setFinTechOpsDogmaDataStatus: (statusOfDogmaData: StatusType) => {},

  finTechOpsDogmaParsedData: {} as DogmaParsedDetails,
  setFinTechOpsDogmaParsedData: (finTechOpsPolicyEngineRawData: DogmaParsedDetails) => {},

  finTechOpsDogmaDashboardData: {} as DogmaDashboardItems,
  setFinTechOpsDogmaDashboardData: (finTechOpsPolicyEngineDashboardData: DogmaDashboardItems) => {},

  //Cost Metrics
  finTechOpsCostMetricsDataStatus: 'loading',
  setFinTechOpsCostMetricsDataStatus: (statusOfCostMetricsData: StatusType) => {},

  finTechOpsCostMetricsParsedData: {} as CostMetricsParsedDetails,
  setFinTechOpsCostMetricsParsedData: (finTechOpsCostMetricsRawData: CostMetricsParsedDetails) => {},

  finTechOpsCostMetricsDashboardData: INITIAL_COST_METRICS_TAB_DATA,
  setFinTechOpsCostMetricsDashboardData: (finTechOpsCostMetricsDashboardData: CostMetricsDashboardItems) => {},

  finTechOpsCostMetricsMonthList: [],
  setFinTechOpsCostMetricsMonthList: (finTechOpsCostMetricsMonthList: string[]) => {},

  // TODO Cradle
  finTechOpsCradleDataStatus: 'loading',
  setFinTechOpsCradleDataStatus: (statusOfCradleDad: StatusType) => {},

  // Risk Remediator
  finTechOpsRiskRemediatorStatus: 'loading',
  setFinTechOpsRiskRemediatorStatus: (statusOfRiskRemediator: StatusType) => {},

  finTechOpsRiskRemediatorData: {} as typeof RISK_REMEDIATOR_INITIAL_DATA,
  setFinTechOpsRiskRemediatorData: (finTechOpsRiskRemediatorRawData: typeof RISK_REMEDIATOR_INITIAL_DATA) => {},

  finTechOpsRemediatorManagerData: [] as any[],
  setFinTechOpsRemediatorManagerData: (finTechOpsRiskRemediatorManagerRawData: typeof RISK_REMEDIATOR_INITIAL_DATA) => {},

  finTechOpsRemediatorDirectsData: [] as any[],
  setFinTechOpsDirectsData: (finTechOpsRiskRemediatorDirectsRawData: typeof RISK_REMEDIATOR_INITIAL_DATA) => {},

  finTechOpsRemediatorAsrData: [] as any[],
  setFinTechOpsRemediatorAsrData: (finTechOpsRemediatorAsrRawData: typeof RISK_REMEDIATOR_INITIAL_DATA) => {},

  finTechOpsRemediatorVpcData: [] as any[],
  setFinTechOpsRemediatorVpcData: (finTechOpsRemediatorVpcRawData: typeof RISK_REMEDIATOR_INITIAL_DATA) => {}
};

const FintechContextDetails = createContext(FintechContextInitialData);

export const useFinTechContext = () => useContext(FintechContextDetails);

interface FintechContextProviderProps {
  children: React.ReactNode;
}

// FinTech Ops --> High level context
export const FintechOpsContextProvider = ({ children }: FintechContextProviderProps) => {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  // Dashboard details
  const [dashboardFilterStatus, setDashboardFilterStatus] = useState<StatusType>('loading');
  const [dashboardFilterData, setDashboardFilterData] = useState<DashboardFilters>({} as DashboardFilters);
  const [dashboardFilterDataCalculationStatus, setDashboardFilterDataCalculationStatus] = useState<StatusType>('loading');

  // Tickets
  const [finTechOpsTicketDataStatus, setFinTechOpsTicketDataStatus] = useState<StatusType>('loading');
  const [finTechOpsTicketDashboard, setFinTechOpsTicketDashboard] = useState<TicketsDashboard>(InitialTicketsTabData);
  const [finTechOpsTicketsData, setFinTechOpsTicketsData] = useState<TicketParsedDetails>({} as TicketParsedDetails);

  // User Metrics
  const [finTechOpsUserMetricsDataStatus, setFinTechOpsUserMetricsDataStatus] = useState<StatusType>('loading');

  const [userMetricsWithUserData, setUserMetricsWithUserData] = useState<UserMetricsParsedDetails>({} as UserMetricsParsedDetails);
  const [finTechOpsUserMetricsDashBoard, setFinTechOpsUserMetricsDashBoard] = useState<UserMetricsDashboardItems>(InitialUserMetricsTabData);

  // SAS
  const [finTechOpsSasDataStatus, setFinTechOpsSasDataStatus] = useState<StatusType>('loading');
  const [finTechOpsSasDashBoard, setFinTechOpsSasDashBoard] = useState<SaSDashboardItems>(InitialSasTabData);
  const [sasData, setSasData] = useState<SasParsedDetails>({} as SasParsedDetails);

  // Policy Engine
  const [finTechOpsPolicyEngineDataStatus, setFinTechOpsPolicyEngineDataStatus] = useState<StatusType>('loading');
  const [finTechOpsPolicyEngineParsedData, setFinTechOpsPolicyEngineParsedData] = useState<PolicyEngineParsedDetails>(
    {} as PolicyEngineParsedDetails
  );
  const [finTechOpsPolicyEngineDashboardData, setFinTechOpsPolicyEngineDashboardData] = useState<PolicyEngineDashboardItems>(
    {} as PolicyEngineDashboardItems
  );

  // Dogma
  const [finTechOpsDogmaDataStatus, setFinTechOpsDogmaDataStatus] = useState<StatusType>('loading');
  const [finTechOpsDogmaParsedData, setFinTechOpsDogmaParsedData] = useState<DogmaParsedDetails>({} as DogmaParsedDetails);
  const [finTechOpsDogmaDashboardData, setFinTechOpsDogmaDashboardData] = useState<any>({});

  const dataFetchedRef = useRef(false);

  // Cradle
  const [finTechOpsCradleDataStatus, setFinTechOpsCradleDataStatus] = useState<StatusType>('loading');

  //Cost Metrics
  const [finTechOpsCostMetricsDataStatus, setFinTechOpsCostMetricsDataStatus] = useState<StatusType>('loading');
  const [finTechOpsCostMetricsParsedData, setFinTechOpsCostMetricsParsedData] = useState<CostMetricsParsedDetails>({} as CostMetricsParsedDetails);
  const [finTechOpsCostMetricsDashboardData, setFinTechOpsCostMetricsDashboardData] = useState<any>({});
  const [finTechOpsCostMetricsMonthList, setFinTechOpsCostMetricsMonthList] = useState<string[]>([]);

  // Risk Remediator

  const [finTechOpsRiskRemediatorStatus, setFinTechOpsRiskRemediatorStatus] = useState<StatusType>('loading');
  const [finTechOpsRiskRemediatorData, setFinTechOpsRiskRemediatorData] = useState<any>(RISK_REMEDIATOR_INITIAL_DATA);
  const [finTechOpsRemediatorManagerData, setFinTechOpsRemediatorManagerData] = useState<any>(RISK_REMEDIATOR_INITIAL_DATA);
  const [finTechOpsRemediatorDirectsData, setFinTechOpsDirectsData] = useState<any>(RISK_REMEDIATOR_INITIAL_DATA);
  const [finTechOpsRemediatorAsrData, setFinTechOpsRemediatorAsrData] = useState<any>(RISK_REMEDIATOR_INITIAL_DATA);
  const [finTechOpsRemediatorVpcData, setFinTechOpsRemediatorVpcData] = useState<any>(RISK_REMEDIATOR_INITIAL_DATA);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getTicketsData();
    getSasData();
    getUserMetricsData();
    getPolicyEngineMetrics();
    getDogmaData();
    getCostMetricsData();
    getRiskRemediatorData();
  }, []);

  useEffect(() => {
    if (
      finTechOpsTicketDataStatus === 'finished' &&
      finTechOpsSasDataStatus === 'finished' &&
      finTechOpsUserMetricsDataStatus === 'finished' &&
      finTechOpsPolicyEngineDataStatus === 'finished' &&
      finTechOpsDogmaDataStatus === 'finished' &&
      finTechOpsCostMetricsDataStatus === 'finished' &&
      finTechOpsRiskRemediatorStatus == 'finished'
    ) {
      loadDashboardFilterData();
    }

    if (
      finTechOpsTicketDataStatus === 'error' ||
      finTechOpsSasDataStatus === 'error' ||
      finTechOpsUserMetricsDataStatus === 'error' ||
      finTechOpsPolicyEngineDataStatus === 'error' ||
      finTechOpsDogmaDataStatus === 'error' ||
      finTechOpsCostMetricsDataStatus === 'error' ||
      finTechOpsRiskRemediatorStatus == 'error'
    ) {
      setDashboardFilterStatus('error');
      setDashboardFilterDataCalculationStatus('error');
    }
  }, [
    finTechOpsTicketDataStatus,
    finTechOpsSasDataStatus,
    finTechOpsUserMetricsDataStatus,
    finTechOpsPolicyEngineDataStatus,
    finTechOpsDogmaDataStatus,
    finTechOpsCostMetricsDataStatus,
    finTechOpsRiskRemediatorStatus
  ]);

  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            {' '}
            submit a ticket
          </Link>
        );
      }
    }
    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} 'or ' {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  // Fetching Tickets API data
  const getTicketsData = async () => {
    setFinTechOpsTicketDataStatus('loading');
    try {
      const [ticketsPayload, lastUpdatedAtPayload] =
        (await getS3PayloadAndLastUpdatedAtData(TICKETS_S3_PREFIX, TICKETS_LAST_UPDATED_AT_S3_PREFIX, FinTechOpsCategories.tickets)) || [];
      const formattedTicketsData = returnUsefulRawDataTickets(ticketsPayload, lastUpdatedAtPayload);
      setFinTechOpsTicketsData(formattedTicketsData);
      setFinTechOpsTicketDataStatus('finished');
    } catch (error: any) {
      logger.error('Unable to load Tickets Data', error);
      setFinTechOpsTicketDataStatus('error');
      displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  // Fetching User Metrics with users data from s3
  const getUserMetricsData = async () => {
    setFinTechOpsUserMetricsDataStatus('loading');
    try {
      const [userMetricsWithUserPayload, lastUpdatedAtPayload] =
        (await getS3PayloadAndLastUpdatedAtData(USER_METRICS_S3_PREFIX, USER_METRICS_LAST_UPDATED_AT_S3_PREFIX, FinTechOpsCategories.userMetrics)) ||
        [];

      const parsedWithUserData = returnUsefulRawUserMetricsData(userMetricsWithUserPayload, lastUpdatedAtPayload);
      setUserMetricsWithUserData(parsedWithUserData);
      setFinTechOpsUserMetricsDataStatus('finished');
    } catch (error: any) {
      //logging any error
      logger.error('Unable to load User Metrics Data', error);
      setFinTechOpsUserMetricsDataStatus('error');
      displayNotification(`error`, FinTechOpsMessages.S3APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  // Fetching SAS API data
  const getSasData = async () => {
    setFinTechOpsSasDataStatus('loading');
    try {
      const [sasPayload, lastUpdatedAtPayload] =
        (await getS3PayloadAndLastUpdatedAtData(SAS_S3_PREFIX, SAS_LAST_UPDATED_AT_S3_PREFIX, FinTechOpsCategories.sas)) || [];

      const formattedSASData = returnUsefulRawDataSas(sasPayload, lastUpdatedAtPayload);
      setSasData(formattedSASData);
      setFinTechOpsSasDataStatus('finished');
    } catch (error: any) {
      //logging any error
      logger.error('Unable to load Sas Data', error);
      setFinTechOpsSasDataStatus('error');
      displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  // Fetching Policy Engine API data
  const getPolicyEngineMetrics = async () => {
    setFinTechOpsPolicyEngineDataStatus('loading');
    try {
      const [policyEnginePayload, lastUpdatedAtPayload] =
        (await getS3PayloadAndLastUpdatedAtData(
          POLICY_ENGINE_S3_PREFIX,
          POLICY_ENGINE_LAST_UPDATED_AT_S3_PREFIX,
          FinTechOpsCategories.policyEngine
        )) || [];

      const parsedPolicyEngineData = returnUsefulRawPolicyEngineData(policyEnginePayload, lastUpdatedAtPayload);
      setFinTechOpsPolicyEngineParsedData(parsedPolicyEngineData);
      setFinTechOpsPolicyEngineDataStatus('finished');
    } catch (error: any) {
      logger.error('Unable to load Policy Engine data', error);
      setFinTechOpsPolicyEngineDataStatus('error');
      displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  // Fetching Dogma API data
  const getDogmaData = async () => {
    setFinTechOpsDogmaDataStatus('loading');
    try {
      const [dogmaPayload, lastUpdatedAtPayload] =
        (await getS3PayloadAndLastUpdatedAtData(DOGMA_S3_PREFIX, DOGMA_LAST_UPDATED_AT_S3_PREFIX, FinTechOpsCategories.dogma)) || [];

      const parsedDogmaData = returnParsedAndFormattedDogmaData(dogmaPayload, lastUpdatedAtPayload);
      setFinTechOpsDogmaParsedData(parsedDogmaData);
      setFinTechOpsDogmaDataStatus('finished');
    } catch (error: any) {
      logger.error('Unable to load Dogma data', error);
      setFinTechOpsDogmaDataStatus('error');
      displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  // Fetching Cost Metrics S3 API data
  const getCostMetricsData = async () => {
    setFinTechOpsCostMetricsDataStatus('loading');
    try {
      const [costMetricsPayload, lastUpdatedAtPayload] =
        (await getS3PayloadAndLastUpdatedAtData(COST_METRICS_S3_PREFIX, COST_METRICS_LAST_UPDATED_AT_S3_PREFIX, FinTechOpsCategories.costMetrics)) ||
        [];
      const parsedCostMetricsData = returnParsedAndFormattedCostMetricsData(costMetricsPayload, lastUpdatedAtPayload);
      const monthList = getUniqueFieldValues(parsedCostMetricsData.data, 'metrics_date_MMM_YYYY');
      setFinTechOpsCostMetricsMonthList(monthList);
      setFinTechOpsCostMetricsParsedData(parsedCostMetricsData);
      setFinTechOpsCostMetricsDataStatus('finished');
    } catch (error: any) {
      logger.error(`Unable to load ${FinTechOpsCategories.costMetrics} data`, error);
      setFinTechOpsCostMetricsDataStatus('error');
      displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  };

  // Risk Remediator
  const getRiskRemediatorData = async () => {
    setFinTechOpsRiskRemediatorStatus('loading');
    try {
      const [riskRemediatorPayload, riskRemediatorManagerData, riskRemediatorDirectsData, riskRemediatorAsrPayload, riskRemediatorVpcPayload] =
        await Promise.all([
          fetchRiskRemediatorRisks(RISK_REMEDIATOR_INITIAL_DATA.duration, RISK_REMEDIATOR_INITIAL_DATA.manager),
          fetchManagers(),
          fetchDirects(RISK_REMEDIATOR_INITIAL_DATA.manager),
          fetchRiskRemediatorAsrData(RISK_REMEDIATOR_INITIAL_DATA.manager),
          fetchRiskRemediatorVpcData(RISK_REMEDIATOR_INITIAL_DATA.manager)
        ]);

      setFinTechOpsRiskRemediatorData(riskRemediatorPayload ?? []);
      setFinTechOpsRemediatorManagerData(riskRemediatorManagerData);
      setFinTechOpsDirectsData(riskRemediatorDirectsData ?? {});
      setFinTechOpsRemediatorAsrData(riskRemediatorAsrPayload ?? {});
      setFinTechOpsRemediatorVpcData(riskRemediatorVpcPayload ?? {});

      setFinTechOpsRiskRemediatorStatus('finished');
    } catch (error: any) {
      logger.error(`Unable to load Risk Remediator data`, error);
      setFinTechOpsRiskRemediatorStatus('error');
      displayNotification(`error`, FinTechOpsMessages.APIError, RISK_REMEDIATOR_API_ERROR);
    }
  };

  const loadDashboardFilterData = () => {
    setDashboardFilterStatus('loading');
    setDashboardFilterDataCalculationStatus('loading');

    const filterValues = getDashboardFilters(
      finTechOpsTicketsData.data,
      userMetricsWithUserData.data,
      sasData.data,
      finTechOpsPolicyEngineParsedData.data,
      finTechOpsDogmaParsedData.data,
      finTechOpsCostMetricsParsedData.data
    );

    setDashboardFilterData({
      team_name: filterValues.uniqueTeams,
      managers: filterValues.uniqueManagers,
      assignees: filterValues.uniqueAssignees
    });
    setDashboardFilterStatus('finished');
    setDashboardFilterDataCalculationStatus('finished');
  };

  const filterAndAddValues = (set: Set<string>, values: string[]) => {
    values.forEach((value: string) => {
      if (value !== null && value !== '') {
        set.add(value);
      }
    });
    return set;
  };

  return (
    <div>
      <FintechContextDetails.Provider
        value={{
          displayNotification,
          flashbarItems,

          dashboardFilterData,
          setDashboardFilterData,

          dashboardFilterStatus,
          setDashboardFilterStatus,

          dashboardFilterDataCalculationStatus,
          setDashboardFilterDataCalculationStatus,

          finTechOpsTicketsData,
          setFinTechOpsTicketsData,

          finTechOpsTicketDashboard,
          setFinTechOpsTicketDashboard,

          finTechOpsUserMetricsDataStatus,
          setFinTechOpsUserMetricsDataStatus,

          finTechOpsDogmaDataStatus,
          setFinTechOpsDogmaDataStatus,

          finTechOpsCostMetricsDataStatus,
          setFinTechOpsCostMetricsDataStatus,

          finTechOpsSasDataStatus,
          setFinTechOpsSasDataStatus,

          finTechOpsCradleDataStatus,
          setFinTechOpsCradleDataStatus,

          finTechOpsPolicyEngineDataStatus,
          setFinTechOpsPolicyEngineDataStatus,

          finTechOpsPolicyEngineParsedData,
          setFinTechOpsPolicyEngineParsedData,

          finTechOpsPolicyEngineDashboardData,
          setFinTechOpsPolicyEngineDashboardData,

          finTechOpsTicketDataStatus,
          setFinTechOpsTicketDataStatus,

          sasData,
          setSasData,

          userMetricsWithUserData,
          setUserMetricsWithUserData,

          finTechOpsSasDashBoard,
          setFinTechOpsSasDashBoard,

          finTechOpsUserMetricsDashBoard,
          setFinTechOpsUserMetricsDashBoard,

          finTechOpsDogmaParsedData,
          setFinTechOpsDogmaParsedData,
          finTechOpsDogmaDashboardData,
          setFinTechOpsDogmaDashboardData,

          finTechOpsCostMetricsParsedData,
          setFinTechOpsCostMetricsParsedData,
          finTechOpsCostMetricsDashboardData,
          setFinTechOpsCostMetricsDashboardData,
          finTechOpsCostMetricsMonthList,
          setFinTechOpsCostMetricsMonthList,

          finTechOpsRiskRemediatorStatus,
          setFinTechOpsRiskRemediatorStatus,
          finTechOpsRiskRemediatorData,
          setFinTechOpsRiskRemediatorData,
          finTechOpsRemediatorManagerData,
          setFinTechOpsRemediatorManagerData,
          finTechOpsRemediatorDirectsData,
          setFinTechOpsDirectsData,
          finTechOpsRemediatorAsrData,
          setFinTechOpsRemediatorAsrData,
          finTechOpsRemediatorVpcData,
          setFinTechOpsRemediatorVpcData
        }}
      >
        {children}
      </FintechContextDetails.Provider>
    </div>
  );
};
