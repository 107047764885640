import { AppLayout, Box, ContentLayout, Flashbar, FlashbarProps, Header, Link, Tabs } from '@amzn/awsui-components-react';
import { DFPHomePageSideNavigation } from '../../dfp-home-page/DFPHomePageSideNavigation';
import React, { useState } from 'react';
import { DogmaSelectionFilters } from './DogmaSelectionFilters';
import { ComingSoonContainer } from '../FinTechOpsCommonElements';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { DogmaDetailsPage } from './DogmaDetailsTab';
import { DogmaOverviewPage } from './DogmaOverview';
import { FinTechOpsHref } from '../FinTechOpsConstants';
import { DogmaContext } from './DogmaContext';
import { StatusType } from '../sas/SasInterfaces';

export const DogmaMain = () => {
  const finOpsContext = useFinTechContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [dogmaGraphStatus, setDogmaGraphStatus] = useState<StatusType>('loading');
  const [dogmaGraphData, setDogmaGraphData] = useState<any>();

  const [dogmaDetailsTabStatus, setDogmaDetailsTabStatus] = useState<StatusType>('loading');
  const [dogmaDetailsTabData, setDogmaDetailsTabData] = useState<any>();

  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            {' '}
            submit a ticket
          </Link>
        );
      }
    }
    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} 'or ' {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={finOpsContext.flashbarItems} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: FinTechOpsHref.finTechOps },
            {
              text: 'Dogma',
              href: ''
            }
          ]}
        />
      }
      maxContentWidth={Number.MAX_VALUE}
      content={
        <>
          <DogmaContext.Provider
            value={{
              displayNotification,
              dogmaGraphStatus,
              setDogmaGraphStatus,
              dogmaGraphData,
              setDogmaGraphData,
              dogmaDetailsTabStatus,
              setDogmaDetailsTabStatus,
              dogmaDetailsTabData,
              setDogmaDetailsTabData
            }}
          >
            <DogmaSelectionFilters />
            <Tabs
              tabs={[
                {
                  label: 'Overview',
                  id: 'dogma-OV',
                  content: <DogmaOverviewPage />
                },
                {
                  label: 'Details',
                  id: 'dogma-DT',
                  content: <DogmaDetailsPage />
                }
              ]}
            />
          </DogmaContext.Provider>
        </>
      }
    />
  );
};
