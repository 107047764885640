import { Box, Button, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { KatFileUploadStatus } from '@amzn/katal-components/es/file-upload';
import { KatFileUpload } from '@amzn/katal-react';
import React, { useEffect, useState } from 'react';
import { getHeaderRowFromExcel, readExcelFile } from 'src/utilities/FileServices';
import { VALIDATION_FAILED, VALIDATION_INITIATED, camelToNormal } from 'src/utilities/CommonHelpers';
import * as MappingValidations from './MappingValidations';
import { logger } from 'src/logger';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import {
  DuplicateValuesValidationMessages,
  HeaderValidationMessages,
  INITIAL_VALIDATION_STATUS,
  NO_DATA_AVAILABLE_IN_FILE,
  RequiredFieldsValidationMessages
} from './MappingConstants';
import { FileState, FileValidation, MappingImportModalProps } from './MappingInterface';

export const MappingImportModal = ({ showModal, onCancel, onConfirm, mappingKey, requiredFileHeaders, keyColumn }: MappingImportModalProps) => {
  const [fileState, setFileState] = useState<FileState>({ fileUploadState: { status: KatFileUploadStatus.READY, error: '' } });
  const [fileValidation, setFileValidation] = useState<FileValidation>(INITIAL_VALIDATION_STATUS);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadFileData, setUploadFileData] = useState<any[]>([]);

  useEffect(() => {
    setUploadingFile(false);
    setFileValidation(INITIAL_VALIDATION_STATUS);
    setFileState({ fileUploadState: { status: KatFileUploadStatus.READY, error: '' } });
  }, [showModal]);

  const handleOnFileUpload = async (event: KatFileUpload.FilesAttachedEvent) => {
    logger.info(` upload - handleOnFileUpload`);
    const fileInfo = await Promise.all([getHeaderRowFromExcel(event.detail.files[0]), readExcelFile(event.detail.files[0])]);
    ValidateData(fileInfo[0], fileInfo[1]);
  };

  const handleOnFileReplacement = async (event: KatFileUpload.FilesReplacedEvent) => {
    logger.info(` upload - handleOnFileReplacement`);
    const fileInfo = await Promise.all([getHeaderRowFromExcel(event.detail.newFiles[0]), readExcelFile(event.detail.newFiles[0])]);
    ValidateData(fileInfo[0], fileInfo[1]);
  };

  const handleOnFileRemove = (event: KatFileUpload.FilesRemovedEvent) => {
    setUploadFileData([]);
  };

  //Function to validate the header, required fields and duplicate values
  const ValidateData = async (uploadFileHeader: string[], uploadFileData: any[]) => {
    if (uploadFileHeader.length === 0 || uploadFileData.length === 0) {
      setFileValidation({
        HeadersMatching: { ...VALIDATION_FAILED, validationMessage: NO_DATA_AVAILABLE_IN_FILE },
        RequiredFieldMissing: { ...VALIDATION_FAILED, validationMessage: NO_DATA_AVAILABLE_IN_FILE },
        DuplicateValidation: { ...VALIDATION_FAILED, validationMessage: NO_DATA_AVAILABLE_IN_FILE }
      });
    } else {
      setUploadFileData(uploadFileData);
      setFileValidation({
        HeadersMatching: { ...VALIDATION_INITIATED, validationMessage: HeaderValidationMessages.InitMsg },
        RequiredFieldMissing: { ...VALIDATION_INITIATED, validationMessage: RequiredFieldsValidationMessages.InitMsg },
        DuplicateValidation: {
          ...VALIDATION_INITIATED,
          validationMessage: DuplicateValuesValidationMessages.InitMsg
        }
      });

      // Updating the UI about the validation status
      setFileValidation({
        // Basic checks like required fields, headers and duplicates
        HeadersMatching: await MappingValidations.validateHeaderData(uploadFileHeader, requiredFileHeaders),
        RequiredFieldMissing: await MappingValidations.validateRequiredFields(uploadFileHeader, keyColumn),
        DuplicateValidation: await MappingValidations.validateDuplicate(uploadFileData, keyColumn)
      });
    }
  };

  const isValidFile = () => {
    return (
      fileValidation.HeadersMatching.validationStatus === 'success' &&
      fileValidation.RequiredFieldMissing.validationStatus === 'success' &&
      fileValidation.DuplicateValidation.validationStatus === 'success'
    );
  };

  const handleExcelFileUploadSubmit = async () => {
    setUploadingFile(true);
    onConfirm(uploadFileData);
    setUploadingFile(false);
  };

  return (
    <Modal
      onDismiss={() => {
        setUploadingFile(true);
        onCancel();
      }}
      size="large"
      visible={showModal}
      closeAriaLabel="Close modal"
      header={`${camelToNormal(mappingKey.split('_')[0].toString())} Mapping Import`}
      footer={
        <>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setUploadingFile(true);
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" disabled={!isValidFile() || uploadingFile} onClick={handleExcelFileUploadSubmit}>
                Load data
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        {!uploadingFile && (
          <KatFileUpload
            accept=".xlsx"
            file-view="list"
            variant="large"
            required
            disabled={uploadingFile}
            multiple={false}
            state={fileState}
            onFilesAttached={(e) => handleOnFileUpload(e)}
            onFilesReplaced={(e) => handleOnFileReplacement(e)}
            onFilesRemoved={(e) => handleOnFileRemove(e)}
          >
            <div slot="hint">{'Accepted file format: .xlsx'}</div>
            <div slot="error">{fileState.fileUploadState.error}</div>
          </KatFileUpload>
        )}
        {uploadingFile && <LoadingSpinner />}
        <div>
          <SpaceBetween size="m" direction="vertical">
            {Object.keys(fileValidation).map((key, index) => {
              return (
                <StatusIndicator key={index} type={fileValidation[key].validationStatus} colorOverride={fileValidation[key].colorOverride}>
                  {`${fileValidation[key].validationMessage}`}
                </StatusIndicator>
              );
            })}
          </SpaceBetween>
        </div>
      </SpaceBetween>
    </Modal>
  );
};
