import { StatusIndicatorProps } from '@amzn/awsui-components-react';
import { sortedObj } from 'src/components/fintech-ops/ops-mappings/MappingValidations';

// File Validations
export const VALIDATION_NOT_INITIATED: ValidationEntity = {
  validationStatus: 'pending',
  colorOverride: 'grey',
  validationMessage: 'Not initiated'
};

export const VALIDATION_INITIATED: ValidationEntity = {
  validationStatus: 'loading',
  colorOverride: 'blue',
  validationMessage: 'Validating'
};

export const VALIDATION_SUCCESS: ValidationEntity = {
  validationStatus: 'success',
  colorOverride: 'green',
  validationMessage: 'Valid'
};

export const VALIDATION_FAILED: ValidationEntity = {
  validationStatus: 'pending',
  colorOverride: 'red',
  validationMessage: 'Invalid'
};

export interface ValidationEntity {
  colorOverride: StatusIndicatorProps.Color;
  validationStatus: StatusIndicatorProps.Type;
  validationMessage: string;
}

export const checkIfAllFieldsArePresent = (arrayOfObjects: any[], fieldsToExclude: string[]) => {
  return arrayOfObjects.every((object: any) => {
    return Object.keys(object).every((field: any) => {
      if (fieldsToExclude.includes(field)) {
        return true;
      }
      return object[field] !== undefined && object[field] !== null && object[field] !== '';
    });
  });
};

export const validateCols = (fileHeaderArr: any[], requiredFields: string[]) => {
  return requiredFields.every((val) => fileHeaderArr.includes(val));
};

// This function compares two array of objects and return the difference
export const compareTwoArrayOfObjects = (initialDataArray: any[], updatedDataArray: string[]) => {
  let initDataArr = initialDataArray.map((obj) => JSON.stringify(sortedObj(obj)));
  let diffArr = updatedDataArray.filter((obj) => !initDataArr.includes(JSON.stringify(sortedObj(obj))));

  return diffArr;
};

// Exclude values from the array by the given array
export const excludeValuesFromArray = (array: any[], valuesToExclude: any[]) => {
  return array.filter((value) => !valuesToExclude.includes(value));
};

export const camelToNormal = (camelCaseString: string): string => {
  // Replace all capital letters with a space and the lowercase letter
  return (
    camelCaseString
      .replace(/([A-Z])/g, ' $1')
      // Capitalize the first letter
      .replace(/^./, (str) => str.toUpperCase())
  );
};

//add unique values to filterArray
export const getUniqueValues = (filterArray: string[], value: string) => {
  if (!filterArray.includes(value)) {
    filterArray.push(value);
  }
};
