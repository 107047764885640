import { Button, DateRangePickerProps, IconProps, SelectProps, TableProps } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllApplications, useAppContext } from 'src/components/context/AppContextProvider';
import { AllAppsOfficeHours, AppContext, Application, UserAuthContext } from 'src/components/context/AppContextModels';
import { useAuth } from 'src/components/context/AuthContextProvider';
import * as mutations from 'src/graphql/mutations';
import { logger } from 'src/logger';
import { getCurrentUTCTimeZoneInISO, getReadableDateTimeFormat } from 'src/utilities/DateTimeUtilities';
import {
  ApplicationWizard,
  ContactsEntity,
  NonProductionEnvironmentsEntity,
  OfficeHoursV2,
  ReportAndAdditionalIssuesEntity,
  TutorialContentDetailsEntity,
  WelcomeContentDetailsEntity
} from '../../AdminModels';
import {
  OfficeHoursSchema,
  applicationDetailsSchema,
  homepageConfigurationSchema,
  infoPanelPageConfigurationSchema,
  linksAndTagsSchema
} from './StepsValidations';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { eFinSuiteAdminMessages } from 'src/constants/AppConstants';

export const INITIAL_STEP_INFO: ApplicationWizard = {
  id: null,
  applicationDetails: {
    applicationName: '',
    applicationLink: '',
    applicationOwner: {
      label: '',
      id: '',
      labelType: 'ApplicationOwner'
    },
    shortApplicationDescription: '',
    fullApplicationDescription: '',
    glAccounts: {
      label: '',
      id: '',
      labelType: 'GLAccount'
    },
    toolUI: {
      label: '',
      id: '',
      labelType: 'ToolUI'
    }
  },
  linksAndTags: {
    hasNonProductionEnvironments: false,
    nonProductionEnvironments: [],
    timeGranularity: [],
    keyDimension: [],
    process: [],
    scenario: [],
    lastEnhanced: []
  },
  homePageConfigurations: {
    hasWelcomeContent: false,
    welcomeTitle: '',
    welcomeContentDetails: [],
    hasAnnouncements: false,
    hasTutorials: false,
    tutorialContentDetails: [],
    hasFeedback: false
  },
  infoPanelPageConfiguration: {
    hasFinanceContacts: false,
    financeContacts: [],
    hasFinTechContacts: false,
    finTechContacts: [],
    hasOfficeHours: false,
    officeHoursV2: [],
    hasReportIssues: false,
    reportIssues: [],
    hasAdditionalResources: false,
    additionalResources: []
  },
  itemMetadata: {
    isActive: true,
    createdBy: '',
    createdTime: '',
    updatedBy: '',
    updatedTime: ''
  }
};

export const removeQuotesFromString = (str: string) => {
  try {
    if (typeof str === 'string') {
      if (str.startsWith('"') && str.endsWith('"')) {
        return str.slice(1, -1);
      }
      return str;
    }
  } catch (error) {
    console.error('Error occurred while removing quotes:', error);
  }

  return str;
};

export const useWizard = () => {
  const navigate = useNavigate();
  const userAuthDetails = useAuth();
  const appContext = useAppContext();
  const formRef = useRef<FormikProps<ApplicationWizard>>(null);

  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [stepError, setStepError] = useState('');
  const [action, setAction] = useState<'Create' | 'Update'>('Create');

  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const [fromUrl, setFromUrl] = React.useState('');

  const setActiveStepIndexAndCloseTools = (index: any) => {
    setActiveStepIndex(index);
  };

  const onNavigate = async (evt: any, activeStepIndex: number, shouldSkipAppNameUniquenessValidation: boolean) => {
    const requestedStepIndex = evt.detail.requestedStepIndex;
    const directionReason = evt.detail.reason;

    const existingApplicationNames = appContext?.listOfApplications?.map((application) => application.applicationName || '');
    const appDetailsAreValid = await applicationDetailsSchema(existingApplicationNames, shouldSkipAppNameUniquenessValidation).isValid(
      formRef.current?.values?.applicationDetails
    );
    const linksAndTagsAreValid = await linksAndTagsSchema.isValid(formRef.current?.values?.linksAndTags);
    const configHomepageAreValid = await homepageConfigurationSchema.isValid(formRef.current?.values?.homePageConfigurations);
    const infoPanelDetailsAreValid = await infoPanelPageConfigurationSchema.isValid(formRef.current?.values?.infoPanelPageConfiguration);

    if (activeStepIndex === 0) {
      if (appDetailsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setApplicationDetailsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (activeStepIndex === 1) {
      if (linksAndTagsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setLinksAndTagsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (activeStepIndex === 2) {
      if (configHomepageAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setConfigureHomePageAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }

    if (directionReason === 'previous' && requestedStepIndex === 2) {
      if (infoPanelDetailsAreValid) {
        setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
        setStepError('');
      } else {
        setInfoPanelDetailsAsTouched();
        setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      }
      return;
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: ''
    });
  };

  const onWizardCancel = () => {
    setShowModalInfo({
      showModal: true,
      eventHeader: 'Cancel',
      eventContentType: 'CANCEL_APPLICATION',
      messageInfo: {
        applicationName: formRef.current?.values.applicationDetails.applicationName
      }
    });
  };

  const navigateBackToFrom = () => {
    if (fromUrl) {
      navigate(fromUrl);
    } else {
      navigate('/admin/applications');
    }
  };

  const onConfirm = async (showModalInfo: ShowModalInfo) => {
    showModalInfo.eventContentType === 'CANCEL_APPLICATION' && navigateBackToFrom();
  };

  const onSubmit = async () => {
    // Last Step validation happens while Submit
    const infoPanelDetailsPageAreValid = await infoPanelPageConfigurationSchema.isValid(formRef.current?.values?.infoPanelPageConfiguration);
    if (!infoPanelDetailsPageAreValid) {
      setInfoPanelDetailsAsTouched();
      setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
      return;
    }

    formRef.current
      ?.validateForm()
      .then(async () => {
        if (formRef.current?.isValid) {
          setStepError('');
          const application = wizardToAPI(formRef.current?.values, userAuthDetails, action);
          const mutation = action === 'Create' ? createApplication(application) : updateApplication(application);
          mutation
            .then(async (response) => {
              logger.info(`Successfully ${action}d Application.`, { info: response });
              const fetchAllApplicationsResponse = await fetchAllApplications();
              appContext.setListOfApplications(fetchAllApplicationsResponse.allApplications);
              appContext.setParsedApplications(fetchAllApplicationsResponse.applicationFlatTableEntity);

              const applicationFlashMessage =
                action === 'Create' ? eFinSuiteAdminMessages.SUCCESSFULLY_ADDS_NEW_APP : eFinSuiteAdminMessages.SUCCESSFULLY_UPDATES_NEW_APP;
              appContext.displayApplicationFlashMessage(applicationFlashMessage, 'success');

              navigateBackToFrom();
            })
            .catch((error: any) => {
              const applicationFlashMessage =
                action === 'Create' ? eFinSuiteAdminMessages.UNSUCCESSFULLY_ADDS_NEW_APP : eFinSuiteAdminMessages.UNSUCCESSFULLY_UPDATES_NEW_APP;
              setStepError(applicationFlashMessage);
              logger.error(`Unable to submit ${action} Application request.`, error);
            });
        } else {
          setStepError(eFinSuiteAdminMessages.NEXT_BUTTON_VALIDATION_ERROR);
          setApplicationDetailsAsTouched();
          setLinksAndTagsAsTouched();
          setConfigureHomePageAsTouched();
        }
      })
      .catch((error: any) => {
        const errorMessage =
          action === 'Create' ? eFinSuiteAdminMessages.UNSUCCESSFULLY_ADDS_NEW_APP : eFinSuiteAdminMessages.UNSUCCESSFULLY_UPDATES_NEW_APP;
        setStepError(errorMessage);
        logger.error(`Unable to submit ${action} Application request.`, error);
      });
  };

  const setApplicationDetailsAsTouched = () => {
    formRef.current?.setTouched({
      applicationDetails: {
        applicationName: true,
        applicationLink: true,
        applicationOwner: { label: true },
        shortApplicationDescription: true,
        fullApplicationDescription: true,
        glAccounts: { label: true },
        toolUI: { label: true }
      }
    });
  };

  const setLinksAndTagsAsTouched = () => {
    formRef.current?.setTouched({
      linksAndTags: {
        hasNonProductionEnvironments: true,
        nonProductionEnvironments: true,
        keyDimension: [{ label: true }],
        process: [{ label: true }],
        scenario: [{ label: true }],
        lastEnhanced: [{ label: true }],
        timeGranularity: [{ label: true }]
      }
    });
  };

  const setConfigureHomePageAsTouched = () => {
    formRef.current?.setTouched({
      homePageConfigurations: {
        hasWelcomeContent: true,
        welcomeTitle: true,
        welcomeContentDetails: true,
        hasAnnouncements: true,
        hasTutorials: true,
        tutorialContentDetails: true,
        hasFeedback: true
      }
    });
  };

  const setInfoPanelDetailsAsTouched = () => {
    formRef.current?.setTouched({
      infoPanelPageConfiguration: {
        hasFinanceContacts: true,
        financeContacts: true,
        hasFinTechContacts: true,
        finTechContacts: true,
        hasOfficeHours: true,
        officeHoursV2: true,
        hasReportIssues: true,
        reportIssues: true,
        hasAdditionalResources: true,
        additionalResources: true
      }
    });
  };
  return {
    activeStepIndex,
    setActiveStepIndexAndCloseTools,
    stepError,
    setStepError,
    action,
    setAction,
    applicationFormRef: formRef,
    onNavigate,
    onWizardCancel,
    onSubmit,
    showModalInfo,
    onCancel,
    onConfirm,
    fromUrl,
    setFromUrl
  };
};

const createApplication = async (inputForCreateApplication: Application) => {
  const createApplicationResponse: any = await API.graphql({
    query: mutations.createApplication,
    variables: { input: inputForCreateApplication }
  });
  return createApplicationResponse?.data?.createApplication;
};

const updateApplication = async (inputForCreateApplication: Application) => {
  const createApplicationResponse: any = await API.graphql({
    query: mutations.updateApplication,
    variables: { input: inputForCreateApplication }
  });
  return createApplicationResponse?.data?.updateApplication;
};

export const wizardToAPI = (applicationWizard: ApplicationWizard, userDetails: UserAuthContext, action: 'Create' | 'Update'): Application => {
  return {
    id: applicationWizard.id || '',
    applicationName: applicationWizard.applicationDetails.applicationName,
    applicationLink: applicationWizard.applicationDetails.applicationLink,
    applicationOwner: [applicationWizard.applicationDetails.applicationOwner],
    shortApplicationDescription: applicationWizard.applicationDetails.shortApplicationDescription,
    fullApplicationDescription: applicationWizard.applicationDetails.fullApplicationDescription,
    glAccounts: [applicationWizard.applicationDetails.glAccounts],
    toolUI: [applicationWizard.applicationDetails.toolUI],
    hasNonProductionEnvironments: applicationWizard.linksAndTags.hasNonProductionEnvironments,
    nonProductionEnvironments: applicationWizard.linksAndTags.nonProductionEnvironments?.map((nonProdEnv) => {
      return {
        ...nonProdEnv,
        accessLevel: [nonProdEnv.accessLevel]
      };
    }),
    timeGranularity: applicationWizard.linksAndTags.timeGranularity,
    keyDimension: applicationWizard.linksAndTags.keyDimension,
    process: applicationWizard.linksAndTags.process,
    scenario: applicationWizard.linksAndTags.scenario,
    lastEnhanced: applicationWizard.linksAndTags.lastEnhanced,
    hasWelcomeContent: applicationWizard.homePageConfigurations.hasWelcomeContent,
    welcomeTitle: applicationWizard.homePageConfigurations.welcomeTitle,
    welcomeContentDetails: applicationWizard.homePageConfigurations.welcomeContentDetails,
    hasAnnouncements: applicationWizard.homePageConfigurations.hasAnnouncements,
    hasTutorials: applicationWizard.homePageConfigurations.hasTutorials,
    tutorialContentDetails: applicationWizard.homePageConfigurations.tutorialContentDetails,
    hasFeedback: applicationWizard.homePageConfigurations.hasFeedback,
    hasFinanceContacts: applicationWizard.infoPanelPageConfiguration.hasFinanceContacts,
    financeContacts: applicationWizard.infoPanelPageConfiguration.financeContacts || [],
    hasFinTechContacts: applicationWizard.infoPanelPageConfiguration.hasFinTechContacts,
    finTechContacts: applicationWizard.infoPanelPageConfiguration.finTechContacts || [],
    hasOfficeHours: applicationWizard.infoPanelPageConfiguration.hasOfficeHours,
    officeHoursV2: applicationWizard.infoPanelPageConfiguration.officeHoursV2 || [],
    hasReportIssues: applicationWizard.infoPanelPageConfiguration.hasReportIssues,
    reportIssues: applicationWizard.infoPanelPageConfiguration.reportIssues || [],
    hasAdditionalResources: applicationWizard.infoPanelPageConfiguration.hasAdditionalResources,
    additionalResources: applicationWizard.infoPanelPageConfiguration.additionalResources || [],
    itemMetadata: {
      isActive: applicationWizard.itemMetadata.isActive,
      createdBy: action === 'Create' ? userDetails.Alias : applicationWizard.itemMetadata.createdBy,
      createdTime: action === 'Create' ? getCurrentUTCTimeZoneInISO() : applicationWizard.itemMetadata.createdTime,
      updatedBy: userDetails.Alias,
      updatedTime: getCurrentUTCTimeZoneInISO()
    }
  };
};

export const APIToWizard = (application: Application): ApplicationWizard => {
  return {
    id: application.id,
    applicationDetails: {
      applicationName: application.applicationName || '',
      applicationLink: application.applicationLink || '',
      applicationOwner: application.applicationOwner[0],
      shortApplicationDescription: application.shortApplicationDescription || '',
      fullApplicationDescription: application.fullApplicationDescription || '',
      glAccounts: application.glAccounts[0],
      toolUI: application.toolUI[0]
    },
    linksAndTags: {
      hasNonProductionEnvironments: application.hasNonProductionEnvironments,
      nonProductionEnvironments: application.nonProductionEnvironments?.map((nonProdEnv) => {
        return {
          ...nonProdEnv,
          accessLevel: nonProdEnv.accessLevel[0]
        };
      }),
      timeGranularity: application.timeGranularity || [],
      keyDimension: application.keyDimension || [],
      process: application.process || [],
      scenario: application.scenario || [],
      lastEnhanced: application.lastEnhanced || []
    },
    homePageConfigurations: {
      hasWelcomeContent: application.hasWelcomeContent || false,
      welcomeTitle: application.welcomeTitle || '',
      welcomeContentDetails: application.welcomeContentDetails || [],
      hasAnnouncements: application.hasAnnouncements || false,
      hasTutorials: application.hasTutorials || false,
      tutorialContentDetails: application.tutorialContentDetails || [],
      hasFeedback: application.hasFeedback || false
    },
    infoPanelPageConfiguration: {
      hasFinanceContacts: application.hasFinanceContacts || false,
      financeContacts: application.financeContacts || [],
      hasFinTechContacts: application.hasFinTechContacts || false,
      finTechContacts: application.finTechContacts || [],
      hasOfficeHours: application.hasOfficeHours || false,
      officeHoursV2: application.officeHoursV2 || [],
      hasReportIssues: application.hasReportIssues || false,
      reportIssues: application.reportIssues || [],
      hasAdditionalResources: application.hasAdditionalResources || false,
      additionalResources: application.additionalResources || []
    },
    itemMetadata: {
      isActive: application.itemMetadata.isActive || false,
      createdBy: application.itemMetadata.createdBy || '',
      createdTime: application.itemMetadata.createdTime,
      updatedBy: application.itemMetadata.updatedBy || '',
      updatedTime: application.itemMetadata.updatedTime
    }
  };
};

// Table definitions
// Non-production environments
export const APP_ENV_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<NonProductionEnvironmentsEntity>[] = [
  {
    id: 'accessLevel',
    header: 'Access level',
    width: 150,
    minWidth: 150,
    cell: (item) => (
      <Button formAction="none" iconName={getAccessLevelIcon(item.accessLevel.label)} variant="link">
        {item.accessLevel.label}
      </Button>
    )
  },
  {
    id: 'displayText',
    header: 'Display name',
    width: 250,
    cell: (item) => item.displayText
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link
  }
];

// Welcome content table
export const APP_WELCOME_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<WelcomeContentDetailsEntity>[] = [
  {
    id: 'displayText',
    header: 'Display name',
    cell: (item) => item.displayText
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link
  },
  {
    id: 'description',
    header: 'Description',
    cell: (item) => item.description
  }
];

// Tutorial content table
export const APP_TUTORIAL_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TutorialContentDetailsEntity>[] = [
  {
    id: 'position',
    header: 'Position',
    cell: (item) => item.position,
    width: 80
  },
  {
    id: 'category',
    header: 'Category',
    cell: (item) => item.category
  },
  {
    id: 'displayTitle',
    header: 'Display title',
    cell: (item) => item.displayTitle
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link
  }
];

export const APP_FINANCE_CONTACT_DEFINITION: TableProps.ColumnDefinition<ContactsEntity>[] = [
  {
    id: 'position',
    header: 'Position',
    cell: (item) => item.position,
    width: 80
  },
  {
    id: 'contactDescription',
    header: 'Contact Description',
    cell: (item) => item.contactDescription
  },
  {
    id: 'alias',
    header: 'Contact Alias',
    cell: (item) => item.alias
  }
];

export const APP_OFFICE_HOURS_DEFINITION: TableProps.ColumnDefinition<OfficeHoursV2>[] = [
  {
    id: 'dateTimeRecurrence',
    header: 'Date, time and recurrence',
    cell: (item) => item.dateTimeRecurrence,
    width: 120
  },
  {
    id: 'locationOrLinkName',
    header: 'Location or link name',
    cell: (item) => item.locationOrLinkName,
    width: 120
  },
  {
    id: 'meetingLink',
    header: 'Meeting Link',
    cell: (item) => item.meetingLink
  }
];

export const APP_REPORT_ISSUES_ADDITIONAL_RESOURCES_DEFINITION: TableProps.ColumnDefinition<ReportAndAdditionalIssuesEntity>[] = [
  {
    id: 'position',
    header: 'Position',
    cell: (item) => item.position,
    width: 80
  },
  {
    id: 'displayName',
    header: 'Display Name',
    cell: (item) => item.displayName
  },
  {
    id: 'link',
    header: 'Link',
    cell: (item) => item.link
  }
];

export const getAccessLevelIcon = (accessLevel: string | undefined | null): IconProps.Name | undefined => {
  let accessLevelIconName: IconProps.Name | undefined = undefined;
  switch (accessLevel) {
    case 'Public':
      accessLevelIconName = 'unlocked';
      break;
    case 'Admin':
      accessLevelIconName = 'lock-private';
      break;
    default:
      accessLevelIconName = undefined;
      break;
  }
  return accessLevelIconName;
};

export const getRecurrencesList = () => {
  return [
    { label: 'Daily (Monday to Friday)', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Does not repeat', value: 'none' }
  ] as SelectProps.Options;
};

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const selectedRecurrence = (recurrence: string) => {
  const selectedOption = getRecurrencesList().find((recurrenceOption: any) => recurrenceOption.value === recurrence);
  return selectedOption || null;
};

export const getMonthlyTypes = () => {
  return [
    { label: 'Day', value: 'Day' },
    { label: 'First', value: 'First' },
    { label: 'Second', value: 'Second' },
    { label: 'Third', value: 'Third' },
    { label: 'Last', value: 'Last' }
  ] as SelectProps.Options;
};

export const selectedMonthlyType = (monthlyType: string) => {
  const selectedOption = getMonthlyTypes().find((getMonthlyType: any) => getMonthlyType.value === monthlyType);
  return selectedOption || null;
};

export const getMonthlyOrdinals = () => {
  return [
    { label: 'Day', value: 'Day' },
    { label: 'Weekday', value: 'Weekday' },
    { label: 'Sunday', value: 'Sunday' },
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' }
  ] as SelectProps.Options;
};

export const selectedOrdinals = (ordinal: string) => {
  const selectedOption = getMonthlyOrdinals().find((getMonthlyType: any) => getMonthlyType.value === ordinal);
  return selectedOption || null;
};
