import { Box, Button, ColumnLayout, Modal, RadioGroup, RadioGroupProps, SpaceBetween } from '@amzn/awsui-components-react';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as mutations from 'src/graphql/mutations';
import { logger } from 'src/logger';
import { ApplicationFlatTableEntity, GroupedAppData, GroupedApplicationFlatTableEntity } from '../context/AppContextModels';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContextProvider';
import { EmptyState } from '../generic-components/empty-state';
import styles from './styles.module.scss';
import { replaceSpecialCharacters, replaceSpaces } from 'src/utilities/CommonUtilities';
import { v4 as uuidv4 } from 'uuid';
import { UserActionsEventTypes } from '../das-finsuite/das-finsuite-constants';

interface GlobalDropdownProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: (confirmMessage: any[]) => void;
  favoriteApplications: ApplicationFlatTableEntity[];
  parsedApplications: ApplicationFlatTableEntity[];
}

interface GlobalDropdownSideNavOptions {
  id: number;
  label: string;
}

const ALL_APPS_RADIO_SELECTION = [
  { value: 'GL Account', label: 'GL Account' },
  { value: 'Team', label: 'Team' },
  { value: 'Product UI', label: 'Product UI' }
];

enum eCategoryBy {
  glAccount = 'GL Account',
  team = 'Team',
  productUi = 'Product UI'
}
const GlobalDropdown: React.FC<GlobalDropdownProps> = ({
  showModal,
  onCancel,
  onConfirm,
  favoriteApplications,
  parsedApplications
}: GlobalDropdownProps) => {
  const navigate = useNavigate();
  const userAuthenticationDetails = useAuth();
  const userIsAdmin = userAuthenticationDetails.isAdmin;
  const appContext = useAppContext();
  // const [isCategorizedByUI, setIsCategorizedByUI] = useState(false);
  const [groupedApplications, setGroupedApplications] = useState<GroupedAppData>({});
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [categorizedBy, setCategorizedBy] = React.useState<eCategoryBy>(eCategoryBy.glAccount);
  const centerPanelRef = useRef<HTMLDivElement>(null);

  const globalDropdownSideNavOptions: GlobalDropdownSideNavOptions[] = [
    { id: 1, label: 'All Apps' },
    { id: 2, label: 'Favorites' }
  ];

  const [selectedOption, setSelectedOption] = useState<GlobalDropdownSideNavOptions>(globalDropdownSideNavOptions[0]);

  // Handle option selection
  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (categorizedBy == eCategoryBy.glAccount) {
      groupByGlAccount(parsedApplications);
    } else if (categorizedBy == eCategoryBy.team) {
      groupByTeam(parsedApplications);
    } else {
      groupByToolUIAccount(parsedApplications);
    }
  }, [categorizedBy, favoriteApplications]);

  const groupByGlAccount = (applicationsList: ApplicationFlatTableEntity[]) => {
    let index = 0;
    const groupedData = applicationsList.reduce((application, appData) => {
      if (!application[appData.glAccount]) {
        application[appData.glAccount] = [];
      }
      application[appData.glAccount].push({
        ...appData,
        isFavoriteApp: favoriteApplications.find((favoriteApp) => favoriteApp.id === appData.id) ? true : false,
        customIndex: index++
      });
      return application;
    }, {} as { [key: string]: GroupedApplicationFlatTableEntity[] });
    setGroupedApplications(sortGroupedData(groupedData));
  };

  const groupByTeam = (applicationsList: ApplicationFlatTableEntity[]) => {
    let index = 0;
    const groupedData = applicationsList.reduce((application, appData) => {
      if (!application[appData.applicationOwner]) {
        application[appData.applicationOwner] = [];
      }
      application[appData.applicationOwner].push({
        ...appData,
        isFavoriteApp: favoriteApplications.find((favoriteApp) => favoriteApp.id === appData.id) ? true : false,
        customIndex: index++
      });
      return application;
    }, {} as { [key: string]: GroupedApplicationFlatTableEntity[] });
    setGroupedApplications(sortGroupedData(groupedData));
  };

  const groupByToolUIAccount = (applicationsList: ApplicationFlatTableEntity[]) => {
    let index = 0;
    const groupedData = applicationsList.reduce((application, appData) => {
      if (!application[appData.toolUI]) {
        application[appData.toolUI] = [];
      }
      application[appData.toolUI].push({
        ...appData,
        isFavoriteApp: favoriteApplications.find((favoriteApp) => favoriteApp.id === appData.id) ? true : false,
        customIndex: index++
      });
      return application;
    }, {} as { [key: string]: GroupedApplicationFlatTableEntity[] });
    setGroupedApplications(sortGroupedData(groupedData));
  };

  const sortGroupedData = (unSortedGroupedData: GroupedAppData) => {
    // Convert the object keys (main categories) to an array
    const categories = Object.keys(unSortedGroupedData);

    // Sort the categories alphabetically
    categories.sort((a, b) => a.localeCompare(b));

    // Create a new object with sorted categories
    const sortedGroupedData: GroupedAppData = {};
    categories.forEach((category) => {
      sortedGroupedData[category] = unSortedGroupedData[category];
    });
    return sortedGroupedData;
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const clickedFavorite = (app: GroupedApplicationFlatTableEntity) => {
    manageFavorites(!app.isFavoriteApp, app);
  };

  const manageFavorites = async (addAppAsFavorite: boolean, app: GroupedApplicationFlatTableEntity) => {
    let finalArray: string[] = [];
    if (!addAppAsFavorite) {
      logger.info(`DaS FinSuite -> User Action: Unfavorite App`, {
        event_type: UserActionsEventTypes.TOP_NAV_UNFAVORITE_APP,
        value: app.applicationName
      });
      finalArray = appContext.favoriteApplications.filter((item) => item !== app.id);
    } else {
      logger.info(`DaS FinSuite -> User Action: Favorite App`, {
        event_type: UserActionsEventTypes.TOP_NAV_FAVORITE_APP,
        value: app.applicationName
      });
      finalArray = [...appContext.favoriteApplications, app.id];
    }

    try {
      const createOrUpdateApplicationFavoritesResponse: any = await API.graphql({
        query: mutations.createOrUpdateApplicationFavorites,
        variables: {
          input: {
            userAlias: userAuthenticationDetails.Alias,
            applicationId: finalArray
          }
        }
      });
      const response = createOrUpdateApplicationFavoritesResponse.data.createOrUpdateApplicationFavorites;
      appContext.setFavoriteApplications(finalArray);
      logger.info(`Updated application favorites for user ${userAuthenticationDetails.Alias}`);
    } catch (error: any) {
      logger.error('Error while updating application favorites ', error);
    }
  };

  const navigateToAppSpecific = (id: string) => {
    navigate('/all-apps/' + id);
    onCancel();
  };

  const clickedCategoryFromSideNav = (category: string) => {
    const element = centerPanelRef?.current?.querySelector(`#category-${replaceSpecialCharacters(category)}`);
    logger.info(`DaS FinSuite -> User Action: Sub-Category Change`, {
      event_type: UserActionsEventTypes.TOP_NAV_SUB_CATEGORY_CHANGE,
      value: category
    });
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const col1 = () => {
    return (
      <SpaceBetween size="m" direction="vertical">
        {/* All Apps & Favorites section  */}
        {globalDropdownSideNavOptions.map((option: GlobalDropdownSideNavOptions) => (
          <Box
            margin={{ top: 'm' }}
            fontSize="heading-s"
            variant="awsui-key-label"
            className={`${selectedOption.id === option.id ? 'global-side-nav-items-selected' : 'global-side-nav-items'}`}
            key={option.id}
          >
            <span onClick={() => handleOptionSelect(option)}>{option.label}</span>
          </Box>
        ))}

        {/* Admin Console only for Admin  */}
        {userIsAdmin && (
          <>
            <div className="horizontal-bar-above-admin" />
            <Box fontSize="heading-s" variant="awsui-key-label" className="grid-content-item">
              <span
                onClick={() => {
                  navigate('/admin');
                  onCancel();
                }}
              >
                {'Admin Console'}
              </span>
            </Box>
          </>
        )}

        {/* Category information  */}
        <>
          {selectedOption.label === 'All Apps' && Object.entries(groupedApplications).length !== 0 && (
            <>
              <div className="horizontal-bar-above-admin" />
              {Object.entries(groupedApplications).map(([glAccount, apps], index) => (
                <div key={index} onClick={() => clickedCategoryFromSideNav(glAccount)}>
                  <Box key={index} fontSize="heading-xs" fontWeight="light" variant="awsui-key-label" className="grid-content-item">
                    <span>{glAccount}</span>
                  </Box>
                </div>
              ))}
            </>
          )}
        </>
      </SpaceBetween>
    );
  };

  const handleCategoryChange = (detail: RadioGroupProps.ChangeDetail) => {
    setCategorizedBy(detail.value as eCategoryBy);
    logger.info(`DaS FinSuite -> User Action: Category Change`, {
      event_type: UserActionsEventTypes.TOP_NAV_CATEGORY_CHANGE,
      value: detail.value
    });
  };

  const col2 = () => {
    return (
      selectedOption && (
        <div>
          {(() => {
            switch (selectedOption.id) {
              case 1: // All Apps
                return (
                  <SpaceBetween size="m" direction="vertical">
                    <div>
                      <Box margin={{ top: 'm' }}>
                        <SpaceBetween size="m" direction="horizontal">
                          <Box variant="awsui-key-label" fontSize="heading-m" className="grid-content-item">
                            {'All Apps'}
                          </Box>
                          <Box>
                            <RadioGroup
                              className="radio-selection-type"
                              onChange={({ detail }) => handleCategoryChange(detail)}
                              value={categorizedBy}
                              items={ALL_APPS_RADIO_SELECTION}
                            />
                          </Box>
                        </SpaceBetween>
                      </Box>
                    </div>

                    <div
                      onClick={() => {
                        logger.info(`DaS FinSuite -> User Action: View all applications`, {
                          event_type: UserActionsEventTypes.TOP_NAV_VIEW_ALL_APPS_CLICKED,
                          value: 'View all applications'
                        });
                        navigate('/all-apps/#list');
                        onCancel();
                      }}
                      className="view-all-apps"
                    >
                      <Box margin={{ top: 'm', bottom: 'm' }}>
                        <SpaceBetween size="xxxs" direction="vertical">
                          <Box padding={{ left: 'l' }} fontSize="heading-s" variant="awsui-key-label" className="grid-content-item">
                            {'View all applications'}
                          </Box>
                          <Box fontSize="body-s" fontWeight="light" variant="p" className="grid-content-description" padding={{ left: 'l' }}>
                            {'All DaS FinTech solutions organized by category on a page'}
                          </Box>
                        </SpaceBetween>
                      </Box>
                    </div>

                    <Box>
                      {Object.entries(groupedApplications).length === 0 && (
                        <EmptyState title="No applications" description="" verticalCenter={true} />
                      )}
                      <ColumnLayout columns={1} disableGutters>
                        <div ref={centerPanelRef}>
                          {Object.entries(groupedApplications).length !== 0 &&
                            Object.entries(groupedApplications).map(([glAccount, apps], index) => (
                              <div key={index} id={`category-${replaceSpecialCharacters(glAccount)}`}>
                                <Box
                                  key={index}
                                  fontSize="heading-m"
                                  fontWeight="light"
                                  variant="awsui-key-label"
                                  className="grouped-category"
                                  padding={{ bottom: 's' }}
                                >
                                  {glAccount}

                                  <SpaceBetween size="xxxs" alignItems="start" direction="vertical">
                                    {apps.map((app, appIndex) => (
                                      <div
                                        key={appIndex}
                                        className="grouped-category-app"
                                        onMouseEnter={() => handleMouseEnter(app.customIndex)}
                                        onMouseLeave={handleMouseLeave}
                                      >
                                        {/* Icon, AppName & Description  */}
                                        <Box margin={{ left: 'l', right: 'm' }}>
                                          <SpaceBetween size="s" direction="horizontal">
                                            {/* Star Icon for favorites */}
                                            <div className={`grouped-category-app ${hoveredIndex === app.customIndex ? 'hovered' : ''}`}>
                                              <Box className="grouped-category-app-icon-holder" float="right" padding={{ top: 's', bottom: 's' }}>
                                                {app.isFavoriteApp ? <FontAwesomeIcon icon={solidStar} onClick={() => clickedFavorite(app)} /> : null}
                                                {!app.isFavoriteApp && hoveredIndex === app.customIndex ? (
                                                  <FontAwesomeIcon icon={regularStar} onClick={() => clickedFavorite(app)} />
                                                ) : null}
                                              </Box>
                                            </div>

                                            {/* App Name & Description  */}
                                            <Box padding={{ top: 's' }}>
                                              <div onClick={() => navigateToAppSpecific(app?.id)}>
                                                <Box
                                                  fontSize="heading-s"
                                                  fontWeight="normal"
                                                  variant="awsui-key-label"
                                                  className="grouped-category-app-name"
                                                >
                                                  {`${app.applicationName}`}
                                                </Box>
                                                <Box
                                                  fontSize="heading-xs"
                                                  fontWeight="normal"
                                                  variant="p"
                                                  className="grouped-category-app-description"
                                                >
                                                  {app.shortApplicationDescription}
                                                </Box>
                                              </div>
                                            </Box>
                                          </SpaceBetween>
                                        </Box>
                                      </div>
                                    ))}
                                  </SpaceBetween>
                                </Box>
                              </div>
                            ))}
                        </div>
                      </ColumnLayout>
                    </Box>
                  </SpaceBetween>
                );
              case 2: // Favorites
                return (
                  <SpaceBetween size="m" direction="vertical">
                    <Box margin={{ top: 'm' }}>
                      <SpaceBetween size="xxs" direction="vertical">
                        <Box variant="awsui-key-label" fontSize="heading-m" className="grid-content-item">
                          {'Favorites'}
                        </Box>
                        {/* <Box fontSize="body-s" variant="p" className="grid-content-description">
                          {
                            'Add your preferred apps to this list to save its homepage for quick access. A shortcut to the external application will be pinned to your Favorites tool bar.'
                          }
                        </Box> */}
                      </SpaceBetween>
                    </Box>

                    <Box className="grouped-category">
                      {favoriteApplications.length === 0 && (
                        <EmptyState
                          title="Your favorite services will appear here."
                          description="To add an App to your favorites, click the star next to its name. Your favorite apps will also appear in the top navigation bookmark bar."
                        />
                      )}
                      <ColumnLayout columns={1} disableGutters>
                        {favoriteApplications.map((app: ApplicationFlatTableEntity, index: number) => (
                          <div
                            key={index}
                            className="grouped-category-app"
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {/* Icon, AppName & Description  */}
                            <Box margin={{ left: 'l', right: 'm' }} className="grouped-category">
                              <SpaceBetween size="s" direction="horizontal">
                                {/* Star Icon for favorites */}
                                <div className={`grouped-category-app ${hoveredIndex === index ? 'hovered' : ''}`}>
                                  <Box className="grouped-category-app-icon-holder" float="right" padding={{ top: 'xs', bottom: 'xs' }}>
                                    <FontAwesomeIcon
                                      icon={solidStar}
                                      onClick={() => clickedFavorite({ ...app, isFavoriteApp: true } as GroupedApplicationFlatTableEntity)}
                                    />
                                  </Box>
                                </div>

                                {/* App Name & Description  */}
                                <div onClick={() => navigateToAppSpecific(app?.id)}>
                                  <Box padding={{ top: 'xs' }}>
                                    <Box fontSize="heading-s" fontWeight="normal" variant="awsui-key-label" className="grouped-category-app-name">
                                      {`${app.applicationName}`}
                                    </Box>
                                    <Box fontSize="heading-xs" fontWeight="normal" variant="p" className="grouped-category-app-description">
                                      {app.shortApplicationDescription}
                                    </Box>
                                  </Box>
                                </div>
                              </SpaceBetween>
                            </Box>
                          </div>
                        ))}
                      </ColumnLayout>
                    </Box>
                  </SpaceBetween>
                );
              default:
                {
                  onCancel();
                }
                return null;
            }
          })()}
        </div>
      )
    );
  };

  const col3 = () => {
    return <Button onClick={onCancel} iconName="close" variant="icon" className="grid-content-item" />;
  };

  return (
    <Modal
      className={styles.globalDropdown}
      onDismiss={() => {
        onCancel();
      }}
      visible={showModal}
      closeAriaLabel="Close modal"
      // size="large"
    >
      <div className="modal-content">
        {/* Column 1  */}
        <div className="col-1">{col1()}</div>

        {/* Column 2  */}
        <div className="col-2">{col2()}</div>

        {/* Column 3  */}
        <div className="col-3">{col3()}</div>
      </div>
    </Modal>
  );
};

export default memo(GlobalDropdown);
