import { Icon, Link, TableProps } from '@amzn/awsui-components-react';
import * as React from 'react';
import { PolicyEngineParsedDataItem } from './PolicyEngineInterface';
import { getMinutesAgoDetails } from 'src/utilities/DateTimeUtilities';

export const POLICY_ENGINE_DETAILS_ISSUES_VISIBLE_CONTENT = [
  'assignee_name',
  'policy_type',
  'severity',
  'resolution_type',
  'policy_class',
  'violator_type',
  'policy_age_in_minutes',
  'status',
  'url'
];

export const POLICY_ENGINE_DETAILS_TABLE: TableProps.ColumnDefinition<PolicyEngineParsedDataItem>[] = [
  {
    id: 'assignee_name',
    header: 'Owner',
    sortingField: 'assignee_name',
    cell: (item: PolicyEngineParsedDataItem) => item.assignee_name,
    isRowHeader: true
  },
  {
    id: 'policy_type',
    header: 'Policy Type',
    sortingField: 'policy_type',
    cell: (item: PolicyEngineParsedDataItem) => item.policy_type,
    isRowHeader: true
  },
  {
    id: 'severity',
    header: 'Severity',
    sortingField: 'severity',
    cell: (item: PolicyEngineParsedDataItem) => item.severity,
    isRowHeader: true
  },
  {
    id: 'resolution_type',
    header: 'Resolution Type',
    sortingField: 'resolution_type',
    cell: (item: PolicyEngineParsedDataItem) => item.resolution_type,
    isRowHeader: true
  },
  {
    id: 'policy_class',
    header: 'Policy Class',
    sortingField: 'policy_class',
    cell: (item: PolicyEngineParsedDataItem) => item.policy_class,
    isRowHeader: true
  },
  {
    id: 'violator_type',
    header: 'Violator Type',
    sortingField: 'violator_type',
    cell: (item: PolicyEngineParsedDataItem) => item.violator_type,
    isRowHeader: true
  },
  {
    id: 'policy_age_in_minutes',
    header: 'Risk since',
    sortingField: 'policy_age_in_minutes',
    cell: (item: PolicyEngineParsedDataItem) => getMinutesAgoDetails(item.policy_age_in_minutes),
    isRowHeader: true
  },
  {
    id: 'status',
    header: 'Status',
    sortingField: 'status',
    cell: (item: PolicyEngineParsedDataItem) => item.status,
    isRowHeader: true
  },
  {
    id: 'url',
    header: 'URL',
    sortingField: 'url',
    cell: (item: PolicyEngineParsedDataItem) => (
      <Link href={item.url} target="_blank" rel="noopener noreferrer">
        <Icon name="external" />
      </Link>
    ),
    isRowHeader: true
  }
];
