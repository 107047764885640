import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { useCostMetricsContext } from './CostMetricsContext';
import { CostMetricsParsedData, CostMetricsFilters } from './CostMetricsInterface';
import { Box, Button, ColumnLayout, Container, FormField, Multiselect, MultiselectProps, SpaceBetween } from '@amzn/awsui-components-react';
import {
  applyFiltersOnCostMetricsDataMetrics,
  createCostMetricsFilterData,
  getCostMetricsCalculatedDetailsTabData,
  getCostMetricsCalculatedGraphData
} from './CostMetricsDataProcessing';
import {
  objectToOptions,
  objectToOptionsForMonths,
  optionsToObject,
  parseObjectToMultiSelectOptions_Sorted,
  returnFilterAgeSorted
} from '../FinTechOpsDataProcessingFunctions';
import { logger } from 'src/logger';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import { getCurrentTime, getTimeDifference, sortedDateArrayMMMYYYYFormat } from 'src/utilities/DateTimeUtilities';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { getMultiSelectPlaceHolderValue } from 'src/utilities/CommonUtilities';

export const CostMetricsSelectionFilters: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const costMetricsContext = useCostMetricsContext();

  const costMetricsParsedData: CostMetricsParsedData[] = finOpsContext?.finTechOpsCostMetricsParsedData?.data || [];

  const [lastRefreshed, setLastRefreshed] = useState('');

  const [masterFilterData, setMasterFilterData] = React.useState<CostMetricsFilters>({
    team: [],
    managers: [],
    products: [],
    resources: [],
    resourceType: [],
    timePeriods: [],
    stages: [],
    applications: []
  });

  //Cost Metrics Global Filters Variables
  const [teamsDropdown, setTeamsDropdown] = useState<MultiselectProps.Options>([]);
  const [managersDropdown, setManagersDropdown] = useState<MultiselectProps.Options>([]);
  const [productsDropdown, setProductsDropdown] = useState<MultiselectProps.Options>([]);
  const [resourcesDropdown, setResourcesDropdown] = useState<MultiselectProps.Options>([]);
  const [resourceTypeDropdown, setResourceTypeDropdown] = useState<MultiselectProps.Options>([]);
  const [timePeriodsDropdown, setTimePeriodsDropdown] = useState<MultiselectProps.Options>([]);
  const [stagesDropdown, setStagesDropdown] = useState<MultiselectProps.Options>([]);
  const [applicationsDropdown, setApplicationsDropdown] = useState<MultiselectProps.Options>([]);

  //Cost Metrics Global Filters Variables holding raw or original data
  const [rawteamsDropdown, setRawTeamsDropdown] = useState<MultiselectProps.Options>([]);
  const [rawmanagersDropdown, setRawManagersDropdown] = useState<MultiselectProps.Options>([]);
  const [rawProductsDropdown, setRawProductsDropdown] = useState<MultiselectProps.Options>([]);
  const [rawResourcesDropdown, setRawResourcesDropdown] = useState<MultiselectProps.Options>([]);
  const [rawResourceTypeDropdown, setRawResourceTypeDropdown] = useState<MultiselectProps.Options>([]);
  const [rawtimePeriodsDropdown, setRawTimePeriodsDropdown] = useState<MultiselectProps.Options>([]);
  const [rawStagesDropdown, setRawStagesDropdown] = useState<MultiselectProps.Options>([]);
  const [rawApplicationsDropdown, setRawApplicationsDropdown] = useState<MultiselectProps.Options>([]);

  const [isRawFilter, setIsRawFilter] = useState(true);

  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    costMetricsContext.setCostMetricsDetailsTabStatus('loading');
    costMetricsContext.setCostMetricsGraphStatus('loading');
    if (finOpsContext.finTechOpsCostMetricsDataStatus === 'finished') {
      const costMetricsFilterValues = createAndUpdateCostMetricsFilters(costMetricsParsedData);
      updateCostMetricsCalculation(costMetricsFilterValues);
      setLastRefreshed(finOpsContext?.finTechOpsCostMetricsParsedData?.metadata?.last_updated_at);
      setIsResetFilter(false);
    }
    if (finOpsContext.finTechOpsCostMetricsDataStatus == 'error') {
      costMetricsContext.displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
      costMetricsContext.setCostMetricsDetailsTabStatus('error');
      costMetricsContext.setCostMetricsGraphStatus('error');
    }
  }, [finOpsContext.finTechOpsCostMetricsDataStatus, isResetFilter]);

  //Function to create and set global filters of cost metrics
  const createAndUpdateCostMetricsFilters = (costMetricsParsedData: CostMetricsParsedData[]): CostMetricsFilters => {
    try {
      const _masterFilterData = createCostMetricsFilterData(costMetricsParsedData);
      setMasterFilterData(_masterFilterData);

      setTeamsDropdown(objectToOptions(_masterFilterData.team));
      setManagersDropdown(objectToOptions(_masterFilterData.managers));
      setProductsDropdown(objectToOptions(_masterFilterData.products));
      setResourcesDropdown(objectToOptions(_masterFilterData.resources));
      setResourceTypeDropdown(objectToOptions(_masterFilterData.resourceType));
      setTimePeriodsDropdown(objectToOptionsForMonths(_masterFilterData.timePeriods));
      setStagesDropdown(objectToOptions(_masterFilterData.stages));
      setApplicationsDropdown(objectToOptions(_masterFilterData.applications));

      //one time setting values for placeholder value comparison with raw filter and currently applied filter
      if (isRawFilter === true) {
        setRawTeamsDropdown(objectToOptions(_masterFilterData.team));
        setRawManagersDropdown(objectToOptions(_masterFilterData.managers));
        setRawProductsDropdown(objectToOptions(_masterFilterData.products));
        setRawResourcesDropdown(objectToOptions(_masterFilterData.resources));
        setRawResourceTypeDropdown(objectToOptions(_masterFilterData.resourceType));
        setRawTimePeriodsDropdown(objectToOptionsForMonths(_masterFilterData.timePeriods));
        setRawStagesDropdown(objectToOptions(_masterFilterData.stages));
        setRawApplicationsDropdown(objectToOptions(_masterFilterData.applications));
      }
      setIsRawFilter(false);

      return _masterFilterData;
    } catch (error: any) {
      logger.error('createAndUpdateCostMetricsFilters: Unable to update CostMetrics filters', error);
      costMetricsContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      costMetricsContext.setCostMetricsGraphStatus('error');
      costMetricsContext.setCostMetricsDetailsTabStatus('error');
      return { team: [], managers: [], products: [], resources: [], resourceType: [], timePeriods: [], stages: [], applications: [] };
    }
  };

  //Function to be called on onBlur event
  const triggerCall = () => {
    const selectedTeams = optionsToObject(teamsDropdown);
    const selectedManagers = optionsToObject(managersDropdown);
    const selectedProducts = optionsToObject(productsDropdown);
    const selectedResources = optionsToObject(resourcesDropdown);
    const selectedResourceType = optionsToObject(resourceTypeDropdown);
    const selectedTimePeriods = optionsToObject(timePeriodsDropdown);
    const selectedStages = optionsToObject(stagesDropdown);
    const selectedApplications = optionsToObject(applicationsDropdown);

    updateCostMetricsCalculation({
      team: selectedTeams,
      managers: selectedManagers,
      products: selectedProducts,
      resources: selectedResources,
      resourceType: selectedResourceType,
      timePeriods: selectedTimePeriods,
      stages: selectedStages,
      applications: selectedApplications
    });
  };

  //Function to update the cost metrics graph and details page
  const updateCostMetricsCalculation = (selectedFilterData: CostMetricsFilters) => {
    costMetricsContext.setCostMetricsGraphStatus('loading');
    costMetricsContext.setCostMetricsDetailsTabStatus('loading');

    try {
      const start = getCurrentTime();

      const [filteredWithoutMonthData, filteredCostMetricsData]: [CostMetricsParsedData[], CostMetricsParsedData[]] =
        applyFiltersOnCostMetricsDataMetrics(finOpsContext.finTechOpsCostMetricsParsedData.data, selectedFilterData);
      createAndUpdateCostMetricsFilters(filteredCostMetricsData);

      const calculatedGraphData: any = getCostMetricsCalculatedGraphData(filteredCostMetricsData);
      costMetricsContext.setCostMetricsTrendsByMonthGraphData(calculatedGraphData.costMetricsTrendsByMonthGraphData);
      costMetricsContext.setCostMetricsTrendsByMonthGraphDataXDomain(calculatedGraphData.costMetricsTrendsByMonthGraphDataXDomain);
      costMetricsContext.setCostMetricsTrendsByMonthGraphDataYDomain(calculatedGraphData.costMetricsTrendsByMonthGraphDataYDomain);

      costMetricsContext.setCostByProductGraphData(calculatedGraphData.costByProductGraphData);
      costMetricsContext.setCostByProductGraphDataXDomain(calculatedGraphData.costByProductGraphDataXDomain);
      costMetricsContext.setCostByProductGraphDataYDomain(calculatedGraphData.costByProductGraphDataYDomain);

      costMetricsContext.setcostByResourceTypeGraphData(calculatedGraphData.costByResourceTypeGraphData);
      costMetricsContext.setcostByResourceTypeGraphDataXDomain(calculatedGraphData.costByResourceTypeGraphDataXDomain);
      costMetricsContext.setcostByResourceTypeGraphDataYDomain(calculatedGraphData.costByResourceTypeGraphDataYDomain);

      costMetricsContext.setCostByApplicationGraphData(calculatedGraphData.costByApplicationGraphData);
      costMetricsContext.setCostByApplicationGraphDataXDomain(calculatedGraphData.costByApplicationGraphDataXDomain);
      costMetricsContext.setCostByApplicationGraphDataYDomain(calculatedGraphData.costByApplicationGraphDataYDomain);

      const calculatedDetalsTableData = getCostMetricsCalculatedDetailsTabData(filteredCostMetricsData, filteredWithoutMonthData);
      costMetricsContext.setCostMetricsDetailsTable(calculatedDetalsTableData.costMetricsDetailsTable);
      costMetricsContext.setAggregatedCostByProductDetailsTable(calculatedDetalsTableData.aggregatedCostMetricsByProductDetailsTable);
      costMetricsContext.setAggregatedCostByAccountDetailsTable(calculatedDetalsTableData.aggregatedCostMetricsByAccountDetailsTable);
      costMetricsContext.setAggregatedCostByApplicationsDetailsTable(calculatedDetalsTableData.aggregatedCostMetricsByApplicationsDetailsTable);
      costMetricsContext.setCostMetricsSummaryContainer(calculatedDetalsTableData.costMetricsSummaryContainer);

      const elapsed = getTimeDifference(start);
      logger.info(`Fetched calculated data for Cost Metrics in ${elapsed} ms`);

      costMetricsContext.setCostMetricsGraphStatus('finished');
      costMetricsContext.setCostMetricsDetailsTabStatus('finished');
    } catch (error: any) {
      logger.error('Unable to process Cost Metrics data', error);
      costMetricsContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      costMetricsContext.setCostMetricsGraphStatus('error');
      costMetricsContext.setCostMetricsDetailsTabStatus('error');
    }
  };

  return (
    <Box padding={{ top: 'xl' }}>
      <Container
        fitHeight
        header={
          <SpaceBetween size="m" direction="horizontal" alignItems="center">
            <Box variant="h2">Cost Metrics</Box>
            <LastRefreshedAt lastRefreshedDateTime={lastRefreshed} />
            <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
          </SpaceBetween>
        }
      >
        <ColumnLayout columns={4} minColumnWidth={20}>
          <FormField label="Team">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(teamsDropdown, 'Team', optionsToObject(rawteamsDropdown))}
              selectedOptions={teamsDropdown}
              onChange={({ detail }) => setTeamsDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.team, 'All Teams')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Teams"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>

          <FormField label="Manager">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(managersDropdown, 'Manager', optionsToObject(rawmanagersDropdown))}
              selectedOptions={managersDropdown}
              onChange={({ detail }) => setManagersDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.managers, 'All Managers')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Managers"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>

          <FormField label="Application">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(applicationsDropdown, 'Applications', optionsToObject(rawApplicationsDropdown))}
              selectedOptions={applicationsDropdown}
              onChange={({ detail }) => setApplicationsDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.applications, 'All Applications')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Applications"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>

          <FormField label="Environment">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(stagesDropdown, 'Environments', optionsToObject(rawStagesDropdown))}
              selectedOptions={stagesDropdown}
              onChange={({ detail }) => setStagesDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.stages, 'All Environments')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Environments"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>

          <FormField label="Resource Type">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(resourceTypeDropdown, 'Resource Type', optionsToObject(rawResourceTypeDropdown))}
              selectedOptions={resourceTypeDropdown}
              onChange={({ detail }) => setResourceTypeDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.resourceType, 'All Resource Type')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Resource Type"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>

          <FormField label="Resource">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(resourcesDropdown, 'Resources', optionsToObject(rawResourcesDropdown))}
              selectedOptions={resourcesDropdown}
              onChange={({ detail }) => setResourcesDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.resources, 'All Resources')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Resources"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>

          <FormField label="Product">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(productsDropdown, 'Product', optionsToObject(rawProductsDropdown))}
              selectedOptions={productsDropdown}
              onChange={({ detail }) => setProductsDropdown(detail.selectedOptions)}
              options={parseObjectToMultiSelectOptions_Sorted(masterFilterData.products, 'All Products')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Products"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>

          <FormField label="Month">
            <Multiselect
              placeholder={getMultiSelectPlaceHolderValue(timePeriodsDropdown, 'Months', optionsToObject(rawtimePeriodsDropdown))}
              selectedOptions={timePeriodsDropdown}
              onChange={({ detail }) => setTimePeriodsDropdown(detail.selectedOptions)}
              options={returnFilterAgeSorted(sortedDateArrayMMMYYYYFormat(masterFilterData.timePeriods), 'All Months')}
              onBlur={() => triggerCall()}
              hideTokens
              expandToViewport
              loadingText="Loading Months"
              errorText="Unable to load data"
              deselectAriaLabel={(e) => `Remove ${e.label}`}
              selectedAriaLabel="Selected"
              virtualScroll
              filteringType="auto"
              statusType={finOpsContext.finTechOpsCostMetricsDataStatus}
              disabled={
                costMetricsContext.costMetricsGraphStatus === 'loading' || costMetricsContext.costMetricsDetailsTabStatus === 'loading' ? true : false
              }
            />
          </FormField>
        </ColumnLayout>
      </Container>
    </Box>
  );
};
