import { DateRangePickerProps, FlashbarProps } from '@amzn/awsui-components-react';
import { eGENERAL_APP_CONTENT_KEY } from 'src/constants/AppConstants';

// Strictly limiting the UserAuthenticationDetails to Cognito Auth Details
export interface UserAuthContext {
  Alias: string;
  DisplayName: string;
  GivenName: string;
  Email: string;
  userLDAPGroups: string[];
  isAdmin: boolean | null;
  isDev: boolean | null;
  isOEReadOnly: boolean;
  auth_status_message: string;
  userAuthDataLoadingStatus: LoadingStatus;
}

export interface SecretKeyConfig {
  environment: string;
  secretARN: string;
}

export interface APIInfo {
  environment: string;
  finsuite_api: string;
  risk_remediator_api: string;
  papi: string;
}

export interface Application {
  id: string;
  applicationName: string | null;
  applicationLink: string | null;
  applicationOwner: LabelInput[];
  shortApplicationDescription: string | null;
  fullApplicationDescription: string | null;
  glAccounts: LabelInput[];
  toolUI: LabelInput[];
  hasNonProductionEnvironments: boolean | null;
  nonProductionEnvironments?: AllAppsNonProductionEnvironmentsEntity[] | null;
  timeGranularity: LabelInput[];
  keyDimension: LabelInput[];
  process: LabelInput[];
  scenario: LabelInput[];
  lastEnhanced: LabelInput[];
  hasWelcomeContent: boolean | null;
  welcomeTitle: string | null;
  welcomeContentDetails?: AllAppsWelcomeContentDetailsEntity[] | null;
  hasAnnouncements: boolean | null;
  hasTutorials: boolean | null;
  tutorialContentDetails?: AllAppsTutorialContentDetailsEntity[] | null;
  hasFeedback: boolean | null;
  hasFinanceContacts: boolean;
  financeContacts?: AllAppsContactsEntity[] | null;
  hasFinTechContacts: boolean;
  finTechContacts?: AllAppsContactsEntity[] | null;
  hasOfficeHours: boolean;
  officeHoursV2: AllAppsOfficeHoursV2[] | null;
  hasReportIssues: boolean;
  reportIssues?: AllAppsReportIssueAndResourcesEntity[] | null;
  hasAdditionalResources: boolean;
  additionalResources?: AllAppsReportIssueAndResourcesEntity[] | null;
  itemMetadata: ItemMetadata;
  status?: string;
}

export interface GroupedAppData {
  [mainCategory: string]: GroupedApplicationFlatTableEntity[];
}

export interface GroupedApplicationFlatTableEntity extends ApplicationFlatTableEntity {
  customIndex: number;
  isFavoriteApp: boolean;
}

export interface GroupedData {
  [mainCategory: string]: ApplicationFlatTableEntity[];
}

export interface ApplicationFlatTableEntity {
  id: string;
  status: string;
  applicationName: string;
  applicationLink: string;
  shortApplicationDescription: string;
  fullApplicationDescription: string;
  applicationOwner: string;

  // Application categories
  glAccount: string;
  toolUI: string;

  // Application tags
  timeGranularity: string[];
  keyDimension: string[];
  process: string[];
  scenario: string[];
  lastEnhanced: string[];

  tutorialContentDetails: AllAppsTutorialContentDetailsEntity[];

  isActive: boolean;
  updatedTime: string;
}

export type LabelType =
  | 'ApplicationName'
  | 'AnnouncementCategory'
  | 'TimeGranularity'
  | 'KeyDimension'
  | 'LastEnhanced'
  | 'Process'
  | 'Scenario'
  | 'GLAccount'
  | 'ToolUI'
  | 'ApplicationOwner'
  | 'AccessLevel';
export interface LabelInput {
  id: string;
  label: string;
  labelType: LabelType;
}

export interface AllAppsNonProductionEnvironmentsEntity {
  id: string | null;
  accessLevel: LabelInput[];
  displayText: string | null;
  link: string | null;
}

export interface AllAppsWelcomeContentDetailsEntity {
  id: string | null;
  displayText: string | null;
  link: string | null;
  description: string | null;
}

export interface DFPHomePageGettingStarted {
  id: string;
  displayText: string;
  link: string;
  description: string;
}

export interface AllAppsTutorialContentDetailsEntity {
  id: string | null;
  position: number | null;
  category: string | null;
  displayTitle: string | null;
  link: string | null;
}

// interface to use for "Tutorials" page filtering
export interface TutorialsFilter extends AllAppsTutorialContentDetailsEntity {
  applicationName: string;
  updatedTime: string;
}

export interface AllAppsContactsEntity {
  id: string | null;
  position: number | null;
  alias: string | null;
  contactDescription: string | null;
}

export interface DateAbsoluteRangePicker {
  type: 'absolute';
  startDate: string;
  endDate: string;
}

export interface AllAppsOfficeHoursV2 {
  id: string;
  dateTimeRecurrence: string | null;
  locationOrLinkName: string | null;
  meetingLink: string | null;
}

export interface AllAppsOfficeHours {
  id: string;
  meetingTime: DateAbsoluteRangePicker | null;
  meetingName: string | null;
  meetingLink: string | null;
  recurrence: {
    type: string | 'daily' | 'weekly' | 'monthly' | 'none';
    weekly: {
      every: number | null;
      days: {
        Sunday: boolean;
        Monday: boolean;
        Tuesday: boolean;
        Wednesday: boolean;
        Thursday: boolean;
        Friday: boolean;
        Saturday: boolean;
      };
    };
    monthly: {
      every: number | null;
      type: string | 'day' | 'first' | 'second' | 'third' | 'last';
      day: number | null;
      ordinal: string | 'Day' | 'Weekday' | 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    };
  };
}

export interface AllAppsReportIssueAndResourcesEntity {
  id: string | null;
  position: number | null;
  displayName: string | null;
  link: string | null;
}

export interface ItemMetadata {
  createdBy: string;
  createdTime: string;
  isActive: boolean | null;
  updatedBy: string;
  updatedTime: string;
}

export interface ApplicationDropdowns {
  listApplicationOwners?: LabelInput[] | null;
  listGLAccounts?: LabelInput[] | null;
  listToolUI?: LabelInput[] | null;
  listAccessLevels?: LabelInput[] | null;
  listTimeGranularity?: LabelInput[] | null;
  listKeyDimension?: LabelInput[] | null;
  listLastEnhanced?: LabelInput[] | null;
  listProcess?: LabelInput[] | null;
  listScenario?: LabelInput[] | null;
}

export interface FavoriteApplications {
  applicationId: string[] | [];
  id: string;
  userAlias: string;
}

export interface AnnouncementEntity {
  id: string;
  title: string;
  applicationName: LabelInput;
  announcementCategory: LabelInput;
  announcementDate: string;
  announcementLink: string;
  announcement: string;
  itemMetadata: ItemMetadata;
}

export interface AnnouncementFlatTableEntity {
  id: string;
  title: string;
  applicationName: string;
  applicationId: string;
  announcementCategory: string;
  announcement: string;
  announcementDate: string;
  announcementLink: string;
  createdBy: string;
  createdTime: string;
  status: string;
  isActive: boolean;
  updatedBy: string;
  updatedTime: string;
}

export interface AppContext {
  isAdminControlsEnabled: boolean | null;
  setIsAdminControlsEnabled: (isEnabled: boolean) => void;
  contextLoadingError: string | null;
  contextLoadingStatus: LoadingStatus;
  listOfApplications: Application[];
  setListOfApplications: (applications: Application[]) => void;
  parsedApplications: ApplicationFlatTableEntity[];
  setParsedApplications: (parsedApplications: ApplicationFlatTableEntity[]) => void;
  favoriteApplications: string[];
  setFavoriteApplications: (favoriteApplications: string[]) => void;
  announcements: AnnouncementEntity[];
  setAnnouncements: (announcements: AnnouncementEntity[]) => void;
  announcementsFlatEntity: AnnouncementFlatTableEntity[];
  setAnnouncementsFlatEntity: (announcements: AnnouncementFlatTableEntity[]) => void;
  applicationMessages: FlashbarProps.MessageDefinition[];
  displayApplicationFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void;
  listApplicationDropdowns: ApplicationDropdowns | undefined;
  setListApplicationDropdowns: (applicationDropdowns: ApplicationDropdowns) => void;
  listGeneralContent: GeneralContent[];
  setListGeneralContent: (generalContent: GeneralContent[]) => void;
}

export interface GeneralContent {
  id: string;
  name: eGENERAL_APP_CONTENT_KEY;
  content: string;
}

export enum LoadingStatus {
  NotInitiated = 'Not Initiated',
  Loading = 'Loading',
  Completed = 'Completed',
  Failed = 'Failed'
}

export interface StoredWidgetPlacement {
  id: string;
  columnOffset?: Record<number, number>;
  rowSpan?: number;
  columnSpan?: number;
}

export interface AppRedirectInfo {
  from: string;
  redirectFromHomePage: boolean;
  fromAppName?: string;
  fromAppId?: string;
}

export interface DFPInfoPanelContent {
  header: string;
  description: string;
  additionalLinks: AdditionalLinksEntity[] | [];
}
export interface AdditionalLinksEntity {
  text: string;
  href: string;
}

export interface PAPIUserInfo {
  personId: string;
  lastName: string;
  firstName: string;
  login: string;
  badgeColor: string;
  businessTitle: string;
  preferredEmail?: any;
  primaryEmail: string;
  primaryPhone: string;
  isManager: boolean;
  managerPersonId: string;
  managerLogin: string;
  managerName: string;
  jobProfileId: string;
  jobProfileName: string;
  costCenterId: string;
  extendedCostCenterId: string;
  costCenterName: string;
  extendedCostCenterName: string;
  companyId: string;
  companyUdmId: string;
  companyName: string;
  jobStatus: string;
  employeeStatus: string;
  workspace: string;
  tenureDays: number;
  serviceDate: number;
  legacyEmployeeId: string;
  isPrehire: boolean;
  pastTenureDays: number;
}
