import { useEffect, useRef, useState } from 'react';
import { useContainerQuery } from '@amzn/awsui-component-toolkit';
import { load, remove, save } from 'src/utilities/LocalStorage';
import { StoredWidgetPlacement } from '../context/AppContextModels';
import { merge } from '../das-finsuite/configurable-dashboard/Utils/ConfigurableDashboardUtils';
import { recordInAppEvent } from 'src/analytics/CloudWatchRumClient';

export function useDFPHomePageItemsLayout(storageKey: string) {
  const [width, ref] = useContainerQuery((entry) => entry.contentBoxWidth);
  const [layout, setLayout] = useState<ReadonlyArray<StoredWidgetPlacement> | null>(() => load(storageKey) ?? null);
  const itemsChanged = useRef(layout !== null);

  useEffect(() => {
    if (itemsChanged.current || !width) {
      return;
    }
    setLayout(getDefaultDFPHomeLayout(width));
  }, [width]);

  function handleLayoutChange(layout: ReadonlyArray<StoredWidgetPlacement>) {
    itemsChanged.current = true;
    save(storageKey, layout);
    setLayout(layout);
    const buttonClickEventData = {
      buttonId: 'dfp-home-page-layout-changed',
      action: 'click'
    };
    recordInAppEvent('buttonClick', buttonClickEventData);
  }

  function resetLayout() {
    itemsChanged.current = false;
    remove(storageKey);
    setLayout(getDefaultDFPHomeLayout(width!));
    const buttonClickEventData = {
      buttonId: 'dfp-home-page-layout-reset',
      action: 'click'
    };
    recordInAppEvent('buttonClick', buttonClickEventData);
  }

  return [ref, layout ?? [], handleLayoutChange, resetLayout] as const;
}

const defaultDFPHomepageLayout: ReadonlyArray<StoredWidgetPlacement> = [
  { id: 'welcomeWidget' },
  { id: 'announcementWidget' },
  { id: 'gettingStartedWidget' }
];

export function getDefaultDFPHomeLayout(width: number) {
  if (width >= 2160) {
    // 6-col layout
    return merge(defaultDFPHomepageLayout, [
      { id: 'welcomeWidget', columnOffset: { '6': 3 } },
      { id: 'announcementWidget', columnOffset: { '6': 3 } },
      { id: 'gettingStartedWidget', columnOffset: { '6': 3 } }
    ]);
  }
  if (width > 1045) {
    // 4-col layout with 4-col overview
    return defaultDFPHomepageLayout;
  }
  if (width > 911) {
    // 4-col layout with 2-col overview
    return merge(defaultDFPHomepageLayout, [
      { id: 'welcomeWidget', rowSpan: 3 },
      { id: 'announcementWidget', rowSpan: 3 },
      { id: 'gettingStartedWidget', rowSpan: 3 }
    ]);
  }
  if (width > 708) {
    // 2-col layout with 4-col overview
    return merge(defaultDFPHomepageLayout, [
      { id: 'welcomeWidget', rowSpan: 3 },
      { id: 'announcementWidget', rowSpan: 2 },
      { id: 'gettingStartedWidget', rowSpan: 3 }
    ]);
  }
  if (width > 687) {
    // 2-col layout with 2-col overview
    return merge(defaultDFPHomepageLayout, [
      { id: 'welcomeWidget', rowSpan: 3 },
      { id: 'announcementWidget', rowSpan: 3 }
    ]);
  }
  if (width > 485) {
    // 1-col layout with 2-col overview
    return merge(defaultDFPHomepageLayout, [
      { id: 'welcomeWidget', rowSpan: 3 },
      { id: 'announcementWidget', rowSpan: 3 }
    ]);
  }
  // 1-col layout with 1-col overview
  return merge(defaultDFPHomepageLayout, [
    { id: 'welcomeWidget', rowSpan: 5 },
    { id: 'announcementWidget', rowSpan: 5 }
  ]);
}
