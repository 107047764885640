import { useEffect, useState } from 'react';
import { logger } from 'src/logger';

// A generic hook for persisting state in local storage.
export function usePersistedState<T>(localStorageKey: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    try {
      const localStorageItem = localStorage.getItem(localStorageKey);
      return localStorageItem !== null ? JSON.parse(localStorageItem) : defaultValue;
    } catch (err: any) {
      logger.error(`Error parsing local storage item "${localStorageKey}":`, err);
      return defaultValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    } catch (err: any) {
      logger.error(`Error setting local storage item "${localStorageKey}":`, err);
    }
  }, [value, localStorageKey]);

  return [value, setValue];
}
