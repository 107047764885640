import React, { useEffect } from 'react';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from 'src/components/das-finsuite/das-finsuite-constants';

interface AnnouncementBodyPopoverProps {
  title: string;
  link?: string;
  linkText?: string;
  popoverBody: string;
}

const DFPPopover: React.FC<AnnouncementBodyPopoverProps> = ({ title, link, linkText, popoverBody }) => {
  return (
    <Popover
      fixedWidth
      size="large"
      dismissButton={false}
      content={
        <SpaceBetween size="m" direction="vertical">
          {link && linkText && (
            <Box variant="p" color="text-status-info" key="link">
              <Link
                href={link}
                external
                onFollow={() => {
                  logger.info(`DaS FinSuite -> User Action: Announcement Widget link clicked`, {
                    event_type: UserActionsEventTypes.ANNOUNCEMENT_WIDGET_LINK_CLICKED,
                    value: title,
                    sub_value: link
                  });
                }}
              >
                {linkText}
              </Link>
            </Box>
          )}
          <Box variant="p" color="text-body-secondary" key="text-body-secondary">
            {popoverBody}
          </Box>
        </SpaceBetween>
      }
    >
      {title}
    </Popover>
  );
};

export default DFPPopover;
