import {
  LexRuntimeV2Client,
  LexRuntimeV2ClientConfig,
  PutSessionCommand,
  PutSessionCommandOutput,
  RecognizeTextCommand,
  RecognizeTextCommandInput,
  RecognizeTextCommandOutput,
  StartConversationCommand,
  StartConversationCommandInput
} from '@aws-sdk/client-lex-runtime-v2';
import { getCredentialsFromAWSService } from 'src/components/context/AuthContextUtils';
import { botAlias, botName, lexLocale, sessionId } from './lex-constants';
import { logger } from 'src/logger';
import ENVIRONMENT_VARIABLES from 'src/constants/EnvironmentConstants';

export const generateRandomString = (): string => {
  const digits = '0123456789';
  let randomString = '';

  while (randomString.length < 6) {
    const randomDigit = digits[Math.floor(Math.random() * digits.length)];
    if (!randomString.includes(randomDigit)) {
      randomString += randomDigit;
    }
  }

  return randomString;
};

export const lexRunTimev2Client = async (): Promise<LexRuntimeV2Client> => {
  const { Region, Stage } = ENVIRONMENT_VARIABLES.env;
  logger.info(`Initializing Lex-run-time client in region ${Region} and stage ${Stage}`);
  const configuration: LexRuntimeV2ClientConfig = {
    region: Region,
    credentials: await getCredentialsFromAWSService()
  };
  const lexClient = new LexRuntimeV2Client(configuration);
  return lexClient;
};

export const createSession = async (sessionId: string): Promise<PutSessionCommandOutput> => {
  const input = {
    botId: botName, // required
    botAliasId: botAlias, // required
    localeId: lexLocale, // required
    sessionId: sessionId, // required
    sessionState: {}
  };
  logger.info(`Creating Session with bot ${botName} and Alias ${botAlias}`);
  try {
    const response = await (await lexRunTimev2Client()).send(new PutSessionCommand(input));
    logger.info('Response: ', response);
    return response;
  } catch (error) {
    logger.error('Error creating session:' + String(error));
    throw error;
  }
};

export const startConversation = async (sessionId: string): Promise<any> => {
  const session = await createSession(sessionId);
  const TextEvent = {
    async *[Symbol.asyncIterator]() {
      yield {
        TextInputEvent: {
          text: 'Try This',
          eventId: '0000'
        }
      };
    }
  };
  const input: StartConversationCommandInput = {
    botId: botName, // required
    botAliasId: botAlias, // required
    localeId: lexLocale, // required
    sessionId: await session.sessionId, // required
    conversationMode: 'TEXT',
    requestEventStream: TextEvent
  };
  const response = (await lexRunTimev2Client()).send(new StartConversationCommand(input));
  console.log('Conversation Start: ', response);
  return response;
};

export const sendMessage = async (inputString: string, sessionId: string): Promise<RecognizeTextCommandOutput> => {
  const input: RecognizeTextCommandInput = {
    botId: botName, // required
    botAliasId: botAlias, // required
    localeId: lexLocale, // required
    sessionId: sessionId, // required
    text: inputString
  };
  const command = new RecognizeTextCommand(input);
  const response = (await lexRunTimev2Client()).send(command);
  console.log('Attempted Message Send Response: ', response);
  return response;
};
