import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { useDogmaContext } from './DogmaContext';
import { GenericBar, GenericLine } from '../sas/SasInterfaces';
import { CreateBarChartData, CreateLineChartData } from '../FinTechOpsDataProcessingFunctions';
import { BarLineChartWithProps } from '../fintech-ops-charts/BarChartWithProps';
import { Grid } from '@amzn/awsui-components-react';
import { FinTechOpsMessages, GRAPH_WIDTHS_BREAK_POINTS } from '../FinTechOpsConstants';
import { EmptyStateMessage } from '../EmptyStateMessage';
import { i18nStringHour } from '../i18nStrings';

const EMPTY_BAR: GenericBar = { dataSeries: [], xDomain: [], yDomain: [] };
const EMPTY_LINE: GenericLine = { dataSeries: [], xDomain: [], yDomain: [] };

export const DogmaOverviewPage: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const dogmaContext = useDogmaContext();

  const [totalViolationByMonth, setTotalViolationByMonth] = useState<GenericBar>(EMPTY_BAR);
  const [totalOpenViolationsByTeam, setTotalOpenViolationsByTeam] = useState<GenericLine>(EMPTY_LINE);
  const [totalOpenViolationsBySeverityType, setTotalOpenViolationsBySeverityType] = useState<GenericBar>(EMPTY_BAR);
  const [totalOpenViolationsByRiskEntityType, setTotalOpenViolationsByRiskEntityType] = useState<GenericLine>(EMPTY_LINE);

  useEffect(() => {
    if (finOpsContext.finTechOpsDogmaDataStatus == 'finished' && dogmaContext.dogmaGraphStatus == 'finished') {
      setTotalViolationByMonth(CreateBarChartData(dogmaContext?.dogmaGraphData?.TotalViolationsByMonth || []));
      setTotalOpenViolationsByTeam(CreateLineChartData(dogmaContext?.dogmaGraphData?.TotalOpenViolationsByTeam || []));
      setTotalOpenViolationsBySeverityType(CreateBarChartData(dogmaContext?.dogmaGraphData?.TotalOpenViolationsBySeverityType || []));
      setTotalOpenViolationsByRiskEntityType(CreateLineChartData(dogmaContext?.dogmaGraphData?.TotalOpenViolationsByRiskEntityType || []));
    }
  }, [finOpsContext, dogmaContext]);

  return (
    <div>
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'loading' && <LoadingSpinner />}
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'error' && <ErrorContainer />}
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'pending' && <NotInitializedContainer />}
      {finOpsContext.finTechOpsPolicyEngineDataStatus == 'finished' && (
        <Grid
          gridDefinition={[
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS },
            { colspan: GRAPH_WIDTHS_BREAK_POINTS }
          ]}
        >
          {/* 1  */}
          <BarLineChartWithProps
            headerName="Total Violations Per Month"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={totalViolationByMonth?.dataSeries}
            xDomain={totalViolationByMonth?.xDomain}
            yDomain={totalViolationByMonth?.yDomain}
            arialLabel="Total Violations Per Month"
            xTitle="Month"
            yTitle="Violations"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Dogma')}
            emptyMessage={
              <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
            }
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
          {/* 2  */}
          <BarLineChartWithProps
            headerName="Total Open Violations by Team"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={totalOpenViolationsByTeam?.dataSeries}
            xDomain={totalOpenViolationsByTeam?.xDomain}
            yDomain={totalOpenViolationsByTeam?.yDomain}
            arialLabel="Total Open Violations by Team"
            xTitle="Month"
            yTitle="Violations"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Dogma')}
            emptyMessage={
              <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
            }
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
          {/* 3  */}
          <BarLineChartWithProps
            headerName="Total Open Violations by Severity Type"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={totalOpenViolationsBySeverityType?.dataSeries}
            xDomain={totalOpenViolationsBySeverityType?.xDomain}
            yDomain={totalOpenViolationsBySeverityType?.yDomain}
            arialLabel="Total Open Violations by Severity Type"
            xTitle="Month"
            yTitle="Violations"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Dogma')}
            emptyMessage={
              <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
            }
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
          {/* 4  */}
          <BarLineChartWithProps
            headerName="Total Open Violations by Risk Entity Type"
            domainType="string"
            stackedBars={true}
            hideFilter={true}
            series={totalOpenViolationsByRiskEntityType?.dataSeries}
            xDomain={totalOpenViolationsByRiskEntityType?.xDomain}
            yDomain={totalOpenViolationsByRiskEntityType?.yDomain}
            arialLabel="Total Open Violations by Risk Entity Type"
            xTitle="Month"
            yTitle="Violations"
            xScaleType="categorical"
            errorText={FinTechOpsMessages.errorText}
            i18nStrings={i18nStringHour('Dogma')}
            emptyMessage={
              <EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />
            }
            noMatchMessage={FinTechOpsMessages.noMatchMessage}
          />
        </Grid>
      )}
    </div>
  );
};
